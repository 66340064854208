import {Injectable} from '@angular/core';
import { HttpService } from "@etop/common";
import {CustomerConversation} from "libs/models/faboshop/CustomerConversation";
import {FbComment} from "libs/models/faboshop/FbComment";
import {FbMessage} from "libs/models/faboshop/FbMessage";
import {StringHandler} from "@etop/utils";

@Injectable({
  providedIn: 'root'
})
export class ConversationApi {
  constructor(
    private http: HttpService,
  ) {
  }

  static mapCustomerConversation(conv: CustomerConversation): CustomerConversation {
    const attachment = conv.external_post_attachments && conv.external_post_attachments[0] || null;
    const sub_attachment = (attachment && attachment.sub_attachments) &&
      attachment.sub_attachments[0] || null;
    conv.image_url = (sub_attachment && sub_attachment.media && sub_attachment.media.src)
      && sub_attachment.media.src || 'assets/imgs/fabo_logo.png';

    return {
      ...conv,
      last_message: conv.last_message || 'Đã gửi 1 tin nhắn',
      fbUsers: [],
      paging: {
        next: '.',
        prev: '.',
        limit: 10
      }
    };
  }

  static mapFbMessage(message: FbMessage): FbMessage {
    if (message.external_attachments[0]?.mime_type == 'video/mp4') {
      message.external_message = `<i class="fas fa-video"></i> Tin nhắn video`;
    }
    if (message.external_attachments[0]?.mime_type == 'audio/mpeg') {
      message.external_message = `<i class="fas fa-volume-up"></i> Tin nhắn audio`;
    }
    return {
      ...message,
      external_message: StringHandler.parseUrlInString(message.external_message),
    };
  }

  static mapFbComment(comment: FbComment): FbComment {
    return {
      ...comment,
      external_message: StringHandler.parseUrlInString(comment.external_message),
    };
  }

  getCustomerConversations(query: Partial<ConversationAPI.GetCustomerConversationsRequest>) {
    return this.http.post(
      `api/fabo.CustomerConversation/ListCustomerConversations`,
      query,
      null
    )
      .toPromise()
      .then(res => {
        res.fb_customer_conversations = res.fb_customer_conversations
          .map(conv => ConversationApi.mapCustomerConversation(conv));
        return res;
      });
  }

  getCustomerConversationById(id) {
    return this.http
      .post(`api/fabo.CustomerConversation/GetCustomerConversationByID`, { id })
      .toPromise()
      .then(res => ConversationApi.mapCustomerConversation(res.conversation));
  }

  listMessages(query: Partial<ConversationAPI.ListMessagesRequest>) {
    return this.http.post(
      `api/fabo.CustomerConversation/ListMessages`,
      query,
      null
    ).toPromise()
      .then(res => {
        res.fb_messages = res.fb_messages.map(mess => ConversationApi.mapFbMessage(mess));
        return res;
      });
  }

  searchCustomerConversation(text: string) {
    return this.http.post(
      `api/fabo.CustomerConversation/SearchCustomerConversations`,
      {text},
      null
    ).toPromise().then(res =>
      res.fb_customer_conversations = res.fb_customer_conversations.map(conv => ConversationApi.mapCustomerConversation(conv)));
  }

  listComments(query: Partial<ConversationAPI.ListCommentsRequest>) {
    return this.http.post(
      `api/fabo.CustomerConversation/ListCommentsByExternalPostID`,
      query,
      null
    ).toPromise()
      .then(res => {
        res.fb_comments.data = res.fb_comments.data.map(comm => ConversationApi.mapFbComment(comm));
        return res;
      });
  }

  updateReadStatus(customer_conversation_id: string, read: boolean) {
    return this.http.post(
      `api/fabo.CustomerConversation/UpdateReadStatus`,
      {customer_conversation_id, read},
      null
    ).toPromise();
  }

  sendComment(body: Partial<ConversationAPI.SendFbCommentRequest>) {
    return this.http.post(
      `api/fabo.CustomerConversation/SendComment`,
      body,
      null
    ).toPromise()
      .then(res => ConversationApi.mapFbComment(res));
  }

  sendMessage(body: Partial<ConversationAPI.SendFbMessageRequest>) {
    return this.http.post(
      `api/fabo.CustomerConversation/SendMessage`,
      body,
      null
    ).toPromise()
      .then(res => ConversationApi.mapFbMessage(res));
  }

  createPost(body: Partial<ConversationAPI.CreatePostRequest>){
    return this.http.post(
      `api/fabo.CustomerConversation/CreatePost`,
      body,
      null
    ).toPromise()
  }

  searchCustomerConversations(text) {
    return this.http.post(`api/fabo.CustomerConversation/SearchCustomerConversations`, { text })
      .toPromise()
      .then(res => res.fb_customer_conversations)
  }

  hideOrUnHideComment(external_page_id,external_comment_id, action: 'hide' | 'unhide') {
    return this.http.post(`api/fabo.CustomerConversation/HideOrUnHideComment`, { external_page_id,external_comment_id,action})
    .toPromise()
    .then(res => res.action);
  }

  likeOrUnLikeComment(external_page_id,external_comment_id, action:'like' | 'unlike') {
    return this.http.post(`api/fabo.CustomerConversation/LikeOrUnLikeComment`, { external_page_id,external_comment_id, action})
    .toPromise()
    .then(res => res.action);
  }

  sendPrivateReply(external_page_id,external_comment_id, message) {
    return this.http.post(`api/fabo.CustomerConversation/SendPrivateReply`, { external_page_id,external_comment_id, message})
    .toPromise()
    .then(res => res.action);
  }
}

export namespace ConversationAPI {
  export interface GetCustomerConversationsRequest {
    filter: GetCustomerConversationsFilter;
    paging: {
      after?: string;
      before?: string;
      limit: number;
      sort?: string;
    };
  }

  export class GetCustomerConversationsFilter {
    external_user_id?: string;
    external_page_id?: string;
    is_read?: any;
    type?: 'unknown' | 'message' | 'comment' | 'all';
  }

  export interface ListMessagesRequest {
    filter: {
      external_conversation_id: string;
    };
    paging: {
      after?: string;
      before?: string;
      limit: number,
      sort?: string;
    };
  }

  export interface ListCommentsRequest {
    filter: {
      external_post_id: string;
      external_user_id?: string;
    };
    paging: {
      after?: string;
      before?: string;
      limit: number;
      sort?: string;
    };
  }

  export interface SendFbCommentRequest {
    external_page_id: string;
    external_post_id: string;
    external_id: string;
    message: string;
    attachment_url?: string;
  }

  export interface SendFbMessageRequest {
    external_page_id: string;
    external_conversation_id: string;
    message: {
      text?: string;
      type: string;
      url?: string;
    }
  }

  export  interface CreatePostRequest {
    external_page_id: string;
    message: string;
  }

}
