import {Injectable} from '@angular/core';
import {EntityState, StoreConfig, EntityStore} from '@datorama/akita';
import {TicketLabel} from "@etop/models";
import {ShopTicketAPI} from "@etop/api";

export interface StateTicketLabel extends EntityState<TicketLabel, string> {
  ui: {
    filter: ShopTicketAPI.GetTicketLabelsFilter
  }

  ticketProductLabels: TicketLabel[];
  activeProductLabel: TicketLabel;

  ticketSubjectLabels: TicketLabel[];
  activeSubjectLabel: TicketLabel;

  ticketDetailLabels: TicketLabel[];
}

const initialState = {
  ui: {
    filter: {}
  },

  ticketProductLabels: [],
  activeProductLabel: null,

  ticketSubjectLabels: [],
  activeSubjectLabel: null,

  ticketDetailLabels: [],
};

@Injectable({
  providedIn: 'root'
})
@StoreConfig({name: 'shopTicketLabel'})
export class TicketLabelStore extends EntityStore<StateTicketLabel> {
  constructor() {
    super(initialState);
  }
}
