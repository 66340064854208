import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EtopMaterialModule, MaterialModule } from '../etop-material';
import { FilterComponent } from './filter/filter.component';
import { FilterInputComponent } from './filter-input/filter-input.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { EtopPipesModule } from 'libs/shared/pipes/etop-pipes.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    EtopPipesModule,
    EtopMaterialModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  declarations: [FilterComponent, FilterInputComponent, FilterSelectComponent],
  exports: [FilterComponent, FilterInputComponent, FilterSelectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EtopFilterModule {}
