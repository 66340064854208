import { Injectable } from '@angular/core';
import { ConversationsStore } from 'libs/state/fabo/conversation/conversation.store';
import { ConversationAPI, ConversationApi, CustomerApi } from '@etop/api';
import { ConversationsQuery } from 'libs/state/fabo/conversation/conversation.query';
import { FbPagesQuery } from 'libs/state/fabo/page/page.query';
import { CustomerFaboApi } from '@etop/api/fabo-api/customer-api.service';
import { AuthenticateStore } from '@etop/core';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { EventBus, HttpService } from '@etop/common';
import { ImageCompress, ImageCompressor, trimUndefined } from '@etop/utils';
import { UtilService } from 'apps/core/src/services/util.service';
import { MobileUploader } from '@etop/ionic/features/uploader/MobileUploader';
import { arrayRemove, arrayUpsert } from '@datorama/akita';
import {
  CursorPaging,
  CustomerConversation,
  FbPage,
  FbUser,
  Message,
  MessageMedia,
  Messages
} from '@etop/models/faboshop';
import { Customer, CustomerAddress, CustomerCreateData, CustomerEvent, CustomerUpdateData } from '@etop/models';
import { FacebookUserTagQuery, FacebookUserTagService } from '@etop/state/fabo/facebook-user-tag';
import SendFbMessageRequest = ConversationAPI.SendFbMessageRequest;
import SendFbCommentRequest = ConversationAPI.SendFbCommentRequest;
import { RelationshipQuery } from '@etop/state/relationship/relationship.query';

const initialState = {
  ui: { filter: new ConversationAPI.GetCustomerConversationsFilter() },
  conversationPaging: {
    after: '.',
    limit: 10,
    sort: '-last_message_at'
  },
  latestCustomerMessage: null,
  needMessageScroll: false,
  activeConversationPost: null,
  activeFbUser: null,
  customerAddress: null
};

@Injectable({
  providedIn: 'root'
})

export class ConversationsService {
  sseListener: Subscription;

  constructor(
    private util: UtilService,
    private imageCompressor: ImageCompressor,
    private uploader: MobileUploader,
    private conversationStore: ConversationsStore,
    private conversationApi: ConversationApi,
    private facebookUserTagQuery: FacebookUserTagQuery,
    private facebookUserTagService: FacebookUserTagService,
    private conversationsQuery: ConversationsQuery,
    private customerApi: CustomerFaboApi,
    private customerService: CustomerApi,
    private fbPagesQuery: FbPagesQuery,
    private http: HttpService,
    private auth: AuthenticateStore,
    private eventBus: EventBus,
    private fbUserTagQuery: FacebookUserTagQuery,
    private relationshipsQuery: RelationshipQuery
  ) {
    this.auth.state$.pipe(map(state => state.isAuthenticated), distinctUntilChanged()).subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.sseListener = this.listenEventStream();
        this.setConversations([]);
      } else if (this.sseListener) {
        this.sseListener.unsubscribe();
        this.sseListener = null;
      }
    });
    this.eventBus.on<CustomerUpdateData>(CustomerEvent.UPDATE).subscribe((data) => {
      this.fetchFbUser(data.conversation, true).then();
    });
    this.eventBus.on<CustomerCreateData>(CustomerEvent.CREATE).subscribe(async (data) => {
      const res = await this.createFbUserCustomer(
        data.customer.id,
        data.conversation.external_user_id
      );
      this.setActiveFbUser(data.conversation.id, res);
      this.fetchFbUser(data.conversation, true).then();
    });
    this.eventBus.on<CustomerUpdateData>(CustomerEvent.DELETE).subscribe(async (data) => {
      await this.fetchFbUser(data.conversation, true);
      this.setCustomerAddress(data.conversation.id, null);
    });
  }

  setActiveConversationPost(conversationId, activeConversationPost) {
    this.conversationStore.update(conversationId, { fb_post: activeConversationPost });
  }

  setFilter(filter: ConversationAPI.GetCustomerConversationsFilter) {
    this.conversationStore.update({ ui: { filter: filter } });
    this.setConversationPaging(initialState.conversationPaging);
  }

  setConversations(conversations) {
    this.conversationStore.set(conversations);
  }

  resetData() {
    this.conversationStore.update(initialState);
  }

  updateTagsInConversation(conversation: CustomerConversation, tag_ids: string[]) {
    let _conersation = JSON.parse(JSON.stringify(conversation));
    _conersation.external_user_tags = tag_ids;
    const tags = this.facebookUserTagQuery.getAll();
    _conersation.tags = tags.filter(tag => {
      if (tag && tag_ids.includes(tag.id)) {
        return tag;
      }
    });
    this.conversationStore.update(_conersation.id, _conersation);
  }

  appendConversations(conversations) {
    this.conversationStore.upsertMany(conversations);
  }

  setActiveConversation(conversation: CustomerConversation) {
    this.conversationStore.setActive(conversation?.id);
  }

  setConversationPaging(paging: CursorPaging) {
    this.conversationStore.update({ conversationPaging: paging });
  }

  async resetConversation() {
    this.resetData();
  }

  async loadMoreConversations(reset = false, mobile?: boolean): Promise<CursorPaging> {
    let cursorPaging = (!reset && this.conversationsQuery.getValue().conversationPaging) || {
      next: '.',
      prev: '.',
      limit: 10
    };
    let conversationPaging: any = {
      after: cursorPaging.next,
      limit: cursorPaging.limit,
      sort: '-last_message_at'
    };
    const newPaging = await this.fetchConversation(conversationPaging);
    if (newPaging.next) {
      this.setConversationPaging({
        ...conversationPaging,
        next: newPaging.next
      });
    }
    if (reset && !mobile) {
      this.selectConversation();
    }
    return newPaging;
  }

  async preloadConversations(reinitConversation?: boolean) {
    if (!this.conversationsQuery.getCount() && !reinitConversation) {
      this.conversationStore.setLoading(true);
    }
    let cursorPaging = this.conversationsQuery.getValue().conversationPaging || {
      next: '.',
      prev: '.',
      limit: 10
    };
    let conversationPaging: any = {
      after: cursorPaging.prev,
      limit: cursorPaging.limit,
      sort: '-last_message_at'
    };
    const newPaging = await this.fetchConversation(conversationPaging,reinitConversation);
    if (newPaging.prev) {
      this.setConversationPaging({
        ...cursorPaging,
        ...newPaging,
        prev: newPaging.prev
      });
    }
    this.conversationStore.setLoading(false);
  }

  async fetchConversation(paging: CursorPaging,reinitConversation?: boolean): Promise<CursorPaging> {
    await this.facebookUserTagService.listTags();
    let filter = this.conversationsQuery.getValue().ui.filter;
    const pages = this.fbPagesQuery.getAll();
    if (pages.length == 0) {
      return null;
    }
    const res = await this.conversationApi.getCustomerConversations({
      filter,
      paging: paging
    });

    let conversations = res.fb_customer_conversations.map(conversation => {
      conversation.paging = {
        next: '.',
        prev: '.',
        limit: 30,
        sort: '-external_created_time'
      };
      const tags = this.mapTags(conversation.external_user_tags);
      conversation.tags = tags;
      return conversation;
    });
    conversations.map(conversation => {
      const external_user_tags = conversation.external_user_tags || [];
      const listTag = this.facebookUserTagQuery.getAll();
      conversation.tags = listTag.filter(tag => {
        if (external_user_tags.includes(tag.id)) {
          return tag;
        }
      });
    });
    if (reinitConversation) {
      this.conversationStore.set(conversations)
    } else {
      this.appendConversations(conversations);
    }
    if (res.paging.next) {
      this.setConversationPaging({
        ...res.paging,
        next: res.paging.next
      });
    }
    return res.paging;
  }

  async selectConversation(conversation?: CustomerConversation) {
    conversation = conversation || this.conversationsQuery.getAll()[0];
    if (!conversation) {
      return;
    }
    this.setActiveConversation(conversation);
    if (!this.conversationsQuery.getEntity(conversation.id)?.fbUsers?.length) {
      await this.fetchFbUser(conversation);
    }
    const hasMessages = this.conversationsQuery.getEntity(conversation.id)?.messages?.length;
    if (!hasMessages) {
      await this.preloadMessages(conversation);
    } else {
      this.setNeedMessageScroll(true);
    }
    if (!conversation.is_read) {
      this.updateReadStatus(conversation);
    }
  }

  async searchCustomerConversation(text: string) {
   const res =  await this.conversationApi.searchCustomerConversation(text);
    const listTag = this.facebookUserTagQuery.getAll();
    res.map(conversation => {
     conversation.tags = listTag.filter(tag => {
       if (conversation?.external_user_tags.includes(tag.id)) {
         return tag;
       }
     });
   });
   return res;
  }


  async searchConversation(conversation, close: boolean) {
    if (close) {
      this.conversationStore.update({ searchingConverastions: null });
      await this.resetConversation();
      await this.preloadConversations(close);
      const conversations = this.conversationsQuery.getAll();
      if (conversations) {
        this.selectConversation(conversations[0]);
      }
      return;
    }
    const searchingConverastions = JSON.parse(JSON.stringify(this.conversationsQuery.getValue().searchingConverastions));
    if (searchingConverastions) {
      searchingConverastions.unshift(conversation.external_id + conversation.id);
      this.conversationStore.update({ searchingConverastions });
    } else {
      this.conversationStore.update({ searchingConverastions: [conversation.external_id + conversation.id] });
    }
  }

  async updateReadStatus(conversation) {
    if (!conversation?.is_read) {
      await this.conversationApi.updateReadStatus(conversation.id, true);
      this.conversationStore.update(conversation.id, { is_read: true });
      this.appendConversations([this.conversationsQuery.getEntity(conversation?.id)]);
    }
  }

  /**
   * message funtions
   **/

  preloadConversationsMessages() {
    const conversations = this.conversationsQuery.getAll();
    conversations.forEach(async conversation => {
      const hasMessages = this.conversationsQuery.getEntity(conversation.id).messages?.length;
      if (!hasMessages) {
        await this.loadMoreMessages(conversation);
      }
    });
  }

  async loadMoreMessages(conversation: CustomerConversation) {
    let paging = conversation.paging;
    let messagePaging: any = {
      after: paging?.prev,
      limit: paging?.limit || 10,
      sort: '-external_created_time'
    };
    const newPaging = await this.fetchMessages(conversation, messagePaging);
    if (newPaging?.prev) {
      this.conversationStore.update(conversation.id, {
        paging: {
          ...paging,
          prev: newPaging.next
        }
      });
    }
  }

  async preloadMessages(conversation: CustomerConversation) {
    let cursorPaging = conversation.paging || {
      next: '.',
      prev: '.',
      limit: 10
    };
    let messagePaging: any = {
      after: cursorPaging.prev,
      limit: cursorPaging.limit,
      sort: '-external_created_time'
    };
    const newPaging = await this.fetchMessages(conversation, messagePaging);
    this.setNeedMessageScroll(true);
    if (newPaging?.prev) {
      this.conversationStore.update(conversation.id, {
        paging: {
          ...cursorPaging,
          prev: newPaging.next
        }
      });
    }
  }

  async fetchMessages(conversation: CustomerConversation, paging: CursorPaging): Promise<CursorPaging> {
    try {
      let messages = [];
      let post = null;
      let latestCustomerMessage = null;
      let resPaging = null;
      this.conversationStore.ui.update(conversation.id, { isLoadingMess: true });
      if (conversation.type == 'message') {
        const fbMessages = await this.conversationApi.listMessages({
          filter: { external_conversation_id: conversation.external_id },
          paging
        });
        messages = fbMessages.fb_messages.map(fbMessage => {
          return {
            ...Message.fromFbMessage(fbMessage),
            created_by: this.relationshipsQuery.getRelationshipNameById(fbMessage?.created_by)
          }
        }
          );
        resPaging = fbMessages.paging;
      } else {
        const fbComments = await this.conversationApi.listComments({
          filter: {
            external_post_id: conversation.external_id,
            external_user_id: conversation.external_user_id
          },
          paging
        });
        messages = fbComments.fb_comments.data.map(fbComment => {
          return {
            ...Message.fromFbComment(fbComment),
            created_by: this.relationshipsQuery.getRelationshipNameById(fbComment?.created_by)
          }
        });
        post = fbComments.fb_post;
        latestCustomerMessage = Message.fromFbComment(fbComments.latest_customer_fb_external_comment);
        resPaging = fbComments.fb_comments.paging;
      }
      if (resPaging.next) {
        let _paging = { ...conversation.paging, after: resPaging.next };
        this.conversationStore.update(conversation.id, { paging: _paging });
        if (conversation?.paging?.before) {
          delete conversation.paging.before;
        }
      }
      this.setActiveConversationPost(conversation.id, post);
      this.setLatestCustomerMessage(conversation.id, latestCustomerMessage);
      this.conversationStore.ui.update(conversation.id, { isLoadingMess: false });
      this.mergeMessages(messages, conversation.id);
      return resPaging;
    } catch (e) {
      this.conversationStore.setLoading(false);
      debug.log('ERROR in fetchMessages', e)
    }


  }

  sendMessageWithText(text: string) {
    const newMessage = {
      ...new Message(),
      id: new Date().getTime().toString(),
      message: text,
      medias: [],
      external_created_time: new Date().toISOString(),
    };
    return this.sendMessage(newMessage, this.conversationsQuery.getActive());
  }

  sendMessageWithImageWeb(preUploadState: any, messageId?: string) {
    this.util.fileToImage(preUploadState).then(async (image: any) => {
      const message: Message = {
        ...new Message(),
        id: messageId || new Date().getTime().toString(),
        message: '',
        medias: [{
          ...new MessageMedia,
          preview_url: image.src
        }],
        external_created_time: new Date().toISOString(),
        status: 'sending'
      };
      this.mergeMessages([message], this.conversationsQuery.getActive().id);
      this.setNeedMessageScroll(true);

      try {
        const res = await this.util.uploadImages([preUploadState], 1024);
        const newMessage = {
          ...message,
          medias: [{
            ...new MessageMedia,
            preview_url: res[0].url
          }],
          created_by: this.relationshipsQuery.getRelationshipNameById(message?.created_by)
        };
        this.sendMessage(newMessage, this.conversationsQuery.getActive()).then();
      } catch (e) {
        const newMessage: Message = {
          ...message,
          status: 'error'
        };
        this.mergeMessages([newMessage], this.conversationsQuery.getActive().id);
        this.setNeedMessageScroll(true);
      }
    });
  }

  sendMessageWithImageNative(preUploadState: any, messageId?: string) {
    const message: Message = {
      ...new Message(),
      id: messageId || new Date().getTime().toString(),
      message: '',
      medias: [{
        ...new MessageMedia,
        preview_url: preUploadState
      }],
      external_created_time: new Date().toISOString(),
      status: 'sending'
    };
    this.mergeMessages([message], this.conversationsQuery.getActive().id);
    this.setNeedMessageScroll(true);

    ImageCompress.decodeImage(preUploadState).then(async (decode) => {
      const limitSize = 1024 * 1024;
      const scaleRatio = (limitSize * 100) / (decode.height * decode.width);
      const compressedDataUrl = await this.imageCompressor.compressFile(
        preUploadState,
        decode.orientation,
        scaleRatio,
        100
      );

      const uploaded = await this.uploader.uploadBase64Image(
        compressedDataUrl.split(',')[1]
      );
      const newMessage = {
        ...message,
        medias: [{
          ...new MessageMedia,
          preview_url: uploaded?.result[0]?.url
        }]
      };

      this.sendMessage(newMessage, this.conversationsQuery.getActive()).then();
    });
  }

  async sendMessage(message: Message, conversation: CustomerConversation) {
    try {
      message = {
        ...message,
        status: 'sending',
        created_by: this.relationshipsQuery.getRelationshipNameById(message.created_by)
      };
      this.mergeMessages([message], conversation.id);
      this.setNeedMessageScroll(true);

      const latestMessage = this.conversationsQuery.getEntity(conversation.id).messages[0];
      const messageId = message.id;

      if (conversation.type == 'message') {
        const { external_page_id, external_conversation_id } = latestMessage;
        const body: SendFbMessageRequest = {
          external_page_id, external_conversation_id,
          message: {
            text: message.message,
            type: 'text',
          }
        };
        if (message?.medias[0]?.preview_url) {
          body.message.url = message?.medias[0]?.preview_url;
          body.message.type = 'image';
          delete body.message.text;
        }

        const newMessage = await this.conversationApi.sendMessage(body).then(msg => Message.fromFbMessage(msg));
        Object.assign(message, trimUndefined(newMessage));
      } else {
        const { external_id } = this.conversationsQuery.getEntity(conversation.id).lastest_message;
        const { external_page_id, external_post_id } = latestMessage;

        const body: SendFbCommentRequest = {
          external_id, external_page_id, external_post_id,
          message: message.message
        };
        if (message?.medias[0]?.preview_url) {
          body.attachment_url = message?.medias[0]?.preview_url;
          delete body.message;
        }

        const newMessage = await this.conversationApi.sendComment(body).then(cmt => Message.fromFbComment(cmt));
        Object.assign(message, trimUndefined(newMessage));
      }

      message = {
        ...message,
        status: 'sent',
        created_by: this.relationshipsQuery.getRelationshipNameById(message.created_by)
      };

      let messages = this.conversationsQuery.getEntity(conversation.id).messages.slice(0);
      messages = arrayRemove(messages, messageId);
      this.conversationStore.update(conversation.id, { messages: messages });
      this.mergeMessages([message], conversation.id);
      setTimeout(() => {
        message.status = undefined;
        this.mergeMessages([message], conversation.id);
      }, 2000);
    } catch (e) {
      message = {
        ...message,
        status: 'error'
      };
      this.mergeMessages([message], conversation.id);
      throw e;
    }


  }

  mergeMessages(messages: Messages, conversationID: string) {
    let pMessages = this.conversationsQuery.getEntity(conversationID).messages?.slice(0) || [];
    messages.forEach(message => pMessages = arrayUpsert(pMessages, message.id, message));
    pMessages.sort((a, b) => new Date(a.external_created_time).getTime() - new Date(b.external_created_time).getTime());
    this.conversationStore.update(conversationID, { messages: pMessages });
  }

  setNeedMessageScroll(needMessageScroll: boolean) {
    this.conversationStore.update({ needMessageScroll: needMessageScroll });
  }

  setLatestCustomerMessage(conversationId, message: Message) {
    if (!message) {
      return;
    }
    this.conversationStore.update(conversationId, { lastest_message: {...message, created_by: this.relationshipsQuery.getRelationshipNameById(message.created_by)} });
  }

  /**
   * User funtions
   **/

  async fetchFbUser(conversation: CustomerConversation, force = false) {
    try {
      if (!conversation) {
        return;
      }
      const fbUsers = conversation.fbUsers || [];
      let customer = conversation?.customer;
      if (!fbUsers.length || force) {
        const res = await this.customerApi.getFbUser(conversation.external_user_id);
        customer = res.customer;
        await this.setActiveFbUser(conversation.id, res);
      }

      if (customer) {
        this.setConversationCustomer(conversation.id, customer);
        let addresses = await this.customerService.getCustomerAddresses(customer.id);
        if (addresses) {
          this.setCustomerAddress(conversation.id, addresses[0]);
          this.setCustomerAddresses(conversation.id, addresses);
        }
      } else {
        this.setConversationCustomer(conversation.id, null);
      }
    } catch (e) {
      debug.error('ERROR fetchFbUser', e);
      throw e;
    }
  }

  async getFbUser(conversationId) {
    return await this.customerApi.getFbUser(conversationId);
  }

  setActiveFbUser(conversationId, activeFbUser: FbUser) {
    let customer: Customer = this.conversationsQuery.getEntity(conversationId)?.customer;
    if (!customer) {
      customer = activeFbUser?.customer;
    }
    this.conversationStore.update(conversationId, {
      customer: customer,
      fbUsers: [activeFbUser]
    });
  }

  resetActiveFbUser() {
    this.conversationStore.setActive(null);
  }

  async createFbUserCustomer(customer_id, external_id) {
    const res = await this.customerApi.createFbUserCustomer(customer_id, external_id)
    this.updateCustomerByFbUser(res.customer, external_id);
    return res;
  }

  updateCustomerByFbUser(customer, external_user_id) {
    const consversations: any = this.conversationsQuery.getAll({ filterBy: conv => conv.external_user_id == external_user_id});
    if (consversations) {
      consversations.forEach(conv => {
        this.conversationStore.update(conv.id, {
          customer
        });
      })
    }
  }

  setConversationCustomer(conversationId, customer: Customer) {
    this.conversationStore.update(conversationId, {
      customer: customer
    });
  }

  setCustomerAddress(conversationId, customerAddress: CustomerAddress) {
    this.conversationStore.update(conversationId, {
      customer: {
        ...this.conversationsQuery.getEntity(conversationId)?.customer,
        address: customerAddress
      }
    });
  }

  setCustomerAddresses(conversationId, customerAddress: CustomerAddress[]) {
    this.conversationStore.update(conversationId, {
      customer: {
        ...this.conversationsQuery.getEntity(conversationId)?.customer,
        addresses: customerAddress
      }
    });
  }

  setTagsConversation(conversationId, external_user_tags) {
    this.conversationStore.update(conversationId, {
      tags: this.mapTags(external_user_tags),
      external_user_tags
    });
  }

  setTagsConversationsByFbUser(external_user_id, external_user_tags) {
    const consversations: any = this.conversationsQuery.getAll({ filterBy: conv => conv.external_user_id == external_user_id});
    if (consversations) {
      consversations.forEach(conv => {
        this.conversationStore.update(conv.id, {
          tags: this.mapTags(external_user_tags),
          external_user_tags
        });
      })
    }
  }

  mapTags(tag_ids) {
    const _tags = this.fbUserTagQuery.getAll();
    let tags = [];
    if (tag_ids) {
      _tags.forEach(tag => {
        if (tag_ids.includes(tag.id)) {
          tags.push(tag);
        }
      });
    }
    return tags;
  }

  listenEventStream() {
    return this.http.listenSSE(`api/event-stream?__token=${this.auth.snapshot.token}`, ['fabo/customer_conversation/insert', 'fabo/customer_conversation/update', 'fabo/comment/insert', 'fabo/comment/update', 'fabo/message/insert', 'fabo/message/update'])
      .subscribe(async data => {
        switch (data.event) {
          case 'fabo/customer_conversation/insert':
          case 'fabo/customer_conversation/update':
            const currentType = this.conversationsQuery.getValue()?.ui.filter?.type;
            const conversation = ConversationApi.mapCustomerConversation(data.data);
            const activeConversation = this.conversationsQuery.getActive();
            const currentFilter = this.conversationsQuery.getValue().ui.filter;
            if (!conversation?.is_read
              && conversation?.id == activeConversation?.id
              && activeConversation?.external_page_id == activeConversation?.external_from?.id
            ) {
              this.conversationApi.updateReadStatus(conversation.id, true).then();
              conversation.is_read = true;
            }
            if (!currentType || currentType == 'all' || currentType == 'unknown'
              || (conversation.type === currentFilter.type && (!currentFilter.external_page_id || conversation?.external_page_id == currentFilter.external_page_id))) {
              conversation.fbUsers = activeConversation?.fbUsers;
              conversation.customer = activeConversation?.customer;
              this.appendConversations([conversation]);
            }
            if(data.data.type == 'comment') {
              let paging = conversation.paging;
              const fbComments = await this.conversationApi.listComments({
                filter: {
                  external_post_id: conversation.external_id,
                  external_user_id: conversation.external_user_id
                },
                paging
              });
              this.setActiveConversationPost(conversation.id, fbComments.fb_post);
            }
            break;
          case 'fabo/comment/insert':
          case 'fabo/comment/update':
            const comment = Message.fromFbComment(ConversationApi.mapFbComment(data.data));
            const commentConv = this.conversationsQuery.getAll().find((
              { external_id, external_user_id }) =>
              comment.external_post_id == external_id &&
              (comment.external_user_id == external_user_id ||
                (comment.external_user_id == comment.external_page_id &&
                  comment.external_parent_user_id == external_user_id)));
            if (commentConv) {
              this.mergeMessages([comment], commentConv.id);
              if (comment.from.id != comment.external_page_id) {
                this.setLatestCustomerMessage(commentConv.id, comment);
              }
            }
            break;
          case 'fabo/message/insert':
          case 'fabo/message/update':
            const message = Message.fromFbMessage(ConversationApi.mapFbMessage(data.data));
            const messageConv = this.conversationsQuery.getAll().find(({ external_id }) =>
            message.external_conversation_id == external_id);
            if (messageConv) {
              this.mergeMessages([{...message, created_by: this.relationshipsQuery.getRelationshipNameById(message.created_by)}], messageConv.id);
            }
            break;
        }
      });
  }

  async getCustomerConversationByID(id) {
    return await this.conversationApi.getCustomerConversationById(id);
  }

  async getCustomerConversationByNotification(id){
    let conversation = this.conversationsQuery.getEntity(id)
    if (!conversation) {
       conversation = await this.getCustomerConversationByID(id)
    }
    await this.selectConversation(conversation)
  }

  async searchCustomerConversations(text) {
    return await this.conversationApi.searchCustomerConversations(text);
  }

  updateSearchConversation(conversation: CustomerConversation) {
    if (!conversation) {
      const _search = this.conversationStore.getValue().searchConversation;
      this.conversationStore.remove(_search.id)
    }
    this.conversationStore.update({ searchConversation: conversation });
  }

  removeConversationOfPage(page: FbPage) {
    const conversations = this.conversationsQuery.getAll()
    const removeConversation = conversations.filter(conversation => {
      if (page.external_id == conversation.external_page_id) {
        return conversation
      }
    }).map(conv => conv.id)
    if (removeConversation) {
      this.conversationStore.remove(removeConversation)
    }
  }

  updateSearchConversations(conversations: CustomerConversation[], key) {
    this.conversationStore.update({ searchConversations: conversations, searchKey: key });
  }

  updateLikeCommentAction(message: Message) {
    const {external_page_id,external_id, is_liked} = message;
    this.conversationApi.likeOrUnLikeComment(external_page_id,external_id, is_liked? 'like' : 'unlike')
  }

  updateHideCommentAction(message: Message) {
    const {external_page_id,external_id, is_hidden} = message;
    this.conversationApi.hideOrUnHideComment(external_page_id,external_id, is_hidden? 'hide' : 'unhide')
  }

  async sendPrivateReply(message: Message, text: string) {
    const {external_page_id,external_id} = message;
    try {
      await this.conversationApi.sendPrivateReply(external_page_id,external_id, text);
    } catch(e) {
      throw (e);
    }
  }

  selectConversationWithFbUser(external_user_id) {
    const conversation = this.conversationsQuery.getAll().filter(
      (conv) => conv.external_user_id == external_user_id
      && conv.type == 'message');
    if (conversation) {
      this.selectConversation(conversation[0]);
    }
  }

  async suggestPhoneFromMessage(limit) {
    const cursorPaging = {
      next: '.',
      prev: '.',
      limit: limit
    };
    try{
      await this.fetchMessages(this.conversationsQuery.getActive(),cursorPaging);
      const messages = this.conversationsQuery.getActive().messages.filter(message => message.from && (message.external_page_id != message.from.id));
      const length = messages.length
      for (let i = length-1; i >=0 ; i--) {
        const reg = /\b(8\s4|84)?[0]?(\s*[235789]{1})(([-\.]*\s*[0-9]{1}){8})\b/;
        const phones = messages[i].message.match(reg);
        if(phones) {
          let phone = phones[0];
          phone = phone.replace(/\./gi,'');
          phone = phone.replace(/[-]/gi,'');
          phone = phone.replace(/\s/gi,'');
          if(phone.indexOf('84') == 0 && phone.length > 9){
            phone = phone.replace('84','');
          }
          if(phone.length == 9){
            phone = '0' + phone;
          }
          return phone;
        }
      }
      return '';
    } catch(e) {
      return '';
    }
  }

  async getMessageByConversation(conversation_id, type) {
    try {
      let res: any;
      let paging = {
        after: '.',
        limit: 100,
      };
      const conversation = this.conversationsQuery.getAll().find(conv => conv.id == conversation_id)
      if (type == 'message') {
        const msg = await this.conversationApi.listMessages({
          filter: { external_conversation_id: conversation.external_id },
          paging
        })
        res = msg.fb_messages
      } else {
        const comments = await this.conversationApi.listComments({
          filter: {
            external_post_id: conversation.external_id,
            external_user_id: conversation.external_user_id
          },
          paging
        })
        res = comments.fb_comments.data
      }
      return res.filter(msg => msg.external_message)
    } catch (e) {
      debug.log('ERROR in getMessageByConversation', e)
    }
  }
}
