import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from 'apps/etelecom-cs/src/app/app.module';
import { hmrBootstrap } from 'apps/core/src/hmr';
import * as debug from '@etop/utils/debug';

fetch('/assets/configs/appConfigs.json')
  .then((res) => res.json())
  .then((environment) => {
    debug.setupDebug(environment);

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${environment.recaptcha_key}`;
    document.getElementsByTagName('head')[0].appendChild(script);

    if (!!environment?.is_production) {
      enableProdMode();
    }

    const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule);

    if (environment.hmr) {
      if (module['hot']) {
        hmrBootstrap(module, bootstrap);
      } else {
        // eslint-disable-next-line no-console
        console.error('HMR is not enabled for webpack-dev-server!');
        // eslint-disable-next-line no-console
        console.log('Are you using the --hmr flag for ng serve?');
      }
    } else {
      // eslint-disable-next-line no-console
      bootstrap().catch((err) => console.log(err));
    }
  });
