import {Injectable} from '@angular/core';
import {AdminConnectionApi, ConnectionAPI, ConnectionApi} from "@etop/api";
import {Connection, ConnectionMethod, ShopConnection} from "libs/models/Connection";
import {ConnectionStore} from "@etop/features/connection/connection.store";
import {StringHandler} from "@etop/utils";
import {AccountShipnowApi} from "@etop/api/shop/account-shipnow.api";
import {AccountShipnow} from "@etop/models";

export const DIRECT_CONNECTION_BIAS = {
  'jt': 1,
  'jt-test': 1,
  'jt-express': 1,
  'ghn': 2,
  'ghn-v2': 2,
  'vtp': 3,
  'ghtk': 4
}

export const BUILTIN_CONNECTION_BIAS = {
  'topship-jt': 1,
  'topship-ghn': 2,
  'topship-ghn-v2': 2,
  'topship-vtp': 3,
  'topship-ghtk': 4
}

@Injectable({
  providedIn: "root"
})
export class ConnectionService {

  constructor(
    private connectionApi: ConnectionApi,
    private adminConnectionApi: AdminConnectionApi,
    private accountShipnowApi: AccountShipnowApi,
    private connectionStore: ConnectionStore,
  ) {
  }

  static filterConnections(
    connectionsList: Connection[],
    isBuiltin = true,
    isDirect = false,
    connection_ids?: string[]
  ) {
    const _builtins = connectionsList.filter(conn => conn.connection_method == 'builtin');
    const _directs = connectionsList.filter(conn => conn.connection_method == 'direct');

    let _connectionsList = [];

    if (isBuiltin) {
      const validIDs = connection_ids?.some(id => _builtins.map(c => c.id)?.includes(id));

      _connectionsList = _connectionsList.concat(_builtins.filter(b => {
        if (validIDs) {
          return connection_ids.includes(b.id);
        }
        return b;
      }));
    }

    if (isDirect) {
      const validIDs = connection_ids?.some(id => _directs.map(c => c.id)?.includes(id));

      _connectionsList = _connectionsList.concat(_directs.filter(c => {
        if (validIDs) {
          return connection_ids.includes(c.id) && c?.connect_status == 'P';
        }
        return c?.connect_status == 'P';
      }));
    }

    return _connectionsList;
  }

  /**
   * @deprecated use getAvailableConnections along with ConnectionStore instead
   */
  async getConnections() {
    const shopConnections: ShopConnection[] = await this.getLoggedInConnections();

    let connections: Connection[] = await this.getAvailableConnections();
    connections = connections.map(conn => {
      const connected = shopConnections.find(sc => sc.connection_id == conn.id);
      conn.connect_status = connected ? 'P' : 'Z';
      conn.connection_email = connected && connected.external_data && connected.external_data.email;
      return conn;
    });
    return connections.filter(conn => conn.connect_status == 'P');
  }

  async getValidConnections(all = false) {
    this.connectionStore.setLoadingConnections(true);
    try {
      const shopConnections: ShopConnection[] = await this.getLoggedInConnections();
      const shopConnectionIds = shopConnections.map(sc => sc.connection_id);

      let validConnections: Connection[] = await this.initConnections(false, all);
      validConnections = validConnections.filter(
        conn => (shopConnectionIds.includes(conn.id) || conn.connection_method == 'builtin') && conn.status == 'P'
      );

      this.connectionStore.setValidConnections(validConnections);
    } catch (e) {
      debug.error('ERROR in getValidConnections', e);
    }
    this.connectionStore.setLoadingConnections(false);
  }

  async initConnections(isAdmin = false, all = false) {
    let initConnections: Connection[] = [];
    if (isAdmin) {
      initConnections = await this.adminConnectionApi.getConnections();
    } else {
      const _shipments = await this.connectionApi.getConnections();
      const _shipnows = [];
      if (all) {
        _shipnows.push(...await this.connectionApi.getConnections('shipnow'));
      }
      initConnections = _shipments.concat(_shipnows);

      for (let conn of initConnections) {
        const loggedInConnections = this.connectionStore.snapshot.loggedInConnections;
        if (conn.connection_subtype == 'shipnow') {
          const identity = loggedInConnections
            .find(shopConn => shopConn.connection_id == conn.id);

          if (identity) {
            const accountShipnow: AccountShipnow = await this.accountShipnowApi.getAccountShipnow(conn.id, identity.external_data.identifier);
            conn.external_verified = accountShipnow.external_verified;
          }

        }
        const connected = loggedInConnections.find(sc => sc.connection_id == conn.id);
        conn.connect_status = connected ? 'P' : 'Z';
        conn.connection_email = connected && connected.external_data && connected.external_data.email;
        conn.connection_identifier = connected && connected.external_data && connected.external_data.identifier;
      }
    }

    const builtinConnections = initConnections.filter(conn => conn.connection_method == 'builtin');
    const directConnections = initConnections.filter(conn => conn.connection_method != 'builtin');

    directConnections.sort((a, b) => {
      const _currName = StringHandler.createHandle(a.name);
      const _nextName = StringHandler.createHandle(b.name);
      return (DIRECT_CONNECTION_BIAS[_currName] || 5) - (DIRECT_CONNECTION_BIAS[_nextName] || 5);
    });

    builtinConnections.sort((a, b) => {
      const _currName = StringHandler.createHandle(a.name);
      const _nextName = StringHandler.createHandle(b.name);
      return (BUILTIN_CONNECTION_BIAS[_currName] || 5) - (BUILTIN_CONNECTION_BIAS[_nextName] || 5);
    });

    if (isAdmin) {
      this.connectionStore.initAdminConnections(directConnections.concat(builtinConnections));
    } else {
      this.connectionStore.initConnections(directConnections.concat(builtinConnections));
    }
    return directConnections.concat(builtinConnections);
  }

  async getAvailableConnections(all = false) {
    const shopConnections: ShopConnection[] = await this.getLoggedInConnections();

    const shipmentConnections: Connection[] = await this.connectionApi.getAvailableConnections();
    const shipnowConnections: Connection[] = [];

    if (all) {
      shipnowConnections.push(...await this.connectionApi.getAvailableConnections('shipnow'));
    }

    const connections: Connection[] = shipmentConnections.concat(shipnowConnections).filter(conn => conn.status == 'P');
    for (let conn of connections) {
      if (conn.connection_subtype == 'shipnow') {
        const identity = this.connectionStore.snapshot.loggedInConnections
          .find(shopConn => shopConn.connection_id == conn.id);

        if (identity) {
          const accountShipnow: AccountShipnow = await this.accountShipnowApi.getAccountShipnow(conn.id, identity.external_data.identifier);
          conn.external_verified = accountShipnow.external_verified;
        }

      }
      const connected = shopConnections.find(sc => sc.connection_id == conn.id);
      conn.connect_status = connected ? 'P' : 'Z';
      conn.connection_email = connected && connected.external_data && connected.external_data.email;
      conn.connection_identifier = connected && connected.external_data && connected.external_data.identifier;
    }
    const availableConnections = connections.sort((a, b) => {
      const _currName = StringHandler.createHandle(a.name);
      const _nextName = StringHandler.createHandle(b.name);
      return (BUILTIN_CONNECTION_BIAS[_currName] || 5) - (BUILTIN_CONNECTION_BIAS[_nextName] || 5);
    });
    this.connectionStore.setAvailableConnections(availableConnections);
    return availableConnections;
  }

  async getLoggedInConnections() {
    const loggedInConnections = await this.connectionApi.getShopConnections();
    this.connectionStore.setLoggedInConnections(loggedInConnections);
    return loggedInConnections;
  }

  async loginConnection(loginInfo: ConnectionAPI.LoginShopConnectionRequest) {
    await this.connectionApi.loginShopConnection(loginInfo);
    this.getConnections().then();
  }

  async loginShopConnectionWithOTP(loginInfo: ConnectionAPI.LoginShopConnectionWithOTPRequest) {
    await this.connectionApi.loginShopConnectionWithOTP(loginInfo);
    this.getConnections().then();
  }

  async loginConnectionWithToken(loginInfo: ConnectionAPI.LoginShopConnectionByTokenRequest) {
    await this.connectionApi.updateShopConnection(loginInfo);
    this.getConnections().then();
  }

  async deleteShopConnection(conn) {
    await this.connectionApi.deleteShopConnection(conn.id);
    this.getConnections().then();
  }

  async registerShopConnection(body) {
    await this.connectionApi.registerShopConnection(body);
    this.getConnections().then();
  }

  async updateShopConnection(body) {
    await this.connectionApi.updateShopConnection(body);
    this.getConnections().then();
  }
}
