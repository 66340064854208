import { Shop } from '@etop/models';


export const INTERVAL_DISPLAY = {
  day: "ngày",
  month: "tháng",
  year: "năm",
};

export const PRODUCT_STATUS_DISPLAY = {
  "P": "Bật",
  "N": "Tắt",
  "Z": "Mới",
};

export const PLAN_STATUS_DISPLAY = {
  "P": "Bật",
  "N": "Tắt",
  "Z": "Mới",
};

export const PAYMENT_STATUS_DISPLAY = {
  "P": "Đã thanh toán",
  "Z": "Chưa thanh toán",
};

export class Subscription {
  account_id: string;
  billing_cycle_anchor_at: Date;
  cancel_at_period_end: true;
  created_at: Date;
  current_period_end_at: Date;
  current_period_start_at: Date;
  customer: {};
  id: string;
  lines: SubcriptionLine[];
  started_at: Date;
  status: string;
  updated_at: Date
}

export class SubcriptionLine {
  created_at: Date;
  id: string;
  plan_id: string;
  quantity: number;
  subscription_id: string;
  updated_at: Date
}

export class SubscriptionProduct {
  created_at: Date;
  description: string;
  id: string;
  image_url: string;
  name: string;
  status: string;
  type: unknown;
  updated_at: Date;
  status_display? : string;

  static subscriptionProductMap(subscriptionProduct: SubscriptionProduct): SubscriptionProduct {
    return {
      ...subscriptionProduct,
      status_display: PRODUCT_STATUS_DISPLAY[subscriptionProduct.status],
    };
  }
}


export class SubscriptionPlan {
  created_at: Date;
  description: string;
  id: string;
  interval: unknown | any;
  interval_count: number;
  name: string;
  price: number;
  product_id: string;
  status: string;
  updated_at: Date;
  product?: SubscriptionProduct;
  interval_display?: string;
  status_display?: string;

  static subscriptionPlanMap(subscriptionPlan: SubscriptionPlan): SubscriptionPlan {
    return {
      ...subscriptionPlan,
      interval_display : INTERVAL_DISPLAY[subscriptionPlan.interval],
      status_display: PLAN_STATUS_DISPLAY[subscriptionPlan.status],
    };
  }
}

export class SubscriptionBill {
  account_id: string;
  created_at: Date;
  customer: {};
  description: string;
  id: string;
  lines: SubscriptionBillLine[];
  payment_id: string;
  payment_status: string;
  status: string;
  subscription_id: string;
  total_amount: 0;
  updated_at: Date;
  subscription?: Subscription;
  payment_status_display?: string;
  plan?: SubscriptionPlan;
  shop?: Shop;

  static subscriptionBillMap(subscriptionBill: SubscriptionBill): SubscriptionBill {
    return {
      ...subscriptionBill,
      payment_status_display: PAYMENT_STATUS_DISPLAY[subscriptionBill.payment_status],
    };
  }
}

export class SubscriptionBillLine {
  created_at: Date;
  description: string;
  id: string;
  line_amount: number;
  period_end_at: Date;
  period_start_at: Date;
  price: number;
  quantity: number;
  subscription_bill_id: string;
  subscription_id: string;
  subscription_line_id: string;
  updated_at: Date
}

export class SubscriptionLine {
  created_at: Date | string;
  id: string;
  plan_id: string;
  quantity: number;
  subscription_id: string
  updated_at: Date | string;
}
