import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import {Ticket, TicketRefType, TicketSource, TicketState, TicketType} from '@etop/models';
import { Paging } from '@etop/shared';

@Injectable({
  providedIn: 'root'
})
export class ShopTicketApi {

  constructor(
    private http: HttpService
  ) {}

  getTickets(request: ShopTicketAPI.GetTicketsRequest) {
    return this.http.post('api/shop.Ticket/GetTickets', request).toPromise()
      .then(res => res.tickets.map(t => Ticket.ticketMap(t)));
  }

  getTicket(id: string) {
    return this.http.post('api/shop.Ticket/GetTicket', {id}).toPromise()
      .then(ticket => Ticket.ticketMap(ticket));
  }

  getTicketLabels(request: ShopTicketAPI.GetTicketLabels) {
    return this.http.post('api/shop.Ticket/GetTicketLabels', request).toPromise()
      .then(res => res.ticket_labels);
  }

  createTicket(request: ShopTicketAPI.CreateTicketRequest) {
    return this.http.post('api/shop.Ticket/CreateTicket', request).toPromise()
      .then();
  }

  updateTicket(request: ShopTicketAPI.UpdateTicketRequest) {
    return this.http.post('api/shop.Ticket/UpdateTicket', request).toPromise()
      .then();
  }

  createTicketLabel(request: ShopTicketAPI.CreateTicketLabelRequest){
    return this.http.post('api/shop.Ticket/CreateTicketLabel', request).toPromise()
      .then();
  }

  updateTicketLabel(request: ShopTicketAPI.UpdateTicketLabelRequest){
    return this.http.post('api/shop.Ticket/UpdateTicketLabel', request).toPromise()
      .then();
  }

  getTicketComments(request: ShopTicketAPI.GetTicketCommentsRequest) {
    return this.http.post('api/shop.Ticket/GetTicketComments', request).toPromise()
      .then(res => res.ticket_comments);
  }

  createTicketComment(request: ShopTicketAPI.CreateTicketCommentRequest) {
    return this.http.post('api/shop.Ticket/CreateTicketComment', request).toPromise()
      .then();
  }

  updateTicketComment(request: ShopTicketAPI.UpdateTicketCommentRequest) {
    return this.http.post('api/shop.Ticket/UpdateTicketComment', request).toPromise();
  }

  deleteTicketLabel(id: string ){
    return this.http.post('api/shop.Ticket/DeleteTicketLabel', {id}).toPromise();
  }

  assignTicket(request: ShopTicketAPI.AssignTicketRequest) {
    return this.http.post('api/shop.Ticket/AssignTicket', request).toPromise();
  }

  reopenTicket(request: ShopTicketAPI.ReopenTicketRequest) {
    return this.http.post('api/shop.Ticket/ReopenTicket', request).toPromise();
  }

  closeTicket(request: ShopTicketAPI.CloseTicketRequest) {
    return this.http.post('api/shop.Ticket/CloseTicket', request).toPromise();
  }

  confirmTicket(request: ShopTicketAPI.ConfirmTicketRequest) {
    return this.http.post('api/shop.Ticket/ConfirmTicket', request).toPromise();
  }
}

export namespace ShopTicketAPI {
  export class GetTicketsRequest {
    filter?: GetTicketsFilter;
    paging: Paging;
  }

  export interface GetTicketComments {
    filter?: GetTicketsFilter;
    paging: Paging;
  }

  export interface GetTicketLabels {
    tree: boolean;
    filter?: GetTicketLabelsFilter;
  }

  export interface GetTicketLabelsFilter {
    type?: TicketType;
  }

  export interface GetTicketsFilter {
    account_id?: string;
    assigned_user_ids?: string[];
    closed_by?: string;
    code?: string;
    created_by?: string;
    ids?: string[];
    label_ids?: string[];
    ref_code?: string;
    ref_id?: string;
    ref_type?: TicketRefType;
    state?: TicketState;
    title?: string;
    types?: TicketType[];
  }

  export class GetTicketCommentsRequest {
    filter?: GetTicketCommentsFilter;
    paging: Paging;
    ticket_id: string;
  }

  export class CreateTicketLabelRequest{
    code?: string;
    color?: string;
    name?: string;
    parent_id?: string;
  }

  export class UpdateTicketLabelRequest{
    id: string;
    code?: string;
    color?: string;
    name?: string;
    parent_id?: string;
  }

  export class CreateTicketCommentRequest {
    ticket_id: string;
    image_url?: string;
    message?: string;
    image_urls?: string[];
    parent_id?: string;

  }

  export interface GetTicketCommentsFilter {
    created_by?: string;
    ids: string[];
    parent_id: string;
    title: string;
  }

  export class CreateTicketRequest {
    description: string;
    label_ids? : string[];
    note? : string;
    ref_code? : string;
    ref_id?: string;
    ref_type? : TicketRefType;
    source?: TicketSource;
    title: string;
    type?: TicketType;
  }

  export class UpdateTicketRequest {
    description: string;
    id: string;
    label_ids?: string[];
    ref_id?: string;
    ref_type?: TicketRefType;
    title: string;
  }

  export interface UpdateTicketCommentRequest {
    account_id: string;
    id: string;

    image_url?: string;
    message?: string;
  }

  export class AssignTicketRequest {
    assigned_user_ids: string[];
    ticket_id: string;
  }

  export class ReopenTicketRequest {
    note?: string;
    ticket_id: string;
  }

  export class ConfirmTicketRequest {
    note?: string;
    ticket_id: string;
  }

  export class CloseTicketRequest {
    note?: string;
    state: TicketState;
    ticket_id: string;
  }
}
