import { BaseModel } from '@etop/core';
import { Department } from './Department';
import {CursorPaging } from '@etop/models'
export class Statistical extends BaseModel{
  date_from : Date;
  date_to: Date;
  summary_type: string;
  report_type: string;
  department_id?: string;
  department?: Department
}

export namespace StatisticalAPI {
  export interface GetStatisticalFilter {
    date_from?: Date;
    date_to?: Date;
    department_id?: string;
  }

  export interface StatisticalRequest {
    filter?: GetStatisticalFilter;
    paging?: CursorPaging
  }
}