import {Address, ShopShippingAddress} from './Address';
import { BaseModel, Processing } from '../core/base/base-model';
import { Fulfillment } from './Fulfillment';
import { ExtendedAccount } from './Account';
import { Customer, CustomerAddress } from './Customer';
import {AuthenticateStore} from "@etop/core";

export const ORDER_STATUS_MOVECROP = {
  Z: "Mới",
  S: "Đang xử lý",
  P: "Giao thành công",
  N: "Huỷ",
  NS: "Trả hàng"
};

export const ORDER_SOURCE = {
  ts_app: 'TOPSHIP App',
  unknown: 'Không xác định',
  default: 'Mặc định',
  etop_pos: 'eTop POS',
  haravan: 'Haravan',
  etop_pxs: 'eTop POS Extension',
  self: 'Nhập hàng',
  import: 'Import',
  etop_cmx: 'CMX',
  api: 'API'
};

export const ORDER_STATUS = {
  Z: "Đặt hàng",
  S: "Đang xử lý",
  P: "Hoàn thành",
  N: "Huỷ",
  NS: "Trả hàng"
};

export const CONFIRM_STATUS = {
  Z: "Chưa xác nhận",
  S: "Đang xử lý",
  P: "Đã xác nhận",
  N: "Huỷ"
};

export const ORDER_PAYMENT_STATUS = {
  N: "Chưa hoàn thành",
  S: "Chưa hoàn thành",
  Z: "Chưa hoàn thành",
  P: "Hoàn thành"
};

export const CANCEL_ORDER_REASONS = [
  "Sai thông tin",
  "Khách yêu cầu hủy",
  "Tư vấn sai sản phẩm",
  "Hết hàng",
  "Nhà vận chuyển không lấy hàng"
];

export class OrderCustomer extends BaseModel{
  id: string;
  birthday: string;
  email: string;
  first_name: string;
  full_name: string;
  gender: string;
  last_name: string;
  phone: string;
  deleted: boolean;
  type?: string;
  address?: CustomerAddress;
  addresses: Array<CustomerAddress>;
  p_data: any = {};
}

export class OrderLine {
  attributes?: Array<any>;
  cancel_reason?: string;
  cancelled_at?: string;
  closed_at?: string;
  confirmed_at?: string;
  code?: string;
  image_url?: string;
  is_outside_etop?: boolean;
  list_price?: number;
  order_id?: string;
  payment_price?: number;
  product_id?: string;
  product_name?: string;
  quantity: number;
  retail_price?: number;
  s_confirm?: string;
  supplier_id?: string;
  total_discount?: number;
  updated_at?: string;
  variant_id?: string;
  x_variant_id?: string;
  inventory_variant?: any;
  p_data?: any = {
    editable: false,
    to_save: false,
    code: ''
  };
}

export class OrderFeeLine {
  type: string;
  name: string;
  code?: string;
  desc?: string;
  amount: number;
}

export class Shipping {
  sh_address: Address;
  x_service_id: string;
  x_shipping_fee: number;
  x_service_name: string;
  pickup_address: Address;
  shipping_address: Address;
  return_address: Address;
  shipping_service_name: string;
  shipping_service_code: string;
  shipping_service_fee: number;
  shipping_provider: string;
  carrier: string;
  include_insurance: boolean;
  try_on: string;
  shipping_payment_type: 'buyer' | 'seller';
  shipping_note: string;
  cod_amount: number;
  weight: number;
  gross_weight: number;
  length: number;
  width: number;
  height: number;
  chargeable_weight: number;
}

export class Order extends BaseModel implements Processing {
  basket_value: number;
  billing_address: Address;
  cancel_reason: string;
  cancelled_at: string;
  closed_at: string;
  code: string;
  confirm: string;
  confirm_status: string;
  confirmed_at: string;
  created_at: string;
  customer: Customer;
  customer_address: Address;
  customer_id: string;
  customer_payment_status: string;
  discounts: Array<any>;
  ed_code: string;
  etop_payment_status: string;
  payment_status: string;
  external_code: string;
  external_data: any;
  external_id: string;
  external_url: string;
  fee_lines: Array<OrderFeeLine>;
  fulfillment_ids: Array<string>;
  fulfillment_shipping_status: string;
  fulfillment_status: string;
  fulfillment_type: string;
  fulfillments: Array<Fulfillment>;
  lines: Array<OrderLine>;
  order_discount: number;
  order_note: string;
  partner_id: string;
  payment_method: string;
  processed_at: string;
  received_amount: number;
  reference_url: string;
  self_url: string;
  sh_confirm: string;
  shipping: Shipping;
  shipping_address: Address;
  shipping_note: string;
  shop_id: string;
  shop_name: string;
  shop_shipping?: ShopShippingAddress;
  source: string;
  status: string;
  total_amount: number;
  total_discount: number;
  total_fee: number;
  total_items: number;
  updated_at: string;

  status_display: string;
  confirm_status_display: string;
  payment_status_display: string;
  source_display: string;
  try_on_display: string;

  token: string;
  shop: ExtendedAccount;

  activeFulfillment: Fulfillment;

  static orderMap(order: Order, auth: AuthenticateStore): Order {
    order = new Order(order);
    order.source_display = ORDER_SOURCE[order.source];
    order.status_display = ORDER_STATUS[order.status];
    order.confirm_status_display = CONFIRM_STATUS[order.confirm_status];
    if (order.payment_status != 'P') {
      order.payment_status = 'Z';
    }
    order.payment_status_display = ORDER_PAYMENT_STATUS[order.payment_status];
    order.shipping = {
      ...order.shipping,
      shipping_address: order.shipping_address
    };
    if (!order.shipping.try_on || order.shipping.try_on == 'unknown') {
      order.shipping.try_on = auth.snapshot.shop.try_on;
    }
    if (order.customer_id == "0") {
      order.customer_id = null;
    }
    if (order.customer.full_name.toLowerCase() == 'khach le') {
      order.customer.full_name = 'Khách lẻ';
    }

    order.p_data.shipping = order.shipping;

    return order;
  }

  static orderMapMovecrop(order: Order, auth: AuthenticateStore) {
    order = Order.orderMap(order, auth);
    order.status_display = ORDER_STATUS_MOVECROP[order.status];

    return order;
  }

  /**
   * @deprecated please do not use p_data any more!!!
   */
  p_data: any = {
    fee_lines: [],
    order_discount: 0,
    total_discount: 0,
    total_amount: 0,
    total_fee: 0,
    error_cod: false,
    shipping: new Shipping(),
    detailed: false,
    selected: false,
    // for shipnow, address must have coordinates
    valid_shipping_address: true
  };

  created_by?: string;
}
