import validator from "validator";
import { variable } from "@angular/compiler/src/output/output_ast";

export const AlphanumberricValidates = [
  {
    check: validator.isAlphanumeric,
    error: "can contain letters (a-z)and numbers (0-9)",
  },
];

export const NoneSpacingValidates = [
  {
    check: (variable) => !validator.contains(variable, " "),
    error: "cannot have space",
  },
];
export const UsernameValidates = [

  {
    check: (variable) => validator.isLength(variable, { max: 64 }),
    error: "following are allowed: a-z, A-Z, 0-9, and common punctuation., with a maximum of 64 characters.",
  }
];

export const PasswordValidatates = [
  {
    check: (variable) => validator.isLength(variable, { min: 8, max: 32 }),
    error: "must have at least 8 characters and maximum of 32 characters",
  },
  {
    check: validator.isAscii,
    error: "following are allowed: a-z, A-Z, 0-9, and common punctuation.",
  }
];

export const NullValidates = [
  {
    check: (variable) => !validator.isEmpty(variable + ''),
    error: "cannot be empty"
  }
];

export const EmailValidates = [
  {
    check: validator.isEmail,
    error: "is invalid!",
  },
];

export const NumberValidates = [
  {
    check: (variable) => validator.isNumeric(variable.toString()),
    error: "must be a number"
  }
];

export const FloatValidates = [
  {
    check: (variable) => validator.isFloat(variable.toString()),
    error: "must be a number"
  }
];

export const IntValidates = [
  {
    check: (variable) => validator.isInt(variable.toString()),
    error: "must be Number!",
  },
];

export const full_nameValidates = [
  {
    check: (variable) => validator.isLength(variable, { max: 100 }),
    error: "can have maximum is 100 characters",
  },
  {
    check: (variable) => !/\d/.test(variable),
    error: "can contains only letters (a-z A-Z)",
  },
];

export const FirstNameValidates = [
  {
    check: (variable) => validator.isLength(variable, { max: 50 }),
    error: "can have maximum is 50 characters",
  },
  {
    check: (variable) => !/\d/.test(variable),
    error: "can contains only letters (a-z A-Z)",
  },

];

export const LastNameValidates = [
  {
    check: (variable) => validator.isLength(variable, { max: 50 }),
    error: "can have maximum is 50 characters",
  },
  {
    check: (variable) => !/\d/.test(variable),
    error: "can contains only letters (a-z A-Z)",
  },

];

export const DateValidates = [
  {
    check: (variable) => {
      if (validator.toDate(variable)) {
        return true;
      } else {
        return false;
      }

    },
    error: "is not Day",
  }
];

export const BooleanValidates = [
  {
    check: validator.isBoolean,
    error: "must be Boolean",
  }
];


export const RateValidates = [
  {
    check: (variable) => validator.isInt(variable.toString(), { min: 1, max: 5 }),
    error: "must be a number from 1 to 5",
  }
];
