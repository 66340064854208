import { Injectable } from '@angular/core';
import { AddressAPI, AddressApi, CustomerApi } from '@etop/api';
import { ConfirmOrderStore } from '@etop/features/fabo/confirm-order/confirm-order.store';
import { Fulfillment, FulfillmentShipmentService } from 'libs/models/Fulfillment';
import { Address } from 'libs/models/Address';
import { Order, OrderLine } from 'libs/models/Order';
import { Customer, CustomerAddress } from 'libs/models/Customer';
import { CustomerFaboApi } from '@etop/api/fabo-api/customer-api.service';
import { Product, Variant } from 'libs/models/Product';
import { ConversationsQuery, ConversationsService } from '@etop/state/fabo/conversation';
import { FbUser } from '@etop/models';
import { ExtraShipmentApi } from '@etop/api/fabo-api/extra-shipment-api';
import GetCustomerAddressesRequest = AddressAPI.GetCustomerAddressesRequest;
import {ProductApi} from "@etop/api/shop/product.api";

@Injectable()
export class ConfirmOrderService {

  constructor(
    private addressApi: AddressApi,
    private customerApi: CustomerApi,
    private conversationService: ConversationsService,
    private conversationsQuery: ConversationsQuery,
    private productApi: ProductApi,
    private faboCustomerApi: CustomerFaboApi,
    private confirmOrderStore: ConfirmOrderStore,
    private extraShipmentApi: ExtraShipmentApi
  ) {
  }

  forceUpdateForm() {
    this.confirmOrderStore.forceUpdateForm();
  }

  async getFromAddresses() {
    const addresses = await this.addressApi.getAddresses();
    const fromAddresses = addresses.filter(a => a.type === 'shipfrom');
    this.confirmOrderStore.setFromAddresses(fromAddresses);
  }

  async removeAddress(id) {
    await this.addressApi.removeAddress(id);
  }

  async createFromAddress(body) {
    let newAddress;
    try {
      newAddress = await this.addressApi.createAddress({
        ...body,
        type: 'shipfrom'
      });
    } catch (e) {
      debug.error('ERROR in ConfirmOrderService.createFromAddress()', e);
      newAddress = body;
    }
    this.confirmOrderStore.addFromAddress(newAddress);
    this.confirmOrderStore.setActiveFromAddress(newAddress);
    return newAddress;
  }

  async updateFromAddress(body) {
    await this.addressApi.updateAddress(body);
  }

  selectFromAddress(address: Address) {
    this.confirmOrderStore.setActiveFromAddress(address);
  }

  activeCustomerAddresses(customerAddresses: CustomerAddress[]) {
    this.confirmOrderStore.setCustomerAddresses(customerAddresses);
  }

  async getToAddresses() {
    const customer = this.confirmOrderStore.snapshot.customer;
    const toAddresses = customer?.id && await this.customerApi.getCustomerAddresses(customer.id) || [];
    this.confirmOrderStore.setToAddresses(toAddresses);
  }

  activeToAddress(address) {
    this.confirmOrderStore.setActiveToAddress(address);
  }

  activeCustomerAddress(customerAddress: CustomerAddress) {
    this.confirmOrderStore.setActiveCustomerAddress(customerAddress);
  }

  activeConfirmOrder(confirmOrder: boolean) {
    this.confirmOrderStore.setConfirmOrder(confirmOrder);
  }

  showDropdown(dropdown) {
    this.confirmOrderStore.setShowDropdown(dropdown);
  }

  async getCustomerAddress(query: GetCustomerAddressesRequest) {
    const res = await this.addressApi.getCustomerAddress(query);
    this.confirmOrderStore.setCustomerAddresses(res);
    return res;
  }

  async searchProducts(query) {
    let paging = {
      offset: 0,
      limit: 10
    };
    this.confirmOrderStore.setProductLoading(false);
    const res = await this.productApi.getProducts({
      paging, filter: {
        name: query
      }
    });
    this.confirmOrderStore.setProducts(res.products);
    this.confirmOrderStore.setProductLoading(true);
  }

  async fetchProducts(start?: number, perpage?: number, filters?: Array<any>) {
    let paging = {
      offset: start || 0,
      limit: perpage || 1000
    };
    this.confirmOrderStore.setProductLoading(false);
    const res = await this.productApi.getProducts({ paging, filters });
    this.confirmOrderStore.setProducts(res.products);
    this.confirmOrderStore.setProductLoading(true);
  }

  async fetchVariants(start?: number, perpage?: number, filters?: Array<any>) {
    let paging = {
      offset: start || 0,
      limit: perpage || 1000
    };
    this.productApi.getProducts({ paging, filters }).then(res => this.confirmOrderStore.setProducts(res.products));
  }


  async createToAddress(body) {
    const customer = this.confirmOrderStore.snapshot.customer;
    let newAddress = body;
    if (customer.id) {
      newAddress = await this.customerApi.createCustomerAddress({
        ...body,
        customer_id: customer.id
      });
    }
    this.confirmOrderStore.addToAddress(newAddress);
    this.confirmOrderStore.setActiveToAddress(newAddress);
  }

  async autoCreateCustomer(body) {
    const customer = this.confirmOrderStore.snapshot.customer;
    if (!customer.id) {
      if (body) {
        const _customer = await this.customerApi.createCustomer(body);
        await this.customerApi.createCustomerAddress({
          ...body,
          customer_id: _customer.id
        });
      }
    }
  }

  async updateToAddress(body) {
    await this.customerApi.updateCustomerAddress(body);
  }

  activeOrder(order) {
    this.confirmOrderStore.setOrderEtop(order);
  }

  selectToAddress(address: Address) {
    this.confirmOrderStore.setActiveToAddress(address);
  }

  updateOrder(order: Order, reset = false) {
    this.confirmOrderStore.setOrder(order);
    if (!reset) {
      const orderCustomer = this.confirmOrderStore.snapshot.order.customer;
      const _customer = this.confirmOrderStore.snapshot.customer;

      this.confirmOrderStore.setCustomer({
        ..._customer,
        full_name: orderCustomer?.full_name,
        phone: orderCustomer?.phone
      });
    }
  }

  setResetData(reset: boolean) {
    this.confirmOrderStore.setResetData(reset);
  }

  updatePickupAddress(address: Address) {
    const ffm = this.confirmOrderStore.snapshot.fulfillment;
    if (!address) {
      return this.updateFulfillment({
        ...ffm,
        pickup_address: null
      });
    }
    const {
      full_name, phone, province, district, ward, id,
      province_code, district_code, ward_code, address1
    } = address;

    this.updateFulfillment({
      ...ffm,
      pickup_address: {
        ...ffm.pickup_address,
        full_name, phone, province, district, ward, id,
        province_code, district_code, ward_code, address1
      }
    });
  }

  updateShippingAddress(address: Address) {
    const ffm = this.confirmOrderStore.snapshot.fulfillment;
    if (!address) {
      return this.updateFulfillment({
        ...ffm,
        shipping_address: null
      });
    }
    const {
      full_name, phone, province, district, ward, id,
      province_code, district_code, ward_code, address1
    } = address;
    this.updateFulfillment({
      ...ffm,
      shipping_address: {
        ...ffm.shipping_address,
        full_name, phone, province, district, ward, id,
        province_code, district_code, ward_code, address1
      }
    });
  }

  updateFulfillment(ffm: Fulfillment) {
    this.confirmOrderStore.setFulfillment(ffm);
  }

  setActiveCustomer(customer: Customer) {
    this.confirmOrderStore.setCustomer(customer);
  }

  setShippingServiceName(shipping_service_name: string) {
    this.confirmOrderStore.setShippingServiceName(shipping_service_name);
  }

  setShippingServiceFee(shipping_service_fee: number) {
    this.confirmOrderStore.setShippingServiceFee(shipping_service_fee);
  }

  selectShipmentService(service: FulfillmentShipmentService) {
    this.confirmOrderStore.setActiveShipmentService(service);
  }

  async createCustomer(data: Customer) {
    try {
      const res = await this.customerApi.createCustomer(data);
      this.confirmOrderStore.setCustomer(res);
      const _address: any = this.confirmOrderStore.snapshot.activeToAddress;
      const customerAddress = await this.createCustomerAddress(_address, res.full_name, res.phone, res.id).catch();
      const ffm = this.confirmOrderStore.snapshot.fulfillment;
      if (customerAddress) {
        this.updateFulfillment({
          ...ffm,
          shipping_address: customerAddress
        });
      } else {
        this.updateFulfillment({
          ...ffm,
          shipping_address: _address
        });
      }
      let fbUSerCustomer: FbUser;
      if (data.external_id) {
        fbUSerCustomer = await this.faboCustomerApi.createFbUserCustomer(res.id, data.external_id).then();
        this.conversationService.setActiveFbUser(this.conversationsQuery.getActive().id, fbUSerCustomer);
      }
      this.activeCustomerAddress(customerAddress);
      return res;
    } catch (e) {
      debug.error('ERROR in createCustomer', e);
      throw e;
    }
  }

  async createCustomerWeb(data: Customer) {
    try {
      const res = await this.customerApi.createCustomer(data);
      this.confirmOrderStore.setCustomer(res);
      const _address: any = this.confirmOrderStore.snapshot.activeToAddress;
      const address = await this.createCustomerAddress(_address, res.full_name, res.phone, res.id).catch();
      let fbUSerCustomer: FbUser;
      if (data.external_id) {
        fbUSerCustomer = await this.faboCustomerApi.createFbUserCustomer(res.id, data.external_id).then();
        fbUSerCustomer = {
          ...fbUSerCustomer,
          customer: {
            ...res,
            address: address,
          }
        }
        this.conversationService.setActiveFbUser(this.conversationsQuery.getActive().id, fbUSerCustomer);
      }
      return res;
    } catch (e) {
      debug.error('ERROR in createCustomer', e);
      throw e;
    }
  }

  async createCustomerAddress(address, full_name, phone, customer_id) {
    try {
      return await this.customerApi.createCustomerAddress({
        ...address,
        full_name: full_name,
        phone: phone,
        customer_id: customer_id
      });
    } catch (e) {
      debug.error('ERROR in createCustomerAddress', e);
    }
  }

  async fastCreateProduct({ product_name, price }) {
    const product = await this.productApi.createProduct({
      name: product_name,
      retail_price: price
    });

    await this.productApi.createVariant({
      product_id: product.id,
      name: product.name,
      product_name: product.name,
      retail_price: product.retail_price,
      list_price: product.retail_price,
      code: product.code
    });
    return this.productApi.getProduct(product.id);
  }

  async createProduct(product: Product) {
    const res = await this.productApi.createProduct(product);
    this.confirmOrderStore.setProduct(res);
    return res;
  }

  async createVariant(variant: Variant) {
    const res = await this.productApi.createVariant(variant);
    this.confirmOrderStore.setVariant(res);
    return res;
  }

  async addVariant(id: string) {
    let ids: string[];
    ids.push(id);
    const variant = await this.productApi.getVariantsByIDs(ids);
    this.confirmOrderStore.setVariant(variant[0]);
  }

  async addOrderLine(data: OrderLine) {
    const _order = this.confirmOrderStore.snapshot.order;
    const lines = _order.lines;
    const existVariant = lines.find(line => line.variant_id === data.variant_id);

    if (existVariant) {
      existVariant.quantity += 1;
    } else {
      lines.push(data);
    }
    this.confirmOrderStore.setLines(lines);
    this.confirmOrderStore.forceUpdateForm$.next();
  }

  async removeOrderLine(orderLine) {
    const _order = this.confirmOrderStore.snapshot.order;
    const lines = _order.lines;
    let index = lines.findIndex(line => line.variant_id === orderLine.variant_id);
    lines.splice(index, 1);
    this.confirmOrderStore.setLines(lines);
    this.confirmOrderStore.forceUpdateForm$.next();
  }

  resetConfirmOrderForm() {
    this.confirmOrderStore.setOrder({
      customer: {
        full_name: '',
        phone: ''
      },
      lines: [],
      order_discount: null,
      fee_lines: [{
        type: 'other',
        name: 'Phụ thu',
        amount: null
      }],
      basket_value: 0,
      total_items: 0,
      total_discount: 0,
      total_fee: 0,
      total_amount: 0
    } as any);
    this.updateFulfillment(new Fulfillment({}));
  }

  async getProduct(product_id) {
    return await this.productApi.getProduct(product_id);
  }

  async customerReturnRate(phone: string,index?: number) {
    return await this.extraShipmentApi.customerReturnRate( phone,index);
  }

  updateCopyOrderCustomer(customer) {
    this.confirmOrderStore.setCopyOrderCustomer(customer);
  }

  updateSaveOrder(order) {
    this.confirmOrderStore.setSaveOrder(order);
  }

  updateSaveFulfillment(fulfillment) {
    this.confirmOrderStore.setSaveFulfillment(fulfillment);
  }

  resetSaveDataOrder() {
    const saveDataOrder = new Order({
      p_data: { is_save: false }
    });
    const saveFulfillment = new Fulfillment({
      p_data: { is_save: false }
    });
    this.updateSaveOrder(saveDataOrder);
    this.updateSaveFulfillment(saveFulfillment)
  }
}
