import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Relationship } from '@etop/models';
import { RelationshipStore, StateRelationships } from './relationship.store';

@Injectable({
  providedIn: 'root'
})
export class RelationshipQuery extends QueryEntity<StateRelationships, Relationship> {
  constructor(protected store: RelationshipStore) {
    super(store);
  }

  getRelationshipNameById(id) {
    let relationships = this.getAll();
    return relationships?.find(relationship => relationship.user_id === id)?.full_name
  }

  getRelationshipById(id) {
    let relationships = this.getAll();
    return relationships?.find(relationship => relationship.user_id === id);
  }

  get currentUi() {
    return this.getValue().ui;
  }
}
