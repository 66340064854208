import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { EtopPipesModule } from '../../pipes';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { FormsModule } from '@angular/forms';
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [EtopPipesModule, FormsModule, CommonModule],
  declarations: [CurrencyInputComponent],
  exports: [CurrencyInputComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EtopFormsModule{}
