import { Injectable } from '@angular/core';
import { HeaderComponent } from './header.component';
import { ActionOpt } from './components/action-button/action-button.interface';
import { Subject } from 'rxjs';
import { NavigationData } from './header.interface';
import { Noti } from '../../../../../libs/models/Noti';
import { HeaderButtonOpt } from 'apps/core/src/components/header/components/header-button/header-button.interface';
import { TabOpt } from 'apps/core/src/components/header/components/tab-options/tab-options.interface';

@Injectable()
export class HeaderControllerService {
  private _headerComponents: Array<HeaderComponent> = [];

  onNavigate = new Subject();
  onRequestMoreNotis = new Subject();
  onFilterNotisByAccount = new Subject();

  constructor() {}

  registerInstance(instance: HeaderComponent) {
    this._headerComponents.push(instance);
  }

  startLoading() {}

  endLoading() {}

  setHeaderButtons(btns: HeaderButtonOpt[]) {
    this._headerComponents.forEach(header => header.setHeaderButtons(btns));
  }

  clearHeaderButtons() {
    this._headerComponents.forEach(header => header.clearHeaderButtons());
  }

  setActions(actions: ActionOpt[], option?) {
    this._headerComponents.forEach(header => header.setActions(actions));
  }

  clearActions() {
    this._headerComponents.forEach(header => header.clearActions());
  }

  setContent(content) {
    this._headerComponents.forEach(header => header.setContent(content));
  }

  clearContent() {
    this._headerComponents.forEach(header => header.clearContent());
  }

  setMobileContent(content) {
    this._headerComponents.forEach(header => header.setMobileContent(content));
  }

  clearMobileContent() {
    this._headerComponents.forEach(header => header.clearMobileContent());
  }

  clearMenus() {}

  setMenus(menus: any[]) {}

  setTabs(tabs: TabOpt[]) {
    this._headerComponents.forEach(header => header.setTabs(tabs));
  }

  clearTabs() {
    this._headerComponents.forEach(header => header.clearTabs());
  }

  navigate(instance: HeaderComponent, data: NavigationData) {
    this.onNavigate.next({ instance, data });
  }

  requestMoreNotis(token) {
    this.onRequestMoreNotis.next(token);
  }

  filterNotisByAccount(token) {
    this.onFilterNotisByAccount.next(token);
  }

  loadNotifications(notifications: Array<Noti>) {
    this._headerComponents.forEach(header => header.loadNotifications(notifications));
  }

}
