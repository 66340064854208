import { BaseModel } from  '../core/base/base-model';
import { Connection } from './Connection';
import { User } from './User';

export const HOTLINE_STATUS = {
  Z: "Chưa kích hoạt",
  P: "Đã kích hoạt",
  unknown: "Không xác định"
};

export enum MobileNetwork {
  unknown = 'unknown',
  mobiphone = 'mobiphone',
  vinaphone = 'vinaphone',
  viettel = 'viettel',
  other = 'other'
}
export class Hotline extends BaseModel{
  connection_id: string;
  connection_method: string;
  created_at: Date;
  description: string;
  hotline: string;
  id: string;
  name: string;
  network: MobileNetwork;
  owner_id: string;
  updated_at: Date;
  status: string;
  is_free_charge: boolean;
  status_display: string;
  user: User;
  connection_name?: string;
  connection?: Connection;
  
  static hotlineMap(hotline: Hotline): Hotline {
    hotline = new Hotline(hotline);
    hotline.status_display = HOTLINE_STATUS[hotline.status];
    return hotline;
  }
}
