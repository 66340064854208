import { Component, OnInit } from '@angular/core';
import { AppService } from '@etop/web/core/app.service';
import { CommonUsecase } from 'apps/shared/src/usecases/common.usecase.service';
import {UtilService} from 'apps/core/src/services/util.service';
import { LocationService } from '@etop/state/location';
import { AuthenticateStore, ConfigService } from '@etop/core';

@Component({
  selector: 'etelecom-cs-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loadConfig = true;
  delayPassed = false;
  completedView = false;

  constructor(
    private appService: AppService,
    private commonUsecase: CommonUsecase,
    private utilService: UtilService,
    private locationService: LocationService,
    private config: ConfigService,
    private auth: AuthenticateStore
  )
  {
    this.utilService.checkMobileVersion();
    this.utilService.checkMediumVersion();
  }


  ngOnInit() {
    
    setTimeout(() => this.delayPassed = true, 1000);
    this.appService.bootstrap()
      .then(() => this.commonUsecase.checkAuthorization(true))
      .then(() => this.loadConfig = false)
      .then(() => {
        if (this.auth?.snapshot?.token) {
          this.locationService.initLocations().then();
        }
      })
      .catch(err => debug.log('Bootstrap failed', err));
  }

  getAssets() {
    return this.config.get('assets')
  }

}
