import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { EnvConfig } from '@etop/models';
import { AppConfig } from 'apps/whitelabel/src/core/WhiteLabelConfig.class';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private title: Title
  ) {}

  appID: string;
  appName: string;
  cfg: EnvConfig;
  favIcon: HTMLLinkElement;

  async getConfig() {
    if (this.cfg) {
      return this.cfg;
    }
    return fetch('/assets/configs/appConfigs.json').then((res) => res.json());
  }

  async getAppConfig(): Promise<AppConfig> {
    let config = await this.getConfig();
    return config && config.app;
  }

  async bootstrap() {
    this.favIcon  = document.querySelector('#favIcon');
    fetch('/assets/configs/appConfigs.json')
      .then((res) => res.json())
      .then((config) => {
        this.cfg = config;
        this.favIcon.href = config?.assets?.favicon
        this.setupApp(config.app);
        this.loadTheme(config.theme);
      });
  }

  setupApp(appConfig) {
    if (!appConfig) {
      throw new Error('Không tìm thấy cài đặt ứng dụng');
    }
    this.title.setTitle(appConfig.appName);
    this.appID = appConfig.appId;
    this.appName = appConfig.appName;
  }

  loadTheme(theme) {
    if (!theme) {
      throw new Error('Không tìm thấy cài đặt giao diện');
    }
    const root = this.document.documentElement;
    for (let variable in theme) {
      if (!theme.hasOwnProperty(variable)) {
        continue;
      }
      root.style.setProperty(`--${variable}`, theme[variable]);
    }
  }
}
