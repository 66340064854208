import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'apps/core/src/services/user.service';
import { Router } from '@angular/router';
import { ConfigService } from '@etop/core/services/config.service';
import {AuthenticateService, AuthenticateStore} from '@etop/core';
import { ModalAction } from 'apps/core/src/components/modal-controller/modal-action.service';

@Component({
  selector: 'shop-verify-phone-modal',
  templateUrl: './verify-phone-modal.component.html',
  styleUrls: ['./verify-phone-modal.component.scss']
})
export class VerifyPhoneModalComponent implements OnInit {
  @Input() phone =''
  verification_token = '';
  countDown = 60;
  intervalId: any;
  loading = false;
  constructor(
    private userService: UserService,
    private router: Router,
    private modalDismiss: ModalAction,
    private config: ConfigService,
    private auth: AuthenticateStore
  ) { }

  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.countDown--;
      if (this.countDown == 0) {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }
  async verifyPhone() {
    if(!this.verification_token) {
      toastr.error('Vui lòng nhập mã xác nhận!');
      return;
    }
    this.loading = true;
    try{
      let res = await this.userService.verifyPhoneResetPasswordUsingToken(this.verification_token);
      this.router.navigateByUrl(`/reset-password?t=${res.reset_password_token}`);
      this.modalDismiss.dismiss(null);
    } catch(e) {
      toastr.error(e.message);
    }
    this.loading = false;
  }

  async resendCode() {
    if (this.countDown) {
      toastr.error('Bạn chưa thể gửi lại mã xác nhận ngay lúc này!', 'Xin chờ giây lát');
      return;
    } else {
      this.countDown = 60;
      this.intervalId = setInterval(() => {
        this.countDown--;
        if (this.countDown == 0) {
          clearInterval(this.intervalId);
        }
      }, 1000);
    }
    this.loading = true;
    try {
      let captcha = await AuthenticateService.getReCaptcha(this.config.get('recaptcha_key'));
      let res = await this.userService.requestResetPassword({
        email:"",
        phone: this.phone,
        recaptcha_token: captcha
      });
      this.auth.updateToken(res.access_token);
      toastr.success('', 'Một mã xác nhận mới vừa được gửi vào tin nhắn của bạn');
    } catch (e) {
      toastr.error(e.message);
    }
    this.loading = false;
  }
}
