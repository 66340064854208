import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { HttpService } from '@etop/common';
import { UserService } from '../services/user.service';
import { UserApi } from '@etop/api';
import { AuthenticateStore } from '@etop/core';

@Injectable({ providedIn: 'root' })
export class AdminGuard implements CanActivate {
  constructor(
    private auth: AuthenticateStore,
    private userService: UserService,
    private userApi: UserApi,
    private http: HttpService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let url: string = state.url;
    return this.checkSession(url);
  }

  async checkSession(url: string) {
    try {
      let session = await this.userApi.sessionInfo();
      if (!session.account || session.account.type != 'etop') {
        throw new Error('You Do Not Have Sufficient Permissions To Access This Page');
      }
    } catch (e) {
      this.userService.clear();
      this.auth.clear();
      this.router.navigateByUrl('/login');
      return false;
    }

    return true;
  }
}
