import { BaseModel } from '../core/base/base-model';

export enum ContactTab {
  general= "general",
  internal = "internal",
  import = 'import'
}

export class Contact extends BaseModel {
  created_at: Date;
  full_name: string;
  id: string;
  phone: string;
  shop_id: string;
  updated_at: Date;
  note: string;
}
