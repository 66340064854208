import { NgModule } from '@angular/core';
import { UserApi } from 'libs/api/general/user.api';
import { AccountApi } from 'libs/api/general/account.api';


@NgModule({
  providers: [
    UserApi,
    AccountApi
  ]
})
export class GeneralApiModule {

}
