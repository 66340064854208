import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import {Address} from "libs/models/Address";
import { CustomerAddress } from '@etop/models';

@Injectable({
  providedIn: "root"
})
export class AddressApi {
  constructor(private http: HttpService) {}

  createAddress(data: AddressAPI.CreateAddressRequest, token?: string): Promise<Address> {
    return this.http.post('api/etop.Address/CreateAddress', data, token).toPromise();
  }

  getAddresses(): Promise<Address[]> {
    return this.http.post('api/etop.Address/GetAddresses', {}).toPromise()
      .then(res => res.addresses);
  }

  getCustomerAddress(request:AddressAPI.GetCustomerAddressesRequest):Promise<CustomerAddress[]>{
    return this.http.post('api/shop.Customer/GetCustomerAddresses',request).toPromise()
      .then(res => res.addresses);
  }

  removeAddress(id: string) {
    return this.http.post('api/etop.Address/RemoveAddress', { id }).toPromise();
  }

  updateAddress(data: AddressAPI.UpdateAddressRequest): Promise<Address> {
    return this.http.post('api/etop.Address/UpdateAddress', data).toPromise();
  }

}

export namespace AddressAPI {
  export interface UpdateAddressRequest extends CreateAddressRequest{
    id: string;
  }

  export interface GetCustomerAddressesRequest{
    customer_id?: string;
    filter?:{
      phone:string,
    }
  }

  export interface CreateAddressRequest {
    province?: string;
    province_code: string;
    district?: string;
    district_code: string;
    ward?: string;
    ward_code: string;
    address1: string;
    address2?: string;
    zip?: string;
    country?: string;
    full_name: string;
    first_name?: string;
    last_name?: string;
    phone: string;
    email?: string;
    position?: string;
    type?: string;
    notes?: {
      note?: string;
      open_time?: string;
      lunch_break?: string;
      other?: string;
    },
    coordinates?: {
      latitude: number;
      longitude: number;
    }
  }

}

