<mat-form-field [appearance]="appearance || 'standard'">
    <mat-label *ngIf="textLabel">{{textLabel}}</mat-label>
    <mat-select [(ngModel)]="value" [placeholder]="textLabel" #singleSelect>
        <mat-option class="d-block">
            <ngx-mat-select-search [formControl]="searchValue" placeholderLabel="Tìm kiếm..." noEntriesFoundLabel="'Không có kết quả'"></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let op of filtered" [value]="valueMap ? valueMap(op) : op.value" [disabled]="op.disabled" [class.topship]="topshipInput" (click)="select.emit()">
            <div [innerHTML]="(displayMap ? displayMap(op) : op.name) | safeHtml"></div>
        </mat-option>
    </mat-select>
</mat-form-field>