<div class="modal-header py-2">
  <h5 style="margin: auto 0 !important; ">Xác thực {{verifyType}}</h5>
  <button type="button" class="btn text-large" data-dismiss="modal">&times;</button>
</div>
<div class="login-box-body p-0">
  <form role="form" class="form-horizontal p-0">
    <div class="p-4 border-top">
      <div class="row">
       <div class="col-12 mb-2">
        <div>Hệ thống vừa gửi cho bạn 1 mã xác thực vào {{verifyType}} <b>{{verifyAccount}}</b>.</div>
        <div>Vui lòng kiểm tra {{verifyType}} và nhập mã vào ô bên dưới:</div>
       </div>
        <div class="col-sm-12">
          <input name="token" type="text" class="form-control" 
            placeholder="Mã xác thực" [(ngModel)]="verifyCode" />
        </div>
      </div>
    </div>
    <div class="p-4 border-top d-flex flex-row-reverse justify-content-between align-items-center">
      <button class="btn btn-primary" (click)="verifyOTP()">
        <i  *ngIf="loading" class="fa fa-circle-o-notch fa-spin" aria-hidden="true" style="font-size: 12px" ></i>
        Xác thực
      </button>
      <div>
        Bạn chưa nhận được mã ? <a (click)="resendCode()" class="resend-otp-btn" [class.text-default]="countDown" ><b>Gửi lại </b><span *ngIf="countDown">(vui lòng chờ trong {{countDown}}s)</span></a>
      </div>
    </div>
  </form>
</div>