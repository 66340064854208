import { BaseModel, Processing } from '@etop/core/base/base-model';
import {Extension} from "@etop/models/Extension";
import { Department } from './Department';

export declare type StaffRole = 'inventory_management' | 'salesman'
| 'owner' | 'analyst' | 'accountant' | 'purchasing_management' | 'staff_management' | 'telecom_customerservice' | 'm_admin' | 'telecom_customerservice_management';

export class Invitation extends BaseModel implements Processing {
  id: string;
  shop_id: string;
  phone: string;
  user_id: string;
  email: string;
  full_name: string;
  short_name: string;
  roles: StaffRole[];
  roles_display: string;
  token: string;
  status: string;
  status_display: string;
  invited_by: string;
  invited_by_user: string;
  accepted_at: null;
  declined_at: null;
  invitation_url: string;
  expired_at: Date | string;
  created_at: Date | string;
  updated_at: Date | string;
  shop: {
    id: string;
    name: string;
    code: string;
    image_url: string;
  };
  p_data: any = {};
}

export class Relationship implements Processing {
  id: string;
  account_id: string;
  deleted: boolean;
  full_name: string;
  email: string;
  permissions: string[];
  position: string;
  roles: StaffRole[];
  roles_display: string;
  short_name: string;
  user_id: string;
  p_data: any = {};
  phone: string;
  extension?: Extension;
  department_id: string;
  department: Department;
}

export enum RelationshipTab {
  staff = "staff",
  extension = "extension",
  shop_invitation = "shopInvitation",
}
