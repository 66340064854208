import { Injectable } from '@angular/core';
import { EntityState, StoreConfig, EntityStore, ActiveState } from '@datorama/akita';
import { FbPage } from '@etop/models/faboshop/FbPage';

export interface StateFbPages extends EntityState<FbPage, string>, ActiveState {
}

@Injectable({
  providedIn: 'root'
})

@StoreConfig({ name: "FbPages", idKey: 'id' , resettable: true})
export class FbPagesStore extends EntityStore<StateFbPages> {
  constructor() {
    super();
  }
}
