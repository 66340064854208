import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

export type EtopWindowMessageEvent =
  'OPEN_NEW_TAB' |
  'CREATE_ORDER' |
  'CLOSE';

@Injectable()
export class WindowRef {
  private _windowNative: Window;
  constructor() {
    this._windowNative = window;
    this._windowNative.name = 'etop-parent';
  }

  get windowNative () {
    return this._windowNative;
  }

  openNewTab(url: string, keepFocus = false) {
    const reg = /^https?:\/\//i;
    let fullUrl = url;
    if (!reg.test(url)) {
      fullUrl = this._windowNative.location.origin + url;
    }

    return window.open(fullUrl, '_blank');
  }
}

@Injectable()
export class WindowMessageService {
  private _newMessage = new Subject();
  public messageChange = this._newMessage.asObservable();

  constructor(protected windowRef: WindowRef) {
    this.windowRef
    .windowNative
    .addEventListener('message', (event: MessageEvent) => {
      this.handleNewMessage(event);
    });
  }

  postMessage(targetWindow: Window, data: any, targetOrigin = "*") {
    return targetWindow
    .postMessage(data, targetOrigin);
  }

  notifyWindowParent(evnetName: EtopWindowMessageEvent, data?: string) {
    const event = {
      name: evnetName,
      data
    };
    const parentWindow = this.windowRef.windowNative.parent;

    this.postMessage(parentWindow, event);
  }

  protected handleNewMessage(event) {
    this._newMessage.next(event);
  }

}
