import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { Paging } from '@etop/shared';
import { Connection, Filter } from '@etop/models';
import { Subscription, SubscriptionBill, SubscriptionPlan, SubscriptionProduct } from '@etop/models/Subscription';

@Injectable({
  providedIn: 'root'
})
export class AdminSubscriptionApi {

  constructor(
    private http: HttpService
  ) {
  }

  async getSubscriptions(body: AdminSubcriptionAPI.GetSubscriptionsRequest) {
    return this.http
      .post('/api/admin.Subscription/GetSubscriptions', body)
      .toPromise()
      .then(res => res.subscriptions);
  }

  async getSubscriptionProducts() {
    return this.http
      .post('/api/admin.Subscription/GetSubscriptionProducts', {})
      .toPromise()
      .then(res => res.subscription_products.map(prod => SubscriptionProduct.subscriptionProductMap(prod)));
  }

  async getSubscriptionPlans() {
    return this.http
      .post('/api/admin.Subscription/GetSubscriptionPlans', {})
      .toPromise()
      .then(res => res.subscription_plans.map(plan => SubscriptionPlan.subscriptionPlanMap(plan)));
  }

  async getSubscriptionBills(body: AdminSubcriptionAPI.GetSubscriptionBillsRequest) {
    return this.http
      .post('/api/admin.Subscription/GetSubscriptionBills', body)
      .toPromise()
      .then(res => res.subscription_bills.map(bill => SubscriptionBill.subscriptionBillMap(bill)));
  }
  async createSubscription(body: AdminSubcriptionAPI.CreateSubscriptionRequest) {
    return this.http
      .post('/api/admin.Subscription/CreateSubscription', body)
      .toPromise()
      .then();
  }

  async createSubscriptionBill(body: AdminSubcriptionAPI.CreateSubscriptionBillRequest) {
    return this.http
      .post('/api/admin.Subscription/CreateSubscriptionBill', body)
      .toPromise()
      .then();
  }
}
export namespace AdminSubcriptionAPI {
  export interface GetSubscriptionsRequest {
    account_id: string,
    filters: Filter[],
    paging: Paging,
  }

  export interface GetSubscriptionBillsRequest {
    account_id: string,
    filters: Filter[],
    paging: Paging,
  }

  export interface CreateSubscriptionBillRequest {
    account_id: string,
    customer: any,
    description: string,
    subscription_id: string;
    total_amount: number;
  }

  export interface CreateSubscriptionRequest {
    account_id: string;
    billing_cycle_anchor_at: Date;
    cancel_at_period_end: boolean;
    customer: any;
    lines: CreateSubscriptionLine[];
  }

  export class CreateSubscriptionLine {
    created_at?: Date;
    plan_id: string;
    quantity?: number;
    updated_at?: Date
  }
}
