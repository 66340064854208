import { Injectable } from '@angular/core';
import {OrderApi, AccountApi} from '@etop/api';
import { requireMethodRoles } from '@etop/core';
import {Order, ORDER_STATUS} from 'libs/models/Order';
import { UtilService } from 'apps/core/src/services/util.service';
import { UserBehaviourTrackingService } from "apps/core/src/services/user-behaviour-tracking.service";
import { Router } from "@angular/router";
import { ConnectionService } from '../connection/connection.service';
import { ConnectionStore } from '../connection/connection.store';
import {Fulfillment, TRY_ON} from 'libs/models/Fulfillment';
import { Connection } from 'libs/models/Connection';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { FilterOperator, Filters } from '@etop/models';

const ORDER_SOURCE = {
  ts_app: 'TOPSHIP App',
  unknown: 'Không xác định',
  default: 'Mặc định',
  etop_pos: 'eTop POS',
  haravan: 'Haravan',
  etop_pxs: 'eTop POS Extension',
  self: 'Nhập hàng',
  import: 'Import',
  etop_cmx: 'CMX',
  api: 'API'
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(
    private router: Router,
    private util: UtilService,
    private ubtService: UserBehaviourTrackingService,
    private accountApi: AccountApi,
    private orderApi: OrderApi,
    private connectionStore: ConnectionStore,
    private connectionService: ConnectionService,
  ) { }

  async orderSourceMap(source, partner_id) {
    if (partner_id != '0' && ['etop_cmx', 'api'].indexOf(source) != -1) {
      const _partner = await this.accountApi.getPublicPartnerInfo(partner_id);
      return _partner
        ? `${ORDER_SOURCE[source]} - ${_partner.name}`
        : ORDER_SOURCE[source];
    }
    return source ? ORDER_SOURCE[source] : 'Không xác định';
  }

  @requireMethodRoles({
    skipError: true,
    valueOnError: true,
    roles: ['admin', 'owner', 'salesman', 'accountant']
  })
  async checkEmptyOrders(shop_id) {
    return await this.orderApi.checkEmptyOrders(shop_id);
  }

  getOrder(id): Promise<Order> {
    return new Promise((resolve, reject) => {
      this.connectionStore.state$.pipe(
        map(s => s?.initConnections),
        distinctUntilChanged((a, b) => a?.length == b?.length)
      ).subscribe(cons => {
        if (cons) {
          this.orderApi.getOrder(id)
            .then(order => {
              order.activeFulfillment = this.getActiveFulfillment(order, cons);
              resolve(order);
            })
            .catch(err => reject(err));
        }
      });
    });
  }

  // TODO: Temporarily closed until dashboard works smoothly
  // @requireMethodRoles({
  //   skipError: true,
  //   valueOnError: [],
  //   roles: ['admin', 'owner', 'salesman', 'accountant']
  // })
  async getOrders(start?: number, perpage?: number, filters?: Array<any>): Promise<Order[]> {
    let paging = {
      offset: start || 0,
      limit: perpage || 20
    };

    await this.connectionService.getValidConnections();
    return new Promise((resolve, reject) => {
      this.connectionStore.state$.pipe(
        map(s => s?.initConnections),
        distinctUntilChanged((a, b) => a?.length == b?.length)
      ).subscribe(connections => {
        if (connections?.length) {
          this.orderApi.getOrders({ paging, filters })
            .then(orders => {
              orders.map((order, index) => {
                order.activeFulfillment = this.getActiveFulfillment(order, connections);
                return this.mapOrderInfo(order, index);
              });
              resolve(orders);
            })
            .catch(err => reject(err));
        }
      });
    });
  }

  setupDataOrder(order: Order): Order {
    order.customer.id = order.customer_id;
    order.p_data = {
      ...order.p_data,
      shipping: {
        ...order.shipping,
        cod_amount: !order.shipping && (order.basket_value + order.total_fee - order.total_discount)
          || order.shipping.cod_amount
      },
      customer: order.customer,
      selected_service: {},
      selected_service_id: '-',
      fee_lines: order.fee_lines,
      total_fee: order.total_fee,
      order_discount: order.order_discount,
      total_discount: order.total_discount,
      total_amount: order.total_amount,
      order_note: order.order_note ? order.order_note : '-',
      ed_code: order.ed_code ? order.ed_code :
        (order.external_id ? order.external_id : '-')
    };
    order.p_data.shipping.try_on_display = TRY_ON[order.p_data.shipping.try_on];
    return order;
  }

  @requireMethodRoles({
    skipError: true,
    valueOnError: null,
    roles: ['admin', 'owner', 'salesman', 'accountant']
  })
  getShopOrders(token, { paging: { limit } }) {
    return this.orderApi.getShopOrders(token, { paging: { limit } });
  }

  createOrder(body) {
    const data = { ...body };
    delete data.o_data;
    delete data.p_data;
    for (let key in data) {
      if (!data[key]) { continue; }
      if (data[key].o_data) { delete data[key].o_data; }
      if (data[key].p_data) { delete data[key].p_data; }
    }
    return this.orderApi.createOrder(data)
      .then(res => {
        if (res.id) {
          this.ubtService.sendUserBehaviour('CreateOrder');
        }
        return res;
      });
  }

  updateOrder(body) {
    return this.orderApi.updateOrder(body);
  }

  mapOrderInfo(order: Order, index) {
    return {
      ...order,
      index,
      search_text: this.util.makeSearchText(`${order.code}${order.customer.full_name}${order.customer.phone}`)
    }
  }

  mapSpecialFilters(filters: Filters) {
    filters.forEach(f => {
      if (f.value == '{}') {
        f.op = FilterOperator.eq;
        f.name = 'fulfillment.ids'
      }
    });
  }

  async navigate(code: string, id?: string, use_slug = true) {
    if (!use_slug) {
      await this.router.navigateByUrl(`orders?code=${code || id}&type=so`);
      return;
    }
    const slug = this.util.getSlug();
    await this.router.navigateByUrl(`s/${slug}/orders?code=${code || id}&type=so`);
  }

  getActiveFulfillment(order: Order, connections: Connection[]) {
    let activeFulfillment: any;
    switch (order.fulfillment_type) {
      case 'shipment':
        const shipmentFfms = order.fulfillments.map(ffm => ffm.shipment);
        const _activeShipmentFfm = shipmentFfms.find(ffm => ffm.status != 'N');
        activeFulfillment = _activeShipmentFfm || shipmentFfms[0];
        break;
      case 'shipnow':
        const shipnowFfms = order.fulfillments.map(ffm => ffm.shipnow);
        const _activeShipnowFfm = shipnowFfms.find(ffm => ffm.status != 'N');
        activeFulfillment = _activeShipnowFfm || shipnowFfms[0];
        break;
      default:
        const _activeFfm = order.fulfillments.find(ffm => ffm.status != 'N');
        activeFulfillment = _activeFfm || order.fulfillments[0];
        break;
    }

    return Fulfillment.fulfillmentMap(activeFulfillment, connections);
  }

  completeOrder(order: Order) {
    return this.orderApi.completeOrder(order.id);
  }

  confirmOrder(order: Order) {
    return this.orderApi.confirmOrder(order.id, 'confirm');
  }

  statusMap(status) {
    return ORDER_STATUS[status] || 'Không xác định';
  }

  cancelOrder(order, reason) {
    return this.orderApi.cancelOrder(
      order.id,
      reason,
      'confirm'
    );
  }
}
