import { Injectable } from '@angular/core';
import {ShopTicketAPI, ShopTicketApi} from "@etop/api";
import {TicketLabelStore} from "./ticket-label.store";
import {TicketLabelQuery} from "./ticket-label.query";
import {TicketLabel} from "@etop/models";
import {trimUndefined} from "@etop/utils";

@Injectable({
  providedIn: 'root'
})
export class TicketLabelService {

  constructor(
    private ticketApi: ShopTicketApi,
    private ticketLabelStore: TicketLabelStore,
    private ticketLabelQuery: TicketLabelQuery
  ) { }

  static validateColor(color: string) {
    if (!color || !color.startsWith('#')) {
      return false;
    }
    return !(color.length != 4 && color.length != 7);
  }

  setFilter(filter: ShopTicketAPI.GetTicketLabelsFilter) {
    const ui = this.ticketLabelQuery.getValue().ui;
    const _filter = {
      ...ui.filter,
      ...filter
    };
    this.ticketLabelStore.update({
      ui: {
        ...ui,
        filter: trimUndefined(_filter)
      }
    });
  }

  async getTicketLabels(doLoading = true) {
    if (doLoading) {
      this.ticketLabelStore.setLoading(true);
    }
    try {
      const {filter} = this.ticketLabelQuery.getValue().ui;
      const data = { tree: true, filter };
      const labels = await this.ticketApi.getTicketLabels(data);
      this.ticketLabelStore.set(labels);
      this.ticketLabelStore.update({ticketProductLabels: labels});

      const ticketProductLabels = this.ticketLabelQuery.getValue().ticketProductLabels;
      this.ticketLabelStore.update({
        ticketSubjectLabels: ticketProductLabels.reduce((a, b) => {
          return a.concat(b.children.map(child => {
            return {
              ...child,
              color: child.color || b.color
            };
          }));
        }, [])
      });
      const ticketSubjectLabels = this.ticketLabelQuery.getValue().ticketSubjectLabels;
      this.ticketLabelStore.update({
        ticketDetailLabels: ticketSubjectLabels.reduce((a, b) => {
          return a.concat(b.children.map(child => {
            return {
              ...child,
              color: child.color || b.color
            };
          }));
        }, [])
      });
      if (doLoading) {
        this.selectTicketProductLabel(labels[0]);
        this.selectTicketSubjectLabel(labels[0]?.children[0]);
      }
    } catch (e) {
      debug.error('ERROR in getting TicketLabels', e);
    }
    if (doLoading) {
      this.ticketLabelStore.update({ticketLabelsLoading: false});
    }
  }

  selectTicketProductLabel(label: TicketLabel) {
    this.ticketLabelStore.update({activeProductLabel: label});
    this.ticketLabelStore.update({ticketSubjectLabels: this.ticketLabelQuery.getValue().ticketSubjectLabels});
  }

  selectTicketSubjectLabel(label: TicketLabel) {
    this.ticketLabelStore.update({activeSubjectLabel: label});
    this.ticketLabelStore.update({ticketDetailLabels: this.ticketLabelQuery.getValue().ticketDetailLabels});
  }

  updateLabelsTree() {
    // NOTE: Product Labels
    const {ticketProductLabels, activeProductLabel} = this.ticketLabelQuery.getValue();
    if (!activeProductLabel) {
      this.selectTicketProductLabel(ticketProductLabels[0]);
    } else {
      ticketProductLabels.some((label, index) => {
        if (label.id == activeProductLabel?.id) {
          this.selectTicketProductLabel(label);
          return true;
        } else if (index == ticketProductLabels?.length - 1) {
          this.selectTicketProductLabel(ticketProductLabels[0]);
        }
      });
    }

    // NOTE: Subject Labels
    const {ticketSubjectLabels, activeSubjectLabel} = this.ticketLabelQuery.getValue();
    if (!activeSubjectLabel) {
      this.selectTicketSubjectLabel(ticketSubjectLabels[0]);
    } else {
      ticketSubjectLabels.some((label, index) => {
        if (label.id == activeSubjectLabel?.id) {
          this.selectTicketSubjectLabel(label);
          return true;
        } else if (index == ticketSubjectLabels?.length - 1) {
          this.selectTicketSubjectLabel(ticketSubjectLabels[0]);
        }
      });
    }
  }

  async createTicketLabel(request: ShopTicketAPI.CreateTicketLabelRequest) {
    try {
      await this.ticketApi.createTicketLabel(request);
      await this.getTicketLabels(false);

      this.updateLabelsTree();
    } catch(e) {
      debug.error('ERROR in createTicketLabel', e);
      throw e;
    }
  }

  async createShopTicketLabel(request: ShopTicketAPI.CreateTicketLabelRequest){
    try {
      await this.ticketApi.createTicketLabel(request);
      await this.getTicketLabels(false);

      this.updateLabelsTree();
    } catch (e) {
      debug.error('ERROR in createShopTicketLabel', e);
      throw e;
    }
  }

  async updateTicketLabel(request: ShopTicketAPI.UpdateTicketLabelRequest) {
    try {
      await this.ticketApi.updateTicketLabel(request);
      await this.getTicketLabels(false);

      this.updateLabelsTree();
    } catch(e) {
      debug.error('ERROR in updateTicketLabel', e);
      throw e;
    }
  }

  async updateShopTicketLabel(request: ShopTicketAPI.UpdateTicketLabelRequest) {
    try {
      await this.ticketApi.updateTicketLabel(request);
      await this.getTicketLabels(false);

      this.updateLabelsTree();
    } catch (e) {
      debug.error('ERROR in updateShopTicketLabel', e);
      throw e;
    }
  }

  async deleteTicketLabel(id: string) {
    try {
      await this.ticketApi.deleteTicketLabel(id);
      await this.getTicketLabels(false);
      this.updateLabelsTree();
    } catch(e) {
      debug.error('ERROR in deleteTicketLabel', e);
      throw e;
    }
  }

  async deleteShopTicketLabel(id: string) {
    try{
      await this.ticketApi.deleteTicketLabel(id);
      await this.getTicketLabels(false);
      this.updateLabelsTree();
    } catch (e) {
      debug.error('ERROR in deleteShopTicketLabel', e);
      throw e;
    }
  }

}
