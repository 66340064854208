import {Injectable} from "@angular/core";
import {HttpService} from "@etop/common";

@Injectable({
  providedIn: 'root'
})

export class AccountShipnowApi {
  constructor(
    private http: HttpService
  ) {}

  getAccountShipnow(connection_id: string, identity: string) {
    return this.http.post('api/shop.AccountShipnow/GetAccountShipnow', {connection_id, identity})
      .toPromise();
  }

}
