import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotPermissionComponent } from 'libs/shared/components/not-permission/not-permission.component';
import {EmptyPageModule} from "../empty-page/empty-page.module";

@NgModule({
  declarations: [
    NotPermissionComponent
  ],
  exports: [
    NotPermissionComponent
  ],
  imports: [
    CommonModule,
    EmptyPageModule
  ]
})
export class NotPermissionModule { }
