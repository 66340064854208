import { Injectable } from '@angular/core';
import { AsyncScrollDirective } from 'libs/shared/directives/async-scroll.directive';

@Injectable({
  providedIn: 'root'
})
export class AsyncScrollControllerService {
  private _scrollComponents: Array<AsyncScrollDirective> = [];

  constructor() { }

  registerInstance(instance: AsyncScrollDirective) {
    this._scrollComponents.push(instance);
  }

  toScroll(scroll_id, scroll_top) {
    this._scrollComponents.filter(item => item.sharedAsyncScroll == scroll_id)
      .forEach(item => {
        item.toScroll(scroll_top);
      });
  }

}
