import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthenticateModule} from '@etop/core';
import {LoggerService} from './services/logger.service';
import {StorageService} from './services/storage.service';
import {HttpService} from '@etop/common';
import {UserBehaviourTrackingService} from './services/user-behaviour-tracking.service';
import {UserService} from './services/user.service';
import {HttpClientModule} from '@angular/common/http';
import {MenuItemComponent} from './components/menu-item/menu-item.component';
import {AdminGuard} from './guards/admin.guard';
import {WindowMessageService, WindowRef} from 'apps/core/src/services/window-message.service';
import {MappingService} from 'apps/core/src/services/mapping.service';
import {ModalControllerModule} from 'apps/core/src/components/modal-controller/modal-controller.module';
import {HeaderModule} from 'apps/core/src/components/header/header.module';
import {CrmApi} from 'apps/core/src/apis/crm-api.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {TelegramApi} from 'apps/core/src/apis/telegram-api.service';
import {VerifyWarningComponent} from './components/header/components/verify-warning/verify-warning.component';
import {GeneralApiModule} from '@etop/api';
import {MenuSliderComponent} from './components/menu-item/menu-slider/menu-slider.component';
import {MaterialModule} from "@etop/shared";
import { NewSidebarComponent } from './components/new-sidebar/new-sidebar.component';
import { TenantWarningComponent } from './components/header/components/tenant-warning/tenant-warning.component';

const apis = [CrmApi, TelegramApi];

const services = [
  HttpService,
  LoggerService,
  StorageService,
  UserBehaviourTrackingService,
  UserService,
  WindowRef,
  WindowMessageService,
  MappingService
];

const guards = [AdminGuard];

const components = [MenuItemComponent, VerifyWarningComponent, NewSidebarComponent, TenantWarningComponent];

@NgModule({
  declarations: [...components, MenuSliderComponent],
  exports: [...components, ModalControllerModule, HeaderModule],
  providers: [...apis, ...services, ...guards],
  imports: [
    RouterModule,
    CommonModule,
    HttpClientModule,
    HeaderModule,
    ModalControllerModule,
    NgbModule,
    GeneralApiModule,
    AuthenticateModule.forRoot(),
    MaterialModule
  ],

  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoreModule {
  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...services, ...guards]
    };
  }
}
