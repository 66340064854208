import { Component, OnInit } from '@angular/core';
import { UtilService } from 'apps/core/src/services/util.service';


@Component({
  selector: 'etelecom-cs-register',
  template: `
    <etelecom-cs-mobile-register class='show-768'></etelecom-cs-mobile-register>
    <etelecom-cs-desktop-register class='hide-768'></etelecom-cs-desktop-register>
  `,
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(
    private util: UtilService
  ) { }

  ngOnInit(): void {
  }
}
