import { BehaviorSubject, Observable } from 'rxjs';
import Queue from 'queue';

export abstract class AStore<T> {
  public readonly _state: BehaviorSubject<T>;
  readonly state$: Observable<T>;
  protected initState: T;

  private _queue = new Queue({
    concurrency: 1,
    autostart: true
  });

  get snapshot(): T {
    return {
      ...this.initState,
      ...this._state.getValue(),
    };
  }

  protected set state(val: T) {
    this._state.next(val);
  }

  protected setState(val: Partial<T>) {
    this._queue.push(cb => {
      this._state.next({
        ...this.snapshot,
        ...val
      })
      cb();
    })

  }

  public invoke() {
    this.setState({});
  }

  public clear() {
    this._state.next(this.initState);
  }

  protected constructor() {
    this._state = new BehaviorSubject<T>(this.initState);
    this.state$ = this._state.asObservable();
  }
}
