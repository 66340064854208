import { BaseModel } from '../core/base/base-model';
import { Connection } from './Connection';

export const STATUS_DISPLAY = {
    "P": "Đang kích hoạt",
    "N": "Đang tắt",
  };

  export const SMS_TYPE_DISPLAY = {
    "customer_service": "CSKH",
    "advertising": "Quảng cáo",
    "unknown": "Khác"
  };

export enum SmsTabs {
    template = 'template',
    list = 'list',
    report = 'report'
}

export enum AdminSmsTabs {
    carrier = 'carrier',
    report = 'report'
}

export class Sms extends BaseModel {
    id: string;
    sender: string;
    phone: string;
    created_at: string;
    updated_at: string;
    status: string;
}

export class SmsCarrier extends BaseModel {
    id: string;
    brand_name: string;
    phone: string;
    created_at: string;
    updated_at: string;
    status: string;
}

export class SmsTemplate extends BaseModel {
    brand_name: string;
    connection_id: string | number;
    content: string;
    name: string;
    networks: string[];
    shop_id: string;
    type: string;
    unicode: boolean;
    id: string;
    status: string;
    created_at: string;
    updated_at: string;
    params: {
        max_length: number;
        min_length: number;
        name: string;
        required: boolean;
        type: string;
    };
    provider?: Connection;
    type_display?: string;
}
