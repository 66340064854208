import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';

const DASHBOARD_SPEC = {
    total_cod_amount: "SUM(total_cod_amount):shop_confirm = 1 and shipping_code != null and shipping_state not in (default, cancelled) and status not in (0, -1)",
    shop_confirm: "count:shop_confirm = 1 and shipping_code != null and shipping_state not in (default, cancelled) and status not in (0, -1)",
    returned: "count:shipping_state in (returning, returned)",
    delivered: "count:shipping_state in (delivered)",
    carrier: "count:shipping_state in (holding, delivering, undeliverable)",
    basket_value: "SUM(basket_value):shop_confirm = 1 and shipping_code != null and shipping_state not in (default, cancelled) and status not in (0, -1)",
    new_customer: "count:deleted_at is null",
    distinct_customer: "count(khach_hang_da_tu_van):deleted is null",
    comment: "count:comment_da_gui",
    message: "count:tin_nhan_da_gui",
    order: "count:status != -1 or shipping_state != cancelled",
    cancelled: "count:shipping_state = cancelled"
  };

@Injectable({
    providedIn: 'root'
})

export class StatisticApi{
    constructor(
      private http: HttpService,
    ) { }

    summaryShop({ date_from, date_to }) {
        return this.http
            .post(`api/fabo.Summary/SummaryShop`, {date_from, date_to })
            .toPromise()
            .then(res => this._tableMap(res.tables))
    }

    summaryStaff({ date_from, date_to }) {
        return this.http
            .post(`api/fabo.Summary/SummaryShop`, {date_from, date_to })
            .toPromise()
            .then(res => res.tables)
    }

    private _tableMap(tables: any) {
        let _todayData = [];
        let _chartData = [];
        let _totalData = [];
        tables[0].data.forEach(_data => {
            _todayData.push(..._data)
        })
        tables[1].data.forEach(_data => {
            _chartData.push(..._data)
        })
        tables[2].data.forEach(_data => {
            _totalData.push(..._data)
        })
        
        return {
            today: {
                basket_value: this._valueFromSpec(_todayData, DASHBOARD_SPEC.basket_value),
                total_cod_amount: this._valueFromSpec(_todayData, DASHBOARD_SPEC.total_cod_amount),
                new_customer: this._valueFromSpec(_todayData, DASHBOARD_SPEC.new_customer),
                shop_confirm: this._valueFromSpec(_todayData, DASHBOARD_SPEC.shop_confirm),
                carrier: this._valueFromSpec(_todayData, DASHBOARD_SPEC.carrier),
                delivered: this._valueFromSpec(_todayData, DASHBOARD_SPEC.delivered),
                returned: this._valueFromSpec(_todayData, DASHBOARD_SPEC.returned),
                cancelled: this._valueFromSpec(_todayData, DASHBOARD_SPEC.cancelled)
            },
            charts: {
                total_cod_amount: this._chartMap(_chartData, DASHBOARD_SPEC.total_cod_amount),
                shop_confirm: this._chartMap(_chartData, DASHBOARD_SPEC.shop_confirm),
                returned: this._chartMap(_chartData, DASHBOARD_SPEC.returned),
                delivered: this._chartMap(_chartData, DASHBOARD_SPEC.delivered),
                cancelled: this._chartMap(_chartData, DASHBOARD_SPEC.cancelled),
                carrier: this._chartMap(_chartData, DASHBOARD_SPEC.carrier),
                basket_value: this._chartMap(_chartData, DASHBOARD_SPEC.basket_value)
            },
            total: {
                basket_value: this._valueFromSpec(_totalData, DASHBOARD_SPEC.basket_value),
                total_cod_amount: this._valueFromSpec(_totalData, DASHBOARD_SPEC.total_cod_amount),
                new_customer: this._valueFromSpec(_totalData, DASHBOARD_SPEC.new_customer),
                shop_confirm: this._valueFromSpec(_totalData, DASHBOARD_SPEC.shop_confirm),
                carrier: this._valueFromSpec(_totalData, DASHBOARD_SPEC.carrier),
                delivered: this._valueFromSpec(_totalData, DASHBOARD_SPEC.delivered),
                returned: this._valueFromSpec(_totalData, DASHBOARD_SPEC.returned),
                cancelled: this._valueFromSpec(_totalData, DASHBOARD_SPEC.cancelled)
            },
            staff: this._staffMap(tables[3])
        }
    }

    private _chartMap(data, spec) {
        let _data =  data.filter(d => d.spec.includes(spec))
        return _data.map(d => Object.assign({
            value: d.value,
            date: d.spec.split(',').pop()
          })
        )
    }

    private _staffMap(data) {
        let _staffData = [];
        data.data.forEach(_data => {
            _staffData.push(..._data)
        })
        let user_ids = data.columns.map(column => column.spec.split(',')[0].replace('user_id = ', ''));
        let _tables = [];
        user_ids.forEach((user, index) => {
            _tables.push({
                rank: index + 1,
                user,
                new_customer: this._columnMap(_staffData, user,  DASHBOARD_SPEC.new_customer),
                distinct_customer: this._columnMap(_staffData, user, DASHBOARD_SPEC.distinct_customer),
                message: this._columnMap(_staffData, user, DASHBOARD_SPEC.message),
                comment: this._columnMap(_staffData, user, DASHBOARD_SPEC.comment),
                order: this._columnMap(_staffData, user, DASHBOARD_SPEC.order),
                basket_value: this._columnMap(_staffData, user, DASHBOARD_SPEC.basket_value),
                total_cod_amount: this._columnMap(_staffData, user, DASHBOARD_SPEC.total_cod_amount)
            })
        })
        return _tables;
    }

    private _columnMap(data, user, spec) {
        let _data = data.find(d => d.spec.includes(spec) && d.spec.includes(user));
        return _data?.value
    }

    private _valueFromSpec(data, spec) {
        let _data = data.find(d => d.spec.includes(spec))
        return _data?.value
    }

}