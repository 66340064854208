import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthenticateStore} from '@etop/core';

@Injectable()
export class AppGuard implements CanActivate {
  constructor(private auth: AuthenticateStore, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (!this.auth.snapshot.user) {
      this.router.navigate(['/register']);
      return false;
    }
    return true;
  }
}
