import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { Connection, ConnectionMethod } from 'libs/models/Connection';

@Injectable({
  providedIn: 'root'
})
export class AdminConnectionApi {

  constructor(
    private http: HttpService
  ) { }

  getConnections(connection_method?: ConnectionMethod) {
    return this.http
      .post('api/admin.Connection/GetConnections', {connection_method})
      .toPromise()
      .then(res => res.connections.map(conn => Connection.connectionMap(conn)));
  }

  getTelecomConnection() {
    return this.http
      .post('api/admin.Connection/GetTelecomConnection', {})
      .toPromise()
      .then(res => res.connections);
  }

  getConnectionServices(id: string) {
    return this.http
      .post('api/admin.Connection/GetConnectionServices', {id})
      .toPromise()
      .then(res => res.connection_service);
  }

  disableConnection(id: string) {
    return this.http
      .post('api/admin.Connection/DisableConnection', {id})
      .toPromise();
  }

  confirmConnection(id: string) {
    return this.http
      .post('api/admin.Connection/ConfirmConnection', {id})
      .toPromise();
  }

  updateBuiltinShopConnection(body: AdminConnectionAPI.UpdateBuiltinShopConnectionRequest) {
    return this.http
      .post('api/admin.Connection/UpdateBuiltinShopConnection', body)
      .toPromise();
  }

  getConnectDirectShipmentShopSetting(shop_id: string) {
    return this.http
      .post('api/admin.Connection/GetConnectDirectShipmentShopSetting', {shop_id})
      .toPromise();
  }

  updateConnectDirectShipmentShopSetting(allow_connect_direct_shipment: boolean, shop_id: string) {
    return this.http
      .post('api/admin.Connection/UpdateConnectDirectShipmentShopSetting', {allow_connect_direct_shipment, shop_id})
      .toPromise();
  }

  updateMinShopBalanceShopSetting(body: AdminConnectionAPI.MinShopBalanceShopSetting) {
    return this.http
      .post('api/admin.Connection/UpdateMinShopBalanceShopSetting', body)
      .toPromise()
      .then(res => res);
  }

  getMinShopBalanceShopSetting(shop_id: string) {
    return this.http
      .post('api/admin.Connection/GetMinBalanceShopSetting', { shop_id })
      .toPromise()
      .then(res => res);
  }

  getSMSConnections() {
    return this.http
      .post('api/admin.Connection/GetSMSConnections', {})
      .toPromise()
      .then(res => res?.connections);
  }

  createSMSConnection(query: {connection_provider: string, name: string, password: string}) {
    return this.http
      .post('api/admin.Connection/CreateSMSConnection', query)
      .toPromise()
      .then(res => res);
  }

  deleteConnection(id: string) {
    return this.http
      .post('api/admin.Connection/DeleteConnection', {id})
      .toPromise()
      .then(res => res);
  }

}

export namespace AdminConnectionAPI {
  export interface UpdateBuiltinShopConnectionRequest {
    connection_id: string;
    external_data: {
      user_id: string;
      email?: string;
    },
    token: string;
  }

  export class  MinShopBalanceShopSetting {
    min_shop_balance: number;
    shop_id: string;
    balance_type: 'actual' | 'expected' | 'credit';
  }
}
