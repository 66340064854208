<div class="modal-header">
  <h4 class="font-weight-bold">Thay đổi mật khẩu</h4>
</div>
<div class="modal-body">
  <div class="row pt-3">
    <div class="col">
      <etop-material-input placeholder="Mật khẩu hiện tại" textLabel="Mật khẩu hiện tại"
                           [(ngModel)]="changePasswordData.currentPassword" type="input" keyboardType="password">
      </etop-material-input>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <etop-material-input placeholder="Mật khẩu mới" textLabel="Mật khẩu mới"
                           [(ngModel)]="changePasswordData.newPassword" type="input" keyboardType="password">
      </etop-material-input>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <etop-material-input placeholder="Nhập lại mật khẩu" textLabel="Nhập lại mật khẩu"
                           [(ngModel)]="changePasswordData.confirmNewPassword" type="input" keyboardType="password">
      </etop-material-input>
    </div>
  </div>
  <div *ngIf="error" class="mt-2">
    <small class="text-danger">
      {{errorMessage}}
    </small>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline btn-default" (click)="close()">Hủy</button>
  <button type="submit" class="btn btn-primary" [disabled]="loading" (click)="save()">
    <i class="fa fa-circle-o-notch fa-spin" aria-hidden="true" *ngIf="loading"></i> Thay đổi mật khẩu</button>
</div>
