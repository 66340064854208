import { Component, OnInit } from '@angular/core';
import {TenantService} from "@etop/state/shop/tenant/tenant.service";
import {TelegramService} from "@etop/features";
import { AuthenticateStore } from '@etop/core';

@Component({
  selector: 'core-tenant-warning',
  templateUrl: './tenant-warning.component.html',
  styleUrls: ['./tenant-warning.component.scss']
})
export class TenantWarningComponent implements OnInit {
  loading = false;
  showWarning = true;
  constructor(
    private tenantService: TenantService,
    private telegramService: TelegramService,
    private auth: AuthenticateStore,
  ) { }

  ngOnInit(): void {
  }

  async createTenant() {
    this.loading = true;
    try {

      const connection_id = this.auth.snapshot.shopSetting?.connection_id;
      const tenant = await this.tenantService.createTenant(connection_id);

      this.showWarning = false;
      this.telegramService.newTenantMessage(tenant);
      toastr.success('Tạo tổng đài thành công');
    } catch (e) {
      toastr.error(e.message, 'Tạo tổng đài thất bại');
    }
    this.loading = false;
  }

}
