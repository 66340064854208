<div class="modal-header py-2">
  <h5 style="margin: auto 0 !important;">Xác nhận số điện thoại</h5>
  <button type="button" class="btn text-large" data-dismiss="modal">&times;</button>
</div>
<div class="login-box-body p-0">
  <form role="form" class="form-horizontal p-0" #form="ngForm">
    <div class="p-3 border-top">
      <div class="row">
        <label for="token" required class="col-sm-4 control-label mb-0 align-self-center">
          Mã xác nhận:
        </label>
        <div class="col-sm-8">
          <input name="token" type="text" class="form-control" 
            placeholder="Mã xác nhận trong tin nhắn" [(ngModel)]="verification_token" />
        </div>
      </div>
    </div>
    <div class="p-3 border-top d-flex flex-row-reverse justify-content-between">
      <button class="btn btn-primary" type="submit"  [disabled]="!form.form.valid"
        (click)="verifyPhone()">
        <i *ngIf="loading" class="fa fa-circle-o-notch fa-spin" aria-hidden="true" style="font-size: 12" ></i>
        Xác nhận
      </button>
      <button [disabled]="countDown" class="btn btn-outline " [class.btn-default]="countDown" [class.text-default]="countDown" (click)="resendCode()" [class.btn-primary]="!countDown" [class.text-primary]="!countDown">
        <i class="fas fa-sync-alt mr-1" *ngIf="!countDown"></i>
        Gửi lại mã <span [hidden]="!countDown">({{countDown}}s)</span>
      </button>
    </div>
  </form>
</div>