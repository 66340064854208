import {Injectable} from '@angular/core';
import {AuthenticateStore} from '@etop/core';
import {CmsService} from 'apps/core/src/services/cms.service';
import {SubscriptionApi, SubscriptionAPI} from "@etop/api/shop/subscription.api";

@Injectable({
  providedIn: "root"
})
export class SubscriptionService {
  constructor(
    private subscriptionApi: SubscriptionApi,
    private auth: AuthenticateStore,
    private cms: CmsService,
  ) {
  }

  async getSubscriptions(request: SubscriptionAPI.GetSubscriptionsRequest) {
    return await this.subscriptionApi.getSubscriptions(request);
  }

  async checkTrialSubscriptions() {
    await this.cms.initBanners();
    const dataBanner = this.cms.getMessageNotSubscription();
    let user_created_at = Date.parse(this.auth.snapshot.user.created_at);
    let trial_date = user_created_at + (dataBanner.trial_date * 86400000);
    let now = Date.now();
    return Math.ceil((trial_date - now) / 86400000);
  }

  async checkSubcriptions() {
    return true;
    let trialRemain = await this.checkTrialSubscriptions();
    if (trialRemain >= 0)
      return true;
    let filters = [];
    filters.push({
      name: "account_id",
      op: "=",
      value: this.auth.snapshot.account.id
    });
    let subscriptions = await this.getSubscriptions({filters});
    if (!subscriptions.length)
      return false;
    subscriptions.forEach(sub => {
      if (sub.status != "P")
        return false;
      if (!sub.lines.length)
        return false;
    })
    return true;
  }

  async checkSubcriptionsNoTrial() {
    let filters = [];
    filters.push({
      name: "account_id",
      op: "=",
      value: this.auth.snapshot.account.id
    });
    let subscriptions = await this.getSubscriptions({filters});
    if (!subscriptions.length)
      return false;
    subscriptions.forEach(sub => {
      if (sub.status != "P")
        return false;
      if (!sub.lines.length)
        return false;
    })
    return true;
  }
}
