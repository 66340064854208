import { BaseModel } from '../core/base/base-model';
import { AuthenticateStore } from '@etop/core';
import { Relationship } from '@etop/models/Authorization';
import { Contact } from '@etop/models/Contact';
import { Hotline } from '@etop/models/Hotline';
import { Department } from './Department';


export enum CallLogState {
  not_answered = "not_answered",
  answered = "answered",
}

export enum CallLogTab {
  general = "general",
  personal = "personal",
}

const TICKET_STATE = {
  not_answered: 'Gọi nhỡ',
  answered: "Thành công",
};

export class CallLog extends BaseModel {
  account_id: string;
  audio_urls: string[];
  call_state: 'not_answered' | 'answered';
  call_targets?: CallTarget[];
  call_status: any;
  callee: string;
  caller: string;
  contact_id: string;
  created_at: string;
  direction: string;
  duration: number;
  direction_display: string;
  extension_id: string;
  extension_number: string;
  external_call_status: string;
  external_direction: string;
  external_id: string;
  id: string;
  started_at: Date;
  ended_at: Date;
  updated_at: Date;
  callerRelationship? : Relationship;
  callerContact?: Contact;
  calleeRelationship? : Relationship;
  calleeContact?: Contact;
  state_display? : string;
  hotline?: Hotline;
  hotline_id: string;
  from?: Relationship | Contact;
  to?: Relationship | Contact;
  user_id: string;
  department_id: string;
  department?: Department;
  reasson_fail_dislay?: string;
  zl_user_name_caller?: string;
  zl_user_name_callee?: string;

  static callLogMap(callLog: CallLog): CallLog {
    callLog.direction_display = callLog.direction == "out" ? "GỌI RA" : "GỌI VÀO";
    callLog.state_display = TICKET_STATE[CallLogState[callLog.call_state]];

    return callLog;
  }

}

export class CallTarget extends BaseModel {
  add_time: string;
  answered_time: string;
  end_reason: string;
  ended_time: string;
  fail_code: string;
  ring_duration: string;
  ring_time: string;
  status: string;
  target_number: string;
  trunk_name: string;
}
