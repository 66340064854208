import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';

@Injectable({
  providedIn: 'root'
})
export class TenantApi {
  constructor(
    private http: HttpService,
  ) {
  }

  async getTenant(connection_id: string) {
    return this.http.post('api/shop.Etelecom/GetTenant', {connection_id}).toPromise();
  }

  async createTenant(connection_id: string) {
    return this.http.post('api/shop.Etelecom/CreateTenant', {connection_id}).toPromise();
  }

}
