import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticateStore } from './authenticate.store.service';
import { RoleDirective } from './role.directive';
import { RolesDirective } from './roles.directive';
import { PermissionDirective } from './permission.directive';
import { PermissionsDirective } from './permissions.directive';
import { AuthenticateService } from './authenticate.service';

@NgModule({
  declarations: [RoleDirective, RolesDirective, PermissionDirective, PermissionsDirective],
  exports: [RoleDirective, RolesDirective, PermissionDirective, PermissionsDirective],
  imports: [
    CommonModule
  ]
})
export class AuthenticateModule {
  static forRoot(): ModuleWithProviders<AuthenticateModule> {
    return {
      ngModule: AuthenticateModule,
      providers: []
    };
  }
}
