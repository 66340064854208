import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { UserService } from 'apps/core/src/services/user.service';
import { ModalAction } from '../../../modal-controller/modal-action.service';
import { CommonUsecase } from 'apps/shared/src/usecases/common.usecase.service';
import { Router } from '@angular/router';
import { AuthenticateStore } from '@etop/core';

@Component({
  selector: 'shop-verify-account-modal',
  templateUrl: './verify-account-modal.component.html',
  styleUrls: ['./verify-account-modal.component.scss']
})
export class VerifyAccountModalComponent implements OnInit {
  @Input() verifyEmail : boolean;
  @Input() phone : string;
  @Input() email : string;

  
  verifyCode : string;
  countDown = 60;
  intervalId: any;
  loading = false;
  constructor(
    private userService: UserService,
    private modalDismiss: ModalAction,
    private commonUsecase: CommonUsecase,
    private router: Router,
    private auth: AuthenticateStore,
    private ref : ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.intervalId = setInterval(() => {
      this.countDown--;
      if (this.countDown == 0) {
        clearInterval(this.intervalId);
      }
    }, 1000);
  }

  async verifyOTP() {
    if(!this.verifyCode){
      toastr.error('Vui lòng nhập mã xác nhận!');
      return;
    }
    this.loading = true;
    try{
      let res;
      if(this.verifyEmail) {
         res = await this.userService.verifyEmailUsingOTP(this.verifyCode);
      } else {
         res = await this.userService.verifyPhoneUsingToken(this.verifyCode);
      }
      this.modalDismiss.dismiss(null);
      await this.commonUsecase.updateSessionInfo(true);
      toastr.success(res.msg);
    }
    catch(e) {
      toastr.error(e.message, 'Yêu cầu xác thực tài khoản thất bại!');
    }
    this.loading = false;
  }

  async resendCode() {
    if (this.countDown) {
      return;
    } else {
      this.countDown = 60; 
      this.intervalId = setInterval(() => {
        this.countDown--;
        if (this.countDown == 0) {
          clearInterval(this.intervalId);
        }
      }, 1000);
    }
    this.loading = true;
    try {
      if(this.verifyEmail) {
        await this.userService.sendEmailVerificationUsingOTP(this.email);
      } else {
        await this.userService.sendPhoneVerification(this.phone);
      }
      toastr.success(`Một mã xác nhận mới vừa được gửi vào ${this.verifyType} của bạn`);
    } catch (e) {
      toastr.error(e.message);
    }
    this.loading = false;
  }
  

  get verifyType() {
    return this.verifyEmail ? 'email' : 'số điện thoại';
  }

  get verifyAccount() {
    return this.verifyEmail ? this.email : this.phone;
  }
}
