import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {TicketLabel} from "@etop/models";
import {StateTicketLabel, TicketLabelStore} from "./ticket-label.store";

@Injectable({
  providedIn: 'root'
})
export class TicketLabelQuery extends QueryEntity<StateTicketLabel, TicketLabel> {

  constructor(protected store: TicketLabelStore) {
    super(store);
  }
}
