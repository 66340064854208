<div class="wrapper-filter" *ngIf="filters?.length">
    <div class="row" [class.topship]="topshipFilter">
        <div [ngClass]="availableFilters?.length ? 'col-9' : 'col-12'">
            <div class="row">
                <ng-container *ngIf="query">
                    <div class="col-2">
                        <etop-material-input textLabel="{{query.label}}" placeholder="{{query.label}}" type="input" (keyup.enter)="onSubmitQuery()" (blur)="onSubmitQuery()" [(ngModel)]="query.value">
                        </etop-material-input>
                    </div>
                </ng-container>
                <ng-content select="[pre]"></ng-content>
                <ng-container *ngFor="let option of filters">
                    <div class="col-2 pb-2" *ngIf="option.show || option.fixed">
                        <etop-material-input *ngIf="option.type == 'input'" type="input" [appearance]="'outline'" textLabel="{{option.label}}" placeholder="{{option.label}}" (keyup.enter)="onSubmitFilter()" (blur)="onSubmitFilter()" [(ngModel)]="option.value">
                        </etop-material-input>
                        <etop-material-input [appearance]="'outline'" *ngIf="option.type == 'select'" type="select" textLabel="{{option.label}}" placeholder="{{option.label}}" [displayMap]="option.displayMap" [valueMap]="option.valueMap" [optionSelect]="option.options" [matOptionHeight]="option.optionsHeight"
                            [topshipInput]="topshipFilter" [(ngModel)]="option.value" (ngModelChange)="onSubmitFilter()">
                        </etop-material-input>
                        <etop-material-input [appearance]="'outline'" *ngIf="option.type == 'select-multiple'" type="select-multiple" textLabel="{{option.label}}" placeholder="{{option.label}}" [displayMap]="option.displayMap" [valueMap]="option.valueMap" [optionSelect]="option.options"
                            [matOptionHeight]="option.optionsHeight" [topshipInput]="topshipFilter" [(ngModel)]="option.value" (ngModelChange)="onSubmitFilter(true)">
                        </etop-material-input>
                        <etop-material-input *ngIf="option.type == 'datetime'" type="datetime" [appearance]="'outline'" textLabel="{{option.label}}" placeholder="{{option.label}}" [(ngModel)]="option.value" (ngModelChange)="onSubmitFilter()">
                        </etop-material-input>
                        <etop-material-input-autocomplete *ngIf="option.type == 'autocomplete'" [appearance]="'outline'" placeholder="{{option.label}}" textLabel="{{option.label}}" [options]="option.options" [displayMap]="option.displayMap" [valueMap]="option.valueMap" [(ngModel)]="option.value"
                            (ngModelChange)="onSubmitFilter()">
                        </etop-material-input-autocomplete>
                    </div>
                </ng-container>
                <ng-content></ng-content>
            </div>
        </div>

        <div class="col-3 ml-auto" *ngIf="availableFilters?.length">
            <div class="d-flex align-items-center btn-sort position-relative">
                <mat-form-field appearance="outline">
                    <mat-label>Chọn bộ lọc</mat-label>
                    <mat-select multiple [formControl]="toppings" [panelClass]="{'topship': topshipFilter}" (selectionChange)="changeSelect($event.value)">
                        <mat-select-trigger>
                            <span *ngIf="toppings.value?.length > 0">Đang chọn: {{toppings.value[0].label}}</span>
                            <span *ngIf="toppings.value?.length > 1" class="example-additional-selection">
                (+ {{toppings.value.length - 1}} mục khác)
              </span>
                        </mat-select-trigger>
                        <mat-option *ngFor="let option of availableFilters" [value]="option">
                            {{option.label}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>