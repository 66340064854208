import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { EtopTablePlaceholderComponent } from './etop-table-placeholder/etop-table-placeholder.component';
import { EmptyComponent } from './empty/empty.component';
import { EtopPaginationComponent } from './etop-pagination/etop-pagination.component';
import { EtopTableComponent } from './etop-table/etop-table.component';
import { SharedDirectivesModule } from '@etop/shared/directives';
import { CommonModule } from '@angular/common';
import { EtopTableNewComponent } from './etop-table-new';

@NgModule({
  imports: [CommonModule, SharedDirectivesModule],
  declarations: [
    EtopPaginationComponent,
    EtopTableComponent,
    EtopTableNewComponent,
    EmptyComponent,
    EtopTablePlaceholderComponent
  ],
  exports: [
    EtopPaginationComponent,
    EtopTableComponent,
    EtopTableNewComponent,
    EmptyComponent,
    EtopTablePlaceholderComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EtopCommonModule {

}
