import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export enum EmptyType {
  default = "default",
  search = "search",
  block = "block",
  error = "error"
}

export interface ActionButton {
  title: string;
  onClick?: () => void;
  cssClass: string;
  icon?: string;
}

@Component({
  selector: 'etop-empty-page',
  templateUrl: './empty-page.component.html',
  styleUrls: ['./empty-page.component.scss']
})
export class EmptyPageComponent implements OnInit {

  constructor() { }

  @Input() title: String;
  @Input() description: String;
  @Input() type: EmptyType = EmptyType.default;
  @Input() actions: ActionButton[] = [];
  @Output() click = new EventEmitter();

  ngOnInit(): void {
    if(!this.title)
      this.titleDisplay();
    if(!this.description){
      this.descriptionDisplay();
    }
  }

  get imageUrl(){
    if(this.type == EmptyType.default)
      return "assets/images/empty_default.png";
    if(this.type == EmptyType.search)
      return "assets/images/empty_search.png";
    if(this.type == EmptyType.block)
      return "assets/images/empty_block.png";
    if(this.type == EmptyType.error)
      return "assets/images/empty_error.png";
  }

  titleDisplay(){
    if(this.type == EmptyType.default)
      this.title = "Không có dữ liệu";
    else if(this.type == EmptyType.search)
      this.title = "Không tìm thấy";
    else if(this.type == EmptyType.block)
      this.title = "Không có quyền truy cập";
    else if(this.type == EmptyType.error)
      this.title = "Không thể truy cập";
  }

  descriptionDisplay(){
    if(this.type == EmptyType.search)
      this.description = "Không tìm thấy kết quả phù hợp. Vui lòng thử lại với từ khoá hoặc bộ lọc khác.";
    else if(this.type == EmptyType.block)
      this.description = "Bạn không có quyền truy cập vào trang hiện tại. Vui lòng liên hệ chủ cửa hàng để được hỗ trợ.";
    else if(this.type == EmptyType.error)
      this.description = "Trang bạn muốn truy cập không tồn tại hoặc đã có lỗi xảy ra. Vui lòng thử lại.";
  }

  handleClick(action) {
    this.click.emit();
    if (action && action.onClick) {
      action.onClick();
    }
  }
}
