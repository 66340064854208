import { BaseModel } from '@etop/core/base/base-model';

export declare type ConnectionStatus = 'Z' | 'P' | 'N';
export declare type ConnectionMethod = 'unknown' | 'builtin' | 'direct';
export declare type ConnectionProvider = 'unknown' | 'ghn' | 'ghtk' | 'vtpost' | 'partner' | 'ahamove';
export declare type ConnectionSubType = 'unknown' | 'shipment' | 'manual' | 'shipnow';
export declare type ConnectionType = 'unknown' | 'shipping';

export const CONNECTION_PROVIDER_LOGO = {
  ghn: 'provider_logos/ghn-s.png',
  ghtk: 'provider_logos/ghtk-s.png',
  vtpost: 'provider_logos/vtpost-s.png',
  ahamove: 'provider_logos/ahamove-s.png',
  dhl: 'provider_logos/dhl-s.png',
  ninjavan: 'provider_logos/ninjavan-s.png',
  default: 'placeholder.png',
  unknown: 'placeholder.png'
};

export const PROVIDER_FULLNAME = {
  ghn: 'Giao hàng nhanh',
  'ghn - v2': 'Giao hàng nhanh',
  ghtk: 'Giao hàng tiết kiệm',
  vtpost: 'Viettel Post',
  ahamove: 'Ahamove'
};

export const STATUS = {
  Z: 'Chưa kết nối',
  N: 'Huỷ kết nối',
  P: 'Đã kết nối'
};

export const HOMEPAGE = {
  ghn: 'https://ghn.vn',
  'ghn - v2':'https://ghn.vn',
  ghtk: 'https://giaohangtietkiem.vn'
};

export const POLICY_LINK = {
  ghn: 'https://ghn.vn/pages/dieu-khoan-su-dung',
  'ghn - v2': 'https://ghn.vn/pages/dieu-khoan-su-dung',
  ghtk: 'https://giaohangtietkiem.vn/dich-vu-giao-hang-ghtk'
};

export const GUIDE_LINK = {
  ahamove: 'https://www.etop.vn/huong-dan/huong-dan-xac-thuc-tai-khoan-ahamove-7204'
}

export class Connection extends BaseModel {
  id: string;
  name: string;
  status: ConnectionStatus;
  created_at: Date;
  updated_at: Date;
  connection_type: ConnectionType;
  connection_subtype: ConnectionSubType;
  connection_method: ConnectionMethod;
  connection_provider: ConnectionProvider;
  image_url: string;

  //NOTE: shipnow
  external_verified: boolean;

  connect_status?: ConnectionStatus;
  connection_services?: ConnectionService[];

  provider_logo?: string;
  name_display?: string;
  status_display?: string;
  connection_email?: string;
  connection_identifier?: string;

  guide_link?: string;
  homepage?: string;
  policy_link?: string;
  tracking_url?: string;

  static getProviderLogo(provider: ConnectionProvider) {
    return `assets/images/${CONNECTION_PROVIDER_LOGO[provider] || CONNECTION_PROVIDER_LOGO.default}`;
  }

  static connectionMap(connection: Connection): Connection {
    return {
      ...connection,
      provider_logo: connection.image_url || Connection.getProviderLogo(connection.connection_provider),
      status_display: STATUS[connection.status],
      name_display: PROVIDER_FULLNAME[connection.name.toLowerCase()] || connection.name,
      homepage: HOMEPAGE[connection.name.toLowerCase()],
      policy_link: POLICY_LINK[connection.name.toLowerCase()],
      guide_link: GUIDE_LINK[connection.name.toLowerCase()]
    };
  }
}

export class ShopConnection extends BaseModel {
  shop_id: string;
  connection_id: string;
  status: ConnectionStatus;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  is_global: boolean;
  external_data: {
    email: string;
    identifier: string;
  };
}

export class ConnectDirectShipmentShopSetting extends BaseModel {
  shop_id: string;
  allow_connect_direct_shipment: boolean;
}

export class MinShopBalanceSetting extends BaseModel {
  shop_id: string;
  min_shop_balance: number;
  balance_type: 'actual' | 'expected';
}

export class ConnectionService {
  name: string;
  service_id: string;
}
