import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private domSanitized: DomSanitizer) {
  }

  transform(value: any, args?: any): any {
    return this.domSanitized.bypassSecurityTrustHtml(value);
  }

}



@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(private domSanitized: DomSanitizer) {
  }

  transform(value: any, args?: any): any {
    return this.domSanitized.bypassSecurityTrustResourceUrl(value);
  }

}

@Pipe({
  name: 'urlify',
})
export class UrlifyPipe implements PipeTransform {
  constructor(private _sanitizer: DomSanitizer) {}

  transform(value: any, args?: any): SafeHtml {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z\d+&@#/%?=~_|!:,.;]*[-A-Z\d+&@#/%=~_|])/gi;

      let html = value;

      if (value) {
        html  = value?.replace(
          urlRegex,
          (url) => `<a href="${url}" target="_blank" onclick="event.stopPropagation();">${url}</a>`
        );
      }
    return this._sanitizer.bypassSecurityTrustHtml(html);
  }
}
