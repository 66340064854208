import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import {CursorPaging} from "@etop/models";

@Injectable({
  providedIn: 'root'
})
export class DepartmentApi {

  constructor(private http: HttpService) {
  }

  async getDepartments(getDepartmentsRequest?: DepartmentAPI.GetDepartmentsRequest) {
    return await this.http.post('api/shop.Department/GetDepartments', getDepartmentsRequest)
      .toPromise().then(res => {
        return {
          paging: res.paging,
          departments: res.departments,
        }
      });
  }

  async createDepartment(createDepartmentRequest?: DepartmentAPI.CreateDepartmentRequest) {
    return await this.http.post('api/shop.Department/CreateDepartment', createDepartmentRequest).toPromise();
  }

  async deleteDepartment(id: String) {
    return await this.http.post('api/shop.Department/DeleteDepartment', {id}).toPromise();
  }

  async updateDepartment(updateDepartmentRequest?: DepartmentAPI.UpdateDepartmentRequest) {
    return await this.http.post('api/shop.Department/UpdateDepartment', updateDepartmentRequest).toPromise();
  }

}

export namespace DepartmentAPI {
  export class GetDepartmentsRequest {
    filter?: GetDepartmentsFilter;
    paging?: CursorPaging;
  }

  export class GetDepartmentsFilter {
    name?: string
  }

  export class CreateDepartmentRequest {
    name: string;
    description: string;
  }

  export class UpdateDepartmentRequest {
    name?: string;
    description?: string;
    id: string;
  }
}
