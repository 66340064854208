import { Injectable } from '@angular/core';
import { ListQueryDTO } from 'libs/models/CommonQuery';
import { PurchaseOrder, PurchaseOrderDiscount, PurchaseOrderLine } from 'libs/models/PurchaseOrder';
import { HttpService } from '@etop/common';

const STATUS = {
  Z: 'Chưa xác nhận',
  N: 'Huỷ',
  P: 'Đã xác nhận'
};

@Injectable()
export class PurchaseOrderApi {

  static get status() {
    return STATUS;
  }

  constructor(
    private http: HttpService
  ) { }

  static statusMap(status) {
    return PurchaseOrderApi.status[status];
  }

  getPurchaseOrder(id) {
    return this.http
      .post(`api/shop.PurchaseOrder/GetPurchaseOrder`, { id })
      .toPromise()
      .then(res => this.purchaseOrderMap(res));
  }

  getPurchaseOrders(query: Partial<ListQueryDTO>): Promise<any> {
    return this.http
      .post('api/shop.PurchaseOrder/GetPurchaseOrders', query)
      .toPromise()
      .then(res => {
        res.purchase_orders = res.purchase_orders.map(r => this.purchaseOrderMap(r));
        return res;
      });
  }

  getPurchaseOrdersByIDs(ids: Array<string>): Promise<any> {
    return this.http
      .post('api/shop.PurchaseOrder/GetPurchaseOrdersByIDs', { ids })
      .toPromise()
      .then(res => res.purchase_orders.map(r => this.purchaseOrderMap(r)));
  }

  createPurchaseOrder(data: PurchaseOrderAPI.CreatePurchaseOrderRequest): Promise<any> {
    return this.http
      .post('api/shop.PurchaseOrder/CreatePurchaseOrder', data)
      .toPromise();
  }

  deletePurchaseOrder(id) {
    return this.http
      .post(`api/shop.PurchaseOrder/DeletePurchaseOrder`, { id })
      .toPromise();
  }

  updatePurchaseOrder(data: PurchaseOrder): Promise<any> {
    data = this.parseNumberPurchaseOrder(data);
    return this.http
      .post(`api/shop.PurchaseOrder/UpdatePurchaseOrder`, data)
      .toPromise();
  }

  confirmPurchaseOrder(id: string, auto_inventory_voucher: 'create' | 'confirm'): Promise<any> {
    return this.http
      .post(`api/shop.PurchaseOrder/ConfirmPurchaseOrder`, { id, auto_inventory_voucher })
      .toPromise();
  }

  cancelPurchaseOrder(id: string, cancel_reason: string, auto_inventory_voucher: 'create' | 'confirm'): Promise<any> {
    return this.http
      .post(`api/shop.PurchaseOrder/CancelPurchaseOrder`, { id, cancel_reason, auto_inventory_voucher })
      .toPromise();
  }

  purchaseOrderMap(purchase_order: PurchaseOrder): PurchaseOrder {
    return new PurchaseOrder({
      ...purchase_order,
      total_products: purchase_order.lines.length,
      total_items: purchase_order.lines.reduce((a, b) => a + Number(b.quantity), 0),
      status_display: PurchaseOrderApi.statusMap(purchase_order.status) || '',
      payment_status: purchase_order.total_amount <= purchase_order.paid_amount ? 'P' : 'Z',
      payment_status_display: purchase_order.total_amount <= purchase_order.paid_amount ? 'Đã hoàn thành' : 'Chưa hoàn thành'
    });
  }

  private parseNumberPurchaseOrder(po: PurchaseOrder): PurchaseOrder {
    po = {
      ...po,
      basket_value: Number(po.basket_value),
      total_discount: Number(po.total_discount),
      total_amount: Number(po.total_amount)
    };
    if (po.lines && po.lines.length) {
      po.lines.forEach(l => {
        l.payment_price = Number(l.payment_price);
        l.quantity = Number(l.quantity);
      });
    }
    return po;
  }
}

export namespace PurchaseOrderAPI{
  export class CreatePurchaseOrderRequest{
    basket_value: number;
    discount_lines: PurchaseOrderDiscount[];
    fee_line:[];
    lines: PurchaseOrderLine[];
    note: string;
    supplier_id : string;
    total_amount : number;
    total_discount : number;
    total_fee: number;
  }
}


