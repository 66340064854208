import { BaseModel } from '../core/base/base-model';
import { Customer } from './Customer';
import { SubscriptionLine, SubscriptionPlan } from './Subscription';

export declare type InvoiceStatus = 'Z' | 'P' | 'N';
export declare type InvoicePaymentStatus = 'Z' | 'P' | 'N';

export enum InvoiceType {
  in = 'in',
  out = 'out',
  default = 'default',
}

export enum InvoiceRefType {
  subscription = 'subscription',
  credit = 'credit',
}

export const INVOICE_STATUS = {
  Z: 'Chưa thanh toán',
  S: 'Đang xử lý',
  P: 'Đã thanh toán',
  N: 'Hủy',
};

export const INVOICE_TYPE = {
  in: 'Thu',
  out: 'Chi',
  default: 'Khác',
};

export const INVOICE_REF_TYPE = {
  subscription: 'Thanh toán dịch vụ',
  credit: 'Nạp tiền',
};

export class Invoice extends BaseModel {
  id: string;
  created_at: string;
  description: string;
  account_id: string;
  payment_id: string;
  status: InvoiceStatus;
  status_display: string;
  referral_ids: string[];
  referral_info: {
    name: string;
    price: number;
  };
  referral_type: InvoiceRefType;
  referral_type_display: string;
  type: InvoiceType;
  type_display: string;
  total_amount: number;
  classify: string;
  customer: Customer;
  lines: any;
  payment_status: InvoicePaymentStatus;
  payment_status_display: string;
  updated_at: string;

  static invoiceMap(
    invoice: Invoice,
    subscriptionPlans: SubscriptionPlan[],
    subscriptionLines: SubscriptionLine[]
  ): Invoice {
    invoice = new Invoice(invoice);
    invoice.status_display = INVOICE_STATUS[invoice.status];
    invoice.type_display = INVOICE_TYPE[invoice.type];

    switch (invoice.referral_type) {
      case InvoiceRefType.credit:
        invoice.referral_type_display = INVOICE_REF_TYPE.credit;
        break;
      case InvoiceRefType.subscription:
        invoice.referral_type_display = INVOICE_REF_TYPE.subscription;
        break;
      default:
        invoice.referral_type_display = 'Khác';
    }
    if (
      invoice.referral_type == InvoiceRefType.subscription &&
      invoice?.referral_ids.length > 0
    ) {
      if (subscriptionLines.length > 0) {
        const subscriptionPlanId = subscriptionLines[0].plan_id;
        const { name, price } = subscriptionPlans.find(
          (plan) => plan.id == subscriptionPlanId
        );
        invoice = {
          ...invoice,
          referral_info: { name, price },
        };
      }
    }
    return invoice;
  }
}

export enum InvoiceTransactionTab {
  invoice = 'invoice',
  transaction = 'transaction',
}
