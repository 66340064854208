import { Inject, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { APP_BASE_HREF, CommonModule } from '@angular/common';
// libs
import { TranslateService } from '@ngx-translate/core';
import { throwIfAlreadyLoaded } from '@etop/utils';
// app
import { PlatformLanguageToken } from './services';

export const BASE_PROVIDERS: any[] = [
  {
    provide: APP_BASE_HREF,
    useValue: '/'
  }
];

@NgModule({
  imports: [CommonModule]
})
export class CoreModule {
  // configuredProviders: *required to configure WindowService and others per platform
  static forRoot(configuredProviders: Array<any>): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [...BASE_PROVIDERS, ...configuredProviders]
    };
  }

  constructor(
    @Optional()
    @SkipSelf()
    parentModule: CoreModule,
    @Inject(PlatformLanguageToken) lang: string,
    translate: TranslateService
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');

    // ensure default platform language is set
    translate.use(lang);
  }
}
