import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import {DEFAULT_RRULE, MoneyTransactionShop} from '@etop/models';
import * as moment from 'moment';
import { RRule } from 'rrule';
import { CmsService } from 'apps/core/src/services/cms.service';
import { AuthenticateStore } from '@etop/core';

@Injectable({ providedIn: 'root' })
export class MoneyTransactionApi {

  constructor(
    private auth: AuthenticateStore,
    private http:HttpService,
    private cms: CmsService,
  ) {}

  private moneyTransactionMap(moneyTransaction: MoneyTransactionShop) {
    switch (moneyTransaction.status) {
      case 'Z':
        moneyTransaction.status_display = 'Chờ chuyển tiền';
        break;
      case 'P':
        moneyTransaction.status_display = 'Đã chuyển tiền';
        break;
    }
    const shop = this.auth.snapshot.shop;
    const createdDate = moment(moneyTransaction.created_at).format('DD/MM/YYYY');
    const shop_rule = shop.money_transaction_rrule || DEFAULT_RRULE;

    moneyTransaction.estimated_transfered_at = moment(
      RRule.fromString(shop_rule + ';COUNT=1').all()[0]
    ).format('DD/MM/YYYY');
    if (
      createdDate ==
      moment(RRule.fromString(shop_rule + ';COUNT=1').all()[0]).format(
        'DD/MM/YYYY'
      )
    ) {
      moneyTransaction.estimated_transfered_at = moment(
        RRule.fromString(shop_rule + ';COUNT=2').all()[1]
      ).format('DD/MM/YYYY');
    }
    let est = moneyTransaction.estimated_transfered_at;

    let noTransaction: string = this.cms.getNoTransactionDays();
    if (
      noTransaction.indexOf(moneyTransaction.estimated_transfered_at) !== -1
    ) {
      const start = noTransaction.indexOf(
        moneyTransaction.estimated_transfered_at
      );
      noTransaction = noTransaction.substring(start);
      const end = noTransaction.indexOf('###');
      const noTransactionDays = noTransaction.substring(0, end).split(','); // after substring start = 0

      est = noTransactionDays[noTransactionDays.length - 2]; // get the last day that transfer has been postponed
      switch (
        moment(est, 'DD/MM/YYYY')
          .add(1, 'days')
          .weekday()
        ) {
        case 5:
          moneyTransaction.estimated_transfered_at = moment(est, 'DD/MM/YYYY')
            .add(3, 'days')
            .format('DD/MM/YY');
          break;
        case 6:
          moneyTransaction.estimated_transfered_at = moment(est, 'DD/MM/YYYY')
            .add(2, 'days')
            .format('DD/MM/YY');
          break;
        default:
          moneyTransaction.estimated_transfered_at = moment(est, 'DD/MM/YYYY')
            .add(1, 'days')
            .format('DD/MM/YY');
      }
      moneyTransaction.transfer_postponed_reason =
        noTransactionDays[noTransactionDays.length - 1]; // get the reason why transfer has been postponed
    } else {
      moneyTransaction.estimated_transfered_at = moment(
        est,
        'DD/MM/YYYY'
      ).format('DD/MM/YY');
    }

    return moneyTransaction;
  }

  getMoneyTransaction(id: string, token?: string): Promise<MoneyTransactionShop>{
    const option = this.http.createCustomDefaultOption(token);
    return this.http.postWithOptions('api/shop.MoneyTransaction/GetMoneyTransaction',{id},option)
      .toPromise().then(res => this.moneyTransactionMap(res))
  }

  getMoneyTransactions(paging,token?: string): Promise<{paging : any, money_transactions:Array<MoneyTransactionShop>}>{
    const option = this.http.createCustomDefaultOption(token);
    return this.http.postWithOptions('api/shop.MoneyTransaction/GetMoneyTransactions',{paging},option)
      .toPromise().then(res =>({
        paging: res.paging,
        money_transactions: res.money_transactions.map(mt => this.moneyTransactionMap(mt))
      }))
  }

}
