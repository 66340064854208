import { Injectable } from '@angular/core';
import { EntityState, StoreConfig, EntityStore, ActiveState, EntityUIStore } from '@datorama/akita';
import { ConversationAPI } from '@etop/api';
import { CustomerConversation, CursorPaging } from '@etop/models/faboshop';
import { FbUserTag } from '@etop/models/faboshop/FbUserTag';

export interface ConversationUI {
  isOpen: boolean;
  isLoading: boolean;
  isLoadingMess: boolean;
}

export interface StateConversations extends EntityState<CustomerConversation, string>, ActiveState {
  ui: {
    filter: ConversationAPI.GetCustomerConversationsFilter,
  };
  conversationPaging: CursorPaging;
  needMessageScroll: boolean;
  listTags: FbUserTag[];
  searchingConverastions: string[],
}

export interface ConversationUIState extends EntityState<ConversationUI> {}

const initialState = {
  ui: { filter: new ConversationAPI.GetCustomerConversationsFilter() },
  conversationPaging: {
    next: '.',
    prev: '.',
    limit: 10,
    sort: '-last_message_at'
  },
  latestCustomerMessage: null,
  needMessageScroll: false,
  activeConversationPost: null,
  searchingConverastions: null,
  activeFbUser: null,
  customerAddress: null,
  searchConversation: null,
  searchConversations: null,
  searchKey: null,
  relationships: null,
};

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'customerConversation', resettable: true })
export class ConversationsStore extends EntityStore<StateConversations> {
  ui: EntityUIStore<ConversationUIState>;

  constructor() {
    super(initialState);
    const defaults = { isLoading: false, isOpen: true, isLoadingMess: false};
    this.createUIStore().setInitialEntityState(defaults);
  }
}
