import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppService } from '@etop/web/core/app.service';

@Injectable()
export class IsUserActiveGuard implements CanActivate {
  constructor(private appService: AppService) {
  }
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const app = await this.appService.getAppConfig();
    if (app.disableUser) {
      window.location.replace(app.redirectUserLink);
    }
    return !app.disableUser;
  }

}
