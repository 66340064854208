export class RuleModel {
  name_rule: string;
  compair_rule: string;
  name: string;
  compair: string;
  value: string;
}

export class FilterModel {
  name: string;
  type: 'input' | 'select';
  ops: Array<any>;

  dataType?: string;
  value?: string;
  no_child?: boolean;
  once?: boolean;
  values?: Array<any>;

  hasParents?: boolean;
  parents?: Array<any>;
}

export class OrderRule {
  name: string;
  value: string;
  order: string;
  valueMap?: (any) => number;
}

export type FilterOptions = Array<FilterOption>;

export interface FilterOption {
  name: string;
  operator: FilterOperator;
  label: string;
  type: 'input' | 'select' | 'datetime' | 'autocomplete' | 'ion-range' | 'select-multiple';

  value?: any;
  placeholder?: string;
  show?: boolean;
  fixed?: boolean;
  meta?: any;
  options?: any[];

  // NOTE: used for type == 'autocomplete' || 'select;
  displayMap?: any;
  valueMap?: any;
  optionsHeight?: string; // 10px || 4rem ...

  order?: number;
}

export enum FilterOperator {
  contains = 'c',
  eq = '=',
  neq = '!=',
  lt = '<',
  lte = '<=',
  gt = '>',
  gte = '>=',
  in = 'in',
  n = '∩',
}

export type Filters = Array<Filter>;

export interface Filter {
  name: string;
  op: FilterOperator;
  value: any;
}

export interface Query {
  name: string;
}

export interface QueryOption {
  label: string;
  value?: any;
  placeholder?: string;
}
