export const isString = function(arg: any) {
  return typeof arg === 'string';
};

export const isObject = function(arg: any) {
  return arg && typeof arg === 'object';
};

export const trimUndefined = function(arg: any) {
  const obj = { ...arg };
  for (let key in obj) {
    if (!obj[key]) {
      delete obj[key]
    }
  }
  return obj;
};
