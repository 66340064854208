export type Operator = 'or' | 'and';

export function checkMatch(ins, targets, op: Operator) {
  if (!ins || !ins.length) {
    return true;
  }
  if (!targets || !targets.length) {
    return false;
  }

  const checkArr: boolean[] = [];
  ins.forEach((permission, index) => checkArr[index] = targets.includes(permission));

  if (op == 'and') {
    return checkArr.reduce((a, b) => a && b);
  }
  return checkArr.reduce((a, b) => a || b);
}
