import {Injectable} from '@angular/core';
import {ShopAccountApi} from "@etop/api";
import {HttpService} from '@etop/common';
import {AuthenticateStore} from '@etop/core';
import {Partner} from '@etop/models/Account';

@Injectable({
  providedIn: "root"
})
export class ShopService {

  authorized_partners: Array<Partner> = [];

  constructor(
    private http: HttpService,
    private auth: AuthenticateStore,
    private shopAccountApi: ShopAccountApi
  ) {
  }

  setDefaultAddress(address_id, type, token?) {
    return this.shopAccountApi.setDefaultAddress(address_id, type, token);
  }

  deleteShop(id: string, token?: string) {
    return this.http
      .post(`api/shop.Account/DeleteShop`, {id}, token)
      .toPromise();
  }

  updateMoneyTransactionCalendar(data) {
    return this.http.post('api/shop.Account/UpdateShop', data).toPromise();
  }

  updateBankAccount(bank_account, token) {
    return this.http
      .post('api/shop.Account/UpdateShop', {bank_account}, token)
      .toPromise();
  }

  updateShopField(data) {
    return this.http.post('api/shop.Account/UpdateShop', data).toPromise();
  }

  updateCompanyInfo(body: any) {
    return this.http
      .post('api/shop.Account/UpdateShop', {company_info: body})
      .toPromise();
  }

  calcShopBalance(credit_classify?: "shipping" | "telecom") {
    return this.http
      .post(`/api/shop.Summary/CalcBalanceUser`, {credit_classify})
      .toPromise()
      .then(res => {
        return res;
      });
  }

  getAuthorizedPartners() {
    return this.http
      .post('api/shop.Authorize/GetAuthorizedPartners', {})
      .toPromise()
      .then(res => {
        this.authorized_partners = res.partners.map(p => p.partner);
        return res;
      });
  }

}
