import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UserService } from 'apps/core/src/services/user.service';
import { AuthenticateStore } from '@etop/core';
import { ModalController } from 'apps/core/src/components/modal-controller/modal-controller.service';
import { VerifyAccountModalComponent } from '../verify-account-modal/verify-account-modal.component';
@Component({
  selector: 'core-verify-warning',
  templateUrl: './verify-warning.component.html',
  styleUrls: ['./verify-warning.component.scss']
})
export class VerifyWarningComponent implements OnInit {
  isSentVerificationEmail = this.auth.snapshot.user.email_verification_sent_at;
  isSentVerificationPhone = this.auth.snapshot.user.phone_verification_sent_at;
  sendingEmail = false;
  sendingPhone = false;
  sendEmailMsg = '';
  sendPhoneMsg = '';
  sendEmailSuccess: boolean;
  sendPhoneSuccess: boolean;

  constructor(
    private ref: ChangeDetectorRef,
    private auth: AuthenticateStore,
    private userService: UserService,
    private modalController:ModalController
  ) { }

  ngOnInit() {
  }

  get emailVerified() {
    return this.auth.snapshot.user.email_verified_at;
  }

  get phoneVerified() {
    return this.auth.snapshot.user.phone_verified_at;
  }
  async sendVerifyEmail() {
    this.sendingEmail = true;
    this.ref.detectChanges();
    try {
      let res = await this.userService.sendEmailVerificationUsingOTP(this.auth.snapshot.user.email);
      let modal = this.modalController.create({
        component: VerifyAccountModalComponent,
        componentProps: {
          verifyEmail: true,
          email: this.auth.snapshot.user.email
        },
        showBackdrop: 'static'
      });
      modal.show().then();
      // this.sentPhoneCode = true;
      this.sendEmailMsg = res.msg;
      this.sendEmailSuccess = true;
      this.ref.detectChanges();
    } catch (e) {
      this.sendEmailMsg = e.message;
      toastr.error(e.msg || e.message);
      this.ref.detectChanges();
    }
    this.sendingEmail = false;
    this.ref.detectChanges();
  }

  async sendVerifyPhone() {
    this.sendingPhone = true;
    this.ref.detectChanges();
    try {
      let res = await this.userService.sendPhoneVerification(this.auth.snapshot.user.phone);

      let modal = this.modalController.create({
        component: VerifyAccountModalComponent,
        componentProps: {
          verifyEmail: false,
          phone: this.auth.snapshot.user.phone
        },
        showBackdrop: 'static'
      });
      modal.show().then();
     
      // this.sentPhoneCode = true;
      this.sendPhoneMsg = res.msg;
      this.sendPhoneSuccess = true;
      this.ref.detectChanges();
    } catch (e) {
      this.sendPhoneMsg = e.message;
      toastr.error(e.msg || e.message);
      this.ref.detectChanges();
    }
    this.sendingPhone = false;
    this.ref.detectChanges();
  }
}
