import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MappingService {

  constructor() {
  }

  mapPaymentStatus(payment_status: string) {
    const statusMap = {
      N: "Chưa thanh toán",
      Z: "Không cần thanh toán",
      P: "Đã thanh toán",
      S: "Cần thanh toán"
    };
    return statusMap[payment_status];
  }

  mapOrderStatus(status: string) {
    const statusMap = {
      "Z": "Mới",
      "S": "Đang xử lý",
      "P": "Thành công",
      "N": "Huỷ",
      "NS": "Trả hàng"
    };
    return statusMap[status] || "Không xác định";
  }


}
