import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectMultilineComponent } from 'apps/shared/src/components/select-multiline/select-multiline.component';
import { CheckboxComponent } from 'apps/shared/src/components/checkbox/checkbox.component';
import { CheckboxCircleComponent } from './components/checkbox-circle/checkbox-circle.component';
import { SharedDirectivesModule, EtopMaterialModule } from '@etop/shared';
import { ChangePasswordModalComponent } from './components/change-password-modal/change-password-modal.component';

const components = [
  SelectMultilineComponent,
  CheckboxComponent,
  CheckboxCircleComponent,
  ChangePasswordModalComponent,
];
const pages = [
];
const directives = [];
const pipes = [];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedDirectivesModule,
    ReactiveFormsModule
  ],
  declarations: [...components, ...directives, ...pipes, ...pages],
  exports: [
    ...components,
    ...directives,
    ...pipes,
    ...pages,
    SharedDirectivesModule,
    EtopMaterialModule
  ],
  providers: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class SharedModule {}
