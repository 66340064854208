import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {EtopPipesModule} from "@etop/shared";
import { HeaderComponent } from 'apps/core/src/components/header/header.component';
import { HeaderControllerService } from 'apps/core/src/components/header/header-controller.service';
import { BreadcrumbComponent } from 'apps/core/src/components/header/components/breadcrumb/breadcrumb.component';
import { UserInfoComponent } from 'apps/core/src/components/header/components/user-info/user-info.component';
import { NotificationPopupComponent } from './components/notification-popup/notification-popup.component';
import { RouterModule } from '@angular/router';
import { ActionButtonComponent } from './components/action-button/action-button.component';
import { NotificationIconComponent } from './components/notification-icon/notification-icon.component';
import { TabOptionsComponent } from './components/tab-options/tab-options.component';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ContentOptionComponent } from 'apps/core/src/components/header/components/content-option/content-option.component';
import { SingleUserInfoComponent } from 'apps/core/src/components/header/components/aff-user-info/single-user-info.component';
import { HeaderButtonComponent } from './components/header-button/header-button.component';
import { LoginModule } from 'apps/shared/src/pages/login/login.module';
import { AuthenticateModule } from '@etop/core';
import { VerifyAccountModalComponent } from './components/verify-account-modal/verify-account-modal.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbComponent,
    UserInfoComponent,
    NotificationPopupComponent,
    ActionButtonComponent,
    NotificationIconComponent,
    TabOptionsComponent,
    ContentOptionComponent,
    SingleUserInfoComponent,
    HeaderButtonComponent,
    VerifyAccountModalComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbModule,
    LoginModule,
    AuthenticateModule,
    EtopPipesModule,
    MatTooltipModule
  ],
  providers: [
    HeaderControllerService
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
