import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'status'
})
export class StatusPipe implements PipeTransform {
  statusHash: any = {
    confirmed: 'Đã xác nhận',
    new: 'Mới',
    rejected: 'Từ chối',
    processing: 'Đang xử lý',
    delivering: 'Đang giao hàng',
    unpaid: 'Chưa thánh toán',
    paid: 'Đã thanh toán',
    successful: 'Thành công',
    completed: 'Hoàn tất',
    canceled: 'Hủy',
    default: 'Default'
  };

  transform(value: string): string {
    return this.statusHash[value] || 'Không xác định';
  }
}

@Pipe({
  name: 'statusvn'
})
export class statusvn implements PipeTransform {
  statusHash: any = {
    approving: 'Chờ duyệt',
    disabled: 'Ngừng Bán',
    published: 'Đang Bán',
    new: 'Mới',
    stopped: 'Không quản lý',
    rejected: 'Từ chối'
  };

  transform(value: string): string {
    return this.statusHash[value] || 'Không xác định';
  }
}

@Pipe({
  name: 'vnd'
})
@Injectable()
export class VND implements PipeTransform {
  transform(value: any): string {
    if (typeof value != "number" && typeof value != "string" || !Number(value)) {
      return value;
    }
    if (typeof value == "string") {
      value = Number(value);
    }
    const valueString = value < 0 ?  (-value).toString() : value.toString();

    if (valueString.includes(".")) {
      const wholePart = valueString.split(".")[0]
        .replace(/./g, (match, p1, p2) => {
          return p1 && match !== "," && ((p2.length - p1) % 3 === 0) ? '.' + match : match;
        });
      const decimalPart = valueString.split(".")[1];

      return `${value < 0 ? '-' : ''}${wholePart},${decimalPart}`;
    } else {
      const result = valueString.replace(/./g, (match, p1, p2) => {
        return p1 && match !== "," && ((p2.length - p1) % 3 === 0) ? '.' + match : match;
      });
      return `${value < 0 ? '-' : ''}${result}`;
    }
  }
}

@Pipe({
  name: 'stripHTML'
})
export class stripHTML implements PipeTransform {
  transform(value: any): string {
    const _tempDIV = document.createElement("div");
    _tempDIV.innerHTML = value;
    return _tempDIV.textContent || _tempDIV.innerText || "";
  }
}


@Pipe({
  name: 'dotReplace'
})
export class DotReplacePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      return value.replace(/,/g, '.');
    }

    return '';
  }
}


// convert duration (second) to string HH:MM:SS format
// Example: 76 => 00:01:06
@Pipe({
  name: 'formatDuration'
})
export class FormatDuration implements PipeTransform {
  transform(duration: number): string {
    const durationObj = new Date(duration * 1000).toISOString().substr(11, 8);
    return durationObj;
  }
}


