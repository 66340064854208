import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BaseRoleDirective } from './base-role-directive.internal';
import { AuthenticateStore } from './authenticate.store.service';
import { checkMatch, Operator } from './common.internal';
import { AuthenticateService } from './authenticate.service';

@Directive({
  selector: '[ePermissions]'
})
export class PermissionsDirective extends BaseRoleDirective {

  private permissions: string[] = [];
  private operator: Operator = 'or';

  constructor(
    element: ElementRef,
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    authenticateStore: AuthenticateStore,
    authenticateService: AuthenticateService
  ) {
    super(element, templateRef, viewContainer, authenticateStore, authenticateService);
  }

  @Input()
  set ePermissions(permissions) {
    this.permissions = permissions;
    this.updateView();
  }

  @Input()
  set ePermissionOp(val) {
    this.operator = (val || 'or').toLowerCase();
  }

  check(): boolean {
    return this.authenticateService.checkPermissions(this.permissions, this.operator);
  }
}
