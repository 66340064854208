import { HttpService } from '@etop/common';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AccountApi {
  constructor(private http: HttpService) {}

  getPublicPartnerInfo(id, token?) {
    return this.http.post('api/etop.Account/GetPublicPartnerInfo', { id }, token).toPromise();
  }

  getPublicPartners(ids: string[], token?) {
    return this.http.post('api/etop.Account/GetPublicPartners', { ids }, token).toPromise();
  }

  updateURLSlug(data: {account_id: string, url_slug: string}, token?) {
    return this.http.post('api/etop.Account/UpdateURLSlug', data, token).toPromise();
  }

  
}

export namespace AccountAPI {

}
