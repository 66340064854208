import { BaseModel } from '@etop/core';
import { CustomerConversation, FbUser } from '@etop/models/faboshop';

export declare type Gender = 'male' | 'female' | 'unknown';

export enum CustomerEvent {
  UPDATE = 'CUSTOMER:UPDATE',
  CREATE = 'CUSTOMER:CREATE',
  DELETE = 'CUSTOMER:DELETE'
}

export interface CustomerCreateData {
  conversation: CustomerConversation,
  customer: Customer
}

export interface CustomerUpdateData {
  conversation: CustomerConversation
}

export class Customer extends BaseModel {
  birthday: string;
  code: string;
  created_at: string;
  email: string;
  full_name: string;
  gender: string;
  id: string;
  note: string;
  phone: string;
  shop_id: string;
  status: string;
  type: string;
  updated_at: string;

  external_id?: string;
  external_info?: any;

  search_text?: string;
  deleted?: boolean;
  address?: CustomerAddress;
  addresses?: Array<CustomerAddress>;
  o_data: any;
  p_data: any = {
    selected: false,
    detailed: false
  };
  info?: string;
  detail: string;
  fb_users: Array<FbUser>;
}

export class CustomerAddress extends BaseModel {
  address1: string;
  address2?: string;
  company?: string;
  country?: string;
  customer_id: string;
  district?: string;
  district_code: string;
  email?: string;
  full_name: string;
  id: string;
  phone: string;
  position?: string;
  province?: string;
  province_code: string;
  ward?: string;
  ward_code: string;

  compare_text?: string;

  coordinates?: {
    latitude: number;
    longitude: number;
  };

  p_data?: any = {
    edited: false,
    editedField: {},
    selected: false
  };
}

export class FbUserInfo extends BaseModel {
  image_url: string;
}
