import { Injectable } from '@angular/core';
import {Customer, CustomerAddress} from "libs/models/Customer";
import {CustomerApi} from "@etop/api";

@Injectable()
export class CustomerService {

  constructor(
    private customerApi:CustomerApi
  ) { }

  async createCustomer(data: Customer) {
    return await this.customerApi.createCustomer(data);
  }

  async createCustomerAddress(data: CustomerAddress) {
    return await this.customerApi.createCustomerAddress(data);
  }

  async getCustomers(start?: number, perpage?: number, filters?: Array<any>) {
    let paging = {
      offset: start || 0,
      limit: perpage || 1000
    };
    return this.customerApi.getCustomers({ paging, filters })
      .then(res => res.customers);
  }

  async getCustomerAddresses(customer_id) {
    return await this.customerApi.getCustomerAddresses(customer_id);
  }

  async updateCustomer(data: Customer) {
    delete data.code;
    return await this.customerApi.updateCustomer(data);
  }

  async updateCustomerAddress(data) {
    return await this.customerApi.updateCustomerAddress(data);
  }

  async deleteCustomer(id) {
    return await this.customerApi.deleteCustomer(id);
  }

  async deleteCustomerAddress(id) {
    return await this.customerApi.deleteCustomerAddress(id);
  }

}
