import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import {CursorPaging} from "@etop/models";

@Injectable({
  providedIn: 'root'
})
export class ContactApi {

  constructor(private http: HttpService) {
  }

  async getContacts(getContactsRequest?: ContactAPI.GetContactsRequest) {
    return await this.http.post('api/shop.Contact/GetContacts', getContactsRequest)
      .toPromise().then(res => {
        return {
          paging: res.paging,
          contacts: res.contacts,
        }
      });
  }

  async getContact(id) {
    return await this.http.post('api/shop.Contact/GetContact', {id}).toPromise();
  }

  async createContact(data: ContactAPI.CreateContact) {
    return this.http.post('api/shop.Contact/CreateContact', data).toPromise();
  }

  async updateContact(data) {
    return this.http.post('api/shop.Contact/UpdateContact', data).toPromise();
  }

  async deleteContact(id) {
    return this.http.post('api/shop.Contact/DeleteContact', {id:id}).toPromise();
  }

  async createContactFromImport(request: ContactAPI.ImportContact[]) {
    return this.http.post('api/shop.Contact/CreateContactFromImport', {contact: request}).toPromise();
  }

  async importContacts(formData) {
    return this.http.purePostForm('api/shop.Import/Contact', formData).toPromise()
      .then(res => {
        if (!res?.contact?.length) {
          throw { code: 'cell_errors', errors: res?.cell_errors };
        }
        return res;
      });
  }
}

export namespace ContactAPI {
  export class GetContactsRequest {
    filter?: GetContactsFilter;
    paging?: CursorPaging
  }
  export class GetContactsFilter {
    ids?: string[];
    phone?: string;
    name?: string;
  }

  export class CreateContact {
    full_name: string;
    phone: string;
    note?: string;
  }

  export class ImportContact {
    name: string;
    number_phone: string;
    note?: string;
  }

  export class GetContactRequest {
    id: string
  }

}
