import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { ValueAccessorBase } from 'apps/core/src/interfaces/ValueAccessorBase';
import { UtilService } from 'apps/core/src/services/util.service';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


@Component({
    selector: 'etop-ngx-mat-select-search',
    templateUrl: './ngx-mat-select-search.component.html',
    styleUrls: ['./ngx-mat-select-search.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: NgxMatSelectSearchComponent, multi: true },
    ]
})
export class NgxMatSelectSearchComponent extends ValueAccessorBase<string> implements OnInit, AfterViewInit, OnChanges, OnDestroy {

    @Input() optionSelect: any = [];
    @Input() displayMap: (any) => string;
    @Input() valueMap: (any) => string;
    @Input() textLabel: string;
    @Input() placeholder: string;
    @Input() topshipInput: string;
    @Input() appearance: any;

    @Output() select = new EventEmitter();
    searchValue: FormControl = new FormControl();

    public filtered: any = [];

    @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

    /** Subject that emits when the component has been destroyed. */
    protected _onDestroy = new Subject<void>();

    constructor(
        private util: UtilService,
        private cdr: ChangeDetectorRef,
    ) {
        super();
    }


    ngOnInit() {
        this.searchValue.valueChanges
            .pipe(takeUntil(this._onDestroy))
            .subscribe(() => {
                this.filterOptions();
            });
        
    }


    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
      }

    ngAfterViewInit() {
        this.filtered = this.optionSelect;
        this.cdr.detectChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.cdr.detectChanges();
        this.filtered = this.optionSelect;
    }

    filterOptions() {
        if (!this.optionSelect) {
            return;
        }
        // get the search keyword
        let search = this.searchValue.value;
        if (!search) {
            this.filtered = this.optionSelect;
            
            return;
        } else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filtered = this.optionSelect.filter(option => this.util.makeSearchText(option.name).indexOf(this.util.makeSearchText(search)) > -1)

    }

}
