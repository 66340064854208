import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { ListQueryDTO } from 'libs/models/CommonQuery';

@Injectable()
export class CategoryApi {

  constructor(
    private http: HttpService
  ) { }

  getCategories(query: Partial<ListQueryDTO>) {
    return this.http.post('api/shop.Category/GetCategories', query).toPromise();
  }

  createCategory(data) {
    return this.http.post('api/shop.Category/CreateCategory', data).toPromise();
  }

}
