import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { Filters } from '@etop/models';

@Injectable({
  providedIn: 'root'
})
export class CustomerFaboApi {
  fabo_url = '';

  constructor(private http: HttpService) {}

  createFbUserCustomer(customer_id: string, external_id: string) {
    return this.http
      .post(`api/fabo.Customer/CreateFbUserCustomer`, { customer_id, external_id })
      .toPromise();
  }

  getFbUser(external_id: string) {
    return this.http.post(`api/fabo.Customer/GetFbUser`, { external_id })
      .toPromise()
      .then(res => {
        if (res.customer?.deleted) {
          res.customer = null;
        }
        return res;
      });
  }

  listFbUsers(customer_id: string) {
    return this.http.post(`api/fabo.Customer/ListFbUsers`, { customer_id })
      .toPromise()
      .then(res => res.fb_users);
  }

  listCustomersWithFbUsers(query: Partial<CustomerAPI.GetCustomersRequest>) {
    return this.http.post(`api/fabo.Customer/ListCustomersWithFbUsers`, query, null)
      .toPromise()
      .then(res =>  res.customers);
  }


  updateTags(fb_external_user_id, tag_ids: Array<string>) {
    return this.http.post(`api/fabo.Customer/UpdateTags`, { fb_external_user_id, tag_ids })
      .toPromise()
      .then(res => res.tag_ids);
  }
}



export namespace CustomerAPI {
  export interface GetCustomersRequest {
    filters: Filters;
    paging: {
      after?: string;
      before?: string;
      limit: number;
      sort?: string;
    };
  }
}
