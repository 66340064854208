import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { UserBehaviourTrackingService } from './user-behaviour-tracking.service';
import {Account, User} from '@etop/models';
import {AuthenticateService, AuthenticateStore, ConfigService} from '@etop/core';
import { LoggerService } from './logger.service';
import {ShopAccountAPI, ShopAccountApi, UserApi} from '@etop/api';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User = null;
  currentAccount: Account = null;
  availableAccounts: Array<Account> = [];

  ready = false;

  constructor(
    private userApi: UserApi,
    private accountApi: ShopAccountApi,
    private http: HttpService,
    private auth: AuthenticateStore,
    private logger: LoggerService,
    private config: ConfigService,
    private ubtService: UserBehaviourTrackingService
  ) {}

  updatePassword(data) {
    return this.userApi.changePassword(data);
  }

  updatePasswordUsingToken(data) {
    return this.userApi.changePasswordUsingToken(data);
  }

  requestResetPassword(data) {
    return this.userApi.requestResetPassword(data);
  }

  requestResetPasswordByPhone(data) {
    return this.userApi.requestResetPasswordByPhone(data);
  }

  sendEmailVerification(email) {
    return this.userApi.sendEmailVerification(email);
  }

  verifyEmailUsingToken(token) {
    return this.userApi.verifyEmailUsingToken(token);
  }

  async sendPhoneVerification(phone: string) {
    const captcha = await AuthenticateService.getReCaptcha(this.config.get('recaptcha_key'));
    const data = {
      phone,
      recaptcha_token: captcha
    };
    return this.userApi.sendPhoneVerification(data);
  }

  checkUserRegistration(data) {
    return this.userApi.checkUserRegistration(data);
  }

  changeRefAff(ref_aff){
    return this.userApi.changeRefAff(ref_aff);
  }

  verifyPhoneUsingToken(token) {
    return this.userApi.verifyPhoneUsingToken(token);
  }

  verifyPhoneResetPasswordUsingToken(token) {
    return this.userApi.verifyPhoneResetPasswordUsingToken(token);
  }

  sendEmailVerificationUsingOTP(email) {
    return this.userApi.sendEmailVerificationUsingOTP(email);
  }

  verifyEmailUsingOTP(token) {
    return this.userApi.verifyEmailUsingOTP(token);
  }

  async checkToken(token?: string) {
    return this.userApi.sessionInfo(token);
  }

  switchAccount(account_id: string, origin?: string) {
    return this.userApi.switchAccount(account_id);
  }

  registerShop(request: ShopAccountAPI.RegisterShopRequest) {
    return this.accountApi.registerShop(request)
      .then(res => {
        if (res.shop && res.shop.id) {
          this.ubtService.sendUserBehaviour('CreateShop');
        }
        return res;
      });
  }

  async signUp(data) {
    if (!data.full_name) {
      throw new Error('Vui lòng điền tên đầy đủ');
    }
    if (!data.phone) {
      throw new Error('Vui lòng điền số điện thoại');
    }
    if (!data.email) {
      throw new Error('Vui lòng nhập địa chỉ email');
    }
    if (!data.password) {
      throw new Error('Vui lòng điền mật khẩu');
    }
    return this.userApi.register(data).then(res => {
      if (res.id) {
        this.ubtService.sendUserBehaviour('CreateAccount');
      }
      return res;
    });
  }

  async signUpUsingToken(data) {
    if (!data.full_name) {
      throw new Error('Vui lòng điền tên đầy đủ');
    }
    if (!data.phone) {
      throw new Error('Vui lòng điền số điện thoại');
    }
    if (!data.email) {
      throw new Error('Vui lòng nhập địa chỉ email');
    }
    if (!data.password) {
      throw new Error('Vui lòng điền mật khẩu');
    }
    return this.userApi.registerUsingToken(data).then(res => {
      if (res.id) {
        this.ubtService.sendUserBehaviour('CreateAccount');
      }
      return res;
    });
  }

  clear() {
    this.user = null;
    this.currentAccount = null;
    this.availableAccounts = [];
  }

  initSession() {
    return this.userApi.initSession();
  }


}
