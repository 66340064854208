<div class="alert alert-warning clearfix no-print" *ngIf="!emailVerified">
  <span *ngIf="!sendEmailMsg && isSentVerificationEmail" class="msg">
    <i class="fa fa-shield mr-1"></i>
    Email của bạn chưa được xác thực. Vui lòng kiểm tra email để xác thực ngay!
  </span>
  <span *ngIf="!sendEmailMsg && !isSentVerificationEmail" class="msg">
    <i class="fa fa-shield mr-1"></i>
    Email của bạn chưa được xác thực. Nhấn vào nút bên để xác thực ngay!
  </span>
  <span *ngIf="sendEmailMsg" class="msg">
    <i class="fa fa-shield mr-1"></i>
    {{sendEmailMsg}}
  </span>
  <button class="btn btn-warning btn-outline" (click)="sendVerifyEmail()" [disabled]="sendingEmail">
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="sendingEmail"></i>
    Gửi email xác thực
  </button>
</div>

<div class="alert alert-warning clearfix no-print" *ngIf="emailVerified && !phoneVerified">
  <span *ngIf="!sendPhoneMsg && isSentVerificationPhone" class="msg">
    <i class="fa fa-shield mr-1"></i>
    Số điện thoại của bạn chưa được xác thực. Vui lòng kiểm tra tin nhắn để xác thực ngay!
  </span>
  <span *ngIf="!sendPhoneMsg && !isSentVerificationPhone" class="msg">
    <i class="fa fa-shield mr-1"></i>
    Số điện thoại của bạn chưa được xác thực. Nhấn vào nút bên để xác thực ngay!
  </span>
  <span *ngIf="sendPhoneMsg" class="msg">
    <i class="fa fa-shield mr-1"></i>
    {{sendPhoneMsg}}
  </span>
  <button class="btn btn-warning btn-outline" (click)="sendVerifyPhone()" *ngIf="!sendPhoneSuccess"
    [disabled]="sendingPhone ">
    <i class="fa fa-circle-o-notch fa-spin" *ngIf="sendingPhone"></i>
    Gửi tin nhắn xác thực
  </button>
</div>
