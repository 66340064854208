import { NgModule } from '@angular/core';
import { InfiniteScrollDirective } from './infinite-scroll.directive';
import { InfiniteScrollComponent } from './infinite-scroll.component';

@NgModule({
  declarations: [InfiniteScrollDirective, InfiniteScrollComponent],
  exports: [InfiniteScrollDirective, InfiniteScrollComponent]
})
export class InfiniteScrollModule {

}
