import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';

@Injectable({
  providedIn: 'root'
})
export class NotificationApi {

  constructor(
    private http: HttpService
  ) { }

  createDevice(data) {
    return this.http.post('api/shop.Notification/CreateDevice', data).toPromise()
      .then(res => res);
  }

  deleteDevice(data: {device_id: string; external_device_id: string}) {
    debug.log('delete');
    return this.http.post('api/shop.Notification/DeleteDevice',data ).toPromise();
  }

  getNotification(noti_id, token?) {
    return this.http.post('api/shop.Notification/GetNotification', { id: noti_id }, token).toPromise()
      .then(res => res);
  }

  getNotifications(paging: any, token?: string) {
    return this.http.post('api/shop.Notification/GetNotifications', { paging }, token).toPromise()
      .then(res => {
        return {
          paging: res.paging,
          notifications: res.notifications
        };
      });
  }

  updateNotifications(ids: Array<string>, is_read: boolean) {
    return this.http.post('api/shop.Notification/UpdateNotifications', { ids, is_read }).toPromise()
      .then(res => res);
  }

}

