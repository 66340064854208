import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import {CursorPaging, Relationship, RelationshipTab} from '@etop/models';
import {AuthorizationAPI} from "@etop/api";

export interface StateRelationships extends EntityState<Relationship, string>, ActiveState {
  ui: {
    filter: AuthorizationAPI.RelationshipFilter,
    paging: CursorPaging,
    currentPage: Number,
    tab: RelationshipTab,
  }
}

const initialState = {
  ui: {
    paging: null,
    currentPage: 1,
    filter: null,
    tab: RelationshipTab.staff
  },
};

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'relationship', resettable: true })
export class RelationshipStore extends EntityStore<StateRelationships>{

  constructor() {
    super(initialState);
  }
}
