import { Injectable } from '@angular/core';
import { ShopSettingsApi } from '@etop/api/shop/shop-settings.api';
import { ShopSettings } from '@etop/models/ShopSettings';

@Injectable({ providedIn: 'root' })
export class ShopSettingsService {

    constructor(private shopSettingsServiceApi: ShopSettingsApi) {}

    async getSetting(): Promise<ShopSettings> {
        return await this.shopSettingsServiceApi.getSetting();
    }

    async updateSetting(data) {
        return await this.shopSettingsServiceApi.updateSetting(data);
    }
}
