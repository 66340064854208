import { BaseDirective } from '@etop/core/base/base-directive';
import { Directive, ElementRef, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthenticateStore } from './authenticate.store.service';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { AuthenticateService } from './authenticate.service';

@Directive()
export abstract class BaseRoleDirective extends BaseDirective {
  protected isHidden = true;

  protected constructor(
    protected element: ElementRef,
    protected templateRef: TemplateRef<any>,
    protected viewContainer: ViewContainerRef,
    protected authenticateStore: AuthenticateStore,
    protected authenticateService: AuthenticateService,
  ) {
    super();
    this.authenticateStore.authenticatedData$.pipe(
      map(data => data.permission),
      distinctUntilChanged(),
      takeUntil(this.destroy$)
    ).subscribe(() => this.updateView());
  }

  protected updateView() {
    if (this.check()) {
      if(this.isHidden) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.isHidden = false;
      }
    } else {
      this.isHidden = true;
      this.viewContainer.clear();
    }
  }

  protected abstract check(): boolean;
}
