import { NgModule } from '@angular/core';
import { VND, DotReplacePipe, StatusPipe, statusvn, stripHTML, FormatDuration } from './etop.pipe';
import { AddressDisplayPipe, LocationCompactPipe } from '@etop/shared/pipes/location.pipe';
import { SafeHtmlPipe, SafeUrlPipe, UrlifyPipe } from './safe-html.pipe';
import { VariantDisplayPipe } from './product-variant.pipe';

const pipes = [
  VND,
  DotReplacePipe,
  StatusPipe,
  stripHTML,
  statusvn,
  LocationCompactPipe,
  AddressDisplayPipe,
  SafeHtmlPipe,
  SafeUrlPipe,
  VariantDisplayPipe,
  FormatDuration,
  UrlifyPipe
];

@NgModule({
  declarations: [...pipes],
  imports: [],
  exports: [...pipes],
  providers: [
    LocationCompactPipe,
    AddressDisplayPipe,
    VND
  ],
})
export class EtopPipesModule { }
