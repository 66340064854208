import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { ListQueryDTO } from 'libs/models/CommonQuery';
import { InventoryVoucher, InventoryVoucherRefType } from 'libs/models/Inventory';
import { InventoryType } from 'apps/core/src/stores/inventory.store.service';

const TYPE = {
  in: 'Nhập',
  out: 'Xuất'
};

const REF = {
  order: 'Đơn hàng',
  purchase_order: 'Đơn nhập hàng',
  stocktake: 'Kiểm kho'
};

const TRADER = {
  customer: 'Khách hàng',
  supplier: 'Nhà cung cấp'
};

@Injectable({
  providedIn: 'root'
})
export class InventoryApi {

  static get type() {
    return TYPE;
  }

  static get ref() {
    return REF;
  }

  static get trader() {
    return TRADER;
  }

  constructor(
    private http: HttpService
  ) { }

  static typeMap(type) {
    return InventoryApi.type[type];
  }

  static refMap(ref_type) {
    return InventoryApi.ref[ref_type];
  }

  static traderMap(trader_type) {
    return InventoryApi.trader[trader_type];
  }

  getInventoryVouchers(query: Partial<ListQueryDTO>): Promise<any> {
    return this.http
      .post('api/shop.Inventory/GetInventoryVouchers', query)
      .toPromise()
      .then(res => res.inventory_vouchers.map(i_v => this.inventoryVoucherMap(i_v)));
  }

  getInventoryVouchersByReference(ref_id: string, ref_type: 'order' | 'purchase_order' | 'stocktake') {
    return this.http
      .post('api/shop.Inventory/GetInventoryVouchersByReference', {ref_id, ref_type})
      .toPromise()
      .then(res => res.inventory_vouchers.map(i_v => this.inventoryVoucherMap(i_v)));
  }

  createInventoryVoucher(ref_id: string, ref_type: InventoryVoucherRefType, type: InventoryType): Promise<any> {
    return this.http
      .post('api/shop.Inventory/CreateInventoryVoucher', {ref_id, ref_type, type})
      .toPromise();
  }

  confirmInventoryVoucher(id: string): Promise<any> {
    return this.http
      .post('api/shop.Inventory/ConfirmInventoryVoucher', {id})
      .toPromise();
  }

  updateInventoryVariantCostPrice(variant_id: string, cost_price: number) {
    cost_price = Number(cost_price);
    return this.http.post('api/shop.Inventory/UpdateInventoryVariantCostPrice', { variant_id, cost_price })
      .toPromise();
  }

  inventoryVoucherMap(inventory_voucher: InventoryVoucher) {
    return new InventoryVoucher({
      ...inventory_voucher,
      type_display: InventoryApi.typeMap(inventory_voucher.type) || '',
      ref_display: InventoryApi.refMap(inventory_voucher.ref_type) || '',
      total_products: inventory_voucher.lines.length,
      total_items: inventory_voucher.lines.reduce((a, b) => a + Number(b.quantity), 0),
      trader_display: inventory_voucher.trader && InventoryApi.traderMap(inventory_voucher.trader.type)
    });
  }

}
