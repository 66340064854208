import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import {
  CursorPaging,
} from '@etop/models';
import { AuthenticateStore } from '@etop/core';

@Injectable({
  providedIn: 'root',
})
export class AdminZaloApi {
  constructor(private http: HttpService, private auth: AuthenticateStore) {}

  async summarizeZCC(request: AdminZaloAPI.SummarizeRequest) {
    return this.http
      .post('api/admin.Zalo/SummarizeZCC', request)
      .toPromise();
  }

  async summarizeZNS(request: AdminZaloAPI.SummarizeRequest) {
    return this.http
      .post('api/admin.Zalo/SummarizeZNS', request)
      .toPromise();
  }

  async summarizeCampaign(request: AdminZaloAPI.SummarizeRequest) {
    return this.http
      .post('api/admin.Zalo/SummarizeCampaign', request)
      .toPromise();
  }

  async listRequestConsent(request: AdminZaloAPI.GetListRequestConsent) {
    return this.http
      .post('api/admin.Zalo/ListRequestConsent', request)
      .toPromise();
  }

  async listTemplates(request: AdminZaloAPI.GetListTemplate) {
    return this.http.post('api/admin.Zalo/ListTemplates', request).toPromise();
  }

  async getTemplate(oa_id: string, template_id: number) {
    return this.http
      .post('api/admin.Zalo/GetTemplate', { oa_id, template_id })
      .toPromise();
  }

  async listMessages(request: AdminZaloAPI.GetListTemplate) {
    return this.http.post('api/admin.Zalo/ListMessages', request).toPromise();
  }

  async getMessage(oa_id: string, msg_id: number) {
    return this.http
      .post('api/admin.Zalo/GetMessage', { oa_id, msg_id })
      .toPromise();
  }

  templateStatus(staus) {
    return {
      PENDING_REVIEW: 'Đang duyệt',
      DISABLE: 'Bị khóa',
      ENABLE: 'Đã duyệt',
      REJECT: 'Bị từ chối',
    }[staus];
  }

  templateType(type) {
    return {
      text: 'Văn bản',
      otp: 'OTP',
      table: 'Dạng bảng',
      rating: 'Đánh giá dịch vụ',
    }[type];
  }

  templateQuality(quality) {
    return {
      UNDEFINED: 'Chưa được xác định',
      LOW: 'Kém',
      MEDIUM: 'Trung bình',
      HIGH: 'Tốt',
      unknown: 'Chưa được xác định',
    }[quality];
  }

  templateTag(tag) {
    return {
      OTP: 'OTP',
      IN_TRANSACTION: 'Xác nhận/Cập nhật giao dịch',
      POST_TRANSACTION: 'Hỗ trợ dịch vụ liên quan sau giao dịch',
      ACCOUNT_UPDATE: 'Cập nhật thông tin tài khoản',
      GENERAL_UPDATE: 'Thay đổi thông tin dịch vụ',
      FOLLOW_UP: 'Thông báo ưu đãi đến khách hàng cũ',
    }[tag];
  }

  consentErrorCode(code: number) {
    switch (code) {
      case 0:
      case 1:
        return 'Khách hàng đã đồng ý trước đó';
      case 2:
        return 'Đã gửi yêu cầu trước đó';
      case 3:
        return 'Khách hàng đã từ chối';
      case 4:
        return 'Người dùng bị chặn';
      case 5:
        return 'Đã vượt quá số lượng yêu cầu cho phép, vui lòng thử lại sau';
      case 6:
        return 'Không đủ quyền';
      case 7:
        return 'Cần có lý do để yêu cầu sự đồng ý của người dùng';
      case 8:
        return 'Chờ phê duyệt của người dùng';
      case 9:
        return 'Người dùng chưa tương tác với OA này';
      case 10:
        return 'Kết nối kém';
      case 11:
        return 'Yêu cầu sai';
      case 12:
        return 'Đã xảy ra lỗi, vui lòng thử lại sau';
      case 13:
        return 'Thông số không hợp lệ';
      case 14:
        return 'Thất bại';
      case 15:
        return 'Yêu cầu gọi đã hết hạn';
      case 18:
      case 19:
        return 'Ngoài giờ làm việc';

      case 20:
        return 'OA chưa yêu cầu sự đồng ý của người dùng';
      case 21:
        return 'OA đã tắt cuộc gọi đến';
    }
  }

  zaloErrorCode(code: number) {
    switch (code) {
      case -100:
        return 'Xảy ra lỗi không xác định, vui lòng thử lại sau'
      case -101:
        return 'Ứng dụng gửi ZNS không hợp lệ'
      case -102:
        return 'Ứng dụng gửi ZNS không tồn tại'
      case -103:
        return 'Ứng dụng chưa được kích hoạt'
      case -104:
        return 'Secret key của ứng dụng không hợp lệ'
      case -105:
        return 'Ứng dụng gửi ZNS chưa đươc liên kết với OA nào'
      case -106:
        return 'Phương thức không được hỗ trợ'
      case -107:
        return 'ID thông báo không hợp lệ'
      case -108:
        return 'Số điện thoại không hợp lệ'
      case -109:
        return 'ID mẫu ZNS không hợp lệ'
      case -1091:
        return 'Template không có trạng thái Reject hoặc Template được tạo từ Admin tool'
      case -110:
        return 'Phiên bản Zalo app không được hỗ trợ. Người dùng cần cập nhật phiên bản mới nhất'
      case -111:
        return 'Mẫu ZNS không có dữ liệu'
      case -112:
        return 'Nội dung mẫu ZNS không hợp lệ'
      case -1121:
        return 'Dữ liệu tham số vượt quá giới hạn ký tự'
      case -1122:
        return 'Dữ liệu mẫu ZNS thiếu tham số'
      case -1123:
        return 'Không thể tạo QR code, vui lòng kiểm tra lại'
      case -1124:
        return 'Dữ liệu tham số không đúng format'
      case -113:
        return 'Button không hợp lệ'
      case -114:
        return 'Người dùng không nhận được ZNS vì các lý do: Trạng thái tài khoản, Tùy chọn nhận ZNS, Sử dụng Zalo phiên bản cũ, hoặc các lỗi nội bộ khác'
      case -115:
        return 'Tài khoản ZNS không đủ số dư'
      case -116:
        return 'Nội dung không hợp lệ'
      case -117:
        return 'OA hoặc ứng dụng gửi ZNS chưa được cấp quyền sử dụng mẫu ZNS này'
      case -118:
        return 'Tài khoản Zalo không tồn tại hoặc đã bị vô hiệu hoá'
      case -119:
        return 'Tài khoản không thể nhận ZNS'
      case -120:
        return 'OA chưa được cấp quyền sử dụng tính năng này'
      case -1201:
        return 'OA chưa có quyền tạo template tag 3'
      case -1202:
        return 'OA không có quyền sử dụng media resources (image/logo)'
      case -121:
        return 'Mẫu ZNS không có nội dung'
      case -122:
        return 'Body request không đúng định dạng JSON'
      case -123:
        return 'Giải mã nội dung thông báo RSA thất bại'
      case -124:
        return 'Mã truy cập không hợp lệ'
      case -1241:
        return 'appsecret_proof không hợp lệ'
      case -125:
        return 'ID Official Account không hợp lệ'
      case -126:
        return 'Ví (development mode) không đủ số dư'
      case -127:
        return 'Template test chỉ có thể được gửi cho quản trị viên'
      case -128:
        return 'Mã encoding key không tồn tại'
      case -129:
        return 'Không thể tạo RSA key, vui lòng thử lại sau'
      case -130:
        return 'Nội dung mẫu ZNS vượt quá giới hạn kí tự'
      case -131:
        return 'Mẫu ZNS chưa được phê duyệt'
      case -132:
        return 'Tham số không hợp lệ'
      case -133:
        return 'Mẫu ZNS này không được phép gửi vào ban đêm (từ 22h-6h)'
      case -134:
        return 'Người dùng chưa phản hồi gợi ý nhận ZNS từ OA'
      case -135:
        return 'OA chưa có quyền gửi ZNS (chưa được xác thực, đang sử dụng gói miễn phí)'
      case -1351:
        return 'OA không có quyền gửi ZNS (Hệ thống chặn do phát hiện vi phạm)'
      case -136:
        return 'Cần kết nối với ZCA để sử dụng tính năng này'
      case -137:
        return 'Thanh toán ZCA thất bại (ví không đủ số dư, …)'
      case -138:
        return 'Ứng dụng gửi ZNS chưa có quyền sử dụng tính năng này'
      case -1381:
        return 'OA chưa cấp quyền cho Extension về quyền sử dụng ZCA của OA'
      case -139:
        return 'Người dùng từ chối nhận loại ZNS này'
      case -140:
        return 'OA chưa được cấp quyền gửi ZNS hậu mãi cho người dùng này'
      case -141:
        return 'Người dùng từ chối nhận ZNS từ Official Account'
      case -142:
        return 'RSA key không tồn tại, vui lòng gọi API tạo RSA key'
      case -143:
        return 'RSA key đã tồn tại, vui lòng gọi API lấy RSA key'
      case -144:
        return 'OA đã vượt giới hạn gửi ZNS trong ngày'
      case -1441:
        return 'OA request gửi vượt ngưỡng monthly promotion quota'
      case -145:
        return 'OA không được phép gửi loại nội dung ZNS này'
      case -146:
        return 'Mẫu ZNS này đã bị vô hiệu hoá do chất lượng gửi thấp'
      case -147:
        return 'Mẫu ZNS đã vượt giới hạn gửi trong ngày'
      case -1471:
        return 'OA đã vượt giới hạn gửi tin ZNS hậu mãi cho người dùng này trong tháng.'
      case -148:
        return 'Không tìm thấy ZNS journey token'
      case -149:
        return 'ZNS journey token không hợp lệ'
      case -150:
        return 'ZNS journey token đã hết hạn'
      case -151:
        return 'Không phải mẫu ZNS E2EE'
      case -152:
        return 'Lấy E2EE key thất bại'
      case -152:
        return 'Lấy E2EE key thất bại'
      case -153:
        return 'Dữ liệu truyền vào sai quy định'
      case -158:
        return 'Dung lượng file vượt qua dung lượng cho phép'
      case -159:
        return 'Định dạng file upload không được cho phép'
      case -160:
        return 'Số lượng tạo/edit template hoặc upload attachment vượt quá daily quota'
      case -32:
        return 'Vượt quá giới hạn request/phút'
      case -201:
        return 'Tham số không hợp lệ'
      case -204:
        return 'Official Account đã bị xóa'
      case -205:
        return 'Official Account không tồn tại'
      case -209:
        return 'Api này không được hỗ trợ'
      case -210:
        return 'Tham số vượt quá giới hạn cho phép'
      case -211:
        return 'Hết quota'
      case -212:
        return 'Official Account chưa đăng ký api này'
      case -213:
        return 'Người dùng chưa quan tâm Official Account'
      case -214:
        return 'Bài viết đang được xử lý'
      case -216:
        return 'Access token không hợp lệ'
      case -217:
        return 'Người dùng đã chặn tin mời quan tâm'
      case -218:
        return 'Hết quota nhận'
      case -221:
        return 'Tài khoản Official Account chưa xác thực'
      case -224:
        return 'Official Account chưa mua gói dịch vụ'
      case -227:
        return 'Người dùng đã bị khóa hoặc không online hơn 45 ngày.'
      case -230:
        return 'Người dùng không tương tác với OA trong 7 ngày qua.'
      case -232:
        return 'Người dùng chưa tương tác với OA'
      case -233:
        return 'Loại tin nhắn không được hỗ trợ'
      case -234:
        return 'Loại tin nhắn này không được gửi vào buổi tối (từ 22 giờ – 6 giờ sáng hôm sau)'
      case -235:
        return 'API không hỗ trợ cho loại OA của bạn'
      case -240:
        return 'API gửi tin nhắn V2 đã không còn hoạt động, vui lòng chuyển qua API gửi tin nhắn V3. Xem hướng dẫn tại https://go.zalo.me/api-v3'
      case -320:
        return 'Ứng dụng của bạn cần kết nối với Zalo Cloud Account để sử dụng tính năng trả phí.'
      case -321:
        return 'Zalo Cloud Account liên kết với App đã hết tiền hoặc không thể thực hiện trả phí.'
      default:
        return 'Không xác định';
    }
  }
}

export namespace AdminZaloAPI {

  export class SummarizeRequest {
    date_from?: string | Date;
    date_to?: string | Date;
    oa_id?: string;
    shop_id?: string;
  }
  
  export class AuthorizeOARequest {
    connection_id: string;
    app_id?: string;
    app_secret?: string;
  }
  export class SendZNSRequest {
    template_data?: any;
    oa_id: string;
    phone: string;
    template_id: number;
    tracking_id?: string;
  }

  export class CreateShopOARequest {
    code: string;
    oa_id?: string;
    state: number;
  }

  export class RequestConsent {
    call_type: 'unknown' | 'audio' | 'video' | 'audio_and_video';
    phone: string;
    reason_code:
      | 'unknown'
      | 'product_service_consulting'
      | 'order_appointment_confirmation'
      | 'delivery_notification'
      | 'flight_announcement'
      | 'update_order';
    oa_id: string;
  }

  export class Paging {
    paging?: CursorPaging;
  }

  export class GetListRequestConsent {
    paging?: CursorPaging;
    filter?: FilterRequestConsent;
  }

  export class GetListTemplate {
    paging?: CursorPaging;
    filter?: FilterTemplate;
  }

  export class GetListJourneys {
    paging?: CursorPaging;
    filter?: FilterJourney;
  }

  export class GetListCampaigns extends Paging {
    filter?: FilterCampaigns;
  }

  export class FilterJourney {
    type?: 'text' | 'otp' | 'table' | 'rating';
    date_from?: string;
    date_to?: string;
    oa_id?: string;
    is_charged?: boolean;
    journey_id?: string;
    msg_id?: string;
    phone?: string;
    status?: string;
  }

  export class FilterCampaigns {
    campaign_name?: string;
    is_scheduled?: boolean;
    oa_id?: string;
    status?: string;
    template_id?: number;
    date_from?: string;
    date_to?: string;
  }

  export class UpdateCampaign {
    campaign_name?: string;
    is_scheduled?: boolean;
    scheduled_time?: string;
    oa_id: string;
    template_id: number;
    campaign_id: string;
  }

  export class FilterTemplate {
    type?: 'text' | 'otp' | 'table' | 'rating';
    date_from?: string;
    date_to?: string;
    oa_id?: string;
    template_id?: number;
    template_name?: string;
  }

  export class FilterRequestConsent {
    account_id?: string;
    call_type?: string;
    date_from?: string;
    date_to?: string;
    phone?: string;
    reason_code?: string;
    status?: string;
    user_consent?: string;
  }

  export class GetListFollowersOA {
    paging?: CursorPaging;
    filter?: FilterFollowersOA;
  }

  export class FilterFollowersOA {
    date_from?: string;
    date_to?: string;
    name?: string;
    oa_id?: string;
    status?: string;
    zl_user_ids?: string[];
    tags?: string[];
  }

  export class GetMessage {
    paging?: CursorPaging;
    filter?: FilterMessage;
  }

  export class FilterMessage {
    msg_type?: 'template' | 'journey' | 'journey';
    date_from?: string;
    date_to?: string;
    oa_id?: string;
    template_id?: number;
    journey_id?: string;
    phone?: string;
    tracking_id?: string;
    msg_id?: string;
    campaign_id?: string;
    user_id?: string;
  }

  export class ExportFilter {
    delimiter?: string;
    excel_compatible_mode: boolean;
  }

  export class ExportZCC {
    delimiter?: string;
    excel_compatible_mode: boolean;

    filter?: {
      date_from?: string;
      date_to?: string;
      call_type?: 'audio' | 'video' | 'audio_and_video';
      is_charged?: boolean;
      oa_id?: string;
      phone?: string;
      reason_code?: string;
      reply_status?: 'Z' | 'P' | 'S' | 'N' | 'NS';
      status?: 'Z' | 'P' | 'N';
      user_id?: string;
    };
  }

  export class ExportZNS {
    delimiter?: string;
    excel_compatible_mode: boolean;

    filter?: {
      date_from?: string;
      date_to?: string;
      delivery_status?: 'received' | 'seen';
      is_charged?: boolean;
      oa_id?: string;
      phone?: string;
      status?: 'Z' | 'P' | 'N';
      user_id?: string;
      journey_id?: string;
      msg_id?: string;
      msg_type?: 'template' | 'journey' | 'uid';
      template_id?: number;
      tracking_id?: string;
    };
  }

  export class ExportCampaign {
    delimiter?: string;
    excel_compatible_mode: boolean;

    filter?: {
      date_from?: string;
      date_to?: string;
      campaign_name?: string;
      is_scheduled?: boolean;
      oa_id?: string;
      status?: string;
      template_id?: number;
      user_id?: string;
    };
  }

  export class CreateCampaignsFromImport {
    campaign_name: string;
    is_scheduled: boolean;
    oa_id: string;
    scheduled_time: string;
    template_id: number;
    data: Array<any>;
  }
}
