import {BaseModel} from '../core/base/base-model';
import {Hotline} from "@etop/models/Hotline";
import {Relationship} from "@etop/models/Authorization";

export class Extension extends BaseModel {
  account_id: string;
  created_at: Date;
  expires_at: Date;
  extension_number: string;
  hotline_id: string;
  id: string;
  updated_at: Date;
  user_id: string;
  hotline?: Hotline;
  relationship?: Relationship;
  subscription_id: string;
  state: string;

  static extensionMap (extension: Extension, hotlines?: Hotline[], relationships?: Relationship[]): Extension
  {
    let mappingExtension = {...extension};
    let hotline = hotlines.find(h => h.id == extension.hotline_id);
    mappingExtension.hotline = hotline;
    let relationship = relationships.find(r => r.user_id == extension.user_id);
    mappingExtension.relationship = relationship;
    return mappingExtension;
  }
}


