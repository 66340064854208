export function log(err: any) {
  return undefined;
}

declare var eyeDebug: any;

const stringify = function(args): string {
  if (!args || !args.length) {
    return '';
  }
  for (let i = 0; i < args.length; i++) {
    if (typeof args[i] == 'object') {
      args[i] = JSON.stringify(args[i], null, 2);
    }
  }
  return args;
};

export function setupDebug(environment, isMobile = false) {
  (window as any).eyeDebug = '';
  (window as any).debug = {
    log: function() {
      if (!environment.production || eyeDebug == 'eyeteam.vn') {
        // tslint:disable-next-line:no-console
        console.log.apply(null, isMobile ? stringify(arguments) : arguments);
      }
    },
    error: function() {
      if (!environment.production || eyeDebug == 'eyeteam.vn') {
        // tslint:disable-next-line:no-console
        console.error.apply(null, isMobile ? stringify(arguments) : arguments);
      }
    },
    trace: function() {
      if (!environment.production || eyeDebug == 'eyeteam.vn') {
        // tslint:disable-next-line:no-console
        console.trace.apply(null, isMobile ? stringify(arguments) : arguments);
      }
    }
  };

}
