import {Shop} from "@etop/models/Account";
import {AdminUser} from "@etop/models/admin";
import {Contact} from './Contact';
import {Relationship} from "@etop/models/Authorization";
import {User} from "@etop/models/User";

export enum TicketType {
  system = "system",
  internal = "internal",
  unknow = "unknow"
}

export enum TicketRefType {
  order_trading = "order_trading",
  ffm = "ffm",
  money_transaction = "money_transaction",
  other = "other",
  contact = "contact"
}

export enum TicketSource {
  pos_web = "pos_web",
  pos_app = "pos_app",
  shipment_app = "shipment_app",
  admin = "admin",
  system = "system"
}

export enum TicketState {
  unknown = "unknown",
  new = "new",
  received = "received",
  processing = "processing",
  success = "success",
  fail = "fail",
  ignore = "ignore",
  cancel = "cancel"
}

export enum TicketTab {
  general = "general",
  assigned = "assigned"
}

const TICKET_STATE = {
  new: 'Mới',
  unknown: "Không xác định",
  received: "Đã tiếp nhận",
  processing: "Đang xử lý",
  success: "Xử lý thành công",
  fail: "Xử lý thất bại",
  ignore: "Từ chối xử lý"
};

const TICKET_REF_TYPE = {
  order_trading: "Đơn hàng trading",
  ffm: "Đơn giao hàng",
  money_transaction: "Phiên đối soát",
  other: "Đối tượng khác",
  contact: "Danh bạ"
}

const TICKET_SOURCE = {
  pos_web: "eTop POS Web",
  pos_app: "eTop POS App",
  shipment_app: "Topship App",
  admin: "Admin",
  system: "Tự động từ hệ thống",
  webphone: "Web phone extentions"
}

export class Ticket {
  account_id: string;
  shop: Shop;

  admin_note: string;

  assigned_user_ids: string[];
  assignedUsers: Relationship[] | AdminUser[];

  closed_at: Date;
  closed_by: string;
  closedByUser: Relationship | AdminUser;

  code: string;

  confirmed_at: Date;
  confirmed_by: string;
  confirmedByUser: Relationship | AdminUser;

  created_at: Date;
  created_by: string;
  createdByUser: User | Relationship | AdminUser;

  description: string;
  id: string;
  label_ids: string[];
  labels: TicketLabel[];
  note: string;
  ref_id: string;
  ref_code: string;
  ref_type: TicketRefType;
  type: TicketType;
  ref_type_display: string;
  source: TicketSource;
  source_display: string;
  state: TicketState;
  state_display: string;
  status: string;
  title: string;
  updated_at: Date;
  updated_by: string;

  from?: {
    created_by: string;
    name: string;
    source: string;
  }

  comments: TicketComment[];
  contact: Contact;

  static ticketStateMap(state: TicketState) {
    return TICKET_STATE[state];
  }

  static ticketRefTypeMap(type: TicketRefType) {
    return TICKET_REF_TYPE[type];
  }

  static ticketSourceMap(src: TicketSource) {
    return TICKET_SOURCE[src];
  }

  static ticketMap(ticket: Ticket): Ticket {
    return {
      ...ticket,
      state_display: Ticket.ticketStateMap(ticket.state),
      ref_type_display: Ticket.ticketRefTypeMap(ticket.ref_type),
      source_display: Ticket.ticketSourceMap(ticket.source),
      closed_by: ticket.closed_by == "0" ? null : ticket.closed_by
    };
  }

}

export class TicketComment {
  account_id: string;
  created_at: Date;
  deleted_at: Date;
  deleted_by: string;
  id: string;
  image_url: string;
  message: string;
  parent_id: string;
  ticket_id: string;
  updated_at: Date;

  created_by: string;

  createdByDisplay?: string;
  ownComment?: boolean;
  sendingStatus?: 'sending' | 'error';

  pID?: string;
  from?: {
    created_by: string;
    name: string;
    source: string;
  }

  static ticketCommentMap(
    currentUserID: string, comment: TicketComment, adminUsers: AdminUser[], shopName: string
  ): TicketComment {
    const fromAdmin = adminUsers.find(ad => ad.user_id == comment.created_by);
    const ownComment = (currentUserID == comment.created_by) || !!fromAdmin;

    let createdByDisplay: string;
    if (ownComment) {
      createdByDisplay = adminUsers?.length ? `${fromAdmin?.full_name} - ${fromAdmin?.roles_display}` : `Shop ${shopName}`;
    } else {
      createdByDisplay = adminUsers?.length ? `Shop ${shopName}` : 'TOPSHIP';
    }

    return {
      ...comment,
      pID: comment?.id,
      ownComment,
      createdByDisplay
    };
  }
}

export class TicketLabel {
  children: TicketLabel[];
  id: string;
  name: string;
  code: string;
  color: string;
  parent_id: string;
}

export enum ActionType {
  ffm_update_cod = 'ffm_update_cod',
  ffm_other = 'ffm_other',
  ffm_push_pickup = 'ffm_push_pickup',
  ffm_push_delivery = 'ffm_push_delivery',
  ffm_push_re_delivery = 'ffm_push_re_delivery',
  ffm_update_info = 'ffm_update_info',
  ffm_update_name = 'ffm_update_name',
  ffm_update_phone = 'ffm_update_phone',
  ffm_update_address = 'ffm_update_address',
}


