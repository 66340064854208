import { BaseModel } from '@etop/core';

export class MessageTemplate extends BaseModel{
  id: string;
  shop_id: string;
  short_code: string;
  template: string;
  created_at: string;
  updated_at: string;
}

export class MessageTemplateVariable extends BaseModel {
  code: string;
  label: string;
}
