import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpErrorResponse } from '@angular/common/http';
import { AuthenticateStore } from '@etop/core';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, map } from 'rxjs/operators';

declare var __debug_host: string;

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  constructor(
    private http: HttpClient,
    private authenServer: AuthenticateStore,
    @Optional() @Inject("SERVICE_URL") private SERVICE_URL: string
  ) {}

  static handleError(error: HttpErrorResponse) {
    // In a real world app, you might use a remote logging infrastructure
    if (!error?.error) {
      return;
    }
    let code = error.error.code;
    let message = error.error.msg;
    let meta = error.error.meta;

    if (!message) {
      switch (error.status) {
        case 404:
          code = 404;
          message = 'Không tìm thấy api';
          break;
      }
    }

    const errMsg = {
      code,
      message,
      meta
    };
    return throwError(errMsg);
  }

  private static extractData(res) {
    if ((res.status >= 200 && res.status < 300) || res.result) {
      return res.body || res.result;
    }
    throw res;
  }

  createHeader(token?: string) {
    const jwt = token || this.authenServer.snapshot.token;
    return new HttpHeaders()
      .set('Authorization', 'Bearer ' + jwt)
      .set('Content-Type', 'application/json');
  }

  createDefaultOption(headers?: any) {
    return {
      headers: headers || this.createHeader(),
      params: null,
      observe: 'response'
    };
  }

  listenSSE(link, events: string[]): Observable<{ event: string, data: any }> {
    let source = new EventSource(this.getUrl(link));
    return new Observable(observer => {
      events.forEach(event =>
        source.addEventListener(event, (e: MessageEvent) => {
          observer.next({
            event: event,
            data: JSON.parse(e.data)
          });
        })
      );
      source.onerror = e => {
        debug.log('EVENT STREAM ERROR', e);
        // observer.error(e);
        // source.close();
      };

      return {
        unsubscribe(): void {
          source.close();
        }
      };
    });
  }

  get(link, query?: object, token?: string): Observable<any> {
    const options = this.createDefaultOption(this.createHeader(token));

    if (query) {
      let params = new HttpParams();
      (<any>Object).entries(query).forEach(entry => {
        const key = entry[0],
          value = entry[1];

        if (Array.isArray(value)) {
          value.forEach(val => {
            params = params.append(`${key}`, val);
          });
        } else {
          params = params.set(key, value);
        }
      });

      options.params = params;
    }

    return this.getWithOptions(link, options);
  }

  post(link, data, token?: string): Observable<any> {
    const options = this.createDefaultOption(this.createHeader(token));
    return this.postWithOptions(link, data, options);
  }

  postForm(link, formData: FormData, token?: string): Observable<any> {
    const headers = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + (token || this.authenServer.snapshot.token)
    );
    const options = this.createDefaultOption(headers);
    return this.postWithOptions(`${link}`, formData, options);
  }

  put(link, data?: any, token?: string): Observable<any> {
    const headers = this.createHeader(token);
    const options = { headers: headers };
    return this.putWithOptions(link, data || {}, options);
  }

  putForm(link, formData?: FormData, token?: string): Observable<any> {
    const headers = new HttpHeaders().set(
      'Authorization',
      (token || this.authenServer.snapshot.token)
    );
    const options = { headers: headers };
    return this.http.put(`${link}`, formData || new FormData(), options).pipe(
      map(HttpService.extractData),
      catchError(HttpService.handleError)
    );
  }

  delete(link, token?: string): Observable<any> {
    const options = this.createDefaultOption(this.createHeader(token));
    return this.deleteWithOptions(link, options);
  }

  postWithOptions(link, data, options): Observable<any> {
    return this.http.post(this.getUrl(link), data, options).pipe(
      map(HttpService.extractData),
      catchError(HttpService.handleError)
    );
  }

  getWithOptions(link, options): Observable<any> {
    return this.http.get(this.getUrl(link), options).pipe(
      map(HttpService.extractData),
      catchError(HttpService.handleError)
    );
  }

  putWithOptions(link, data, options): Observable<any> {
    return this.http.put(this.getUrl(link), data, options).pipe(
      map(HttpService.extractData),
      catchError(HttpService.handleError)
    );
  }

  deleteWithOptions(link, options): Observable<any> {
    return this.http.delete(this.getUrl(link), options).pipe(
      map(HttpService.extractData),
      catchError(HttpService.handleError)
    );
  }

  private getUrl(link) {
    let host = !/http(s?)/i.test(link) && (this.SERVICE_URL) || '';
    if (host) {
      if (!host.startsWith('http')) {
        let url = window.location.href;
        let arr = url.split('/');
        host = arr[0] + '//' + host;
      }
      if (!host.endsWith('/')) {
        host = host + '/';
      }
    }
    if (link.startsWith('/')) {
      link = link.slice(1);
    }
    return `${host}${link}`;
  }

  createCustomDefaultOption(token) {
    const headers = this.createHeader(token);
    return {
      headers: headers,
      params: null,
      observe: 'response'
    };
  }

  createCustomHeader(token) {
    return new HttpHeaders()
      .set('Authorization', 'Bearer ' + token)
      .set('Content-Type', 'application/json');
  }

  createCustomOption(token) {
    const headers = this.createHeader(token);
    return {
      headers: headers,
      params: null,
      observe: 'response'
    };
  }

  customPost(link, data, token): Observable<any> {
    const options = this.createCustomOption(token);
    return this.postWithOptions(link, data, options);
  }

  purePost(link, data, token): Observable<any> {
    const options: any = this.createCustomOption(token);
    return this.http.post(link, data, options);
  }

  purePostForm(link, formData: FormData): Observable<any> {
    const headers = new HttpHeaders().set(
      'Authorization',
      'Bearer ' + this.authenServer.snapshot.token
    );
    const options = { headers: headers };
    return this.http.post(`${link}`, formData, options).pipe(
      catchError(HttpService.handleError)
    );
  }

  upload(formData) {
    return this.postForm('upload', formData).toPromise().then(res => res.result);
  }
}
