<div class="nav-sidebar hide-768" [ngClass]="{'sidebar-expanded-mobile': !isCollapse, 'sidebar-expanded-desktop': isCollapse}">
  <div class="nav-sidebar-inner-scroll">
    <div class="logo">
      <img src="{{getAssets()?.logo}}" alt="logo">
    </div>
    <ul class="sidebar-top-level-items">
      <ng-container *ngFor="let menu of menus">
        <ng-container *eRoles="menu?.roles">
          <ng-container *ePermissions="menu?.permissions">
            <li
              *ngIf="!menu?.hidden"
              class="is-over is-showing-fly-out"
              [class.position-relative]="menu?.display_submenus"
              [class.hide-768]="menu?.onlyDesktop"
              routerLinkActive="active" #rla="routerLinkActive">
              
              <a *ngIf="!menu.href" class="justify-content-center parent-menu">
                <div class="nav-icon-container" [routerLink]="menu.route">
                  <div class="menu-icon {{rla.isActive ? 'active-menu-item' : ''}}">
                    <img *ngIf="menu?.icon" [src]="menu.icon" alt="">
                    <mat-icon *ngIf="menu?.matIcon" fontSet="{{menu?.matIconOutlined && 'material-icons-outlined'}}">
                      {{menu?.matIcon}}
                    </mat-icon>
                  </div>
                </div>
                <span class="nav-item-name" [routerLink]="menu.route" *ngIf="isCollapse">{{menu.name}}</span>
                <div class="expand-menu {{rla.isActive ? 'collapsed' : ''}}" *ngIf="menu?.display_submenus" [attr.data-toggle]="'collapse'" [attr.data-target]="'#'+menu.route" [attr.aria-expanded]="rla.isActive ? true : false" [attr.aria-controls]="menu.route">
                  <mat-icon class="arrow-more">expand_more</mat-icon>
                  <mat-icon class="arrow-less">expand_less</mat-icon>
                </div>
              </a>
              <a [href]="menu.href" target="_blank" *ngIf="menu.href" class="justify-content-center parent-menu">
                <div class="nav-icon-container">
                  <div class="menu-icon">
                    <img *ngIf="menu?.icon" [src]="menu.icon" alt="">
                    <mat-icon *ngIf="menu?.matIcon" fontSet="{{menu?.matIconOutlined && 'material-icons-outlined'}}">
                      {{menu?.matIcon}}
                    </mat-icon>
                  </div>
                </div>
                <span class="nav-item-name" *ngIf="isCollapse">{{menu.name}} 1</span>
              </a>

              <ng-container *ngIf="menu?.display_submenus || !isCollapse">
                <ul class="sidebar-sub-level-items fly-out-list is-fly-out-only collapse {{rla.isActive ? 'show' : ''}}" id="{{menu.route}}">
                  <ng-container *ngIf="!isCollapse">
                    <li>
                      <a class="menu-dropdown-item" [routerLink]="menu.route">
                        <strong class="px-4 fly-out-top-item-name">{{menu.name}}</strong>
                      </a>
                    </li>
                  </ng-container>
                  <ng-container *ngFor="let submenu of menu?.submenus; let first = first">
                    <ng-container *ePermissions="submenu?.permissions">
                      <ng-container *ngIf="!submenu?.hidden">
                        <li class="submenu-dropdown-item" routerLinkActive="active" #rlaSub="routerLinkActive">
                          <a class="submenu-item {{rlaSub.isActive? 'active' : ''}}" [routerLink]="submenu.route">
                            <span class="nav-item-name">{{submenu.name}}</span>
                            <div class="submenu-icon">
                              <mat-icon>circle</mat-icon>
                            </div>
                          </a>
                        </li>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                </ul>
              </ng-container>
            </li>
            <div *ngIf="menu.isDivider" class="divider"></div>
          </ng-container>
        </ng-container>
      </ng-container>
      <li class="toggle-sidebar-button" (click)="toggleSidebar()" *ngIf="isCollapse">
        <i class="fa fa-angle-double-left text-bigger mr-2"></i>
        <span class="collapse-text mt-1">Thu gọn</span>
      </li>
      <li class="toggle-sidebar-button" (click)="toggleSidebar()" *ngIf="!isCollapse">
        <i class="fa fa-angle-double-right text-bigger m-auto"></i>
      </li>
    </ul>
  </div>
</div>
<div class="show-768" style="width: 100%;bottom: 0;position: fixed;z-index: 10;">
  <div class="nav-sidebar-mobile">
    <ng-container *ngFor="let menu of menus">
      <ng-container *eRoles="menu?.roles">
        <ng-container *ePermissions="menu?.permissions">
          <div class="menu-item" routerLinkActive="active" #rla="routerLinkActive" *ngIf="!menu.hidden && !menu.onlyDesktop"
               [class.active]="rla.isActive">
            <a *ngIf="!menu.href" [routerLink]="menu.route" class="menu-icon-container">
              <div class="menu-icon" [ngStyle]="{'background-color': rla.isActive ? menu?.icon_color : ''}">
                <img *ngIf="menu?.icon" [src]="menu.icon" alt="">
                <mat-icon *ngIf="menu?.matIcon" fontSet="{{menu?.matIconOutlined && 'material-icons-outlined'}}">
                  {{menu?.matIcon}}
                </mat-icon>
              </div>
            </a>
            <a *ngIf="menu.href" [href]="menu.href" target="_blank" class="menu-icon-container">
              <div class="menu-icon">
                <img *ngIf="menu?.icon" [src]="menu.icon" alt="">
                <mat-icon *ngIf="menu?.matIcon" fontSet="{{menu?.matIconOutlined && 'material-icons-outlined'}}">
                  {{menu?.matIcon}}
                </mat-icon>
              </div>
            </a>
            <div class='menu-name'>{{menu.name}}</div>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-content select="div[role=customSidebar]"></ng-content>
  </div>
</div>
