import { Injectable } from '@angular/core';
import { Address, OrderAddress } from 'libs/models/Address';
import {Fulfillment,FulfillmentShipmentService} from 'libs/models/Fulfillment';
import { AStore } from 'apps/core/src/interfaces/AStore';
import { FulfillmentAPI } from '@etop/api';
import { Order, OrderLine, Shipping } from 'libs/models/Order';
import { Product, Variant } from 'libs/models/Product';
import { Subject } from 'rxjs';
import { Customer, CustomerAddress } from 'libs/models/Customer';

// export interface ShipmentService {
//   selected: boolean;
//   unique_id: string;
//   name: string;
//   fee: number;
//   code: string;
//   connection_info: any;
//   provider: string;
//   estimated_pickup_at: string;
//   estimated_delivery_at: string;
// }

export interface ConfirmOrderData {
  fromAddresses: Address[];
  activeFromAddress: Address;

  toAddresses: Address[];
  activeToAddress: Address;
  customerAddress: CustomerAddress[];
  activeCustomerAddress: CustomerAddress;

  productLoading: boolean;
  products: Product[];
  variants: Variant[];
  variant: Variant;
  product: Product;
  order: Order;
  customer: Customer;
  fulfillment: Fulfillment;
  resetData: boolean,
  confirmOrder: boolean,


  shippingServiceName:string,
  shippingServiceFee:number,

  showDropdown: boolean;
  shipmentServicesRequest: FulfillmentAPI.GetShipmentServicesRequest;
  activeShipmentService: FulfillmentShipmentService;
  coupon: string;
  copyDataOrderCustomer: CustomerAddress;
  saveDataOrder: Order;
  saveDataFulfillment: Fulfillment;
}

@Injectable()
export class ConfirmOrderStore extends AStore<ConfirmOrderData> {
  initState = {
    fromAddresses: [],
    activeFromAddress: null,
    shippingServiceName: null,
    shippingServiceFee: null,
    toAddresses: [],
    customerAddress: [],
    activeToAddress: null,
    activeCustomerAddress: null,
    confirmOrder: null,
    order: new Order({
      lines: [],
      customer: new Customer({}),
      shipping : new Shipping(),
    }),
    productLoading: false,
    products: [],
    coupon: null,
    variants: [],
    variant: new Variant({}),
    product: new Product({}),
    customer: new Customer({}),
    resetData: false,
    fulfillment: new Fulfillment({
      pickup_address: new OrderAddress({}),
      shipping_address: new OrderAddress({}),
      chargeable_weight: 500,
      p_data: { cod_amount_edited: false, insurance_edited: false }
    }),
    shipmentServicesRequest: new FulfillmentAPI.GetShipmentServicesRequest(),
    activeShipmentService: null,
    showDropdown: false,
    copyDataOrderCustomer: new CustomerAddress({}),
    saveDataOrder: new Order({
      p_data: { is_save: false }
    }),
    saveDataFulfillment: new Fulfillment({
      p_data: { is_save: false }
    }),
  };

  readonly forceUpdateForm$ = new Subject();

  constructor() {
    super();
  }

  forceUpdateForm() {
    this.forceUpdateForm$.next();
  }

  setShipmentServicesRequest(
    request: Partial<FulfillmentAPI.GetShipmentServicesRequest>
  ) {
    const _shipmentServicesRequest = this.snapshot.shipmentServicesRequest;
    this.setState({
      shipmentServicesRequest: {
        ..._shipmentServicesRequest,
        ...request
      }
    });
  }

  setActiveShipmentService(service: FulfillmentShipmentService) {
    this.setState({ activeShipmentService: service });
  }

  setResetData(reset:boolean){
    this.setState({resetData:reset})
  }

  setConfirmOrder(confirmOrder: boolean){
    this.setState({confirmOrder: confirmOrder});
  }

  setCustomerAddresses(address: CustomerAddress[]) {
    this.setState({ customerAddress: address });
  }

  setFromAddresses(addresses: Address[]) {
    this.setState({ fromAddresses: addresses });
    this.setActiveFromAddress(addresses[0]);
  }

  setShowDropdown(dropdown) {
    this.setState({ showDropdown: dropdown });
  }

  addFromAddress(address: Address) {
    const _addresses = this.snapshot.fromAddresses;
    _addresses.push(address);
    this.setState({ fromAddresses: _addresses });
  }

  setActiveFromAddress(address: Address) {
    if (!address?.id) {
      address = {
        ...address,
        id: new Date().getTime().toString()
      };
    }
    this.setState({ activeFromAddress: address });
  }

  addOrderLine(line: OrderLine) {
    const _order = this.snapshot.order;
    const _lines = _order.lines;
    _lines.push(line);
    this.setState({
      order: {
        ..._order,
        lines: _lines
      }
    });
  }

  setProductLoading(loading: boolean) {
    this.setState({ productLoading: loading });
  }

  setLines(lines: OrderLine[]) {
    const _order = this.snapshot.order;
    this.setState({
      order: {
        ..._order,
        lines: lines
      }
    });
  }

  setToAddresses(addresses: Address[]) {
    this.setState({ toAddresses: addresses });
    this.setActiveToAddress(addresses[0]);
  }

  addToAddress(address: Address) {
    const _addresses = this.snapshot.toAddresses;
    _addresses.push(address);
    this.setState({ toAddresses: _addresses });
  }

  setActiveCustomerAddress(address: CustomerAddress) {
    this.setState({ activeCustomerAddress: address });
  }

  setActiveToAddress(address: Address) {
    if (!address?.id) {
      address = {
        ...address,
        id: new Date().getTime().toString()
      };
    }
    this.setState({ activeToAddress: address });
  }

  setProducts(products: Product[]) {
    this.setState({ products });
  }

  setProduct(product: Product) {
    this.setState({ product });
  }

  setVariant(variant: Variant) {
    this.setState({ variant });
  }

  setShippingServiceName(shipping_service_name: string) {
    this.setState({ shippingServiceName: shipping_service_name });
  }

  setShippingServiceFee(shipping_service_fee: number) {
    this.setState({ shippingServiceFee: shipping_service_fee });
  }

  setOrder(order: Order) {
    this.setState({ order });
    const shipmentServiceRequest = this.snapshot.shipmentServicesRequest;
    const { basket_value } = this.snapshot.order;
    this.setShipmentServicesRequest({
      ...shipmentServiceRequest,
      basket_value
    });
  }

  setOrderEtop(order) {
    this.setState({ order });
  }

  setCustomer(customer: Customer) {
    this.setState({ customer });
  }

  setFulfillment(fulfillment: Fulfillment) {
    this.setState({ fulfillment });
    const {
      chargeable_weight,
      include_insurance,
      pickup_address,
      shipping_address,
      coupon,
      cod_amount,
      insurance_value
    } = fulfillment;

    const _shipmentServicesRequest = this.snapshot.shipmentServicesRequest;
    this.setShipmentServicesRequest({
      ..._shipmentServicesRequest,
      chargeable_weight,
      include_insurance,
      insurance_value,
      total_cod_amount: cod_amount,
      coupon,
      from_province_code: pickup_address?.province_code,
      from_district_code: pickup_address?.district_code,
      from_ward_code: pickup_address?.ward_code,
      to_province_code: shipping_address?.province_code,
      to_district_code: shipping_address?.district_code,
      to_ward_code: shipping_address?.ward_code
    });

  }

  setCopyOrderCustomer(customer: CustomerAddress) {
    this.setState({ copyDataOrderCustomer: customer });
  }

  setSaveOrder(order: Order) {
    this.setState({ saveDataOrder: order });
  }

  setSaveFulfillment(fullfilment: Fulfillment) {
    this.setState({ saveDataFulfillment: fullfilment });
  }
}
