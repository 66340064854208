import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { Ticket } from 'libs/models/Ticket';
import { ConfigService } from '@etop/core';

@Injectable()
export class CrmApi {
  crm_url = '';

  constructor(private http: HttpService, private config: ConfigService) {
    this.crm_url = this.config.getConfig()?.crm_url;
  }

  createLead(body: {}) {
    body = Object.assign({}, body, {
      environment: this.config.get('production') ? 'production' : 'development'
    });
    return this.http.post(`${this.crm_url}/api.crm/lead`, body).toPromise();
  }

  updateLead(body: {}) {
    body = Object.assign({}, body, {
      environment: this.config.get('production') ? 'production' : 'development'
    });
    return this.http
      .post(`${this.crm_url}/api.crm/lead/update`, body)
      .toPromise();
  }

  createContact(body: {}) {
    return this.http.post(`${this.crm_url}/api.crm/contact`, body).toPromise();
  }

  updateContact(body: {}) {
    return this.http
      .post(`${this.crm_url}/api.crm/contact/update`, body)
      .toPromise();
  }

  createTicket(body: {}) {
    body = Object.assign({}, body, {
      environment: this.config.get('production') ? 'production' : 'development'
    });
    return this.http
      .post(`${this.crm_url}/api.crm/ticket`, body)
      .toPromise()
      .then(res => res.data);
  }

  getTickets(query: {}) {
    query = Object.assign({}, query, {
      environment: this.config.get('production') ? 'production' : 'development'
    });
    return this.http
      .get(`${this.crm_url}/api.crm/ticket`, query)
      .toPromise()
      .then(res => res.data);
  }

  getTicketComment(ticket_id) {
    return this.http.get(`${this.crm_url}/api.crm/ticket/${ticket_id}`)
      .toPromise()
      .then(res => res.data);
  }

  createComment(body: {}) {
    return this.http
      .post(`${this.crm_url}/api.crm/ticket/comment`, body)
      .toPromise()
      .then(res => res.data);
  }

}
