import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SideSliderComponent } from './side-slider.component';
import { EtopPipesModule } from 'libs/shared/pipes/etop-pipes.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [CommonModule, EtopPipesModule],
  declarations: [SideSliderComponent],
  exports: [SideSliderComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SideSliderModule {}
