import { Injectable } from '@angular/core';
import {Fulfillment, Provider, PROVIDER_LOGO, ShipnowDeliveryPoint, ShippingState} from 'libs/models/Fulfillment';
import { HttpService } from '@etop/common';
import { Observable } from 'rxjs';
import { AuthenticateStore } from '@etop/core';
import { UtilService } from 'apps/core/src/services/util.service';
import { Address, OrderAddress } from 'libs/models/Address';

export class FulfillmentsQuery {
  paging: {
    offset?: number;
    limit?: number;
    sort?: string;
  };
  filters: Array<{
    name: string;
    op: string;
    value: string;
  }>;
  mixed: {
    ids?: Array<string>;
    all?: boolean;
    all_shops?: true;
    all_suppliers?: true;
  };
  order_id: string;
  status: string;
}

export class ExportFilterQueryDTO {
  date_from: string;
  date_to: string;
  filters?: Array<{
    name: string,
    op: string,
    value: string,
  }>;
  delimiter?: string;
  excel_compatible_mode: boolean;
  ids?: Array<string>;
}

export class ExportStatisticalDTO {
  id?: string;
  date_from: string;
  date_to: string;
  filters?: Array<{
    name: string,
    op: string,
    value: string,
  }>;
  export_type: string;
  excel_compatible_mode: boolean
}

const PROVIDER_LOGO_LG = {
  ghn: "ship-ghn.png",
  ghtk: "ship-ghtk.png",
  vtpost: "ship-vtpost.png",
  ahamove: "ship-ahamove.png",
  default: "placeholder_medium.png",
};

@Injectable({ providedIn: 'root' })
export class FulfillmentApi {

  static get providerLogoLg() {
    return PROVIDER_LOGO_LG;
  }

  constructor(
    private http: HttpService,
    private auth: AuthenticateStore,
    private util: UtilService
  ) { }

  private static parseNumberShipment(shipment: FulfillmentAPI.CreateShipmentFulfillmentRequest) {
    return {
      ...shipment,
      chargeable_weight: Number(shipment.chargeable_weight),
      cod_amount: Number(shipment.cod_amount),
      height: Number(shipment.height),
      length: Number(shipment.length),
      shipping_service_fee: Number(shipment.shipping_service_fee),
      width: Number(shipment.width),
    };
  }

  private static parseNumberShipmentServices(
    data: FulfillmentAPI.GetShipmentServicesRequest
  ): FulfillmentAPI.GetShipmentServicesRequest {
    return {
      ...data,
      basket_value: Number(data.basket_value),
      chargeable_weight: Number(data.chargeable_weight),
      gross_weight: Number(data.gross_weight),
      height: Number(data.height),
      length: Number(data.length),
      total_cod_amount: Number(data.total_cod_amount),
      width: Number(data.width),
    };
  }

  private static parseNumberShipnow(shipnow: FulfillmentAPI.CreateShipnowFulfillmentRequest) {
    shipnow.shipping_service_fee = Number(shipnow.shipping_service_fee);
    if (shipnow.delivery_points && shipnow.delivery_points.length) {
      shipnow.delivery_points.forEach(dp => {
        dp.cod_amount = Number(dp.cod_amount)
      });
    }
    return shipnow;
  }

  private static parseNumberShipnowServices(
    data: FulfillmentAPI.GetShipnowServicesRequest
  ): FulfillmentAPI.GetShipnowServicesRequest {
    data.delivery_points.forEach(dp => {
      dp.cod_amount = Number(dp.cod_amount)
    });
    return {...data};
  }

  static getProviderLogoBig(provider: Provider | string) {
    return `assets/images/${FulfillmentApi.providerLogoLg[
      provider
    ] || FulfillmentApi.providerLogoLg.default}`;
  }

  // NOTE: Shipment

  importFulfillments(formData) {
    return this.http.purePostForm('api/shop.Import/Fulfillments', formData).toPromise()
      .then(res => {
        if (!res?.fulfillments?.length) {
          throw {code: 'cell_errors', errors: res.cell_errors};
        }
        return res;
      });
  }

  createFulfillmentsFromImport(body: FulfillmentAPI.CreateShipmentFulfillmentFromImportRequest[]) {
    return this.http.post('api/shop.Shipment/CreateFulfillmentsFromImport', {
      fulfillments: body.map(item => item.fulfillment)
    }).toPromise()
      .then(res => {
        res.fulfillments = res.fulfillments.map((ffm, idx) => {
          if (!ffm) {
            ffm = body[idx].fulfillment;
          }
          ffm.import_fulfillment_id = ffm.id;
          ffm.id = body[idx].id;
          ffm.shipping_carrier_name = body[idx].shipping_carrier_name;
          const error = res.errors[idx];
          ffm.shipping_address = ffm.shipping_address?.address1;
          ffm.createErrorMsg =  error?.code != 'ok' ? error?.msg : null;
          ffm.createStatus = error?.code != 'ok' ? 'failed' : 'success';
          
          return ffm;
        });
        return res;
      });
  }

  getFulfillment(id, token) {
    return this.http
      .post('api/shop.Fulfillment/GetFulfillment', { id }, token)
      .toPromise();
  }

  getFulfillments(query: Partial<FulfillmentsQuery>) {
    return this.http
      .post('api/shop.Fulfillment/GetFulfillments', query)
      .toPromise();
  }

  getFulfillmentsByIDs(ids: Array<string>): Promise<any> {
    return this.http
      .post('api/shop.Fulfillment/GetFulfillmentsByIDs', { ids })
      .toPromise()
      .then(res => res.fulfillments);
  }

  updateFulfillmentNumberOfPrint(ids: Array<string>) {
    return this.http
      .post('api/shop.Fulfillment/UpdateFulfillmentNumberOfPrint', { id: ids })
      .toPromise();
  }

  getShipmentServices(body: FulfillmentAPI.GetShipmentServicesRequest) {
    body = FulfillmentApi.parseNumberShipmentServices(body);
    const { index } = body;
    return this.http.post("api/shop.Shipment/GetShippingServices", body)
      .toPromise()
      .then(res => {
        res.services = res.services.filter(
          s => this.util.removeDiacritic(s.name).toLowerCase() != 'tiet kiem'
        );
        res.services.forEach(s => {
          s.unique_id = s.code + '-' + s.connection_info.id;
          s.is_available = s.shipment_service_info ? s.shipment_service_info.is_available : true;
          s.error_message = s.shipment_service_info ? s.shipment_service_info.error_message : '';
          s.provider_logo = (s.connection_info && s.connection_info.image_url) ?
            s.connection_info.image_url : PROVIDER_LOGO[s.carrier]
        });
        return { ...res, index };
      });
  }

  getPublicExternalShippingServices(body) {
    const { index } = body;
    return this.http.post("api/shop.Fulfillment/GetPublicExternalShippingServices", body)
      .toPromise()
      .then(res => ({ ...res, index }));
  }

  createShipmentFulfillment(body: FulfillmentAPI.CreateShipmentFulfillmentRequest) {
    body = FulfillmentApi.parseNumberShipment(body);
    return this.http.post("api/shop.Shipment/CreateFulfillments", body)
      .toPromise()
      .then(res => res.fulfillment);
  }

  cancelShipmentFulfillment(fulfillment_id: string, cancel_reason: string) {
    return this.http.post("api/shop.Shipment/CancelFulfillment", {fulfillment_id, cancel_reason})
      .toPromise();
  }

  getShipnowFulfillments(query: Partial<FulfillmentsQuery>) {
    return this.http
      .post('api/shop.Shipnow/GetShipnowFulfillments', query)
      .toPromise();
  }

  getShipnowFulfillment(id, token?) {
    return this.http
      .post('api/shop.Shipnow/GetShipnowFulfillment', { id }, token)
      .toPromise();
  }

  getShipnowServices(body: FulfillmentAPI.GetShipnowServicesRequest) {
    body = FulfillmentApi.parseNumberShipnowServices(body);
    const { index } = body;
    return this.http.post("api/shop.Shipnow/GetShipnowServices", body)
      .toPromise()
      .then(res => {
        res.services.forEach(s => {
          s.unique_id = s.code + '-' + s.connection_info.id;
          s.is_available = true;
          s.provider_logo = (s.connection_info && s.connection_info.image_url) ?
            s.connection_info.image_url : PROVIDER_LOGO[s.carrier]
        });
        return { ...res, index };
      });
  }

  createShipnowFulfillment(body: FulfillmentAPI.CreateShipnowFulfillmentRequest) {
    body = FulfillmentApi.parseNumberShipnow(body);
    return this.http.post("api/shop.Shipnow/CreateShipnowFulfillmentV2", body).toPromise();
  }

  confirmShipnowFulfillment(shipnow_id) {
    return this.http.post("api/shop.Shipnow/ConfirmShipnowFulfillment", { id: shipnow_id }).toPromise();
  }

  cancelShipnowFulfillment(shipnow_id, cancel_reason) {
    return this.http.post("api/shop.Shipnow/CancelShipnowFulfillment", { id: shipnow_id, cancel_reason }).toPromise();
  }

  updateFulfillmentsShippingState(
    ids: Array<string>,
    shipping_state: ShippingState
  ) {
    return this.http
      .post('api/shop.Fulfillment/UpdateFulfillmentsShippingState', {
        ids,
        shipping_state
      })
      .toPromise();
  }

  requestExportFulfillmentExcel(export_filters: ExportFilterQueryDTO) {
    return new Observable(observer => {
      let sseListener = this.http
        .listenSSE(`api/event-stream?__token=${this.auth.snapshot.token}`, ['export/progress', 'export/ok', 'ping'])
        .subscribe(res => {
          if (export_filters.ids && export_filters.ids.length) {
            delete export_filters.date_from;
            delete export_filters.date_to;
          }
          if (JSON.stringify(res.data) == '{}') {
            this.http
              .post('api/shop.Export/RequestExport', {
                ...export_filters,
                export_type: 'shop/fulfillments'
              })
              .toPromise()
              .catch(err => {
                debug.log('ERROR', err);
                sseListener.unsubscribe();
                observer.error(err);
              });
          }
          if (res.event == 'export/ok') {
            sseListener.unsubscribe();
            observer.next(res.data);
            observer.complete();
          } else if (res.event == 'export/progress') {
            observer.next(res.data);
          }
        });

      return {
        unsubscribe(): void {
          sseListener.unsubscribe();
        }
      };
    });
  }

  async getFulfillmentHistory(fulfillment: Fulfillment) {
    const ffmId = fulfillment.id;
    const token = this.auth.getTokenByShop(fulfillment.shop_id);
    let ret = [];
    try {
      const res = await this.http.customPost(
        "api/shop.History/GetFulfillmentHistory",
        { paging: { limit: 100 }, id: ffmId },
        token).toPromise();
      const history = res.data.map(h => {
        const time = new Date(h.last_sync_at || h._time).getTime();
        return { ...h, time };
      }).sort((h1, h2) => h1.time - h2.time);

      for (let h of history) {
        const date = h.last_sync_at || h._time;
        let event = "";
        const currentStatus = h.shipping_state;

        event = this.util.fulfillmentShippingStateMap(currentStatus);

        if (event) {
          let isDuplicate = false;
          for (let r of ret) {
            if (r.date.substr(0, 16) == date.substr(0, 16) && r.event == event) {
              isDuplicate = true;
              break;
            }
          }

          if (isDuplicate) {
            continue;
          }
          ret.push({ date, event });
        }
      }
    } catch (e) {
      debug.error(e);
    }

    return ret;
  }

  updateFulfillmentInfo(body: Partial<FulfillmentAPI.UpdateFulfillmentInfoRequest>) {
    return this.http.post('/api/shop.Shipment/UpdateFulfillmentInfo', body)
      .toPromise();
  }

  updateFulfillmentCOD(body: Partial<FulfillmentAPI.UpdateFulfillmentCOD>) {
    return this.http.post('/api/shop.Shipment/UpdateFulfillmentCOD', body)
      .toPromise();
  }
}

export namespace FulfillmentAPI {

  export class GetShipmentServicesRequest {
    basket_value: number;
    chargeable_weight: number;
    gross_weight?: number;
    connection_ids?: string[];
    include_insurance: boolean;
    insurance_value?: number;

    coupon?: string;

    from_province_code: string;
    from_district_code: string;
    from_ward_code: string;

    to_province_code: string;
    to_district_code: string;
    to_ward_code: string;

    total_cod_amount: number;
    width?: number;
    length?: number;
    height?: number;
    index?: number;
  }

  export interface CreateShipmentFulfillmentRequest {
    order_id: string;
    pickup_address: Address;
    shipping_address: Address;
    chargeable_weight: number;
    cod_amount: number;
    shipping_service_code: string;
    shipping_service_fee: number;
    shipping_service_name: string;
    connection_id: string;
    try_on: string;
    coupon?: string,
    insurance_value?: number;
    shipping_note: string;
    include_insurance: boolean;
    shipping_type?: 'manual' | 'shipment' | 'shipnow';
    height?: number;
    length?: number;
    width?: number;
    shipping_payment_type? : 'seller' | 'buyer';
    return_address?: Address;
  }

  export interface CreateShipmentFulfillmentFromImportRequest {
    fulfillment: {
      basket_value: number;
      cod_amount: number;
      connection_id: string;
      ed_code: string;
      include_insurance: boolean;
      pickup_address: Address,
      product_description: string;
      shipping_address: Address,
      shipping_note: string;
      shipping_service_code: string;
      shipping_service_fee: number;
      shipping_service_name: string;
      total_weight: number;
      try_on: string;
    };

    id: string;
    shipping_carrier_name: string;
  }

  export interface GetShipnowServicesRequest {
    order_ids?: string[];
    connection_ids?: string[];
    coupon?: string;
    pickup_address: Address;
    delivery_points: ShipnowDeliveryPoint[];

    index?: number;
  }

  export interface CreateShipnowFulfillmentRequest {
    carrier: string;
    connection_id: string;
    coupon?: string;
    delivery_points: ShipnowDeliveryPoint[],
    pickup_address: Address,
    shipping_note: string;
    shipping_service_code: string;
    shipping_service_fee: number;
  }

  export interface UpdateFulfillmentInfoRequest {
    fulfillment_id: string;
    include_insurance?: boolean;
    insurance_value?: number;
    pickup_address?: OrderAddress;
    shipping_address?: OrderAddress;
    shipping_note?: string;
    try_on?: string;
    gross_weight?: number;
  }

  export interface UpdateFulfillmentCOD {
    cod_amount: number;
    fulfillment_id: string;
  }
}
