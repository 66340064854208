import { Paging } from './../../shared/components/etop-common/etop-pagination/paging.interface';
import {Injectable} from "@angular/core";
import {HttpService} from "@etop/common";

export enum ProductSubscriptionType {
  UNKNOWN = 'unknown',
  ECOMIFY = 'ecomify',
  EXTENSION = 'telecom-extension',
}

@Injectable({
  providedIn: 'root'
})

export class SubscriptionApi {
  constructor(
    private http: HttpService
  ) {}

  getSubscriptions(request: SubscriptionAPI.GetSubscriptionsRequest) {
    return this.http.post('api/shop.Subscription/GetSubscriptions', request)
      .toPromise().then(res => res.subscriptions);
  }

  getSubscription(account_id: string, id: string) {
    return this.http.post('api/shop.Subscription/GetSubscription', {account_id, id}).toPromise();
  }

  getSubscriptionProducts(type: ProductSubscriptionType) {
    return this.http.post('api/shop.Subscription/GetSubscriptionProducts', {type}).toPromise().then(res => res.subscription_products);
  }

  getSubscriptionPlans(product_id: string) {
    return this.http.post('api/shop.Subscription/GetSubscriptionPlans', {product_id}).toPromise().then(res => res.subscription_plans);
  }

}

export namespace SubscriptionAPI{

  export class GetSubscriptionsRequest{
    account_id?: string;
    filters?: Array<GetSubscriptionFilter>;
    paging?: Paging
  }

  export class GetSubscriptionFilter{
    name?: string;
    op?: string;
    value?: string;
  }
}
