import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { ListQueryDTO } from 'libs/models/CommonQuery';
import { Attribute, Product, Variant } from 'libs/models/Product';
import { ServiceCacheBase } from 'apps/core/src/interfaces/ServiceCacheBase';
import { UtilService } from 'apps/core/src/services/util.service';

export enum ProductSourceType {
  DEFAULT = 'unknown',
  CUSTOM = 'custom',
  KIOTVIET = 'kiotviet',
}

export class ProductUpdateDto {
  id: string;
  name?: string;
  description?: string;
  short_desc?: string;
  desc_html?: string;
  code?: string;
}

export class VariantUpdateDto {
  id: string;
  name?: string;
  description?: string;
  short_desc?: string;
  desc_html?: string;
  note?: string;
  retail_price?: number;
  list_price?: number;
  inventory?: number;
  attributes?: Array<any>;
  code?: string;
}

export class ProductTagsUpdateDto {
  ids: string[];
  adds?: string[];
  deletes?: string[];
  replace_all?: string[];
  delete_all?: boolean;
}

@Injectable({ providedIn: 'root' })
export class ProductApi extends ServiceCacheBase {
  constructor(
    private http: HttpService,
    private util: UtilService
  ) {
    super();
  }


  variantMap(
    variant,
    variantDefault = { image: 'assets/images/placeholder_medium.png' },
    productName?: string
  ): Variant {
    const hasImage = variant.image_urls[0] && variant.image_urls[0].length > 0;
    return {
      ...variant,
      price: variant.retail_price,
      cost_price: variant.inventory_variant && variant.inventory_variant.cost_price || 0,
      bg_image: `url(${(hasImage && variant.image_urls[0]) || variantDefault.image || 'assets/images/placeholder_medium.png'})`,
      image: (hasImage && variant.image_urls[0]) || variantDefault.image || 'assets/images/placeholder_medium.png',
      product_name: productName,
      p_data: {
        code: variant.id,
        image_url: Array.isArray(variant.image_urls) ? variant.image_urls[0] : variant.image_urls,
        search_product_name: productName && this.util.makeSearchText(productName)
      }
    };
  }


  productMap(p): Product {
    p.variants = p.variants.reverse();
    let v = p.variants[0];
    const hasImage = p.image_urls && p.image_urls.length > 0 && p.image_urls[0] && p.image_urls[0].length > 0;
    return {
      ...p,
      total_quantity: p.variants.reduce((acc, cur) => {
        return (
          acc +
          ((cur.inventory_variant &&
            Number(cur.inventory_variant.quantity)) ||
            0)
        );
      }, 0),
      price: v ? v.retail_price : p.retail_price,
      bg_image: `url(${(hasImage && p.image_urls[0]) ||
      'assets/images/placeholder_medium.png'})`,
      image:
        (hasImage && p.image_urls[0]) ||
        'assets/images/placeholder_medium.png',
      variants: p.variants.map(variant => {
        return this.variantMap(
          variant,
          { image: p.image_urls[0] },
          p.name
        )
      })
    };
  }

  createProduct(data: Partial<Product>, token?): Promise<Product> {
    return this.http.post('api/shop.Product/CreateProduct', data, token)
      .toPromise();
  }

  importProductFromExcel(form: FormData) {
    return this.http.postForm('api/shop.Import/Products', form).toPromise();
  }

  createVariant(data: Partial<Variant>, token?): Promise<Variant> {
    data.retail_price = Number(data.retail_price);
    data.list_price = Number(data.list_price);
    return this.http.post('api/shop.Product/CreateVariant', data, token)
      .toPromise()
      .then(variant => this.variantMap(variant));
  }

  getProduct(id: string, token?): Promise<Product> {
    return this.http.post('api/shop.Product/GetProduct', { id }, token).toPromise()
      .then(res => this.productMap(res));
  }

  getProducts(query: Partial<ListQueryDTO>, token?: string): Promise<{ paging: any; products: Array<Product> }> {
    return this.http.post('api/shop.Product/GetProducts', query, token).toPromise()
      .then(res => {
        res.products = res.products.map(product => this.productMap(product));
        return res;
      });
  }

  getProductsByIDs(ids: Array<string>, token?) {
    return this.http.post('api/shop.Product/GetProductsByIDs', { ids }, token).toPromise()
      .then(res => {
        res.products = res.products.map(product => this.productMap(product));
        return res;
      });
  }

  removeProducts(ids: Array<string>, token?) {
    return this.http.post('api/shop.Product/RemoveProducts', { ids }, token).toPromise();
  }

  removeVariants(ids: Array<string>, token?) {
    return this.http.post('api/shop.Product/RemoveVariants', { ids }, token).toPromise();
  }

  updateProduct(data: ProductUpdateDto, token?) {
    return this.http.post('api/shop.Product/UpdateProduct', data, token).toPromise()
      .then(res => this.productMap(res));
  }

  updateProductCategory(data, token?) {
    return this.http.post('api/shop.Product/UpdateProductCategory', data, token).toPromise()
  }

  removeProductCategory(id: string) {
    return this.http.post('api/shop.Product/RemoveProductCategory', { id }).toPromise()
  }

  updateProductImages(data) {
    return this.http.post('api/shop.Product/UpdateProductImages', data).toPromise()
      .then(res => this.productMap(res));
  }

  updateProductsTags(data: ProductTagsUpdateDto) {
    return this.http.post('api/shop.Product/UpdateProductsTags', data).toPromise();
  }

  updateVariant(data: VariantUpdateDto, token?) {
    data.retail_price = Number(data.retail_price);
    data.list_price = Number(data.list_price);
    return this.http.post('api/shop.Product/UpdateVariant', data, token).toPromise()
      .then(res => this.variantMap(res));
  }

  removeProductCollection(product_id: string, collection_ids: string[]) {
    return this.http.post('api/shop.Product/RemoveProductCollection', {
      product_id,
      collection_ids
    }).toPromise();
  }

  addProductCollection(product_id: string, collection_ids: string[]) {
    return this.http.post('api/shop.Product/AddProductCollection', {
      product_id,
      collection_ids
    }).toPromise();
  }

  getVariantsByIDs(ids: Array<string>, token?) {
    return this.http.post('api/shop.Product/GetVariantsByIDs', { ids }, token).toPromise()
      .then(res => {
        res.variants = res.variants.map(variant => this.variantMap(variant));
        return res;
      });
  }

  updateVariantAttributes(data: {attributes: Attribute[], variant_id: string}, token?) {
    return this.http.post('api/shop.Product/UpdateVariantAttributes', data, token).toPromise()
      .then(res => {
        res.variants = res.variants.map(variant => this.variantMap(variant));
        return res;
      });
  }
}
