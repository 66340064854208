import { NgModule } from '@angular/core';
import { ModalController } from './modal-controller.service';
import { ModalContainerComponent } from './modal-container.component';
import { ModalComponent } from 'apps/core/src/components/modal-controller/modal.component';
import { CommonModule } from '@angular/common';
import { ModalAction } from 'apps/core/src/components/modal-controller/modal-action.service';
import { DialogComponent } from './dialog.component';
import { DialogControllerService } from './dialog-controller.service';

@NgModule({
    imports: [CommonModule],
    declarations: [ModalContainerComponent, ModalComponent, DialogComponent],
    providers: [ModalController, ModalAction, DialogControllerService],
    exports: [ModalContainerComponent]
})
export class ModalControllerModule {
}
