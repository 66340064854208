<div class="wrapper-login">
  <div class="login-body">
    <div class="r-side">
      <div class="d-flex d-sm-none m-4">
        <img class="logo" src="{{getAssets()?.logo}}" alt="logo">
      </div>
      <!-- VIEW LOGIN   -->
      <ng-container *ngIf="currentView(view.LOGIN)">
        <div class="d-flex flex-column h-100">
          <div class="login-form px-5">
            <div class="title">
              <strong class="text-larger text-gray">Đăng nhập</strong>
            </div>
            <div class="info">
              <p class="text-big text-gray"> Vui lòng nhập <b>số điện thoại</b> và <b>mật khẩu</b> để đăng nhập. </p>
            </div>
            <div>
              <div class="form-group">
                <input type="text" class="form-control rounded-pill" autofocus placeholder="Nhập số điện thoại"
                  [(ngModel)]="phone" [readonly]="!!invitation_token" [class.cursor-not-allowed]="!!invitation_token">
              </div>
              <div class="form-group position-relative">
                <div *ngIf="password" class="position-absolute changephone-text" (mousedown)="showPassword()"
                  (mouseup)="hidePassword()"> Hiển thị </div>
                <input #passwordInput type="password" class="form-control rounded-pill" placeholder="Nhập mật khẩu"
                  [(ngModel)]="password" />
              </div>
              <button class="btn rounded-pill" type="button" (click)="onLogin()">
                <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i> Đăng nhập </button>
              <div class="p-2 pt-3 text-white forgot d-inline-block" style="cursor: pointer" (click)="forgotPassword()">
                Quên mật khẩu?</div>
            </div>
          </div>
          <div class="action-form border-top px-4 text-white w-100 d-flex align-items-center justify-content-center" *ngIf="allowRegister()">
            <span>Bạn chưa có tài khoản?</span>
            <button class="btn small-btn rounded-pill ml-3" (click)="toView(view.PHONE_SUBMIT)"> Đăng ký </button>
          </div>
        </div>
      </ng-container>
      <!-- VIEW REGISTER -->
      <ng-container *ngIf="currentView(view.REGISTER)">
        <div class="d-flex flex-column h-100">
          <div class="register-form px-5">
            <div class="title">
              <strong class="text-larger text-gray">Tạo tài khoản</strong>
            </div>
            <div class="info">
              <p class="text-big text-gray"> Vui lòng sử dụng thông tin chính xác để có được trải nghiệm tốt nhất trong
                quá trình sử dụng. </p>
            </div>
            <form>
              <div class="form-group position-relative">
                <div class="position-absolute text-white changephone-text" (click)="toView(view.PHONE_SUBMIT)"> Thay đổi
                </div>
                <input type="text" class="form-control rounded-pill" name="phone" readonly [(ngModel)]="phone">
              </div>
              <div class="form-group">
                <input type="text" class="form-control rounded-pill" autofocus placeholder="Nhập họ tên" name="fullname"
                  [(ngModel)]="signupData.full_name">
              </div>
              <div class="form-group position-relative">
                <input type="email" class="form-control rounded-pill" [(ngModel)]="signupData.email"
                  placeholder="Nhập email" name="email" [class.cursor-not-allowed]="!!invitation_token"
                  [readonly]="!!invitation_token">
              </div>
              <div class="form-group position-relative" *ngIf="!invitation">
                <input type="text" name="shop" placeholder="Tên công ty" [(ngModel)]="shop_name"
                  class="form-control rounded-pill">
              </div>
              <div class="form-group position-relative">
                <div class="position-absolute changephone-text" (mousedown)="showPassword()" (mouseup)="hidePassword()"
                  *ngIf="password"> Hiển thị </div>
                <input type="password" class="form-control rounded-pill" placeholder="Nhập mật khẩu" name="password"
                  [(ngModel)]="signupData.password">
              </div>
              <div class="form-group position-relative">
                <input type="password" class="form-control rounded-pill" placeholder="Nhập lại mật khẩu" name="confirm"
                  [(ngModel)]="signupData.confirm">
              </div>
              <div class="form-group text-white">
                <div class="py-3">
                  <mat-checkbox name="agree_email_info" class="mb-2" color="white"
                    [(ngModel)]="signupData.agree_email_info"> Nhận email liên hệ từ đội ngũ eTop </mat-checkbox>
                </div>
                <div class="pb-3">
                  <mat-checkbox [(ngModel)]="signupData.agree_tos" name="agree_tos" class="mb-2" color="white"> Bằng
                    việc đăng ký tôi đồng ý với <a class="provision text-white font-weight-bold" target="_blank"> điều
                      khoản sử dụng dịch vụ </a>
                  </mat-checkbox>
                </div>
                <button class="btn btn-register rounded-pill" type="submit" [disabled]="loading" (click)="signUp()">
                  <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i> Tạo tài khoản </button>
              </div>
            </form>
          </div>
        </div>
      </ng-container>
      <!-- VIEW CREATE SHOP -->
      <ng-container *ngIf="currentView(view.CREATE_SHOP)">
        <div class="d-flex flex-column h-100">
          <div class="register-form px-5">
            <div class="title">
              <strong class="text-larger text-gray">Xin chào, {{userName}}</strong>
            </div>
            <div class="info">
              <p class="text-big text-gray"> Vui lòng tạo công ty để bắt đầu sử dụng. </p>
            </div>
            <form (submit)="createShop()">
              <div class="form-group">
                <input type="text" class="form-control rounded-pill" autofocus placeholder="Nhập tên công ty"
                  name="fullname" [(ngModel)]="signupData.shop_name">
              </div>
              <div class="form-group text-white">
                <button class="btn btn-register rounded-pill" type="submit" [disabled]="loading">
                  <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i> Tiếp tục </button>
              </div>
            </form>
          </div>
        </div>
      </ng-container>
      <!-- VIEW PHONE SUBMIT -->
      <ng-container *ngIf="currentView(view.PHONE_SUBMIT)">
        <div class="d-flex flex-column h-100">
          <div class="register-form px-5">
            <div class="title">
              <strong class="text-larger text-gray">Xin chào,</strong>
            </div>
            <div class="info">
              <p class="text-big text-gray"> Vui lòng nhập số điện thoại để bắt đầu trải nghiệm các tiện ích MIỄN PHÍ!
              </p>
            </div>
            <div>
              <div class="form-group">
                <input type="tel" class="form-control rounded-pill" placeholder="Nhập số điện thoại" name="phone"
                  [(ngModel)]="signupData.phone" autofocus>
              </div>
              <button class="btn rounded-pill" [disabled]="loading" (click)="onPhoneSubmit()">
                <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i> Tiếp tục </button>
            </div>
          </div>
          <div class="">
            <div class=" action-form border-top px-5 text-white w-100 d-flex align-items-center justify-content-center">
              <span>Bạn đã có tài khoản?</span>
              <button class="btn small-btn rounded-pill ml-3" (click)="toView(view.LOGIN)"> Đăng nhập </button>
            </div>
          </div>
        </div>
      </ng-container>
      <!-- VIEW PHONE VERIFY -->
      <ng-container *ngIf="currentView(view.PHONE_VERIFY)">
        <div class="d-flex flex-column h-100">
          <div class="register-form px-5">
            <div class="title">
              <strong class="text-larger text-gray">Xác nhận số điện thoại</strong>
            </div>
            <div class="info">
              <p class="text-big text-gray"> Một mã xác nhận đã được gửi đến số <strong
                  class="font-13">{{phone}}</strong>, vui lòng kiểm tra tin nhắn và nhập mã vào ô bên dưới. </p>
            </div>
            <div>
              <div class="form-group position-relative">
                <div class="position-absolute text-white changephone-text" (click)="toView(view.PHONE_SUBMIT)"> Thay đổi
                </div>
                <input class="form-control rounded-pill" [(ngModel)]="phone" readonly>
              </div>
              <div class="form-group">
                <input class="form-control rounded-pill" placeholder="Nhập mã xác nhận" name="verify_code" autofocus
                  [(ngModel)]="verify_code">
              </div>
              <button class="btn btn-register rounded-pill" [disabled]="loading || !verify_code"
                (click)="onPhoneVerify()">
                <i class="fa fa-spinner fa-spin mr-2" *ngIf="loading"></i> Xác nhận </button>
              <div class="resend-code text-white pt-3 px-2">
                <span>Bạn không nhận được mã? </span>
                <span style="opacity: 0.5" *ngIf="countdown > 0"><strong>Gửi lại </strong>(vui lòng chờ trong
                  {{countdown}} giây)</span>
                <strong class="text-white" style="cursor: pointer" *ngIf="countdown == 0"
                  (click)="reSendverifyphone()">Gửi lại</strong>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>