import {Injectable} from '@angular/core';
import {HttpService} from '@etop/common';
import {UserSource} from "libs/models";

@Injectable()
export class UserApi {

  constructor(private http: HttpService) {
  }

  fakeCallToJiraApi() {
    return this.http.post('https://e-b2b.atlassian.net/rest/api/2/issue', {
      fields: {
        project: {
          id: "10009"
        },
        issuetype: {
          name: "Task"
        },
        summary: "REST ye merry gentlemen.",
        description: "Order entry fails when selecting supplier."
      }
    }).toPromise();
  }

  updateUserEmail(data: UserAPI.UpdateUserEmailRequest) {
    return this.http.post('api/etop.User/UpdateUserEmail', data).toPromise();
  }

  updateUserPhone(data: UserAPI.UpdateUserPhoneRequest) {
    return this.http.post('api/etop.User/UpdateUserPhone', data).toPromise();
  }

  changePassword(data: UserAPI.ChangePasswordRequest) {
    return this.http.post('api/etop.User/ChangePassword', data).toPromise();
  }

  changePasswordUsingToken(data: UserAPI.ChangePasswordUsingTokenRequest) {
    return this.http.post('api/etop.User/ChangePasswordUsingToken', data).toPromise();
  }

  login(data: UserAPI.LoginRequest) {
    return this.http.post('/api/etop.User/Login', data).toPromise();
  }

  logout() {
    return this.http.post('/api/etop.User/Logout', {}).toPromise();
  }

  register(data: UserAPI.RegisterRequest) {
    return this.http.post('api/etop.User/Register', data).toPromise();
  }

  registerUsingToken(data: UserAPI.RegisterRequest) {
    return this.http.post('api/etop.User/RegisterUsingToken', data).toPromise();
  }

  requestResetPassword(data) {
    return this.http.post('api/etop.User/ResetPassword', data).toPromise();
  }

  requestResetPasswordByPhone(data) {
    return this.http.post('api/etop.User/ResetPassword', data).toPromise();
  }

  sendEmailVerification(email) {
    return this.http.post('api/etop.User/SendEmailVerification', {email}).toPromise();
  }

  sendPhoneVerification(data: { phone: string, recaptcha_token: any }) {
    return this.http.post('api/etop.User/SendPhoneVerification', data).toPromise();
  }

  checkUserRegistration(data: { phone: string, recaptcha_token?: string }) {
    return this.http.post('api/etop.User/CheckUserRegistration', data).toPromise();
  }

  sendSTokenEmail(data: { email: string; account_id: string }) {
    return this.http.post('api/etop.User/SendSTokenEmail', data).toPromise();
  }

  sendEmailVerificationUsingOTP(email) {
    return this.http.post('api/etop.User/SendEmailVerificationUsingOTP', {email}).toPromise();
  }

  changeRefAff(ref_aff: string) {
    return this.http.post('api/etop.User/ChangeRefAff', {ref_aff}).toPromise();
  }

  verifyEmailUsingOTP(verification_token) {
    return this.http.post('api/etop.User/VerifyEmailUsingOTP', {verification_token}).toPromise();
  }

  sessionInfo(token?: string) {
    return this.http.post('api/etop.User/SessionInfo', {}, token).toPromise();
  }

  switchAccount(account_id: string) {
    return this.http
      .post(`api/etop.User/SwitchAccount`, {account_id})
      .toPromise();
  }

  updatePermission(data: UserAPI.UpdatePermissionRequest, token?) {
    return this.http.post(`api/etop.User/UpdatePermission`, data, token).toPromise();
  }

  upgradeAccessToken(stoken) {
    return this.http.post("api/etop.User/UpgradeAccessToken", {stoken}).toPromise();
  }

  verifyEmailUsingToken(token) {
    return this.http.post('api/etop.User/VerifyEmailUsingToken', {verification_token: token}).toPromise();
  }

  verifyPhoneUsingToken(token) {
    return this.http.post('api/etop.User/VerifyPhoneUsingToken', {verification_token: token}).toPromise();
  }

  verifyPhoneResetPasswordUsingToken(token) {
    return this.http.post('api/etop.User/VerifyPhoneResetPasswordUsingToken', {verification_token: token}).toPromise();
  }

  initSession() {
    return this.http.post('/api/etop.User/InitSession', {}).toPromise();
  }

  requestRegisterSimplify(phone: string) {
    return this.http.post('/api/etop.User/RequestRegisterSimplify', {phone}).toPromise();
  }

  registerSimplify(phone: string, otp: string) {
    return this.http.post('/api/etop.User/RegisterSimplify', {phone, otp}).toPromise();
  }

}

export namespace UserAPI {
  export interface UpdateUserEmailRequest {
    authentication_method: string,
    email?: string,
    first_code?: string,
    second_code?: string
  }

  export interface UpdateUserPhoneRequest {
    authentication_method: string,
    phone?: string,
    first_code?: string,
    second_code?: string
  }

  export interface ChangePasswordRequest {
    current_password: string;
    new_password: string;
    confirm_password: string;
  }

  export interface ChangePasswordUsingTokenRequest {
    email?: string;
    phone?: string;
    reset_password_token: string;
    new_password: string;
    confirm_password: string;
  }

  export interface LoginRequest {
    login: string;
    password: string;
    account_id?: string;
    account_type?: 'unknown' | 'partner' | 'shop' | 'affiliate' | 'etop';
  }

  export interface RegisterRequest {
    full_name: string;
    short_name?: string;
    phone: string;
    email: string;
    password: string;
    agree_tos: boolean
    agree_email_info: boolean;
    register_token?: string
    source?: UserSource;
  }

  export interface UpdatePermissionRequest {
    items: Array<{
      type?: string;
      key?: string;
      grants: string[];
      revokes: string[];
      replace_all: string[];
      revoke_all: boolean;
    }>
  }

}

