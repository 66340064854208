import { Injectable } from '@angular/core';
import {HttpService} from "@etop/common";

@Injectable({
  providedIn: "root"
})
export class LocationApi {

  constructor(
    private http: HttpService
  ) { }

  listProvinces() {
    return this.http.post('/api/etop.Location/GetProvinces', {}).toPromise();
  }

  listDistricts() {
    return this.http.post('/api/etop.Location/GetDistricts', {}).toPromise();
  }

  listWards() {
    return this.http.post('/api/etop.Location/GetWards', {}).toPromise();
  }

}
