import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
const MODULES = [TranslateModule];

@NgModule({
  imports: [...MODULES],
  declarations: [],
  exports: [...MODULES]
})
export class UISharedModule {}
