import { BaseModel } from '../core/base/base-model';

export declare type TransactionStatus = 'Z' | 'P' | 'N';
export declare type TransactionPaymentStatus = 'Z' | 'P' | 'N';

export enum TransactionType {
  credit = 'credit',
  invoice = 'invoice',
  default = 'default',
}
export enum TransactionRefType {
  credit = 'credit',
  invoice = 'invoice',
  default = 'default',
}
export const TRANSACTION_STATUS = {
  Z: 'Chưa xác nhận',
  P: 'Đã xác nhận',
  N: 'Hủy',
};

export const TRANSACTION_TYPE = {
  invoice: 'Hóa đơn',
  credit: 'Phiếu nạp tiền',
};

export class Transaction extends BaseModel {
  account_id: string;
  amount: number;
  classify: string;
  created_at: string;
  id: string;
  note: string;
  name: string;
  payment_id: string;
  payment_status: TransactionPaymentStatus;
  payment_status_display: string;
  status: TransactionStatus;
  status_display: string;
  referral_ids: string[];
  referral_type: TransactionRefType;
  type: TransactionType;
  type_display: string;
  updated_at: string;

  static transactionMap(transaction: Transaction): Transaction {
    transaction = new Transaction(transaction);
    transaction.status_display = TRANSACTION_STATUS[transaction.status];
    switch (transaction.type) {
      case TransactionType.credit:
        transaction.type_display = TRANSACTION_TYPE.credit;
      break;
      case TransactionType.invoice:
        transaction.type_display = TRANSACTION_TYPE.invoice;
      break;
      default:
        transaction.type_display =  'Khác';
    }
    return transaction;
  }
}
