import { Injectable } from '@angular/core';
declare const fbq: Function;
declare const ga: Function;
@Injectable({
  providedIn: 'root'
})
export class UserBehaviourTrackingService {
  constructor() {}

  sendUserBehaviour(action: string) {
    if(typeof fbq !== 'undefined') {
      fbq('track', action);
    }
    if(typeof ga !== 'undefined') {
      ga('send', 'event', 'User', action, 'User Classification');
    }
  }
}
