import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreadcrumbMenu } from '../header/components/breadcrumb/breadcrumb.component';
import { MenuItem } from '../menu-item/menu-item.interface';
import { ConfigService } from '@etop/core';

@Component({
  selector: 'core-new-sidebar',
  templateUrl: './new-sidebar.component.html',
  styleUrls: ['./new-sidebar.component.scss']
})
export class NewSidebarComponent implements OnInit {
  @Input() menus: Array<MenuItem> = [];

  currentItem: BreadcrumbMenu = {name: '', name_custom: ''};
  isCollapse = true;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private ref: ChangeDetectorRef,
    private config: ConfigService, 
  ) { }

  @HostListener("window:resize", [])
  public onResize() {
    const width = window.innerWidth;
    if (width < 1366) {
      this.isCollapse = false
    }
  }

  ngOnInit() {
    const width = window.innerWidth;
    if (width < 1366) {
      this.isCollapse = false
    }
    this.updateActiveItem();
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        this.updateActiveItem();
      }
    });
  }

  getAssets() {
    return this.config.get('assets')
  }

  updateActiveItem() {
    let activeMenuItem = this.menus.find(menu =>
      this.router.isActive(this.router.createUrlTree(menu.route, {relativeTo: this.activatedRoute}), false)
    );
    let activeSubMenuItem = activeMenuItem && activeMenuItem.submenus && activeMenuItem.submenus.length
      && activeMenuItem.submenus.find(
        submenu => this.router.isActive(
          this.router.createUrlTree(activeMenuItem.route.concat(submenu.route),
            {relativeTo: this.activatedRoute}),
          false
        )
      );

    this.currentItem = (activeSubMenuItem && {
      name: activeSubMenuItem.name,
      name_custom: ''
    }) || (activeMenuItem && {name: activeMenuItem.name, name_custom: activeMenuItem.name_custom}) || {
      name: '',
      name_custom: ''
    };
    this.ref.markForCheck();
  }

  toggleSidebar() {
    this.isCollapse = !this.isCollapse;
  }

}
