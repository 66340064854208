import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { AuthenticateStore } from '@etop/core';
import { CallLog, CursorPaging } from '@etop/models';
import {Observable} from "rxjs";
import {ExportFilterQueryDTO} from "@etop/api";

@Injectable({
  providedIn: 'root'
})
export class CallLogApi {
  constructor(
    private http: HttpService,
    private auth: AuthenticateStore
  ) {}

  async getCallLogs(GetCallLogsRequest?: CallLogAPI.GetCallLogsRequest) {
    return await this.http.post('api/shop.Etelecom/GetCallLogs', GetCallLogsRequest).toPromise()
      .then(res => {
        return {
          paging: res.paging,
          call_logs: res.call_logs.map(callLog => CallLog.callLogMap(callLog))
        };
      });
  }

  async getExtensions(hotline_id?: string) {
    return await this.http.post('api/shop.Etelecom/GetExtensions', {hotline_id}).toPromise().then(res => res.extensions);
  }

  requestExportCallLogExcel(export_filters: ExportFilterQueryDTO) {
    if(export_filters.delimiter == '\\t') {
      export_filters.delimiter = '\t';
    }
    return new Observable(observer => {
      let sseListener = this.http
        .listenSSE(`api/event-stream?__token=${this.auth.snapshot.token}`, ['export/progress', 'export/ok', 'ping'])
        .subscribe(res => {
          if (export_filters.ids && export_filters.ids.length) {
            delete export_filters.date_from;
            delete export_filters.date_to;
          }
          if (JSON.stringify(res.data) == '{}') {
            this.http
              .post('api/shop.Export/RequestExport', {
                ...export_filters,
                export_type: 'shop/calllogs'
              })
              .toPromise()
              .catch(err => {
                debug.log('ERROR', err);
                sseListener.unsubscribe();
                observer.error(err);
              });
          }
          if (res.event == 'export/ok') {
            sseListener.unsubscribe();
            observer.next(res.data);
            observer.complete();
          } else if (res.event == 'export/progress') {
            observer.next(res.data);
          }
        });

      return {
        unsubscribe(): void {
          sseListener.unsubscribe();
        }
      };
    });
  }


}

export namespace CallLogAPI {
  export interface GetCallLogsFilter {
    extension_ids?: string[];
    hotline_ids?: string[];
    call_number?: string;
    date_from?: Date;
    date_to?: Date;
    user_id?: string;
    direction?: string,
    department_id?: string;
    duration?: {
      comparison?: string,
      value: number;  
    };
  }

  export interface GetCallLogsRequest {
    filter?: GetCallLogsFilter;
    paging?: CursorPaging
  }
}

