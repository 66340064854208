import { Injectable } from '@angular/core';
import { FulfillmentApi } from '@etop/api';
import { ShipmentCarrier } from '@etop/models';
import {UtilService} from "apps/core/src/services/util.service";

@Injectable()
export class FulfillmentService {

  constructor(
    private util: UtilService,
    private fulfillmentApi: FulfillmentApi,
  ) { }

  groupShipmentServicesByConnection(shipmentServices: any[], shopConnectionIds?: any[]) {
    shipmentServices = shipmentServices.filter(
      service => !service.connection_info.name.toLowerCase().includes('topship')
    );
    const hash = {};

    for (let service of shipmentServices) {
      service.selected = false;
      if (hash[service.connection_info.id]) {
        hash[service.connection_info.id].services.push(service);
      } else {
        hash[service.connection_info.id] = {
          carrier_name: service.carrier,
          name: service.connection_info.name,
          id: service.connection_info.id,
          logo: `assets/images/provider_logos/${service.carrier}-s.png`,
          services: [service]
        };
      }
    }
    const carriers = shopConnectionIds || [];

    let results = [];
    // NOTE: Group
    for (let key in hash) {
      const id = hash[key].id;
      if (carriers.includes(id)) {
        results.push(hash[key]);
      }
    }

    // NOTE: Sort
    for (let carrier of results) {
      carrier.services = carrier.services.sort((s1, s2) => {
        const fee1 = s1.fee;
        const fee2 = s2.fee;
        return fee1 - fee2;
      });
    }
    return results;
  }

}
