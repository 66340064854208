import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { EnvConfig } from '@etop/models/EnvConfig';

export const CONFIG_TOKEN = '$_config_token';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private appConfig: any;

  constructor(@Optional() @Inject(CONFIG_TOKEN) private url: any, http: HttpClient) {
    http.get(url)
      .toPromise()
      .then(config => {
        this.appConfig = config;
      });
  }

  getConfig(): EnvConfig {
    return this.appConfig;
  }

  get(name: string) {
    return this.appConfig && this.appConfig[name];
  }
}
