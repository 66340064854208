import {Injectable} from '@angular/core';
import {HttpService} from '@etop/common';

@Injectable({providedIn: "root"})
export class NotifySettingApi {

  constructor(private http: HttpService) {
  }

  getNotifySetting() {
    return this.http
      .post('/api/etop.User/GetNotifySetting', {})
      .toPromise()
      .then(res => res.topics);
  }

  enableNotifyTopic(topic) {
    return this.http.post('api/etop.User/EnableNotifyTopic', {topic}).toPromise()
      .then(res => res.topics);
  }

  disableNotifyTopic(topic) {
    return this.http.post('api/etop.User/DisableNotifyTopic', {topic}).toPromise()
      .then(res => res.topics);
  }
}
