import { EmptyPageComponent } from './empty-page.component';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';



@NgModule({
  declarations: [EmptyPageComponent],
  imports: [CommonModule],
  exports: [EmptyPageComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class EmptyPageModule { }
