import {Connection, ShopConnection} from "libs/models/Connection";
import {Injectable} from "@angular/core";
import {AStore} from "apps/core/src/interfaces/AStore";

export interface ConnectionData {
  initAdminConnections: Connection[];
  initConnections: Connection[];
  builtinConnections: Connection[];
  validConnections: Connection[];

  availableConnections: Connection[];
  loggedInConnections: ShopConnection[];

  loadingConnections: boolean;
}

@Injectable({
  providedIn: "root"
})
export class ConnectionStore extends AStore<ConnectionData> {
  initState = {
    initAdminConnections: [],
    initConnections: [],
    builtinConnections: [],
    validConnections: [],

    availableConnections: [],
    loggedInConnections: [],

    loadingConnections: true
  }

  constructor() {
    super();
  }

  initConnections(connections: Connection[]) {
    this.setState({initConnections: connections});
    this.setState({builtinConnections: connections.filter(conn => conn.connection_method == 'builtin')});
  }

  initAdminConnections(connections: Connection[]) {
    this.setState({initAdminConnections: connections});
  }

  setValidConnections(connections: Connection[]) {
    this.setState({validConnections: connections});
  }

  setAvailableConnections(connections: Connection[]) {
    this.setState({availableConnections: connections});
  }

  setLoggedInConnections(connections: ShopConnection[]) {
    this.setState({loggedInConnections: connections});
  }

  setLoadingConnections(loading: boolean) {
    this.setState({loadingConnections: loading});
  }

}
