import { ActiveState, EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { FbUserTag } from '@etop/models/faboshop/FbUserTag';

export interface StateFbUserTag extends EntityState<FbUserTag, string>, ActiveState {
  newFbUserTag: FbUserTag;
}

const initState = {
  newFbUserTag: null,
};

@Injectable({
  providedIn: 'root'
})
@StoreConfig({ name: 'tag', resettable: true })
export class FacebookUserTagStore extends EntityStore<StateFbUserTag>{
  constructor() {
    super(initState);
  }
}
