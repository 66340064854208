import { Injectable } from '@angular/core';
import {Connection, User} from "@etop/models";
import { StorageService } from 'apps/core/src/services/storage.service';
import { AsyncSubject } from 'rxjs';
import { ExtendedAccount } from 'libs/models/Account';
import misc from 'apps/core/src/libs/misc';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { AStore } from 'apps/core/src/interfaces/AStore';
import { Account } from 'libs/models/Account';
import { ShopSettings } from '@etop/models/ShopSettings';

export class AuthenticateDTO {
  token?: string;
  session?: any;
  user?: any;
  account?: Account;
  accounts?: Account[];
  shop?: ExtendedAccount;
  permission?: {
    roles: string[],
    permissions: string[],
  };
  isAuthenticated?: boolean;
  uptodate?: boolean;
  oneSignal?: {
    device_id: string,
    external_device_id: string
  };
  shopSetting?: ShopSettings;
}

export interface AuthenticateData {
  storageReady: boolean;
  uptodate?: boolean;
  isAuthenticated: boolean;
  permission?: {
    roles: string[],
    permissions: string[],
  };
  token: string;
  session?: any;
  user?: User;
  shop?: ExtendedAccount;
  account?: Account;
  accounts?: Account[];
  __ref?: string;
  redirectUrl?: string;
  oneSignal?: {
    device_id: string,
    external_device_id: string,
  };
  shopSetting?: ShopSettings;
  telecomConnections?: Connection[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticateStore extends AStore<AuthenticateData> {
  __storage_key = 'auth-data';

  initState: AuthenticateData = {
    token: '', storageReady: false, isAuthenticated: false, accounts: [], uptodate: false,
    permission: {
      roles: [],
      permissions: []
    }
  };

  readonly isStorageReady$ = this.state$.pipe(map(({ storageReady }) => storageReady), distinctUntilChanged());
  readonly authenticatedData$ = this.state$.pipe(filter(data => data.isAuthenticated));
  readonly isAuthenticated$ = this.state$.pipe(
    map(({ isAuthenticated }) => isAuthenticated),
    distinctUntilChanged());

  __ref = '';

  constructor(
    private storage: StorageService
  ) {
    super();
    storage.onLocalStorageUpdated.subscribe(({ data: any, loaded }) => {
      if (loaded) {
        this.load();
      }
    });

    if (storage.ready) {
      this.load();
    }
    this.state$.subscribe(() => {
      this.store();
    });
    this.state$.pipe(map(({uptodate}) => uptodate), distinctUntilChanged(), filter(uptodate => uptodate), take(1))
      .subscribe(() => {
        this._ready$.next(true);
        this._ready$.complete();
      })
  }

  private _ready$ = new AsyncSubject();

  waitForReady() {
    return this._ready$.toPromise();
  }

  updateInfo(info: AuthenticateDTO) {
    let data: any = {};
    for (let key in info) {
      data[key] = info[key];
    }
    this.setState({
      ...data
    });
  }

  updatePermissions(permission) {
    this.setState({
      permission,
    })
  }

  updateUser(user) {
    this.setState({
      user
    });
  }

  updateOneSignal(oneSignal) {
    this.setState({ oneSignal });
  }

  updateShopSetting(shopSetting: ShopSettings) {
    this.setState({ shopSetting });
  }

  updateTelecomConnections(telecomConnections: Connection[]) {
    this.setState({ telecomConnections });
  }

  updateShop(shop, force = false) {
    let account = this.snapshot.accounts.find(a => a.id == shop.id);

    if (account) {
      account.shop = shop;
      account.name = shop.name;
      account.display_name = `${shop.code} - ${shop.name}`;
      account.image_url = shop.image_url;
    }
    if ((this.snapshot.shop && this.snapshot.shop.id == shop.id) || force) {
      this.setState({
        shop,
        account
      });
    }
  }

  addAccount(account) {
    const accounts = this.snapshot.accounts;
    let existed = this.snapshot.accounts.find(a => a.id == account.id);
    if (existed) {
      return this.updateShop(account);
    }
    accounts.push(account);
    this.setState({
      accounts
    });
  }

  updateAccount(account) {
    const accounts = this.snapshot.accounts;
    const accIndex = this.findAccountIndex(account.id);

    accounts[accIndex] = account;
    this.setState({
      accounts,
      account
    });
  }

  updateAccounts(accounts: any[]) {
    this.setState({
      accounts
    });
  }

  selectAccount(index): Account {
    const accounts = this.snapshot.accounts;
    let account: any = accounts.find(a => a.url_slug == index || a.id == index);
    if (!account) {
      if (index < 0 || index >= this.snapshot.accounts.length) {
        return null;
      }
      account = accounts[index];
    }

    if (!account) {
      return null;
    }

    if (this.snapshot.account.id === account.id) {
      return account;
    }
    this.setState({
      token: account.token,
      account,
      permission: account.user_account.permission,
      shop: account.shop
    });
    return account;
  }

  updateSToken(token) {
    const shop = this.snapshot.shop;
    shop.token = token;
    this.setState({
      shop
    });
  }

  removeAccount(account_id) {
    let accounts = this.snapshot.accounts.filter(a => a.id != account_id);
    this.setState({
      accounts
    });
  }

  // TODO: move to ShopAccountService
  updateAutoFfmOption(option) {
    const shop = this.snapshot.shop;
    shop.auto_create_ffm = option;
    this.setState({
      shop
    });
  }

  // TODO: move to ShopAccountService
  updateTryOn(code) {
    const shop = this.snapshot.shop;
    shop.try_on = code;
    this.setState({
      shop
    });
  }

  currentAccountIndex() {
    return this.findAccountIndex(this.snapshot.account?.id);
  }

  getTokenByShop(shop_id) {
    const shop = this.snapshot.accounts.find(a => a.id === shop_id);
    return shop ? shop.token : null;
  }

  getStringTokenByShop(shop_id) {
    return misc.encodeBase64(this.getTokenByShop(shop_id));
  }

  setRef(url) {
    if (url == '/') {
      return;
    }
    this.__ref = url;
  }

  getRef(clear = false) {
    const ref = this.__ref;
    if (clear) {
      this.__ref = '';
    }
    return ref;
  }

  updateToken(token: string) {
    this.setState({
      token
    });
  }

  findAccountIndex(id) {
    const index = this.snapshot.accounts.findIndex(a => a.id === id);
    return index < 0 ? 0 : index;
  }

  load() {
    let authData = this.storage.get(this.__storage_key);
    this.state = {
      ...authData,
      storageReady: true
    };
  }

  setDefaultAddress(address_id, type, shop_id?) {
    let shop = this.snapshot.shop;

    if (shop_id) {
      let acc = this.snapshot.accounts.find(a => a.shop.id == shop_id);
      if (acc && acc.shop) {
        shop = acc.shop;
      }
    }

    if (type == 'shipfrom') {
      shop.ship_from_address_id = address_id;
    } else if (type == 'shipto') {
      shop.ship_to_address_id = address_id;
    }
    this.setState({
      shop
    });
  }

  store() {
    let data = this.snapshot;
    delete data.storageReady;
    delete data.uptodate;
    this.storage.set(this.__storage_key, data);
  }

  clear() {
    this.storage.clear();
    this.state = this.initState;
  }
}
