import { Injectable } from '@angular/core';
import { QueryEntity, QueryConfig, EntityUIQuery } from '@datorama/akita';
import { StateConversations, ConversationsStore, ConversationUIState } from 'libs/state/fabo/conversation/conversation.store';
import { Observable } from 'rxjs';
import { CustomerConversation } from '@etop/models/faboshop';

const sortby = (a, b) => (
  new Date(b.last_message_at).getTime() -
  new Date(a.last_message_at).getTime());

@Injectable({
  providedIn: 'root'
})
@QueryConfig({ sortBy: sortby })
export class ConversationsQuery extends QueryEntity<StateConversations, CustomerConversation> {
  ui: EntityUIQuery<ConversationUIState>;

  constructor(protected store: ConversationsStore) {
    super(store);
    this.createUIQuery();
  }

  selectLoadingMess(id: string): Observable<boolean> {
    return this.ui.selectEntity(id, entity => entity.isLoadingMess);
  }
}
