import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { ForgotPasswordModalComponent } from './modals/forgot-password-modal/forgot-password-modal.component';
import { ModalControllerModule } from 'apps/core/src/components/modal-controller/modal-controller.module';
import { FormsModule } from '@angular/forms';
import { EtopProductComponent } from './components/etop-product/etop-product.component';
import { ChangePasswordModalComponent } from '../login/modals/change-password-modal/change-password-modal.component'
import { VerifyPhoneModalComponent } from './modals/verify-phone-modal/verify-phone-modal.component';
import { EtopMaterialModule } from '@etop/shared';

@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordModalComponent,
        EtopProductComponent,
        ChangePasswordModalComponent,
        VerifyPhoneModalComponent
    ],
    imports: [CommonModule, FormsModule, ModalControllerModule, EtopMaterialModule],
    exports: [LoginComponent, EtopProductComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA]
})
export class LoginModule {}
