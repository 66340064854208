<div class="blank-page">
  <img class="blank-img" src="{{imageUrl}}" alt="empty list"/>
  <div class="wrap-content">
    <div class="blank-content">
      <div class="text-bigger text-center m-2">
        {{title}}
      </div>
    </div>
    <div class="description"> {{description}} </div>
    <div class="text-center mt-4">
          <span *ngFor="let action of actions; let firstAction = first">
            <button *ngIf="firstAction" class="btn {{action?.cssClass}}" (click)="handleClick(action)">
                <i class="fa {{action.icon}} mr-1 text-primary"></i>
                <span [innerHtml]="action?.title"></span>
            </button>
            <button *ngIf="!firstAction" class="ml-3 btn {{action?.cssClass}}" (click)="handleClick(action)">
              <i class="fa {{action.icon}} mr-1 text-primary"></i>
              <span [innerHtml]="action?.title"></span>
          </button>
          </span>
    </div>
  </div>
</div>
