import { Directive, ElementRef, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { checkMatch, Operator } from './common.internal';
import { BaseRoleDirective } from '@etop/core/modules/authenticate/base-role-directive.internal';
import { AuthenticateStore } from '@etop/core/modules/authenticate/authenticate.store.service';
import { AuthenticateService } from '@etop/core/modules/authenticate/authenticate.service';

@Directive({
  selector: '[eRoles]'
})
export class RolesDirective extends BaseRoleDirective {
  private roles: string[] = [];
  private operator: Operator = 'or';

  constructor(
    element: ElementRef,
    templateRef: TemplateRef<any>,
    viewContainer: ViewContainerRef,
    authenticateStore: AuthenticateStore,
    authenticateService: AuthenticateService
  ) {
    super(element, templateRef, viewContainer, authenticateStore, authenticateService);
  }

  @Input()
  set eRoles(roles) {
    this.roles = roles;
    this.updateView();
  }

  @Input()
  set eRolesOp(val) {
    this.operator = (val || 'or').toLowerCase();
    this.updateView();
  }

  protected check(): boolean {
    return this.authenticateService.checkRoles(this.roles, this.operator);
  }
}
