import { BaseModel } from '../core/base/base-model';

export class BankAccount {
  account_name: string;
  account_number: string;
  branch: string;
  name: string;
  province: string;
  bank_code?: string;
  province_code?: string;
  branch_code?: string;

  region?: string;
}

export class Bank extends BaseModel {
  code: string;
  name: string;
  type: string;
}

export class BankProvince extends BaseModel {
  code: string;
  name: string;
  bank_code: string;
}

export class BankBranch extends BaseModel {
  code: string;
  name: string;
  bank_code: string;
  province_code: string;
}

export class Balance{
  actual_balance: number;
  available_balance: number;
}
