import {Injectable} from "@angular/core";
import {HttpService} from "@etop/common";
import {Address, BankAccount, CompanyInfo, SurveyInfo} from "@etop/models";

@Injectable({
  providedIn: 'root'
})

export class ShopAccountApi {
  constructor(
    private http: HttpService
  ) {}

  registerShop(request: ShopAccountAPI.RegisterShopRequest) {
    return this.http
      .post('api/shop.Account/RegisterShop', request)
      .toPromise();
  }

  setDefaultAddress(id, type, token?) {
    return this.http.post(`api/shop.Account/SetDefaultAddress`, {id, type}, token)
      .toPromise();
  }

}

export namespace ShopAccountAPI {
  export interface RegisterShopRequest {
    address?: Partial<Address>,
    bank_account?: BankAccount,
    company_info?: CompanyInfo,
    email?: string;
    image_url?: string;
    money_transaction_rrule?: string;
    name: string;
    phone: string;
    shipping_service_select_strategy?: {
      key: string
      value: string;
    }[],
    survey_info?: SurveyInfo[],
    url_slug?: string;
    website_url?: string;
  }
}
