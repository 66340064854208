import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import { ShopSettings } from '@etop/models/ShopSettings';

@Injectable({
    providedIn: 'root'
  })
  
  export class ShopSettingsApi {
    constructor(
      private http: HttpService
    ) {}
  
    getSetting() {
        return this.http.post(`api/shop.Setting/GetSetting`, {})
            .toPromise();
    }
    
    updateSetting(data: ShopSettings) {
        return this.http.post(`api/shop.Setting/UpdateSetting`, data)
          .toPromise();
    }
  }