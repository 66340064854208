const _ = require('lodash');

export class Misc {
  uniqueArray(arr) {
    let hash = {};
    let result = [];
    arr.forEach(item => hash[item] = 1);
    for (let i in hash) {
      if (hash.hasOwnProperty(i) && hash[i] === 1) {
        result.push(i);
      }
    }
    return result;
  }

  encodeBase64(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function (match, p1) {
      return String.fromCharCode(parseInt('0x' + p1, 16));
    }));
  }

  decodeBase64(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
  }

  convertHour(min: number, is12?: boolean): string {
    min = min % 1440;
    const h = Math.floor(min / 60);
    const m = min % 60;
    let result = "";
    if (is12) {
      const h12 = h % 12 + (h === 12 ? 12 : 0);

      result += _.padStart(h12.toString(), 2, '0')
        + ":" + _.padStart(m.toString(), 2, '0')
        + " " + (h >= 12 ? "PM" : "AM");
    } else {
      result += _.padStart(h.toString(), 2, '0')
        + ":" + _.padStart(m.toString(), 2, '0');
    }

    return result;
  }

  buildQueryString(params: object): string {
    if (!params) {
      return '';
    }
    const keyValues = (<any>Object).entries(params);
    return keyValues.reduce((q, next) => next[1] !== undefined ? `${q}&${next[0]}=${next[1]}` : q, '');
  }

  arrayWrap(x: any): any[] {
    if (Array.isArray(x)) return x;
    return [x];
  }

  formatToConcurrency(number) {
    if (!Number.isNaN(number)) {
      number = parseInt(number);
    }

    if (!Number.isFinite(number)) {
      console.error('Invalid number', number, typeof number);
      number = 0;
    }

    return number.toFixed().replace(/./g, function (c, i, a) {
      return i && c !== "." && ((a.length - i) % 3 === 0) ? ',' + c : c;
    });
  }

  compare(value, op, target) {
    switch (op) {
      case 'contains':
        return value.indexOf(target) >= 0;
      case 'not containing':
        return value.indexOf(target) < 0;
      case '=':
        return value == target;
    }
    return true;
  }
}

const misc = new Misc();
export default misc;
