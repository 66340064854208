import { Injectable } from '@angular/core';
import misc from '../libs/misc';
import { Subject } from 'rxjs';
import { LoggerService } from './logger.service';
import { ConfigService } from '@etop/core/services/config.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  __secretCode = '';

  onLocalStorageUpdated = new Subject();
  ready = false;
  data: any = {};

  constructor(private logger: LoggerService, private configService: ConfigService) {
    this.__secretCode = misc.encodeBase64('nửa con gà');
    this.load();
  }

  get(name) {
    try {
      return this.data[name];
    } catch (e) {
      return null;
    }
  }

  set(name, value) {
    this.logger.log(name, value);
    this.data[name] = value;
    this.store();
    this._invokeUpdate();
    return true;
  }

  _invokeUpdate(loaded = false) {
    let data = this.data;
    this.onLocalStorageUpdated.next({ data, loaded });
    this.ready = loaded || this.ready;
  }

  store() {
    let data = this.data;
    let storeData = this.configService.getConfig()?.production
      ? misc.encodeBase64(JSON.stringify(data))
      : JSON.stringify(data);
    localStorage.setItem(this.__secretCode, storeData);
  }

  load() {
    try {
      let storeData = localStorage.getItem(this.__secretCode);
      if (storeData) {
        const data = this.configService.getConfig()?.production
          ? JSON.parse(misc.decodeBase64(storeData))
          : JSON.parse(storeData);
        this.data = data || {};
      }
    } catch (e) {
      this.logger.error('failed to load saved data', e);
    }
    this._invokeUpdate(true);
  }

  clear() {
    Object.keys(this.data).forEach(k => {
      this.data[k] = null;
    });
    localStorage.clear();
  }
}
