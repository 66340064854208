import { Injectable } from '@angular/core';
import { HttpService } from '@etop/common';
import {Connection, ConnectionSubType} from 'libs/models/Connection';

@Injectable({
  providedIn: 'root'
})
export class ConnectionApi {

  constructor(
    private http: HttpService
  ) {}

  getConnections(connection_subtype: ConnectionSubType = 'shipment') {
    return this.http
      .post('api/shop.Connection/GetConnections', {connection_subtype})
      .toPromise()
      .then(res => res.connections.map(conn => Connection.connectionMap(conn)));
  }

  getAvailableConnections(connection_subtype: ConnectionSubType = 'shipment') {
    return this.http
      .post('api/shop.Connection/GetAvailableConnections', {
        connection_subtype
      }).toPromise()
        .then(res => res.connections.map(conn => Connection.connectionMap(conn)));
  }

  getShopConnections() {
    return this.http
      .post('api/shop.Connection/GetShopConnections', {})
      .toPromise()
      .then(res => res.shop_connections);
  }

  loginShopConnection(body: ConnectionAPI.LoginShopConnectionRequest) {
    return this.http
      .post('api/shop.Connection/LoginShopConnection', body)
      .toPromise();
  }

  loginShopConnectionWithOTP(body: ConnectionAPI.LoginShopConnectionWithOTPRequest) {
    return this.http
      .post('api/shop.Connection/LoginShopConnectionWithOTP', body)
      .toPromise();
  }

  updateShopConnection(body: ConnectionAPI.LoginShopConnectionByTokenRequest) {
    return this.http
      .post('api/shop.Connection/UpdateShopConnection', body)
      .toPromise();
  }

  registerShopConnection(body: ConnectionAPI.RegisterShopConnectionRequest) {
    return this.http
      .post('api/shop.Connection/RegisterShopConnection', body)
      .toPromise();
  }

  deleteShopConnection(connection_id) {
    return this.http
      .post('api/shop.Connection/DeleteShopConnection', { connection_id })
      .toPromise();
  }
}
export namespace ConnectionAPI {
  export class LoginShopConnectionRequest {
    connection_id: string;
    email?: string;
    password?: string;
    identifier: string;
  }

  export class LoginShopConnectionWithOTPRequest {
    connection_id: string;
    identifier: string;
    otp?: string;
  }
  export class LoginShopConnectionByTokenRequest {
    connection_id: string;
    external_data: {
      email?: string;
      user_id: string;
    };
    user_id?: string;
    token: string;
  }
  export class RegisterShopConnectionRequest {
    connection_id: string;
    name: string;
    phone: string;
    email: string;
    password: string;
    province: string;
    district: string;
    address: string;
  }
}
