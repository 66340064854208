import {Injectable} from '@angular/core';
import {ListQueryDTO} from 'libs/models/CommonQuery';
import {HttpService} from '@etop/common';
import {Invitation, Relationship} from 'libs/models/Authorization';
import {Paging} from "@etop/shared";
import {CursorPaging, Filters} from "@etop/models";

export class InvitationCreateDto {
  roles: string[];
  email?: string;
  short_name?: string;
  phone?: string;
  full_name: string;
  position?: string;
}

const ROLE = {
  owner: 'Chủ shop',
  accountant: 'Kế toán',
  analyst: 'Phân tích',
  inventory_management: 'Quản lý kho',
  purchasing_management: 'Thu mua',
  salesman: 'Bán hàng',
  staff_management: 'Quản lý nhân viên',
  telecom_customerservice: 'Chăm sóc khách hàng'
};

const ROLEFABO = {
  owner: 'Chủ shop',
  salesman: 'Bán hàng',
  staff_management: 'Quản lý nhân viên',
}

@Injectable({
  providedIn: 'root'
})
export class AuthorizationApi {
  static get role() {
    return ROLE;
  }

  static get roleFabo() {
    return ROLEFABO;
  }

  constructor(private http: HttpService) {
  }

  static roleMap(role) {
    return AuthorizationApi.role[role];
  }

  static roleMapFabo(role) {
    return AuthorizationApi.roleFabo[role];
  }

  invitationMap(invitation: Invitation) {
    return {
      ...invitation,
      roles_display: invitation.roles && invitation.roles.map
      (r => AuthorizationApi.roleMap(r)).join(', ') || ''
    };
  }

  /* NOTE: Shop */

  getInvitationByToken(token: string): Promise<any> {
    return this.http
      .post('api/etop.UserRelationship/GetInvitationByToken', {token})
      .toPromise();
  }

  createInvitation(body: InvitationCreateDto): Promise<any> {
    return this.http
      .post('api/etop.AccountRelationship/CreateInvitation', body)
      .toPromise();
  }

  createStaff(body): Promise<any> {
    return this.http.post('api/shop.Etelecom/CreateUserAndAssignExtension', body).toPromise();
  }

  deleteInvitation(token: string): Promise<any> {
    return this.http
      .post('api/etop.AccountRelationship/DeleteInvitation', {token})
      .toPromise();
  }

  getShopInvitations(query?: Partial<ListQueryDTO>): Promise<any> {
    return this.http
      .post('api/etop.AccountRelationship/GetInvitations', query)
      .toPromise()
      .then(res => res.invitations.map(i => this.invitationMap(i)));
  }

  getRelationships(query?: AuthorizationAPI.GetRelationshipRequest): Promise<any> {
    return this.http
      .post('api/etop.AccountRelationship/GetRelationships', query)
      .toPromise()
      .then(res => res.relationships.map(r => this.relationshipMap(r)).sort(r => {
        return r.roles.indexOf('owner') != -1 && -1 || 1;
      }));
  }

  getAccountUsers(request?: AuthorizationAPI.GetAccountUserRequest): Promise<any> {
    return this.http
      .post('api/shop.AccountUser/GetAccountUsers', request)
      .toPromise().then(res => {
        return {
          paging: res.paging,
          account_users: res.account_users.map(r => this.relationshipMap(r)).sort(r => {
            return r.roles.indexOf('owner') != -1 && -1 ||  1;
          })
        };
      });
  }

  updateAccountUser(request?: AuthorizationAPI.UpdateAccountUserRequest): Promise<any> {
    return this.http
      .post('api/shop.AccountUser/UpdateAccountUser', request)
      .toPromise().then();
  }

  removeUserFromAccount(user_id: string): Promise<any> {
    return this.http
      .post('api/etop.AccountRelationship/RemoveUser', {user_id})
      .toPromise();
  }

  updatePermission(roles: string[], user_id: string): Promise<any> {
    return this.http
      .post('api/etop.AccountRelationship/UpdatePermission', {roles, user_id})
      .toPromise();
  }

  updateRelationship(full_name, user_id: string): Promise<any> {
    return this.http
      .post('api/etop.AccountRelationship/UpdateRelationship', {full_name, user_id})
      .toPromise();
  }

  /* NOTE: User */

  getUserInvitations(query?: Partial<ListQueryDTO>): Promise<any> {
    return this.http
      .post('api/etop.UserRelationship/GetInvitations', query)
      .toPromise()
      .then(res => res.invitations.map(i => this.invitationMap(i)));
  }

  acceptInvitation(token: string) {
    return this.http
      .post('api/etop.UserRelationship/AcceptInvitation', {token})
      .toPromise();
  }

  rejectInvitation(token: string) {
    return this.http
      .post('api/etop.UserRelationship/RejectInvitation', {token})
      .toPromise();
  }

  invitationMapFabo(invitation: Invitation) {
    return {
      ...invitation,
      roles_display: invitation.roles && invitation.roles.map
      (r => AuthorizationApi.roleMapFabo(r)).join(', ') || ''
    };
  }

  relationshipMap(relationship: Relationship) {
    return {
      ...relationship,
      roles_display: relationship.roles && relationship.roles.map
      (r => AuthorizationApi.roleMap(r)).join(', ') || ''
    };
  }

  relationshipMapFabo(relationship: Relationship) {
    return {
      ...relationship,
      roles_display: relationship.roles && relationship.roles.map
      (r => AuthorizationApi.roleMapFabo(r)).join(', ') || ''
    };
  }


  resendInvitation(request: AuthorizationAPI.ResendInvitationRequest) {
    return this.http
      .post('api/etop.AccountRelationship/ResendInvitation', request)
      .toPromise();
  }

  createAccountUser(request: AuthorizationAPI.CreateAccountUserRequest) {
    return this.http.post('api/shop.AccountUser/CreateAccountUser', request).toPromise();
  }

  createExternalAccountHaravan(request: AuthorizationAPI.CreateExternalAccountHaravanRequest) {
    return this.http.post('api/shop.ExternalAccount/CreateExternalAccountHaravan', request).toPromise();
  }

}

export namespace AuthorizationAPI {
  export interface ResendInvitationRequest {
    email: string;
    phone: string
  }

  export interface GetRelationshipRequest {
    filter?: RelationshipFilter;
    filters?: Filters;
    paging?: Paging;
  }

  export interface RelationshipFilter {
    name?:  string;
    phone?: string;
    extension_number?: string;
    role?: string;
    user_ids?: Array<string>;
  }

  export interface GetAccountUserRequest {
    filter?: GetAccountUserFilter;
    paging?: CursorPaging;
  }

  export interface GetAccountUserFilter {
    full_name?: string;
    phone?: string;
    user_ids?: string[];
    role?: string;
    department_id?: string;
    name?: string;
  }

  export interface CreateAccountUserRequest {
    full_name: string;
    phone: string;
    password: string;
    roles: string[];
    department_id?: string;
  }

  export interface CreateExternalAccountHaravanRequest {
    code: string;
    org_id: number;
  }

  export interface UpdateAccountUserRequest {
    full_name?: string;
    roles?: string[];
    department_id?: string;
    password?: string;
    user_id: string;
  }
}
