import {Injectable} from '@angular/core';
import {HttpService} from '@etop/common';
import {ListQueryDTO} from 'libs/models/CommonQuery';
import {Order, OrderFeeLine, OrderLine, Shipping} from 'libs/models/Order';
import {MappingService} from 'apps/core/src/services/mapping.service';
import {UserBehaviourTrackingService} from 'apps/core/src/services/user-behaviour-tracking.service';
import {AuthenticateStore} from '@etop/core';
import {Observable} from 'rxjs';
import {ExportFilterQueryDTO} from '@etop/api/shop/fulfillment.api';

@Injectable({providedIn: 'root'})
export class OrderApi {
  constructor(
    private http: HttpService,
    private mappingService: MappingService,
    private ubtService: UserBehaviourTrackingService,
    private auth: AuthenticateStore
  ) {
  }

  createOrder(order: Partial<OrderAPI.CreateOrderRequest>): Promise<Order> {
    order = this.parseNumberOrder(order);
    return this.http.post(`api/shop.Order/CreateOrder`, order).toPromise()
      .then(newOrder => Order.orderMap(newOrder, this.auth));
  }

  getOrders(query: Partial<ListQueryDTO>): Promise<Array<Order>> {
    return this.http.post(`api/shop.Order/GetOrders`, query).toPromise()
      .then(res => res.orders.map(order => Order.orderMap(order, this.auth)));
  }

  getOrder(id): Promise<Order> {
    return this.http.post(`api/shop.Order/GetOrder`, {id})
      .toPromise()
      .then(order => Order.orderMap(order, this.auth));
  }

  getOrdersByIDs(ids: Array<string>, isMovecrop = false): Promise<Array<Order>> {
    return this.http.post(`api/shop.Order/GetOrdersByIDs`, {ids})
      .toPromise()
      .then(res => res.orders.map(order => isMovecrop ?
        Order.orderMapMovecrop(order, this.auth) :
        Order.orderMap(order, this.auth))
      );
  }

  updateOrder(order: Partial<OrderAPI.UpdateOrderRequest>, token?): Promise<Order> {
    if (!order.id) {
      throw new Error('order.id is required');
    }
    order = this.parseNumberOrder(order);
    return this.http.post(`api/shop.Order/UpdateOrder`, order, token)
      .toPromise()
      .then(updatedOrder => Order.orderMap(updatedOrder, this.auth));
  }

  confirmOrder(order_id: string, auto_inventory_voucher?: 'create' | 'confirm'): Promise<any> {
    return this.http
      .post(`api/shop.Order/ConfirmOrder`, {order_id, auto_inventory_voucher})
      .toPromise();
  }

  completeOrder(order_id: string): Promise<any> {
    return this.http
      .post(`api/shop.Order/CompleteOrder`, {order_id})
      .toPromise();
  }

  cancelOrder(order_id: string, cancel_reason: string, auto_inventory_voucher?: 'create' | 'confirm') {
    return this.http.post(`api/shop.Order/CancelOrder`, {
      order_id,
      cancel_reason,
      auto_inventory_voucher
    }).toPromise();
  }

  getOptions(token) {
    const headers = this.http.createHeader(token);
    const options = this.http.createDefaultOption(headers);
    return options;
  }

  getShopOrders(token, opt = {paging: {limit: 100000}}) {
    const options = this.getOptions(token);
    return this.http
      .postWithOptions('api/shop.Order/GetOrders', opt, options)
      .toPromise();
  }

  async checkEmptyOrders(shop_id) {
    const options = this.getOptions(this.auth.getTokenByShop(shop_id));
    const paging = {
      offset: 0,
      limit: 1
    };
    const res = await this.http
      .postWithOptions('api/shop.Order/GetOrders', {paging}, options)
      .toPromise();
    const {orders} = res;
    return orders.length > 0;
  }

  importOrder(formData) {
    return this.http.purePostForm('api/shop.Import/Orders', formData).toPromise()
      .then(res => res);
  }

  requestExportOrderExcel(export_filters: ExportFilterQueryDTO) {
    let waiting = false;
    return new Observable(observer => {
      let sseListener = this.http
        .listenSSE(`api/event-stream?__token=${this.auth.snapshot.token}`, ['export/progress', 'export/ok', 'ping'])
        .subscribe(res => {
          if (export_filters.ids && export_filters.ids.length) {
            delete export_filters.date_from;
            delete export_filters.date_to;
          }
          if (!waiting) {
            waiting = true;
            this.http
              .post('api/shop.Export/RequestExport', {
                ...export_filters,
                export_type: 'shop/orders'
              })
              .toPromise()
              .catch(err => {
                debug.log('ERROR', err);
                sseListener.unsubscribe();
                observer.error(err);
              });
          }
          if (res.event == 'export/ok') {
            sseListener.unsubscribe();
            observer.next(res.data);
            observer.complete();
          } else if (res.event == 'export/progress') {
            observer.next(res.data);
          }
        });

      return {
        unsubscribe(): void {
          sseListener.unsubscribe();
        }
      };
    });
  }

  private parseNumberOrder(order: Partial<OrderAPI.CreateOrderRequest>) {
    order = {
      ...order,
      basket_value: Number(order.basket_value),
      order_discount: Number(order.order_discount),
      total_amount: Number(order.total_amount),
      total_items: Number(order.total_items),
      total_fee: Number(order.total_fee),
      total_discount: Number(order.total_discount)
    };
    if (order.shipping) {
      const shipping = order.shipping;
      order.shipping = {
        ...shipping,
        cod_amount: Number(shipping.cod_amount),
        chargeable_weight: Number(shipping.chargeable_weight),
        length: Number(shipping.length),
        width: Number(shipping.width),
        height: Number(shipping.height),
        shipping_service_fee: Number(shipping.shipping_service_fee),
      };
    }
    if (order.fee_lines && order.fee_lines.length) {
      order.fee_lines.forEach(l => {
        l.amount = Number(l.amount);
      });
    }
    if (order.lines && order.lines.length) {
      order.lines.forEach(l => {
        l.quantity = Number(l.quantity);
        l.list_price = Number(l.list_price);
        l.retail_price = Number(l.retail_price);
        l.payment_price = Number(l.payment_price);
      });
    }
    return order;
  }

}

export namespace OrderAPI {
  export class CreateOrderRequest {
    source: string;
    payment_method: string;
    customer_id?: string;
    customer?: {
      full_name: string;
      phone?: string;
    };
    total_items: number;
    basket_value: number;
    total_fee: number;
    order_discount: number;
    total_discount: number;
    total_amount: number;
    fee_lines: OrderFeeLine[];
    lines: OrderLine[];
    shipping?: Shipping;
    order_note?: string;
  }

  export interface UpdateOrderRequest extends CreateOrderRequest {
    id: string;
  }

}
