import {BaseModel} from '../core/base/base-model';
import {Address} from './Address';
import {User} from './User';
import {BankAccount} from './Bank';

export enum MoneyTransactionRrule {
  everyDay = 'FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR',
  every246 = 'FREQ=WEEKLY;BYDAY=MO,WE,FR',
  every35 = 'FREQ=WEEKLY;BYDAY=TU,TH',
  everyFriday = 'FREQ=WEEKLY;BYDAY=FR',
  twoFridaysEveryMonth = 'FREQ=MONTHLY;BYDAY=+2FR,-1FR',
  oneFridaysEveryMonth = 'FREQ=MONTHLY;BYDAY=-1FR',
  everyLastWorkingDayOfMonth = 'FREQ=MONTHLY;BYDAY=MO,TU,WE,TH,FR;BYSETPOS=-1',
  everyLastDayOfMonth = 'FREQ=MONTHLY;BYMONTHDAY=-1'
}

export const MONEY_TRANSACTION_RRULE = {
  'FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR': 'Hàng ngày',
  'FREQ=WEEKLY;BYDAY=MO,WE,FR': 'Thứ 2-4-6 hàng tuần',
  'FREQ=WEEKLY;BYDAY=TU,TH': 'Thứ 3-5 hàng tuần',
  'FREQ=WEEKLY;BYDAY=FR': '1 tuần 1 lần vào thứ 6',
  'FREQ=MONTHLY;BYDAY=+2FR,-1FR': '1 tháng 2 lần vào thứ 6',
  'FREQ=MONTHLY;BYDAY=-1FR': '1 tháng 1 lần vào thứ 6',
  'FREQ=MONTHLY;BYDAY=MO,TU,WE,TH,FR;BYSETPOS=-1': '1 tháng 1 lần vào ngày cuối cùng của tháng'
};

export const MONEY_TRANSACTION_RRULE_OPTIONS = [
  {
    name: 'Hàng ngày',
    value:'FREQ=WEEKLY;BYDAY=MO,TU,WE,TH,FR'
  },
  {
    name: 'Thứ 2-4-6 hàng tuần',
    value:'FREQ=WEEKLY;BYDAY=MO,WE,FR'
  },
  {
    name: 'Thứ 3-5 hàng tuần',
    value:'FREQ=WEEKLY;BYDAY=TU,TH'
  },
  {
    name: '1 tuần 1 lần vào thứ 6',
    value:'FREQ=WEEKLY;BYDAY=FR'
  },
  {
    name: '1 tháng 2 lần vào thứ 6',
    value:'FREQ=MONTHLY;BYDAY=+2FR,-1FR'
  },
  {
    name: '1 tháng 1 lần vào thứ 6',
    value:'FREQ=MONTHLY;BYDAY=-1FR'
  },
  {
    name: '1 tháng 1 lần vào ngày cuối cùng của tháng',
    value:'FREQ=MONTHLY;BYDAY=MO,TU,WE,TH,FR;BYSETPOS=-1'
  }
];

export const DEFAULT_RRULE = MoneyTransactionRrule.everyFriday;

export class Account extends BaseModel {
  id: string;
  name: string;
  type: string;
  access_token: string;
  expires_in: number;
  image_url: string;
  url_slug: string;

  user_id: string;
  user_full_name: string;
  user_short_name: string;
  account_id: string;
  account_name: string;
  account_type: string;
  position: string;
  status: string;
  response_status: string;
  invitation_sent_by: string;
  invitation_sent_at: string;
  invitation_accepted_at: string;
  invitation_rejected_at: string;
  disabled_at: string;
  disabled_by: string;
  disable_reason: string;

  display_name: string;
  token: string;
  user_account: UserAccount;
  shop: ExtendedAccount;
  created_at: string;
  email: string;
  code:string;
  phone: string;
}

export class CompanyInfo extends BaseModel {
  name: string;
  tax_code: string;
  address: string;
  website: string;
  legal_representative: {
    name: string;
    position: string;
    phone: string;
    email: string;
  };
}

export interface SurveyInfo {
  key: string;
  answer: string;
  question: string;
}

export class UserAccount extends Account {
  permission: {
    roles: string[];
    permissions: string[];
  }
}

export class ExtendedAccount extends Account {
  id: string;
  name: string;
  status: string;
  is_test: boolean;
  address: Address;
  phone: string;
  bank_account: BankAccount;
  auto_create_ffm: boolean;
  website_url: string;
  image_url: string;
  email: string;
  product_source_id: string;
  ship_to_address_id: string;
  ship_from_address_id: string;
  ghn_note_code: string;
  try_on: string;
  owner_id: string;
  user: User;
  company_info: CompanyInfo;
  money_transaction_rrule: MoneyTransactionRrule;
  money_transaction_rrule_display: string;
  survey_info: SurveyInfo[];
  code: string;
  token: string;
  created_at: string;
  display_name: string;

  static shopMap(shop: ExtendedAccount): ExtendedAccount {
    return {
      ...shop,
      money_transaction_rrule_display: MONEY_TRANSACTION_RRULE[shop.money_transaction_rrule]
    };
  }
}

export class Partner extends BaseModel {
  id: string;
  name: string;
  type?: string;
  image_url?: string;
  website?: string;
  redirect_url?: string;
  content?: any;
  is_authorized?: boolean;
}

export class AccountShipnow extends BaseModel {
  id: string;
  phone: string;
  name: string;
  connection_id: string;
  owner_id: string;
  external_verified: boolean;
  external_created_at: Date;
  created_at: Date;
  updated_at: Date;
  last_send_verify_at: Date;
  external_ticket_id: string;
  id_card_front_img: string;
  id_card_back_img: string;
  portrait_img: string;
  fanpage_url: string;
  website_url: string;
  company_imgs: Array<string>;
  business_license_imgs: Array<string>;
  uploaded_at: Date;
}


/**
 * Shop which being used in Admin/CRM. Not to be confusing with Shop in Etop web/app, Topship app
 * which is an Account with the
 * type == "shop"
 *
 */
export class Shop extends BaseModel {
  address: Address;
  auto_create_ffm: boolean;
  bank_account: BankAccount;
  email: string;
  ghn_note_code: string;
  id: string;
  code: string;
  image_url: string;
  is_test: boolean;
  name: string;
  phone: string;
  product_source_id: string;
  ship_from_address_id: string;
  ship_to_address_id: string;
  status: string;
  website_url: string;
  money_transaction_rrule: MoneyTransactionRrule;
  created_at?: Date;
  updated_at?: Date;
  user: User;
  owner_id: string;
  money_transaction_rrule_display: string;
  is_prior_money_transaction: boolean;
  money_transaction_count: number;

  static shopMap(shop: Shop): Shop {
    return {
      ...shop,
      money_transaction_rrule_display: MONEY_TRANSACTION_RRULE[shop.money_transaction_rrule],
      is_prior_money_transaction: shop?.is_prior_money_transaction || false
    };
  }
}
