import { BaseModel } from '@etop/core/base/base-model';

export class ConnectShopOA extends BaseModel {
  refresh_token: string;
  refresh_token_expires_at: string;
  status: string;
  token: string;
  token_expires_at: string;
}

export class ShopOA extends BaseModel {
  connection_id: string;
  connection_method: string;
  created_at: string;
  id: string;
  onwer_id: string;
  shop_id: string;
  status: string;
  updated_at: string;
  avatar: string;
  cover: string;
  description: string;
  is_verified: boolean;
  name: string;
  oa_id: string;
  expires_at: string;
  app_id: string;
  current_quality: string;
  last_week_quality: string;
  daily_quota: number;
  daily_quota_promotion: number;
  estimated_next_month_promotion_quota: number;
  monthly_promotion_quota: number;
  remaining_monthly_promotion_quota: number;
  remaining_quota: number;
  webhook_url: string;
}

export class OAConversation extends BaseModel {
  attachments?: {
    sticker: Attachment[];
    image: Attachment[];
    gif: Attachment[];
    file: Attachment[];
    link: Attachment[];
    audio: Attachment[];
    video: Attachment[];
    location: Attachment[];
    business_card: Attachment[];
  };
  created_at: string;
  direction?: 'in' | 'out';
  id: string;
  message: string;
  msg_id: string;
  oa_id: string;
  sender_id: string;
  recipient_id: string;
  sent_time: string;
  shop_id: string;
  type: ConversationType | string;
  updated_at: string;
  zl_user_id: string;
  quote_msg_id: string;
  quote_message: {
    type: ConversationType | string;
    message: string;
    attachment?: {
      sticker: Attachment[];
      image: Attachment[];
      gif: Attachment[];
      file: Attachment[];
      link: Attachment[];
      audio: Attachment[];
      video: Attachment[];
      location: Attachment[];
      business_card: Attachment[];
    };
    direction?: 'in' | 'out';
  }
}

export class ConversationOAChat extends BaseModel {
  attachments?: {
    sticker: Attachment[];
    image: Attachment[];
    gif: Attachment[];
    file: Attachment[];
    link: Attachment[];
    audio: Attachment[];
    video: Attachment[];
    location: Attachment[];
    business_card: Attachment[];
  };
  direction?: 'in' | 'out';
  message: string;
  sent_time: string;
  type: ConversationType | string;
}

export class FollowerOAChat extends BaseModel {
  assigned_user_id: string;
  avatar: string;
  avatars: string[];
  created_at: string;
  full_name: string;
  full_name_norm: string;
  gender: string;
  has_conversation: boolean;
  id: string;
  oa_id: string;
  phone: string;
  phone_norm: string;
  shared_info: ShareInfo;
  shop_id: string;
  state: 'unfollow' | 'follow';
  tags_and_notes_info: {
    notes: string[];
    tag_names: string[]
  };
  updated_at: string;
  zl_user_id: string;
  zl_user_id_by_app: string;
  last_conversation: ConversationOAChat;
  read_msg?: boolean;
}

export class ShareInfo extends BaseModel {
  address: string;
  city: string;
  district: string;
  name: string;
  phone: string;
  ward: string;
}

export class Attachment {
  thumbnail?: string;
  description?: string;
  url?: string;
  size?: string;
  checksum?: string;
  name?: string;
  type?: string;
}

export class RequestConsents extends BaseModel {
  call_type: 'unknown' | 'audio' | 'video' | 'audio_and_video';
  error_code: string;
  created_at: string;
  id: string;
  message: string;
  updated_at: string;
  oa_id: string;
  phone: string;
  reason_code:
    | 'unknown'
    | 'product_service_consulting'
    | 'order_appointment_confirmation'
    | 'delivery_notification'
    | 'flight_announcement'
    | 'update_order';
  shop_id: string;
  reason_code_display: string;
  call_type_display: string;
  account_id: string;
  user_consent: 'unknown' | 'allow' | 'user_blocked';
  owner_id: string;
  expires_at: string;
  error_code_display: string;
  shopOA: ShopOA;
  status: string;
  user_id: string;
  created_by: string;
  reply_status: string;
  status_display: string;
}

export class Followers extends BaseModel {
  avatar: string;
  created_at: string;
  name: string;
  full_name: string;
  gender: 'unknown' | 'male' | 'female';
  id: string;
  oa: ShopOA;
  oa_id: string;
  owner_id: string;
  shop_id: string;
  status: string;
  updated_at: string;
  zl_user_id: string;
  zl_user_id_by_app: string;
  phone: string;
  state: 'unfollow' | 'follow';
  has_conversation: boolean;
  last_conversation: ConversationOAChat;
  tags_and_notes_info: {
    notes: string[];
    tag_names: string[]
  };
  shared_info: ShareInfo;
}

export class ZNSTemplateParams extends BaseModel {
  accept_null: boolean;
  max_length: number;
  min_length: number;
  name: string;
  require: boolean;
  type: string;
}

export class ZNSTemplate extends BaseModel {
  apply_template_quota: boolean;
  created_at: string;
  oa_id: string;
  oa_name: string;
  oa_avatar: string;
  preview_url: string;
  price: number;
  shop_id: string;
  template_daily_quota: number;
  template_id: number;
  template_name: string;
  template_params: Array<ZNSTemplateParams>;
  template_quality: string;
  template_remaining_quota: number;
  template_status: string;
  template_tag: string;
  timeout: number;
  type: string;
  updated_at: string;
  id: string;
}

export class ShopApp extends BaseModel {
  app_id: string;
  created_at: string;
  id: string;
  oa_secret: string;
  shop_id: string;
  updated_at: string;
}

export class Journey extends BaseModel {
  created_at: string;
  error_code: number;
  error_message: string;
  id: string;
  is_charged: boolean;
  journey_id: string;
  journey_token: string;
  msg_id: string;
  oa_id: string;
  phone: string;
  shop_id: string;
  status: 'Z' | 'P' | 'N';
  updated_at: string;
  zl_charged_at: string;
  zl_created_at: string;
  zl_expired_at: string;
  zl_timeout_at: string;
  oa_name: string;
  oa_avatar: string;
  status_display: string;
}

export class ZNSMessage extends BaseModel {
  created_at: string;
  delivery_time: string;
  fee_main: number;
  fee_token: number;
  id: string;
  is_charged: true;
  journey_id: string;
  msg_id: string;
  oa_id: string;
  phone: string;
  sent_time: string;
  shop_id: string;
  template_id: number;
  timeout: number;
  tracking_id: string;
  type: string;
  updated_at: string;
  oa_name: string;
  oa_avatar: string;
  template: ZNSTemplate;
  template_data: any;
  error_code: number;
  error_message: string;
  user_id: string;
  delivery_status: 'unknown' | 'received' | 'seen';
  status: 'Z' | 'P' | 'N';
  status_display: string;
  delivery_status_display: string;
  created_by: any;
  campaign_id: string;
  rate: number;
  submit_time: string;
  feedback: string[];
  note: string;
}

export class Campaign extends BaseModel {
  campaign_name: string;
  created_at: string;
  id: string;
  is_scheduled: boolean;
  oa_id: string;
  scheduled_time: string;
  shop_id: string;
  status: string;
  template_id: number;
  updated_at: string;
  user_id: string;
  oa_name: string;
  oa_avatar: string;
  template: ZNSTemplate;
  charged: number;
  delivered: number;
  recorded: number;
  sent: number;
  status_display: string;
  sent_rate: string;
  created_by: any;
}

export class UserQuota extends BaseModel {
  cs_reply_remain: number;
  cs_reply_total: number;
  last_interaction: string;
  promotion_daily_remain: number;
  promotion_daily_total: number;
  promotion_monthly_remain: number;
  promotion_monthly_total: number;
  interaction_2_days: string;
  expire_7_days: boolean;
  active: boolean;
}

export class OAQuota extends BaseModel {
  remain: number;
  total: number;
}

export enum ConversationType {
  text = 'text',
  audio = 'audio',
  photo = 'photo',
  GIF = 'GIF',
  link = 'link',
  file = 'file',
  sticker = 'sticker',
  location = 'location',
  image = 'image',
  business_card = 'business_card',
  unknown = 'unknown',
  video = 'video',
}

export class TotalFollowers extends BaseModel {
  total: number;
  total_sync: number;
}

export enum FollowersTab {
  list = 'list',
  report = 'report',
}

export enum AdminZaloTab {
  zns = 'zns',
  zcc = 'zcc',
  messages = 'messages',
}

export enum ZccTab {
  list = 'list',
  report = 'report',
  followers = 'followers',
  import = 'import',
}

export enum ZNSTab {
  reports = 'reports',
  templates = 'templates',
  messages = 'messages',
  journeys = 'journeys',
  campaigns = 'campaigns',
  rating = 'rating',
}

export const REQUEST_CONSENT_REASON = {
  product_service_consulting: 'Tư vấn sản phẩm / dịch vụ',
  delivery_notification: 'Thông báo giao hàng',
  order_appointment_confirmation: 'Xác nhận đơn hàng / cuộc hẹn',
  flight_announcement: 'Thông báo chuyến bay',
  update_order: 'Cập nhật đơn hàng',
  unknown: 'Không xác định',
};

export const CALL_TYPE = {
  audio: 'Cuộc gọi thoại',
  video: ' Cuộc gọi video',
  audio_and_video: 'Cả gọi thoại và video',
  unknown: 'Không xác định',
};

export const USER_CONSENT = {
  allow: 'Cho phép gọi',
  user_blocked: ' Chặn',
  unknown: 'Không xác định',
};

export const MSG_STATUS = {
  Z: 'Đang chờ xử lý',
  S: 'Đang xử lý',
  P: 'Thành công',
  N: 'Thất bại',
};

export const CAMPAIGN_STATUS = {
  Z: 'Đang chờ xử lý',
  S: 'Đang xử lý',
  P: 'Hoàn thành',
  N: 'Hủy',
};
export const CONSENT_STATUS = {
  Z: 'Mới tạo',
  P: 'Thành công',
  N: 'Thất bại',
};

export const MSG_DELIVERY = {
  unknown: '-',
  seen: 'Đã xem',
  received: 'Đã nhận',
};

export const STICKERS = [
  {
    id: "95bc13962fd3c68d9fc2",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49162&size=130&version=2"
},
{
    id: "84d603fc3fb9d6e78fa8",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49163&size=130&version=2"
},
{
    id: "f28c72a64ee3a7bdfef2",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49164&size=130&version=2"
},
{
    id: "2a87abad97e87eb627f9",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49165&size=130&version=2"
},
{
    id: "0b7e8954b5115c4f0500",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49166&size=130&version=2"
},
{
    id: "a92e2a041641ff1fa650",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49167&size=130&version=2"
},
{
    id: "dc4c40667c23957dcc32",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49168&size=130&version=2"
},
{
    id: "d2cb4fe173a49afac3b5",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49169&size=130&version=2"
},
{
    id: "735bed71d134386a6125",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49170&size=130&version=2"
},
{
    id: "b4432b69172cfe72a73d",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49171&size=130&version=2"
},
{
    id: "6802f028cc6d25337c7c",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49172&size=130&version=2"
},
{
    id: "37f2aed8929d7bc3228c",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49173&size=130&version=2"
},
{
    id: "66dffcf5c0b029ee70a1",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49174&size=130&version=2"
},
{
    id: "ab14303e0c7be525bc6a",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49175&size=130&version=2"
},
{
    id: "b525210f1d4af414ad5b",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49176&size=130&version=2"
},
{
    id: "6902fc28c06d2933707c",
    url: "https://zalo-api.zadn.vn/api/emoticon/sprite?eid=49177&size=130&version=2"
},
  {
    id: '7a150a563613df4d8602',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43516&size=130',
  },
  {
    id: '744605053940d01e8951',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43517&size=130',
  },
  {
    id: 'af3cdd7fe13a0864512b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43518&size=130',
  },
  {
    id: '2d8f5ecc62898bd7d298',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43519&size=130',
  },
  {
    id: '289ea4de989b71c5288a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43520&size=130',
  },
  {
    id: '999114d12894c1ca9885',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43521&size=130',
  },
  {
    id: 'bef730b70cf2e5acbce3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43522&size=130',
  },
  {
    id: '5776d836e4730d2d5462',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43523&size=130',
  },
  {
    id: 'cf5b471b7b5e9200cb4f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43524&size=130',
  },
  {
    id: 'c5b84cf870bd99e3c0ac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43525&size=130',
  },
  {
    id: '7821f261ce24277a7e35',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43526&size=130',
  },
  {
    id: '3e34b5748931606f3920',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43527&size=130',
  },
  {
    id: '3be6bfa683e36abd33f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43528&size=130',
  },
  {
    id: '836d062d3a68d3368a79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43529&size=130',
  },
  {
    id: 'eb626d225167b839e176',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43530&size=130',
  },
  {
    id: 'ba903dd00195e8cbb184',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43531&size=130',
  },
  {
    id: '009cc1d1fd9414ca4d85',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42829&size=130',
  },
  {
    id: 'fde13fac03e9eab7b3f8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42830&size=130',
  },
  {
    id: '7468b7258b60623e3b71',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42831&size=130',
  },
  {
    id: '0403d84ee40b0d55541a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42832&size=130',
  },
  {
    id: 'c47b19362573cc2d9562',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42833&size=130',
  },
  {
    id: '06ded893e4d60d8854c7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42834&size=130',
  },
  {
    id: 'aa457508494da013f95c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42835&size=130',
  },
  {
    id: '8d73553e697b8025d96a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42836&size=130',
  },
  {
    id: 'aab673fb4fbea6e0ffaf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42837&size=130',
  },
  {
    id: 'a34979044541ac1ff550',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42838&size=130',
  },
  {
    id: 'a4d57f9843ddaa83f3cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42839&size=130',
  },
  {
    id: 'df9f0bd23797dec98786',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42840&size=130',
  },
  {
    id: '80c6f9afc5ea2cb475fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33781&size=130',
  },
  {
    id: '4bd131b80dfde4a3bdec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33782&size=130',
  },
  {
    id: 'b669cd00f145181b4154',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33783&size=130',
  },
  {
    id: '4a4b3e220267eb39b276',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33784&size=130',
  },
  {
    id: 'da04af6d93287a762339',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33785&size=130',
  },
  {
    id: '54cf22a61ee3f7bdaef2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33786&size=130',
  },
  {
    id: 'bc1fcb76f7331e6d4722',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33787&size=130',
  },
  {
    id: '0ca67ccf408aa9d4f09b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33788&size=130',
  },
  {
    id: 'b0e5c18cfdc914974dd8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33789&size=130',
  },
  {
    id: '27ea558369c68098d9d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33790&size=130',
  },
  {
    id: '4d1e3e770232eb6cb223',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33791&size=130',
  },
  {
    id: '3736bb58871d6e43370c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33792&size=130',
  },
  {
    id: '0ece94aca8e941b718f8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34838&size=130',
  },
  {
    id: '623ef95cc5192c477508',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34839&size=130',
  },
  {
    id: '3eb5aad796927fcc2683',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34840&size=130',
  },
  {
    id: 'e7f372914ed4a78afec5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34841&size=130',
  },
  {
    id: 'ea647c064043a91df052',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34842&size=130',
  },
  {
    id: 'e10176634a26a378fa37',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34843&size=130',
  },
  {
    id: 'a72737450b00e25ebb11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34844&size=130',
  },
  {
    id: 'abee3a8c06c9ef97b6d8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34845&size=130',
  },
  {
    id: '96d104b338f6d1a888e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34846&size=130',
  },
  {
    id: 'e10772654e20a77efe31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34847&size=130',
  },
  {
    id: 'de7272104e55a70bfe44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34848&size=130',
  },
  {
    id: '3be09682aac743991ad6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34849&size=130',
  },
  {
    id: 'cf3161535d16b448ed07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34850&size=130',
  },
  {
    id: '1ff4b0968cd3658d3cc2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34851&size=130',
  },
  {
    id: 'c06d680f544abd14e45b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34852&size=130',
  },
  {
    id: 'fa2d534f6f0a8654df1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34853&size=130',
  },
  {
    id: 'cecd64af58eab1b4e8fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34854&size=130',
  },
  {
    id: '7dbed6dcea9903c75a88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34855&size=130',
  },
  {
    id: '86c122a31ee6f7b8aef7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34856&size=130',
  },
  {
    id: '34219143ad0644581d17',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34857&size=130',
  },
  {
    id: '763ed2b0eef507ab5ee4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25640&size=130',
  },
  {
    id: 'd8537ddd4198a8c6f189',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25641&size=130',
  },
  {
    id: '6cedca63f6261f784637',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25642&size=130',
  },
  {
    id: 'a9c50e4b320edb50821f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25643&size=130',
  },
  {
    id: 'e65246dc7a9993c7ca88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25644&size=130',
  },
  {
    id: '0efbaf7593307a6e2321',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25645&size=130',
  },
  {
    id: '774cd5c2e98700d95996',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25646&size=130',
  },
  {
    id: '824721c91d8cf4d2ad9d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25647&size=130',
  },
  {
    id: '0338bfb683f36aad33e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25648&size=130',
  },
  {
    id: 'cb9d76134a56a308fa47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25649&size=130',
  },
  {
    id: '7bc3c54df90810564919',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25650&size=130',
  },
  {
    id: '0524baaa86ef6fb136fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25651&size=130',
  },
  {
    id: '1e94a61a9a5f73012a4e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25652&size=130',
  },
  {
    id: '873d3eb302f6eba8b2e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25653&size=130',
  },
  {
    id: '2a6790e9acac45f21cbd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25654&size=130',
  },
  {
    id: '044dbfc383866ad83397',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25655&size=130',
  },
  {
    id: 'fd5a49d475919ccfc580',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25656&size=130',
  },
  {
    id: '479ef210ce55270b7e44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25657&size=130',
  },
  {
    id: 'a9f811ae2debc4b59dfa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48180&size=130',
  },
  {
    id: 'bb1102473e02d75c8e13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48181&size=130',
  },
  {
    id: 'b8cc029a3edfd7818ece',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48182&size=130',
  },
  {
    id: '4b1ff049cc0c25527c1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48183&size=130',
  },
  {
    id: 'f62042767e33976dce22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48184&size=130',
  },
  {
    id: '819734c10884e1dab895',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48185&size=130',
  },
  {
    id: '88603e360273eb2db262',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48186&size=130',
  },
  {
    id: '0511b2478e02675c3e13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48187&size=130',
  },
  {
    id: '950f2559191cf042a90d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48188&size=130',
  },
  {
    id: 'ba7d0b2b376ede30877f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48189&size=130',
  },
  {
    id: 'cfd17d8741c2a89cf1d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48190&size=130',
  },
  {
    id: 'b15202043e41d71f8e50',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48191&size=130',
  },
  {
    id: 'b41d784b440ead50f41f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48192&size=130',
  },
  {
    id: '533d9e6ba22e4b70123f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48193&size=130',
  },
  {
    id: 'eb4025161953f00da942',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48194&size=130',
  },
  {
    id: 'e7b128e714a2fdfca4b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48195&size=130',
  },
  {
    id: '0be8c3befffb16a54fea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48196&size=130',
  },
  {
    id: 'c4980dce318bd8d5819a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48197&size=130',
  },
  {
    id: 'd44b1e1d2258cb069249',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48198&size=130',
  },
  {
    id: 'fa4231140d51e40fbd40',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48199&size=130',
  },
  {
    id: '5e8095d1a99440ca1985',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47943&size=130',
  },
  {
    id: '42158644ba01535f0a10',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47944&size=130',
  },
  {
    id: '741bb14a8d0f64513d1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47945&size=130',
  },
  {
    id: '30d3f682cac723997ad6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47946&size=130',
  },
  {
    id: '3b2cfc7dc03829667029',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47947&size=130',
  },
  {
    id: 'db371b662723ce7d9732',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47948&size=130',
  },
  {
    id: 'b2e173b04ff5a6abffe4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47949&size=130',
  },
  {
    id: '41e683b7bff256ac0fe3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47950&size=130',
  },
  {
    id: 'caa009f135b4dcea85a5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47951&size=130',
  },
  {
    id: '9537496675239c7dc532',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47952&size=130',
  },
  {
    id: '30b8ede9d1ac38f261bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47953&size=130',
  },
  {
    id: 'fe0420551c10f54eac01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47954&size=130',
  },
  {
    id: '385ee70fdb4a32146b5b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47955&size=130',
  },
  {
    id: '2e40f611ca54230a7a45',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47956&size=130',
  },
  {
    id: 'a1d5788444c1ad9ff4d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47957&size=130',
  },
  {
    id: '83a259f365b68ce8d5a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47958&size=130',
  },
  {
    id: 'd72cf07dcc3825667c29',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48043&size=130',
  },
  {
    id: '18fd38ac04e9edb7b4f8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48044&size=130',
  },
  {
    id: 'd56af43bc87e2120786f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48045&size=130',
  },
  {
    id: '8adca88d94c87d9624d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48046&size=130',
  },
  {
    id: '340917582b1dc2439b0c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48047&size=130',
  },
  {
    id: '1f6523341f71f62faf60',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48048&size=130',
  },
  {
    id: 'ba7c872dbb6852360b79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48049&size=130',
  },
  {
    id: '086836390a7ce322ba6d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48050&size=130',
  },
  {
    id: '91a8aef992bc7be222ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48051&size=130',
  },
  {
    id: '9f09a7589b1d72432b0c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48052&size=130',
  },
  {
    id: 'e8d8d189edcc04925ddd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48053&size=130',
  },
  {
    id: 'a1cc9b9da7d84e8617c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48054&size=130',
  },
  {
    id: '034738160453ed0db442',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48055&size=130',
  },
  {
    id: '629556c46a8183dfda90',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48056&size=130',
  },
  {
    id: 'db9deeccd2893bd76298',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48057&size=130',
  },
  {
    id: '8bfbbdaa81ef68b131fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48058&size=130',
  },
  {
    id: '559a62cb5e8eb7d0ee9f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48059&size=130',
  },
  {
    id: 'bc348c65b020597e0031',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48060&size=130',
  },
  {
    id: 'e662d733eb7602285b67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48061&size=130',
  },
  {
    id: '2f681d39217cc822916d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48062&size=130',
  },
  {
    id: '59299d7fa13a4864112b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48200&size=130',
  },
  {
    id: 'ae5a6b0c5749be17e758',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48201&size=130',
  },
  {
    id: '0359c50ff94a1014495b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48202&size=130',
  },
  {
    id: '7b31bc678022697c3033',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48203&size=130',
  },
  {
    id: 'e81f2849140cfd52a41d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48204&size=130',
  },
  {
    id: '46b387e5bba052fe0bb1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48205&size=130',
  },
  {
    id: 'fd863fd00395eacbb384',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48206&size=130',
  },
  {
    id: '9ac4599265d78c89d5c6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48207&size=130',
  },
  {
    id: '47c19b97a7d24e8c17c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48208&size=130',
  },
  {
    id: '56288b7eb73b5e65072a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48209&size=130',
  },
  {
    id: 'c2a21cf420b1c9ef90a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48210&size=130',
  },
  {
    id: '92394d6f712a9874c13b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48211&size=130',
  },
  {
    id: '89a9daf8e6bd0fe356ac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48095&size=130',
  },
  {
    id: 'd5efb9be85fb6ca535ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48096&size=130',
  },
  {
    id: '53263e770232eb6cb223',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48097&size=130',
  },
  {
    id: 'e3d08d81b1c4589a01d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48098&size=130',
  },
  {
    id: '881be74adb0f32516b1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48099&size=130',
  },
  {
    id: 'ad6fc53ef97b1025496a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48100&size=130',
  },
  {
    id: 'd8a2b1f38db664e83da7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48101&size=130',
  },
  {
    id: 'b9cad39befde06805fcf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48102&size=130',
  },
  {
    id: '19d972884ecda793fedc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48103&size=130',
  },
  {
    id: '1f2a7b7b473eae60f72f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48104&size=130',
  },
  {
    id: 'd7c9b2988edd67833ecc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48105&size=130',
  },
  {
    id: 'b2ded48fe8ca019458db',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48106&size=130',
  },
  {
    id: '40fd27ac1be9f2b7abf8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48107&size=130',
  },
  {
    id: 'e8378866b4235d7d0432',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48108&size=130',
  },
  {
    id: '1a487b19475cae02f74d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48109&size=130',
  },
  {
    id: '1fd47d8541c0a89ef1d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48110&size=130',
  },
  {
    id: '1b24587564308d6ed421',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48079&size=130',
  },
  {
    id: '415a1d0b214ec810915f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48080&size=130',
  },
  {
    id: 'd36b8e3ab27f5b21026e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48081&size=130',
  },
  {
    id: '268678d74492adccf483',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48082&size=130',
  },
  {
    id: '65253a740631ef6fb620',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48083&size=130',
  },
  {
    id: '13e94bb877fd9ea3c7ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48084&size=130',
  },
  {
    id: 'ea1bb34a8f0f66513f1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48085&size=130',
  },
  {
    id: '3cde668f5acab394eadb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48086&size=130',
  },
  {
    id: 'c0ed9bbca7f94ea717e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48087&size=130',
  },
  {
    id: '58ae0cff30bad9e480ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48088&size=130',
  },
  {
    id: '89c3dc92e0d7098950c6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48089&size=130',
  },
  {
    id: '98aacefbf2be1be042af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48090&size=130',
  },
  {
    id: '31ee66bf5afab3a4eaeb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48091&size=130',
  },
  {
    id: 'f702a7539b1672482b07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48092&size=130',
  },
  {
    id: '8ca4ddf5e1b008ee51a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48093&size=130',
  },
  {
    id: '38cb6a9a56dfbf81e6ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48094&size=130',
  },
  {
    id: '56efa1be9dfb74a52dea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47995&size=130',
  },
  {
    id: '8b8a7bdb479eaec0f78f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47996&size=130',
  },
  {
    id: '5bc1aa9096d57f8b26c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47997&size=130',
  },
  {
    id: '19d8eb89d7cc3e9267dd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47998&size=130',
  },
  {
    id: 'fc6c0f3d3378da268369',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47999&size=130',
  },
  {
    id: 'b1e2bdb381f668a831e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48000&size=130',
  },
  {
    id: 'ff47f216ce53270d7e42',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48001&size=130',
  },
  {
    id: 'b457ba0686436f1d3652',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48002&size=130',
  },
  {
    id: '73137c424007a959f016',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48003&size=130',
  },
  {
    id: '603a686b542ebd70e43f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48004&size=130',
  },
  {
    id: '3d0c345d0818e146b809',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48005&size=130',
  },
  {
    id: 'c215c844f4011d5f4410',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=48006&size=130',
  },
  {
    id: 'da1a394b050eec50b51f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47983&size=130',
  },
  {
    id: 'd68d2adc1699ffc7a688',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47984&size=130',
  },
  {
    id: '2c9fd1ceed8b04d55d9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47985&size=130',
  },
  {
    id: '11fbefaad3ef3ab163fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47986&size=130',
  },
  {
    id: 'b7d5488474c19d9fc4d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47987&size=130',
  },
  {
    id: '6d459514a951400f1940',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47988&size=130',
  },
  {
    id: 'bb5c420d7e489716ce59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47989&size=130',
  },
  {
    id: '981862495e0cb752ee1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47990&size=130',
  },
  {
    id: 'fba200f33cb6d5e88ca7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47991&size=130',
  },
  {
    id: 'e23a166b2a2ec3709a3f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47992&size=130',
  },
  {
    id: '55c8a0999cdc75822ccd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47993&size=130',
  },
  {
    id: 'f02f067e3a3bd3658a2a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47994&size=130',
  },
  {
    id: 'da1a394b050eec50b51f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47983&size=130',
  },
  {
    id: 'd68d2adc1699ffc7a688',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47984&size=130',
  },
  {
    id: '2c9fd1ceed8b04d55d9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47985&size=130',
  },
  {
    id: '11fbefaad3ef3ab163fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47986&size=130',
  },
  {
    id: 'b7d5488474c19d9fc4d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47987&size=130',
  },
  {
    id: '6d459514a951400f1940',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47988&size=130',
  },
  {
    id: 'bb5c420d7e489716ce59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47989&size=130',
  },
  {
    id: '981862495e0cb752ee1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47990&size=130',
  },
  {
    id: 'fba200f33cb6d5e88ca7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47991&size=130',
  },
  {
    id: 'e23a166b2a2ec3709a3f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47992&size=130',
  },
  {
    id: '55c8a0999cdc75822ccd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47993&size=130',
  },
  {
    id: 'f02f067e3a3bd3658a2a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47994&size=130',
  },
  {
    id: '88b308e234a7ddf984b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47884&size=130',
  },
  {
    id: '7dacfcfdc0b829e670a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47885&size=130',
  },
  {
    id: 'f7a775f649b3a0edf9a2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47886&size=130',
  },
  {
    id: 'b02633770f32e66cbf23',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47887&size=130',
  },
  {
    id: '20c2bc9380d6698830c7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47888&size=130',
  },
  {
    id: '3801a5509915704b2904',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47889&size=130',
  },
  {
    id: '294eb71f8b5a62043b4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47890&size=130',
  },
  {
    id: '7196eec7d2823bdc6293',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47891&size=130',
  },
  {
    id: '5405cc54f011194f4000',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47892&size=130',
  },
  {
    id: 'd044491575509c0ec541',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47893&size=130',
  },
  {
    id: '1ec78496b8d3518d08c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47894&size=130',
  },
  {
    id: '972c0c7d3038d9668029',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47895&size=130',
  },
  {
    id: 'ba932ec21287fbd9a296',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47896&size=130',
  },
  {
    id: '09779c26a063493d1072',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47897&size=130',
  },
  {
    id: '149682c7be8257dc0e93',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47898&size=130',
  },
  {
    id: '6bf8fca9c0ec29b270fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47899&size=130',
  },
  {
    id: 'b2adcefdf2b81be642a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47856&size=130',
  },
  {
    id: 'cc4bb11b8d5e64003d4f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47857&size=130',
  },
  {
    id: '8f88f1d8cd9d24c37d8c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47858&size=130',
  },
  {
    id: '75c80a9836dddf8386cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47859&size=130',
  },
  {
    id: 'd73aaf6a932f7a71233e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47860&size=130',
  },
  {
    id: 'ec459515a950400e1941',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47861&size=130',
  },
  {
    id: '35354f6573209a7ec331',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47862&size=130',
  },
  {
    id: '0ed3758349c6a098f9d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47863&size=130',
  },
  {
    id: '93b3e7e3dba632f86bb7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47864&size=130',
  },
  {
    id: 'ed1c984ca4094d571418',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47865&size=130',
  },
  {
    id: '687b1e2b226ecb30927f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47866&size=130',
  },
  {
    id: 'c7ddb08d8cc865963cd9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47867&size=130',
  },
  {
    id: '04f168a154e4bdbae4f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47840&size=130',
  },
  {
    id: 'd3bdbeed82a86bf632b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47841&size=130',
  },
  {
    id: 'bc16d246ee03075d5e12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47842&size=130',
  },
  {
    id: '4c0723571f12f64caf03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47843&size=130',
  },
  {
    id: 'a0e7c8b7f4f21dac44e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47844&size=130',
  },
  {
    id: '4f9426c41a81f3dfaa90',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47845&size=130',
  },
  {
    id: '871ded4dd10838566119',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47846&size=130',
  },
  {
    id: '92eaf9bac5ff2ca175ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47847&size=130',
  },
  {
    id: '41682538197df023a96c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47848&size=130',
  },
  {
    id: 'eb968ec6b2835bdd0292',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47849&size=130',
  },
  {
    id: '68450e153250db0e8241',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47850&size=130',
  },
  {
    id: 'bb1fdc4fe00a0954501b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47851&size=130',
  },
  {
    id: '1f847fd44391aacff380',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47852&size=130',
  },
  {
    id: '19af78ff44baade4f4ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47853&size=130',
  },
  {
    id: 'f0e292b2aef747a91ee6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47854&size=130',
  },
  {
    id: '5eba3dea01afe8f1b1be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47855&size=130',
  },
  {
    id: 'c7678337bf72562c0f63',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47816&size=130',
  },
  {
    id: '403c056c3929d0778938',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47817&size=130',
  },
  {
    id: '6efe28ae14ebfdb5a4fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47818&size=130',
  },
  {
    id: '056a423a7e7f9721ce6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47819&size=130',
  },
  {
    id: '284668165453bd0de442',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47820&size=130',
  },
  {
    id: 'fc60bd308175682b3164',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47821&size=130',
  },
  {
    id: '6a6e283e147bfd25a46a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47822&size=130',
  },
  {
    id: 'a43be76bdb2e32706b3f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47823&size=130',
  },
  {
    id: 'ae6af23ace7f27217e6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47824&size=130',
  },
  {
    id: 'ab6af63aca7f23217a6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47825&size=130',
  },
  {
    id: 'efffb1af8dea64b43dfb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47826&size=130',
  },
  {
    id: 'b159ee09d24c3b12625d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47827&size=130',
  },
  {
    id: '059c5dcc618988d7d198',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47828&size=130',
  },
  {
    id: 'ca3e936eaf2b46751f3a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47829&size=130',
  },
  {
    id: 'c56a9f3aa37f4a21136e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47830&size=130',
  },
  {
    id: 'a182fad2c6972fc97686',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47831&size=130',
  },
  {
    id: 'cab79ee7a2a24bfc12b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47832&size=130',
  },
  {
    id: 'c7dc928caec947971ed8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47833&size=130',
  },
  {
    id: '53790529396cd032897d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47834&size=130',
  },
  {
    id: '04c853986fdd8683dfcc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47835&size=130',
  },
  {
    id: 'f31fa34f9f0a76542f1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47836&size=130',
  },
  {
    id: 'c5769426a863413d1872',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47837&size=130',
  },
  {
    id: 'b449e619da5c33026a4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47838&size=130',
  },
  {
    id: '27ce749e48dba185f8ca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47839&size=130',
  },
  {
    id: '0d7bfd2bc16e2830717f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47740&size=130',
  },
  {
    id: '4935b86584206d7e3431',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47741&size=130',
  },
  {
    id: 'd68b24db189ef1c0a88f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47742&size=130',
  },
  {
    id: 'e26011302d75c42b9d64',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47743&size=130',
  },
  {
    id: '455b490b754e9c10c55f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47744&size=130',
  },
  {
    id: '4efe43ae7feb96b5cffa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47745&size=130',
  },
  {
    id: 'af7ba12b9d6e74302d7f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47746&size=130',
  },
  {
    id: '278a28da149ffdc1a48e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47747&size=130',
  },
  {
    id: 'd56fdd3fe17a0824516b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47748&size=130',
  },
  {
    id: 'ed9fe4cfd88a31d4689b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47749&size=130',
  },
  {
    id: '40b04ae076a59ffbc6b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47750&size=130',
  },
  {
    id: '8a9a81cabd8f54d10d9e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47751&size=130',
  },
  {
    id: '836e873ebb7b52250b6a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47752&size=130',
  },
  {
    id: 'a112a442980771592816',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47753&size=130',
  },
  {
    id: '524154116854810ad845',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47754&size=130',
  },
  {
    id: 'fd7ffa2fc66a2f34767b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47755&size=130',
  },
  {
    id: '73c8af9893dd7a8323cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47696&size=130',
  },
  {
    id: 'e1213c710034e96ab025',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47697&size=130',
  },
  {
    id: 'f3482d18115df803a14c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47698&size=130',
  },
  {
    id: 'a6b979e945acacf2f5bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47699&size=130',
  },
  {
    id: '4bb493e4afa146ff1fb0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47700&size=130',
  },
  {
    id: '5a9b83cbbf8e56d00f9f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47701&size=130',
  },
  {
    id: 'f67c2c2c1069f937a078',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47702&size=130',
  },
  {
    id: 'f7412c111054f90aa045',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47703&size=130',
  },
  {
    id: 'bde469b455f1bcafe5e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47704&size=130',
  },
  {
    id: '58398d69b12c5872013d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47705&size=130',
  },
  {
    id: 'e8563e060243eb1db252',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47706&size=130',
  },
  {
    id: '44f693a6afe346bd1ff2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47707&size=130',
  },
  {
    id: '862738770432ed6cb423',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47666&size=130',
  },
  {
    id: '95172a471602ff5ca613',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47667&size=130',
  },
  {
    id: 'bc0b045b381ed140880f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47668&size=130',
  },
  {
    id: '8b5032000e45e71bbe54',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47669&size=130',
  },
  {
    id: 'a4a81ef822bdcbe392ac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47670&size=130',
  },
  {
    id: '338388d3b4965dc80487',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47671&size=130',
  },
  {
    id: '1d9ba9cb958e7cd0259f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47672&size=130',
  },
  {
    id: '1af7afa793e27abc23f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47673&size=130',
  },
  {
    id: '329584c5b88051de0891',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47674&size=130',
  },
  {
    id: '7a4ccd1cf15918074148',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47675&size=130',
  },
  {
    id: '4ebffeefc2aa2bf472bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47676&size=130',
  },
  {
    id: '7d52cc02f04719194056',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47677&size=130',
  },
  {
    id: 'bcc30e9332d6db8882c7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47678&size=130',
  },
  {
    id: '98772b271762fe3ca773',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47679&size=130',
  },
  {
    id: '0b57c707fb42121c4b53',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47680&size=130',
  },
  {
    id: '27f4eaa4d6e13fbf66f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47681&size=130',
  },
  {
    id: '81094f59731c9a42c30d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47682&size=130',
  },
  {
    id: '2bf4e4a4d8e131bf68f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47683&size=130',
  },
  {
    id: 'c17f092f356adc34857b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47684&size=130',
  },
  {
    id: '9a1053406f05865bdf14',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47685&size=130',
  },
  {
    id: '59be93eeafab46f51fba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47686&size=130',
  },
  {
    id: 'a43a6f6a532fba71e33e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47687&size=130',
  },
  {
    id: 'de071a572612cf4c9603',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47688&size=130',
  },
  {
    id: 'a69063c05f85b6dbef94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47689&size=130',
  },
  {
    id: 'da041c542011c94f9000',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47690&size=130',
  },
  {
    id: '1181d6d1ea9403ca5a85',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47691&size=130',
  },
  {
    id: 'c0d700873cc2d59c8cd3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47692&size=130',
  },
  {
    id: '219ee0cedc8b35d56c9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47693&size=130',
  },
  {
    id: '59f29ba2a7e74eb917f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47694&size=130',
  },
  {
    id: '0bf4c8a4f4e11dbf44f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47695&size=130',
  },
  {
    id: '26688838b47d5d23046c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47650&size=130',
  },
  {
    id: '38a197f1abb442ea1ba5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47651&size=130',
  },
  {
    id: '2db685e6b9a350fd09b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47652&size=130',
  },
  {
    id: 'efe946b97afc93a2caed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47653&size=130',
  },
  {
    id: 'cc1666465a03b35dea12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47654&size=130',
  },
  {
    id: 'a7b40ce430a1d9ff80b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47655&size=130',
  },
  {
    id: '05a5a1f59db074ee2da1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47656&size=130',
  },
  {
    id: 'a73d026d3e28d7768e39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47657&size=130',
  },
  {
    id: '456ee33edf7b36256f6a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47658&size=130',
  },
  {
    id: '80ef27bf1bfaf2a4abeb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47659&size=130',
  },
  {
    id: '9b003b500715ee4bb704',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47660&size=130',
  },
  {
    id: 'adf30ca330e6d9b880f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47661&size=130',
  },
  {
    id: '25a187f1bbb452ea0ba5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47662&size=130',
  },
  {
    id: '6155c205fe40171e4e51',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47663&size=130',
  },
  {
    id: '15c4a99495d17c8f25c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47664&size=130',
  },
  {
    id: 'd1506c005045b91be054',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47665&size=130',
  },
  {
    id: '02da70894ccca592fcdd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47614&size=130',
  },
  {
    id: '05b376e04aa5a3fbfab4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47615&size=130',
  },
  {
    id: 'a8cb249b18def180a8cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47616&size=130',
  },
  {
    id: 'd6765b2667638e3dd772',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47617&size=130',
  },
  {
    id: '3039be69822c6b72323d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47618&size=130',
  },
  {
    id: '90af1fff23bacae493ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47619&size=130',
  },
  {
    id: 'd08e58de649b8dc5d48a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47620&size=130',
  },
  {
    id: '5721de71e2340b6a5225',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47621&size=130',
  },
  {
    id: '185a920aae4f47111e5e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47622&size=130',
  },
  {
    id: '71edfabdc6f82fa676e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47623&size=130',
  },
  {
    id: '5778d328ef6d06335f7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47624&size=130',
  },
  {
    id: '7bb0fde0c1a528fb71b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47626&size=130',
  },
  {
    id: 'bf5138010444ed1ab455',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47627&size=130',
  },
  {
    id: 'ee9d6ecd5288bbd6e299',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47628&size=130',
  },
  {
    id: '6f0aee5ad21f3b41620e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47629&size=130',
  },
  {
    id: 'd8095a59661c8f42d60d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47630&size=130',
  },
  {
    id: 'b76034300875e12bb864',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47631&size=130',
  },
  {
    id: 'f39a6fca538fbad1e39e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47632&size=130',
  },
  {
    id: '1d7c802cbc6955370c78',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47633&size=130',
  },
  {
    id: '62a434f708b2e1ecb8a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47578&size=130',
  },
  {
    id: 'edc4ba9786d26f8c36c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47579&size=130',
  },
  {
    id: '7f592f0a134ffa11a35e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47580&size=130',
  },
  {
    id: '479316c02a85c3db9a94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47581&size=130',
  },
  {
    id: '8678d42be86e0130587f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47582&size=130',
  },
  {
    id: '1e294d7a713f9861c12e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47583&size=130',
  },
  {
    id: '6f5103023f47d6198f56',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47584&size=130',
  },
  {
    id: '7b1b16482a0dc3539a1c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47585&size=130',
  },
  {
    id: '840aea59d61c3f42660d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47586&size=130',
  },
  {
    id: 'ef608033bc7655280c67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47587&size=130',
  },
  {
    id: '26a34ef072b59bebc2a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47588&size=130',
  },
  {
    id: '0aaf63fc5fb9b6e7efa8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47589&size=130',
  },
  {
    id: 'b20ed85de4180d465409',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47590&size=130',
  },
  {
    id: '8686edd5d19038ce6181',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47591&size=130',
  },
  {
    id: '0f196b4a570fbe51e71e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47592&size=130',
  },
  {
    id: '7fd01a8326c6cf9896d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47593&size=130',
  },
  {
    id: '7f79952aa96f4031197e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47462&size=130',
  },
  {
    id: '4307a85494117d4f2400',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47463&size=130',
  },
  {
    id: '1c61f832c4772d297466',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47464&size=130',
  },
  {
    id: '0bc8ee9bd2de3b8062cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47465&size=130',
  },
  {
    id: '8e8268d15494bdcae485',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47466&size=130',
  },
  {
    id: '992c7e7f423aab64f22b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47467&size=130',
  },
  {
    id: '4e1eae4d92087b562219',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47468&size=130',
  },
  {
    id: '58e7b9b485f16caf35e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47469&size=130',
  },
  {
    id: '4838aa6b962e7f70263f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47470&size=130',
  },
  {
    id: '795a9a09a64c4f12165d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47471&size=130',
  },
  {
    id: 'e96215312974c02a9965',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47472&size=130',
  },
  {
    id: '346dc93ef57b1c25456a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47473&size=130',
  },
  {
    id: '1729597b653e8c60d52f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47298&size=130',
  },
  {
    id: 'b17cfe2ec26b2b35727a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47299&size=130',
  },
  {
    id: '3de775b549f0a0aef9e1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47300&size=130',
  },
  {
    id: '5aba13e82fadc6f39fbc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47301&size=130',
  },
  {
    id: 'b03ffa6dc6282f767639',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47302&size=130',
  },
  {
    id: '4bb600e43ca1d5ff8cb0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47303&size=130',
  },
  {
    id: '2cfa68a854edbdb3e4fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47304&size=130',
  },
  {
    id: '83e7c6b5faf013ae4ae1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47305&size=130',
  },
  {
    id: '705a36080a4de313ba5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47306&size=130',
  },
  {
    id: 'efafa8fd94b87de624a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47307&size=130',
  },
  {
    id: '5bd61b8427c1ce9f97d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47308&size=130',
  },
  {
    id: 'fa70bb2287676e393776',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47309&size=130',
  },
  {
    id: '8d9ecfccf3891ad74398',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47310&size=130',
  },
  {
    id: 'b9c9fa9bc6de2f8076cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47311&size=130',
  },
  {
    id: 'c9519503a94640181957',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47312&size=130',
  },
  {
    id: '84fad9a8e5ed0cb355fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47313&size=130',
  },
  {
    id: '3e13ca41f6041f5a4615',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47224&size=130',
  },
  {
    id: '4eb8bbea87af6ef137be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47225&size=130',
  },
  {
    id: 'cb023d500115e84bb104',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47226&size=130',
  },
  {
    id: '24a0d3f2efb706e95fa6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47227&size=130',
  },
  {
    id: '30e4c0b6fcf315ad4ce2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47228&size=130',
  },
  {
    id: 'f4e605b439f1d0af89e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47229&size=130',
  },
  {
    id: '45ecb7be8bfb62a53bea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47230&size=130',
  },
  {
    id: '0ee4fdb6c1f328ad71e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47231&size=130',
  },
  {
    id: 'd768db3ae77f0e21576e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47232&size=130',
  },
  {
    id: '323b3f69032cea72b33d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47233&size=130',
  },
  {
    id: '168918db249ecdc0948f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47234&size=130',
  },
  {
    id: '63e46cb650f3b9ade0e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47235&size=130',
  },
  {
    id: '43904bc277879ed9c796',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47236&size=130',
  },
  {
    id: '4b2242707e35976bce24',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47237&size=130',
  },
  {
    id: '16271c752030c96e9021',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47238&size=130',
  },
  {
    id: '8cdb8789bbcc52920bdd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47239&size=130',
  },
  {
    id: 'be8ebadc86996fc73688',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47240&size=130',
  },
  {
    id: '575f520d6e488716de59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47241&size=130',
  },
  {
    id: '2a8d2cdf109af9c4a08b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47242&size=130',
  },
  {
    id: 'be3db96f852a6c74353b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47243&size=130',
  },
  {
    id: 'c9e3c9b1f5f41caa45e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47244&size=130',
  },
  {
    id: 'be2bbf79833c6a62332d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47245&size=130',
  },
  {
    id: '63f661a45de1b4bfedf0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47246&size=130',
  },
  {
    id: 'ad82aed092957bcb2284',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47247&size=130',
  },
  {
    id: 'c7cedb9ce7d90e8757c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47248&size=130',
  },
  {
    id: 'd13dcc6ff02a1974403b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47249&size=130',
  },
  {
    id: 'e918f74acb0f22517b1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47250&size=130',
  },
  {
    id: '5882fdd0c19528cb7184',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47145&size=130',
  },
  {
    id: 'c0d266805ac5b39bead4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47146&size=130',
  },
  {
    id: 'a28205d03995d0cb8984',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47147&size=130',
  },
  {
    id: '107cb02e8c6b65353c7a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47148&size=130',
  },
  {
    id: '1eb6bfe483a16aff33b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47149&size=130',
  },
  {
    id: 'fd775f2563608a3ed371',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47150&size=130',
  },
  {
    id: '2649851bb95e5000094f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47151&size=130',
  },
  {
    id: 'c8e474b648f3a1adf8e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47152&size=130',
  },
  {
    id: 'd49869ca558fbcd1e59e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47153&size=130',
  },
  {
    id: '6fffd1adede804b65df9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47154&size=130',
  },
  {
    id: '19c3a6919ad4738a2ac5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47155&size=130',
  },
  {
    id: '2be893baafff46a11fee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47156&size=130',
  },
  {
    id: 'c19378c14484addaf495',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47157&size=130',
  },
  {
    id: '1677ac259060793e2071',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47158&size=130',
  },
  {
    id: '0876b3248f61663f3f70',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47159&size=130',
  },
  {
    id: '9c2a2878143dfd63a42c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47160&size=130',
  },
  {
    id: '29d39c81a0c4499a10d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47161&size=130',
  },
  {
    id: '1a97acc5908079de2091',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47162&size=130',
  },
  {
    id: 'dcfc6bae57ebbeb5e7fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47163&size=130',
  },
  {
    id: '50f0e0a2dce735b96cf6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47164&size=130',
  },
  {
    id: '0132b0608c25657b3c34',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47165&size=130',
  },
  {
    id: '44d6f684cac1239f7ad0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47166&size=130',
  },
  {
    id: 'a61015422907c0599916',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47167&size=130',
  },
  {
    id: 'b739ef64d3213a7f6330',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47060&size=130',
  },
  {
    id: 'b2a3ebfed7bb3ee567aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47061&size=130',
  },
  {
    id: '04855ed8629d8bc3d28c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47062&size=130',
  },
  {
    id: '1ef845a579e090bec9f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47063&size=130',
  },
  {
    id: '353e61635d26b478ed37',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47064&size=130',
  },
  {
    id: '236f76324a77a329fa66',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47065&size=130',
  },
  {
    id: '418617db2b9ec2c09b8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47066&size=130',
  },
  {
    id: 'fefca9a195e47cba25f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47067&size=130',
  },
  {
    id: 'cffe9fa3a3e64ab813f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47068&size=130',
  },
  {
    id: '9870c92df5681c364579',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47069&size=130',
  },
  {
    id: 'fad8a88594c07d9e24d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47070&size=130',
  },
  {
    id: '4020137d2f38c6669f29',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47071&size=130',
  },
  {
    id: '06976aca568fbfd1e69e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47072&size=130',
  },
  {
    id: '198f74d24897a1c9f886',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47073&size=130',
  },
  {
    id: '3720597d65388c66d529',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47074&size=130',
  },
  {
    id: '22314d6c71299877c138',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47075&size=130',
  },
  {
    id: 'd050b80d84486d163459',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47076&size=130',
  },
  {
    id: '002c69715534bc6ae525',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47077&size=130',
  },
  {
    id: 'e76c8d31b174582a0165',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47078&size=130',
  },
  {
    id: '1fab2bf617b3feeda7a2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47032&size=130',
  },
  {
    id: '33d506883acdd3938adc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47033&size=130',
  },
  {
    id: 'a3bd95e0a9a540fb19b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47034&size=130',
  },
  {
    id: '517566285a6db333ea7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47035&size=130',
  },
  {
    id: '00b530e80cade5f3bcbc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47036&size=130',
  },
  {
    id: 'e1c1d09cecd905875cc8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47037&size=130',
  },
  {
    id: 'e21bd046ec03055d5c12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47038&size=130',
  },
  {
    id: '78e24bbf77fa9ea4c7eb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47039&size=130',
  },
  {
    id: 'e017ac4a900f7951201e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47040&size=130',
  },
  {
    id: 'ae4ee313df5636086f47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47041&size=130',
  },
  {
    id: '30267e7b423eab60f22f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47042&size=130',
  },
  {
    id: 'eae9a5b499f170af29e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=47043&size=130',
  },
  {
    id: 'b2e34abe76fb9fa5c6ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46964&size=130',
  },
  {
    id: '8a1a73474f02a65cff13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46965&size=130',
  },
  {
    id: '5046aa1b965e7f00264f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46966&size=130',
  },
  {
    id: '66ca9d97a1d2488c11c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46967&size=130',
  },
  {
    id: '2a80dedde2980bc65289',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46968&size=130',
  },
  {
    id: 'b37546287a6d9333ca7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46969&size=130',
  },
  {
    id: '3e57c80af44f1d11445e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46970&size=130',
  },
  {
    id: 'c3f934a408e1e1bfb8f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46971&size=130',
  },
  {
    id: '4d16bd4b810e6850311f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46972&size=130',
  },
  {
    id: 'cfd53e8802cdeb93b2dc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46973&size=130',
  },
  {
    id: '9f8c6dd15194b8cae185',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46974&size=130',
  },
  {
    id: '0412f74fcb0a22547b1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46975&size=130',
  },
  {
    id: '10f31cae20ebc9b590fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46976&size=130',
  },
  {
    id: '51045c59601c8942d00d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46977&size=130',
  },
  {
    id: '3f3a31670d22e47cbd33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46978&size=130',
  },
  {
    id: 'dcf5d3a8efed06b35ffc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46979&size=130',
  },
  {
    id: 'dfd6d78bebce02905bdf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46980&size=130',
  },
  {
    id: '0ba202ff3ebad7e48eab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46981&size=130',
  },
  {
    id: 'fccff692cad723897ac6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46982&size=130',
  },
  {
    id: '533c586164248d7ad435',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46983&size=130',
  },
  {
    id: 'd977dd2ae16f0831517e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46984&size=130',
  },
  {
    id: 'bce9b9b485f16caf35e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46985&size=130',
  },
  {
    id: '02a304fe38bbd1e588aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46986&size=130',
  },
  {
    id: '9270952da96840361979',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46987&size=130',
  },
  {
    id: '9703975eab1b42451b0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46988&size=130',
  },
  {
    id: '5a3f5b6267278e79d736',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46989&size=130',
  },
  {
    id: 'eb49e914d5513c0f6540',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46990&size=130',
  },
  {
    id: '3ae639bb05feeca0b5ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46991&size=130',
  },
  {
    id: 'b572512f6d6a8434dd7b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46952&size=130',
  },
  {
    id: '7b2e9e73a2364b681227',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46953&size=130',
  },
  {
    id: 'bb9b5dc6618388ddd192',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46954&size=130',
  },
  {
    id: '7577922aae6f47311e7e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46955&size=130',
  },
  {
    id: '961a76474a02a35cfa13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46956&size=130',
  },
  {
    id: 'ea910bcc3789ded78798',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46957&size=130',
  },
  {
    id: 'a657440a784f9111c85e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46958&size=130',
  },
  {
    id: '1dbefee3c2a62bf872b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46959&size=130',
  },
  {
    id: 'cdbb31e60da3e4fdbdb2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46960&size=130',
  },
  {
    id: 'aaf157ac6be982b7dbf8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46961&size=130',
  },
  {
    id: '7f2e8173bd3654680d27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46962&size=130',
  },
  {
    id: 'fb8204df389ad1c4888b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46963&size=130',
  },
  {
    id: '652b9977a5324c6c1523',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46704&size=130',
  },
  {
    id: 'e85c15002945c01b9954',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46705&size=130',
  },
  {
    id: '94b76aeb56aebff0e6bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46706&size=130',
  },
  {
    id: 'b91546497a0c9352ca1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46707&size=130',
  },
  {
    id: '11b7e9ebd5ae3cf065bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46708&size=130',
  },
  {
    id: 'e99010cc2c89c5d79c98',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46709&size=130',
  },
  {
    id: '51e8abb497f17eaf27e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46710&size=130',
  },
  {
    id: '4af9b1a58de064be3df1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46711&size=130',
  },
  {
    id: 'e616124a2e0fc7519e1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46712&size=130',
  },
  {
    id: 'a61e53426f078659df16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46713&size=130',
  },
  {
    id: '865c70004c45a51bfc54',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46714&size=130',
  },
  {
    id: 'e4eb13b72ff2c6ac9fe3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46715&size=130',
  },
  {
    id: '4240b21c8e5967073e48',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46716&size=130',
  },
  {
    id: '8a347b68472dae73f73c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46717&size=130',
  },
  {
    id: '651b9747ab02425c1b13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46718&size=130',
  },
  {
    id: '81db72874ec2a79cfed3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46719&size=130',
  },
  {
    id: 'c345cf19f35c1a02434d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46720&size=130',
  },
  {
    id: 'fd63f03fcc7a25247c6b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46721&size=130',
  },
  {
    id: '8d2a8376bf33566d0f22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46722&size=130',
  },
  {
    id: '6a4865145951b00fe940',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46723&size=130',
  },
  {
    id: '906450386c7d8523dc6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46668&size=130',
  },
  {
    id: '75f2b4ae88eb61b538fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46669&size=130',
  },
  {
    id: 'f6e034bc08f9e1a7b8e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46670&size=130',
  },
  {
    id: '0a2cc970f5351c6b4524',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46671&size=130',
  },
  {
    id: '093cd560e925007b5934',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46672&size=130',
  },
  {
    id: 'c2f11fad23e8cab693f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46673&size=130',
  },
  {
    id: '3832e66eda2b33756a3a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46674&size=130',
  },
  {
    id: '9e3841647d21947fcd30',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46675&size=130',
  },
  {
    id: '6965b1398d7c64223d6d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46676&size=130',
  },
  {
    id: '2c94f5c8c98d20d3799c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46677&size=130',
  },
  {
    id: '02e2d8bee4fb0da554ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46678&size=130',
  },
  {
    id: '6a1ab1468d03645d3d12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46679&size=130',
  },
  {
    id: '9c9148cd74889dd6c499',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46680&size=130',
  },
  {
    id: '4f619a3da6784f261669',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46681&size=130',
  },
  {
    id: '935d45017944901ac955',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46682&size=130',
  },
  {
    id: '4542921eae5b47051e4a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46683&size=130',
  },
  {
    id: '389ce8c0d4853ddb6494',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46684&size=130',
  },
  {
    id: 'c76516392a7cc3229a6d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46685&size=130',
  },
  {
    id: 'cd911fcd2388cad69399',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46686&size=130',
  },
  {
    id: 'b566663a5a7fb321ea6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46687&size=130',
  },
  {
    id: '55942dcb118ef8d0a19f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46580&size=130',
  },
  {
    id: '6bda12852ec0c79e9ed1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46581&size=130',
  },
  {
    id: '95afeff0d3b53aeb63a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46582&size=130',
  },
  {
    id: '9d30e66fda2a33746a3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46583&size=130',
  },
  {
    id: 'b112c54df90810564919',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46584&size=130',
  },
  {
    id: '0e777b28476dae33f77c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46585&size=130',
  },
  {
    id: '04c972964ed3a78dfec2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46586&size=130',
  },
  {
    id: 'bd9ccac3f6861fd84697',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46587&size=130',
  },
  {
    id: 'eeca9e95a2d04b8e12c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46588&size=130',
  },
  {
    id: 'a153d00cec4905175c58',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46589&size=130',
  },
  {
    id: '39e34bbc77f99ea7c7e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46590&size=130',
  },
  {
    id: 'd80cab5397167e482707',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46591&size=130',
  },
  {
    id: 'a43dc062fc2715794c36',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46568&size=130',
  },
  {
    id: 'e4468119bd5c54020d4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46569&size=130',
  },
  {
    id: 'a663c03cfc7915274c68',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46570&size=130',
  },
  {
    id: 'b98bded4e2910bcf5280',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46571&size=130',
  },
  {
    id: 'd3e6b3b98ffc66a23fed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46572&size=130',
  },
  {
    id: '7a391b662723ce7d9732',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46573&size=130',
  },
  {
    id: 'dc99bec682836bdd3292',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46574&size=130',
  },
  {
    id: '0eee6db151f4b8aae1e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46575&size=130',
  },
  {
    id: 'aee7d2b8eefd07a35eec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46576&size=130',
  },
  {
    id: '218e5cd1609489cad085',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46577&size=130',
  },
  {
    id: 'af23d17ced3904675d28',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46578&size=130',
  },
  {
    id: '152d6a725637bf69e626',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46579&size=130',
  },
  {
    id: 'c70c9753ab1642481b07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46556&size=130',
  },
  {
    id: '9bd2ca8df6c81f9646d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46557&size=130',
  },
  {
    id: '318063df5f9ab6c4ef8b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46558&size=130',
  },
  {
    id: 'cd0e9e51a2144b4a1205',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46559&size=130',
  },
  {
    id: 'e0958ccab08f59d1009e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46560&size=130',
  },
  {
    id: '048169de559bbcc5e58a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46561&size=130',
  },
  {
    id: '92effcb0c0f529ab70e4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46562&size=130',
  },
  {
    id: '1c9773c84f8da6d3ff9c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46563&size=130',
  },
  {
    id: 'c875a02a9c6f75312c7e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46564&size=130',
  },
  {
    id: '0d266479583cb162e82d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46565&size=130',
  },
  {
    id: '4cd3268c1ac9f397aad8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46566&size=130',
  },
  {
    id: '423929661523fc7da532',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46567&size=130',
  },
  {
    id: 'a31e9f41a3044a5a1315',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46512&size=130',
  },
  {
    id: 'a5ed98b2a4f74da914e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46513&size=130',
  },
  {
    id: '90c3ae9c92d97b8722c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46514&size=130',
  },
  {
    id: '7d4f42107e55970bce44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46515&size=130',
  },
  {
    id: '931bab4497017e5f2710',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46516&size=130',
  },
  {
    id: '446b7d344171a82ff160',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46517&size=130',
  },
  {
    id: '5b43611c5d59b407ed48',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46518&size=130',
  },
  {
    id: '98dfa3809fc5769b2fd4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46519&size=130',
  },
  {
    id: '211c15432906c0589917',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46520&size=130',
  },
  {
    id: 'e1d4d48be8ce019058df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46521&size=130',
  },
  {
    id: 'cc9dfac2c6872fd97696',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46522&size=130',
  },
  {
    id: 'bab28dedb1a858f601b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46523&size=130',
  },
  {
    id: 'dfcecb91f7d41e8a47c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46488&size=130',
  },
  {
    id: '018814d72892c1cc9883',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46489&size=130',
  },
  {
    id: '7225647a583fb161e82e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46490&size=130',
  },
  {
    id: '71d966865ac3b39dead2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46491&size=130',
  },
  {
    id: 'abdfbb8087c56e9b37d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46492&size=130',
  },
  {
    id: 'd4cdc592f9d7108949c6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46493&size=130',
  },
  {
    id: 'f95aeb05d7403e1e6751',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46494&size=130',
  },
  {
    id: '1ba808f734b2ddec84a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46495&size=130',
  },
  {
    id: '9a3cb6638a2663783a37',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46496&size=130',
  },
  {
    id: '1c31316e0d2be475bd3a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46497&size=130',
  },
  {
    id: '899fa7c09b8572db2b94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46498&size=130',
  },
  {
    id: 'b9b596eaaaaf43f11abe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46499&size=130',
  },
  {
    id: 'fa0d06523a17d3498a06',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46448&size=130',
  },
  {
    id: 'c9df348008c5e19bb8d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46449&size=130',
  },
  {
    id: 'ee75102a2c6fc5319c7e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46450&size=130',
  },
  {
    id: '3b16c449f80c1152481d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46451&size=130',
  },
  {
    id: 'b6374e68722d9b73c23c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46452&size=130',
  },
  {
    id: 'a5b35cec60a989f7d0b8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46453&size=130',
  },
  {
    id: 'f6b80ce730a2d9fc80b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46454&size=130',
  },
  {
    id: '7dea86b5baf053ae0ae1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46455&size=130',
  },
  {
    id: 'ee8a1ad52690cfce9681',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46456&size=130',
  },
  {
    id: '25c8d097ecd2058c5cc3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46457&size=130',
  },
  {
    id: '26a1d0feecbb05e55caa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46458&size=130',
  },
  {
    id: 'dccc2b9317d6fe88a7c7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46459&size=130',
  },
  {
    id: 'bb434b1c77599e07c748',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46460&size=130',
  },
  {
    id: '137fe220de65373b6e74',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46461&size=130',
  },
  {
    id: 'eac2189d24d8cd8694c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46462&size=130',
  },
  {
    id: '99a66af956bcbfe2e6ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46463&size=130',
  },
  {
    id: '2a0d26521a17f349aa06',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46464&size=130',
  },
  {
    id: '7e3773684f2da673ff3c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46465&size=130',
  },
  {
    id: '8c52820dbe4857160e59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46466&size=130',
  },
  {
    id: '6805675a5b1fb241eb0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46467&size=130',
  },
  {
    id: '45e3ebbcd7f93ea767e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46370&size=130',
  },
  {
    id: '3bd79488a8cd419318dc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46371&size=130',
  },
  {
    id: 'abbc03e33fa6d6f88fb7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46372&size=130',
  },
  {
    id: 'c4726d2d5168b836e179',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46373&size=130',
  },
  {
    id: '45daef85d3c03a9e63d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46374&size=130',
  },
  {
    id: '889923c61f83f6ddaf92',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46375&size=130',
  },
  {
    id: 'dab27eed42a8abf6f2b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46376&size=130',
  },
  {
    id: 'f021557e693b8065d92a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46377&size=130',
  },
  {
    id: '8f8e29d11594fccaa585',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46378&size=130',
  },
  {
    id: '08bfafe093a57afb23b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46379&size=130',
  },
  {
    id: 'aacb0a9436d1df8f86c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46380&size=130',
  },
  {
    id: '461de742db0732596b16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46381&size=130',
  },
  {
    id: '017ba3249f61763f2f70',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46382&size=130',
  },
  {
    id: '126cb1338d7664283d67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46383&size=130',
  },
  {
    id: '65b0d9efe5aa0cf455bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46384&size=130',
  },
  {
    id: '9eb223ed1fa8f6f6afb9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46385&size=130',
  },
  {
    id: 'ea7e54216864813ad875',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46386&size=130',
  },
  {
    id: '5e96e1c9dd8c34d26d9d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46387&size=130',
  },
  {
    id: '76b8cee7f2a21bfc42b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46388&size=130',
  },
  {
    id: '8a0a33550f10e64ebf01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46389&size=130',
  },
  {
    id: '2023a87c94397d672428',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46340&size=130',
  },
  {
    id: 'c5344c6b702e9970c03f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46341&size=130',
  },
  {
    id: 'bc8c36d30a96e3c8ba87',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46342&size=130',
  },
  {
    id: '11de9a81a6c44f9a16d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46343&size=130',
  },
  {
    id: '203fa4609825717b2834',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46344&size=130',
  },
  {
    id: '378ab2d58e9067ce3e81',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46345&size=130',
  },
  {
    id: 'bc4c3a130656ef08b647',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46346&size=130',
  },
  {
    id: '5442d31def5806065f49',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46347&size=130',
  },
  {
    id: '630de352df1736496f06',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46348&size=130',
  },
  {
    id: '8c130d4c3109d8578118',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46349&size=130',
  },
  {
    id: '6439e666da23337d6a32',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46350&size=130',
  },
  {
    id: 'a103225c1e19f747ae08',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46351&size=130',
  },
  {
    id: '62e01ebe22fbcba592ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46320&size=130',
  },
  {
    id: '2a13574d6b088256db19',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46321&size=130',
  },
  {
    id: 'dc52a20c9e4977172e58',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46322&size=130',
  },
  {
    id: 'a800d75eeb1b02455b0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46323&size=130',
  },
  {
    id: '1c0f64515814b14ae805',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46324&size=130',
  },
  {
    id: 'eb42921cae5947071e48',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46325&size=130',
  },
  {
    id: '71a00bfe37bbdee587aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46326&size=130',
  },
  {
    id: '8a96f1c8cd8d24d37d9c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46327&size=130',
  },
  {
    id: '5fed2bb317f6fea8a7e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46328&size=130',
  },
  {
    id: '6dcf189124d4cd8a94c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46329&size=130',
  },
  {
    id: '39b44fea73af9af1c3be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46330&size=130',
  },
  {
    id: '3a714d2f716a9834c17b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46331&size=130',
  },
  {
    id: '83dff381cfc4269a7fd5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46332&size=130',
  },
  {
    id: 'a2eed3b0eff506ab5fe4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46333&size=130',
  },
  {
    id: '22c1509f6cda8584dccb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46334&size=130',
  },
  {
    id: 'dcf7afa993ec7ab223fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46335&size=130',
  },
  {
    id: '0fc4839bbfde56800fcf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46336&size=130',
  },
  {
    id: '7887f5d8c99d20c3798c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46337&size=130',
  },
  {
    id: '98fa16a52ae0c3be9af1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46338&size=130',
  },
  {
    id: 'e2846ddb519eb8c0e18f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46339&size=130',
  },
  {
    id: '0bf44baa77ef9eb1c7fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46284&size=130',
  },
  {
    id: '44ae05f039b5d0eb89a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46285&size=130',
  },
  {
    id: '321e70404c05a55bfc14',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46286&size=130',
  },
  {
    id: 'a6bde5e3d9a630f869b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46287&size=130',
  },
  {
    id: '59c4059a39dfd08189ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46288&size=130',
  },
  {
    id: '3f6f62315e74b72aee65',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46289&size=130',
  },
  {
    id: 'c2a99cf7a0b249ec10a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46290&size=130',
  },
  {
    id: 'afc0f09eccdb25857cca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46291&size=130',
  },
  {
    id: '9651ce0ff24a1b14425b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46292&size=130',
  },
  {
    id: 'ef34b66a8a2f63713a3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46293&size=130',
  },
  {
    id: '041d5e4362068b58d217',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46294&size=130',
  },
  {
    id: '2bdf70814cc4a59afcd5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46295&size=130',
  },
  {
    id: '30e064be58fbb1a5e8ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46296&size=130',
  },
  {
    id: '336c66325a77b329ea66',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46297&size=130',
  },
  {
    id: '23cb759549d0a08ef9c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46298&size=130',
  },
  {
    id: '1a3f4d617124987ac135',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46299&size=130',
  },
  {
    id: 'fd5ae804d4413d1f6450',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46233&size=130',
  },
  {
    id: '5f45491b755e9c00c54f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46234&size=130',
  },
  {
    id: 'd652c10cfd4914174d58',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46235&size=130',
  },
  {
    id: '15ad05f339b6d0e889a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46236&size=130',
  },
  {
    id: '7323627d5e38b766ee29',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46237&size=130',
  },
  {
    id: '8c179e49a20c4b52121d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46238&size=130',
  },
  {
    id: '4b8b58d564908dced481',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46239&size=130',
  },
  {
    id: 'b24e9e10a2554b0b1244',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46240&size=130',
  },
  {
    id: 'c895e5cbd98e30d0699f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46241&size=130',
  },
  {
    id: 'd134ff6ac32f2a71733e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46242&size=130',
  },
  {
    id: 'bb60943ea87b4125186a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46243&size=130',
  },
  {
    id: 'e128c976f5331c6d4522',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46244&size=130',
  },
  {
    id: '1f2636780a3de363ba2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46245&size=130',
  },
  {
    id: '9769bd378172682c3163',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46246&size=130',
  },
  {
    id: 'b5389e66a2234b7d1232',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46247&size=130',
  },
  {
    id: '580f7c514014a94af005',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46248&size=130',
  },
  {
    id: '588f5dd1619488cad185',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46217&size=130',
  },
  {
    id: 'ca5bcc05f040191e4051',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46218&size=130',
  },
  {
    id: '686b6f355370ba2ee361',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46219&size=130',
  },
  {
    id: 'f31af344cf01265f7f10',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46220&size=130',
  },
  {
    id: '5dc85c9660d3898dd0c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46221&size=130',
  },
  {
    id: 'ebc5e99bd5de3c8065cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46222&size=130',
  },
  {
    id: 'e89eebc0d7853edb6794',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46223&size=130',
  },
  {
    id: '92cd8e93b2d65b8802c7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46224&size=130',
  },
  {
    id: '2460393e057bec25b56a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46225&size=130',
  },
  {
    id: '0e40101e2c5bc5059c4a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46226&size=130',
  },
  {
    id: '04871bd9279ccec2978d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46227&size=130',
  },
  {
    id: 'a5a4bdfa81bf68e131ae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46228&size=130',
  },
  {
    id: 'd93bc065fc20157e4c31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46229&size=130',
  },
  {
    id: '01cc1b9227d7ce8997c6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46230&size=130',
  },
  {
    id: '41f95aa766e28fbcd6f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46231&size=130',
  },
  {
    id: '01f015ae29ebc0b599fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46232&size=130',
  },
  {
    id: '5885addb919e78c0218f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46201&size=130',
  },
  {
    id: '350ec350ff15164b4f04',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46202&size=130',
  },
  {
    id: '97c1609f5cdab584eccb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46203&size=130',
  },
  {
    id: 'aff45faa63ef8ab1d3fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46204&size=130',
  },
  {
    id: 'fe050f5b331eda40830f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46205&size=130',
  },
  {
    id: 'bfd54d8b71ce9890c1df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46206&size=130',
  },
  {
    id: 'cee73db901fce8a2b1ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46207&size=130',
  },
  {
    id: 'ef5ae304df41361f6f50',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46208&size=130',
  },
  {
    id: 'ad80a0de9c9b75c52c8a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46209&size=130',
  },
  {
    id: '0dbe03e03fa5d6fb8fb4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46210&size=130',
  },
  {
    id: '212f2e711234fb6aa225',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46211&size=130',
  },
  {
    id: 'cb28c376ff33166d4f22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46212&size=130',
  },
  {
    id: 'fac3f39dcfd826867fc9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46213&size=130',
  },
  {
    id: 'd7a2ddfce1b908e751a8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46214&size=130',
  },
  {
    id: 'b0b5bbeb87ae6ef037bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46215&size=130',
  },
  {
    id: '6be96fb753f2baace3e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46216&size=130',
  },
  {
    id: '0f7fe221de64373a6e75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46177&size=130',
  },
  {
    id: '155efb00c7452e1b7754',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46178&size=130',
  },
  {
    id: 'ef53000d3c48d5168c59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46179&size=130',
  },
  {
    id: 'a59e4dc0718598dbc194',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46180&size=130',
  },
  {
    id: '2187c8d9f49c1dc2448d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46181&size=130',
  },
  {
    id: '67a78df9b1bc58e201ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46182&size=130',
  },
  {
    id: '3cd7d789ebcc02925bdd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46183&size=130',
  },
  {
    id: 'a8154c4b700e9950c01f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46184&size=130',
  },
  {
    id: '764e9310af55460b1f44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46185&size=130',
  },
  {
    id: '7153970dab4842161b59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46186&size=130',
  },
  {
    id: '1375f42bc86e2130787f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46187&size=130',
  },
  {
    id: '3d65dd3be17e0820516f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46188&size=130',
  },
  {
    id: 'd43935670922e07cb933',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46189&size=130',
  },
  {
    id: 'd9123b4c0709ee57b718',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46190&size=130',
  },
  {
    id: '800763595f1cb642ef0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46191&size=130',
  },
  {
    id: '3941c51ff95a1004494b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46192&size=130',
  },
  {
    id: 'f95a04043841d11f8850',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46193&size=130',
  },
  {
    id: 'f80d06533a16d3488a07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46194&size=130',
  },
  {
    id: 'c30e3c500015e94bb004',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46195&size=130',
  },
  {
    id: 'dbc5239b1fdef680afcf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46196&size=130',
  },
  {
    id: '3b34c26afe2f17714e3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46197&size=130',
  },
  {
    id: '0c5bf605ca40231e7a51',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46198&size=130',
  },
  {
    id: 'cc0937570b12e24cbb03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46199&size=130',
  },
  {
    id: '289cdcc2e08709d95096',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46200&size=130',
  },
  {
    id: '070fa95195147c4a2505',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46114&size=130',
  },
  {
    id: '7aafd5f1e9b400ea59a5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46115&size=130',
  },
  {
    id: 'b3c11b9f27dace8497cb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46116&size=130',
  },
  {
    id: '28f981a7bde254bc0df3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46117&size=130',
  },
  {
    id: 'e2e148bf74fa9da4c4eb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46118&size=130',
  },
  {
    id: 'fdd756896acc8392dadd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46119&size=130',
  },
  {
    id: 'cd45691b555ebc00e54f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46120&size=130',
  },
  {
    id: 'cbe76eb952fcbba2e2ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46121&size=130',
  },
  {
    id: '2cbd8ae3b6a65ff806b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46122&size=130',
  },
  {
    id: '75d5d28beece07905edf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46123&size=130',
  },
  {
    id: '4e6dee33d2763b286267',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46124&size=130',
  },
  {
    id: 'a4c3059d39d8d08689c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46125&size=130',
  },
  {
    id: 'c49e66c05a85b3dbea94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46126&size=130',
  },
  {
    id: 'b450170e2b4bc2159b5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46127&size=130',
  },
  {
    id: '06d8ba8686c36f9d36d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46128&size=130',
  },
  {
    id: '82b53feb03aeeaf0b3bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46129&size=130',
  },
  {
    id: 'b2f80ca630e3d9bd80f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46130&size=130',
  },
  {
    id: '96f229ac15e9fcb7a5f8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46131&size=130',
  },
  {
    id: 'c6347e6a422fab71f23e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46132&size=130',
  },
  {
    id: 'f7c34e9d72d89b86c2c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46133&size=130',
  },
  {
    id: 'e6f35cad60e889b6d0f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46134&size=130',
  },
  {
    id: '647ddf23e3660a385377',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46135&size=130',
  },
  {
    id: 'e9075d59611c8842d10d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46136&size=130',
  },
  {
    id: '2a1f9f41a3044a5a1315',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46137&size=130',
  },
  {
    id: '20f9a8a794e27dbc24f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46084&size=130',
  },
  {
    id: '4fc6c698fadd13834acc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46085&size=130',
  },
  {
    id: '979f1dc12184c8da9195',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46086&size=130',
  },
  {
    id: '383ab3648f21667f3f30',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46087&size=130',
  },
  {
    id: 'c22a46747a31936fca20',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46088&size=130',
  },
  {
    id: 'ed8a68d45491bdcfe480',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46089&size=130',
  },
  {
    id: '3cfebaa086e56fbb36f4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46090&size=130',
  },
  {
    id: 'c4ed43b37ff696a8cfe7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46091&size=130',
  },
  {
    id: '181f9841a4044d5a1415',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46092&size=130',
  },
  {
    id: 'c13940677c22957ccc33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46093&size=130',
  },
  {
    id: '9a0b18552410cd4e9401',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46094&size=130',
  },
  {
    id: '8b8e08d03495ddcb8484',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46095&size=130',
  },
  {
    id: '2d13b14d8d0864563d19',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46096&size=130',
  },
  {
    id: '0cbb91e5ada044fe1db1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46097&size=130',
  },
  {
    id: '5b3dc563f92610784937',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46098&size=130',
  },
  {
    id: '4b8ad4d4e89101cf5880',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46099&size=130',
  },
  {
    id: '0d9a95c4a98140df1990',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46100&size=130',
  },
  {
    id: '4efad7a4ebe102bf5bf0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46101&size=130',
  },
  {
    id: '27a06ff953bcbae2e3ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46020&size=130',
  },
  {
    id: 'b624ff7dc3382a667329',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46021&size=130',
  },
  {
    id: 'f9deb3878fc2669c3fd3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46022&size=130',
  },
  {
    id: '4932026b3e2ed7708e3f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46023&size=130',
  },
  {
    id: 'e936ad6f912a7874213b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46024&size=130',
  },
  {
    id: 'e847ad1e915b7805214a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46025&size=130',
  },
  {
    id: '044c42157e50970ece41',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46026&size=130',
  },
  {
    id: '954ed217ee52070c5e43',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46027&size=130',
  },
  {
    id: 'c69a86c3ba8653d80a97',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46028&size=130',
  },
  {
    id: '53ad12f42eb1c7ef9ea0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46029&size=130',
  },
  {
    id: '778b35d20997e0c9b986',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46030&size=130',
  },
  {
    id: '07a744fe78bb91e5c8aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=46031&size=130',
  },
  {
    id: '98a394faa8bf41e118ae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45952&size=130',
  },
  {
    id: '37a23afb06beefe0b6af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45953&size=130',
  },
  {
    id: '1674182d2468cd369479',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45954&size=130',
  },
  {
    id: '3e9931c00d85e4dbbd94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45955&size=130',
  },
  {
    id: '87308f69b32c5a72033d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45956&size=130',
  },
  {
    id: '095d00043c41d51f8c50',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45957&size=130',
  },
  {
    id: 'cb0cc155fd10144e4d01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45958&size=130',
  },
  {
    id: '6d04665d5a18b346ea09',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45959&size=130',
  },
  {
    id: 'ee93eacad68f3fd1669e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45960&size=130',
  },
  {
    id: '5d005859641c8d42d40d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45961&size=130',
  },
  {
    id: 'b89abec382866bd83297',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45962&size=130',
  },
  {
    id: 'ef6ee837d4723d2c6463',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45963&size=130',
  },
  {
    id: 'ed4ded14d151380f6140',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45964&size=130',
  },
  {
    id: 'e364e23dde7837266e69',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45965&size=130',
  },
  {
    id: '2c562e0f124afb14a25b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45966&size=130',
  },
  {
    id: '88368b6fb72a5e74073b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45967&size=130',
  },
  {
    id: '097915202965c03b9974',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45968&size=130',
  },
  {
    id: 'febce3e5dfa036fe6fb1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45969&size=130',
  },
  {
    id: 'cca1d2f8eebd07e35eac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45970&size=130',
  },
  {
    id: '2c15334c0f09e657bf18',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45971&size=130',
  },
  {
    id: 'f234ea6dd6283f766639',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45972&size=130',
  },
  {
    id: '0ad3138a2fcfc6919fde',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45973&size=130',
  },
  {
    id: 'fc18e641da04335a6a15',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45974&size=130',
  },
  {
    id: 'f780ecd9d09c39c2608d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45975&size=130',
  },
  {
    id: '80de7d8741c2a89cf1d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45937&size=130',
  },
  {
    id: 'a1935fca638f8ad1d39e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45938&size=130',
  },
  {
    id: 'fc8003d93f9cd6c28f8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45939&size=130',
  },
  {
    id: 'f26c0a353670df2e8661',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45940&size=130',
  },
  {
    id: '09d7f08ecccb25957cda',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45941&size=130',
  },
  {
    id: 'cf113548090de053b91c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45942&size=130',
  },
  {
    id: '12b8e9e1d5a43cfa65b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45943&size=130',
  },
  {
    id: '84bf70e64ca3a5fdfcb2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45944&size=130',
  },
  {
    id: '3068c531f974102a4965',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45945&size=130',
  },
  {
    id: 'f02906703a35d36b8a24',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45946&size=130',
  },
  {
    id: '88cf7f9643d3aa8df3c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45947&size=130',
  },
  {
    id: '3af4caadf6e81fb646f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45948&size=130',
  },
  {
    id: '35b0c4e9f8ac11f248bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45949&size=130',
  },
  {
    id: '0164f33dcf7826267f69',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45950&size=130',
  },
  {
    id: '8d547e0d4248ab16f259',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45951&size=130',
  },
  {
    id: '86d143887fcd9693cfdc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45897&size=130',
  },
  {
    id: '7ee4b8bd84f86da634e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45898&size=130',
  },
  {
    id: 'a65f61065d43b41ded52',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45899&size=130',
  },
  {
    id: '09c3c99af5df1c8145ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45900&size=130',
  },
  {
    id: '4964883db4785d260469',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45901&size=130',
  },
  {
    id: '7ebebce780a269fc30b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45902&size=130',
  },
  {
    id: '34dcf785cbc0229e7bd1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45903&size=130',
  },
  {
    id: '581e8447b802515c0813',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45904&size=130',
  },
  {
    id: '225cff05c3402a1e7351',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45905&size=130',
  },
  {
    id: 'ba8764de589bb1c5e88a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45906&size=130',
  },
  {
    id: '2200fd59c11c2842710d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45907&size=130',
  },
  {
    id: 'cf6f17362b73c22d9b62',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45908&size=130',
  },
  {
    id: 'd917004e3c0bd5558c1a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45909&size=130',
  },
  {
    id: '42d99880a4c54d9b14d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45910&size=130',
  },
  {
    id: '3f9ee4c7d88231dc6893',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45911&size=130',
  },
  {
    id: '3225e67cda3933676a28',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45912&size=130',
  },
  {
    id: 'aaf97fa043e5aabbf3f4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45913&size=130',
  },
  {
    id: 'a9f87fa143e4aabaf3f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45914&size=130',
  },
  {
    id: '85b052e96eac87f2debd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45915&size=130',
  },
  {
    id: 'b0e260bb5cfeb5a0ecef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45916&size=130',
  },
  {
    id: '03a2aefb92be7be022af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45857&size=130',
  },
  {
    id: '3a63943aa87f4121186e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45858&size=130',
  },
  {
    id: '0868a7319b74722a2b65',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45859&size=130',
  },
  {
    id: 'bb8c13d52f90c6ce9f81',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45860&size=130',
  },
  {
    id: 'c0dd698455c1bc9fe5d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45861&size=130',
  },
  {
    id: 'e349491075559c0bc544',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45862&size=130',
  },
  {
    id: '97583c010044e91ab055',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45863&size=130',
  },
  {
    id: 'b24b16122a57c3099a46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45864&size=130',
  },
  {
    id: 'c85e6d075142b81ce153',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45865&size=130',
  },
  {
    id: '7b5add03e14608185157',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45866&size=130',
  },
  {
    id: 'b80a1f532316ca489307',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45867&size=130',
  },
  {
    id: '72eed2b7eef207ac5ee3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45868&size=130',
  },
  {
    id: '4f25ee7cd2393b676228',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45869&size=130',
  },
  {
    id: '9026327f0e3ae764be2b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45870&size=130',
  },
  {
    id: 'da7879214564ac3af575',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45871&size=130',
  },
  {
    id: 'a52a19732536cc689527',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45872&size=130',
  },
  {
    id: '6d34d06dec2805765c39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45873&size=130',
  },
  {
    id: '0526bb7f873a6e64372b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45874&size=130',
  },
  {
    id: 'b29d0dc43181d8df8190',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45875&size=130',
  },
  {
    id: '9dc5259c19d9f087a9c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45876&size=130',
  },
  {
    id: 'f33e4a6776229f7cc633',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45877&size=130',
  },
  {
    id: '0179bb2087656e3b3774',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45878&size=130',
  },
  {
    id: '6dd9d680eac5039b5ad4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45879&size=130',
  },
  {
    id: '8a763e2f026aeb34b27b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45880&size=130',
  },
  {
    id: '055c8805b4405d1e0451',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45825&size=130',
  },
  {
    id: '5103df5ae31f0a41530e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45826&size=130',
  },
  {
    id: '57bfd8e6e4a30dfd54b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45827&size=130',
  },
  {
    id: 'f042781b445ead00f44f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45828&size=130',
  },
  {
    id: '3394bacd86886fd63699',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45829&size=130',
  },
  {
    id: '88fc02a53ee0d7be8ef1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45830&size=130',
  },
  {
    id: '74ebffb2c3f72aa973e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45831&size=130',
  },
  {
    id: 'f6f272ab4eeea7b0feff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45832&size=130',
  },
  {
    id: '60e3e5bad9ff30a169ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45833&size=130',
  },
  {
    id: 'b9c53f9c03d9ea87b3c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45834&size=130',
  },
  {
    id: '04d4838dbfc856960fd9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45835&size=130',
  },
  {
    id: 'cdf04da971ec98b2c1fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45836&size=130',
  },
  {
    id: 'c1f340aa7cef95b1ccfe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45837&size=130',
  },
  {
    id: '307ab2238e6667383e77',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45838&size=130',
  },
  {
    id: '12319168ad2d44731d3c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45839&size=130',
  },
  {
    id: 'bfbb23e21fa7f6f9afb6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45840&size=130',
  },
  {
    id: 'e6ffa3a79fe276bc2ff3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45769&size=130',
  },
  {
    id: '98a8def0e2b50beb52a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45770&size=130',
  },
  {
    id: '09af4ef772b29becc2a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45771&size=130',
  },
  {
    id: 'da519a09a64c4f12165d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45772&size=130',
  },
  {
    id: '38f879a045e5acbbf5f4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45773&size=130',
  },
  {
    id: '6f8d2dd51190f8cea181',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45774&size=130',
  },
  {
    id: '16ba55e269a780f9d9b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45775&size=130',
  },
  {
    id: 'd1438d1bb15e5800014f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45776&size=130',
  },
  {
    id: 'ff99a2c19e8477da2e95',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45777&size=130',
  },
  {
    id: '30b36eeb52aebbf0e2bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45778&size=130',
  },
  {
    id: '7f4120191c5cf502ac4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45779&size=130',
  },
  {
    id: 'b7b2efead3af3af163be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45780&size=130',
  },
  {
    id: 'cb5e9206ae43471d1e52',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45781&size=130',
  },
  {
    id: '7b5f21071d42f41cad53',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45782&size=130',
  },
  {
    id: '9a1fc147fd02145c4d13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45783&size=130',
  },
  {
    id: 'fe43aa1b965e7f00264f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45784&size=130',
  },
  {
    id: 'a7fb9aa3a6e64fb816f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45745&size=130',
  },
  {
    id: '7e7d40257c60953ecc71',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45746&size=130',
  },
  {
    id: '01743e2c0269eb37b278',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45747&size=130',
  },
  {
    id: '1c55240d1848f116a859',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45748&size=130',
  },
  {
    id: '72fd4ba577e09ebec7f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45749&size=130',
  },
  {
    id: 'c1fefba6c7e32ebd77f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45750&size=130',
  },
  {
    id: '9c3ea7669b23727d2b32',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45751&size=130',
  },
  {
    id: 'ee78da20e6650f3b5674',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45752&size=130',
  },
  {
    id: '43b576ed4aa8a3f6fab9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45753&size=130',
  },
  {
    id: '71fa47a27be792b9cbf6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45754&size=130',
  },
  {
    id: 'f1c2c69afadf13814ace',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45755&size=130',
  },
  {
    id: 'd2e1e2b9defc37a26eed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45756&size=130',
  },
  {
    id: '878eaed692937bcd2282',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45733&size=130',
  },
  {
    id: 'e940c318ff5d16034f4c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45734&size=130',
  },
  {
    id: '2cab07f33bb6d2e88ba7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45735&size=130',
  },
  {
    id: '6bb04fe873ad9af3c3bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45736&size=130',
  },
  {
    id: 'a8158d4db10858560119',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45737&size=130',
  },
  {
    id: '40df66875ac2b39cead3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45738&size=130',
  },
  {
    id: 'adc38a9bb6de5f8006cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45739&size=130',
  },
  {
    id: '65dd458579c0909ec9d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45740&size=130',
  },
  {
    id: 'd8d7f98fc5ca2c9475db',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45741&size=130',
  },
  {
    id: 'd300f158cd1d24437d0c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45742&size=130',
  },
  {
    id: '84e5a7bd9bf872a62be9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45743&size=130',
  },
  {
    id: '0a8536dd0a98e3c6ba89',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45744&size=130',
  },
  {
    id: '536ffa37c6722f2c7663',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45605&size=130',
  },
  {
    id: 'b89012c82e8dc7d39e9c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45606&size=130',
  },
  {
    id: '0135aa6d96287f762639',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45607&size=130',
  },
  {
    id: '2b268f7eb33b5a65032a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45608&size=130',
  },
  {
    id: 'c0f765af59eab0b4e9fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45609&size=130',
  },
  {
    id: '9e12384a040fed51b41e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45610&size=130',
  },
  {
    id: '3a129d4aa10f4851111e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45611&size=130',
  },
  {
    id: 'bc2f1c772032c96c9023',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45612&size=130',
  },
  {
    id: '21fd80a5bce055be0cf1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45613&size=130',
  },
  {
    id: 'd36171394d7ca422fd6d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45614&size=130',
  },
  {
    id: 'ae0c0d543111d84f8100',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45615&size=130',
  },
  {
    id: 'c3f07fa843edaab3f3fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45616&size=130',
  },
  {
    id: '8ff132a90eece7b2befd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45617&size=130',
  },
  {
    id: '7873c62bfa6e13304a7f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45618&size=130',
  },
  {
    id: 'af4910112c54c50a9c45',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45619&size=130',
  },
  {
    id: '21bb99e3a5a64cf815b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45620&size=130',
  },
  {
    id: 'f6d96f8153c4ba9ae3d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45589&size=130',
  },
  {
    id: 'c1185b4067058e5bd714',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45590&size=130',
  },
  {
    id: '0edb9583a9c6409819d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45591&size=130',
  },
  {
    id: 'acc1389904dced82b4cd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45592&size=130',
  },
  {
    id: '06a993f1afb446ea1fa5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45593&size=130',
  },
  {
    id: 'bbf92da111e4f8baa1f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45594&size=130',
  },
  {
    id: '3d46aa1e965b7f05264a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45595&size=130',
  },
  {
    id: '90a800f03cb5d5eb8ca4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45596&size=130',
  },
  {
    id: '06d4978cabc942971bd8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45597&size=130',
  },
  {
    id: '37faa5a299e770b929f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45598&size=130',
  },
  {
    id: '2506b65e8a1b63453a0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45599&size=130',
  },
  {
    id: '416ced34d171382f6160',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45600&size=130',
  },
  {
    id: '0224af7c93397a672328',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45601&size=130',
  },
  {
    id: '6f58c100fd45141b4d54',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45602&size=130',
  },
  {
    id: '94f63bae07ebeeb5b7fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45603&size=130',
  },
  {
    id: '648accd2f09719c94086',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45604&size=130',
  },
  {
    id: 'ed1a60425c07b559ec16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45569&size=130',
  },
  {
    id: '0a02845ab81f5141080e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45570&size=130',
  },
  {
    id: 'dc2b53736f368668df27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45571&size=130',
  },
  {
    id: 'f4727c2a406fa931f07e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45572&size=130',
  },
  {
    id: '06ad8ff5b3b05aee03a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45573&size=130',
  },
  {
    id: '61cceb94d7d13e8f67c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45574&size=130',
  },
  {
    id: '9ac7119f2ddac4849dcb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45575&size=130',
  },
  {
    id: '7b0fff57c3122a4c7303',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45576&size=130',
  },
  {
    id: 'db995ec162848bdad295',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45577&size=130',
  },
  {
    id: 'a20f24571812f14ca803',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45578&size=130',
  },
  {
    id: '88d60f8e33cbda9583da',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45579&size=130',
  },
  {
    id: 'f4b674ee48aba1f5f8ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45580&size=130',
  },
  {
    id: 'f0e271ba4dffa4a1fdee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45581&size=130',
  },
  {
    id: 'a29020c81c8df5d3ac9c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45582&size=130',
  },
  {
    id: '60e8e3b0dff536ab6fe4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45583&size=130',
  },
  {
    id: '541cc844f4011d5f4410',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45584&size=130',
  },
  {
    id: 'da6147397b7c9222cb6d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45585&size=130',
  },
  {
    id: 'c4c55a9d66d88f86d6c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45586&size=130',
  },
  {
    id: '06f699aea5eb4cb515fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45587&size=130',
  },
  {
    id: '03259b7da7384e661729',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45588&size=130',
  },
  {
    id: '1777d3feefbb06e55faa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25416&size=130',
  },
  {
    id: '94dc51556d10844edd01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25417&size=130',
  },
  {
    id: 'baa77c2e406ba935f07a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25418&size=130',
  },
  {
    id: '2101e688dacd33936adc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25419&size=130',
  },
  {
    id: '3739f7b0cbf522ab7be4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25420&size=130',
  },
  {
    id: '69a7a82e946b7d35247a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25421&size=130',
  },
  {
    id: '88264aaf76ea9fb4c6fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25422&size=130',
  },
  {
    id: 'ba9d79144551ac0ff540',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25423&size=130',
  },
  {
    id: '6c4eb0c78c8265dc3c93',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25424&size=130',
  },
  {
    id: 'aaf077794b3ca262fb2d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25425&size=130',
  },
  {
    id: '2858f6d1ca9423ca7a85',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25426&size=130',
  },
  {
    id: 'd1020e8b32cedb9082df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25427&size=130',
  },
  {
    id: '4c109499a8dc418218cd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25428&size=130',
  },
  {
    id: 'fab6233f1f7af624af6b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25429&size=130',
  },
  {
    id: '2986f30fcf4a26147f5b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25430&size=130',
  },
  {
    id: 'ebe4306d0c28e576bc39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25431&size=130',
  },
  {
    id: 'a71b73924fd7a689ffc6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25432&size=130',
  },
  {
    id: '2a80ff09c34c2a12735d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25433&size=130',
  },
  {
    id: 'c5d3135a2f1fc6419f0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25434&size=130',
  },
  {
    id: '1609c180fdc5149b4dd4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25435&size=130',
  },
  {
    id: 'dc190c9030d5d98b80c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25436&size=130',
  },
  {
    id: '79f2a87b943e7d60242f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25437&size=130',
  },
  {
    id: '21838f0ab34f5a11035e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25378&size=130',
  },
  {
    id: 'ee2141a87ded94b3cdfc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25379&size=130',
  },
  {
    id: '933c3bb507f0eeaeb7e1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25380&size=130',
  },
  {
    id: 'a4cd0d443101d85f8110',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25381&size=130',
  },
  {
    id: '825928d01495fdcba484',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25382&size=130',
  },
  {
    id: 'b95a12d32e96c7c89e87',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25383&size=130',
  },
  {
    id: '4f0beb82d7c73e9967d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25384&size=130',
  },
  {
    id: '9ea53b2c0769ee37b778',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25385&size=130',
  },
  {
    id: '4f20e9a9d5ec3cb265fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25386&size=130',
  },
  {
    id: 'e55642df7e9a97c4ce8b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25387&size=130',
  },
  {
    id: 'b1fc11752d30c46e9d21',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25388&size=130',
  },
  {
    id: '987d39f405b1ecefb5a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25389&size=130',
  },
  {
    id: '6553c7dafb9f12c14b8e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25390&size=130',
  },
  {
    id: 'e0e2436b7f2e9670cf3f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25391&size=130',
  },
  {
    id: '90282ca110e4f9baa0f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25392&size=130',
  },
  {
    id: '4016fd9fc1da288471cb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25393&size=130',
  },
  {
    id: '5631e8b8d4fd3da364ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25394&size=130',
  },
  {
    id: '01f7be7e823b6b65322a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25395&size=130',
  },
  {
    id: '92d62a5f161aff44a60b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25396&size=130',
  },
  {
    id: '4b06f28fceca27947edb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25397&size=130',
  },
  {
    id: 'a47c1ef522b0cbee92a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25398&size=130',
  },
  {
    id: '16e6ad6f912a7874213b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25399&size=130',
  },
  {
    id: '80c7344e080be155b81a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25400&size=130',
  },
  {
    id: '0487b10e8d4b64153d5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25401&size=130',
  },
  {
    id: 'a1449e1fa25a4b04124b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45491&size=130',
  },
  {
    id: 'ff71c72afb6f12314b7e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45492&size=130',
  },
  {
    id: '25a81cf320b6c9e890a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45493&size=130',
  },
  {
    id: '3836026d3e28d7768e39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45494&size=130',
  },
  {
    id: 'e9e1d2baeeff07a15eee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45495&size=130',
  },
  {
    id: 'a05e9405a840411e1851',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45496&size=130',
  },
  {
    id: '9818ad43910678582117',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45497&size=130',
  },
  {
    id: 'e767d13ced7904275d68',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45498&size=130',
  },
  {
    id: '45b372e84eada7f3febc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45499&size=130',
  },
  {
    id: '7ad24a8976cc9f92c6dd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45500&size=130',
  },
  {
    id: 'f27cc327ff62163c4f73',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45501&size=130',
  },
  {
    id: '045a36010a44e31aba55',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45502&size=130',
  },
  {
    id: 'ee20dd7be13e0860512f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45503&size=130',
  },
  {
    id: '71bf3de401a1e8ffb1b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45504&size=130',
  },
  {
    id: 'd1509c0ba04e4910105f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45505&size=130',
  },
  {
    id: '09a547fe7bbb92e5cbaa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45506&size=130',
  },
  {
    id: 'bfadf0f6ccb325ed7ca2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45507&size=130',
  },
  {
    id: 'df029759ab1c42421b0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45508&size=130',
  },
  {
    id: '4f07b65c8a1963473a08',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45429&size=130',
  },
  {
    id: '4524bf7f833a6a64332b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45430&size=130',
  },
  {
    id: '91c46a9f56dabf84e6cb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45431&size=130',
  },
  {
    id: '8154750f494aa014f95b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45432&size=130',
  },
  {
    id: '79bc8ce7b0a259fc00b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45433&size=130',
  },
  {
    id: 'b7ed41b67df394adcde2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45434&size=130',
  },
  {
    id: 'a8d55f8e63cb8a95d3da',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45435&size=130',
  },
  {
    id: '95ce659559d0b08ee9c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45436&size=130',
  },
  {
    id: 'aba55afe66bb8fe5d6aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45437&size=130',
  },
  {
    id: '4134b36f8f2a66743f3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45438&size=130',
  },
  {
    id: '9a8069db559ebcc0e58f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45439&size=130',
  },
  {
    id: '8adc8687bac2539c0ad3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45440&size=130',
  },
  {
    id: '17b31ae826adcff396bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45441&size=130',
  },
  {
    id: 'e0a7eefcd2b93be762a8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45442&size=130',
  },
  {
    id: 'c71cc847f4021d5c4413',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45443&size=130',
  },
  {
    id: '1b8c13d72f92c6cc9f83',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45444&size=130',
  },
  {
    id: 'f80f1d542111c84f9100',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45417&size=130',
  },
  {
    id: 'f9261f7d2338ca669329',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45418&size=130',
  },
  {
    id: 'bfe458bf64fa8da4d4eb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45419&size=130',
  },
  {
    id: 'd3a833f30fb6e6e8bfa7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45420&size=130',
  },
  {
    id: 'f20313582f1dc6439f0c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45421&size=130',
  },
  {
    id: 'a2c5409e7cdb9585ccca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45422&size=130',
  },
  {
    id: 'fe341d6f212ac874913b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45423&size=130',
  },
  {
    id: 'ccc330980cdde583bccc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45424&size=130',
  },
  {
    id: '574baa1096557f0b2644',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45425&size=130',
  },
  {
    id: '3183cfd8f39d1ac3438c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45426&size=130',
  },
  {
    id: '3a46c51df95810064949',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45427&size=130',
  },
  {
    id: 'd56c2d371172f82ca163',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45428&size=130',
  },
  {
    id: '6a80a7db9b9e72c02b8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45377&size=130',
  },
  {
    id: '312bff70c3352a6b7324',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45378&size=130',
  },
  {
    id: 'b40d7b564713ae4df702',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45379&size=130',
  },
  {
    id: '5e539608aa4d43131a5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45380&size=130',
  },
  {
    id: 'a2746b2f576abe34e77b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45381&size=130',
  },
  {
    id: '5e8794dca89941c71888',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45382&size=130',
  },
  {
    id: '126ad931e5740c2a5565',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45383&size=130',
  },
  {
    id: '654fa1149d51740f2d40',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45384&size=130',
  },
  {
    id: '04c0c19bfdde14804dcf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45385&size=130',
  },
  {
    id: '1bd1dd8ae1cf089151de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45386&size=130',
  },
  {
    id: 'a65261095d4cb412ed5d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45387&size=130',
  },
  {
    id: '3a1ffa44c6012f5f7610',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45388&size=130',
  },
  {
    id: '13439218ae5d47031e4c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45325&size=130',
  },
  {
    id: '34a9b6f28ab763e93aa6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45326&size=130',
  },
  {
    id: 'c9dd4a8676c39f9dc6d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45327&size=130',
  },
  {
    id: 'b2d02e8b12cefb90a2df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45328&size=130',
  },
  {
    id: '33ddae8692c37b9d22d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45329&size=130',
  },
  {
    id: 'a4a43aff06baefe4b6ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45330&size=130',
  },
  {
    id: 'c874572f6b6a8234db7b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45331&size=130',
  },
  {
    id: '16bf8ee4b2a15bff02b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45332&size=130',
  },
  {
    id: '7e17e74cdb0932576b18',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45333&size=130',
  },
  {
    id: '7e9fe4c4d88131df6890',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45334&size=130',
  },
  {
    id: '1d188643ba0653580a17',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45335&size=130',
  },
  {
    id: 'e0c0749b48dea180f8cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45336&size=130',
  },
  {
    id: '57abc2f0feb517eb4ea4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45337&size=130',
  },
  {
    id: 'a81f3e440201eb5fb210',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45338&size=130',
  },
  {
    id: 'c270552b696e8030d97f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45339&size=130',
  },
  {
    id: 'ac833cd8009de9c3b08c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45340&size=130',
  },
  {
    id: 'a73dc667fa22137c4a33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45293&size=130',
  },
  {
    id: '20a842f27eb797e9cea6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45294&size=130',
  },
  {
    id: '782e1b742731ce6f9720',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45295&size=130',
  },
  {
    id: '2525597f653a8c64d52b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45296&size=130',
  },
  {
    id: '7d4900133c56d5088c47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45297&size=130',
  },
  {
    id: '11e26fb853fdbaa3e3ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45298&size=130',
  },
  {
    id: 'c3fcbca680e369bd30f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45299&size=130',
  },
  {
    id: '986ae030dc75352b6c64',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45300&size=130',
  },
  {
    id: '871dfe47c2022b5c7213',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45301&size=130',
  },
  {
    id: '23a359f965bc8ce2d5ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45302&size=130',
  },
  {
    id: 'bbe4c0befcfb15a54cea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45303&size=130',
  },
  {
    id: 'c4d6b08c8cc965973cd8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45304&size=130',
  },
  {
    id: 'f409b95385166c483507',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45249&size=130',
  },
  {
    id: 'cfb781edbda854f60db9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45250&size=130',
  },
  {
    id: '3e37716d4d28a476fd39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45251&size=130',
  },
  {
    id: '628e2ad41691ffcfa680',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45252&size=130',
  },
  {
    id: 'ff36b66c8a2963773a38',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45253&size=130',
  },
  {
    id: '45950fcf338adad4839b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45254&size=130',
  },
  {
    id: '977bdc21e064093a5075',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45255&size=130',
  },
  {
    id: '218e65d45991b0cfe980',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45256&size=130',
  },
  {
    id: 'e2b3a7e99bac72f22bbd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45257&size=130',
  },
  {
    id: '21b167eb5baeb2f0ebbf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45258&size=130',
  },
  {
    id: '759e32c40e81e7dfbe90',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45259&size=130',
  },
  {
    id: '112c51766d33846ddd22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45260&size=130',
  },
  {
    id: '9eabe3eedfab36f56fba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45041&size=130',
  },
  {
    id: '62f71cb220f7c9a990e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45042&size=130',
  },
  {
    id: '9532ea77d6323f6c6623',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45043&size=130',
  },
  {
    id: '8578fd3dc17828267169',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45044&size=130',
  },
  {
    id: '07d67e9342d6ab88f2c7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45045&size=130',
  },
  {
    id: 'ce23b4668823617d3832',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45046&size=130',
  },
  {
    id: '1add61985dddb483edcc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45047&size=130',
  },
  {
    id: 'c9ecbda981ec68b231fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45048&size=130',
  },
  {
    id: 'd64fa30a9f4f76112f5e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45049&size=130',
  },
  {
    id: '13fc65b959fcb0a2e9ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45050&size=130',
  },
  {
    id: 'a003d746eb03025d5b12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45051&size=130',
  },
  {
    id: 'b2bbc2fefebb17e54eaa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45052&size=130',
  },
  {
    id: '138dd2d7ee9207cc5e83',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45133&size=130',
  },
  {
    id: '6a53a809944c7d12245d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45134&size=130',
  },
  {
    id: 'd47a17202b65c23b9b74',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45135&size=130',
  },
  {
    id: 'ba60663a5a7fb321ea6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45136&size=130',
  },
  {
    id: '57e68abcb6f95fa706e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45137&size=130',
  },
  {
    id: 'e17b3f210364ea3ab375',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45138&size=130',
  },
  {
    id: '8b38546268278179d836',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45139&size=130',
  },
  {
    id: 'df9907c33b86d2d88b97',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45140&size=130',
  },
  {
    id: '12c7cb9df7d81e8647c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45141&size=130',
  },
  {
    id: '244cfe16c2532b0d7242',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45142&size=130',
  },
  {
    id: '7242a918955d7c03254c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45143&size=130',
  },
  {
    id: '9b5c4f0673439a1dc352',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45144&size=130',
  },
  {
    id: 'd776022c3e69d7378e78',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45145&size=130',
  },
  {
    id: '8d715b2b676e8e30d77f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45146&size=130',
  },
  {
    id: '294bfe11c2542b0a7245',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45147&size=130',
  },
  {
    id: '7e2cae7692337b6d2222',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45148&size=130',
  },
  {
    id: 'f63327691b2cf272ab3d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45149&size=130',
  },
  {
    id: '86f354a968ec81b2d8fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45150&size=130',
  },
  {
    id: 'cc541f0e234bca15935a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45151&size=130',
  },
  {
    id: '8cf860a25ce7b5b9ecf6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45152&size=130',
  },
  {
    id: 'a85b45017944901ac955',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45153&size=130',
  },
  {
    id: '0ce5e2bfdefa37a46eeb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45154&size=130',
  },
  {
    id: 'af2940737c369568cc27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45155&size=130',
  },
  {
    id: 'bd8155db699e80c0d98f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=45156&size=130',
  },
  {
    id: '16e2cb48f70d1e53471c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16465&size=130',
  },
  {
    id: 'e96737cd0b88e2d6bb99',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16466&size=130',
  },
  {
    id: '131bccb1f0f419aa40e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16467&size=130',
  },
  {
    id: '4fa4970eab4b42151b5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16468&size=130',
  },
  {
    id: 'a32f7a8546c0af9ef6d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16469&size=130',
  },
  {
    id: 'e221388b04ceed90b4df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16470&size=130',
  },
  {
    id: '1ce5c74ffb0a12544b1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16471&size=130',
  },
  {
    id: '69a8bd02814768193156',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16472&size=130',
  },
  {
    id: '3b10eebad2ff3ba162ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16473&size=130',
  },
  {
    id: '4b709ddaa19f48c1118e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16474&size=130',
  },
  {
    id: 'fd9c2a361673ff2da662',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16475&size=130',
  },
  {
    id: '1dafcd05f140181e4151',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16476&size=130',
  },
  {
    id: '3f44eeeed2ab3bf562ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16477&size=130',
  },
  {
    id: '0060d2caee8f07d15e9e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16478&size=130',
  },
  {
    id: '93f940537c169548cc07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16479&size=130',
  },
  {
    id: 'ef94033e3f7bd6258f6a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16480&size=130',
  },
  {
    id: '34e3d949e50c0c52551d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16481&size=130',
  },
  {
    id: '7a339499a8dc418218cd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16482&size=130',
  },
  {
    id: 'c2122db811fdf8a3a1ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16483&size=130',
  },
  {
    id: '080be0a1dce435ba6cf5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16484&size=130',
  },
  {
    id: 'd0bc331b0f5ee600bf4f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19823&size=130',
  },
  {
    id: 'dafd265a1a1ff341aa0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19824&size=130',
  },
  {
    id: 'a6815b2667638e3dd772',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19825&size=130',
  },
  {
    id: '39b1c716fb53120d4b42',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19826&size=130',
  },
  {
    id: 'f7f708503415dd4b8404',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19827&size=130',
  },
  {
    id: '05e5fd42c10728597116',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19828&size=130',
  },
  {
    id: '2f69d6ceea8b03d55a9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19829&size=130',
  },
  {
    id: '5a81a0269c63753d2c72',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19830&size=130',
  },
  {
    id: '69dd927aae3f47611e2e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19831&size=130',
  },
  {
    id: '30cec469f82c1172483d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19832&size=130',
  },
  {
    id: '771882bfbefa57a40eeb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19833&size=130',
  },
  {
    id: '9225648258c7b199e8d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19834&size=130',
  },
  {
    id: '0174f6d3ca9623c87a87',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19835&size=130',
  },
  {
    id: '7b878b20b7655e3b0774',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19836&size=130',
  },
  {
    id: 'ffd80e7f323adb64822b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19837&size=130',
  },
  {
    id: 'ff720dd53190d8ce8181',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19838&size=130',
  },
  {
    id: '361fc5b8f9fd10a349ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19839&size=130',
  },
  {
    id: '90039ca4a0e149bf10f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19840&size=130',
  },
  {
    id: '326308c43481dddf8490',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19894&size=130',
  },
  {
    id: '6bc650616c24857adc35',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19895&size=130',
  },
  {
    id: '9ea3aa0496417f1f2650',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19896&size=130',
  },
  {
    id: 'b95d8cfab0bf59e100ae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19897&size=130',
  },
  {
    id: 'd42ce28bdece37906edf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19898&size=130',
  },
  {
    id: '4b8d7c2a406fa931f07e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19899&size=130',
  },
  {
    id: '14f9245e181bf145a80a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19900&size=130',
  },
  {
    id: '3cc00d673122d87c8133',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19901&size=130',
  },
  {
    id: 'eb01d9a6e5e30cbd55f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19902&size=130',
  },
  {
    id: '5808dd4de10808565119',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44809&size=130',
  },
  {
    id: '83f405b139f4d0aa89e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44810&size=130',
  },
  {
    id: '9e9219d72592cccc9583',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44811&size=130',
  },
  {
    id: 'bd083d4d0108e856b119',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44812&size=130',
  },
  {
    id: '3693b7d68b9362cd3b82',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44813&size=130',
  },
  {
    id: 'a00322461e03f75dae12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44814&size=130',
  },
  {
    id: '76d0f595c9d0208e79c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44815&size=130',
  },
  {
    id: 'fd7f613a5d7fb421ed6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44816&size=130',
  },
  {
    id: '4a14d751eb14024a5b05',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44817&size=130',
  },
  {
    id: '1f38817dbd3854660d29',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44818&size=130',
  },
  {
    id: 'acbc33f90fbce6e2bfad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44819&size=130',
  },
  {
    id: '5b80c3c5ff8016de4f91',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44820&size=130',
  },
  {
    id: 'c92464605825b17be834',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44577&size=130',
  },
  {
    id: 'a66e082a346fdd31847e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44578&size=130',
  },
  {
    id: '59e7f6a3cae623b87af7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44579&size=130',
  },
  {
    id: '8a6222261e63f73dae72',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44580&size=130',
  },
  {
    id: '5d46f402c84721197856',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44581&size=130',
  },
  {
    id: '9abb30ff0cbae5e4bcab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44582&size=130',
  },
  {
    id: '961b3d5f011ae844b10b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44583&size=130',
  },
  {
    id: '5afdfeb9c2fc2ba272ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44584&size=130',
  },
  {
    id: '8a692f2d1368fa36a379',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44585&size=130',
  },
  {
    id: 'e5d9439d7fd89686cfc9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44586&size=130',
  },
  {
    id: 'ef0f484b740e9d50c41f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44587&size=130',
  },
  {
    id: '5419f45dc81821467809',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44588&size=130',
  },
  {
    id: '65251c622027c9799036',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44533&size=130',
  },
  {
    id: '50442a031646ff18a657',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44534&size=130',
  },
  {
    id: 'ac1fd758eb1d02435b0c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44535&size=130',
  },
  {
    id: '19fa6dbd51f8b8a6e1e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44536&size=130',
  },
  {
    id: '8826fd61c124287a7135',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44537&size=130',
  },
  {
    id: 'b236c471f834116a4825',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44538&size=130',
  },
  {
    id: 'fa7e8d39b17c5822016d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44539&size=130',
  },
  {
    id: '81acf1ebcdae24f07dbf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44540&size=130',
  },
  {
    id: 'd3e0a2a79ee277bc2ef3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44541&size=130',
  },
  {
    id: '0bd7799045d5ac8bf5c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44542&size=130',
  },
  {
    id: '69ed1aaa26efcfb196fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44543&size=130',
  },
  {
    id: 'dc7150356c70852edc61',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44544&size=130',
  },
  {
    id: 'fcc6ad8091c5789b21d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44253&size=130',
  },
  {
    id: '4c451e032246cb189257',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44254&size=130',
  },
  {
    id: '8fa5dce3e0a609f850b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44255&size=130',
  },
  {
    id: '5edd329b0edee780becf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44256&size=130',
  },
  {
    id: '94edf9abc5ee2cb075ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44257&size=130',
  },
  {
    id: '02ed6cab50eeb9b0e0ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44258&size=130',
  },
  {
    id: '20494f0f734a9a14c35b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44259&size=130',
  },
  {
    id: '24ef4ca970ec99b2c0fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44260&size=130',
  },
  {
    id: '4c2325651920f07ea931',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44261&size=130',
  },
  {
    id: '7d6c172a2b6fc2319b7e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44262&size=130',
  },
  {
    id: '65800ec63283dbdd8292',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44263&size=130',
  },
  {
    id: 'e1168550b915504b0904',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44264&size=130',
  },
  {
    id: 'a0858dc3b18658d80197',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44193&size=130',
  },
  {
    id: '93b7bdf181b468ea31a5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44194&size=130',
  },
  {
    id: '4f1160575c12b54cec03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44195&size=130',
  },
  {
    id: 'c340eb06d7433e1d6752',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44196&size=130',
  },
  {
    id: 'a4ab8dedb1a858f601b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44197&size=130',
  },
  {
    id: '90a3bae586a06ffe36b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44198&size=130',
  },
  {
    id: '329019d62593cccd9582',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44199&size=130',
  },
  {
    id: '0378273e1b7bf225ab6a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44200&size=130',
  },
  {
    id: '85f7a0b19cf475aa2ce5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44201&size=130',
  },
  {
    id: '767150376c72852cdc63',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44202&size=130',
  },
  {
    id: '66a441e27da794f9cdb6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44203&size=130',
  },
  {
    id: '710151476d02845cdd13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44204&size=130',
  },
  {
    id: '477b663d5a78b326ea69',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44205&size=130',
  },
  {
    id: 'e667c421f864113a4875',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44206&size=130',
  },
  {
    id: 'c5b5e6f3dab633e86aa7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44207&size=130',
  },
  {
    id: '47bb7bfd47b8aee6f7a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44208&size=130',
  },
  {
    id: 'b0a3c2e2fea717f94eb6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44030&size=130',
  },
  {
    id: '6ae319a225e7ccb995f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44031&size=130',
  },
  {
    id: '1fa193e7afa246fc1fb3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44032&size=130',
  },
  {
    id: 'b4c1398705c2ec9cb5d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44033&size=130',
  },
  {
    id: '9a2414622827c1799836',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44034&size=130',
  },
  {
    id: 'deca518c6dc98497ddd8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44035&size=130',
  },
  {
    id: 'cd2345657920907ec931',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44036&size=130',
  },
  {
    id: '0d4b840db84851160859',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44037&size=130',
  },
  {
    id: '630ce94ad50f3c51651e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44038&size=130',
  },
  {
    id: 'ae1e2558191df043a90c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44039&size=130',
  },
  {
    id: '6b59ef1fd35a3a04634b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44040&size=130',
  },
  {
    id: 'c52140677c22957ccc33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44041&size=130',
  },
  {
    id: '852203643f21d67f8f30',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44042&size=130',
  },
  {
    id: 'e1e566a35ae6b3b8eaf7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44043&size=130',
  },
  {
    id: '2210a2569e13774d2e02',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44044&size=130',
  },
  {
    id: '2445a503994670182957',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44045&size=130',
  },
  {
    id: '632de16bdd2e34706d3f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44046&size=130',
  },
  {
    id: '9a6c192a256fcc31957e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44047&size=130',
  },
  {
    id: 'f6536a155650bf0ee641',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=44048&size=130',
  },
  {
    id: '030bc24afe0f17514e1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43853&size=130',
  },
  {
    id: 'c12d036c3f29d6778f38',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43854&size=130',
  },
  {
    id: '48058b44b7015e5f0710',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43855&size=130',
  },
  {
    id: 'f0ee2caf10eaf9b4a0fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43856&size=130',
  },
  {
    id: '1c2ec16ffd2a14744d3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43857&size=130',
  },
  {
    id: '64fcbabd86f86fa636e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43858&size=130',
  },
  {
    id: '42c79d86a1c3489d11d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43859&size=130',
  },
  {
    id: 'd02508643421dd7f8430',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43860&size=130',
  },
  {
    id: 'f4e82da911ecf8b2a1fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43861&size=130',
  },
  {
    id: '8e3b547a683f8161d82e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43862&size=130',
  },
  {
    id: '696db22c8e6967373e78',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43863&size=130',
  },
  {
    id: 'b10165405905b05be914',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43864&size=130',
  },
  {
    id: '871752566e13874dde02',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43865&size=130',
  },
  {
    id: '29deff9fc3da2a8473cb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43866&size=130',
  },
  {
    id: 'd5e102a03ee5d7bb8ef4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43867&size=130',
  },
  {
    id: '4a7c9a3da6784f261669',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43868&size=130',
  },
  {
    id: 'b74166005a45b31bea54',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43869&size=130',
  },
  {
    id: '1becc9adf5e81cb645f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43870&size=130',
  },
  {
    id: '879c54dd689881c6d889',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43871&size=130',
  },
  {
    id: '644c880db4485d160459',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43872&size=130',
  },
  {
    id: 'fd4e100f2c4ac5149c5b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43873&size=130',
  },
  {
    id: '02e9eca8d0ed39b360fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43874&size=130',
  },
  {
    id: '4ecda18c9dc974972dd8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43875&size=130',
  },
  {
    id: '62a375ec49a9a0f7f9b8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42395&size=130',
  },
  {
    id: '2fb23ffd03b8eae6b3a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42396&size=130',
  },
  {
    id: '81469009ac4c45121c5d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42397&size=130',
  },
  {
    id: '06fe14b128f4c1aa98e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42398&size=130',
  },
  {
    id: '9ac1898eb5cb5c9505da',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42399&size=130',
  },
  {
    id: '096825271962f03ca973',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42400&size=130',
  },
  {
    id: '773d5a7266378f69d626',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42401&size=130',
  },
  {
    id: 'da29f466c823217d7832',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42402&size=130',
  },
  {
    id: '9a45b50a894f6011395e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42403&size=130',
  },
  {
    id: '6a8442cb7e8e97d0ce9f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42404&size=130',
  },
  {
    id: 'd695ffdac39f2ac1738e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42405&size=130',
  },
  {
    id: 'a6d78c98b0dd598300cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42406&size=130',
  },
  {
    id: 'b0469b09a74c4e12175d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42407&size=130',
  },
  {
    id: 'b14c9503a94640181957',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42408&size=130',
  },
  {
    id: 'd610f35fcf1a26447f0b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42409&size=130',
  },
  {
    id: '68264e69722c9b72c23d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42410&size=130',
  },
  {
    id: 'd582f2cdce8827d67e99',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42411&size=130',
  },
  {
    id: 'db91fbdec79b2ec5778a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42412&size=130',
  },
  {
    id: '476866275a62b33cea73',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42413&size=130',
  },
  {
    id: '426a60255c60b53eec71',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42414&size=130',
  },
  {
    id: 'eb71c83ef47b1d25446a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42415&size=130',
  },
  {
    id: '0f70333f0f7ae624bf6b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42416&size=130',
  },
  {
    id: '7f60422f7e6a9734ce7b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42417&size=130',
  },
  {
    id: 'b77a8935b5705c2e0561',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42418&size=130',
  },
  {
    id: '91557d154150a80ef141',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43616&size=130',
  },
  {
    id: '62018f41b3045a5a0315',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43617&size=130',
  },
  {
    id: '11b7fff7c3b22aec73a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43618&size=130',
  },
  {
    id: '134ffc0fc04a2914705b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43619&size=130',
  },
  {
    id: '71a399e3a5a64cf815b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43620&size=130',
  },
  {
    id: 'aac643867fc3969dcfd2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43621&size=130',
  },
  {
    id: '1efef4bec8fb21a578ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43622&size=130',
  },
  {
    id: '07e1eca1d0e439ba60f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43623&size=130',
  },
  {
    id: '271cc35cff1916474f08',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43624&size=130',
  },
  {
    id: '49c6ac8690c3799d20d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43625&size=130',
  },
  {
    id: '7a089c48a00d4953101c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43626&size=130',
  },
  {
    id: '962c716c4d29a477fd38',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43627&size=130',
  },
  {
    id: 'ef570f173352da0c8343',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43628&size=130',
  },
  {
    id: '792a986aa42f4d71143e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43629&size=130',
  },
  {
    id: 'af354d757130986ec121',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43630&size=130',
  },
  {
    id: '1634f574c931206f7920',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43631&size=130',
  },
  {
    id: '3f88c3c8ff8d16d34f9c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43632&size=130',
  },
  {
    id: '1eb6e3f6dfb336ed6fa2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43633&size=130',
  },
  {
    id: '0cd8f298cedd27837ecc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43634&size=130',
  },
  {
    id: '1162ee22d2673b396276',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43635&size=130',
  },
  {
    id: 'aa1052506e15874bde04',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43636&size=130',
  },
  {
    id: '62519b11a7544e0a1745',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43637&size=130',
  },
  {
    id: '3b39c179fd3c14624d2d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43638&size=130',
  },
  {
    id: '7d118651ba14534a0a05',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=43639&size=130',
  },
  {
    id: 'c1cba98495c17c9f25d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42468&size=130',
  },
  {
    id: 'd3acbae386a66ff836b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42469&size=130',
  },
  {
    id: '5fd6359909dce082b9cd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42470&size=130',
  },
  {
    id: '7ded16a22ae7c3b99af6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42471&size=130',
  },
  {
    id: '70f714b828fdc1a398ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42472&size=130',
  },
  {
    id: '133676794a3ca362fa2d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42473&size=130',
  },
  {
    id: '977bf134cd71242f7d60',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42474&size=130',
  },
  {
    id: 'c69da1d29d9774c92d86',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42475&size=130',
  },
  {
    id: '0c756c3a507fb921e06e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42476&size=130',
  },
  {
    id: '7734167b2a3ec3609a2f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42477&size=130',
  },
  {
    id: 'af29cd66f123187d4132',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42478&size=130',
  },
  {
    id: '235940167c53950dcc42',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42479&size=130',
  },
  {
    id: '877ffb30c7752e2b7764',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42480&size=130',
  },
  {
    id: 'f1638c2cb06959370078',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42481&size=130',
  },
  {
    id: '94a4eaebd6ae3ff066bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42482&size=130',
  },
  {
    id: '0fee70a14ce4a5bafcf5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42483&size=130',
  },
  {
    id: '5a04224b1e0ef750ae1f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42484&size=130',
  },
  {
    id: 'cbf3b2bc8ef967a73ee8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42485&size=130',
  },
  {
    id: '2d25576a6b2f8271db3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42486&size=130',
  },
  {
    id: '8a9ff1d0cd9524cb7d84',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42487&size=130',
  },
  {
    id: 'af01db4ee70b0e55571a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42488&size=130',
  },
  {
    id: 'aa11df5ee31b0a45530a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42489&size=130',
  },
  {
    id: '0b5b7d144151a80ff140',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42490&size=130',
  },
  {
    id: 'bda9cae6f6a31ffd46b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42491&size=130',
  },
  {
    id: 'a2039d4ca10948571118',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42419&size=130',
  },
  {
    id: 'a5c09d8fa1ca489411db',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42420&size=130',
  },
  {
    id: '0e15375a0b1fe241bb0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42421&size=130',
  },
  {
    id: '2ddb17942bd1c28f9bc0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42422&size=130',
  },
  {
    id: 'f5bccef3f2b61be842a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42423&size=130',
  },
  {
    id: 'e8d7dc98e0dd098350cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42424&size=130',
  },
  {
    id: '38250d6a312fd871813e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42425&size=130',
  },
  {
    id: '0e263869042ced72b43d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42426&size=130',
  },
  {
    id: '74d643997fdc9682cfcd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42427&size=130',
  },
  {
    id: 'f69ec6d1fa9413ca4a85',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42428&size=130',
  },
  {
    id: '3ae80ba737e2debc87f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42429&size=130',
  },
  {
    id: '6bbb59f465b18cefd5a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42430&size=130',
  },
  {
    id: 'd629e566d923307d6932',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42431&size=130',
  },
  {
    id: '90e8dca7e0e209bc50f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42432&size=130',
  },
  {
    id: '8f20c26ffe2a17744e3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42433&size=130',
  },
  {
    id: 'a4e8eaa7d6e23fbc66f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42434&size=130',
  },
  {
    id: '014c4e0372469b18c257',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42435&size=130',
  },
  {
    id: 'dca194eea8ab41f518ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42436&size=130',
  },
  {
    id: '75723c3d0078e926b069',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42437&size=130',
  },
  {
    id: '7f40350f094ae014b95b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42438&size=130',
  },
  {
    id: 'ae2de562d92730796936',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42439&size=130',
  },
  {
    id: '68602c2f106af934a07b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42440&size=130',
  },
  {
    id: 'e0e8a5a799e270bc29f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42441&size=130',
  },
  {
    id: '72c0348f08cae194b8db',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=42442&size=130',
  },
  {
    id: 'f7209e69a22c4b72123d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41957&size=130',
  },
  {
    id: '5c15365c0a19e347ba08',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41958&size=130',
  },
  {
    id: '5c4837010b44e21abb55',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41959&size=130',
  },
  {
    id: 'dfedbba487e16ebf37f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41960&size=130',
  },
  {
    id: '5fa33aea06afeff1b6be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41961&size=130',
  },
  {
    id: '6b480d013144d81a8155',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41962&size=130',
  },
  {
    id: 'ef05884cb4095d570418',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41963&size=130',
  },
  {
    id: 'cae1aaa896ed7fb326fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41964&size=130',
  },
  {
    id: 'b78fd6c6ea8303dd5a92',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41965&size=130',
  },
  {
    id: 'cfa3adea91af78f121be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41966&size=130',
  },
  {
    id: '27cb448278c79199c8d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41967&size=130',
  },
  {
    id: 'dc0ba0429c0775592c16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41968&size=130',
  },
  {
    id: 'bb4591adade844b61df9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=678&size=130',
  },
  {
    id: '1b5530bd0cf8e5a6bce9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=679&size=130',
  },
  {
    id: '1afb3e130256eb08b247',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=680&size=130',
  },
  {
    id: '51b3745b481ea140f80f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=681&size=130',
  },
  {
    id: '5e3678de449badc5f48a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=682&size=130',
  },
  {
    id: '611d46f57ab093eecaa1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=683&size=130',
  },
  {
    id: '1594357c0939e067b928',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=684&size=130',
  },
  {
    id: 'b94b98a3a4e64db814f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=685&size=130',
  },
  {
    id: '727850906cd5858bdcc4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=686&size=130',
  },
  {
    id: '37801468282dc173983c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=687&size=130',
  },
  {
    id: '08cb34230866e138b877',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=688&size=130',
  },
  {
    id: 'c2e2ff0ac34f2a11735e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=689&size=130',
  },
  {
    id: '84f0ba18865d6f03364c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=690&size=130',
  },
  {
    id: '63935c7b603e8960d02f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=691&size=130',
  },
  {
    id: '2da5154d2908c0569919',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=692&size=130',
  },
  {
    id: '9bb8a2509e15774b2e04',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=693&size=130',
  },
  {
    id: '359d0f753330da6e8321',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=694&size=130',
  },
  {
    id: '0de3360b0a4ee310ba5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=695&size=130',
  },
  {
    id: '9228a6c09a8573db2a94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=696&size=130',
  },
  {
    id: '2a431fab23eecab093ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=697&size=130',
  },
  {
    id: '2f5f4c147051990fc040',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41455&size=130',
  },
  {
    id: '7ac5068e3acbd3958ada',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41456&size=130',
  },
  {
    id: 'bd87c0ccfc8915d74c98',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41457&size=130',
  },
  {
    id: '21675f2c63698a37d378',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41458&size=130',
  },
  {
    id: 'd6f6a9bd95f87ca625e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41459&size=130',
  },
  {
    id: '54c02c8b10cef990a0df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41460&size=130',
  },
  {
    id: 'fdb484ffb8ba51e408ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41461&size=130',
  },
  {
    id: '9a39e072dc3735696c26',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41462&size=130',
  },
  {
    id: '79da02913ed4d78a8ec5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41463&size=130',
  },
  {
    id: 'ed0e9945a5004c5e1511',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41464&size=130',
  },
  {
    id: '449b31d00d95e4cbbd84',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41465&size=130',
  },
  {
    id: 'e7e191aaadef44b11dfe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41466&size=130',
  },
  {
    id: '8671373a0b7fe221bb6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41277&size=130',
  },
  {
    id: '18beaaf596b07fee26a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41278&size=130',
  },
  {
    id: '6d2dde66e2230b7d5232',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41279&size=130',
  },
  {
    id: '7b45b70e8b4b62153b5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41280&size=130',
  },
  {
    id: '83ce4e8572c09b9ec2d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41281&size=130',
  },
  {
    id: '81de4f9573d09a8ec3c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41282&size=130',
  },
  {
    id: 'a13b6e705235bb6be224',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41283&size=130',
  },
  {
    id: 'd034187f243acd64942b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41284&size=130',
  },
  {
    id: 'cdd2049938dcd18288cd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41285&size=130',
  },
  {
    id: '66aface490a179ff20b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41286&size=130',
  },
  {
    id: 'f9c932820ec7e799bed6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41287&size=130',
  },
  {
    id: 'a35e67155b50b20eeb41',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41288&size=130',
  },
  {
    id: '8c1d495675139c4dc502',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41289&size=130',
  },
  {
    id: '6a66ac2d906879362079',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41290&size=130',
  },
  {
    id: 'f665312e0d6be435bd7a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41291&size=130',
  },
  {
    id: 'd12311682d2dc4739d3c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41292&size=130',
  },
  {
    id: 'f454351f095ae004b94b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41293&size=130',
  },
  {
    id: '25fce7b7dbf232ac6be3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41294&size=130',
  },
  {
    id: '55ce9685aac0439e1ad1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41295&size=130',
  },
  {
    id: '3710eb5bd71e3e40670f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41296&size=130',
  },
  {
    id: '96984bd377969ec8c787',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41297&size=130',
  },
  {
    id: '1d89c3c2ff8716d94f96',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41298&size=130',
  },
  {
    id: '4f739038ac7d45231c6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41299&size=130',
  },
  {
    id: '59a681edbda854f60db9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41300&size=130',
  },
  {
    id: '5e57b41d885861063849',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41062&size=130',
  },
  {
    id: 'bd5356196a5c8302da4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41063&size=130',
  },
  {
    id: 'a18245c8798d90d3c99c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41064&size=130',
  },
  {
    id: '73099643aa0643581a17',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41065&size=130',
  },
  {
    id: '527cb4368873612d3862',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41066&size=130',
  },
  {
    id: '2588c2c2fe8717d94e96',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41067&size=130',
  },
  {
    id: 'b97b593165748c2ad565',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41068&size=130',
  },
  {
    id: 'fef41fbe23fbcaa593ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41069&size=130',
  },
  {
    id: 'da9c38d60493edcdb482',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41070&size=130',
  },
  {
    id: '738990c3ac8645d81c97',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41071&size=130',
  },
  {
    id: '3b12c758fb1d12434b0c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41072&size=130',
  },
  {
    id: 'f9d7049d38d8d18688c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41073&size=130',
  },
  {
    id: '82187c524017a949f006',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41074&size=130',
  },
  {
    id: 'b7ed48a774e29dbcc4f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41075&size=130',
  },
  {
    id: 'e854101e2c5bc5059c4a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41076&size=130',
  },
  {
    id: 'fda704ed38a8d1f688b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41077&size=130',
  },
  {
    id: '9e5a64105855b10be844',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41078&size=130',
  },
  {
    id: '53aba8e194a47dfa24b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41079&size=130',
  },
  {
    id: '3e01ca4bf60e1f50461f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41080&size=130',
  },
  {
    id: 'ae585b1267578e09d746',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41081&size=130',
  },
  {
    id: '2bc7d3b2eff706a95fe6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40820&size=130',
  },
  {
    id: '8aea739f4fdaa684ffcb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40821&size=130',
  },
  {
    id: 'd47b2e0e124bfb15a25a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40822&size=130',
  },
  {
    id: '27d0dca5e0e009be50f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40823&size=130',
  },
  {
    id: '845470214c64a53afc75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40824&size=130',
  },
  {
    id: 'fe4a0b3f377ade24876b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40825&size=130',
  },
  {
    id: '93a965dc5999b0c7e988',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40826&size=130',
  },
  {
    id: '9be86c9d50d8b986e0c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40827&size=130',
  },
  {
    id: 'c91139640521ec7fb530',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40828&size=130',
  },
  {
    id: 'e04211372d72c42c9d63',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40829&size=130',
  },
  {
    id: '54b6a6c39a8673d82a97',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40830&size=130',
  },
  {
    id: '0969fa1cc6592f077648',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40831&size=130',
  },
  {
    id: 'a069ac1c905979072048',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40832&size=130',
  },
  {
    id: '7829755c4919a047f908',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40833&size=130',
  },
  {
    id: 'b4cbbabe86fb6fa536ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40834&size=130',
  },
  {
    id: 'ac3ba34e9f0b76552f1a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40835&size=130',
  },
  {
    id: '9f409735ab70422e1b61',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40836&size=130',
  },
  {
    id: '32493b3c0779ee27b768',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40837&size=130',
  },
  {
    id: '50c55ab066f58fabd6e4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40838&size=130',
  },
  {
    id: '5d5656236a668338da77',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40839&size=130',
  },
  {
    id: '100314762833c16d9822',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40840&size=130',
  },
  {
    id: '7ec07bb547f0aeaef7e1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40841&size=130',
  },
  {
    id: 'a174a7019b44721a2b55',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40842&size=130',
  },
  {
    id: '7a957de041a5a8fbf1b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40843&size=130',
  },
  {
    id: '9715c561f924107a4935',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40670&size=130',
  },
  {
    id: 'fd96aee292a77bf922b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40671&size=130',
  },
  {
    id: '1a1d76694a2ca372fa3d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40672&size=130',
  },
  {
    id: '94d9f9adc5e82cb675f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40673&size=130',
  },
  {
    id: '28bd46c97a8c93d2ca9d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40674&size=130',
  },
  {
    id: '1fe570914cd4a58afcc5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40675&size=130',
  },
  {
    id: '00b868cc5489bdd7e498',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40676&size=130',
  },
  {
    id: 'd9d2b0a68ce365bd3cf2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40677&size=130',
  },
  {
    id: 'ab88c1fcfdb914e74da8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40678&size=130',
  },
  {
    id: '2d9d46e97aac93f2cabd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40679&size=130',
  },
  {
    id: '662d02593e1cd7428e0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40680&size=130',
  },
  {
    id: '1a2e7f5a431faa41f30e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=40681&size=130',
  },
  {
    id: '9727c858f41d1d43440c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38355&size=130',
  },
  {
    id: '73522b2d1768fe36a779',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38356&size=130',
  },
  {
    id: '084e51316d74842add65',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38357&size=130',
  },
  {
    id: 'b230e84fd40a3d54641b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38358&size=130',
  },
  {
    id: '51550a2a366fdf31867e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38359&size=130',
  },
  {
    id: '0a195e6662238b7dd232',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38360&size=130',
  },
  {
    id: 'ab5efe21c2642b3a7275',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38361&size=130',
  },
  {
    id: '66a930d60c93e5cdbc82',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38362&size=130',
  },
  {
    id: '2c517b2e476bae35f77a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38363&size=130',
  },
  {
    id: '7c3c2c431006f958a017',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38364&size=130',
  },
  {
    id: '19b648c9748c9dd2c49d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38365&size=130',
  },
  {
    id: 'e9e9bb9687d36e8d37c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=38366&size=130',
  },
  {
    id: '25ce24661823f17da832',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17037&size=130',
  },
  {
    id: 'b116b3be8ffb66a53fea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17038&size=130',
  },
  {
    id: 'e8daeb72d7373e696726',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17039&size=130',
  },
  {
    id: 'dfa3c30bff4e16104f5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17040&size=130',
  },
  {
    id: 'e692fb3ac77f2e21776e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17041&size=130',
  },
  {
    id: '33602dc8118df8d3a19c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17042&size=130',
  },
  {
    id: '16900938357ddc23856c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17043&size=130',
  },
  {
    id: '894d91e5ada044fe1db1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17044&size=130',
  },
  {
    id: '37722eda129ffbc1a28e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17045&size=130',
  },
  {
    id: '6ec17469482ca172f83d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17046&size=130',
  },
  {
    id: '5f0a8d72b13758690126',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37470&size=130',
  },
  {
    id: '831d50656c20857edc31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37471&size=130',
  },
  {
    id: '9af976814ac4a39afad5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37472&size=130',
  },
  {
    id: 'daed37950bd0e28ebbc1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37473&size=130',
  },
  {
    id: '1d3af342cf0726597f16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37474&size=130',
  },
  {
    id: '5e0fb1778d32646c3d23',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37475&size=130',
  },
  {
    id: '0a23e25bde1e37406e0f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37476&size=130',
  },
  {
    id: 'a65f4f2773629a3cc373',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37477&size=130',
  },
  {
    id: '6b65811dbd5854060d49',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37478&size=130',
  },
  {
    id: '97b47ccc4089a9d7f098',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37479&size=130',
  },
  {
    id: '5e51ba29866c6f32367d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37480&size=130',
  },
  {
    id: '7ad09fa8a3ed4ab313fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=37481&size=130',
  },
  {
    id: 'cabdb7d98b9c62c23b8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36593&size=130',
  },
  {
    id: 'c91cb7788b3d62633b2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36594&size=130',
  },
  {
    id: 'fda582c1be8457da0e95',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36595&size=130',
  },
  {
    id: 'f8bf80dbbc9e55c00c8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36596&size=130',
  },
  {
    id: 'da97a3f39fb676e82fa7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36597&size=130',
  },
  {
    id: '3cf046947ad1938fcac0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36598&size=130',
  },
  {
    id: 'cd0eb66a8a2f63713a3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36599&size=130',
  },
  {
    id: 'bcf3c897f4d21d8c44c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36600&size=130',
  },
  {
    id: '8b5dfe39c27c2b22726d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36601&size=130',
  },
  {
    id: '9429e24dde0837566e19',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36602&size=130',
  },
  {
    id: '2e8a59ee65ab8cf5d5ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36603&size=130',
  },
  {
    id: 'bca1ccc5f08019de4091',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36604&size=130',
  },
  {
    id: '8df2fc96c0d3298d70c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36605&size=130',
  },
  {
    id: 'cf7dbd19815c6802314d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36606&size=130',
  },
  {
    id: 'bc51cf35f3701a2e4361',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36607&size=130',
  },
  {
    id: '48f7c492f8d7118948c6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36608&size=130',
  },
  {
    id: '9a4417212b64c23a9b75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36609&size=130',
  },
  {
    id: 'da1354766833816dd822',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36610&size=130',
  },
  {
    id: '2827a7429b0772592b16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36611&size=130',
  },
  {
    id: '4bcac3afffea16b44ffb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=36612&size=130',
  },
  {
    id: '4e084c68702d9973c03c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35470&size=130',
  },
  {
    id: 'd3c7d0a7ece205bc5cf3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35471&size=130',
  },
  {
    id: 'f52be94bd50e3c50651f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35472&size=130',
  },
  {
    id: '8a1d977dab3842661b29',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35473&size=130',
  },
  {
    id: '4dd953b96ffc86a2dfed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35474&size=130',
  },
  {
    id: 'd088cfe8f3ad1af343bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35475&size=130',
  },
  {
    id: '292031400d05e45bbd14',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35476&size=130',
  },
  {
    id: 'e374fa14c6512f0f7640',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35477&size=130',
  },
  {
    id: '26dc3cbc00f9e9a7b0e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35478&size=130',
  },
  {
    id: 'e4b0ffd0c3952acb7384',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35479&size=130',
  },
  {
    id: '78466c265063b93de072',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35480&size=130',
  },
  {
    id: '9ced898db5c85c9605d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35481&size=130',
  },
  {
    id: '518347e37ba692f8cbb7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35482&size=130',
  },
  {
    id: 'af22b84284076d593416',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35483&size=130',
  },
  {
    id: '532443447f01965fcf10',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35484&size=130',
  },
  {
    id: '73c462a45ee1b7bfeef0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35485&size=130',
  },
  {
    id: 'c2e46a8456c1bf9fe6d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35364&size=130',
  },
  {
    id: '8b5722371e72f72cae63',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35365&size=130',
  },
  {
    id: 'fd0d576d6b288276db39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35366&size=130',
  },
  {
    id: 'dfa374c34886a1d8f897',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35367&size=130',
  },
  {
    id: 'fbee5f8e63cb8a95d3da',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35368&size=130',
  },
  {
    id: '0ddca8bc94f97da724e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35369&size=130',
  },
  {
    id: 'aa2a0c4a300fd951801e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35370&size=130',
  },
  {
    id: 'ab870ce730a2d9fc80b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35371&size=130',
  },
  {
    id: 'ce3f6e5f521abb44e20b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35372&size=130',
  },
  {
    id: 'a09d01fd3db8d4e68da9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35373&size=130',
  },
  {
    id: '8cfa2e9a12dffb81a2ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35374&size=130',
  },
  {
    id: 'a2f101913dd4d48a8dc5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35375&size=130',
  },
  {
    id: 'f37f4f1f735a9a04c34b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35376&size=130',
  },
  {
    id: '105fad3f917a7824216b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35377&size=130',
  },
  {
    id: '22429c22a06749391076',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35378&size=130',
  },
  {
    id: '34a68bc6b7835edd0792',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35379&size=130',
  },
  {
    id: '76b2ced2f2971bc94286',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35380&size=130',
  },
  {
    id: 'cf6f760f4a4aa314fa5b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35381&size=130',
  },
  {
    id: 'c4457e254260ab3ef271',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35382&size=130',
  },
  {
    id: '80413b210764ee3ab775',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35383&size=130',
  },
  {
    id: '7a1bce7bf23e1b60422f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35384&size=130',
  },
  {
    id: 'e83c5d5c61198847d108',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35385&size=130',
  },
  {
    id: '5c07ea67d6223f7c6633',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35386&size=130',
  },
  {
    id: '6f15d875e4300d6e5421',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35387&size=130',
  },
  {
    id: '06b09ed0a2954bcb1284',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35348&size=130',
  },
  {
    id: '1cdf85bfb9fa50a409eb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35349&size=130',
  },
  {
    id: 'dd9947f97bbc92e2cbad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35350&size=130',
  },
  {
    id: '5acdc1adfde814b64df9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35351&size=130',
  },
  {
    id: 'cda159c165848cdad595',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35352&size=130',
  },
  {
    id: '3603a3639f2676782f37',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35353&size=130',
  },
  {
    id: '08909ef0a2b54beb12a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35354&size=130',
  },
  {
    id: '80d717b72bf2c2ac9be3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35355&size=130',
  },
  {
    id: '5842c822f4671d394476',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35356&size=130',
  },
  {
    id: '4c2add4ae10f0851511e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35357&size=130',
  },
  {
    id: '85dd17bd2bf8c2a69be9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35358&size=130',
  },
  {
    id: '5117c277fe32176c4e23',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=35359&size=130',
  },
  {
    id: '18f30b503715de4b8704',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18847&size=130',
  },
  {
    id: 'e5a5c906f5431c1d4552',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18848&size=130',
  },
  {
    id: 'e576c8d5f4901dce4481',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18849&size=130',
  },
  {
    id: '14673ac40681efdfb690',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18850&size=130',
  },
  {
    id: '82c7ad649121787f2130',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18851&size=130',
  },
  {
    id: 'b9259186adc3449d1dd2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18852&size=130',
  },
  {
    id: 'de8ef72dcb6822367b79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18853&size=130',
  },
  {
    id: 'de03f4a0c8e521bb78f4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18854&size=130',
  },
  {
    id: 'adbd861eba5b53050a4a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18855&size=130',
  },
  {
    id: '94f3b0508c15654b3c04',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18856&size=130',
  },
  {
    id: 'df11fab2c6f72fa976e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18857&size=130',
  },
  {
    id: 'b4e19242ae0747591e16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18858&size=130',
  },
  {
    id: '53e9744a480fa151f81e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18859&size=130',
  },
  {
    id: 'ba869a25a6604f3e1671',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18860&size=130',
  },
  {
    id: '79f9585a641f8d41d40e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18861&size=130',
  },
  {
    id: 'f8d3da70e6350f6b5624',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18862&size=130',
  },
  {
    id: 'e667c5c4f98110df4990',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18863&size=130',
  },
  {
    id: '90deac7d903879662029',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18864&size=130',
  },
  {
    id: 'b0f68d55b110584e0101',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18865&size=130',
  },
  {
    id: 'ff57c1f4fdb114ef4da0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18866&size=130',
  },
  {
    id: '760b49a875ed9cb3c5fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18867&size=130',
  },
  {
    id: '8959b1fa8dbf64e13dae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18868&size=130',
  },
  {
    id: 'c0a8f90bc54e2c10755f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18869&size=130',
  },
  {
    id: '921da8be94fb7da524ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18870&size=130',
  },
  {
    id: '27701cd32096c9c89087',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18871&size=130',
  },
  {
    id: '0ece94aca8e941b718f8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34838&size=130',
  },
  {
    id: '623ef95cc5192c477508',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34839&size=130',
  },
  {
    id: '3eb5aad796927fcc2683',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34840&size=130',
  },
  {
    id: 'e7f372914ed4a78afec5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34841&size=130',
  },
  {
    id: 'ea647c064043a91df052',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34842&size=130',
  },
  {
    id: 'e10176634a26a378fa37',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34843&size=130',
  },
  {
    id: 'a72737450b00e25ebb11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34844&size=130',
  },
  {
    id: 'abee3a8c06c9ef97b6d8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34845&size=130',
  },
  {
    id: '96d104b338f6d1a888e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34846&size=130',
  },
  {
    id: 'e10772654e20a77efe31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34847&size=130',
  },
  {
    id: 'de7272104e55a70bfe44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34848&size=130',
  },
  {
    id: '3be09682aac743991ad6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34849&size=130',
  },
  {
    id: 'cf3161535d16b448ed07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34850&size=130',
  },
  {
    id: '1ff4b0968cd3658d3cc2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34851&size=130',
  },
  {
    id: 'c06d680f544abd14e45b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34852&size=130',
  },
  {
    id: 'fa2d534f6f0a8654df1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34853&size=130',
  },
  {
    id: 'cecd64af58eab1b4e8fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34854&size=130',
  },
  {
    id: '7dbed6dcea9903c75a88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34855&size=130',
  },
  {
    id: '86c122a31ee6f7b8aef7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34856&size=130',
  },
  {
    id: '34219143ad0644581d17',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34857&size=130',
  },
  {
    id: 'bb580c373072d92c8063',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34107&size=130',
  },
  {
    id: 'e59a55f569b080eed9a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34108&size=130',
  },
  {
    id: 'e1b050df6c9a85c4dc8b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34109&size=130',
  },
  {
    id: '515de332df7736296f66',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34110&size=130',
  },
  {
    id: 'e19f66fd5ab8b3e6eaa9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34827&size=130',
  },
  {
    id: '5375d317ef52060c5f43',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34828&size=130',
  },
  {
    id: 'e34c622e5e6bb735ee7a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34829&size=130',
  },
  {
    id: '6827ea45d6003f5e6611',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34830&size=130',
  },
  {
    id: 'ed5f6e3d5278bb26e269',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34831&size=130',
  },
  {
    id: 'e20f7e6d4228ab76f239',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34832&size=130',
  },
  {
    id: 'e06a7d08414da813f15c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34833&size=130',
  },
  {
    id: '035d9d3fa17a4824116b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34834&size=130',
  },
  {
    id: 'a78c38ee04abedf5b4ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34835&size=130',
  },
  {
    id: '5724cf46f3031a5d4312',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34836&size=130',
  },
  {
    id: '5383cae1f6a41ffa46b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34837&size=130',
  },
  {
    id: 'a3ccfba0c7e52ebb77f4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34516&size=130',
  },
  {
    id: '43131a7f263acf64962b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34517&size=130',
  },
  {
    id: 'd1208b4cb7095e570718',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34518&size=130',
  },
  {
    id: '8862d30eef4b06155f5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34519&size=130',
  },
  {
    id: 'a0fcf490c8d5218b78c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34520&size=130',
  },
  {
    id: 'b93bec57d012394c6003',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34521&size=130',
  },
  {
    id: 'd9918ffdb3b85ae603a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34522&size=130',
  },
  {
    id: '6ad13dbd01f8e8a6b1e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34523&size=130',
  },
  {
    id: 'e6f5b6998adc63823acd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34524&size=130',
  },
  {
    id: '571406783a3dd3638a2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34525&size=130',
  },
  {
    id: 'b4dfe6b3daf633a86ae7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34526&size=130',
  },
  {
    id: '6e0d3d610124e87ab135',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34527&size=130',
  },
  {
    id: '5d2931450d00e45ebd11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34528&size=130',
  },
  {
    id: '7d4810242c61c53f9c70',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34529&size=130',
  },
  {
    id: '100e7e624227ab79f236',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34530&size=130',
  },
  {
    id: 'f9d096bcaaf943a71ae8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34531&size=130',
  },
  {
    id: 'd36fbb0387466e183757',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34532&size=130',
  },
  {
    id: 'ff4f9623aa6643381a77',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34533&size=130',
  },
  {
    id: 'ed9087fcbbb952e70ba8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34534&size=130',
  },
  {
    id: 'c067ab0b974e7e10275f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34535&size=130',
  },
  {
    id: '74b510d92c9cc5c29c8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34536&size=130',
  },
  {
    id: 'f5e2908eaccb45951cda',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34537&size=130',
  },
  {
    id: 'ebee8d82b1c7589901d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34538&size=130',
  },
  {
    id: '26df41b37df694a8cde7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34539&size=130',
  },
  {
    id: '0fee1e8222c7cb9992d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34461&size=130',
  },
  {
    id: '1c700e1c3259db078248',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34462&size=130',
  },
  {
    id: '398d2ae116a4fffaa6b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34463&size=130',
  },
  {
    id: 'bb62970eab4b42151b5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34464&size=130',
  },
  {
    id: 'e198ccf4f0b119ef40a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34465&size=130',
  },
  {
    id: '8358ad349171782f2160',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34466&size=130',
  },
  {
    id: '567a79164553ac0df542',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34467&size=130',
  },
  {
    id: '018429e815adfcf3a5bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34468&size=130',
  },
  {
    id: '823fab5397167e482707',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34469&size=130',
  },
  {
    id: '17d23dbe01fbe8a5b1ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34470&size=130',
  },
  {
    id: '58e573894fcca692ffdd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34471&size=130',
  },
  {
    id: '422d66415a04b35aea15',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=34472&size=130',
  },
  {
    id: '80c6f9afc5ea2cb475fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33781&size=130',
  },
  {
    id: '4bd131b80dfde4a3bdec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33782&size=130',
  },
  {
    id: 'b669cd00f145181b4154',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33783&size=130',
  },
  {
    id: '4a4b3e220267eb39b276',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33784&size=130',
  },
  {
    id: 'da04af6d93287a762339',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33785&size=130',
  },
  {
    id: '54cf22a61ee3f7bdaef2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33786&size=130',
  },
  {
    id: 'bc1fcb76f7331e6d4722',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33787&size=130',
  },
  {
    id: '0ca67ccf408aa9d4f09b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33788&size=130',
  },
  {
    id: 'b0e5c18cfdc914974dd8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33789&size=130',
  },
  {
    id: '27ea558369c68098d9d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33790&size=130',
  },
  {
    id: '4d1e3e770232eb6cb223',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33791&size=130',
  },
  {
    id: '3736bb58871d6e43370c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=33792&size=130',
  },
  {
    id: 'a32843a67fe396bdcff2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25708&size=130',
  },
  {
    id: '448ea5009945701b2954',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25709&size=130',
  },
  {
    id: 'a25c40d27c9795c9cc86',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25710&size=130',
  },
  {
    id: '9bfe78704435ad6bf424',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25711&size=130',
  },
  {
    id: '3068cce6f0a319fd40b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25712&size=130',
  },
  {
    id: '9b3466ba5affb3a1eaee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25713&size=130',
  },
  {
    id: 'aff4517a6d3f8461dd2e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25714&size=130',
  },
  {
    id: '2c0ed380efc5069b5fd4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25715&size=130',
  },
  {
    id: '1cd5e45bd81e3140680f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25716&size=130',
  },
  {
    id: '9d4d64c35886b1d8e897',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25717&size=130',
  },
  {
    id: 'd4242eaa12effbb1a2fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25718&size=130',
  },
  {
    id: 'f5dc0e523217db498206',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25719&size=130',
  },
  {
    id: 'f0c0044e380bd155881a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25720&size=130',
  },
  {
    id: 'b29947177b52920ccb43',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25721&size=130',
  },
  {
    id: 'f00d06833ac6d3988ad7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25722&size=130',
  },
  {
    id: 'ef09188724c2cd9c94d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25723&size=130',
  },
  {
    id: '0726f7a8cbed22b37bfc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25724&size=130',
  },
  {
    id: '490eb88084c56d9b34d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25725&size=130',
  },
  {
    id: '9c626eec52a9bbf7e2b8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25726&size=130',
  },
  {
    id: 'c201318f0dcae494bddb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25727&size=130',
  },
  {
    id: '0454463e7a7b9325ca6a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32974&size=130',
  },
  {
    id: 'd3499023ac6645381c77',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32975&size=130',
  },
  {
    id: '1cfc40967cd3958dccc2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32976&size=130',
  },
  {
    id: 'd815857fb93a5064092b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32977&size=130',
  },
  {
    id: '34b46ade569bbfc5e68a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32978&size=130',
  },
  {
    id: '73db2cb110f4f9aaa0e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32979&size=130',
  },
  {
    id: 'd7ca8fa0b3e55abb03f4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32980&size=130',
  },
  {
    id: 'ba62e308df4d36136f5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32981&size=130',
  },
  {
    id: '727828121457fd09a446',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32982&size=130',
  },
  {
    id: 'f3f2a89894dd7d8324cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32983&size=130',
  },
  {
    id: '2a4b7e214264ab3af275',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32984&size=130',
  },
  {
    id: 'b059e533d97630286967',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32985&size=130',
  },
  {
    id: 'd0ecf886c4c32d9d74d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32932&size=130',
  },
  {
    id: 'b0b099daa59f4cc1158e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32933&size=130',
  },
  {
    id: '9c73b6198a5c63023a4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32934&size=130',
  },
  {
    id: '75705e1a625f8b01d24e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32935&size=130',
  },
  {
    id: '214c05263963d03d8972',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32936&size=130',
  },
  {
    id: 'a25c8736bb73522d0b62',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32937&size=130',
  },
  {
    id: '341f12752e30c76e9e21',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32938&size=130',
  },
  {
    id: 'abd38cb9b0fc59a200ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32939&size=130',
  },
  {
    id: 'aed38eb9b2fc5ba202ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32940&size=130',
  },
  {
    id: '3ed81fb223f7caa993e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32941&size=130',
  },
  {
    id: 'f4e7d68deac803965ad9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32942&size=130',
  },
  {
    id: '9677b51d895860063949',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32943&size=130',
  },
  {
    id: '66035a69662c8f72d63d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32944&size=130',
  },
  {
    id: '7c5941337d769428cd67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32945&size=130',
  },
  {
    id: 'b8e28688bacd53930adc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32946&size=130',
  },
  {
    id: 'e7afd8c5e4800dde5491',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32947&size=130',
  },
  {
    id: 'd5fe24521817f149a806',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18045&size=130',
  },
  {
    id: 'a8175abb66fe8fa0d6ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18046&size=130',
  },
  {
    id: 'b71844b478f191afc8e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18047&size=130',
  },
  {
    id: 'a5fca95095157c4b2504',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18048&size=130',
  },
  {
    id: 'adc4a0689c2d75732c3c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18049&size=130',
  },
  {
    id: '289426381a7df323aa6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18050&size=130',
  },
  {
    id: '231b2cb710f2f9aca0e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18051&size=130',
  },
  {
    id: '585850f46cb185efdca0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18052&size=130',
  },
  {
    id: 'c283cb2ff76a1e34477b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18053&size=130',
  },
  {
    id: 'db9fd133ed7604285d67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18054&size=130',
  },
  {
    id: '05a00e0c3249db178258',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18055&size=130',
  },
  {
    id: '73d977754b30a26efb21',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18056&size=130',
  },
  {
    id: 'fca1f90dc5482c167559',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18057&size=130',
  },
  {
    id: '331335bf09fae0a4b9eb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18058&size=130',
  },
  {
    id: '93ad9401a844411a1855',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18059&size=130',
  },
  {
    id: '35ca35660923e07db932',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18060&size=130',
  },
  {
    id: '04439fd4a3914acf1380',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32023&size=130',
  },
  {
    id: '5f8dcb1af75f1e01474e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32024&size=130',
  },
  {
    id: '68fafd6dc12828767139',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32025&size=130',
  },
  {
    id: 'ad4c3bdb079eeec0b78f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32026&size=130',
  },
  {
    id: '79ffee68d22d3b73623c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32027&size=130',
  },
  {
    id: '0b079b90a7d54e8b17c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32028&size=130',
  },
  {
    id: '5909c89ef4db1d8544ca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32029&size=130',
  },
  {
    id: '912303b43ff1d6af8fe0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32030&size=130',
  },
  {
    id: '3a65a9f295b77ce925a6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32031&size=130',
  },
  {
    id: '1fd2b3458f00665e3f11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32032&size=130',
  },
  {
    id: '0b97a6009a45731b2a54',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32033&size=130',
  },
  {
    id: 'a50b0b9c37d9de8787c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32034&size=130',
  },
  {
    id: '5360fcf7c0b229ec70a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32035&size=130',
  },
  {
    id: '892521b21df7f4a9ade6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32036&size=130',
  },
  {
    id: '8d0d249a18dff181a8ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32037&size=130',
  },
  {
    id: '04a2ae3592707b2e2261',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32038&size=130',
  },
  {
    id: '6542ced5f2901bce4281',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32039&size=130',
  },
  {
    id: 'df537bc44781aedff790',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32040&size=130',
  },
  {
    id: 'd23477a34be6a2b8fbf7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32041&size=130',
  },
  {
    id: '3d069b91a7d44e8a17c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32042&size=130',
  },
  {
    id: '667cc1ebfdae14f04dbf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32043&size=130',
  },
  {
    id: '357995eea9ab40f519ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32044&size=130',
  },
  {
    id: '287189e6b5a35cfd05b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32045&size=130',
  },
  {
    id: '855527c21b87f2d9ab96',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32046&size=130',
  },
  {
    id: 'a4c70f513314da4a8305',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31783&size=130',
  },
  {
    id: 'df8c7b1a475fae01f74e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31784&size=130',
  },
  {
    id: '5f8bfa1dc6582f067649',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31785&size=130',
  },
  {
    id: '89f82f6e132bfa75a33a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31786&size=130',
  },
  {
    id: 'cb476cd15094b9cae085',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31787&size=130',
  },
  {
    id: '7403d495e8d0018e58c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31788&size=130',
  },
  {
    id: '65d2c444f801115f4810',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31789&size=130',
  },
  {
    id: '4cd2ee44d2013b5f6210',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31790&size=130',
  },
  {
    id: 'a35000c63c83d5dd8c92',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31791&size=130',
  },
  {
    id: '16ccaa5a961f7f41260e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31792&size=130',
  },
  {
    id: 'c6207bb647f3aeadf7e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31793&size=130',
  },
  {
    id: 'dce562735e36b768ee27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31794&size=130',
  },
  {
    id: 'f77980e9bcac55f20cbd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31483&size=130',
  },
  {
    id: '542324b318f6f1a8a8e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31484&size=130',
  },
  {
    id: '6eb11f212364ca3a9375',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31485&size=130',
  },
  {
    id: '2ed65c466003895dd012',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31486&size=130',
  },
  {
    id: '6a3119a125e4ccba95f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31487&size=130',
  },
  {
    id: 'b5a039310574ec2ab565',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31488&size=130',
  },
  {
    id: '396cb4fd88b861e638a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31489&size=130',
  },
  {
    id: '63b3ed22d16738396176',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31490&size=130',
  },
  {
    id: '5033dfa2e3e70ab953f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31491&size=130',
  },
  {
    id: '2640aed192947bca2285',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31492&size=130',
  },
  {
    id: 'dbdf524e6e0b8755de1a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31493&size=130',
  },
  {
    id: '224ca8dd94987dc62489',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31494&size=130',
  },
  {
    id: '294ca2dd9e9877c62e89',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31495&size=130',
  },
  {
    id: 'a5ae213f1d7af424ad6b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31496&size=130',
  },
  {
    id: '668ee31fdf5a36046f4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31497&size=130',
  },
  {
    id: '46d9c048fc0d15534c1c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31498&size=130',
  },
  {
    id: '9b731ce220a7c9f990b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31499&size=130',
  },
  {
    id: '370bb79a8bdf62813bce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31500&size=130',
  },
  {
    id: '7cdefd4fc10a2854711b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31501&size=130',
  },
  {
    id: 'c711458079c5909bc9d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31502&size=130',
  },
  {
    id: 'a6f3594265078c59d516',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23411&size=130',
  },
  {
    id: '6e319680aac5439b1ad4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23412&size=130',
  },
  {
    id: '4761bed082956bcb3284',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23413&size=130',
  },
  {
    id: '56e5ac549011794f2000',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23414&size=130',
  },
  {
    id: '2e3ad58be9ce009059df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23415&size=130',
  },
  {
    id: '1d31e980d5c53c9b65d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23416&size=130',
  },
  {
    id: 'baf64f4773029a5cc313',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23417&size=130',
  },
  {
    id: 'af20599165d48c8ad5c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23418&size=130',
  },
  {
    id: 'bb364c8770c2999cc0d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23419&size=130',
  },
  {
    id: '1ee1ee50d2153b4b6204',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23420&size=130',
  },
  {
    id: '40c8b1798d3c64623d2d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23421&size=130',
  },
  {
    id: '28d6da67e6220f7c5633',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23422&size=130',
  },
  {
    id: '28afdb1ee75b0e05574a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23423&size=130',
  },
  {
    id: '1e1712a62ee3c7bd9ef2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23424&size=130',
  },
  {
    id: '4c3341827dc79499cdd6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23425&size=130',
  },
  {
    id: '1ae014512814c14a9805',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23426&size=130',
  },
  {
    id: '276128d01495fdcba484',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23427&size=130',
  },
  {
    id: '885a80ebbcae55f00cbf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23428&size=130',
  },
  {
    id: 'dd2dd49ce8d9018758c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23429&size=130',
  },
  {
    id: '935699e7a5a24cfc15b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23430&size=130',
  },
  {
    id: 'b430bf8183c46a9a33d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23431&size=130',
  },
  {
    id: '088a0c3b307ed920806f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23432&size=130',
  },
  {
    id: 'b8d1bd608125687b3134',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23433&size=130',
  },
  {
    id: '3bc13d700135e86bb124',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23434&size=130',
  },
  {
    id: 'b766b0d78c9265cc3c83',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23435&size=130',
  },
  {
    id: '5634af8893cd7a9323dc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22133&size=130',
  },
  {
    id: '71c08b7cb7395e670728',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22134&size=130',
  },
  {
    id: '3d84c638fa7d13234a6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22135&size=130',
  },
  {
    id: '50f4a448980d7153281c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22136&size=130',
  },
  {
    id: '5f24aa9896dd7f8326cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22137&size=130',
  },
  {
    id: 'a56353df6f9a86c4df8b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22138&size=130',
  },
  {
    id: '9ea2691e555bbc05e54a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22139&size=130',
  },
  {
    id: 'cbb63b0a074fee11b75e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22140&size=130',
  },
  {
    id: '697498c8a48d4dd3149c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22141&size=130',
  },
  {
    id: 'f85c0ae036a5dffb86b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22142&size=130',
  },
  {
    id: 'ba20499c75d99c87c5c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22143&size=130',
  },
  {
    id: '7dca71764d33a46dfd22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22144&size=130',
  },
  {
    id: '92369f8aa3cf4a9113de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22145&size=130',
  },
  {
    id: '6a946428586db133e87c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22146&size=130',
  },
  {
    id: 'f137fe8bc2ce2b9072df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22147&size=130',
  },
  {
    id: '1bdc13602f25c67b9f34',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22148&size=130',
  },
  {
    id: 'aecea7729b3772692b26',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22149&size=130',
  },
  {
    id: 'e196eb2ad76f3e31677e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22150&size=130',
  },
  {
    id: 'f227f99bc5de2c8075cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22151&size=130',
  },
  {
    id: '8e1a8aa6b6e35fbd06f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22152&size=130',
  },
  {
    id: 'fe26a9cf958a7cd4259b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=987&size=130',
  },
  {
    id: 'eb9cbb7587306e6e3721',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=988&size=130',
  },
  {
    id: 'b432e5dbd99e30c0698f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=989&size=130',
  },
  {
    id: '892ddbc4e7810edf5790',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=990&size=130',
  },
  {
    id: 'df4f8ca6b0e359bd00f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=991&size=130',
  },
  {
    id: 'f2849e6da2284b761239',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=992&size=130',
  },
  {
    id: 'd9a5b44c880961573818',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=993&size=130',
  },
  {
    id: '3bb3555a691f8041d90e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=994&size=130',
  },
  {
    id: '95f4fa1dc6582f067649',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=995&size=130',
  },
  {
    id: '43cc2b251760fe3ea771',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=996&size=130',
  },
  {
    id: '32375bde679b8ec5d78a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=997&size=130',
  },
  {
    id: 'dc42b6ab8aee63b03aff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=998&size=130',
  },
  {
    id: '49eb22021e47f719ae56',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=999&size=130',
  },
  {
    id: '36e7520e6e4b8715de5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1000&size=130',
  },
  {
    id: '9b4afea3c2e62bb872f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1001&size=130',
  },
  {
    id: '406026891accf392aadd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1002&size=130',
  },
  {
    id: '9a8ffd66c123287d7132',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1003&size=130',
  },
  {
    id: 'fe939e7aa23f4b61122e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1004&size=130',
  },
  {
    id: 'bb7eda97e6d20f8c56c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1005&size=130',
  },
  {
    id: '1e287cc14084a9daf095',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1006&size=130',
  },
  {
    id: '1b9a31080d4de413bd5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30886&size=130',
  },
  {
    id: '858aae18925d7b03224c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30887&size=130',
  },
  {
    id: '542770b54cf0a5aefce1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30888&size=130',
  },
  {
    id: '88c5ad579112784c2103',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30889&size=130',
  },
  {
    id: 'fbcfdd5de11808465109',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30890&size=130',
  },
  {
    id: '4c376ba557e0bebee7f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30891&size=130',
  },
  {
    id: '4f7b6fe953acbaf2e3bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30892&size=130',
  },
  {
    id: 'c0dce14edd0b34556d1a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30893&size=130',
  },
  {
    id: 'ec40ced2f2971bc94286',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30894&size=130',
  },
  {
    id: 'a12c82bebefb57a50eea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30895&size=130',
  },
  {
    id: '8148bdda819f68c1318e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30896&size=130',
  },
  {
    id: 'b2158f87b3c25a9c03d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30897&size=130',
  },
  {
    id: '852abbb887fd6ea337ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30898&size=130',
  },
  {
    id: '484377d14b94a2cafb85',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30899&size=130',
  },
  {
    id: '6e2a56b86afd83a3daec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30900&size=130',
  },
  {
    id: 'fba1c233fe7617284e67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30901&size=130',
  },
  {
    id: 'f53bcfa9f3ec1ab243fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30902&size=130',
  },
  {
    id: 'd8d8e34adf0f36516f1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30903&size=130',
  },
  {
    id: 'd0ffe46dd82831766839',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30904&size=130',
  },
  {
    id: '64f5d367ef22067c5f33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30779&size=130',
  },
  {
    id: 'a8be182c2469cd379478',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30780&size=130',
  },
  {
    id: '9f772ee512a0fbfea2b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30781&size=130',
  },
  {
    id: '327580e7bca255fc0cb3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30782&size=130',
  },
  {
    id: '85fb36690a2ce372ba3d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30783&size=130',
  },
  {
    id: '6dbca12e9d6b74352d7a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30784&size=130',
  },
  {
    id: '94bd592f656a8c34d57b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30785&size=130',
  },
  {
    id: '9e8b50196c5c8502dc4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30786&size=130',
  },
  {
    id: 'ca2c4cb170f499aac0e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30474&size=130',
  },
  {
    id: 'a7c3205e1c1bf545ac0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30475&size=130',
  },
  {
    id: '984218df249acdc4948b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30476&size=130',
  },
  {
    id: 'c77546e87aad93f3cabc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30477&size=130',
  },
  {
    id: 'b9413bdc0799eec7b788',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30478&size=130',
  },
  {
    id: '7004f399cfdc26827fcd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30479&size=130',
  },
  {
    id: '79dce541d904305a6915',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30480&size=130',
  },
  {
    id: '3b1fa6829ac773992ad6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30481&size=130',
  },
  {
    id: '3c60a2fd9eb877e62ea9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30482&size=130',
  },
  {
    id: '2fe5b0788c3d65633c2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30483&size=130',
  },
  {
    id: 'ea3272af4eeaa7b4fefb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30484&size=130',
  },
  {
    id: 'a6fe3f630326ea78b337',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30485&size=130',
  },
  {
    id: 'b4842e19125cfb02a24d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30486&size=130',
  },
  {
    id: '4f25d4b8e8fd01a358ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30487&size=130',
  },
  {
    id: '6f74fbe9c7ac2ef277bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30488&size=130',
  },
  {
    id: '3877adea91af78f121be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30489&size=130',
  },
  {
    id: 'de198487b8c2519c08d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29910&size=130',
  },
  {
    id: '20d77b49470cae52f71d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29911&size=130',
  },
  {
    id: '53d807463b03d25d8b12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29912&size=130',
  },
  {
    id: 'acdff941c5042c5a7515',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29913&size=130',
  },
  {
    id: '83ccd552e91700495906',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29914&size=130',
  },
  {
    id: 'e1e0b67e8a3b63653a2a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29915&size=130',
  },
  {
    id: 'd3f1836fbf2a56740f3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29916&size=130',
  },
  {
    id: '2f857e1b425eab00f24f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29917&size=130',
  },
  {
    id: '576305fd39b8d0e689a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29918&size=130',
  },
  {
    id: '802ed3b0eff506ab5fe4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29919&size=130',
  },
  {
    id: '4aa926371a72f32caa63',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29920&size=130',
  },
  {
    id: '1d4970d74c92a5ccfc83',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29921&size=130',
  },
  {
    id: 'f4499ad7a6924fcc1683',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29922&size=130',
  },
  {
    id: 'e5f08a6eb62b5f75063a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29923&size=130',
  },
  {
    id: 'ecf68468b82d5173083c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29924&size=130',
  },
  {
    id: 'c9c7a0599c1c75422c0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29925&size=130',
  },
  {
    id: 'f97a93e4afa146ff1fb0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29926&size=130',
  },
  {
    id: 'acd9c747fb02125c4b13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29927&size=130',
  },
  {
    id: 'f4d4904aac0f45511c1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29928&size=130',
  },
  {
    id: '124277dc4b99a2c7fb88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29929&size=130',
  },
  {
    id: 'cdd9ab4797027e5c2713',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29930&size=130',
  },
  {
    id: 'ed7fa9e695a37cfd25b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29640&size=130',
  },
  {
    id: 'beebfb72c7372e697726',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29641&size=130',
  },
  {
    id: 'bf01f998c5dd2c8375cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29642&size=130',
  },
  {
    id: '0a814d18715d9803c14c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29643&size=130',
  },
  {
    id: '0dfb4d6271279879c136',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29644&size=130',
  },
  {
    id: '2de66c7f503ab964e02b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29645&size=130',
  },
  {
    id: '503712ae2eebc7b59efa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29646&size=130',
  },
  {
    id: '19f65a6f662a8f74d63b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29647&size=130',
  },
  {
    id: 'a5f4f96dc5282c767539',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29648&size=130',
  },
  {
    id: 'e646bbdf879a6ec4378b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29649&size=130',
  },
  {
    id: '81a1df38e37d0a23536c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29650&size=130',
  },
  {
    id: '1c2443bd7ff896a6cfe9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29651&size=130',
  },
  {
    id: '7f4927d01b95f2cbab84',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29652&size=130',
  },
  {
    id: '0f4e56d76a9283ccda83',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29653&size=130',
  },
  {
    id: 'b5a7ef3ed37b3a25636a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29654&size=130',
  },
  {
    id: '75122e8b12cefb90a2df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29655&size=130',
  },
  {
    id: '3294660d5a48b316ea59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29656&size=130',
  },
  {
    id: '93aec637fa72132c4a63',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29657&size=130',
  },
  {
    id: 'bb2aedb3d1f638a861e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29658&size=130',
  },
  {
    id: 'bef2e96bd52e3c70653f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29659&size=130',
  },
  {
    id: 'a43cf4a5c8e021be78f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29660&size=130',
  },
  {
    id: 'dd538ccab08f59d1009e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29661&size=130',
  },
  {
    id: '975bc5c2f98710d94996',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29662&size=130',
  },
  {
    id: '45d7164e2a0bc3559a1a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29663&size=130',
  },
  {
    id: 'afc6c35fff1a16444f0b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29664&size=130',
  },
  {
    id: '66470bde379bdec5878a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29665&size=130',
  },
  {
    id: 'f14a9fd3a3964ac81387',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29666&size=130',
  },
  {
    id: '97a6f83fc47a2d24746b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29667&size=130',
  },
  {
    id: '78c6105f2c1ac5449c0b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29668&size=130',
  },
  {
    id: '82e3eb7ad73f3e61672e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29669&size=130',
  },
  {
    id: 'f46c46f47ab193efcaa0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29246&size=130',
  },
  {
    id: 'f54946d17a9493caca85',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29247&size=130',
  },
  {
    id: '0edcc244fe01175f4e10',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29248&size=130',
  },
  {
    id: 'ba6177f94bbca2e2fbad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29249&size=130',
  },
  {
    id: '540e9a96a6d34f8d16c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29250&size=130',
  },
  {
    id: '7adab542890760593916',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29251&size=130',
  },
  {
    id: '2861e0f9dcbc35e26cad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29252&size=130',
  },
  {
    id: '15dcdc44e001095f5010',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29253&size=130',
  },
  {
    id: 'eba021381d7df423ad6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29254&size=130',
  },
  {
    id: 'e312288a14cffd91a4de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29255&size=130',
  },
  {
    id: '2fb3eb2bd76e3e30677f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29256&size=130',
  },
  {
    id: '9c8a591265578c09d546',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29257&size=130',
  },
  {
    id: '48a68e3eb27b5b25026a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29258&size=130',
  },
  {
    id: '9c285bb067f58eabd7e4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29259&size=130',
  },
  {
    id: '59a3993ba57e4c20156f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29260&size=130',
  },
  {
    id: 'dcb31d2b216ec830917f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29261&size=130',
  },
  {
    id: '1fe9dd71e134086a5125',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29262&size=130',
  },
  {
    id: '944157d96b9c82c2db8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=29263&size=130',
  },
  {
    id: '4c2214a728e2c1bc98f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28628&size=130',
  },
  {
    id: 'bc02e587d9c2309c69d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28629&size=130',
  },
  {
    id: '71ea2b6f172afe74a73b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28630&size=130',
  },
  {
    id: 'f232a9b795f27cac25e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28631&size=130',
  },
  {
    id: '1a3e4ebb72fe9ba0c2ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28632&size=130',
  },
  {
    id: '3d4268c75482bddce493',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28633&size=130',
  },
  {
    id: '68403ec50280ebdeb291',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28634&size=130',
  },
  {
    id: '07e050656c20857edc31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28635&size=130',
  },
  {
    id: '0e8c5e09624c8b12d25d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28636&size=130',
  },
  {
    id: 'b01be19edddb34856dca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28637&size=130',
  },
  {
    id: '39966b135756be08e747',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28638&size=130',
  },
  {
    id: 'a691f514c951200f7940',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28639&size=130',
  },
  {
    id: '1a5d76d84a9da3c3fa8c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28640&size=130',
  },
  {
    id: '64aa092f356adc34857b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28641&size=130',
  },
  {
    id: '924bfccec08b29d5709a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28642&size=130',
  },
  {
    id: '372a58af64ea8db4d4fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28643&size=130',
  },
  {
    id: '47a54d207165983bc174',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28550&size=130',
  },
  {
    id: '42a5492075659c3bc574',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28551&size=130',
  },
  {
    id: 'a3bda7389b7d72232b6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28552&size=130',
  },
  {
    id: '6b4d6ec8528dbbd3e29c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28553&size=130',
  },
  {
    id: '25d423511f14f64aaf05',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28554&size=130',
  },
  {
    id: '082f0faa33efdab183fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28555&size=130',
  },
  {
    id: '03aa032f3f6ad6348f7b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28556&size=130',
  },
  {
    id: 'c76fc6eafaaf13f14abe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28557&size=130',
  },
  {
    id: '7a03788644c3ad9df4d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28558&size=130',
  },
  {
    id: '846587e0bba552fb0bb4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28559&size=130',
  },
  {
    id: '2fb433310f74e62abf65',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28560&size=130',
  },
  {
    id: '02191f9c23d9ca8793c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28561&size=130',
  },
  {
    id: '7d3263b75ff2b6acefe3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28562&size=130',
  },
  {
    id: '50374fb273f79aa9c3e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28563&size=130',
  },
  {
    id: '11e709623527dc798536',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28564&size=130',
  },
  {
    id: 'a4eebd6b812e6870313f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28565&size=130',
  },
  {
    id: 'ac6576e04aa5a3fbfab4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28502&size=130',
  },
  {
    id: 'b5866e035246bb18e257',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28503&size=130',
  },
  {
    id: '3a43eec6d2833bdd6292',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28504&size=130',
  },
  {
    id: '868653036f468618df57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28505&size=130',
  },
  {
    id: '115ec7dbfb9e12c04b8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28506&size=130',
  },
  {
    id: '9dc74a4276079f59c616',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28507&size=130',
  },
  {
    id: '018fd10aed4f04115d5e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28508&size=130',
  },
  {
    id: 'd75e06db3a9ed3c08a8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28509&size=130',
  },
  {
    id: '8e905c156050890ed041',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28510&size=130',
  },
  {
    id: '7698a51d995870062949',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28511&size=130',
  },
  {
    id: '3b03d786ebc3029d5bd2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28512&size=130',
  },
  {
    id: '3af8d77deb3802665b29',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28513&size=130',
  },
  {
    id: '69aa872fbb6a52340b7b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28514&size=130',
  },
  {
    id: 'e9e106643a21d37f8a30',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28515&size=130',
  },
  {
    id: 'a9a441217d64943acd75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28516&size=130',
  },
  {
    id: '342eddabe1ee08b051ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28517&size=130',
  },
  {
    id: '7a351ab126f4cfaa96e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28396&size=130',
  },
  {
    id: '82cee34adf0f36516f1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28397&size=130',
  },
  {
    id: 'b420d6a4eae103bf5af0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28398&size=130',
  },
  {
    id: 'dd25bea182e46bba32f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28399&size=130',
  },
  {
    id: '9033ecb7d0f239ac60e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28400&size=130',
  },
  {
    id: 'fa178793bbd652880bc7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28401&size=130',
  },
  {
    id: '379d4919755c9c02c54d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28402&size=130',
  },
  {
    id: '2719589d64d88d86d4c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28403&size=130',
  },
  {
    id: 'c529bdad81e868b631f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28404&size=130',
  },
  {
    id: '44763df201b7e8e9b1a6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28405&size=130',
  },
  {
    id: '6deb176f2b2ac2749b3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28406&size=130',
  },
  {
    id: '8693fd17c152280c7143',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28407&size=130',
  },
  {
    id: 'e91a9d9ea1db488511ca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28408&size=130',
  },
  {
    id: 'f4d38157bd12544c0d03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28409&size=130',
  },
  {
    id: 'ba6dcce9f0ac19f240bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28410&size=130',
  },
  {
    id: '8a27fda3c1e628b871f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28411&size=130',
  },
  {
    id: '7cef0c6b302ed970803f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28412&size=130',
  },
  {
    id: '884ff9cbc58e2cd0759f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28413&size=130',
  },
  {
    id: '335341d77d9294cccd83',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28414&size=130',
  },
  {
    id: 'c08cb3088f4d66133f5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28415&size=130',
  },
  {
    id: 'd1585ddd619888c6d189',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28416&size=130',
  },
  {
    id: '9ad017552b10c24e9b01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28417&size=130',
  },
  {
    id: 'd4405ac566808fded691',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28418&size=130',
  },
  {
    id: 'a908268d1ac8f396aad9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28419&size=130',
  },
  {
    id: 'c6faaa7e963b7f65262a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28384&size=130',
  },
  {
    id: 'f76b9aefa6aa4ff416bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28385&size=130',
  },
  {
    id: '55db3b5f071aee44b70b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28386&size=130',
  },
  {
    id: '0cd663525f17b649ef06',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28387&size=130',
  },
  {
    id: 'b562dde6e1a308fd51b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28388&size=130',
  },
  {
    id: '1be272664e23a77dfe32',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28389&size=130',
  },
  {
    id: 'af3fc5bbf9fe10a049ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28390&size=130',
  },
  {
    id: '6310089434d1dd8f84c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28391&size=130',
  },
  {
    id: 'b4fad07eec3b05655c2a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28392&size=130',
  },
  {
    id: '103b75bf49faa0a4f9eb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28393&size=130',
  },
  {
    id: 'afa2c926f5631c3d4572',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28394&size=130',
  },
  {
    id: '429b251f195af004a94b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28395&size=130',
  },
  {
    id: '5c8e180a244fcd11945e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28360&size=130',
  },
  {
    id: '9940dcc4e08109df5090',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28361&size=130',
  },
  {
    id: 'b8d2fe56c2132b4d7202',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28362&size=130',
  },
  {
    id: '31b9763d4a78a326fa69',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28363&size=130',
  },
  {
    id: '801fc09bfcde15804ccf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28364&size=130',
  },
  {
    id: '7a503bd40791eecfb780',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28365&size=130',
  },
  {
    id: '115753d36f9686c8df87',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28366&size=130',
  },
  {
    id: '735e30da0c9fe5c1bc8e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28367&size=130',
  },
  {
    id: '40be1c3a207fc921906e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28368&size=130',
  },
  {
    id: 'd6718bf5b7b05eee07a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28369&size=130',
  },
  {
    id: '195447d07b9592cbcb84',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28370&size=130',
  },
  {
    id: '70c02f441301fa5fa310',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28371&size=130',
  },
  {
    id: 'a196f912c5572c097546',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28372&size=130',
  },
  {
    id: '31b068345471bd2fe460',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28373&size=130',
  },
  {
    id: '50a80a2c3669df378678',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28374&size=130',
  },
  {
    id: 'b2a5e921d5643c3a6575',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28375&size=130',
  },
  {
    id: '90d0c454f811114f4800',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28376&size=130',
  },
  {
    id: '131446907ad5938bcac4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28377&size=130',
  },
  {
    id: 'b681e005dc40351e6c51',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28378&size=130',
  },
  {
    id: '112946ad7ae893b6caf9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28379&size=130',
  },
  {
    id: '8561d5e5e9a000fe59b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28380&size=130',
  },
  {
    id: 'ad6efceac0af29f170be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28381&size=130',
  },
  {
    id: '596e0bea37afdef187be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28382&size=130',
  },
  {
    id: 'c13c92b8aefd47a31eec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28383&size=130',
  },
  {
    id: '89a83d2c0169e837b178',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28216&size=130',
  },
  {
    id: '8bd33e570212eb4cb203',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28217&size=130',
  },
  {
    id: 'cc617ae546a0affef6b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28218&size=130',
  },
  {
    id: '98b22f361373fa2da362',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28219&size=130',
  },
  {
    id: 'fc694ced70a899f6c0b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28220&size=130',
  },
  {
    id: '025eb3da8f9f66c13f8e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28221&size=130',
  },
  {
    id: '2ce79e63a2264b781237',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28222&size=130',
  },
  {
    id: '24fb977fab3a42641b2b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28223&size=130',
  },
  {
    id: '90c45c406005895bd014',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28224&size=130',
  },
  {
    id: 'fa2f37ab0beee2b0bbff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28225&size=130',
  },
  {
    id: '0e02c086fcc3159d4cd2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28226&size=130',
  },
  {
    id: '1004df80e3c50a9b53d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28227&size=130',
  },
  {
    id: 'fd7135f509b0e0eeb9a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28228&size=130',
  },
  {
    id: 'c3360ab236f7dfa986e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28229&size=130',
  },
  {
    id: '8ecb444f780a9154c81b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28230&size=130',
  },
  {
    id: 'c22809ac35e9dcb785f8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28231&size=130',
  },
  {
    id: '37e3f367cf22267c7f33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28232&size=130',
  },
  {
    id: '9f645ae066a58ffbd6b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28233&size=130',
  },
  {
    id: '545892dcae9947c71e88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28234&size=130',
  },
  {
    id: '3efaf97ec53b2c65752a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=28235&size=130',
  },
  {
    id: 'b361c68afacf13914ade',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=505&size=130',
  },
  {
    id: 'b3bcc557f912104c4903',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=506&size=130',
  },
  {
    id: 'b5cbc220fe65173b4e74',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=507&size=130',
  },
  {
    id: '66ca16212a64c33a9a75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=508&size=130',
  },
  {
    id: '1881696a552fbc71e53e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=509&size=130',
  },
  {
    id: '2dc15e2a626f8b31d27e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=511&size=130',
  },
  {
    id: '5f90d378ef3d06635f2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=512&size=130',
  },
  {
    id: '307abd9281d7688931c6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=513&size=130',
  },
  {
    id: '0d2083c8bf8d56d30f9c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=514&size=130',
  },
  {
    id: '8f5600be3cfbd5a58cea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=515&size=130',
  },
  {
    id: 'db3053d86f9d86c3df8c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=516&size=130',
  },
  {
    id: 'c7f54e1d72589b06c249',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=517&size=130',
  },
  {
    id: '85b90f513314da4a8305',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=518&size=130',
  },
  {
    id: '3402bfea83af6af133be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=519&size=130',
  },
  {
    id: 'a66e22861ec3f79daed2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=520&size=130',
  },
  {
    id: 'e50652806ec5879bded4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27707&size=130',
  },
  {
    id: '7599c51ff95a1004494b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27708&size=130',
  },
  {
    id: 'a43015b629f3c0ad99e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27709&size=130',
  },
  {
    id: '9f502dd61193f8cda182',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27710&size=130',
  },
  {
    id: '613fd2b9eefc07a25eed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27711&size=130',
  },
  {
    id: 'd2071e8122c4cb9a92d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27712&size=130',
  },
  {
    id: '347bf9fdc5b82ce675a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27713&size=130',
  },
  {
    id: '1a9ed418e85d0103584c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27714&size=130',
  },
  {
    id: 'e85427d21b97f2c9ab86',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27715&size=130',
  },
  {
    id: 'f34f3bc9078ceed2b79d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27716&size=130',
  },
  {
    id: 'cfb606303a75d32b8a64',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27717&size=130',
  },
  {
    id: 'b1497bcf478aaed4f79b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27718&size=130',
  },
  {
    id: 'b0407bc64783aeddf792',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27719&size=130',
  },
  {
    id: '75feb1788d3d64633d2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27720&size=130',
  },
  {
    id: 'dcd519532516cc489507',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27721&size=130',
  },
  {
    id: '1786d100ed45041b5d54',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27722&size=130',
  },
  {
    id: '573590b3acf645a81ce7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27723&size=130',
  },
  {
    id: '6e23aea592e07bbe22f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27724&size=130',
  },
  {
    id: '823c43ba7fff96a1cfee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27725&size=130',
  },
  {
    id: 'cd170f9133d4da8a83c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27726&size=130',
  },
  {
    id: '3edefd58c11d2843710c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27727&size=130',
  },
  {
    id: 'e5bf3939057cec22b56d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27728&size=130',
  },
  {
    id: '3f33e2b5def037ae6ee1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27729&size=130',
  },
  {
    id: '7fb3a1359d70742e2d61',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27730&size=130',
  },
  {
    id: 'b8831f052340ca1e9351',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27691&size=130',
  },
  {
    id: '07cea7489b0d72532b1c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27692&size=130',
  },
  {
    id: 'a59604103855d10b8844',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27693&size=130',
  },
  {
    id: '670cc58af9cf109149de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27694&size=130',
  },
  {
    id: '4deeee68d22d3b73623c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27695&size=130',
  },
  {
    id: '557ce9fad5bf3ce165ae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27696&size=130',
  },
  {
    id: 'a96914ef28aac1f498bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27697&size=130',
  },
  {
    id: '6ae9d46fe82a0174583b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27698&size=130',
  },
  {
    id: 'd1766ef052b5bbebe2a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27699&size=130',
  },
  {
    id: '46dcfe5ac21f2b41720e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27700&size=130',
  },
  {
    id: '88a431220d67e439bd76',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27701&size=130',
  },
  {
    id: '676fdde9e1ac08f251bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27702&size=130',
  },
  {
    id: 'ba1c019a3ddfd4818dce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27703&size=130',
  },
  {
    id: 'af1b1b9d27d8ce8697c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27704&size=130',
  },
  {
    id: '7f9bca1df6581f064649',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27705&size=130',
  },
  {
    id: 'c15f77d94b9ca2c2fb8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27706&size=130',
  },
  {
    id: '08c6c147fd02145c4d13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27461&size=130',
  },
  {
    id: '75d3bf5283176a493306',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27462&size=130',
  },
  {
    id: 'fe5135d00995e0cbb984',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27463&size=130',
  },
  {
    id: 'e9442dc51180f8dea191',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27464&size=130',
  },
  {
    id: '6970acf190b479ea20a5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27465&size=130',
  },
  {
    id: 'ef8d290c1549fc17a558',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27466&size=130',
  },
  {
    id: '806447e57ba092fecbb1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27467&size=130',
  },
  {
    id: 'ac0f6c8e50cbb995e0da',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27468&size=130',
  },
  {
    id: '765bb7da8b9f62c13b8e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27469&size=130',
  },
  {
    id: 'b83b7aba46ffafa1f6ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27470&size=130',
  },
  {
    id: 'b37270f34cb6a5e8fca7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27471&size=130',
  },
  {
    id: 'bac966485a0db353ea1c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27472&size=130',
  },
  {
    id: '318eec0fd04a3914605b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27473&size=130',
  },
  {
    id: 'bdf663775f32b66cef23',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27474&size=130',
  },
  {
    id: 'f33a2cbb10fef9a0a0ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27475&size=130',
  },
  {
    id: '9ba543247f61963fcf70',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27476&size=130',
  },
  {
    id: '7861a1e09da574fb2db4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27477&size=130',
  },
  {
    id: '6624bca580e069be30f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27478&size=130',
  },
  {
    id: 'c68b1d0a214fc811915e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27479&size=130',
  },
  {
    id: '1a73cef2f2b71be942a6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27480&size=130',
  },
  {
    id: 'f4bb213a1d7ff421ad6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27481&size=130',
  },
  {
    id: 'a0e276634a26a378fa37',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27482&size=130',
  },
  {
    id: '2689f108cd4d24137d5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27483&size=130',
  },
  {
    id: 'c1d411552d10c44e9d01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27484&size=130',
  },
  {
    id: '0f93b512895760093946',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27446&size=130',
  },
  {
    id: '27709cf1a0b449ea10a5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27447&size=130',
  },
  {
    id: '0173b5f289b760e939a6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27448&size=130',
  },
  {
    id: '4f99fa18c65d2f03764c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27449&size=130',
  },
  {
    id: '4d3ffbbec7fb2ea577ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27450&size=130',
  },
  {
    id: '827e35ff09bae0e4b9ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27451&size=130',
  },
  {
    id: 'ee3c5ebd62f88ba6d2e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27452&size=130',
  },
  {
    id: '3fbf8e3eb27b5b25026a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27453&size=130',
  },
  {
    id: '31e38362bf2756790f36',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27454&size=130',
  },
  {
    id: '81b832390e7ce722be6d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27455&size=130',
  },
  {
    id: 'f7b93b38077dee23b76c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27456&size=130',
  },
  {
    id: 'd3421ec32286cbd89297',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27457&size=130',
  },
  {
    id: '0faac12bfd6e14304d7f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27458&size=130',
  },
  {
    id: 'c4c20b433706de588717',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27459&size=130',
  },
  {
    id: '7a48b2c98e8c67d23e9d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27460&size=130',
  },
  {
    id: '0413a3939fd676882fc7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27179&size=130',
  },
  {
    id: '227f82ffbeba57e40eab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27180&size=130',
  },
  {
    id: 'e29b431b7f5e9600cf4f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27181&size=130',
  },
  {
    id: 'd57f77ff4bbaa2e4fbab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27182&size=130',
  },
  {
    id: '77f1d471e834016a5825',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27183&size=130',
  },
  {
    id: 'e0115c9160d4898ad0c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27184&size=130',
  },
  {
    id: '118aac0a904f7911205e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27185&size=130',
  },
  {
    id: '8cce324e0e0be755be1a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27186&size=130',
  },
  {
    id: '039bbc1b805e6900304f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27187&size=130',
  },
  {
    id: 'c58a7d0a414fa811f15e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27188&size=130',
  },
  {
    id: '9a4623c61f83f6ddaf92',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27189&size=130',
  },
  {
    id: '6f9bd51be95e0000594f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27190&size=130',
  },
  {
    id: '2b4090c0ac8545db1c94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27191&size=130',
  },
  {
    id: '81ab352b096ee030b97f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27192&size=130',
  },
  {
    id: '4d6cf8ecc4a92df774b8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27193&size=130',
  },
  {
    id: '0f6ab9ea85af6cf135be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27194&size=130',
  },
  {
    id: '281267905bd5b28bebc4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26819&size=130',
  },
  {
    id: 'cbd18353bf1656480f07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26820&size=130',
  },
  {
    id: 'a83ae1b8ddfd34a36dec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26821&size=130',
  },
  {
    id: '11125b9067d58e8bd7c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26822&size=130',
  },
  {
    id: '6f8e240c1849f117a858',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26823&size=130',
  },
  {
    id: 'f9e3bd618124687a3135',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26824&size=130',
  },
  {
    id: '7a8d3f0f034aea14b35b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26825&size=130',
  },
  {
    id: '16bd503f6c7a8524dc6b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26826&size=130',
  },
  {
    id: '9ee5d967e5220c7c5533',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26827&size=130',
  },
  {
    id: '1e525ed062958bcbd284',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26828&size=130',
  },
  {
    id: 'efd1ae5392167b482207',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26829&size=130',
  },
  {
    id: '729530170c52e50cbc43',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26830&size=130',
  },
  {
    id: 'dabe993ca5794c271568',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26831&size=130',
  },
  {
    id: '21937d114154a80af145',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26832&size=130',
  },
  {
    id: '72e62f641321fa7fa330',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26833&size=130',
  },
  {
    id: 'a37ffdfdc1b828e671a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26834&size=130',
  },
  {
    id: 'ed21cea3f2e61bb842f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26799&size=130',
  },
  {
    id: '6a3f56bd6af883a6dae9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26800&size=130',
  },
  {
    id: '7b9d461f7a5a9304ca4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26801&size=130',
  },
  {
    id: '2dce134c2f09c6579f18',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26802&size=130',
  },
  {
    id: '193126b31af6f3a8aae7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26803&size=130',
  },
  {
    id: 'a20f9a8da6c84f9616d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26804&size=130',
  },
  {
    id: '689551176d52840cdd43',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26805&size=130',
  },
  {
    id: '180b22891eccf792aedd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26806&size=130',
  },
  {
    id: 'bfd18453b81651480807',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26807&size=130',
  },
  {
    id: 'ff6bcbe9f7ac1ef247bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26808&size=130',
  },
  {
    id: '6c5559d765928cccd583',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26809&size=130',
  },
  {
    id: '1f2529a715e2fcbca5f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26810&size=130',
  },
  {
    id: '3e6609e435a1dcff85b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26811&size=130',
  },
  {
    id: 'fc72ccf0f0b519eb40a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26812&size=130',
  },
  {
    id: '29a418262463cd3d9472',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26813&size=130',
  },
  {
    id: '4e977c154050a90ef041',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26814&size=130',
  },
  {
    id: '92b7a1359d70742e2d61',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26815&size=130',
  },
  {
    id: '92f6de74e2310b6f5220',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26816&size=130',
  },
  {
    id: '51671ce520a0c9fe90b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26817&size=130',
  },
  {
    id: '5daf132d2f68c6369f79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26818&size=130',
  },
  {
    id: '34a3d821e4640d3a5475',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26720&size=130',
  },
  {
    id: '2b68c6eafaaf13f14abe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26721&size=130',
  },
  {
    id: '97fe797c4539ac67f528',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26722&size=130',
  },
  {
    id: '7e2c91aeadeb44b51dfa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26723&size=130',
  },
  {
    id: 'a7a44f2673639a3dc372',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26724&size=130',
  },
  {
    id: '60df895db5185c460509',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26725&size=130',
  },
  {
    id: '150fff8dc3c82a9673d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26726&size=130',
  },
  {
    id: 'e5480eca328fdbd1829e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26727&size=130',
  },
  {
    id: '0b1aef98d3dd3a8363cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26728&size=130',
  },
  {
    id: '97f8727a4e3fa761fe2e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26729&size=130',
  },
  {
    id: '2368c5eaf9af10f149be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26730&size=130',
  },
  {
    id: '872f60ad5ce8b5b6ecf9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26731&size=130',
  },
  {
    id: 'c02720a51ce0f5beacf1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26732&size=130',
  },
  {
    id: 'fe0b1f8923ccca9293dd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26733&size=130',
  },
  {
    id: 'dec73c450000e95eb011',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26734&size=130',
  },
  {
    id: 'face194c2509cc579518',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26735&size=130',
  },
  {
    id: '1a9be619da5c33026a4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26736&size=130',
  },
  {
    id: 'bdfc407e7c3b9565cc2a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26737&size=130',
  },
  {
    id: '8e8e700c4c49a517fc58',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26738&size=130',
  },
  {
    id: '68ed976fab2a42741b3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26739&size=130',
  },
  {
    id: '78b68034bc71552f0c60',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26740&size=130',
  },
  {
    id: 'cc9035120957e009b946',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26741&size=130',
  },
  {
    id: '9daf672d5b68b236eb79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26742&size=130',
  },
  {
    id: '4a59b1db8d9e64c03d8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26743&size=130',
  },
  {
    id: '82dd765f4a1aa344fa0b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26744&size=130',
  },
  {
    id: 'bed94b5b771e9e40c70f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26745&size=130',
  },
  {
    id: 'b33545b779f290acc9e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26746&size=130',
  },
  {
    id: 'c2a97b2b476eae30f77f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26677&size=130',
  },
  {
    id: 'd36569e755a2bcfce5b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26678&size=130',
  },
  {
    id: 'c48f7f0d4348aa16f359',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26679&size=130',
  },
  {
    id: 'f39347117b54920acb45',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26680&size=130',
  },
  {
    id: '3ae38f61b3245a7a0335',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26681&size=130',
  },
  {
    id: 'ff5549d775929cccc583',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26682&size=130',
  },
  {
    id: 'dfce684c5409bd57e418',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26683&size=130',
  },
  {
    id: '5da0ed22d16738396176',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26684&size=130',
  },
  {
    id: '4b4dfacfc68a2fd4769b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26685&size=130',
  },
  {
    id: '2dcc9f4ea30b4a55131a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26686&size=130',
  },
  {
    id: '0ab4b93685736c2d3562',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26687&size=130',
  },
  {
    id: 'be4772c54e80a7defe91',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26688&size=130',
  },
  {
    id: '9c8f510d6d488416dd59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26689&size=130',
  },
  {
    id: '53ba9d38a17d4823116c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26690&size=130',
  },
  {
    id: 'c1d40e563213db4d8202',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26691&size=130',
  },
  {
    id: '4d6e85ecb9a950f709b8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26692&size=130',
  },
  {
    id: '5fd0205d1c18f546ac09',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26611&size=130',
  },
  {
    id: '5bbf23321f77f629af66',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26612&size=130',
  },
  {
    id: '9514ec99d0dc398260cd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26613&size=130',
  },
  {
    id: 'c0c4ba49860c6f52361d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26614&size=130',
  },
  {
    id: '8591fe1cc2592b077248',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26615&size=130',
  },
  {
    id: '29b35d3e617b8825d16a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26616&size=130',
  },
  {
    id: '170d62805ec5b79beed4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26617&size=130',
  },
  {
    id: '1f2469a955ecbcb2e5fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26618&size=130',
  },
  {
    id: '30d3475e7b1b9245cb0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26619&size=130',
  },
  {
    id: '532223af1feaf6b4affb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26620&size=130',
  },
  {
    id: '6ab71b3a277fce21976e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26621&size=130',
  },
  {
    id: '628a10072c42c51c9c53',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26622&size=130',
  },
  {
    id: '3ef54d78713d9863c12c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26623&size=130',
  },
  {
    id: '07c68b44b7015e5f0710',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26624&size=130',
  },
  {
    id: '16ea9b68a72d4e73173c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26625&size=130',
  },
  {
    id: '0f038181bdc4549a0dd5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26626&size=130',
  },
  {
    id: 'b58e3a0c0649ef17b658',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26627&size=130',
  },
  {
    id: 'e3f86b7a573fbe61e72e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26628&size=130',
  },
  {
    id: '54b6dd34e171082f5160',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26629&size=130',
  },
  {
    id: '63c6e944d5013c5f6510',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26630&size=130',
  },
  {
    id: '31c1794c4509ac57f518',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26564&size=130',
  },
  {
    id: 'a144e8c9d48c3dd2649d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26565&size=130',
  },
  {
    id: '5fb915342971c02f9960',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26566&size=130',
  },
  {
    id: 'c9d48259be1c57420e0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26567&size=130',
  },
  {
    id: '103a54b768f281acd8e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26568&size=130',
  },
  {
    id: 'ee70abfd97b87ee627a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26569&size=130',
  },
  {
    id: '9311d59ce9d9008759c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26570&size=130',
  },
  {
    id: 'fb63bcee80ab69f530ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26571&size=130',
  },
  {
    id: '782e38a304e6edb8b4f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26572&size=130',
  },
  {
    id: '5bf41a79263ccf62962d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26573&size=130',
  },
  {
    id: '602c22a11ee4f7baaef5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26574&size=130',
  },
  {
    id: '57f6147b283ec160982f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26575&size=130',
  },
  {
    id: '99b2c53ff97a1024496b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26576&size=130',
  },
  {
    id: '70262dab11eef8b0a1ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26577&size=130',
  },
  {
    id: '8969d7e4eba102ff5bb0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26578&size=130',
  },
  {
    id: '392666ab5aeeb3b0eaff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26579&size=130',
  },
  {
    id: '94a9cc24f061193f4070',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26580&size=130',
  },
  {
    id: 'cbc2924fae0a47541e1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26581&size=130',
  },
  {
    id: '01e55b68672d8e73d73c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26582&size=130',
  },
  {
    id: 'e58fbe0282476b193256',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26583&size=130',
  },
  {
    id: '54e3006e3c2bd5758c3a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26584&size=130',
  },
  {
    id: '58bd7b304775ae2bf764',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26543&size=130',
  },
  {
    id: 'f62bcaa6f6e31fbd46f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26544&size=130',
  },
  {
    id: 'a94694cba88e41d0189f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26545&size=130',
  },
  {
    id: '64585ad566908fced681',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26546&size=130',
  },
  {
    id: 'fce2c36fff2a16744f3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26547&size=130',
  },
  {
    id: 'ea5ed2d3ee9607c85e87',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26548&size=130',
  },
  {
    id: 'c060f9edc5a82cf675b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26549&size=130',
  },
  {
    id: '5ad6605b5c1eb540ec0f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26550&size=130',
  },
  {
    id: '36230dae31ebd8b581fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26551&size=130',
  },
  {
    id: '29171d9a21dfc88191ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26552&size=130',
  },
  {
    id: '5cbb69365573bc2de562',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26553&size=130',
  },
  {
    id: '02b6343b087ee120b86f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26554&size=130',
  },
  {
    id: '523565b859fdb0a3e9ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26555&size=130',
  },
  {
    id: 'ec40dccde08809d65099',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26556&size=130',
  },
  {
    id: '423473b94ffca6a2ffed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26557&size=130',
  },
  {
    id: '9b58a9d595907cce2581',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26558&size=130',
  },
  {
    id: '37eb04663823d17d8832',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26559&size=130',
  },
  {
    id: '38b6743b487ea120f86f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26560&size=130',
  },
  {
    id: '8041cdccf18918d74198',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26561&size=130',
  },
  {
    id: '1fc851456d00845edd11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26562&size=130',
  },
  {
    id: '96d4d959e51c0c42550d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26563&size=130',
  },
  {
    id: '338b24061843f11da852',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26523&size=130',
  },
  {
    id: '493859b565f08caed5e1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26524&size=130',
  },
  {
    id: '297d38f004b5edebb4a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26525&size=130',
  },
  {
    id: '00c412492e0cc7529e1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26526&size=130',
  },
  {
    id: 'b668a5e599a070fe29b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26527&size=130',
  },
  {
    id: '23b80f353370da2e8361',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26528&size=130',
  },
  {
    id: '20050d8831cdd89381dc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26529&size=130',
  },
  {
    id: '4692681f545abd04e44b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26530&size=130',
  },
  {
    id: '102e3fa303e6eab8b3f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26531&size=130',
  },
  {
    id: '02d02a5d1618ff46a609',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26532&size=130',
  },
  {
    id: '000b298615c3fc9da5d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26533&size=130',
  },
  {
    id: '575d7dd04195a8cbf184',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26534&size=130',
  },
  {
    id: 'e2bec933f5761c284567',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26535&size=130',
  },
  {
    id: 'a05884d5b89051ce0881',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26536&size=130',
  },
  {
    id: '68e34d6e712b9875c13a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26537&size=130',
  },
  {
    id: '83afa522996770392976',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26538&size=130',
  },
  {
    id: '321a159729d2c08c99c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26539&size=130',
  },
  {
    id: '2982090f354adc14855b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26540&size=130',
  },
  {
    id: '5786760b4a4ea310fa5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26541&size=130',
  },
  {
    id: '9294b0198c5c65023c4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26542&size=130',
  },
  {
    id: '31fbce76f2331b6d4222',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26483&size=130',
  },
  {
    id: '3f7cc7f1fbb412ea4ba5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26484&size=130',
  },
  {
    id: 'e9b7103a2c7fc5219c6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26485&size=130',
  },
  {
    id: '3516cf9bf3de1a8043cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26486&size=130',
  },
  {
    id: '15fbee76d2333b6d6222',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26487&size=130',
  },
  {
    id: 'a79c53116f54860adf45',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26488&size=130',
  },
  {
    id: '90dd65505915b04be904',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26489&size=130',
  },
  {
    id: 'e9bd1f302375ca2b9364',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26490&size=130',
  },
  {
    id: 'f69801153d50d40e8d41',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26491&size=130',
  },
  {
    id: '8cea7c674022a97cf033',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26492&size=130',
  },
  {
    id: 'd93628bb14fefda0a4ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26493&size=130',
  },
  {
    id: '776985e4b9a150ff09b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26494&size=130',
  },
  {
    id: 'fa1a099735d2dc8c85c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26495&size=130',
  },
  {
    id: '293125bc19f9f0a7a9e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26496&size=130',
  },
  {
    id: '580c558169c4809ad9d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26497&size=130',
  },
  {
    id: '34953a18065def03b64c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26498&size=130',
  },
  {
    id: 'b108be8582c06b9e32d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26499&size=130',
  },
  {
    id: 'ff40f7cdcb8822d67b99',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26500&size=130',
  },
  {
    id: '614068cd5488bdd6e499',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26501&size=130',
  },
  {
    id: 'cf7bc5f6f9b310ed49a2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26502&size=130',
  },
  {
    id: '68ed67455b00b25eeb11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17027&size=130',
  },
  {
    id: 'd9e0d148ed0d04535d1c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17028&size=130',
  },
  {
    id: '7bd7727f4e3aa764fe2b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17029&size=130',
  },
  {
    id: 'e1e5eb4dd7083e566719',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17030&size=130',
  },
  {
    id: '2b3820901cd5f58bacc4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17031&size=130',
  },
  {
    id: 'bea5ba0d86486f163659',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17032&size=130',
  },
  {
    id: 'c122c48af8cf119148de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17033&size=130',
  },
  {
    id: '852c8384bfc1569f0fd0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17034&size=130',
  },
  {
    id: 'a370a4d8989d71c3288c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17035&size=130',
  },
  {
    id: '0cd20c7a303fd961802e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17036&size=130',
  },
  {
    id: '423fb7978bd2628c3bc3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17017&size=130',
  },
  {
    id: '191befb3d3f63aa863e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17018&size=130',
  },
  {
    id: 'bd414ae976ac9ff2c6bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17019&size=130',
  },
  {
    id: '3c16ccbef0fb19a540ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17020&size=130',
  },
  {
    id: '4d55bcfd80b869e630a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17021&size=130',
  },
  {
    id: 'cf8e3d260163e83db172',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17022&size=130',
  },
  {
    id: '3c36cf9ef3db1a8543ca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17023&size=130',
  },
  {
    id: '41ce4d667123987dc132',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17024&size=130',
  },
  {
    id: '636e6ec65283bbdde292',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17025&size=130',
  },
  {
    id: '0c7102d93e9cd7c28e8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17026&size=130',
  },
  {
    id: '761874b348f6a1a8f8e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16782&size=130',
  },
  {
    id: 'a715a4be98fb71a528ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16783&size=130',
  },
  {
    id: '63c77f6c4329aa77f338',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16784&size=130',
  },
  {
    id: '140a09a135e4dcba85f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16785&size=130',
  },
  {
    id: 'c6d8d873e4360d685427',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16786&size=130',
  },
  {
    id: '61bf7e144251ab0ff240',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16787&size=130',
  },
  {
    id: '03f91b522717ce499706',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16788&size=130',
  },
  {
    id: '05e31c48200dc953901c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16789&size=130',
  },
  {
    id: '46fc5c576012894cd003',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16790&size=130',
  },
  {
    id: '68c4736f4f2aa674ff3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16791&size=130',
  },
  {
    id: '55cd41667d23947dcd32',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16792&size=130',
  },
  {
    id: '54b941127d579409cd46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16793&size=130',
  },
  {
    id: '89689fc3a3864ad81397',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16794&size=130',
  },
  {
    id: 'd3abc400f845111b4854',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16795&size=130',
  },
  {
    id: 'ae15bebe82fb6ba532ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16796&size=130',
  },
  {
    id: '12c903623f27d6798f36',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16797&size=130',
  },
  {
    id: '528840237c669538cc77',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16798&size=130',
  },
  {
    id: '408c53276f62863cdf73',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16799&size=130',
  },
  {
    id: '4894863fba7a53240a6b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16706&size=130',
  },
  {
    id: '2d6ce2c7de8237dc6e93',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16707&size=130',
  },
  {
    id: '7feab7418b04625a3b15',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16708&size=130',
  },
  {
    id: '9c4855e369a680f8d9b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16709&size=130',
  },
  {
    id: '87204d8b71ce9890c1df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16710&size=130',
  },
  {
    id: '6fffa4549811714f2800',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16711&size=130',
  },
  {
    id: 'a62c62875ec2b79ceed3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16712&size=130',
  },
  {
    id: '5f609acba68e4fd0169f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16713&size=130',
  },
  {
    id: '0f26c98df5c81c9645d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16714&size=130',
  },
  {
    id: '4b128cb9b0fc59a200ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16715&size=130',
  },
  {
    id: 'd25712fc2eb9c7e79ea8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16716&size=130',
  },
  {
    id: 'a0b7611c5d59b407ed48',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16717&size=130',
  },
  {
    id: '2fa6ed0dd14838166159',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16718&size=130',
  },
  {
    id: 'c0af03043f41d61f8f50',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16719&size=130',
  },
  {
    id: 'cb0217a92becc2b29bfd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16720&size=130',
  },
  {
    id: 'e428398305c6ec98b5d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16721&size=130',
  },
  {
    id: 'b90667ad5be8b2b6ebf9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16722&size=130',
  },
  {
    id: 'b5b66a1d5658bf06e649',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16723&size=130',
  },
  {
    id: 'cc3a149128d4c18a98c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16724&size=130',
  },
  {
    id: '2e06f7adcbe822b67bf9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16725&size=130',
  },
  {
    id: '8791f93bc57e2c20756f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16626&size=130',
  },
  {
    id: '9340ecead0af39f160be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16627&size=130',
  },
  {
    id: 'b862c0c8fc8d15d34c9c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16628&size=130',
  },
  {
    id: '7c7505df399ad0c4898b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16629&size=130',
  },
  {
    id: '74b70e1d3258db068249',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16630&size=130',
  },
  {
    id: '71480ae236a7dff986b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16631&size=130',
  },
  {
    id: '139267385b7db223eb6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16632&size=130',
  },
  {
    id: '2aa25f08634d8a13d35c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16633&size=130',
  },
  {
    id: 'dfaea90495417c1f2550',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16634&size=130',
  },
  {
    id: '5c362b9c17d9fe87a7c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16635&size=130',
  },
  {
    id: 'e9ae9904a5414c1f1550',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16636&size=130',
  },
  {
    id: '1c0d6da751e2b8bce1f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16637&size=130',
  },
  {
    id: '8f85fd2fc16a2834717b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16638&size=130',
  },
  {
    id: '506923c31f86f6d8af97',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16639&size=130',
  },
  {
    id: '856009cb358edcd0859f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16640&size=130',
  },
  {
    id: 'f42d798645c3ac9df5d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16641&size=130',
  },
  {
    id: '974319e825adccf395bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16642&size=130',
  },
  {
    id: '5ccbd360ef25067b5f34',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16643&size=130',
  },
  {
    id: 'e95161fa5dbfb4e1edae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16644&size=130',
  },
  {
    id: 'f7d17e7a423fab61f22e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16645&size=130',
  },
  {
    id: '65785bd267978ec9d786',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16562&size=130',
  },
  {
    id: 'fe68c1c2fd8714d94d96',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16563&size=130',
  },
  {
    id: '54d76c7d5038b966e029',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16564&size=130',
  },
  {
    id: 'c382fa28c66d2f33767c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16565&size=130',
  },
  {
    id: '86ddbc778032696c3023',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16566&size=130',
  },
  {
    id: '108f2b251760fe3ea771',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16567&size=130',
  },
  {
    id: '3d820928356ddc33857c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16568&size=130',
  },
  {
    id: '850db0a78ce265bc3cf3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16569&size=130',
  },
  {
    id: '148222281e6df733ae7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16570&size=130',
  },
  {
    id: '61b956136a568308da47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16571&size=130',
  },
  {
    id: '26ba16102a55c30b9a44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16572&size=130',
  },
  {
    id: '60ac51066d43841ddd52',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16573&size=130',
  },
  {
    id: '1c752edf129afbc4a28b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16574&size=130',
  },
  {
    id: 'd0fee354df11364f6f00',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16575&size=130',
  },
  {
    id: '77d83b720737ee69b726',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16576&size=130',
  },
  {
    id: 'b79bb5318974602a3965',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16526&size=130',
  },
  {
    id: 'f1f7f25dce1827467e09',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16527&size=130',
  },
  {
    id: '8b95973fab7a42241b6b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16528&size=130',
  },
  {
    id: '88a39509a94c4012195d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16529&size=130',
  },
  {
    id: '65a27b08474dae13f75c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16530&size=130',
  },
  {
    id: 'c836d79cebd902875bc8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16531&size=130',
  },
  {
    id: '8fde9774ab31426f1b20',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16532&size=130',
  },
  {
    id: 'ce54d7feebbb02e55baa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16533&size=130',
  },
  {
    id: 'db73c1d9fd9c14c24d8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16534&size=130',
  },
  {
    id: '227239d8059decc3b58c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16535&size=130',
  },
  {
    id: '6c4278e844adadf3f4bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16536&size=130',
  },
  {
    id: '6c6c79c64583acddf592',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16537&size=130',
  },
  {
    id: '77ba61105d55b40bed44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16538&size=130',
  },
  {
    id: 'a664b1ce8d8b64d53d9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16539&size=130',
  },
  {
    id: '3e4f2ee512a0fbfea2b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16540&size=130',
  },
  {
    id: '468357296b6c8232db7d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16541&size=130',
  },
  {
    id: '58d6b17c8d3964673d28',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16485&size=130',
  },
  {
    id: '5bfdb1578d12644c3d03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16486&size=130',
  },
  {
    id: '366fddc5e18008de5191',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16487&size=130',
  },
  {
    id: '7c8c9826a4634d3d1472',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16488&size=130',
  },
  {
    id: '2c7bc9d1f5941cca4585',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16489&size=130',
  },
  {
    id: 'd67530df0c9ae5c4bc8b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16490&size=130',
  },
  {
    id: '74e5934faf0a46541f1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16491&size=130',
  },
  {
    id: 'ace14c4b700e9950c01f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16492&size=130',
  },
  {
    id: 'a62347897bcc9292cbdd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16493&size=130',
  },
  {
    id: '44b8a6129a5773092a46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16494&size=130',
  },
  {
    id: 'cf342c9e10dbf985a0ca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16495&size=130',
  },
  {
    id: '82927e38427dab23f26c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16496&size=130',
  },
  {
    id: '2e76d3dcef9906c75f88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16497&size=130',
  },
  {
    id: '2e1ed0b4ecf105af5ce0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16498&size=130',
  },
  {
    id: 'e51d1ab726f2cfac96e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16499&size=130',
  },
  {
    id: '12ceea64d6213f7f6630',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16500&size=130',
  },
  {
    id: 'ec6415ce298bc0d5999a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16501&size=130',
  },
  {
    id: '1189eb23d7663e386777',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16502&size=130',
  },
  {
    id: '39d4c27efe3b17654e2a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16503&size=130',
  },
  {
    id: '3d33c999f5dc1c8245cd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=16504&size=130',
  },
  {
    id: '98a18a4cb6095f570618',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1950&size=130',
  },
  {
    id: '3c152ff813bdfae3a3ac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1951&size=130',
  },
  {
    id: '4a0466e95aacb3f2eabd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1952&size=130',
  },
  {
    id: '422f6fc25387bad9e396',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1953&size=130',
  },
  {
    id: '42526cbf50fab9a4e0eb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1954&size=130',
  },
  {
    id: 'f3a2dc4fe00a0954501b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1955&size=130',
  },
  {
    id: '1981316c0d29e477bd38',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1956&size=130',
  },
  {
    id: '1cd0353d0978e026b969',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1957&size=130',
  },
  {
    id: '681242ff7eba97e4ceab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1958&size=130',
  },
  {
    id: 'ed95c678fa3d13634a2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1959&size=130',
  },
  {
    id: '81d1a53c997970272968',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1960&size=130',
  },
  {
    id: '4bbc6e515214bb4ae205',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1961&size=130',
  },
  {
    id: 'a1be8753bb1652480b07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1962&size=130',
  },
  {
    id: 'bc0e9be3a7a64ef817b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1963&size=130',
  },
  {
    id: '308810652c20c57e9c31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1964&size=130',
  },
  {
    id: '2d190cf430b1d9ef80a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1965&size=130',
  },
  {
    id: '6dce4f2373669a38c377',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1966&size=130',
  },
  {
    id: '024c21a11de4f4baadf5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1967&size=130',
  },
  {
    id: '8eb5b2588e1d67433e0c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1968&size=130',
  },
  {
    id: '40167dfb41bea8e0f1af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1969&size=130',
  },
  {
    id: '46a5d34aef0f06515f1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1305&size=130',
  },
  {
    id: '3594a37b9f3e76602f2f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1306&size=130',
  },
  {
    id: '650ef2e1cea427fa7eb5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1307&size=130',
  },
  {
    id: '675af7b5cbf022ae7be1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1308&size=130',
  },
  {
    id: '20e4b10b8d4e64103d5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1309&size=130',
  },
  {
    id: 'cdf15f1e635b8a05d34a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1310&size=130',
  },
  {
    id: 'cad5593a657f8c21d56e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1311&size=130',
  },
  {
    id: '8d4f21a01de5f4bbadf4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1312&size=130',
  },
  {
    id: '0b31a6de9a9b73c52a8a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1313&size=130',
  },
  {
    id: '5a2df4c2c88721d97896',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1314&size=130',
  },
  {
    id: '4349eca6d0e339bd60f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1315&size=130',
  },
  {
    id: 'f6e55e0a624f8b11d25e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1316&size=130',
  },
  {
    id: 'bffc16132a56c3089a47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1317&size=130',
  },
  {
    id: '6efec411f854110a4845',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1318&size=130',
  },
  {
    id: 'eb9e40717c34956acc25',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1319&size=130',
  },
  {
    id: '931437fb0bbee2e0bbaf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1320&size=130',
  },
  {
    id: 'b1d71438287dc123986c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1321&size=130',
  },
  {
    id: 'a62600c93c8cd5d28c9d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1322&size=130',
  },
  {
    id: '77dfd030ec75052b5c64',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1323&size=130',
  },
  {
    id: '1377b3988fdd66833fcc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1324&size=130',
  },
  {
    id: 'f91858f764b28decd4a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1325&size=130',
  },
  {
    id: 'f3d2513d6d788426dd69',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1326&size=130',
  },
  {
    id: 'dac879274562ac3cf573',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1327&size=130',
  },
  {
    id: 'b50009ef35aadcf485bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1328&size=130',
  },
  {
    id: '6c36d1d9ed9c04c25d8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1329&size=130',
  },
  {
    id: 'ac3712d82e9dc7c39e8c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1330&size=130',
  },
  {
    id: 'fa3a45d5799090cec981',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1331&size=130',
  },
  {
    id: 'dcf0641f585ab104e84b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1332&size=130',
  },
  {
    id: 'd5046ceb50aeb9f0e0bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1333&size=130',
  },
  {
    id: 'a6121cfd20b8c9e690a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1334&size=130',
  },
  {
    id: '7686cd69f12c1872413d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1335&size=130',
  },
  {
    id: 'bdc1092e356bdc35857a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1336&size=130',
  },
  {
    id: '56e2e30ddf4836166f59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1337&size=130',
  },
  {
    id: '8c6c3a8306c6ef98b6d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1338&size=130',
  },
  {
    id: 'bcc9cb27f7621e3c4773',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1275&size=130',
  },
  {
    id: 'c43eb4d0889561cb3884',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1276&size=130',
  },
  {
    id: '72d903373f72d62c8f63',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1277&size=130',
  },
  {
    id: '9c0beee5d2a03bfe62b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1278&size=130',
  },
  {
    id: 'c412b7fc8bb962e73ba8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1279&size=130',
  },
  {
    id: 'd9b0555f691a8044d90b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1280&size=130',
  },
  {
    id: '777efa91c6d42f8a76c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1281&size=130',
  },
  {
    id: '11af9f40a3054a5b1314',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1282&size=130',
  },
  {
    id: 'e5d46a3b567ebf20e66f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1283&size=130',
  },
  {
    id: '3b4eb3a18fe466ba3ff5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1284&size=130',
  },
  {
    id: '68cee121dd64343a6d75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1285&size=130',
  },
  {
    id: '15e69f09a34c4a12135d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1286&size=130',
  },
  {
    id: '45c8ce27f2621b3c4273',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1287&size=130',
  },
  {
    id: '7c44f8abc4ee2db074ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1288&size=130',
  },
  {
    id: '8c9a09753530dc6e8521',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1289&size=130',
  },
  {
    id: 'c482426d7e289776ce39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1290&size=130',
  },
  {
    id: '75c0f22fce6a27347e7b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1291&size=130',
  },
  {
    id: 'cf8f4f6073259a7bc334',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1292&size=130',
  },
  {
    id: '3e0cbfe383a66af833b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1293&size=130',
  },
  {
    id: 'fed37c3c4079a927f068',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1294&size=130',
  },
  {
    id: '0dc38e2cb2695b370278',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1295&size=130',
  },
  {
    id: '346aa88594c07d9e24d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1296&size=130',
  },
  {
    id: '5e5dc3b2fff716a94fe6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1297&size=130',
  },
  {
    id: 'e3a77d48410da853f11c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1298&size=130',
  },
  {
    id: '45e4da0be64e0f10565f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1299&size=130',
  },
  {
    id: 'e70c7fe343a6aaf8f3b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1300&size=130',
  },
  {
    id: '74c1ed2ed16b3835617a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1301&size=130',
  },
  {
    id: '070b9de4a1a148ff11b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1302&size=130',
  },
  {
    id: 'b7c72c28106df933a07c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1303&size=130',
  },
  {
    id: '263bb2d48e9167cf3e80',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1304&size=130',
  },
  {
    id: 'ed689281aec4479a1ed5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1011&size=130',
  },
  {
    id: '3a67428e7ecb9795ceda',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1012&size=130',
  },
  {
    id: '1e2a67c35b86b2d8eb97',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1013&size=130',
  },
  {
    id: '92eae803d4463d186457',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1014&size=130',
  },
  {
    id: 'b68ecd67f122187c4133',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1015&size=130',
  },
  {
    id: '553d21d41d91f4cfad80',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1016&size=130',
  },
  {
    id: 'd0c8a5219964703a2975',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1017&size=130',
  },
  {
    id: '2d7e5b9767d28e8cd7c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1018&size=130',
  },
  {
    id: '425535bc09f9e0a7b9e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1019&size=130',
  },
  {
    id: 'c141b1a88ded64b33dfc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1020&size=130',
  },
  {
    id: '5f472eae12ebfbb5a2fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1021&size=130',
  },
  {
    id: '3dee4f0773429a1cc353',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1022&size=130',
  },
  {
    id: '211e52f76eb287ecdea3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1023&size=130',
  },
  {
    id: '1ea2924cae0947571e18',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1024&size=130',
  },
  {
    id: 'f3257ecb428eabd0f29f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1025&size=130',
  },
  {
    id: '90be1e502215cb4b9204',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1026&size=130',
  },
  {
    id: '1fc2902cac6945371c78',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1027&size=130',
  },
  {
    id: '9d4815a629e3c0bd99f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1028&size=130',
  },
  {
    id: 'ddb954576812814cd803',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1029&size=130',
  },
  {
    id: '8e3504db389ed1c0888f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1030&size=130',
  },
  {
    id: '56d9dd37e172082c5163',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1031&size=130',
  },
  {
    id: '789bfc75c030296e7021',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1032&size=130',
  },
  {
    id: '6c8ae964d5213c7f6530',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1033&size=130',
  },
  {
    id: '101a96f4aab143ef1aa0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1034&size=130',
  },
  {
    id: '472dc0c3fc8615d84c97',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1035&size=130',
  },
  {
    id: '17669788abcd42931bdc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1036&size=130',
  },
  {
    id: '06c0872ebb6b52350b7a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1037&size=130',
  },
  {
    id: 'dc475ea962ec8bb2d2fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1038&size=130',
  },
  {
    id: '422ec1c0fd8514db4d94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1039&size=130',
  },
  {
    id: '50c1cc2ff06a1934407b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1040&size=130',
  },
  {
    id: '8053cbbaf7ff1ea147ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=967&size=130',
  },
  {
    id: '94ebd002ec4705195c56',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=968&size=130',
  },
  {
    id: 'e47fa1969dd3748d2dc2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=969&size=130',
  },
  {
    id: 'd7d3913aad7f44211d6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=970&size=130',
  },
  {
    id: '2d1b6af256b7bfe9e6a6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=971&size=130',
  },
  {
    id: '9359d3b0eff506ab5fe4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=972&size=130',
  },
  {
    id: '44ce05273962d03c8973',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=973&size=130',
  },
  {
    id: '712033c90f8ce6d2bf9d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=974&size=130',
  },
  {
    id: '05d946307a75932bca64',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=975&size=130',
  },
  {
    id: 'b373ef9ad3df3a8163ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=976&size=130',
  },
  {
    id: '9db3c05afc1f15414c0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=977&size=130',
  },
  {
    id: 'b870e699dadc33826acd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=978&size=130',
  },
  {
    id: '28f3771a4b5fa201fb4e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=979&size=130',
  },
  {
    id: 'df1087f9bbbc52e20bad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=980&size=130',
  },
  {
    id: '47411ea822edcbb392fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=981&size=130',
  },
  {
    id: 'cb889161ad24447a1d35',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=982&size=130',
  },
  {
    id: '75d62e3f127afb24a26b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=983&size=130',
  },
  {
    id: '70ee24071842f11ca853',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=984&size=130',
  },
  {
    id: 'aeeafb03c7462e187757',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=985&size=130',
  },
  {
    id: '6b043ded01a8e8f6b1b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=986&size=130',
  },
  {
    id: 'bc1d83f4bfb156ef0fa0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=947&size=130',
  },
  {
    id: '13042bed17a8fef6a7b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=948&size=130',
  },
  {
    id: 'be0787eebbab52f50bba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=949&size=130',
  },
  {
    id: '0e1934f008b5e1ebb8a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=950&size=130',
  },
  {
    id: '1f6a248318c6f198a8d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=951&size=130',
  },
  {
    id: '298e1d672122c87c9133',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=952&size=130',
  },
  {
    id: '6a255fcc63898ad7d398',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=953&size=130',
  },
  {
    id: 'ce98f871c4342d6a7425',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=954&size=130',
  },
  {
    id: '93f5a41c985971072848',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=955&size=130',
  },
  {
    id: 'da9aea73d6363f686627',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=956&size=130',
  },
  {
    id: '554d64a458e1b1bfe8f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=957&size=130',
  },
  {
    id: '787f4a9676d39f8dc6c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=958&size=130',
  },
  {
    id: 'ed1edef7e2b20bec52a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=959&size=130',
  },
  {
    id: '81d6cd3ff17a1824416b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=960&size=130',
  },
  {
    id: '1e5753be6ffb86a5dfea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=961&size=130',
  },
  {
    id: '24626a8b56cebf90e6df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=962&size=130',
  },
  {
    id: '8092cf7bf33e1a60432f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=963&size=130',
  },
  {
    id: '0fb3475a7b1f9241cb0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=964&size=130',
  },
  {
    id: '1a5053b96ffc86a2dfed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=965&size=130',
  },
  {
    id: '51711b9827ddce8397cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=966&size=130',
  },
  {
    id: '9921cdcaf18f18d1419e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=472&size=130',
  },
  {
    id: '7fb02a5b161eff40a60f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=473&size=130',
  },
  {
    id: 'a622f0c9cc8c25d27c9d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=474&size=130',
  },
  {
    id: '0b755c9e60db8985d0ca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=475&size=130',
  },
  {
    id: 'e9ffb91485516c0f3540',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=476&size=130',
  },
  {
    id: 'b4d3e638da7d33236a6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=478&size=130',
  },
  {
    id: 'dc828f69b32c5a72033d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=479&size=130',
  },
  {
    id: 'a9e9c502f94710194956',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=480&size=130',
  },
  {
    id: 'd0c3bd28816d6833317c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=481&size=130',
  },
  {
    id: '69f0071b3b5ed2008b4f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=482&size=130',
  },
  {
    id: '37f4581f645a8d04d44b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=483&size=130',
  },
  {
    id: '6f94067f3a3ad3648a2b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=485&size=130',
  },
  {
    id: '8616ecfdd0b839e660a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=486&size=130',
  },
  {
    id: '07216cca508fb9d1e09e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=487&size=130',
  },
  {
    id: 'a9bfcd54f111184f4100',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=488&size=130',
  },
  {
    id: '79731c9820ddc98390cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=489&size=130',
  },
  {
    id: '9dd7fb3cc7792e277768',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=490&size=130',
  },
  {
    id: '6b1c0cf730b2d9ec80a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=491&size=130',
  },
  {
    id: 'e84a88a1b4e45dba04f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=492&size=130',
  },
  {
    id: '4f3d2ed61293fbcda282',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=493&size=130',
  },
  {
    id: 'fe5c9cb7a0f249ac10e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=494&size=130',
  },
  {
    id: '19777a9c46d9af87f6c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=495&size=130',
  },
  {
    id: '67731b9827ddce8397cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=496&size=130',
  },
  {
    id: '21075cec60a989f7d0b8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=497&size=130',
  },
  {
    id: 'dc55a2be9efb77a52eea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=498&size=130',
  },
  {
    id: 'b60ac9e1f5a41cfa45b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=499&size=130',
  },
  {
    id: 'b3f9cb12f7571e094746',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=500&size=130',
  },
  {
    id: 'ec0d95e6a9a340fd19b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=501&size=130',
  },
  {
    id: '07287dc34186a8d8f197',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=502&size=130',
  },
  {
    id: '74f80f133356da088347',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=503&size=130',
  },
  {
    id: '89effd04c141281f7150',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=504&size=130',
  },
  {
    id: 'd1fa50126c578509dc46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=525&size=130',
  },
  {
    id: '470349e875ad9cf3c5bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=386&size=130',
  },
  {
    id: 'aacfa5249961703f2970',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=387&size=130',
  },
  {
    id: '22a72a4c1609ff57a618',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=388&size=130',
  },
  {
    id: '85458caeb0eb59b500fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=389&size=130',
  },
  {
    id: '8fa38548b90d5053091c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=390&size=130',
  },
  {
    id: '921099fba5be4ce015af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=391&size=130',
  },
  {
    id: 'ff57fbbcc7f92ea777e8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=392&size=130',
  },
  {
    id: '5fb85a5366168f48d607',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=393&size=130',
  },
  {
    id: '9f4a99a1a5e44cba15f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=394&size=130',
  },
  {
    id: 'f716f0fdccb825e67ca9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=395&size=130',
  },
  {
    id: '8a238ac8b68d5fd3069c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=396&size=130',
  },
  {
    id: '586a598165c48c9ad5d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=397&size=130',
  },
  {
    id: '648b73604f25a67bff34',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=411&size=130',
  },
  {
    id: 'dce0cc0bf04e1910405f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=412&size=130',
  },
  {
    id: 'd4b6c55df91810464909',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=413&size=130',
  },
  {
    id: '4d675f8c63c98a97d3d8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=414&size=130',
  },
  {
    id: '850d96e6aaa343fd1ab2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=415&size=130',
  },
  {
    id: '272e0bc53780dede8791',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=416&size=130',
  },
  {
    id: '35b918522417cd499406',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=417&size=130',
  },
  {
    id: '9341bdaa81ef68b131fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=418&size=130',
  },
  {
    id: '977fb89484d16d8f34c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=419&size=130',
  },
  {
    id: 'b6c99e22a2674b391276',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=420&size=130',
  },
  {
    id: 'b5cc9c27a062493c1073',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=421&size=130',
  },
  {
    id: 'a4348edfb29a5bc4028b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=422&size=130',
  },
  {
    id: '50a17b4a470fae51f71e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=423&size=130',
  },
  {
    id: 'f396221b1e5ef700ae4f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26461&size=130',
  },
  {
    id: 'cd821f0f234aca14935b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26462&size=130',
  },
  {
    id: '534b80c6bc8355dd0c92',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26463&size=130',
  },
  {
    id: '0754ebd9d79c3ec2678d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26464&size=130',
  },
  {
    id: '7a1e9793abd642881bc7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26465&size=130',
  },
  {
    id: 'e605088834cddd9384dc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26466&size=130',
  },
  {
    id: '19b4f639ca7c23227a6d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26467&size=130',
  },
  {
    id: 'a7a64f2b736e9a30c37f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26468&size=130',
  },
  {
    id: '981471994ddca482fdcd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26469&size=130',
  },
  {
    id: '1697fc1ac05f2901704e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26470&size=130',
  },
  {
    id: 'a282490f754a9c14c55b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26471&size=130',
  },
  {
    id: '36c9d244ee01075f5e10',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26472&size=130',
  },
  {
    id: 'e30a06873ac2d39c8ad3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26473&size=130',
  },
  {
    id: '3bf3dd7ee13b0865512a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26474&size=130',
  },
  {
    id: '69588ed5b2905bce0281',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26475&size=130',
  },
  {
    id: 'd42834a508e0e1beb8f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26476&size=130',
  },
  {
    id: 'b8b1593c65798c27d568',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26477&size=130',
  },
  {
    id: '5e55bcd8809d69c3308c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26478&size=130',
  },
  {
    id: '76889505a940401e1951',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26479&size=130',
  },
  {
    id: '0d5cf1d1cd9424ca7d85',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26480&size=130',
  },
  {
    id: '42518adcb6995fc70688',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26436&size=130',
  },
  {
    id: 'e14128cc1489fdd7a498',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26437&size=130',
  },
  {
    id: 'f5c73f4a030fea51b31e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26438&size=130',
  },
  {
    id: 'a1056a8856cdbf93e6dc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26439&size=130',
  },
  {
    id: '7753b3de8f9b66c53f8a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26440&size=130',
  },
  {
    id: 'a77462f95ebcb7e2eead',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26441&size=130',
  },
  {
    id: '75ccb3418f04665a3f15',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26442&size=130',
  },
  {
    id: '00fec773fb3612684b27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26443&size=130',
  },
  {
    id: '843044bd78f891a6c8e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26444&size=130',
  },
  {
    id: 'd0cd11402d05c45b9d14',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26445&size=130',
  },
  {
    id: 'df251da821edc8b391fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26446&size=130',
  },
  {
    id: '2666e5ebd9ae30f069bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26447&size=130',
  },
  {
    id: 'a56e79e345a6acf8f5b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26448&size=130',
  },
  {
    id: '99ba44377872912cc863',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26449&size=130',
  },
  {
    id: '76cfa84294077d592416',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26450&size=130',
  },
  {
    id: 'ebed34600825e17bb834',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26451&size=130',
  },
  {
    id: '1f40c7cdfb8812d64b99',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26452&size=130',
  },
  {
    id: 'd9a7002a3c6fd5318c7e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26453&size=130',
  },
  {
    id: '980d42807ec5979bced4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26454&size=130',
  },
  {
    id: 'edec36610a24e37aba35',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26455&size=130',
  },
  {
    id: 'c98b1d062143c81d9152',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26456&size=130',
  },
  {
    id: 'a0ed75604925a07bf934',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26457&size=130',
  },
  {
    id: '907046fd7ab893e6caa9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26458&size=130',
  },
  {
    id: '1a27cdaaf1ef18b141fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26459&size=130',
  },
  {
    id: '14c8c445f800115e4811',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26460&size=130',
  },
  {
    id: 'b38d1f002345ca1b9354',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26400&size=130',
  },
  {
    id: '7341decce2890bd75298',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26401&size=130',
  },
  {
    id: 'ae82000f3c4ad5148c5b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26402&size=130',
  },
  {
    id: 'd2877d0a414fa811f15e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26403&size=130',
  },
  {
    id: '76b0de3de2780b265269',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26404&size=130',
  },
  {
    id: 'a20a0b8737c2de9c87d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26405&size=130',
  },
  {
    id: '81972b1a175ffe01a74e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26406&size=130',
  },
  {
    id: 'b84613cb2f8ec6d09f9f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26407&size=130',
  },
  {
    id: 'd3f977744b31a26ffb20',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26408&size=130',
  },
  {
    id: 'c47c61f15db4b4eaeda5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26409&size=130',
  },
  {
    id: 'd312759f49daa084f9cb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26410&size=130',
  },
  {
    id: '082fafa293e77ab923f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26411&size=130',
  },
  {
    id: 'fdde5d5361168848d107',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26412&size=130',
  },
  {
    id: 'aaf00b7d3738de668729',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26413&size=130',
  },
  {
    id: '53f6f17bcd3e24607d2f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26414&size=130',
  },
  {
    id: '992e3aa306e6efb8b6f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26415&size=130',
  },
  {
    id: '350a0b8637c3de9d87d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26290&size=130',
  },
  {
    id: '8bb2b43e887b6125386a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26291&size=130',
  },
  {
    id: 'b82280aebceb55b50cfa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26292&size=130',
  },
  {
    id: 'c4dbfd57c112284c7103',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26293&size=130',
  },
  {
    id: '7b8441087d4d9413cd5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26294&size=130',
  },
  {
    id: '1b3920b51cf0f5aeace1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26295&size=130',
  },
  {
    id: '5e946a18565dbf03e64c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26296&size=130',
  },
  {
    id: 'afa09a2ca6694f371678',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26297&size=130',
  },
  {
    id: '233f15b329f6c0a899e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26298&size=130',
  },
  {
    id: '7b1a4c9670d3998dc0c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26299&size=130',
  },
  {
    id: '28ff18732436cd689427',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26300&size=130',
  },
  {
    id: '3dc20c4e300bd955801a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26301&size=130',
  },
  {
    id: '953fa7b39bf672a82be7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26302&size=130',
  },
  {
    id: '308e03023f47d6198f56',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26303&size=130',
  },
  {
    id: 'de92921eae5b47051e4a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26304&size=130',
  },
  {
    id: '4a93071f3b5ad2048b4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26305&size=130',
  },
  {
    id: '5cd812542e11c74f9e00',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26306&size=130',
  },
  {
    id: '7f8f30030c46e518bc57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26307&size=130',
  },
  {
    id: 'a92be1a7dde234bc6df3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26308&size=130',
  },
  {
    id: 'ec3ca5b099f570ab29e4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26309&size=130',
  },
  {
    id: '316824e418a1f1ffa8b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26265&size=130',
  },
  {
    id: '379921151d50f40ead41',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26266&size=130',
  },
  {
    id: '3e3229be15fbfca5a5ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26267&size=130',
  },
  {
    id: '021512992edcc7829ecd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26268&size=130',
  },
  {
    id: '8883990fa54a4c14155b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26269&size=130',
  },
  {
    id: 'c4bed632ea7703295a66',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26270&size=130',
  },
  {
    id: '91ad8221be64573a0e75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26271&size=130',
  },
  {
    id: 'ef4dc3c1ff8416da4f95',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26272&size=130',
  },
  {
    id: 'a84885c4b98150df0990',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26273&size=130',
  },
  {
    id: 'fec5d049ec0c05525c1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26274&size=130',
  },
  {
    id: '2fd1005d3c18d5468c09',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26275&size=130',
  },
  {
    id: 'e12dc9a1f5e41cba45f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26276&size=130',
  },
  {
    id: '60d44958751d9c43c50c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26277&size=130',
  },
  {
    id: 'ee0ec482f8c7119948d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26278&size=130',
  },
  {
    id: '1d0836840ac1e39fbad0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26279&size=130',
  },
  {
    id: 'd7a2f32ecf6b26357f7a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=26280&size=130',
  },
  {
    id: '9e7d49f275b79ce9c5a6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25947&size=130',
  },
  {
    id: '7f08af8793c27a9c23d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25948&size=130',
  },
  {
    id: '7ff8ae7792327b6c2223',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25949&size=130',
  },
  {
    id: '88955a1a665f8f01d64e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25950&size=130',
  },
  {
    id: 'c0cd13422f07c6599f16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25951&size=130',
  },
  {
    id: 'a75f4bd077959ecbc784',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25952&size=130',
  },
  {
    id: '27b4ca3bf67e1f20466f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25953&size=130',
  },
  {
    id: '203bceb4f2f11baf42e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25954&size=130',
  },
  {
    id: '21ebce64f2211b7f4230',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25955&size=130',
  },
  {
    id: 'bf8c57036b468218db57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25956&size=130',
  },
  {
    id: '796990e6aca345fd1cb2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25957&size=130',
  },
  {
    id: 'a34949c675839cddc592',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25958&size=130',
  },
  {
    id: '9e5b75d44991a0cff980',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25959&size=130',
  },
  {
    id: '22fec671fa34136a4a25',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25960&size=130',
  },
  {
    id: '0ee2eb6dd7283e766739',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25961&size=130',
  },
  {
    id: 'b3d65559691c8042d90d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25962&size=130',
  },
  {
    id: '742693a9afec46b21ffd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25963&size=130',
  },
  {
    id: '89beda30e6750f2b5664',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25823&size=130',
  },
  {
    id: 'c922a5ac99e970b729f8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25824&size=130',
  },
  {
    id: 'ce34a3ba9fff76a12fee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25825&size=130',
  },
  {
    id: 'f7f69978a53d4c63152c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25826&size=130',
  },
  {
    id: '87c7e849d40c3d52641d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25827&size=130',
  },
  {
    id: '17e57f6b432eaa70f33f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25828&size=130',
  },
  {
    id: 'c21eab9097d57e8b27c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25829&size=130',
  },
  {
    id: 'a2ebc865f4201d7e4431',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25830&size=130',
  },
  {
    id: '54433fcd0388ead6b399',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25831&size=130',
  },
  {
    id: 'fe679ae9a6ac4ff216bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25832&size=130',
  },
  {
    id: '88daed54d111384f6100',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25833&size=130',
  },
  {
    id: 'd186b7088b4d62133b5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25834&size=130',
  },
  {
    id: 'ffb2983ca4794d271468',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25835&size=130',
  },
  {
    id: '0f696fe753a2bafce3b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25836&size=130',
  },
  {
    id: '375656d86a9d83c3da8c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25837&size=130',
  },
  {
    id: '640606883acdd3938adc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25838&size=130',
  },
  {
    id: '4b2b28a514e0fdbea4f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25839&size=130',
  },
  {
    id: 'fbf5877bbb3e52600b2f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25840&size=130',
  },
  {
    id: '028b7f054340aa1ef351',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25841&size=130',
  },
  {
    id: '44643aea06afeff1b6be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25842&size=130',
  },
  {
    id: '213f5eb162f48baad2e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25843&size=130',
  },
  {
    id: 'fd0c8582b9c7509909d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25844&size=130',
  },
  {
    id: '084171cf4d8aa4d4fd9b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25845&size=130',
  },
  {
    id: 'b76ccde2f1a718f941b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25846&size=130',
  },
  {
    id: 'd7e3f56dc92820767939',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25774&size=130',
  },
  {
    id: '0fbc2c321077f929a066',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25775&size=130',
  },
  {
    id: 'f375cffbf3be1ae043af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25776&size=130',
  },
  {
    id: '38b5053b397ed020896f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25777&size=130',
  },
  {
    id: '7d9d43137f569608cf47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25778&size=130',
  },
  {
    id: '776048ee74ab9df5c4ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25779&size=130',
  },
  {
    id: '14ea2c641021f97fa030',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25780&size=130',
  },
  {
    id: 'd964e0eadcaf35f16cbe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25781&size=130',
  },
  {
    id: '4bd0715e4d1ba445fd0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25782&size=130',
  },
  {
    id: 'ec64d7eaebaf02f15bbe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25783&size=130',
  },
  {
    id: '35e2016c3d29d4778d38',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25784&size=130',
  },
  {
    id: 'e792d21cee5907075e48',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25785&size=130',
  },
  {
    id: 'c630f0beccfb25a57cea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25786&size=130',
  },
  {
    id: 'b5f88276be33576d0e22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25787&size=130',
  },
  {
    id: '1fca2f441301fa5fa310',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25788&size=130',
  },
  {
    id: 'f115c09bfcde15804ccf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25789&size=130',
  },
  {
    id: '37fd05733936d0688927',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25790&size=130',
  },
  {
    id: '71cf42417e04975ace15',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25791&size=130',
  },
  {
    id: 'bfdaf354cf11264f7f00',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25792&size=130',
  },
  {
    id: '183d55b369f680a8d9e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25793&size=130',
  },
  {
    id: '3f1871964dd3a48dfdc2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25794&size=130',
  },
  {
    id: 'febfb1318d74642a3d65',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25795&size=130',
  },
  {
    id: '6db2253c1979f027a968',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25796&size=130',
  },
  {
    id: '910bd885e4c00d9e54d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25797&size=130',
  },
  {
    id: 'ab4ce1c2dd8734d96d96',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25798&size=130',
  },
  {
    id: 'dabd0f333376da288367',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25689&size=130',
  },
  {
    id: 'ce4218cc2489cdd79498',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25690&size=130',
  },
  {
    id: '759da2139e5677082e47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25691&size=130',
  },
  {
    id: '1b8dcb03f7461e184757',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25692&size=130',
  },
  {
    id: 'b913689d54d8bd86e4c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25693&size=130',
  },
  {
    id: 'b30e61805dc5b49bedd4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25694&size=130',
  },
  {
    id: '075cd4d2e89701c95886',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25695&size=130',
  },
  {
    id: '0618ea96d6d33f8d66c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25696&size=130',
  },
  {
    id: 'de5633d80f9de6c3bf8c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25697&size=130',
  },
  {
    id: '98ad76234a66a338fa77',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25698&size=130',
  },
  {
    id: 'a5534add76989fc6c689',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25699&size=130',
  },
  {
    id: '0614ee9ad2df3b8162ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25700&size=130',
  },
  {
    id: 'f3441aca268fcfd1969e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25701&size=130',
  },
  {
    id: '413dabb397f67ea827e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25702&size=130',
  },
  {
    id: 'fe4415ca298fc0d1999e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25703&size=130',
  },
  {
    id: '4b36afb893fd7aa323ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25704&size=130',
  },
  {
    id: 'a102448c78c99197c8d8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25705&size=130',
  },
  {
    id: '3177f0f9ccbc25e27cad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25677&size=130',
  },
  {
    id: 'a9c16b4f570abe54e71b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25678&size=130',
  },
  {
    id: '60aca3229f6776392f76',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25679&size=130',
  },
  {
    id: 'eb85370b0b4ee210bb5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25680&size=130',
  },
  {
    id: '6285bf0b834e6a10335f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25681&size=130',
  },
  {
    id: '0703d98de5c80c9655d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25682&size=130',
  },
  {
    id: 'e99c36120a57e309ba46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25683&size=130',
  },
  {
    id: '82595ad766928fccd683',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25684&size=130',
  },
  {
    id: '7a85a30b9f4e76102f5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25685&size=130',
  },
  {
    id: '9d4d47c37b8692d8cb97',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25686&size=130',
  },
  {
    id: 'f5442eca128ffbd1a29e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25687&size=130',
  },
  {
    id: '0945ddcbe18e08d0519f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25688&size=130',
  },
  {
    id: '2b8f7d064143a81df152',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25562&size=130',
  },
  {
    id: '7ed42f5d1318fa46a309',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25565&size=130',
  },
  {
    id: '082b5ba267e78eb9d7f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25567&size=130',
  },
  {
    id: '733d1eb422f1cbaf92e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25569&size=130',
  },
  {
    id: 'cb24a4ad98e871b628f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25571&size=130',
  },
  {
    id: '85edec64d021397f6030',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25573&size=130',
  },
  {
    id: '3ecc54456800815ed811',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25574&size=130',
  },
  {
    id: '6b690fe033a5dafb83b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25576&size=130',
  },
  {
    id: '733815b129f4c0aa99e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25578&size=130',
  },
  {
    id: '5b233aaa06efefb1b6fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25581&size=130',
  },
  {
    id: '76c6144f280ac154981b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25582&size=130',
  },
  {
    id: '26305ab966fc8fa2d6ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25584&size=130',
  },
  {
    id: '301a4e9372d69b88c2c7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25586&size=130',
  },
  {
    id: '55392cb010f5f9aba0e4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25589&size=130',
  },
  {
    id: 'df14a49d98d8718628c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25591&size=130',
  },
  {
    id: '0b577dde419ba8c5f18a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25594&size=130',
  },
  {
    id: '510e21871dc2f49cadd3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25596&size=130',
  },
  {
    id: 'a216d39fefda06845fcb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25597&size=130',
  },
  {
    id: 'eea0622e5e6bb735ee7a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25600&size=130',
  },
  {
    id: '86d20b5c3719de478708',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25601&size=130',
  },
  {
    id: 'acfa7e734236ab68f227',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25438&size=130',
  },
  {
    id: '66a6b52f896a6034397b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25439&size=130',
  },
  {
    id: '1882f40bc84e2110785f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25440&size=130',
  },
  {
    id: '4693ab1a975f7e01274e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25441&size=130',
  },
  {
    id: '2739c9b0f5f51cab45e4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25442&size=130',
  },
  {
    id: '9d3072b94efca7a2feed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25443&size=130',
  },
  {
    id: '84556cdc5099b9c7e088',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25444&size=130',
  },
  {
    id: '684181c8bd8d54d30d9c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25445&size=130',
  },
  {
    id: 'df5135d8099de0c3b98c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25446&size=130',
  },
  {
    id: 'b08a5b0367468e18d757',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25447&size=130',
  },
  {
    id: 'f4f4107d2c38c5669c29',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25448&size=130',
  },
  {
    id: '3d75d8fce4b90de754a8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25449&size=130',
  },
  {
    id: '1e0df884c4c12d9f74d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25450&size=130',
  },
  {
    id: '3da1da28e66d0f33567c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25451&size=130',
  },
  {
    id: '69918918b55d5c03054c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25452&size=130',
  },
  {
    id: 'be9d5f1463518a0fd340',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25453&size=130',
  },
  {
    id: '76b5943ca87941271868',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25454&size=130',
  },
  {
    id: '545687b8bbfd52a30bec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1119&size=130',
  },
  {
    id: 'bc2850c66c8385dddc92',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1120&size=130',
  },
  {
    id: '1806f5e8c9ad20f379bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1121&size=130',
  },
  {
    id: '95c47b2a476fae31f77e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1122&size=130',
  },
  {
    id: 'f90b16e52aa0c3fe9ab1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1123&size=130',
  },
  {
    id: 'be9d56736a368368da27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1124&size=130',
  },
  {
    id: 'ca2423ca1f8ff6d1af9e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1125&size=130',
  },
  {
    id: 'ed0b07e53ba0d2fe8bb1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1126&size=130',
  },
  {
    id: 'e8e903073f42d61c8f53',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1127&size=130',
  },
  {
    id: '593dbcd3809669c83087',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1129&size=130',
  },
  {
    id: 'd2b63458081de143b80c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1130&size=130',
  },
  {
    id: '1ec3f92dc5682c367579',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1131&size=130',
  },
  {
    id: 'd2dc32320e77e729be66',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1132&size=130',
  },
  {
    id: '23acc242fe0717594e16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1133&size=130',
  },
  {
    id: '46c7a429986c7132287d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1134&size=130',
  },
  {
    id: '9284876abb2f52710b3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1177&size=130',
  },
  {
    id: '0d791b9727d2ce8c97c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1178&size=130',
  },
  {
    id: 'ff82ba6d86286f763639',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1481&size=130',
  },
  {
    id: '717b37940bd1e28fbbc0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1482&size=130',
  },
  {
    id: 'c65e81b1bdf454aa0de5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1483&size=130',
  },
  {
    id: '261f66f05ab5b3ebeaa4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1484&size=130',
  },
  {
    id: '630522ea1eaff7f1aebe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1485&size=130',
  },
  {
    id: '450107ee3babd2f58bba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1486&size=130',
  },
  {
    id: '14b3575c6b198247db08',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1487&size=130',
  },
  {
    id: '0db851576d12844cdd03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1488&size=130',
  },
  {
    id: '8e03d3ecefa906f75fb8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1489&size=130',
  },
  {
    id: '07d65939657c8c22d56d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1490&size=130',
  },
  {
    id: '48d4173b2b7ec2209b6f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1491&size=130',
  },
  {
    id: '73d02b3f177afe24a76b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1492&size=130',
  },
  {
    id: '1de844077842911cc853',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1493&size=130',
  },
  {
    id: 'dc6e8681bac4539a0ad5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1494&size=130',
  },
  {
    id: '9fb0c45ff81a1144480b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1495&size=130',
  },
  {
    id: 'b634e2dbde9e37c06e8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1496&size=130',
  },
  {
    id: 'd9eb8c04b041591f0050',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1497&size=130',
  },
  {
    id: 'c60d90e2aca745f91cb6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1498&size=130',
  },
  {
    id: '125845b779f290acc9e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1499&size=130',
  },
  {
    id: 'e02ab0c58c8065de3c91',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1500&size=130',
  },
  {
    id: 'fb0daae296a77ff926b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1501&size=130',
  },
  {
    id: '0f965d79613c8862d12d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1502&size=130',
  },
  {
    id: '226c71834dc6a498fdd7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1503&size=130',
  },
  {
    id: 'f2d19e3ea27b4b25126a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1504&size=130',
  },
  {
    id: 'e2518fbeb3fb5aa503ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1505&size=130',
  },
  {
    id: '9aa6f449c80c2152781d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1506&size=130',
  },
  {
    id: '1c0773e84fada6f3ffbc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1507&size=130',
  },
  {
    id: '6992017d3d38d4668d29',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1508&size=130',
  },
  {
    id: '7f8616692a2cc3729a3d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1509&size=130',
  },
  {
    id: '49e1230e1f4bf615af5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1510&size=130',
  },
  {
    id: '345487babbff52a10bee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1087&size=130',
  },
  {
    id: 'dbc0172e2b6bc2359b7a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1088&size=130',
  },
  {
    id: 'ecc3212d1d68f436ad79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1089&size=130',
  },
  {
    id: 'ec2022ce1e8bf7d5ae9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1090&size=130',
  },
  {
    id: '36eaf904c5412c1f7550',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1091&size=130',
  },
  {
    id: 'f2003aee06abeff5b6ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1092&size=130',
  },
  {
    id: 'e5732c9d10d8f986a0c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1093&size=130',
  },
  {
    id: 'ecf1261f1a5af304aa4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1094&size=130',
  },
  {
    id: 'b6e67d08414da813f15c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1095&size=130',
  },
  {
    id: 'd33017de2b9bc2c59b8a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1096&size=130',
  },
  {
    id: 'b22277cc4b89a2d7fb98',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1097&size=130',
  },
  {
    id: '643aa2d49e9177cf2e80',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1098&size=130',
  },
  {
    id: 'e4f2231c1f59f607af48',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1099&size=130',
  },
  {
    id: 'ad486da651e3b8bde1f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1100&size=130',
  },
  {
    id: '181fd9f1e5b40cea55a5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1101&size=130',
  },
  {
    id: '6f5cadb291f778a921e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1102&size=130',
  },
  {
    id: 'ea2829c61583fcdda592',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1103&size=130',
  },
  {
    id: '92994e7772329b6cc223',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1104&size=130',
  },
  {
    id: '975a4ab476f19fafc6e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1105&size=130',
  },
  {
    id: 'de2500cb3c8ed5d08c9f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1106&size=130',
  },
  {
    id: '78a2a74c9b0972572b18',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1107&size=130',
  },
  {
    id: '071adff4e3b10aef53a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1108&size=130',
  },
  {
    id: '455b9cb5a0f049ae10e1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1109&size=130',
  },
  {
    id: 'f80822e61ea3f7fdaeb2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1110&size=130',
  },
  {
    id: '1c97c779fb3c12624b2d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1111&size=130',
  },
  {
    id: '94c3402d7c689536cc79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1112&size=130',
  },
  {
    id: '32ade743db0632586b17',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1113&size=130',
  },
  {
    id: '37cae124dd61343f6d70',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1114&size=130',
  },
  {
    id: '2220f5cec98b20d5799a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1115&size=130',
  },
  {
    id: 'e5aa35440901e05fb910',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1116&size=130',
  },
  {
    id: 'abc37a2d4668af36f679',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1117&size=130',
  },
  {
    id: 'e8e83a060643ef1db652',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1118&size=130',
  },
  {
    id: 'ce68d586e9c3009d59d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1175&size=130',
  },
  {
    id: 'ec41f8afc4ea2db474fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1176&size=130',
  },
  {
    id: 'cef5351a095fe001b94e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1399&size=130',
  },
  {
    id: '3fe7cb08f74d1e13475c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1400&size=130',
  },
  {
    id: 'b23647d97b9c92c2cb8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1401&size=130',
  },
  {
    id: '7d5f8bb0b7f55eab07e4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1402&size=130',
  },
  {
    id: '4a69bd8681c3689d31d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1403&size=130',
  },
  {
    id: 'e2f3121c2e59c7079e48',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1404&size=130',
  },
  {
    id: 'fd570cb830fdd9a380ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1405&size=130',
  },
  {
    id: '0233f0dccc9925c77c88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1406&size=130',
  },
  {
    id: 'b29c41737d369468cd27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1407&size=130',
  },
  {
    id: '3cb730580c1de543bc0c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1408&size=130',
  },
  {
    id: '3fbb32540e11e74fbe00',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1409&size=130',
  },
  {
    id: '566d588264c78d99d4d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1410&size=130',
  },
  {
    id: 'fcbaf355cf10264e7f01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1411&size=130',
  },
  {
    id: 'd9aad145ed00045e5d11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1412&size=130',
  },
  {
    id: '3fec36030a46e318ba57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1413&size=130',
  },
  {
    id: '1fac15432906c0589917',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1414&size=130',
  },
  {
    id: 'f77afc95c0d0298e70c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1415&size=130',
  },
  {
    id: '163d12d22e97c7c99e86',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1416&size=130',
  },
  {
    id: '9472919dadd844861dc9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1417&size=130',
  },
  {
    id: 'a0a6a6499a0c73522a1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1418&size=130',
  },
  {
    id: '96c2912dad6844361d79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1419&size=130',
  },
  {
    id: '2cd42c3b107ef920a06f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=1420&size=130',
  },
  {
    id: 'd5be35340971e02fb960',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24684&size=130',
  },
  {
    id: '54d9b553891660483907',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24685&size=130',
  },
  {
    id: '02f4e07edc3b35656c2a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24686&size=130',
  },
  {
    id: 'b9315abb66fe8fa0d6ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24687&size=130',
  },
  {
    id: 'b46148eb74ae9df0c4bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24688&size=130',
  },
  {
    id: '223cdfb6e3f30aad53e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24689&size=130',
  },
  {
    id: 'e3c61d4c2109c8579118',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24690&size=130',
  },
  {
    id: 'ec06138c2fc9c6979fd8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24691&size=130',
  },
  {
    id: '57e0af6a932f7a71233e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24692&size=130',
  },
  {
    id: 'a17e58f464b18defd4a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24693&size=130',
  },
  {
    id: '662c9ca6a0e349bd10f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24694&size=130',
  },
  {
    id: '00a7fb2dc7682e367779',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24695&size=130',
  },
  {
    id: 'd5a5212f1d6af434ad7b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24696&size=130',
  },
  {
    id: 'e8b51d3f217ac824916b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24697&size=130',
  },
  {
    id: '4789b1038d4664183d57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24698&size=130',
  },
  {
    id: '916466ee5aabb3f5eaba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24699&size=130',
  },
  {
    id: '2a2bdaa1e6e40fba56f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24700&size=130',
  },
  {
    id: 'd57724fd18b8f1e6a8a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24701&size=130',
  },
  {
    id: '63fa9170ad35446b1d24',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24702&size=130',
  },
  {
    id: '7e798df3b1b658e801a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24703&size=130',
  },
  {
    id: 'c400108a2ccfc5919cde',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24664&size=130',
  },
  {
    id: '1931ccbbf0fe19a040ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24665&size=130',
  },
  {
    id: '84ed52676e22877cde33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24666&size=130',
  },
  {
    id: 'e8dd3f570312ea4cb303',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24667&size=130',
  },
  {
    id: 'b38963035f46b618ef57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24668&size=130',
  },
  {
    id: '87c5564f6a0a8354da1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24669&size=130',
  },
  {
    id: '7f29ada391e678b821f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24670&size=130',
  },
  {
    id: '910c42867ec3979dced2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24671&size=130',
  },
  {
    id: 'b7eb5b6167248e7ad735',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24672&size=130',
  },
  {
    id: '6c0d8187bdc2549c0dd3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24673&size=130',
  },
  {
    id: 'b6bc583664738d2dd462',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24674&size=130',
  },
  {
    id: '84d36b59571cbe42e70d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24675&size=130',
  },
  {
    id: 'e84f00c53c80d5de8c91',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24676&size=130',
  },
  {
    id: 'a0b4493e757b9c25c56a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24677&size=130',
  },
  {
    id: 'e294081e345bdd05844a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24678&size=130',
  },
  {
    id: '624e89c4b5815cdf0590',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24679&size=130',
  },
  {
    id: '3633d2b9eefc07a25eed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24680&size=130',
  },
  {
    id: '629d8717bb52520c0b43',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24681&size=130',
  },
  {
    id: '47c7a14d9d0874562d19',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24682&size=130',
  },
  {
    id: 'ba025d8861cd8893d1dc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24683&size=130',
  },
  {
    id: '8d9ac22ffe6a17344e7b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24515&size=130',
  },
  {
    id: '9d51d5e4e9a100ff59b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24516&size=130',
  },
  {
    id: '34a27d174152a80cf143',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24517&size=130',
  },
  {
    id: '0f0345b679f390adc9e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24518&size=130',
  },
  {
    id: '3bb170044c41a51ffc50',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24519&size=130',
  },
  {
    id: 'e9b6ad03914678182157',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24520&size=130',
  },
  {
    id: '22e167545b11b24feb00',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24521&size=130',
  },
  {
    id: '89f5cf40f3051a5b4314',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24522&size=130',
  },
  {
    id: '675f20ea1caff5f1acbe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24523&size=130',
  },
  {
    id: '5a3f1a8a26cfcf9196de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24524&size=130',
  },
  {
    id: '558114342871c12f9860',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24525&size=130',
  },
  {
    id: 'cf5a8defb1aa58f401bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24526&size=130',
  },
  {
    id: '7cff3f4a030fea51b31e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24527&size=130',
  },
  {
    id: '6f7133c40f81e6dfbf90',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24528&size=130',
  },
  {
    id: 'faf9a74c9b0972572b18',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24529&size=130',
  },
  {
    id: 'b3b9ed0cd14938176158',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24530&size=130',
  },
  {
    id: 'a4b4fb01c7442e1a7755',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24531&size=130',
  },
  {
    id: '9848c0fdfcb815e64ca9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24532&size=130',
  },
  {
    id: '8f1ad6afeaea03b45afb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24533&size=130',
  },
  {
    id: '10b94a0c76499f17c658',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24534&size=130',
  },
  {
    id: 'fcefa75a9b1f72412b0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24535&size=130',
  },
  {
    id: 'a570f1c5cd8024de7d91',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24536&size=130',
  },
  {
    id: '97bcc209fe4c17124e5d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24537&size=130',
  },
  {
    id: '9d8bcb3ef77b1e25476a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24538&size=130',
  },
  {
    id: '87744fc173849adac395',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24388&size=130',
  },
  {
    id: '7d77b4c2888761d93896',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24389&size=130',
  },
  {
    id: 'fd1937ac0be9e2b7bbf8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24390&size=130',
  },
  {
    id: '7cabb71e8b5b62053b4a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24391&size=130',
  },
  {
    id: '1b74dfc1e3840ada5395',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24392&size=130',
  },
  {
    id: 'bfc07a754630af6ef621',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24393&size=130',
  },
  {
    id: '09a0cf15f3501a0e4341',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24394&size=130',
  },
  {
    id: '3459f3eccfa926f77fb8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24395&size=130',
  },
  {
    id: '645fa4ea98af71f128be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24396&size=130',
  },
  {
    id: '0a8bcb3ef77b1e25476a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24397&size=130',
  },
  {
    id: '306df2d8ce9d27c37e8c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24398&size=130',
  },
  {
    id: '4b2c8899b4dc5d8204cd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24399&size=130',
  },
  {
    id: 'c7b51b002745ce1b9754',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24400&size=130',
  },
  {
    id: 'ed1030a50ce0e5bebcf1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24401&size=130',
  },
  {
    id: '22f5fc40c005295b7014',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24402&size=130',
  },
  {
    id: 'e31b3cae00ebe9b5b0fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24403&size=130',
  },
  {
    id: '8c9f542a686f8131d87e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24404&size=130',
  },
  {
    id: 'b030698555c0bc9ee5d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24405&size=130',
  },
  {
    id: 'ad7877cd4b88a2d6fb99',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24406&size=130',
  },
  {
    id: '7bc1a0749c31756f2c20',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24407&size=130',
  },
  {
    id: 'bef36a465603bf5de612',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24408&size=130',
  },
  {
    id: '1433c186fdc3149d4dd2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24409&size=130',
  },
  {
    id: '445d92e8aead47f31ebc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24410&size=130',
  },
  {
    id: '6fc5b87084356d6b3424',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24411&size=130',
  },
  {
    id: '1d3dcd88f1cd189341dc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24412&size=130',
  },
  {
    id: '1ab2b5068943601d3952',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24099&size=130',
  },
  {
    id: 'e4c64c7270379969c026',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24100&size=130',
  },
  {
    id: 'b7781ecc2289cbd79298',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24101&size=130',
  },
  {
    id: '80682adc1699ffc7a688',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24102&size=130',
  },
  {
    id: '6edec56af92f1071493e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24103&size=130',
  },
  {
    id: '38999c2da06849361079',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24104&size=130',
  },
  {
    id: 'bb191ead22e8cbb692f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24105&size=130',
  },
  {
    id: '07f9a14d9d0874562d19',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24106&size=130',
  },
  {
    id: '936f34db089ee1c0b88f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24107&size=130',
  },
  {
    id: '818021341d71f42fad60',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24108&size=130',
  },
  {
    id: '4bb0ea04d6413f1f6650',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24109&size=130',
  },
  {
    id: 'e60644b278f791a9c8e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24110&size=130',
  },
  {
    id: '4de9ee5dd2183b466209',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24111&size=130',
  },
  {
    id: '121aaeae92eb7bb522fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24112&size=130',
  },
  {
    id: 'a19a1c2e206bc935907a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24113&size=130',
  },
  {
    id: 'cfbc71084d4da413fd5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24114&size=130',
  },
  {
    id: 'e5ca5a7e663b8f65d62a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24115&size=130',
  },
  {
    id: '50eae85ed41b3d45640a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24116&size=130',
  },
  {
    id: '30f18945b5005c5e0511',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24117&size=130',
  },
  {
    id: '63e7d953e5160c485507',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24118&size=130',
  },
  {
    id: 'aafd11492d0cc4529d1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24119&size=130',
  },
  {
    id: 'd78263365f73b62def62',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24120&size=130',
  },
  {
    id: '71b9c40df84811164859',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24121&size=130',
  },
  {
    id: 'e386553269778029d966',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24122&size=130',
  },
  {
    id: '3a4842ff7eba97e4ceab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24052&size=130',
  },
  {
    id: '8c36f581c9c4209a79d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24053&size=130',
  },
  {
    id: '34154ea272e79bb9c2f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24054&size=130',
  },
  {
    id: 'ebf8904fac0a45541c1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24055&size=130',
  },
  {
    id: '48303c8700c2e99cb0d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24056&size=130',
  },
  {
    id: '345441e37da694f8cdb7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24057&size=130',
  },
  {
    id: 'a1e5d752eb1702495b06',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24058&size=130',
  },
  {
    id: '151562a25ee7b7b9eef6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24059&size=130',
  },
  {
    id: 'bf60cfd7f3921acc4383',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24060&size=130',
  },
  {
    id: '29dd586a642f8d71d43e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24061&size=130',
  },
  {
    id: '69451bf227b7cee997a6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24062&size=130',
  },
  {
    id: '9683e534d971302f6960',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24063&size=130',
  },
  {
    id: 'ad28e99fd5da3c8465cb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24008&size=130',
  },
  {
    id: '067043c77f8296dccf93',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24009&size=130',
  },
  {
    id: '5e7e18c9248ccdd2949d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24010&size=130',
  },
  {
    id: '8c55cbe2f7a71ef947b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24011&size=130',
  },
  {
    id: 'cb148ba3b7e65eb807f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24012&size=130',
  },
  {
    id: 'bbabfa1cc6592f077648',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24013&size=130',
  },
  {
    id: 'd8529ae5a6a04ffe16b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24014&size=130',
  },
  {
    id: '0f4b4cfc70b999e7c0a8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24015&size=130',
  },
  {
    id: '3e9362245e61b73fee70',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24016&size=130',
  },
  {
    id: 'ed9ab02d8c6865363c79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24017&size=130',
  },
  {
    id: '0c5852ef6eaa87f4debb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24018&size=130',
  },
  {
    id: '10124fa573e09abec3f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24019&size=130',
  },
  {
    id: '169e4e29726c9b32c27d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24020&size=130',
  },
  {
    id: 'e740bef782b26bec32a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24021&size=130',
  },
  {
    id: '109e4a29766c9f32c67d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24022&size=130',
  },
  {
    id: '47a31c142051c90f9040',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24023&size=130',
  },
  {
    id: '7d5529e215a7fcf9a5b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24024&size=130',
  },
  {
    id: '4acd1f7a233fca61932e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24025&size=130',
  },
  {
    id: '324b64fc58b9b1e7e8a8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24026&size=130',
  },
  {
    id: 'a1f3f644ca01235f7a10',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24027&size=130',
  },
  {
    id: 'd3d1f566c923207d7932',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23978&size=130',
  },
  {
    id: 'a76380d4bc9155cf0c80',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23979&size=130',
  },
  {
    id: '295809ef35aadcf485bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23980&size=130',
  },
  {
    id: 'd2e3f354cf11264f7f00',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23981&size=130',
  },
  {
    id: '4b9469235566bc38e577',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23982&size=130',
  },
  {
    id: '1bde3869042ced72b43d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23983&size=130',
  },
  {
    id: '21eb1d5c2119c8479108',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23984&size=130',
  },
  {
    id: '6421599665d38c8dd5c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23985&size=130',
  },
  {
    id: '1e2720901cd5f58bacc4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23986&size=130',
  },
  {
    id: 'bd848233be7657280e67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23987&size=130',
  },
  {
    id: '77d14f6673239a7dc332',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23988&size=130',
  },
  {
    id: '9abda30a9f4f76112f5e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23989&size=130',
  },
  {
    id: 'c8fef249ce0c27527e1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23990&size=130',
  },
  {
    id: '1c7027c71b82f2dcab93',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23991&size=130',
  },
  {
    id: '2b4a1ffd23b8cae693a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23992&size=130',
  },
  {
    id: '31b8040f384ad114885b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23993&size=130',
  },
  {
    id: '1334258319c6f098a9d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23994&size=130',
  },
  {
    id: 'b7838034bc71552f0c60',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23995&size=130',
  },
  {
    id: '6bea5b5d67188e46d709',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23996&size=130',
  },
  {
    id: '1d152ca210e7f9b9a0f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23997&size=130',
  },
  {
    id: 'dd82ef35d3703a2e6361',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23998&size=130',
  },
  {
    id: '0ebc3d0b014ee810b15f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23999&size=130',
  },
  {
    id: '08fa444d78089156c819',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24000&size=130',
  },
  {
    id: '45b208053440dd1e8451',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24001&size=130',
  },
  {
    id: '86d2c865f4201d7e4431',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24002&size=130',
  },
  {
    id: '1784583364768d28d467',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24003&size=130',
  },
  {
    id: '687720c01c85f5dbac94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24004&size=130',
  },
  {
    id: 'ca5a83edbfa856f60fb9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24005&size=130',
  },
  {
    id: 'f676bcc1808469da3095',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24006&size=130',
  },
  {
    id: '0e8e4539797c9022c96d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=24007&size=130',
  },
  {
    id: 'bb3aab8d97c87e9627d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23964&size=130',
  },
  {
    id: '270736b00af5e3abbae4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23965&size=130',
  },
  {
    id: '8b6799d0a5954ccb1584',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23966&size=130',
  },
  {
    id: '2acd397a053fec61b52e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23967&size=130',
  },
  {
    id: '82e3ae5492117b4f2200',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23968&size=130',
  },
  {
    id: 'aba28615ba50530e0a41',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23969&size=130',
  },
  {
    id: '6fc441737d369468cd27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23970&size=130',
  },
  {
    id: '8879a7ce9b8b72d52b9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23971&size=130',
  },
  {
    id: '9217baa086e56fbb36f4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23972&size=130',
  },
  {
    id: '55ae7c19405ca902f04d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23973&size=130',
  },
  {
    id: '2a4f00f83cbdd5e38cac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23974&size=130',
  },
  {
    id: 'c9c2e275de30376e6e21',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23975&size=130',
  },
  {
    id: 'd43df08acccf25917cde',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23976&size=130',
  },
  {
    id: '4b476ef052b5bbebe2a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23977&size=130',
  },
  {
    id: '750545b379f690a8c9e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23740&size=130',
  },
  {
    id: 'aa419bf7a7b24eec17a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23741&size=130',
  },
  {
    id: 'e68cd43ae87f0121586e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23742&size=130',
  },
  {
    id: '0daa3e1c0259eb07b248',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23743&size=130',
  },
  {
    id: '9e5ad2eceea907f75eb8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23744&size=130',
  },
  {
    id: '7e5833ee0fabe6f5bfba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23745&size=130',
  },
  {
    id: '594617f02bb5c2eb9ba4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23746&size=130',
  },
  {
    id: 'efb9a00f9c4a75142c5b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23747&size=130',
  },
  {
    id: '15db5d6d61288876d139',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23748&size=130',
  },
  {
    id: 'bf3df68bcace23907adf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23749&size=130',
  },
  {
    id: '3e0d74bb48fea1a0f8ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23750&size=130',
  },
  {
    id: 'f22cb99a85df6c8135ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23751&size=130',
  },
  {
    id: '17a9531f6f5a8604df4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23752&size=130',
  },
  {
    id: '40e8055e391bd045890a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23753&size=130',
  },
  {
    id: 'f5d0b3668f23667d3f32',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23754&size=130',
  },
  {
    id: '759b322d0e68e736be79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23755&size=130',
  },
  {
    id: 'c10781b1bdf454aa0de5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23756&size=130',
  },
  {
    id: '4a590bef37aadef487bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23757&size=130',
  },
  {
    id: '418a033c3f79d6278f68',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23758&size=130',
  },
  {
    id: '09344a8276c79f99c6d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23759&size=130',
  },
  {
    id: '8bc3d775eb30026e5b21',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23760&size=130',
  },
  {
    id: 'bbefd35eef1b06455f0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23524&size=130',
  },
  {
    id: 'ff959624aa61433f1a70',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23525&size=130',
  },
  {
    id: '14967e274262ab3cf273',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23526&size=130',
  },
  {
    id: 'f2f49945a5004c5e1511',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23527&size=130',
  },
  {
    id: '4da729161553fc0da542',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23528&size=130',
  },
  {
    id: '3a095fb863fd8aa3d3ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23529&size=130',
  },
  {
    id: 'ccd5aa6496217f7f2630',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23530&size=130',
  },
  {
    id: '66d201633d26d4788d37',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23531&size=130',
  },
  {
    id: '71eb115a2d1fc4419d0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23532&size=130',
  },
  {
    id: '207c41cd7d8894d6cd99',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23533&size=130',
  },
  {
    id: '60104ca170e499bac0f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23456&size=130',
  },
  {
    id: '8a92a7239b6672382b77',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23457&size=130',
  },
  {
    id: '1c5d32ec0ea9e7f7beb8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23458&size=130',
  },
  {
    id: '54587be947acaef2f7bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23459&size=130',
  },
  {
    id: '417069c15584bcdae595',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23460&size=130',
  },
  {
    id: 'a7ac8e1db2585b060249',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23461&size=130',
  },
  {
    id: 'cf91e520d965303b6974',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23462&size=130',
  },
  {
    id: 'e0afcb1ef75b1e05474a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23463&size=130',
  },
  {
    id: '45ea615b5d1eb440ed0f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23464&size=130',
  },
  {
    id: '517474c54880a1def891',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23465&size=130',
  },
  {
    id: '5f6979d8459dacc3f58c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23466&size=130',
  },
  {
    id: '04ac231d1f58f606af49',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23467&size=130',
  },
  {
    id: 'b9e19950a5154c4b1504',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23468&size=130',
  },
  {
    id: 'fc26289714d2fd8ca4c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23384&size=130',
  },
  {
    id: 'dca109103555dc0b8544',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23385&size=130',
  },
  {
    id: '069ed02fec6a05345c7b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23386&size=130',
  },
  {
    id: '113dc68cfac913974ad8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23387&size=130',
  },
  {
    id: '819851296d6c8432dd7d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23388&size=130',
  },
  {
    id: '788da93c95797c272568',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23389&size=130',
  },
  {
    id: '04a1d610ea55030b5a44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23390&size=130',
  },
  {
    id: '52ea815bbd1e54400d0f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23391&size=130',
  },
  {
    id: '5383bf3283776a293366',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23392&size=130',
  },
  {
    id: '15acf81dc4582d067449',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23393&size=130',
  },
  {
    id: '2ca7c216fe53170d4e42',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23394&size=130',
  },
  {
    id: 'eac90578393dd063892c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23395&size=130',
  },
  {
    id: '62858a34b6715f2f0660',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23396&size=130',
  },
  {
    id: 'a4c24d7371369868c127',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23397&size=130',
  },
  {
    id: '836f69de559bbcc5e58a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23398&size=130',
  },
  {
    id: '39af0b1f375ade04874b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23230&size=130',
  },
  {
    id: '700f43bf7ffa96a4cfeb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23231&size=130',
  },
  {
    id: '6bda276a1b2ff271ab3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23232&size=130',
  },
  {
    id: 'a969e4d9d89c31c2688d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23233&size=130',
  },
  {
    id: '61d02f601325fa7ba334',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23234&size=130',
  },
  {
    id: '665929e915acfcf2a5bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23235&size=130',
  },
  {
    id: 'e15fa9ef95aa7cf425bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23236&size=130',
  },
  {
    id: '5ba412142e51c70f9e40',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23237&size=130',
  },
  {
    id: '25346f8453c1ba9fe3d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23238&size=130',
  },
  {
    id: '7f7e34ce088be1d5b89a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23239&size=130',
  },
  {
    id: '367072c04e85a7dbfe94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23240&size=130',
  },
  {
    id: '9152d4e2e8a701f958b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23241&size=130',
  },
  {
    id: 'a1f5e745db00325e6b11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23242&size=130',
  },
  {
    id: '3e1279a245e7acb9f5f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23243&size=130',
  },
  {
    id: '10e950596c1c8542dc0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23244&size=130',
  },
  {
    id: '49d308633426dd788437',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23245&size=130',
  },
  {
    id: '19255b9567d08e8ed7c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23246&size=130',
  },
  {
    id: 'ea5da9ed95a87cf625b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23247&size=130',
  },
  {
    id: '7b3227821bc7f299abd6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23248&size=130',
  },
  {
    id: '5a9807283b6dd2338b7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23249&size=130',
  },
  {
    id: 'e0279194add1448f1dc0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23037&size=130',
  },
  {
    id: '456c37df0b9ae2c4bb8b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23038&size=130',
  },
  {
    id: '39a94a1a765f9f01c64e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23039&size=130',
  },
  {
    id: '2479a8c9948c7dd2249d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23040&size=130',
  },
  {
    id: '5f8bd23bee7e07205e6f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23041&size=130',
  },
  {
    id: '04f78a47b6025f5c0613',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23042&size=130',
  },
  {
    id: '30e1bf5183146a4a3305',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23043&size=130',
  },
  {
    id: 'ff8277324b77a229fb66',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23044&size=130',
  },
  {
    id: 'cc93452379669038c977',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23045&size=130',
  },
  {
    id: '11c59b75a7304e6e1721',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23046&size=130',
  },
  {
    id: '3707bcb780f269ac30e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23047&size=130',
  },
  {
    id: 'a9682dd8119df8c3a18c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23048&size=130',
  },
  {
    id: 'cf994a29766c9f32c67d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23049&size=130',
  },
  {
    id: 'dcf35a4366068f58d617',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23050&size=130',
  },
  {
    id: '7b0ffcbfc0fa29a470eb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23051&size=130',
  },
  {
    id: '8b580be837addef387bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23052&size=130',
  },
  {
    id: '567fd7cfeb8a02d45b9b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23053&size=130',
  },
  {
    id: '85ab071b3b5ed2008b4f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23054&size=130',
  },
  {
    id: '0ad98969b52c5c72053d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23055&size=130',
  },
  {
    id: 'b04f2cff10baf9e4a0ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23056&size=130',
  },
  {
    id: '439322201e65f73bae74',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23021&size=130',
  },
  {
    id: 'ae29cc9af0df198140ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23022&size=130',
  },
  {
    id: 'a391c022fc6715394c76',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23023&size=130',
  },
  {
    id: 'c610baa386e66fb836f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23024&size=130',
  },
  {
    id: 'd878a5cb998e70d0299f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23025&size=130',
  },
  {
    id: '488536360a73e32dba62',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23026&size=130',
  },
  {
    id: '8abbf508c94d2013795c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23027&size=130',
  },
  {
    id: '784500f63cb3d5ed8ca2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23028&size=130',
  },
  {
    id: '9988e03bdc7e35206c6f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23029&size=130',
  },
  {
    id: 'ee7d94cea88b41d5189a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23030&size=130',
  },
  {
    id: '8db0f603ca4623187a57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23031&size=130',
  },
  {
    id: '615e15ed29a8c0f699b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23032&size=130',
  },
  {
    id: '29735cc0608589dbd094',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23033&size=130',
  },
  {
    id: '3f0949ba75ff9ca1c5ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23034&size=130',
  },
  {
    id: 'd6a9a11a9d5f74012d4e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23035&size=130',
  },
  {
    id: '0fb87f0b434eaa10f35f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23036&size=130',
  },
  {
    id: '45de066d3a28d3768a39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22991&size=130',
  },
  {
    id: '428c1e3f227acb24926b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22992&size=130',
  },
  {
    id: '403a1d8921ccc89291dd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22993&size=130',
  },
  {
    id: '15b44b0777429e1cc753',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22994&size=130',
  },
  {
    id: '729c2d2f116af834a17b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22995&size=130',
  },
  {
    id: '788620351c70f52eac61',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22996&size=130',
  },
  {
    id: '011858ab64ee8db0d4ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22997&size=130',
  },
  {
    id: 'd1528be1b7a45efa07b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22998&size=130',
  },
  {
    id: '2e7a75c9498ca0d2f99d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22999&size=130',
  },
  {
    id: 'de648ad7b6925fcc0683',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23000&size=130',
  },
  {
    id: 'b9b8ec0bd04e3910605f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23001&size=130',
  },
  {
    id: '618c373f0b7ae224bb6b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23002&size=130',
  },
  {
    id: '9180c633fa7613284a67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23003&size=130',
  },
  {
    id: '94c3c470f835116b4824',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23004&size=130',
  },
  {
    id: '5b2e0a9d36d8df8686c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23005&size=130',
  },
  {
    id: 'd38a8139bd7c54220d6d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23006&size=130',
  },
  {
    id: '38e96b5a571fbe41e70e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23007&size=130',
  },
  {
    id: 'f04e9cfda0b849e610a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23008&size=130',
  },
  {
    id: '28ec455f791a9044c90b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23009&size=130',
  },
  {
    id: '3f0451b76df284acdde3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=23010&size=130',
  },
  {
    id: '336a06d93a9cd3c28a8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22969&size=130',
  },
  {
    id: 'db39ed8ad1cf389161de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22970&size=130',
  },
  {
    id: '18792fca138ffad1a39e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22971&size=130',
  },
  {
    id: 'b2d58266be23577d0e32',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22972&size=130',
  },
  {
    id: '088f393c0579ec27b568',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22973&size=130',
  },
  {
    id: 'd0dce26fde2a37746e3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22974&size=130',
  },
  {
    id: 'c98afa39c67c2f22766d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22975&size=130',
  },
  {
    id: '55bf190c2549cc179558',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22976&size=130',
  },
  {
    id: 'cc1b81a8bded54b30dfc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22977&size=130',
  },
  {
    id: '6789293a157ffc21a56e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22978&size=130',
  },
  {
    id: 'ce5781e4bda154ff0db0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22979&size=130',
  },
  {
    id: '16a85e1b625e8b00d24f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22980&size=130',
  },
  {
    id: 'f5f8bc4b800e6950301f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22981&size=130',
  },
  {
    id: 'd0c09a73a6364f681627',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22982&size=130',
  },
  {
    id: '405e0bed37a8def687b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22983&size=130',
  },
  {
    id: 'cf6b8bd8b79d5ec3078c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22984&size=130',
  },
  {
    id: '5b1f1eac22e9cbb792f8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22985&size=130',
  },
  {
    id: '697e2fcd1388fad6a399',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22986&size=130',
  },
  {
    id: '91bed60dea4803165a59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22987&size=130',
  },
  {
    id: 'baaffa1cc6592f077648',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22988&size=130',
  },
  {
    id: 'a35189e2b5a75cf905b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22950&size=130',
  },
  {
    id: '56737dc04185a8dbf194',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22951&size=130',
  },
  {
    id: 'a6d68265be20577e0e31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22952&size=130',
  },
  {
    id: '825ca7ef9baa72f42bbb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22953&size=130',
  },
  {
    id: '7c1e5aad66e88fb6d6f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22954&size=130',
  },
  {
    id: 'd1b8f60bca4e23107a5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22955&size=130',
  },
  {
    id: 'ee3fce8cf2c91b9742d8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22956&size=130',
  },
  {
    id: '530f72bc4ef9a7a7fee8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22957&size=130',
  },
  {
    id: 'dbb9f90ac54f2c11755e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22958&size=130',
  },
  {
    id: '096e2add1698ffc6a689',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22959&size=130',
  },
  {
    id: '4b7877cb4b8ea2d0fb9f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22960&size=130',
  },
  {
    id: '126f2fdc1399fac7a388',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22961&size=130',
  },
  {
    id: 'a1469ff5a3b04aee13a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22962&size=130',
  },
  {
    id: '486a77d94b9ca2c2fb8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22963&size=130',
  },
  {
    id: 'bfcb8778bb3d52630b2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22964&size=130',
  },
  {
    id: '663e5f8d63c88a96d3d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22965&size=130',
  },
  {
    id: '51bb6b08574dbe13e75c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22966&size=130',
  },
  {
    id: '1cfb27481b0df253ab1c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22967&size=130',
  },
  {
    id: '85d3b1608d25647b3d34',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22968&size=130',
  },
  {
    id: 'a468badb869e6fc0368f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22930&size=130',
  },
  {
    id: '52504de371a698f8c1b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22931&size=130',
  },
  {
    id: 'f92de19edddb34856dca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22932&size=130',
  },
  {
    id: 'ea1af3a9cfec26b27ffd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22933&size=130',
  },
  {
    id: '82a79814a4514d0f1440',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22934&size=130',
  },
  {
    id: 'f20ae9b9d5fc3ca265ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22935&size=130',
  },
  {
    id: 'ec48f8fbc4be2de074af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22936&size=130',
  },
  {
    id: 'ede6f855c4102d4e7401',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22937&size=130',
  },
  {
    id: '49265f9563d08a8ed3c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22938&size=130',
  },
  {
    id: '515b46e87aad93f3cabc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22939&size=130',
  },
  {
    id: 'cb83db30e7750e2b5764',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22940&size=130',
  },
  {
    id: 'c793d620ea65033b5a74',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22941&size=130',
  },
  {
    id: 'e7f8f54bc90e2050791f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22942&size=130',
  },
  {
    id: '11d9026a3e2fd7718e3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22943&size=130',
  },
  {
    id: 'e15acde9f1ac18f241bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22944&size=130',
  },
  {
    id: 'bc909123ad6644381d77',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22945&size=130',
  },
  {
    id: 'f567dbd4e7910ecf5780',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22946&size=130',
  },
  {
    id: '7c4753f46fb186efdfa0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22947&size=130',
  },
  {
    id: '898ba1389d7d74232d6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22948&size=130',
  },
  {
    id: 'f048d9fbe5be0ce055af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22949&size=130',
  },
  {
    id: '066fb3dc8f9966c73f88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22841&size=130',
  },
  {
    id: '8398352b096ee030b97f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22842&size=130',
  },
  {
    id: '1289a53a997f7021296e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22843&size=130',
  },
  {
    id: '612fd19cedd904875dc8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22844&size=130',
  },
  {
    id: 'f8f1494275079c59c516',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22845&size=130',
  },
  {
    id: '4e3efc8dc0c8299670d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22846&size=130',
  },
  {
    id: '28929b21a7644e3a1775',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22847&size=130',
  },
  {
    id: '41698ddab19f58c1018e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22848&size=130',
  },
  {
    id: 'ca8e073d3b78d2268b69',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22849&size=130',
  },
  {
    id: '51929f21a3644a3a1375',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22850&size=130',
  },
  {
    id: 'f735388604c3ed9db4d2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22851&size=130',
  },
  {
    id: 'b9ed715e4d1ba445fd0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22852&size=130',
  },
  {
    id: '93245a9766d28f8cd6c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22853&size=130',
  },
  {
    id: 'a2fd684e540bbd55e41a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22854&size=130',
  },
  {
    id: '3dbff60cca4923177a58',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22855&size=130',
  },
  {
    id: '9cdc586f642a8d74d43b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22856&size=130',
  },
  {
    id: 'f28737340b71e22fbb60',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22857&size=130',
  },
  {
    id: '2de6eb55d7103e4e6701',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22858&size=130',
  },
  {
    id: 'a038678b5bceb290ebdf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22859&size=130',
  },
  {
    id: 'b4d374604825a17bf834',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22860&size=130',
  },
  {
    id: 'fedb73684f2da673ff3c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22785&size=130',
  },
  {
    id: 'f9a9771a4b5fa201fb4e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22786&size=130',
  },
  {
    id: '58e3d750eb15024b5b04',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22787&size=130',
  },
  {
    id: '674eeffdd3b83ae663a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22788&size=130',
  },
  {
    id: 'b5823c310074e92ab065',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22789&size=130',
  },
  {
    id: '716afbd9c79c2ec2778d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22790&size=130',
  },
  {
    id: '0298892bb56e5c30057f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22791&size=130',
  },
  {
    id: 'b54c31ff0dbae4e4bdab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22792&size=130',
  },
  {
    id: '98141da721e2c8bc91f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22793&size=130',
  },
  {
    id: 'c9184fab73ee9ab0c3ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22794&size=130',
  },
  {
    id: '60d1e762db2732796b36',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22795&size=130',
  },
  {
    id: '52ded26dee2807765e39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22796&size=130',
  },
  {
    id: 'bc9c3d2f016ae834b17b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22797&size=130',
  },
  {
    id: '1fc69d75a130486e1121',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22798&size=130',
  },
  {
    id: '1b1798a4a4e14dbf14f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22799&size=130',
  },
  {
    id: '396ca5df999a70c4298b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22800&size=130',
  },
  {
    id: '9e4e73fc4fb9a6e7ffa8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22625&size=130',
  },
  {
    id: '689a8628ba6d53330a7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22626&size=130',
  },
  {
    id: '87fe684c5409bd57e418',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22627&size=130',
  },
  {
    id: '0ae0e252de1737496e06',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22628&size=130',
  },
  {
    id: '9ade736c4f29a677ff38',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22629&size=130',
  },
  {
    id: '49aea31c9f5976072f48',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22630&size=130',
  },
  {
    id: 'a6a64d147151980fc140',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22631&size=130',
  },
  {
    id: '1aa0fe12c2572b097246',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22632&size=130',
  },
  {
    id: '475ea2ec9ea977f72eb8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22633&size=130',
  },
  {
    id: 'b50953bb6ffe86a0dfef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22634&size=130',
  },
  {
    id: '05dfe26dde2837766e39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22635&size=130',
  },
  {
    id: '1bd1fb63c7262e787737',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22636&size=130',
  },
  {
    id: '4a98ab2a976f7e31277e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22637&size=130',
  },
  {
    id: '6cd48e66b2235b7d0232',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22638&size=130',
  },
  {
    id: 'af804c3270779929c066',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22639&size=130',
  },
  {
    id: '44e6f154cd11244f7d00',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22585&size=130',
  },
  {
    id: '1a73acc1908479da2095',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22586&size=130',
  },
  {
    id: '0116b6a48ae163bf3af0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22587&size=130',
  },
  {
    id: '384c88feb4bb5de504aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22588&size=130',
  },
  {
    id: '30958127bd62543c0d73',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22589&size=130',
  },
  {
    id: 'f43346817ac4939acad5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22590&size=130',
  },
  {
    id: 'f2fd414f7d0a9454cd1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22591&size=130',
  },
  {
    id: '35c9f97bc53e2c60752f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22592&size=130',
  },
  {
    id: 'b3b17e034246ab18f257',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22593&size=130',
  },
  {
    id: '3e80f032cc7725297c66',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22594&size=130',
  },
  {
    id: '7c93b3218f64663a3f75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22595&size=130',
  },
  {
    id: 'ecc524771832f16ca823',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22596&size=130',
  },
  {
    id: '2326ea94d6d13f8f66c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22597&size=130',
  },
  {
    id: 'fd0537b70bf2e2acbbe3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22598&size=130',
  },
  {
    id: '2017eba5d7e03ebe67f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22599&size=130',
  },
  {
    id: '4f4a8bf8b7bd5ee307ac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22600&size=130',
  },
  {
    id: '88864d347171982fc160',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22601&size=130',
  },
  {
    id: 'f5eb33590f1ce642bf0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22602&size=130',
  },
  {
    id: '2e01e9b3d5f63ca865e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22603&size=130',
  },
  {
    id: '31fcf14ecd0b24557d1a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22604&size=130',
  },
  {
    id: '4b64e5d6d99330cd6982',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22562&size=130',
  },
  {
    id: '0adea56c992970772938',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22563&size=130',
  },
  {
    id: '92663ad40691efcfb680',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22564&size=130',
  },
  {
    id: '2cb08502b94750190956',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22565&size=130',
  },
  {
    id: 'd4d17e634226ab78f237',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22566&size=130',
  },
  {
    id: '7454dfe6e3a30afd53b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22567&size=130',
  },
  {
    id: '52baf608ca4d23137a5c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22568&size=130',
  },
  {
    id: 'f76d52df6e9a87c4de8b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22569&size=130',
  },
  {
    id: '0798a12a9d6f74312d7e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22570&size=130',
  },
  {
    id: '6429c39bffde16804fcf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22571&size=130',
  },
  {
    id: '66acc61efa5b13054a4a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22572&size=130',
  },
  {
    id: '4e68efdad39f3ac1638e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22573&size=130',
  },
  {
    id: '7715d5a7e9e200bc59f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22574&size=130',
  },
  {
    id: '04eba7599b1c72422b0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22575&size=130',
  },
  {
    id: '049bb829846c6d32347d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22576&size=130',
  },
  {
    id: 'd2376f8553c0ba9ee3d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22577&size=130',
  },
  {
    id: '890e37bc0bf9e2a7bbe8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22578&size=130',
  },
  {
    id: '6b95d427e862013c5873',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22579&size=130',
  },
  {
    id: '47feff4cc3092a577318',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22580&size=130',
  },
  {
    id: '2d0a94b8a8fd41a318ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22581&size=130',
  },
  {
    id: '80bf3a0d0648ef16b659',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22582&size=130',
  },
  {
    id: '82ff394d0508ec56b519',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22583&size=130',
  },
  {
    id: '86cd327f0e3ae764be2b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22584&size=130',
  },
  {
    id: '44662fdb139efac0a38f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22503&size=130',
  },
  {
    id: 'fe209a9da6d84f8616c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22504&size=130',
  },
  {
    id: 'd5ffb0428c0765593c16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22505&size=130',
  },
  {
    id: '15e0735d4f18a646ff09',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22506&size=130',
  },
  {
    id: 'ae95c928f56d1c33457c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22507&size=130',
  },
  {
    id: '345154ec68a981f7d8b8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22508&size=130',
  },
  {
    id: '7fa71e1a225fcb01924e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22509&size=130',
  },
  {
    id: 'ad37cf8af3cf1a9143de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22510&size=130',
  },
  {
    id: '601103ac3fe9d6b78ff8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22511&size=130',
  },
  {
    id: 'd858a4e598a071fe28b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22512&size=130',
  },
  {
    id: '198e64335876b128e867',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22513&size=130',
  },
  {
    id: '46f838450400ed5eb411',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22514&size=130',
  },
  {
    id: 'acadd310ef55060b5f44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22515&size=130',
  },
  {
    id: '6fd7176a2b2fc2719b3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22516&size=130',
  },
  {
    id: 'd9cba0769c33756d2c22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22517&size=130',
  },
  {
    id: '71ca0b773732de6c8723',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22518&size=130',
  },
  {
    id: 'e1a59a18a65d4f03164c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22519&size=130',
  },
  {
    id: '1a6b6ed65293bbcde282',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22520&size=130',
  },
  {
    id: '87b0f20dce4827167e59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22521&size=130',
  },
  {
    id: '23ab55166953800dd942',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22522&size=130',
  },
  {
    id: '158e62335e76b728ee67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22523&size=130',
  },
  {
    id: '0e727ecf428aabd4f29b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22524&size=130',
  },
  {
    id: 'e5a0941da85841061849',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22525&size=130',
  },
  {
    id: 'c500b7bd8bf862a63be9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22526&size=130',
  },
  {
    id: '18726bcf578abed4e79b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22527&size=130',
  },
  {
    id: '9c02afbf93fa7aa423eb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22463&size=130',
  },
  {
    id: 'ada4e119dd5c34026d4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22464&size=130',
  },
  {
    id: 'ec68a1d59d9074ce2d81',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22465&size=130',
  },
  {
    id: 'bc3cf281cec4279a7ed5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22466&size=130',
  },
  {
    id: '8d45c2f8febd17e34eac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22467&size=130',
  },
  {
    id: 'ee33a68e9acb73952ada',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22468&size=130',
  },
  {
    id: '47620edf329adbc4828b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22469&size=130',
  },
  {
    id: 'b463fedec29b2bc5728a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22470&size=130',
  },
  {
    id: 'edd7a66a9a2f73712a3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22471&size=130',
  },
  {
    id: 'e73da3809fc5769b2fd4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22472&size=130',
  },
  {
    id: 'ff6fbad286976fc93686',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22473&size=130',
  },
  {
    id: '3420729d4ed8a786fec9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22474&size=130',
  },
  {
    id: '2133668e5acbb395eada',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22475&size=130',
  },
  {
    id: '436e03d33f96d6c88f87',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22476&size=130',
  },
  {
    id: '8199c024fc61153f4c70',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22477&size=130',
  },
  {
    id: '8373c1cefd8b14d54d9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22478&size=130',
  },
  {
    id: '6aea29571512fc4ca503',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22479&size=130',
  },
  {
    id: '9f19c3a4ffe116bf4ff0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22480&size=130',
  },
  {
    id: '73b12e0c1249fb17a258',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22481&size=130',
  },
  {
    id: '4ea910142c51c50f9c40',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22482&size=130',
  },
  {
    id: '26851a39267ccf22966d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22192&size=130',
  },
  {
    id: 'ed9ad026ec63053d5c72',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22193&size=130',
  },
  {
    id: '83a7bd1b815e6800314f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22194&size=130',
  },
  {
    id: 'dc85e339df7c36226f6d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22195&size=130',
  },
  {
    id: 'ca4ff2f3ceb627e87ea7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22196&size=130',
  },
  {
    id: 'd3c1ea7dd6383f666629',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22197&size=130',
  },
  {
    id: 'a7c49d78a13d4863112c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22198&size=130',
  },
  {
    id: '9562aede929b7bc5228a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22199&size=130',
  },
  {
    id: '7a0a4eb672f39badc2e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22200&size=130',
  },
  {
    id: '8594b0288c6d65333c7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22201&size=130',
  },
  {
    id: '4586733a4f7fa621ff6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22202&size=130',
  },
  {
    id: '2d3e1a8226c7cf9996d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22203&size=130',
  },
  {
    id: '8238b2848ec1679f3ed0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22204&size=130',
  },
  {
    id: '3cd30d6f312ad874813b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22205&size=130',
  },
  {
    id: 'e74bd5f7e9b200ec59a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22206&size=130',
  },
  {
    id: '485f7be347a6aef8f7b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22207&size=130',
  },
  {
    id: '5ec0127c2e39c7679e28',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22208&size=130',
  },
  {
    id: '8f4fc2f3feb617e84ea7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22209&size=130',
  },
  {
    id: '21026fbe53fbbaa5e3ea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22210&size=130',
  },
  {
    id: '562f199325d6cc8895c7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22211&size=130',
  },
  {
    id: '9a2dd291eed4078a5ec5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22212&size=130',
  },
  {
    id: 'dbe0925cae1947471e08',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22213&size=130',
  },
  {
    id: '565e1ce220a7c9f990b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22214&size=130',
  },
  {
    id: '8b4dc0f1fcb415ea4ca5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22215&size=130',
  },
  {
    id: '13b7570b6b4e8210db5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22216&size=130',
  },
  {
    id: 'e16ca4d0989571cb2884',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22217&size=130',
  },
  {
    id: 'f2c8b4748831616f3820',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22218&size=130',
  },
  {
    id: '9c728cceb08b59d5009a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22172&size=130',
  },
  {
    id: 'e60bf7b7cbf222ac7be3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22173&size=130',
  },
  {
    id: 'd020c29cfed917874ec8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22174&size=130',
  },
  {
    id: 'c94adaf6e6b30fed56a2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22175&size=130',
  },
  {
    id: 'c642eafed6bb3fe566aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22176&size=130',
  },
  {
    id: 'd63dfb81c7c42e9a77d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22177&size=130',
  },
  {
    id: '62c64c7a703f9961c02e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22178&size=130',
  },
  {
    id: 'aafa8546b903505d0912',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22179&size=130',
  },
  {
    id: 'a8358089bccc55920cdd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22180&size=130',
  },
  {
    id: 'ff04d6b8eafd03a35aec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22181&size=130',
  },
  {
    id: '8784ad38917d7823216c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22182&size=130',
  },
  {
    id: '79fa52466e03875dde12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22183&size=130',
  },
  {
    id: '661842a47ee197bfcef0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22184&size=130',
  },
  {
    id: 'af1d8aa1b6e45fba06f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22185&size=130',
  },
  {
    id: '1cb53a09064cef12b65d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22186&size=130',
  },
  {
    id: '18de3f620327ea79b336',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22187&size=130',
  },
  {
    id: 'cc37ec8bd0ce399060df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22188&size=130',
  },
  {
    id: '3c651dd9219cc8c2918d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22189&size=130',
  },
  {
    id: '2fc60d7a313fd861812e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22190&size=130',
  },
  {
    id: 'edd3ce6ff22a1b74423b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22191&size=130',
  },
  {
    id: 'ada7021b3e5ed7008e4f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22051&size=130',
  },
  {
    id: '6063c8dff49a1dc4448b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22052&size=130',
  },
  {
    id: '8ca7251b195ef000a94f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22053&size=130',
  },
  {
    id: '23bb8907b5425c1c0553',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22054&size=130',
  },
  {
    id: '26f88d44b101585f0110',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22055&size=130',
  },
  {
    id: '550bf1b7cdf224ac7de3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22056&size=130',
  },
  {
    id: '4127e49bd8de318068cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22057&size=130',
  },
  {
    id: 'bc5d1ae126a4cffa96b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22058&size=130',
  },
  {
    id: '28a78f1bb35e5a00034f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22059&size=130',
  },
  {
    id: '9a753ac9068cefd2b69d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22060&size=130',
  },
  {
    id: '0ba2aa1e965b7f05264a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22061&size=130',
  },
  {
    id: 'aed70c6b302ed970803f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22062&size=130',
  },
  {
    id: '410de2b1def437aa6ee5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22063&size=130',
  },
  {
    id: '28f59449a80c4152181d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22064&size=130',
  },
  {
    id: 'e7505aec66a98ff7d6b8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22065&size=130',
  },
  {
    id: '1c4ba2f79eb277ec2ea3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22066&size=130',
  },
  {
    id: 'a2c91d752130c86e9121',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22067&size=130',
  },
  {
    id: '507fe8c3d4863dd86497',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22068&size=130',
  },
  {
    id: '2be59259ae1c47421e0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22069&size=130',
  },
  {
    id: 'bf34058839cdd09389dc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22070&size=130',
  },
  {
    id: '999d22211e64f73aae75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22071&size=130',
  },
  {
    id: '8cf7384b040eed50b41f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22072&size=130',
  },
  {
    id: 'd3df66635a26b378ea37',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22073&size=130',
  },
  {
    id: '240892b4aef147af1ee0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22074&size=130',
  },
  {
    id: '6568d2d4ee9107cf5e80',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=22075&size=130',
  },
  {
    id: '0cbbe604da41331f6a50',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21862&size=130',
  },
  {
    id: '4f46a4f998bc71e228ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21863&size=130',
  },
  {
    id: '392bdd94e1d1088f51c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21864&size=130',
  },
  {
    id: 'ca152faa13effab1a3fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21865&size=130',
  },
  {
    id: '8c2b6a9456d1bf8fe6c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21866&size=130',
  },
  {
    id: '1cebfb54c7112e4f7700',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21867&size=130',
  },
  {
    id: '8c3a6c8550c0b99ee0d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21868&size=130',
  },
  {
    id: '06a5e71adb5f32016b4e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21869&size=130',
  },
  {
    id: '6d0b8fb4b3f15aaf03e0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21870&size=130',
  },
  {
    id: 'ac804f3f737a9a24c36b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21871&size=130',
  },
  {
    id: 'f9da05653920d07e8931',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21872&size=130',
  },
  {
    id: '13beee01d2443b1a6255',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21873&size=130',
  },
  {
    id: '9fab61145d51b40fed40',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21874&size=130',
  },
  {
    id: '5feda0529c1775492c06',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21875&size=130',
  },
  {
    id: '0a2ff290ced5278b7ec4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21876&size=130',
  },
  {
    id: 'faad03123f57d6098f46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21877&size=130',
  },
  {
    id: '1c39e686dac3339d6ad2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21878&size=130',
  },
  {
    id: 'd9a5221a1e5ff701ae4e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21879&size=130',
  },
  {
    id: 'dc9928261463fd3da472',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21880&size=130',
  },
  {
    id: '59e7ac58901d7943200c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21881&size=130',
  },
  {
    id: 'f43766885acdb393eadc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21790&size=130',
  },
  {
    id: '8b1718a824edcdb394fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21791&size=130',
  },
  {
    id: 'f0425cfd60b889e6d0a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21792&size=130',
  },
  {
    id: '9fe632590e1ce742be0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21793&size=130',
  },
  {
    id: '21718fceb38b5ad5039a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21794&size=130',
  },
  {
    id: 'f94356fc6ab983e7daa8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21795&size=130',
  },
  {
    id: '4bd1e36edf2b36756f3a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21796&size=130',
  },
  {
    id: '7e4dd7f2ebb702e95ba6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21797&size=130',
  },
  {
    id: '4f19e5a6d9e330bd69f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21798&size=130',
  },
  {
    id: 'cd6366dc5a99b3c7ea88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21799&size=130',
  },
  {
    id: '6c32c88df4c81d9644d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21800&size=130',
  },
  {
    id: '29638cdcb09959c70088',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21801&size=130',
  },
  {
    id: 'ea0760b85cfdb5a3ecec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21766&size=130',
  },
  {
    id: 'c9c1427e7e3b9765ce2a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21767&size=130',
  },
  {
    id: '7240f6ffcaba23e47aab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21768&size=130',
  },
  {
    id: 'b20137be0bfbe2a5bbea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21769&size=130',
  },
  {
    id: '0f5889e7b5a25cfc05b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21770&size=130',
  },
  {
    id: 'e31e64a158e4b1bae8f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21771&size=130',
  },
  {
    id: '0b048bbbb7fe5ea007ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21772&size=130',
  },
  {
    id: '07a88617ba52530c0a43',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21773&size=130',
  },
  {
    id: '7c61fedec29b2bc5728a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21774&size=130',
  },
  {
    id: 'd2d951666d23847ddd32',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21775&size=130',
  },
  {
    id: '4ddad165ed20047e5d31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21776&size=130',
  },
  {
    id: '2af9b7468b03625d3b12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21777&size=130',
  },
  {
    id: '3a40a4ff98ba71e428ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21778&size=130',
  },
  {
    id: '5148cef7f2b21bec42a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21779&size=130',
  },
  {
    id: '21e7b958851d6c43350c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21780&size=130',
  },
  {
    id: '5e11c7aefbeb12b54bfa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21781&size=130',
  },
  {
    id: 'd7e04d5f711a9844c10b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21782&size=130',
  },
  {
    id: '39b6a2099e4c77122e5d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21783&size=130',
  },
  {
    id: '3461a0de9c9b75c52c8a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21784&size=130',
  },
  {
    id: '1e8f8b30b7755e2b0764',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21785&size=130',
  },
  {
    id: 'fc806a3f567abf24e66b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21786&size=130',
  },
  {
    id: 'e8bc7f034346aa18f357',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21787&size=130',
  },
  {
    id: '299ab92585606c3e3571',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21788&size=130',
  },
  {
    id: '7128e097dcd2358c6cc3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21789&size=130',
  },
  {
    id: '950af7b4cbf122af7be0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21742&size=130',
  },
  {
    id: '792e1a9026d5cf8b96c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21743&size=130',
  },
  {
    id: 'fd1481aabdef54b10dfe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21744&size=130',
  },
  {
    id: '5f6f22d11e94f7caae85',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21745&size=130',
  },
  {
    id: '38e1465f7a1a9344ca0b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21746&size=130',
  },
  {
    id: '50342f8a13cffa91a3de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21747&size=130',
  },
  {
    id: 'cd7ab5c4898160df3990',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21748&size=130',
  },
  {
    id: '12a06b1e575bbe05e74a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21749&size=130',
  },
  {
    id: '499f33210f64e63abf75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21750&size=130',
  },
  {
    id: 'a688dd36e173082d5162',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21751&size=130',
  },
  {
    id: 'ef349b8aa7cf4e9117de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21752&size=130',
  },
  {
    id: '9ef3eb4dd7083e566719',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21753&size=130',
  },
  {
    id: '5b8a2d341171f82fa160',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21754&size=130',
  },
  {
    id: '530724b918fcf1a2a8ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21755&size=130',
  },
  {
    id: '0f157fab43eeaab0f3ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21756&size=130',
  },
  {
    id: '98cae974d5313c6f6520',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21757&size=130',
  },
  {
    id: '3231408f7cca9594ccdb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21758&size=130',
  },
  {
    id: 'aa4ad9f4e5b10cef55a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21759&size=130',
  },
  {
    id: '0ad1866eba2b53750a3a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21760&size=130',
  },
  {
    id: 'ce0a43b57ff096aecfe1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21761&size=130',
  },
  {
    id: '0c0e82b1bef457aa0ee5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21762&size=130',
  },
  {
    id: 'd4c25b7d67388e66d729',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21763&size=130',
  },
  {
    id: 'ecb0640f584ab114e85b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21764&size=130',
  },
  {
    id: 'f05f79e045a5acfbf5b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21765&size=130',
  },
  {
    id: '9df79049ac0c45521c1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21633&size=130',
  },
  {
    id: 'ba7fb4c1888461da3895',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21634&size=130',
  },
  {
    id: 'd641d9ffe5ba0ce455ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21635&size=130',
  },
  {
    id: '9e5b96e5aaa043fe1ab1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21636&size=130',
  },
  {
    id: '61a968175452bd0ce443',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21637&size=130',
  },
  {
    id: '84668ed8b29d5bc3028c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21638&size=130',
  },
  {
    id: '634b68f554b0bdeee4a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21639&size=130',
  },
  {
    id: '927c96c2aa8743d91a96',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21640&size=130',
  },
  {
    id: '850480babcff55a10cee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21641&size=130',
  },
  {
    id: '23d4256a192ff071a93e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21642&size=130',
  },
  {
    id: '0c4a0bf437b1deef87a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21643&size=130',
  },
  {
    id: '15f0154e290bc055991a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21644&size=130',
  },
  {
    id: '8bd28a6cb6295f770638',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21645&size=130',
  },
  {
    id: '79617bdf479aaec4f78b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21646&size=130',
  },
  {
    id: '5e665dd8619d88c3d18c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21647&size=130',
  },
  {
    id: '1f038fbdb3f85aa603e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21532&size=130',
  },
  {
    id: 'ad743cca008fe9d1b09e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21533&size=130',
  },
  {
    id: 'e1e4735a4f1fa641ff0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21534&size=130',
  },
  {
    id: '0d579ee9a2ac4bf212bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21535&size=130',
  },
  {
    id: 'f8d75469682c8172d83d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21536&size=130',
  },
  {
    id: '28d4856ab92f5071093e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21537&size=130',
  },
  {
    id: '1773b9cd85886cd63599',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21538&size=130',
  },
  {
    id: 'd924769a4adfa381face',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21539&size=130',
  },
  {
    id: 'f4505cee60ab89f5d0ba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21540&size=130',
  },
  {
    id: 'f3c45a7a663f8f61d62e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21541&size=130',
  },
  {
    id: '568ffc31c074292a7065',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21542&size=130',
  },
  {
    id: 'b2ef19512514cc4a9505',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21543&size=130',
  },
  {
    id: 'd30a77b44bf1a2affbe0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21544&size=130',
  },
  {
    id: 'e4ac41127d579409cd46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21545&size=130',
  },
  {
    id: '6c2eca90f6d51f8b46c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21546&size=130',
  },
  {
    id: 'c93a6e8452c1bb9fe2d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21547&size=130',
  },
  {
    id: '0473a4cd988871d62899',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21548&size=130',
  },
  {
    id: 'e26443da7f9f96c1cf8e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21549&size=130',
  },
  {
    id: 'ecb94e0772429b1cc253',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21550&size=130',
  },
  {
    id: '9e8d1a332676cf289667',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21512&size=130',
  },
  {
    id: '4327c699fadc13824acd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21513&size=130',
  },
  {
    id: 'a15427ea1baff2f1abbe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21514&size=130',
  },
  {
    id: '3ea7b919855c6c02354d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21515&size=130',
  },
  {
    id: '86c1067f3a3ad3648a2b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21516&size=130',
  },
  {
    id: '36bdb7038b4662183b57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21517&size=130',
  },
  {
    id: 'f1eb73554f10a64eff01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21518&size=130',
  },
  {
    id: 'd4e5575b6b1e8240db0f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21519&size=130',
  },
  {
    id: '77ebeb55d7103e4e6701',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21520&size=130',
  },
  {
    id: '3d8fa0319c74752a2c65',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21521&size=130',
  },
  {
    id: '4df0d34eef0b06555f1a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21522&size=130',
  },
  {
    id: '7858e7e6dba332fd6bb2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21523&size=130',
  },
  {
    id: 'd6574ee972ac9bf2c2bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21524&size=130',
  },
  {
    id: 'ce7f57c16b8482dadb95',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21525&size=130',
  },
  {
    id: 'c7725dcc618988d7d198',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21526&size=130',
  },
  {
    id: 'e5397e8742c2ab9cf2d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21527&size=130',
  },
  {
    id: 'a422309c0cd9e587bcc8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21528&size=130',
  },
  {
    id: '22c6b7788b3d62633b2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21529&size=130',
  },
  {
    id: '3f86a938957d7c23256c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21530&size=130',
  },
  {
    id: 'd7b740097c4c9512cc5d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21531&size=130',
  },
  {
    id: 'c61abda381e668b831f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21495&size=130',
  },
  {
    id: 'c4a0b0198c5c65023c4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21496&size=130',
  },
  {
    id: 'eb7d9ec4a2814bdf1290',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21497&size=130',
  },
  {
    id: '248a52336e768728de67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21498&size=130',
  },
  {
    id: '20ba57036b468218db57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21499&size=130',
  },
  {
    id: '161d66a45ae1b3bfeaf0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21500&size=130',
  },
  {
    id: '093c788544c0ad9ef4d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21501&size=130',
  },
  {
    id: 'c203b0ba8cff65a13cee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21502&size=130',
  },
  {
    id: 'b67ec5c7f98210dc4993',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21503&size=130',
  },
  {
    id: '449ac824f4611d3f4470',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21504&size=130',
  },
  {
    id: 'bbf5364b0a0ee350ba1f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21505&size=130',
  },
  {
    id: 'db95552b696e8030d97f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21506&size=130',
  },
  {
    id: 'f88677384b7da223fb6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21507&size=130',
  },
  {
    id: 'e4e16c5f501ab944e00b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21508&size=130',
  },
  {
    id: '2941a0ff9cba75e42cab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21509&size=130',
  },
  {
    id: '2c04a6ba9aff73a12aee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21510&size=130',
  },
  {
    id: 'cad641687d2d9473cd3c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21511&size=130',
  },
  {
    id: '739020291c6cf532ac7d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21471&size=130',
  },
  {
    id: '7ee6125f2e1ac7449e0b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21472&size=130',
  },
  {
    id: 'd8e6b55f891a6044390b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21473&size=130',
  },
  {
    id: 'd382bd3b817e6820316f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21474&size=130',
  },
  {
    id: '7e4511fc2db9c4e79da8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21475&size=130',
  },
  {
    id: '99d5f16ccd2924777d38',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21476&size=130',
  },
  {
    id: '28b6410f7d4a9414cd5b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21477&size=130',
  },
  {
    id: '021668af54eabdb4e4fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21478&size=130',
  },
  {
    id: '117e7ac74682afdcf693',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21479&size=130',
  },
  {
    id: 'a521c198fddd14834dcc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21480&size=130',
  },
  {
    id: '2fb94a0076459f1bc654',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21481&size=130',
  },
  {
    id: '03fb65425907b059e916',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21482&size=130',
  },
  {
    id: '1d1a7aa346e6afb8f6f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21483&size=130',
  },
  {
    id: 'a826c89ff4da1d8444cb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21484&size=130',
  },
  {
    id: '507731ce0d8be4d5bd9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21485&size=130',
  },
  {
    id: '0b1969a055e5bcbbe5f4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21486&size=130',
  },
  {
    id: '6bb5080c3449dd178458',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21487&size=130',
  },
  {
    id: 'b967c5def99b10c5498a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21488&size=130',
  },
  {
    id: '11cc6c755030b96ee021',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21489&size=130',
  },
  {
    id: 'f80886b1baf453aa0ae5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21490&size=130',
  },
  {
    id: 'e90596bcaaf943a71ae8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21491&size=130',
  },
  {
    id: '443e3c8700c2e99cb0d3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21492&size=130',
  },
  {
    id: 'e88c9135ad70442e1d61',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21493&size=130',
  },
  {
    id: 'a0bcda05e6400f1e5651',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21494&size=130',
  },
  {
    id: '1a645ddd619888c6d189',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21451&size=130',
  },
  {
    id: '6f6b2fd21397fac9a386',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21452&size=130',
  },
  {
    id: '93f3d24aee0f07515e1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21453&size=130',
  },
  {
    id: '910cd3b5eff006ae5fe1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21454&size=130',
  },
  {
    id: 'dcb09f09a34c4a12135d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21455&size=130',
  },
  {
    id: '3ef862415e04b75aee15',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21456&size=130',
  },
  {
    id: '8a58d7e1eba402fa5bb5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21457&size=130',
  },
  {
    id: 'd91087a9bbec52b20bfd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21458&size=130',
  },
  {
    id: '343d6b8457c1be9fe7d0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21459&size=130',
  },
  {
    id: '9da1c518f95d1003494c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21460&size=130',
  },
  {
    id: '8ecad773eb3602685b27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21461&size=130',
  },
  {
    id: 'd2fa8843b4065d580417',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21462&size=130',
  },
  {
    id: '829cd925e5600c3e5571',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21463&size=130',
  },
  {
    id: '32c3667a5a3fb361ea2e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21464&size=130',
  },
  {
    id: '6cfa39430506ec58b517',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21465&size=130',
  },
  {
    id: '1b684dd1719498cac185',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21466&size=130',
  },
  {
    id: 'b85fefe6d3a33afd63b2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21467&size=130',
  },
  {
    id: 'd2c88271be34576a0e25',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21468&size=130',
  },
  {
    id: 'ef4abef382b66be832a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21469&size=130',
  },
  {
    id: '232971904dd5a48bfdc4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21470&size=130',
  },
  {
    id: '09e70a5e361bdf45860a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21391&size=130',
  },
  {
    id: 'd1b1cd08f14d1813415c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21392&size=130',
  },
  {
    id: '0cd4116d2d28c4769d39',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21393&size=130',
  },
  {
    id: 'c552dbebe7ae0ef057bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21394&size=130',
  },
  {
    id: 'f4b8eb01d7443e1a6755',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21395&size=130',
  },
  {
    id: '9dd88561b924507a0935',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21396&size=130',
  },
  {
    id: '9be18258be1d57430e0c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21397&size=130',
  },
  {
    id: '9b908129bd6c54320d7d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21398&size=130',
  },
  {
    id: '988d8334bf71562f0f60',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21399&size=130',
  },
  {
    id: 'b2caa6739a3673682a27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21400&size=130',
  },
  {
    id: '0c6119d8259dccc3958c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21401&size=130',
  },
  {
    id: 'e359f5e0c9a520fb79b4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21402&size=130',
  },
  {
    id: 'f083e73adb7f32216b6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21403&size=130',
  },
  {
    id: 'a70bb7b28bf762a93be6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21404&size=130',
  },
  {
    id: '505e41e77da294fccdb3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21405&size=130',
  },
  {
    id: 'e485f63cca7923277a68',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21406&size=130',
  },
  {
    id: 'f72fe496d8d3318d68c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21407&size=130',
  },
  {
    id: 'b1d19d68a12d4873113c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21408&size=130',
  },
  {
    id: '6ee043597f1c9642cf0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21409&size=130',
  },
  {
    id: '784856f16ab483eadaa5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21410&size=130',
  },
  {
    id: 'e77a3fc30386ead8b397',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21332&size=130',
  },
  {
    id: '486491ddad9844c61d89',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21333&size=130',
  },
  {
    id: '0a48d0f1ecb405ea5ca5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21334&size=130',
  },
  {
    id: 'bae861515d14b44aed05',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21335&size=130',
  },
  {
    id: 'ad96792f456aac34f57b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21336&size=130',
  },
  {
    id: '0c6fd9d6e5930ccd5582',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21337&size=130',
  },
  {
    id: 'ec9e3a270662ef3cb673',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21338&size=130',
  },
  {
    id: 'b9bf6e065243bb1de252',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21339&size=130',
  },
  {
    id: 'c7b2170b2b4ec2109b5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21340&size=130',
  },
  {
    id: '4b569aefa6aa4ff416bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21341&size=130',
  },
  {
    id: 'cd171fae23ebcab593fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21342&size=130',
  },
  {
    id: '79a3aa1a965f7f01264e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21343&size=130',
  },
  {
    id: '8b7c67c55b80b2deeb91',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21344&size=130',
  },
  {
    id: '0817e5aed9eb30b569fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21345&size=130',
  },
  {
    id: 'd89136280a6de333ba7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21346&size=130',
  },
  {
    id: '5d3ab2838ec667983ed7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21347&size=130',
  },
  {
    id: 'dccf34760833e16db822',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21348&size=130',
  },
  {
    id: '345adde3e1a608f851b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21349&size=130',
  },
  {
    id: 'f93f13862fc3c69d9fd2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21350&size=130',
  },
  {
    id: '0677edced18b38d5619a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21351&size=130',
  },
  {
    id: 'eda4761d4a58a306fa49',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21271&size=130',
  },
  {
    id: 'a8223c9b00dee980b0cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21272&size=130',
  },
  {
    id: 'b06325da199ff0c1a98e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21273&size=130',
  },
  {
    id: '8d991b202765ce3b9774',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21274&size=130',
  },
  {
    id: '6c89fb30c7752e2b7764',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21275&size=130',
  },
  {
    id: '8b8e1b372772ce2c9763',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21276&size=130',
  },
  {
    id: '8cb71d0e214bc815915a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21277&size=130',
  },
  {
    id: '4db6df0fe34a0a14535b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21278&size=130',
  },
  {
    id: '7ed3ed6ad12f3871613e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21279&size=130',
  },
  {
    id: '261a8aa3b6e65fb806f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21280&size=130',
  },
  {
    id: 'f44059f965bc8ce2d5ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21281&size=130',
  },
  {
    id: '95bf3b060743ee1db752',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21282&size=130',
  },
  {
    id: '1a84b53d897860263969',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21283&size=130',
  },
  {
    id: '25e58d5cb11958470108',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21284&size=130',
  },
  {
    id: '104cb9f585b06cee35a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21285&size=130',
  },
  {
    id: 'f2ee585764128d4cd403',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21286&size=130',
  },
  {
    id: 'a3bd28041441fd1fa450',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21255&size=130',
  },
  {
    id: 'f91f7da641e3a8bdf1f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21256&size=130',
  },
  {
    id: '7bccfe75c2302b6e7221',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21257&size=130',
  },
  {
    id: 'f4c972704e35a76bfe24',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21258&size=130',
  },
  {
    id: '028f8536b973502d0962',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21259&size=130',
  },
  {
    id: '3b3cbb8587c06e9e37d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21260&size=130',
  },
  {
    id: '311bb0a28ce765b93cf6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21261&size=130',
  },
  {
    id: '2a30a88994cc7d9224dd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21262&size=130',
  },
  {
    id: 'eb0b68b254f7bda9e4e6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21263&size=130',
  },
  {
    id: 'a585393c0579ec27b568',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21264&size=130',
  },
  {
    id: '9cf001493d0cd4528d1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21265&size=130',
  },
  {
    id: '51fccf45f3001a5e4311',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21266&size=130',
  },
  {
    id: '44bfdb06e7430e1d5752',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21267&size=130',
  },
  {
    id: '814b19f225b7cce995a6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21268&size=130',
  },
  {
    id: '5837c18efdcb14954dda',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21269&size=130',
  },
  {
    id: '8984133d2f78c6269f69',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21270&size=130',
  },
  {
    id: 'f8299b91a7d44e8a17c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21231&size=130',
  },
  {
    id: '79ba05023947d0198956',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21232&size=130',
  },
  {
    id: '00fa7d424107a859f116',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21233&size=130',
  },
  {
    id: 'db90a528996d7033297c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21234&size=130',
  },
  {
    id: '099a76224a67a339fa76',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21235&size=130',
  },
  {
    id: '5efd26451a00f35eaa11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21236&size=130',
  },
  {
    id: '96bcef04d3413a1f6350',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21237&size=130',
  },
  {
    id: '10d66a6e562bbf75e63a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21238&size=130',
  },
  {
    id: 'd449aff193b47aea23a5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21239&size=130',
  },
  {
    id: '8b94ff2cc3692a377378',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21240&size=130',
  },
  {
    id: '753f00873cc2d59c8cd3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21241&size=130',
  },
  {
    id: 'e1ab9713ab5642081b47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21242&size=130',
  },
  {
    id: '175960e15ca4b5faecb5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21243&size=130',
  },
  {
    id: '2e415ef962bc8be2d2ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21244&size=130',
  },
  {
    id: '1bdd6a655620bf7ee631',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21245&size=130',
  },
  {
    id: '229d50256c60853edc71',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21246&size=130',
  },
  {
    id: 'd521a6999adc73822acd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21247&size=130',
  },
  {
    id: '76b6fa0fc64a2f14765b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21248&size=130',
  },
  {
    id: '19a89411a854410a1845',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21249&size=130',
  },
  {
    id: '96c918702435cd6b9424',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21250&size=130',
  },
  {
    id: 'a693292a156ffc31a57e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21251&size=130',
  },
  {
    id: 'aded25541911f04fa900',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21252&size=130',
  },
  {
    id: '06e78f5eb31b5a45030a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21253&size=130',
  },
  {
    id: '4304c9bdf5f81ca645e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21254&size=130',
  },
  {
    id: 'a471bbc9878c6ed2379d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21139&size=130',
  },
  {
    id: 'fb9be323df6636386f77',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21140&size=130',
  },
  {
    id: 'ea2ff397cfd2268c7fc3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21141&size=130',
  },
  {
    id: '30882a301675ff2ba664',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21142&size=130',
  },
  {
    id: '768f6d375172b82ce163',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21143&size=130',
  },
  {
    id: '6d0979b145f4acaaf5e5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21144&size=130',
  },
  {
    id: 'a2abb7138b5662083b47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21145&size=130',
  },
  {
    id: 'd47ec2c6fe8317dd4e92',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21146&size=130',
  },
  {
    id: 'dce7cb5ff71a1e44470b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21147&size=130',
  },
  {
    id: 'cf57dfefe3aa0af453bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21148&size=130',
  },
  {
    id: 'af5fbee782a26bfc32b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21149&size=130',
  },
  {
    id: '719a63225f67b639ef76',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21150&size=130',
  },
  {
    id: 'e165f2ddce9827c67e89',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21151&size=130',
  },
  {
    id: '92eabe5282176b493206',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21152&size=130',
  },
  {
    id: 'a9fc8444b801515f0810',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21153&size=130',
  },
  {
    id: '6cf142497e0c9752ce1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21154&size=130',
  },
  {
    id: '84bbab0397467e182757',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21155&size=130',
  },
  {
    id: 'f047d8ffe4ba0de454ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21156&size=130',
  },
  {
    id: 'b74b9ef3a2b64be812a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21157&size=130',
  },
  {
    id: '71a55b1d67588e06d749',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21158&size=130',
  },
  {
    id: 'd94b88f0b4b55deb04a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20957&size=130',
  },
  {
    id: 'd65484efb8aa51f408bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20958&size=130',
  },
  {
    id: 'd66b85d0b99550cb0984',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20959&size=130',
  },
  {
    id: '31a55d1e615b8805d14a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20960&size=130',
  },
  {
    id: '490824b318f6f1a8a8e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20961&size=130',
  },
  {
    id: '906cfed7c2922bcc7283',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20962&size=130',
  },
  {
    id: 'cf37a08c9cc975972cd8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20963&size=130',
  },
  {
    id: '1ea976124a57a309fa46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20964&size=130',
  },
  {
    id: '8c4ee5f5d9b030ee69a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20965&size=130',
  },
  {
    id: '64d40e6f322adb74823b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20966&size=130',
  },
  {
    id: 'ed0886b3baf653a80ae7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20967&size=130',
  },
  {
    id: '9d0cf9b7c5f22cac75e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20968&size=130',
  },
  {
    id: '1d7778cc4489add7f498',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20969&size=130',
  },
  {
    id: 'f03c9687aac2439c1ad3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20970&size=130',
  },
  {
    id: '655b02e03ea5d7fb8eb4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20971&size=130',
  },
  {
    id: '019b61205d65b43bed74',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20972&size=130',
  },
  {
    id: 'c439a58299c7709929d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20973&size=130',
  },
  {
    id: '5c403efb02beebe0b2af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20974&size=130',
  },
  {
    id: '81c2e279de3c37626e2d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20975&size=130',
  },
  {
    id: '60ad1c162053c90d9042',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20976&size=130',
  },
  {
    id: '55d5286e142bfd75a43a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20977&size=130',
  },
  {
    id: '49e237590b1ce242bb0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20978&size=130',
  },
  {
    id: '49df36640a21e37fba30',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20979&size=130',
  },
  {
    id: '2985513e6d7b8425dd6a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20980&size=130',
  },
  {
    id: 'f4a3a919955c7c02254d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20689&size=130',
  },
  {
    id: '2ba1751b495ea000f94f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20690&size=130',
  },
  {
    id: '752f2a9516d0ff8ea6c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20691&size=130',
  },
  {
    id: 'ec6bb4d1889461ca3885',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20692&size=130',
  },
  {
    id: 'd5498cf3b0b659e800a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20693&size=130',
  },
  {
    id: 'a651fcebc0ae29f070bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20694&size=130',
  },
  {
    id: '6bed30570c12e54cbc03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20695&size=130',
  },
  {
    id: '939ac720fb65123b4b74',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20696&size=130',
  },
  {
    id: 'e971bccb808e69d0309f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20697&size=130',
  },
  {
    id: 'cc6e9ad4a6914fcf1680',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20698&size=130',
  },
  {
    id: 'ec09bbb387f66ea837e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20699&size=130',
  },
  {
    id: 'c9df9965a5204c7e1531',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20700&size=130',
  },
  {
    id: '5930088a34cfdd9184de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20701&size=130',
  },
  {
    id: '9cc9ce73f2361b684227',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20702&size=130',
  },
  {
    id: '4bf6184c2409cd579418',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20703&size=130',
  },
  {
    id: '8593e929d56c3c32657d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20704&size=130',
  },
  {
    id: '707c1dc62183c8dd9192',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20705&size=130',
  },
  {
    id: '12477cfd40b8a9e6f0a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20706&size=130',
  },
  {
    id: '9c97f32dcf6826367f79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20707&size=130',
  },
  {
    id: '6cd5046f382ad174883b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20708&size=130',
  },
  {
    id: 'c8e4a15e9d1b74452d0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20709&size=130',
  },
  {
    id: 'bc86533c6f798627df68',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20579&size=130',
  },
  {
    id: 'ce0226b81afdf3a3aaec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20580&size=130',
  },
  {
    id: '5cfbb5418904605a3915',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20581&size=130',
  },
  {
    id: '61958b2fb76a5e34077b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20582&size=130',
  },
  {
    id: '2135ca8ff6ca1f9446db',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20583&size=130',
  },
  {
    id: '77f6934caf0946571f18',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20584&size=130',
  },
  {
    id: '1792f228ce6d27337e7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20585&size=130',
  },
  {
    id: '2ec4c87ef43b1d65442a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20586&size=130',
  },
  {
    id: 'f2f1154b290ec050991f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20587&size=130',
  },
  {
    id: '9a597ae346a6aff8f6b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20588&size=130',
  },
  {
    id: 'f17410ce2c8bc5d59c9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20589&size=130',
  },
  {
    id: 'fa2f189524d0cd8e94c1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20590&size=130',
  },
  {
    id: '46eba5519914704a2905',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20591&size=130',
  },
  {
    id: '841f78a544e0adbef4f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20592&size=130',
  },
  {
    id: 'fbc4067e3a3bd3658a2a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20593&size=130',
  },
  {
    id: '51e4af5e931b7a45230a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20594&size=130',
  },
  {
    id: '24f9db43e7060e585717',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20595&size=130',
  },
  {
    id: '6f6497deab9b42c51b8a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20596&size=130',
  },
  {
    id: '8aff73454f00a65eff11',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20597&size=130',
  },
  {
    id: '4917b3ad8fe866b63ff9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20598&size=130',
  },
  {
    id: '194fedebd1ae38f061bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20088&size=130',
  },
  {
    id: '059ef03acc7f25217c6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20089&size=130',
  },
  {
    id: '8c657ac14684afdaf695',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20090&size=130',
  },
  {
    id: '4057b7f38bb662e83ba7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20091&size=130',
  },
  {
    id: '3a14cab0f6f51fab46e4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20092&size=130',
  },
  {
    id: '2038d19cedd904875dc8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20093&size=130',
  },
  {
    id: 'f85a0afe36bbdfe586aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20094&size=130',
  },
  {
    id: '3636c592f9d7108949c6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20095&size=130',
  },
  {
    id: '24fc2858141dfd43a40c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20096&size=130',
  },
  {
    id: '388b352f096ae034b97b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20097&size=130',
  },
  {
    id: 'bb26b58289c7609939d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20098&size=130',
  },
  {
    id: '504d5fe963ac8af2d3bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20099&size=130',
  },
  {
    id: 'fc0bf4afc8ea21b478fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20100&size=130',
  },
  {
    id: 'e5a235060943e01db952',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20060&size=130',
  },
  {
    id: '4ee19f45a3004a5e1311',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20061&size=130',
  },
  {
    id: 'c0ed12492e0cc7529e1d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20062&size=130',
  },
  {
    id: 'eb3c389804dded83b4cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20063&size=130',
  },
  {
    id: 'e1540df031b5d8eb81a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20064&size=130',
  },
  {
    id: 'bf4052e46ea187ffdeb0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20065&size=130',
  },
  {
    id: '037feddbd19e38c0618f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20066&size=130',
  },
  {
    id: 'd38e3c2a006fe931b07e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20067&size=130',
  },
  {
    id: 'ea1102b53ef0d7ae8ee1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20068&size=130',
  },
  {
    id: '0606efa2d3e73ab963f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20069&size=130',
  },
  {
    id: 'caaf200b1c4ef510ac5f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20070&size=130',
  },
  {
    id: '64598ffdb3b85ae603a9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20071&size=130',
  },
  {
    id: '14a5f001cc44251a7c55',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20072&size=130',
  },
  {
    id: 'b5e9504d6c088556dc19',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20073&size=130',
  },
  {
    id: 'e4c102653e20d77e8e31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20074&size=130',
  },
  {
    id: '4f63a8c794827ddc2493',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20075&size=130',
  },
  {
    id: 'b71f57bb6bfe82a0dbef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20076&size=130',
  },
  {
    id: '868167255b60b23eeb71',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20077&size=130',
  },
  {
    id: 'd7d635720937e069b926',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20078&size=130',
  },
  {
    id: 'e8140bb037f5deab87e4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20079&size=130',
  },
  {
    id: '9fc363675f22b67cef33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20080&size=130',
  },
  {
    id: 'd44529e115a4fcfaa5b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20081&size=130',
  },
  {
    id: '796687c2bb8752d90b96',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20082&size=130',
  },
  {
    id: 'eb33149728d2c18c98c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20083&size=130',
  },
  {
    id: '7e3b869fbada53840acb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20084&size=130',
  },
  {
    id: '1a29e38ddfc836966fd9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20085&size=130',
  },
  {
    id: '90db6a7f563abf64e62b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20086&size=130',
  },
  {
    id: '6ec9956da92840761939',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=20087&size=130',
  },
  {
    id: 'b7df9378af3d46631f2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19880&size=130',
  },
  {
    id: 'ad1d88bab4ff5da104ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19881&size=130',
  },
  {
    id: '2a3b0c9c30d9d98780c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19882&size=130',
  },
  {
    id: '599c7e3b427eab20f26f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19883&size=130',
  },
  {
    id: 'b15991feadbb44e51daa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19884&size=130',
  },
  {
    id: 'e489c52ef96b1035497a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19885&size=130',
  },
  {
    id: '6c574ef072b59bebc2a4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19886&size=130',
  },
  {
    id: 'bdc89e6fa22a4b74123b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19887&size=130',
  },
  {
    id: '53a16f065343ba1de352',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19888&size=130',
  },
  {
    id: '52ed6f4a530fba51e31e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19889&size=130',
  },
  {
    id: '65785bdf679a8ec4d78b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19890&size=130',
  },
  {
    id: '7ba644017844911ac855',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19891&size=130',
  },
  {
    id: '3b4103e63fa3d6fd8fb2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19892&size=130',
  },
  {
    id: '5da564025847b119e856',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19893&size=130',
  },
  {
    id: '256d28ca148ffdd1a49e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19841&size=130',
  },
  {
    id: '69a9670e5b4bb215eb5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19842&size=130',
  },
  {
    id: '5b27548068c5819bd8d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19843&size=130',
  },
  {
    id: '75a27d054140a81ef151',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19844&size=130',
  },
  {
    id: '5e4157e66ba382fddbb2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19845&size=130',
  },
  {
    id: '20312a9616d3ff8da6c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19846&size=130',
  },
  {
    id: 'd86bd3ccef8906d75f98',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19847&size=130',
  },
  {
    id: '1a521ef522b0cbee92a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19848&size=130',
  },
  {
    id: 'd14ad4ede8a801f658b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19849&size=130',
  },
  {
    id: 'b575b3d28f9766c93f86',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19850&size=130',
  },
  {
    id: 'bd44bae386a66ff836b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19851&size=130',
  },
  {
    id: '204320e41ca1f5ffacb0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19852&size=130',
  },
  {
    id: 'd315d2b2eef707a95ee6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19853&size=130',
  },
  {
    id: 'c86fcac8f68d1fd3469c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19854&size=130',
  },
  {
    id: '87c8846fb82a5174083b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19855&size=130',
  },
  {
    id: '1aa8060f3a4ad3148a5b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19856&size=130',
  },
  {
    id: 'd4d3c974f5311c6f4520',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19857&size=130',
  },
  {
    id: '4efa505d6c188546dc09',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19858&size=130',
  },
  {
    id: 'd43acb9df7d81e8647c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19859&size=130',
  },
  {
    id: '50b3481474519d0fc440',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19860&size=130',
  },
  {
    id: 'bf19a6be9afb73a52aea',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19861&size=130',
  },
  {
    id: 'a8fdb25a8e1f67413e0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19862&size=130',
  },
  {
    id: '728e6929556cbc32e57d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19863&size=130',
  },
  {
    id: 'eef8fa5fc61a2f44760b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19864&size=130',
  },
  {
    id: 'b064a5c3998670d82997',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19865&size=130',
  },
  {
    id: '9d2e8b89b7cc5e9207dd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19866&size=130',
  },
  {
    id: '301a27bd1bf8f2a6abe9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19867&size=130',
  },
  {
    id: '5a4d4aea76af9ff1c6be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19868&size=130',
  },
  {
    id: '53f9425e7e1b9745ce0a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19869&size=130',
  },
  {
    id: '00e8124f2e0ac7549e1b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19870&size=130',
  },
  {
    id: 'e9fe7c59401ca942f00d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19737&size=130',
  },
  {
    id: '942f02883ecdd7938edc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19738&size=130',
  },
  {
    id: '6e91f936c5732c2d7562',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19739&size=130',
  },
  {
    id: 'a0f330540c11e54fbc00',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19740&size=130',
  },
  {
    id: 'c97a58dd64988dc6d489',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19741&size=130',
  },
  {
    id: 'ecb47e134256ab08f247',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19742&size=130',
  },
  {
    id: 'b9372a9016d5ff8ba6c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19743&size=130',
  },
  {
    id: '0e8fa2289e6d77332e7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19744&size=130',
  },
  {
    id: 'd55378f444b1adeff4a0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19745&size=130',
  },
  {
    id: '2a6e84c9b88c51d2089d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19746&size=130',
  },
  {
    id: '7ebad11ded5804065d49',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19747&size=130',
  },
  {
    id: 'be7616d12a94c3ca9a85',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19748&size=130',
  },
  {
    id: '7931b0978cd2658c3cc3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19525&size=130',
  },
  {
    id: 'c7fe0d58311dd843810c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19526&size=130',
  },
  {
    id: '72c8b96e852b6c75353a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19527&size=130',
  },
  {
    id: '421e86b8bafd53a30aec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19528&size=130',
  },
  {
    id: 'd4be11182d5dc4039d4c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19529&size=130',
  },
  {
    id: '6e82a82494617d3f2470',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19530&size=130',
  },
  {
    id: '35b6f210ce55270b7e44',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19531&size=130',
  },
  {
    id: 'cb5f0bf937bcdee287ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19532&size=130',
  },
  {
    id: 'd58514232866c1389877',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19533&size=130',
  },
  {
    id: '7d1bbfbd83f86aa633e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19534&size=130',
  },
  {
    id: '417082d6be9357cd0e82',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19535&size=130',
  },
  {
    id: 'f64c2aea16affff1a6be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19536&size=130',
  },
  {
    id: '2d6ff0c9cc8c25d27c9d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19537&size=130',
  },
  {
    id: 'aced724b4e0ea750fe1f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19538&size=130',
  },
  {
    id: '1dbbc21dfe5817064e49',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19539&size=130',
  },
  {
    id: 'd18e0928356ddc33857c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19540&size=130',
  },
  {
    id: '1f3ac69cfad913874ac8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19541&size=130',
  },
  {
    id: 'f6652cc31086f9d8a097',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19542&size=130',
  },
  {
    id: '04badf1ce3590a075348',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19543&size=130',
  },
  {
    id: 'edb539130556ec08b547',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19544&size=130',
  },
  {
    id: '6123b48588c0619e38d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19545&size=130',
  },
  {
    id: '63665fc763828adcd393',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19376&size=130',
  },
  {
    id: 'f49fc93ef57b1c25456a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19377&size=130',
  },
  {
    id: 'b9b58714bb51520f0b40',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19378&size=130',
  },
  {
    id: 'b5318a90b6d55f8b06c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19379&size=130',
  },
  {
    id: '8880b0218c64653a3c75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19380&size=130',
  },
  {
    id: '07583ef902bcebe2b2ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19381&size=130',
  },
  {
    id: 'd942e3e3dfa636f86fb7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19382&size=130',
  },
  {
    id: '7b9d403c7c799527cc68',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19383&size=130',
  },
  {
    id: '8c8fb82e846b6d35347a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19384&size=130',
  },
  {
    id: '27cb126a2e2fc7719e3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19385&size=130',
  },
  {
    id: 'd0bfe61eda5b33056a4a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19386&size=130',
  },
  {
    id: '39440ee532a0dbfe82b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19387&size=130',
  },
  {
    id: '2f961f372372ca2c9363',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19388&size=130',
  },
  {
    id: 'ce40ffe1c3a42afa73b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19389&size=130',
  },
  {
    id: '66b654176852810cd843',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19390&size=130',
  },
  {
    id: '844eb7ef8baa62f43bbb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19391&size=130',
  },
  {
    id: '354c29ed15a8fcf6a5b9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19344&size=130',
  },
  {
    id: '51144cb570f099aec0e1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19345&size=130',
  },
  {
    id: '8f1991b8adfd44a31dec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19346&size=130',
  },
  {
    id: 'ce42d1e3eda604f85db7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19347&size=130',
  },
  {
    id: '9ce98448b80d5153081c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19348&size=130',
  },
  {
    id: '01ba181b245ecd00944f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19349&size=130',
  },
  {
    id: '00391a9826ddcf8396cc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19350&size=130',
  },
  {
    id: 'b537ae9692d37b8d22c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19351&size=130',
  },
  {
    id: '713d659c59d9b087e9c8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19352&size=130',
  },
  {
    id: 'f5ece04ddc0835566c19',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19353&size=130',
  },
  {
    id: '036115c02985c0db9994',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19354&size=130',
  },
  {
    id: '506d47cc7b8992d7cb98',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19355&size=130',
  },
  {
    id: 'f45ae4fbd8be31e068af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19356&size=130',
  },
  {
    id: 'ba3bab9a97df7e8127ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19357&size=130',
  },
  {
    id: '547546d47a9193cfca80',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19358&size=130',
  },
  {
    id: 'f258e1f9ddbc34e26dad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19359&size=130',
  },
  {
    id: '29db057a393fd061892e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19360&size=130',
  },
  {
    id: '01fb2c5a101ff941a00e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19361&size=130',
  },
  {
    id: '8682a82394667d382477',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19362&size=130',
  },
  {
    id: 'ac4b83eabfaf56f10fbe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19363&size=130',
  },
  {
    id: '9937b2978ed2678c3ec3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19111&size=130',
  },
  {
    id: '23c607663b23d27d8b32',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19112&size=130',
  },
  {
    id: '59157cb540f0a9aef0e1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19113&size=130',
  },
  {
    id: 'df30f990c5d52c8b75c4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19114&size=130',
  },
  {
    id: '528075204965a03bf974',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19115&size=130',
  },
  {
    id: 'ed29cd89f1cc189241dd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19116&size=130',
  },
  {
    id: 'c0c5e165dd20347e6d31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19117&size=130',
  },
  {
    id: '82c1a0619c24757a2c35',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19118&size=130',
  },
  {
    id: '534c70ec4ca9a5f7fcb8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19119&size=130',
  },
  {
    id: '8b7db7dd8b9862c63b89',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19120&size=130',
  },
  {
    id: '9ad7a7779b32726c2b23',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19121&size=130',
  },
  {
    id: '568b682b546ebd30e47f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19122&size=130',
  },
  {
    id: '62d65d766133886dd122',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19123&size=130',
  },
  {
    id: 'a9d89178ad3d44631d2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19124&size=130',
  },
  {
    id: '405f79ff45baace4f5ab',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19125&size=130',
  },
  {
    id: '8eefb44f880a6154381b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19126&size=130',
  },
  {
    id: '422e798e45cbac95f5da',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19127&size=130',
  },
  {
    id: 'f9d0cd70f135186b4124',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19128&size=130',
  },
  {
    id: '042431840dc1e49fbdd0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19129&size=130',
  },
  {
    id: '116827c81b8df2d3ab9c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19130&size=130',
  },
  {
    id: 'a2d672764e33a76dfe22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19036&size=130',
  },
  {
    id: 'e54734e708a2e1fcb8b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19037&size=130',
  },
  {
    id: '970845a879ed90b3c9fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19038&size=130',
  },
  {
    id: 'bff36c535016b948e007',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19039&size=130',
  },
  {
    id: '5957b5f789b260ec39a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19040&size=130',
  },
  {
    id: '9bb876184a5da303fa4c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19041&size=130',
  },
  {
    id: 'ea5c04fc38b9d1e788a8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19042&size=130',
  },
  {
    id: '4045afe593a07afe23b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19043&size=130',
  },
  {
    id: 'e3af0b0f374ade14875b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19044&size=130',
  },
  {
    id: 'dada337a0f3fe661bf2e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19045&size=130',
  },
  {
    id: 'ddae370e0b4be215bb5a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19046&size=130',
  },
  {
    id: 'b3c7586764228d7cd433',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19047&size=130',
  },
  {
    id: '954971e94daca4f2fdbd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19048&size=130',
  },
  {
    id: '71819421a864413a1875',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19049&size=130',
  },
  {
    id: 'd43032900ed5e78bbec4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19050&size=130',
  },
  {
    id: '4b8dac2d906879362079',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19051&size=130',
  },
  {
    id: 'dbc53b650720ee7eb731',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19052&size=130',
  },
  {
    id: 'd38832280e6de733be7c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19053&size=130',
  },
  {
    id: 'f9221b8227c7ce9997d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19054&size=130',
  },
  {
    id: 'c42327831bc6f298abd7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19055&size=130',
  },
  {
    id: '8c1870b84cfda5a3fcec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19056&size=130',
  },
  {
    id: 'e90d14ad28e8c1b698f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19057&size=130',
  },
  {
    id: '5fbfa11f9d5a74042d4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19058&size=130',
  },
  {
    id: '155aeafad6bf3fe166ae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19059&size=130',
  },
  {
    id: '707b88dbb49e5dc0048f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19060&size=130',
  },
  {
    id: 'c5e201423d07d4598d16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19016&size=130',
  },
  {
    id: 'a8e36d435106b858e117',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19017&size=130',
  },
  {
    id: 'f3d135710934e06ab925',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19018&size=130',
  },
  {
    id: '06b4c114fd51140f4d40',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19019&size=130',
  },
  {
    id: '41938133bd7654280d67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19020&size=130',
  },
  {
    id: '1cb7dd17e152080c5143',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19021&size=130',
  },
  {
    id: '278ee52ed96b3035697a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19022&size=130',
  },
  {
    id: 'cfeb0c4b300ed950801f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19023&size=130',
  },
  {
    id: '9a5546f57ab093eecaa1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19024&size=130',
  },
  {
    id: '1e01c3a1ffe416ba4ff5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19025&size=130',
  },
  {
    id: '74b4aa1496517f0f2640',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19026&size=130',
  },
  {
    id: '570788a7b4e25dbc04f3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19027&size=130',
  },
  {
    id: '45fc9d5ca11948471108',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19028&size=130',
  },
  {
    id: '6683bf2383666a383377',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19029&size=130',
  },
  {
    id: '187bc2dbfe9e17c04e8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19030&size=130',
  },
  {
    id: 'f6512df111b4f8eaa1a5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19031&size=130',
  },
  {
    id: 'eff73b570712ee4cb703',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19032&size=130',
  },
  {
    id: '1f25ca85f6c01f9e46d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19033&size=130',
  },
  {
    id: '99534ff373b69ae8c3a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19034&size=130',
  },
  {
    id: '6000b7a08be562bb3bf4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19035&size=130',
  },
  {
    id: '5579c7d9fb9c12c24b8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18974&size=130',
  },
  {
    id: '7a0ee9aed5eb3cb565fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18975&size=130',
  },
  {
    id: '27f78b57b7125e4c0703',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18976&size=130',
  },
  {
    id: 'c9e264425807b159e816',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18977&size=130',
  },
  {
    id: '269f883fb47a5d24046b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18978&size=130',
  },
  {
    id: '3df09250ae15474b1e04',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18979&size=130',
  },
  {
    id: '6519cdb9f1fc18a241ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18980&size=130',
  },
  {
    id: '1093b93385766c283567',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18981&size=130',
  },
  {
    id: '40afea0fd64a3f14665b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18982&size=130',
  },
  {
    id: '00f1ab5197147e4a2705',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18983&size=130',
  },
  {
    id: '4aa4ee04d2413b1f6250',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18984&size=130',
  },
  {
    id: '7131d491e8d4018a58c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18985&size=130',
  },
  {
    id: '20c78667ba22537c0a33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18986&size=130',
  },
  {
    id: '49a5ee05d2403b1e6251',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18987&size=130',
  },
  {
    id: '08efa84f940a7d54241b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18988&size=130',
  },
  {
    id: 'f49e553e697b8025d96a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18989&size=130',
  },
  {
    id: '34be961eaa5b43051a4a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18990&size=130',
  },
  {
    id: '74f7d757eb12024c5b03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18991&size=130',
  },
  {
    id: '378c8b2cb7695e370778',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18992&size=130',
  },
  {
    id: '00f9bd59811c6842310d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18993&size=130',
  },
  {
    id: '50a6ee06d2433b1d6252',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18994&size=130',
  },
  {
    id: '4661f9c1c5842cda7595',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18995&size=130',
  },
  {
    id: '5c7a0ad9369cdfc2868d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18906&size=130',
  },
  {
    id: 'a8d6ff75c3302a6e7321',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18907&size=130',
  },
  {
    id: 'eca5bc068043691d3052',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18908&size=130',
  },
  {
    id: '485019f325b6cce895a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18909&size=130',
  },
  {
    id: '1d564ff573b09aeec3a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18910&size=130',
  },
  {
    id: '4723148028c5c19b98d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18911&size=130',
  },
  {
    id: '85bae919d55c3c02654d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18912&size=130',
  },
  {
    id: '32385f9b63de8a80d3cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18913&size=130',
  },
  {
    id: 'dfc6b1658d20647e3d31',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18914&size=130',
  },
  {
    id: '34375b9467d18e8fd7c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18915&size=130',
  },
  {
    id: '07b96f1a535fba01e34e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18916&size=130',
  },
  {
    id: 'ce23a7809bc5729b2bd4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18917&size=130',
  },
  {
    id: '8549efead3af3af163be',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18918&size=130',
  },
  {
    id: '21184abb76fe9fa0c6ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18919&size=130',
  },
  {
    id: '0ae06e435206bb58e217',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18920&size=130',
  },
  {
    id: '09376c9450d1b98fe0c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18921&size=130',
  },
  {
    id: '611f07bc3bf9d2a78be8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18922&size=130',
  },
  {
    id: '8447e3e4dfa136ff6fb0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18923&size=130',
  },
  {
    id: '6c590cfa30bfd9e180ae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18924&size=130',
  },
  {
    id: '6cbe0d1d3158d8068149',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18925&size=130',
  },
  {
    id: '895f4afc76b99fe7c6a8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18767&size=130',
  },
  {
    id: 'a14a7de941aca8f2f1bd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18768&size=130',
  },
  {
    id: '8e2353806fc5869bdfd4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18769&size=130',
  },
  {
    id: '48ef964caa0943571a18',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18770&size=130',
  },
  {
    id: 'a1d87e7b423eab60f22f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18771&size=130',
  },
  {
    id: 'bc8264215864b13ae875',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18772&size=130',
  },
  {
    id: '5c4085e3b9a650f809b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18773&size=130',
  },
  {
    id: '60c7ba6486216f7f3630',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18774&size=130',
  },
  {
    id: '07e0dc43e00609585017',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18775&size=130',
  },
  {
    id: 'ed7739d40591eccfb580',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18776&size=130',
  },
  {
    id: '40fd955ea91b4045190a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18777&size=130',
  },
  {
    id: '33ace50fd94a3014695b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18778&size=130',
  },
  {
    id: '1a31cd92f1d7188941c6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18779&size=130',
  },
  {
    id: '6815b8b684f36dad34e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18780&size=130',
  },
  {
    id: '83fc525f6e1a8744de0b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18781&size=130',
  },
  {
    id: '834351e06da584fbddb4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18782&size=130',
  },
  {
    id: 'e44e37ed0ba8e2f6bbb9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18783&size=130',
  },
  {
    id: '0e8fe22cde6937376e78',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18784&size=130',
  },
  {
    id: 'e8b705143951d00f8940',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18785&size=130',
  },
  {
    id: '62518cf2b0b759e900a6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18786&size=130',
  },
  {
    id: 'c7485deb61ae88f0d1bf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18710&size=130',
  },
  {
    id: '0c1897bbabfe42a01bef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18711&size=130',
  },
  {
    id: '0fb29b11a7544e0a1745',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18712&size=130',
  },
  {
    id: 'a43031930dd6e488bdc7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18713&size=130',
  },
  {
    id: 'db024da171e498bac1f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18714&size=130',
  },
  {
    id: '5c95cb36f7731e2d4762',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18715&size=130',
  },
  {
    id: 'eec37e604225ab7bf234',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18716&size=130',
  },
  {
    id: '06209783abc642981bd7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18717&size=130',
  },
  {
    id: 'bb5829fb15befce0a5af',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18718&size=130',
  },
  {
    id: 'cad4597765328c6cd523',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18719&size=130',
  },
  {
    id: 'ef0b43a87fed96b3cffc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18720&size=130',
  },
  {
    id: 'bf1a12b92efcc7a29eed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18721&size=130',
  },
  {
    id: '4ad0e473d83631686827',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18722&size=130',
  },
  {
    id: '61cece6df2281b764239',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18723&size=130',
  },
  {
    id: 'd4107cb340f6a9a8f0e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18724&size=130',
  },
  {
    id: 'cdda6479583cb162e82d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18725&size=130',
  },
  {
    id: 'de39749a48dfa181f8ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18726&size=130',
  },
  {
    id: '2af08153bd1654480d07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18727&size=130',
  },
  {
    id: 'facc5e6f622a8b74d23b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18728&size=130',
  },
  {
    id: '5b01fea2c2e72bb972f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18729&size=130',
  },
  {
    id: '57903d320177e829b166',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18662&size=130',
  },
  {
    id: '7fe214402805c15b9814',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18663&size=130',
  },
  {
    id: 'e9638dc1b18458da0195',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18664&size=130',
  },
  {
    id: 'd9e4bc468003695d3012',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18665&size=130',
  },
  {
    id: '321854ba68ff81a1d8ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18666&size=130',
  },
  {
    id: 'c1fba6599a1c73422a0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18667&size=130',
  },
  {
    id: '3c045ca660e389bdd0f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18668&size=130',
  },
  {
    id: 'ddcdbc6f802a6974303b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18669&size=130',
  },
  {
    id: '488b2a29166cff32a67d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18670&size=130',
  },
  {
    id: '3cb15f1363568a08d347',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18671&size=130',
  },
  {
    id: 'e5f59957a5124c4c1503',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18672&size=130',
  },
  {
    id: '8986f424c861213f7870',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18673&size=130',
  },
  {
    id: '8621f883c4c62d9874d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18674&size=130',
  },
  {
    id: 'a842d7e0eba502fb5bb4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18675&size=130',
  },
  {
    id: '4e7636d40a91e3cfba80',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18676&size=130',
  },
  {
    id: '35f24c507015994bc004',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18677&size=130',
  },
  {
    id: '32ca4868742d9d73c43c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18678&size=130',
  },
  {
    id: '8ce2f740cb05225b7b14',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18679&size=130',
  },
  {
    id: '119a6538597db023e96c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18680&size=130',
  },
  {
    id: '9767e2c5de8037de6e91',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18681&size=130',
  },
  {
    id: '3e7464d65893b1cde882',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18646&size=130',
  },
  {
    id: 'e8b9b31b8f5e66003f4f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18647&size=130',
  },
  {
    id: '12e646447a01935fca10',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18648&size=130',
  },
  {
    id: 'fb23ae8192c47b9a22d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18649&size=130',
  },
  {
    id: '13b9451b795e9000c94f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18650&size=130',
  },
  {
    id: 'df1a88b8b4fd5da304ec',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18651&size=130',
  },
  {
    id: '4dab1d09214cc812915d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18652&size=130',
  },
  {
    id: '1da94c0b704e9910c05f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18653&size=130',
  },
  {
    id: '9714c5b6f9f310ad49e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18654&size=130',
  },
  {
    id: 'e027b3858fc0669e3fd1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18655&size=130',
  },
  {
    id: 'ce5ea2fc9eb977e72ea8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18656&size=130',
  },
  {
    id: '2e6e43cc7f8996d7cf98',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18657&size=130',
  },
  {
    id: 'd19fbf3d83786a263369',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18658&size=130',
  },
  {
    id: 'd5dbba79863c6f62362d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18659&size=130',
  },
  {
    id: '20b8481a745f9d01c44e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18660&size=130',
  },
  {
    id: '70ab1909254ccc12955d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18661&size=130',
  },
  {
    id: '8722a7809bc5729b2bd4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18604&size=130',
  },
  {
    id: '8ba7aa0596407f1e2651',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18605&size=130',
  },
  {
    id: '0e8a2c28106df933a07c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18606&size=130',
  },
  {
    id: '7fb15c1360568908d047',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18607&size=130',
  },
  {
    id: '5ed462765e33b76dee22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18608&size=130',
  },
  {
    id: '9a04a7a69be372bd2bf2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18609&size=130',
  },
  {
    id: '91f9af5b931e7a40230f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18610&size=130',
  },
  {
    id: '64e25b4067058e5bd714',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18611&size=130',
  },
  {
    id: '12bc2a1e165bff05a64a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18612&size=130',
  },
  {
    id: '412f788d44c8ad96f4d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18613&size=130',
  },
  {
    id: '9e7da4df989a71c4288b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18614&size=130',
  },
  {
    id: 'd506eea4d2e13bbf62f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18615&size=130',
  },
  {
    id: 'a7c59367af22467c1f33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18616&size=130',
  },
  {
    id: '46bd731f4f5aa604ff4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18617&size=130',
  },
  {
    id: 'a44092e2aea747f91eb6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18618&size=130',
  },
  {
    id: 'a6ff915dad1844461d09',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18619&size=130',
  },
  {
    id: 'dcd9ec7bd03e3960602f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18620&size=130',
  },
  {
    id: '6a2c5b8e67cb8e95d7da',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18621&size=130',
  },
  {
    id: '609a52386e7d8723de6c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18622&size=130',
  },
  {
    id: '4c8a7f28436daa33f37c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18623&size=130',
  },
  {
    id: '339835350970e02eb961',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18314&size=130',
  },
  {
    id: '2ff6285b141efd40a40f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18315&size=130',
  },
  {
    id: 'f255f2f8cebd27e37eac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18316&size=130',
  },
  {
    id: 'f0acf101cd44241a7d55',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18317&size=130',
  },
  {
    id: '504452e96eac87f2debd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18318&size=130',
  },
  {
    id: 'e4dbe776db33326d6b22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18319&size=130',
  },
  {
    id: 'e239fe94c2d12b8f72c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18320&size=130',
  },
  {
    id: 'fd2de080dcc5359b6cd4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18321&size=130',
  },
  {
    id: 'e8ebf646ca03235d7a12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18322&size=130',
  },
  {
    id: '096a16c72a82c3dc9a93',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18323&size=130',
  },
  {
    id: '92b38a1eb65b5f05064a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18324&size=130',
  },
  {
    id: '384521e81dadf4f3adbc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18325&size=130',
  },
  {
    id: '88b49219ae5c47021e4d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18326&size=130',
  },
  {
    id: 'ec17f7bacbff22a17bee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18327&size=130',
  },
  {
    id: '1bac0f013344da1a8355',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18328&size=130',
  },
  {
    id: '6fdd7a704635af6bf624',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18329&size=130',
  },
  {
    id: '18750ed8329ddbc3828c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18330&size=130',
  },
  {
    id: '368c21211d64f43aad75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18331&size=130',
  },
  {
    id: '2cee3c430006e958b017',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18332&size=130',
  },
  {
    id: '620e73a34fe6a6b8fff7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18333&size=130',
  },
  {
    id: '79ae31020d47e419bd56',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18116&size=130',
  },
  {
    id: '087c41d07d9594cbcd84',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18117&size=130',
  },
  {
    id: '1da957056b40821edb51',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18118&size=130',
  },
  {
    id: '967dddd1e19408ca5185',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18119&size=130',
  },
  {
    id: '57f513592f1cc6429f0d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18120&size=130',
  },
  {
    id: '7ad33f7f033aea64b32b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18121&size=130',
  },
  {
    id: '506116cd2a88c3d69a99',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18122&size=130',
  },
  {
    id: 'c3cc8460b825517b0834',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18123&size=130',
  },
  {
    id: '10ea50466c03855ddc12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18124&size=130',
  },
  {
    id: '1a6e5bc267878ed9d796',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18125&size=130',
  },
  {
    id: '52cd10612c24c57a9c35',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18126&size=130',
  },
  {
    id: 'ff07bcab80ee69b030ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18127&size=130',
  },
  {
    id: 'ebe6b74a8b0f62513b1e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18128&size=130',
  },
  {
    id: '6fd532790e3ce762be2d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18129&size=130',
  },
  {
    id: 'a9aaf706cb43221d7b52',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18130&size=130',
  },
  {
    id: '57c3086f342add74843b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18131&size=130',
  },
  {
    id: '142d4c8170c4999ac0d5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18132&size=130',
  },
  {
    id: 'ad16f4bac8ff21a178ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18133&size=130',
  },
  {
    id: '35ef6f435306ba58e317',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18134&size=130',
  },
  {
    id: 'c60a9da6a1e348bd11f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18135&size=130',
  },
  {
    id: '9ec74b6b772e9e70c73f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18009&size=130',
  },
  {
    id: 'd9d00f7c3339da678328',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18010&size=130',
  },
  {
    id: '74f9a3559f10764e2f01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18011&size=130',
  },
  {
    id: 'fb332b9f17dafe84a7cb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18012&size=130',
  },
  {
    id: '87c0566c6a298377da38',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18013&size=130',
  },
  {
    id: '99324b9e77db9e85c7ca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18014&size=130',
  },
  {
    id: 'e9c73a6b062eef70b63f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18015&size=130',
  },
  {
    id: '11c0fd6cc12928777138',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18016&size=130',
  },
  {
    id: 'bc2b51876dc2849cddd3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18017&size=130',
  },
  {
    id: 'a6fd485174149d4ac405',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18018&size=130',
  },
  {
    id: '886267ce5b8bb2d5eb9a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18019&size=130',
  },
  {
    id: 'a5024dae71eb98b5c1fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18020&size=130',
  },
  {
    id: '69be8012bc5755090c46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18021&size=130',
  },
  {
    id: 'e93403983fddd6838fcc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18022&size=130',
  },
  {
    id: '20b2cb1ef75b1e05474a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18023&size=130',
  },
  {
    id: '619f8533b97650280967',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18024&size=130',
  },
  {
    id: '6a888f24b3615a3f0370',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18025&size=130',
  },
  {
    id: '813967955bd0b28eebc1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18026&size=130',
  },
  {
    id: 'e5fd02513e14d74a8e05',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18027&size=130',
  },
  {
    id: '3b83db2fe76a0e34577b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18028&size=130',
  },
  {
    id: '8ba942057e40971ece51',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17989&size=130',
  },
  {
    id: '3a2bf087ccc2259c7cd3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17990&size=130',
  },
  {
    id: '8cc3476f7b2a9274cb3b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17991&size=130',
  },
  {
    id: '2b7defd1d3943aca6385',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17992&size=130',
  },
  {
    id: 'dde1184d2408cd569419',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17993&size=130',
  },
  {
    id: 'c21504b938fcd1a288ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17994&size=130',
  },
  {
    id: '05adc201fe44171a4e55',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17995&size=130',
  },
  {
    id: '09fcc950f5151c4b4504',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17996&size=130',
  },
  {
    id: '08b1c91df5581c064549',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17997&size=130',
  },
  {
    id: 'e0f1225d1e18f746ae09',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17998&size=130',
  },
  {
    id: '3e9efd32c17728297166',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17999&size=130',
  },
  {
    id: 'b8946438587db123e86c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18000&size=130',
  },
  {
    id: 'a7887a244661af3ff670',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18001&size=130',
  },
  {
    id: '549c8a30b6755f2b0664',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18002&size=130',
  },
  {
    id: 'aa5975f549b0a0eef9a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18003&size=130',
  },
  {
    id: 'fc7e24d21897f1c9a886',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18004&size=130',
  },
  {
    id: 'ee22378e0bcbe295bbda',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18005&size=130',
  },
  {
    id: '2eb6f41ac85f2101784e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18006&size=130',
  },
  {
    id: 'f9be22121e57f709ae46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18007&size=130',
  },
  {
    id: '1b82cf2ef36b1a35437a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=18008&size=130',
  },
  {
    id: '1af28b5eb71b5e45070a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17949&size=130',
  },
  {
    id: '8ff61d5a211fc841910e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17950&size=130',
  },
  {
    id: 'adac3e000245eb1bb254',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17951&size=130',
  },
  {
    id: '007aacd6909379cd2082',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17952&size=130',
  },
  {
    id: 'f23e5f9263d78a89d3c6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17953&size=130',
  },
  {
    id: '6f2ec182fdc714994dd6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17954&size=130',
  },
  {
    id: '78b6d71aeb5f02015b4e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17955&size=130',
  },
  {
    id: '4386eb2ad76f3e31677e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17956&size=130',
  },
  {
    id: 'c0f3695f551abc44e50b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17957&size=130',
  },
  {
    id: '3b4c91e0ada544fb1db4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17958&size=130',
  },
  {
    id: 'fff954556810814ed801',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17959&size=130',
  },
  {
    id: 'e8be4c1270579909c046',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17960&size=130',
  },
  {
    id: '39219c8da0c8499610d9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17961&size=130',
  },
  {
    id: '38029eaea2eb4bb512fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17962&size=130',
  },
  {
    id: '980a3fa603e3eabdb3f2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17963&size=130',
  },
  {
    id: '5f22ff8ec3cb2a9573da',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17964&size=130',
  },
  {
    id: '77f6d65aea1f03415a0e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17965&size=130',
  },
  {
    id: 'f23c50906cd5858bdcc4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17966&size=130',
  },
  {
    id: '4e71edddd19838c66189',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17967&size=130',
  },
  {
    id: 'd1a76d0b514eb810e15f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17968&size=130',
  },
  {
    id: 'fb8f7e234266ab38f277',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17929&size=130',
  },
  {
    id: 'b2b0341c0859e107b848',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17930&size=130',
  },
  {
    id: '250aa2a69ee377bd2ef2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17931&size=130',
  },
  {
    id: '3a4ebae286a76ff936b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17932&size=130',
  },
  {
    id: '0e008facb3e95ab703f8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17933&size=130',
  },
  {
    id: '11ba9316af53460d1f42',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17934&size=130',
  },
  {
    id: '7cddff71c3342a6a7325',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17935&size=130',
  },
  {
    id: 'a8c53469082ce172b83d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17936&size=130',
  },
  {
    id: '2809b5a589e060be39f1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17937&size=130',
  },
  {
    id: '70ddee71d2343b6a6225',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17938&size=130',
  },
  {
    id: '10058fa9b3ec5ab203fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17939&size=130',
  },
  {
    id: 'e126798a45cfac91f5de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17940&size=130',
  },
  {
    id: 'f0a56909554cbc12e55d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17941&size=130',
  },
  {
    id: '65b5ff19c35c2a02734d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17942&size=130',
  },
  {
    id: 'd3da487674339d6dc422',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17943&size=130',
  },
  {
    id: '43d5d779eb3c02625b2d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17944&size=130',
  },
  {
    id: '327ba7d79b9272cc2b83',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17945&size=130',
  },
  {
    id: 'c84f5ee362a68bf8d2b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17946&size=130',
  },
  {
    id: 'd081472d7b689236cb79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17947&size=130',
  },
  {
    id: 'ae6d3ec10284ebdab295',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17948&size=130',
  },
  {
    id: '54c613692f2cc6729f3d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17867&size=130',
  },
  {
    id: '02ec42437e069758ce17',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17868&size=130',
  },
  {
    id: '5ca31d0c2149c8179158',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17869&size=130',
  },
  {
    id: 'cb6689c9b58c5cd2059d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17870&size=130',
  },
  {
    id: '2bbc68135456bd08e447',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17871&size=130',
  },
  {
    id: '8562d9cde5880cd65599',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17872&size=130',
  },
  {
    id: 'd27c8fd3b3965ac80387',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17873&size=130',
  },
  {
    id: '44591af626b3cfed96a2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17874&size=130',
  },
  {
    id: '43ab1c042041c91f9050',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17875&size=130',
  },
  {
    id: 'ee9ab6358a70632e3a61',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17876&size=130',
  },
  {
    id: 'fb57a2f89ebd77e32eac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17877&size=130',
  },
  {
    id: '85c9df66e3230a7d5332',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17878&size=130',
  },
  {
    id: '64243f8b03ceea90b3df',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17879&size=130',
  },
  {
    id: 'a068f4c7c88221dc7893',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17880&size=130',
  },
  {
    id: '3fa76a08564dbf13e65c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17881&size=130',
  },
  {
    id: 'd18b8724bb61523f0b70',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17882&size=130',
  },
  {
    id: '8276d5d9e99c00c2598d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17883&size=130',
  },
  {
    id: 'a0b0f01fcc5a25047c4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17884&size=130',
  },
  {
    id: '89ecd843e4060d585417',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17885&size=130',
  },
  {
    id: '485c1af326b6cfe896a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17886&size=130',
  },
  {
    id: '839cac33907679282067',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17827&size=130',
  },
  {
    id: '7da75508694d8013d95c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17828&size=130',
  },
  {
    id: 'a3b28a1db6585f060649',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17829&size=130',
  },
  {
    id: '6f20458f79ca9094c9db',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17830&size=130',
  },
  {
    id: '064b2de411a1f8ffa1b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17831&size=130',
  },
  {
    id: '0bca2f651320fa7ea331',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17832&size=130',
  },
  {
    id: 'bef39b5ca7194e471708',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17833&size=130',
  },
  {
    id: '795c5ff363b68ae8d3a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17834&size=130',
  },
  {
    id: '16f2315d0d18e446bd09',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17835&size=130',
  },
  {
    id: '1dbb3d140151e80fb140',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17836&size=130',
  },
  {
    id: 'c273e3dcdf9936c76f88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17837&size=130',
  },
  {
    id: 'b42c9683aac643981ad7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17838&size=130',
  },
  {
    id: '44cd67625b27b279eb36',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17839&size=130',
  },
  {
    id: 'a50599aaa5ef4cb115fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17840&size=130',
  },
  {
    id: '36ad0b023747de198756',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17841&size=130',
  },
  {
    id: '9d3aa3959fd0768e2fc1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17842&size=130',
  },
  {
    id: '01f23e5d0218eb46b209',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17843&size=130',
  },
  {
    id: '44ef7c404005a95bf014',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17844&size=130',
  },
  {
    id: 'aa1f93b0aff546ab1fe4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17845&size=130',
  },
  {
    id: '17402def11aaf8f4a1bb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17846&size=130',
  },
  {
    id: '0396de39e27c0b22526d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17745&size=130',
  },
  {
    id: 'fc1e22b11ef4f7aaaee5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17746&size=130',
  },
  {
    id: 'ffec20431c06f558ac17',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17747&size=130',
  },
  {
    id: 'c05518fa24bfcde194ae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17748&size=130',
  },
  {
    id: '6988b0278c62653c3c73',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17749&size=130',
  },
  {
    id: '8d4157ee6bab82f5dbba',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17750&size=130',
  },
  {
    id: 'efd3347c0839e167b828',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17751&size=130',
  },
  {
    id: 'b04d64e258a7b1f9e8b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17752&size=130',
  },
  {
    id: 'e3d636790a3ce362ba2d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17753&size=130',
  },
  {
    id: 'ad477be847adaef3f7bc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17754&size=130',
  },
  {
    id: 'c96a1ec52280cbde9291',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17755&size=130',
  },
  {
    id: '6324b38b8fce66903fdf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17756&size=130',
  },
  {
    id: '0b7edad1e6940fca5685',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17757&size=130',
  },
  {
    id: '1cc2ce6df2281b764239',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17758&size=130',
  },
  {
    id: '2edefd71c134286a7125',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17759&size=130',
  },
  {
    id: 'a47948d674939dcdc482',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17760&size=130',
  },
  {
    id: '5438b99785d26c8c35c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17761&size=130',
  },
  {
    id: '0ef9e056dc13354d6c02',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17762&size=130',
  },
  {
    id: 'd0ea3f450300ea5eb311',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17763&size=130',
  },
  {
    id: '1bc5f36acf2f26717f3e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17764&size=130',
  },
  {
    id: 'fed6ab78973d7e63272c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17625&size=130',
  },
  {
    id: 'daad8c03b04659180057',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17626&size=130',
  },
  {
    id: 'f7e8a0469c03755d2c12',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17627&size=130',
  },
  {
    id: 'd49f8431b874512a0865',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17628&size=130',
  },
  {
    id: '339862365e73b72dee62',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17629&size=130',
  },
  {
    id: '38986a365673bf2de662',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17630&size=130',
  },
  {
    id: '38a96b075742be1ce753',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17631&size=130',
  },
  {
    id: '56b13a1f065aef04b64b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17632&size=130',
  },
  {
    id: 'f2579ff9a3bc4ae213ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17633&size=130',
  },
  {
    id: 'd156bff883bd6ae333ac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17634&size=130',
  },
  {
    id: 'bf39d097ecd2058c5cc3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17635&size=130',
  },
  {
    id: '34d05c7e603b8965d02a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17636&size=130',
  },
  {
    id: 'd701beaf82ea6bb432fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17637&size=130',
  },
  {
    id: '49d5237b1f3ef660af2f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17638&size=130',
  },
  {
    id: 'd958b2f68eb367ed3ea2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17639&size=130',
  },
  {
    id: 'ccbfa81194547d0a2445',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17640&size=130',
  },
  {
    id: 'f481bd2f816a6834317b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17605&size=130',
  },
  {
    id: 'd1019bafa7ea4eb417fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17606&size=130',
  },
  {
    id: 'd8f89356af13464d1f02',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17607&size=130',
  },
  {
    id: '254161ef5daab4f4edbb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17608&size=130',
  },
  {
    id: '98e1dd4fe10a0854511b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17609&size=130',
  },
  {
    id: '8bb8cd16f153180d4142',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17610&size=130',
  },
  {
    id: '2c306b9e57dbbe85e7ca',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17611&size=130',
  },
  {
    id: '940cd4a2e8e701b958f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17612&size=130',
  },
  {
    id: '5524148a28cfc19198de',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17613&size=130',
  },
  {
    id: '07db45757930906ec921',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17614&size=130',
  },
  {
    id: 'af57ecf9d0bc39e260ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17615&size=130',
  },
  {
    id: '19ca45647921907fc930',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17616&size=130',
  },
  {
    id: '3b1e66b05af5b3abeae4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17617&size=130',
  },
  {
    id: '8005deabe2ee0bb052ff',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17618&size=130',
  },
  {
    id: '71592ef712b2fbeca2a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17619&size=130',
  },
  {
    id: '42a31a0d2648cf169659',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17620&size=130',
  },
  {
    id: '35ac6c025047b919e056',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17621&size=130',
  },
  {
    id: '371d6db351f6b8a8e1e7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17622&size=130',
  },
  {
    id: '5a0401aa3defd4b18dfe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17623&size=130',
  },
  {
    id: 'b21de6b3daf633a86ae7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17624&size=130',
  },
  {
    id: '974730e90cace5f2bcbd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17451&size=130',
  },
  {
    id: '246a84c4b88151df0890',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17452&size=130',
  },
  {
    id: '49d9e877d4323d6c6423',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17453&size=130',
  },
  {
    id: '396b9bc5a7804ede1791',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17454&size=130',
  },
  {
    id: 'ef874c29706c9932c07d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17455&size=130',
  },
  {
    id: '2a289686aac3439d1ad2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17456&size=130',
  },
  {
    id: '0290bf3e837b6a25336a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17457&size=130',
  },
  {
    id: '6549dbe7e7a20efc57b3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17458&size=130',
  },
  {
    id: 'fddd42737e369768ce27',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17459&size=130',
  },
  {
    id: 'f46d4cc3708699d8c097',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17460&size=130',
  },
  {
    id: 'a16518cb248ecdd0949f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17461&size=130',
  },
  {
    id: '3c4886e6baa353fd0ab2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17462&size=130',
  },
  {
    id: '2fea9444a801415f1810',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17463&size=130',
  },
  {
    id: 'e61852b66ef387addee2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17464&size=130',
  },
  {
    id: '0339b6978ad2638c3ac3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17465&size=130',
  },
  {
    id: '0eb6b818845d6d03344c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17466&size=130',
  },
  {
    id: '509fe731db74322a6b65',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17467&size=130',
  },
  {
    id: '552ee580d9c5309b69d4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17468&size=130',
  },
  {
    id: '052db48388c6619838d7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17469&size=130',
  },
  {
    id: '416ff3c1cf8426da7f95',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17470&size=130',
  },
  {
    id: '007287dcbb9952c70b88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17419&size=130',
  },
  {
    id: '026e82c0be8557db0e94',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17420&size=130',
  },
  {
    id: '0bf88a56b6135f4d0602',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17421&size=130',
  },
  {
    id: '5906dba8e7ed0eb357fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17422&size=130',
  },
  {
    id: '4044c3eaffaf16f14fbe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17423&size=130',
  },
  {
    id: '2d23b18d8dc864963dd9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17424&size=130',
  },
  {
    id: 'a1003cae00ebe9b5b0fa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17425&size=130',
  },
  {
    id: '814c1fe223a7caf993b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17426&size=130',
  },
  {
    id: '8faa10042c41c51f9c50',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17427&size=130',
  },
  {
    id: '26fabe5482116b4f3200',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17428&size=130',
  },
  {
    id: '5577ccd9f09c19c2408d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17429&size=130',
  },
  {
    id: 'dd1f47b17bf492aacbe5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17430&size=130',
  },
  {
    id: '10398b97b7d25e8c07c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17431&size=130',
  },
  {
    id: '3664a2ca9e8f77d12e9e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17432&size=130',
  },
  {
    id: '8482112c2d69c4379d78',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17433&size=130',
  },
  {
    id: '6306f5a8c9ed20b379fc',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17434&size=130',
  },
  {
    id: '346aa3c49f8176df2f90',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17435&size=130',
  },
  {
    id: '7789e727db62323c6b73',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17436&size=130',
  },
  {
    id: '69a0f80ec44b2d15745a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17437&size=130',
  },
  {
    id: '0d6e9fc0a3854adb1394',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17438&size=130',
  },
  {
    id: '63347e9d42d8ab86f2c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17297&size=130',
  },
  {
    id: '15e40b4d3708de568719',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17298&size=130',
  },
  {
    id: '5b33449a78df9181c8ce',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17299&size=130',
  },
  {
    id: '3d5125f819bdf0e3a9ac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17300&size=130',
  },
  {
    id: '44d25d7b613e8860d12f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17301&size=130',
  },
  {
    id: '8fbb9512a95740091946',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17302&size=130',
  },
  {
    id: '96958d3cb17958270168',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17303&size=130',
  },
  {
    id: '9aeb8e42b2075b590216',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17304&size=130',
  },
  {
    id: '5a7c4fd573909acec381',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17305&size=130',
  },
  {
    id: '2a7e3cd70092e9ccb083',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17306&size=130',
  },
  {
    id: 'dbe4cc4df00819564019',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17307&size=130',
  },
  {
    id: '417551dc6d9984c7dd88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17308&size=130',
  },
  {
    id: 'ba66abcf978a7ed4279b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17309&size=130',
  },
  {
    id: '68877a2e466baf35f67a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17310&size=130',
  },
  {
    id: '3ddf2e761233fb6da222',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17311&size=130',
  },
  {
    id: '86ee21471d02f45cad13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17195&size=130',
  },
  {
    id: 'cf996f305375ba2be364',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17196&size=130',
  },
  {
    id: '8ab02b19175cfe02a74d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17197&size=130',
  },
  {
    id: '7cc7de6ee22b0b75523a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17198&size=130',
  },
  {
    id: '28488be1b7a45efa07b5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17199&size=130',
  },
  {
    id: '3ee4824dbe0857560e19',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17200&size=130',
  },
  {
    id: 'cb26768f4acaa394fadb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17201&size=130',
  },
  {
    id: 'bed100783c3dd5638c2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17202&size=130',
  },
  {
    id: 'cf9a70334c76a528fc67',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17203&size=130',
  },
  {
    id: '897531dc0d99e4c7bd88',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17204&size=130',
  },
  {
    id: 'e5685cc1608489dad095',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17205&size=130',
  },
  {
    id: 'd1ba6b135756be08e747',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17206&size=130',
  },
  {
    id: '596ae2c3de8637d86e97',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17207&size=130',
  },
  {
    id: '4079f4d0c89521cb7884',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17208&size=130',
  },
  {
    id: 'da4a6fe353a6baf8e3b7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17209&size=130',
  },
  {
    id: '4df9fb50c7152e4b7704',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17210&size=130',
  },
  {
    id: 'cdb07a19465caf02f64d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17211&size=130',
  },
  {
    id: 'accf1c662023c97d9032',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=17212&size=130',
  },
  {
    id: '2fc528441401fd5fa410',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27531&size=130',
  },
  {
    id: '930f938eafcb46951fda',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27532&size=130',
  },
  {
    id: '256324e218a7f1f9a8b6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27533&size=130',
  },
  {
    id: 'b638b4b988fc61a238ed',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27534&size=130',
  },
  {
    id: 'e6f6e577d932306c6923',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27535&size=130',
  },
  {
    id: 'f172edf3d1b638e861a7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27536&size=130',
  },
  {
    id: 'daa6c727fb62123c4b73',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27537&size=130',
  },
  {
    id: '77be693f557abc24e56b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27538&size=130',
  },
  {
    id: '0ac115402905c05b9914',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27539&size=130',
  },
  {
    id: '2eb736360a73e32dba62',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27540&size=130',
  },
  {
    id: '9c7485f5b9b050ee09a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27541&size=130',
  },
  {
    id: '0ad210532c16c5489c07',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27542&size=130',
  },
  {
    id: '83809801a4444d1a1455',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27543&size=130',
  },
  {
    id: 'ba15ae9492d17b8f22c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27544&size=130',
  },
  {
    id: 'af52bad386966fc83687',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27545&size=130',
  },
  {
    id: 'db93cd12f15718094146',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=27546&size=130',
  },
  {
    id: '31d9e192ddd734896dc6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41308&size=130',
  },
  {
    id: 'ed273c6c0029e977b038',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41309&size=130',
  },
  {
    id: '8bf159ba65ff8ca1d5ee',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41310&size=130',
  },
  {
    id: '1f99ccd2f09719c94086',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41311&size=130',
  },
  {
    id: 'd26e3e250260eb3eb271',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41312&size=130',
  },
  {
    id: '7d589013ac5645081c47',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41313&size=130',
  },
  {
    id: '9745790e454bac15f55a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41314&size=130',
  },
  {
    id: '0585eaced68b3fd5669a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41315&size=130',
  },
  {
    id: '21fcc9b7f5f21cac45e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41316&size=130',
  },
  {
    id: '534eba0586406f1e3651',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41317&size=130',
  },
  {
    id: '729e98d5a4904dce1481',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41318&size=130',
  },
  {
    id: '5aabb1e08da564fb3db4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41319&size=130',
  },
  {
    id: 'b1c2558969cc8092d9dd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41320&size=130',
  },
  {
    id: '6f5d8a16b6535f0d0642',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41321&size=130',
  },
  {
    id: 'eeda089134d4dd8a84c5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41322&size=130',
  },
  {
    id: 'f54e12052e40c71e9e51',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41323&size=130',
  },
  {
    id: '7ee69eada2e84bb612f9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41324&size=130',
  },
  {
    id: '34efd5a4e9e100bf59f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41325&size=130',
  },
  {
    id: 'dbc5398e05cbec95b5da',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41326&size=130',
  },
  {
    id: 'e6f605bd39f8d0a689e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41327&size=130',
  },
  {
    id: 'af1c53576f12864cdf03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41328&size=130',
  },
  {
    id: '5295afde939b7ac5238a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41329&size=130',
  },
  {
    id: 'c98f37c40b81e2dfbb90',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41330&size=130',
  },
  {
    id: 'bae145aa79ef90b1c9fe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=41331&size=130',
  },
  {
    id: '89c62a511614ff4aa605',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32047&size=130',
  },
  {
    id: '7d63c1f4fdb114ef4da0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32048&size=130',
  },
  {
    id: 'cf1072874ec2a79cfed3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32049&size=130',
  },
  {
    id: '92352ca210e7f9b9a0f6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32050&size=130',
  },
  {
    id: '6bc6d451e814014a5805',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32051&size=130',
  },
  {
    id: '252c9dbba1fe48a011ef',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32052&size=130',
  },
  {
    id: 'f1fb486c74299d77c438',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32053&size=130',
  },
  {
    id: '91732be417a1feffa7b0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32054&size=130',
  },
  {
    id: '31b08a27b6625f3c0673',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32055&size=130',
  },
  {
    id: '5b6eeff9d3bc3ae263ad',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32056&size=130',
  },
  {
    id: 'eede5b49670c8e52d71d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32057&size=130',
  },
  {
    id: 'e8955e0262478b19d256',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32058&size=130',
  },
  {
    id: 'fce54b7277379e69c726',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32059&size=130',
  },
  {
    id: '5b00eb97d7d23e8c67c3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32060&size=130',
  },
  {
    id: 'eb515ac666838fddd692',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32061&size=130',
  },
  {
    id: '92b9202e1c6bf535ac7a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32062&size=130',
  },
  {
    id: '59b5ea22d6673f396676',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32063&size=130',
  },
  {
    id: '9ff153666f23867ddf32',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32064&size=130',
  },
  {
    id: 'ec6f21f81dbdf4e3adac',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32065&size=130',
  },
  {
    id: '3c4cf2dbce9e27c07e8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32066&size=130',
  },
  {
    id: 'f4a93b3e077bee25b76a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32067&size=130',
  },
  {
    id: 'e1c429531516fc48a507',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32068&size=130',
  },
  {
    id: 'bea677314b74a22afb65',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32069&size=130',
  },
  {
    id: '55339fa4a3e14abf13f0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=32070&size=130',
  },
  {
    id: '3fd18047bc02555c0c13',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31795&size=130',
  },
  {
    id: '7fc1c757fb12124c4b03',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31796&size=130',
  },
  {
    id: '1cc0a5569913704d2902',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31797&size=130',
  },
  {
    id: '1f13a58599c0709e29d1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31798&size=130',
  },
  {
    id: 'ca4071d64d93a4cdfd82',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31799&size=130',
  },
  {
    id: '7914cd82f1c7189941d6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31800&size=130',
  },
  {
    id: '0e02bb9487d16e8f37c0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31801&size=130',
  },
  {
    id: '4f53f9c5c5802cde7591',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31802&size=130',
  },
  {
    id: '18e6af7093357a6b2324',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31803&size=130',
  },
  {
    id: '2c219cb7a0f249ac10e3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31804&size=130',
  },
  {
    id: '46e6f770cb35226b7b24',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31805&size=130',
  },
  {
    id: 'a52317b52bf0c2ae9be1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31806&size=130',
  },
  {
    id: 'f1f642607e25977bce34',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31807&size=130',
  },
  {
    id: 'e69b2a0d1648ff16a659',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31808&size=130',
  },
  {
    id: 'ab4466d25a97b3c9ea86',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31809&size=130',
  },
  {
    id: '556a9bfca7b94ee717a8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31810&size=130',
  },
  {
    id: 'e5522ac41681ffdfa690',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31811&size=130',
  },
  {
    id: '1dd9d54fe90a0054591b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31812&size=130',
  },
  {
    id: 'd91510832cc6c5989cd7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31813&size=130',
  },
  {
    id: '6e37a4a198e471ba28f5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=31814&size=130',
  },
  {
    id: '1a17c185fdc0149e4dd1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30807&size=130',
  },
  {
    id: 'ce3b1aa926eccfb296fd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30808&size=130',
  },
  {
    id: '250ff09dccd825867cc9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30809&size=130',
  },
  {
    id: '1f04c996f5d31c8d45c2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30810&size=130',
  },
  {
    id: '31f3e661da24337a6a35',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30811&size=130',
  },
  {
    id: '913741a57de094becdf1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30812&size=130',
  },
  {
    id: 'fb0f2a9d16d8ff86a6c9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30813&size=130',
  },
  {
    id: 'ab09799b45deac80f5cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30814&size=130',
  },
  {
    id: 'dc650ff733b2daec83a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30815&size=130',
  },
  {
    id: '923d7eaf42eaabb4f2fb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30816&size=130',
  },
  {
    id: 'dda330310c74e52abc65',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30817&size=130',
  },
  {
    id: '849b6a09564cbf12e65d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30818&size=130',
  },
  {
    id: 'cde722751e30f76eae21',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30819&size=130',
  },
  {
    id: '2f00c792fbd712894bc6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30820&size=130',
  },
  {
    id: 'd42f3dbd01f8e8a6b1e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30821&size=130',
  },
  {
    id: 'f34219d02595cccb9584',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30822&size=130',
  },
  {
    id: '2624cdb6f1f318ad41e2',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30823&size=130',
  },
  {
    id: '327dd6efeaaa03f45abb',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30824&size=130',
  },
  {
    id: '9ac77f554310aa4ef301',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30825&size=130',
  },
  {
    id: 'b44b52d96e9c87c2de8d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=30826&size=130',
  },
  {
    id: 'b098c016fc53150d4c42',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25852&size=130',
  },
  {
    id: 'd64ba7c59b8072de2b91',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25853&size=130',
  },
  {
    id: '2d965f18635d8a03d34c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25854&size=130',
  },
  {
    id: '89b7fa39c67c2f22766d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25855&size=130',
  },
  {
    id: 'ab7527fa1bbff2e1abae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25856&size=130',
  },
  {
    id: '6ea2e32ddf6836366f79',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25857&size=130',
  },
  {
    id: '46d4c85bf41e1d40440f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25858&size=130',
  },
  {
    id: 'ac1b23941fd1f68fafc0',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25859&size=130',
  },
  {
    id: '679aef15d3503a0e6341',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25860&size=130',
  },
  {
    id: '3d71b4fe88bb61e538aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25861&size=130',
  },
  {
    id: '4966c3e9ffac16f24fbd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25862&size=130',
  },
  {
    id: 'f14b7ac44681afdff690',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25863&size=130',
  },
  {
    id: 'cf1c4b9377d69e88c7c7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25864&size=130',
  },
  {
    id: '82f707783b3dd2638b2c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25865&size=130',
  },
  {
    id: 'a45122de1e9bf7c5ae8a',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25866&size=130',
  },
  {
    id: '41cec641fa04135a4a15',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25867&size=130',
  },
  {
    id: '922512aa2eefc7b19efe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25868&size=130',
  },
  {
    id: 'd2f4537b6f3e8660df2f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25869&size=130',
  },
  {
    id: '09168b99b7dc5e8207cd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25870&size=130',
  },
  {
    id: 'fefa62755e30b76eee21',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25872&size=130',
  },
  {
    id: '078e9901a5444c1a1555',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25874&size=130',
  },
  {
    id: 'afe3306c0c29e577bc38',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25875&size=130',
  },
  {
    id: '73b9c530f975102b4964',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25402&size=130',
  },
  {
    id: '4718f091ccd4258a7cc5',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25403&size=130',
  },
  {
    id: 'b5a4052d3968d0368979',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25404&size=130',
  },
  {
    id: '64d6d55fe91a0044590b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25405&size=130',
  },
  {
    id: '4beef967c5222c7c7533',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25406&size=130',
  },
  {
    id: 'eec15d48610d8853d11c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25407&size=130',
  },
  {
    id: '0fb6c33fff7a16244f6b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25408&size=130',
  },
  {
    id: '9afd57746b31826fdb20',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25409&size=130',
  },
  {
    id: '07cfc946f5031c5d4512',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25410&size=130',
  },
  {
    id: '9c96531f6f5a8604df4b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25411&size=130',
  },
  {
    id: '1b23d3aaefef06b15ffe',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25412&size=130',
  },
  {
    id: 'e4c62d4f110af854a11b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25413&size=130',
  },
  {
    id: '8cf6467f7a3a9364ca2b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25414&size=130',
  },
  {
    id: 'd7c31c4a200fc951901e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25415&size=130',
  },
  {
    id: '44fe3e760233eb6db222',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25334&size=130',
  },
  {
    id: '5987220f1e4af714ae5b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25335&size=130',
  },
  {
    id: '45a3312b0d6ee430bd7f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25336&size=130',
  },
  {
    id: 'cd13b89b84de6d8034cf',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25337&size=130',
  },
  {
    id: '0ed3785b441ead40f40f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25338&size=130',
  },
  {
    id: 'aa7fddf7e1b208ec51a3',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25339&size=130',
  },
  {
    id: '0f817f09434caa12f35d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25340&size=130',
  },
  {
    id: 'b6d8c750fb15124b4b04',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25341&size=130',
  },
  {
    id: '535321db1d9ef4c0ad8f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25342&size=130',
  },
  {
    id: 'f03483bcbff956a70fe8',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25343&size=130',
  },
  {
    id: 'fa2976a04ae5a3bbfaf4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25344&size=130',
  },
  {
    id: 'd7945a1d66588f06d649',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25345&size=130',
  },
  {
    id: '1a5394daa89f41c1188e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25346&size=130',
  },
  {
    id: 'f3537cda409fa9c1f08e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25347&size=130',
  },
  {
    id: '7081f808c44d2d13745c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25348&size=130',
  },
  {
    id: 'd6465fcf638a8ad4d39b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25349&size=130',
  },
  {
    id: '8984030d3f48d6168f59',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25350&size=130',
  },
  {
    id: 'b57c3ef502b0ebeeb2a1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25351&size=130',
  },
  {
    id: '09348dbdb1f858a601e9',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25352&size=130',
  },
  {
    id: '08918d18b15d5803014c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=25353&size=130',
  },
  {
    id: '148f5e3062758b2bd264',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21958&size=130',
  },
  {
    id: 'dee0955fa91a4044190b',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21959&size=130',
  },
  {
    id: 'e63da2829ec777992ed6',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21960&size=130',
  },
  {
    id: '8a5acfe5f3a01afe43b1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21961&size=130',
  },
  {
    id: '0df74b48770d9e53c71c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21962&size=130',
  },
  {
    id: 'f669b1d68d9364cd3d82',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21963&size=130',
  },
  {
    id: 'be9cfe23c2662b387277',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21964&size=130',
  },
  {
    id: 'ec9ead219164783a2175',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21965&size=130',
  },
  {
    id: '5ec41c7b203ec960902f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21966&size=130',
  },
  {
    id: 'ceb48d0bb14e5810015f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21967&size=130',
  },
  {
    id: '6bad37120b57e209bb46',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21968&size=130',
  },
  {
    id: 'a9faf445c800215e7811',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21969&size=130',
  },
  {
    id: '53fb68425407bd59e416',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21431&size=130',
  },
  {
    id: '524c66f55ab0b3eeeaa1',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21432&size=130',
  },
  {
    id: 'b1d2846bb82e5170083f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21433&size=130',
  },
  {
    id: '1add2c641021f97fa030',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21434&size=130',
  },
  {
    id: '886dbfd483916acf3380',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21435&size=130',
  },
  {
    id: '5cc56c7c5039b967e028',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21436&size=130',
  },
  {
    id: 'b7ec8655ba10534e0a01',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21437&size=130',
  },
  {
    id: 'e5fbd742eb0702595b16',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21438&size=130',
  },
  {
    id: '3f7a0cc33086d9d88097',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21439&size=130',
  },
  {
    id: '511a1da321e6c8b891f7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21440&size=130',
  },
  {
    id: '637f2ec61283fbdda292',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21441&size=130',
  },
  {
    id: 'b243fcfac0bf29e170ae',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21442&size=130',
  },
  {
    id: 'a4d5eb6cd7293e776738',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21443&size=130',
  },
  {
    id: 'f5ecbd558110684e3101',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21444&size=130',
  },
  {
    id: 'a3b5ea0cd6493f176658',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21445&size=130',
  },
  {
    id: '5fb11508294dc013995c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21446&size=130',
  },
  {
    id: '2c83673a5b7fb221eb6e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21447&size=130',
  },
  {
    id: '727436cd0a88e3d6ba99',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21448&size=130',
  },
  {
    id: '099b4c2270679939c076',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21449&size=130',
  },
  {
    id: '165a50e36ca685f8dcb7',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=21450&size=130',
  },
  {
    id: '938d222a1e6ff731ae7e',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19773&size=130',
  },
  {
    id: '6492d635ea70032e5a61',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19774&size=130',
  },
  {
    id: '34c08767bb22527c0b33',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19775&size=130',
  },
  {
    id: '589e9439a87c4122186d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19776&size=130',
  },
  {
    id: '3ca4f103cd4624187d57',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19777&size=130',
  },
  {
    id: 'c2be0c19305cd902804d',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19778&size=130',
  },
  {
    id: '2fd1e076dc33356d6c22',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19779&size=130',
  },
  {
    id: 'e8bb201c1c59f507ac48',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19780&size=130',
  },
  {
    id: '2759eefed2bb3be562aa',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19781&size=130',
  },
  {
    id: '67ffad58911d7843210c',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19782&size=130',
  },
  {
    id: '0d86c621fa64133a4a75',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19783&size=130',
  },
  {
    id: 'ce760ad13694dfca8685',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19784&size=130',
  },
  {
    id: '587b9ddca19948c71188',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19785&size=130',
  },
  {
    id: '29f3ef54d3113a4f6300',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19786&size=130',
  },
  {
    id: 'b54e72e94eaca7f2febd',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19787&size=130',
  },
  {
    id: '521792b0aef547ab1ee4',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19788&size=130',
  },
  {
    id: '6a8cab2b976e7e30277f',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19789&size=130',
  },
  {
    id: 'eae728401405fd5ba414',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19790&size=130',
  },
  {
    id: '8fba4c1d70589906c049',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19791&size=130',
  },
  {
    id: 'b3d06f775332ba6ce323',
    url:
      'https://zalo-api.zadn.vn/api/emoticon/sprite?eid=19792&size=130',
  },
];

export const STICKERS_PICKER = [
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker1d6c59a965ec8cb2d5fd.png",
      price: "Miễn phí",
      name: "PVcomBank",
      thumbImg: "https://zalo-api.zadn.vn/4/3/9/0/4/12232/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/4/3/9/0/4/12232/icon_pre/pvcombank1_thumb.png",
      id: "95acbd69812c6872313d",
      source: "0",
      totalImage: "16 sticker",
      value: [
        {
            id: "95bc13962fd3c68d9fc2",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49162&size=130"
        },
        {
            id: "84d603fc3fb9d6e78fa8",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49163&size=130"
        },
        {
            id: "f28c72a64ee3a7bdfef2",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49164&size=130"
        },
        {
            id: "2a87abad97e87eb627f9",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49165&size=130"
        },
        {
            id: "0b7e8954b5115c4f0500",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49166&size=130"
        },
        {
            id: "a92e2a041641ff1fa650",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49167&size=130"
        },
        {
            id: "dc4c40667c23957dcc32",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49168&size=130"
        },
        {
            id: "d2cb4fe173a49afac3b5",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49169&size=130"
        },
        {
            id: "735bed71d134386a6125",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49170&size=130"
        },
        {
            id: "b4432b69172cfe72a73d",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49171&size=130"
        },
        {
            id: "6802f028cc6d25337c7c",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49172&size=130"
        },
        {
            id: "37f2aed8929d7bc3228c",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49173&size=130"
        },
        {
            id: "66dffcf5c0b029ee70a1",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49174&size=130"
        },
        {
            id: "ab14303e0c7be525bc6a",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49175&size=130"
        },
        {
            id: "b525210f1d4af414ad5b",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49176&size=130"
        },
        {
            id: "6902fc28c06d2933707c",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=49177&size=130"
        }
    ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker49f0e435d870312e6861.png",
      price: "Miễn phí",
      name: "Én Comic",
      thumbImg: "https://zalo-api.zadn.vn/a/1/d/4/6/12065/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/a/1/d/4/6/12065/icon_pre/encomic_thumb.png",
      id: "982435e109a4e0fab9b5",
      source: "Tuấn Dũng",
      totalImage: "16 sticker",
      value: [
          {
              id: "5e8095d1a99440ca1985",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47943&size=130"
          },
          {
              id: "42158644ba01535f0a10",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47944&size=130"
          },
          {
              id: "741bb14a8d0f64513d1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47945&size=130"
          },
          {
              id: "30d3f682cac723997ad6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47946&size=130"
          },
          {
              id: "3b2cfc7dc03829667029",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47947&size=130"
          },
          {
              id: "db371b662723ce7d9732",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47948&size=130"
          },
          {
              id: "b2e173b04ff5a6abffe4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47949&size=130"
          },
          {
              id: "41e683b7bff256ac0fe3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47950&size=130"
          },
          {
              id: "caa009f135b4dcea85a5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47951&size=130"
          },
          {
              id: "9537496675239c7dc532",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47952&size=130"
          },
          {
              id: "30b8ede9d1ac38f261bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47953&size=130"
          },
          {
              id: "fe0420551c10f54eac01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47954&size=130"
          },
          {
              id: "385ee70fdb4a32146b5b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47955&size=130"
          },
          {
              id: "2e40f611ca54230a7a45",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47956&size=130"
          },
          {
              id: "a1d5788444c1ad9ff4d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47957&size=130"
          },
          {
              id: "83a259f365b68ce8d5a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47958&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker6551ca94f6d11f8f46c0.png",
      price: "Miễn phí",
      name: "Conan - Tàu Ngầm Sắt Màu Đen",
      thumbImg: "https://zalo-api.zadn.vn/3/6/6/c/9/12067/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/3/6/6/c/9/12067/icon_pre/conantaungamsatmauden_thumb.png",
      id: "a1df0e1a325fdb01824e",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "da1a394b050eec50b51f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47983&size=130"
          },
          {
              id: "d68d2adc1699ffc7a688",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47984&size=130"
          },
          {
              id: "2c9fd1ceed8b04d55d9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47985&size=130"
          },
          {
              id: "11fbefaad3ef3ab163fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47986&size=130"
          },
          {
              id: "b7d5488474c19d9fc4d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47987&size=130"
          },
          {
              id: "6d459514a951400f1940",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47988&size=130"
          },
          {
              id: "bb5c420d7e489716ce59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47989&size=130"
          },
          {
              id: "981862495e0cb752ee1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47990&size=130"
          },
          {
              id: "fba200f33cb6d5e88ca7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47991&size=130"
          },
          {
              id: "e23a166b2a2ec3709a3f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47992&size=130"
          },
          {
              id: "55c8a0999cdc75822ccd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47993&size=130"
          },
          {
              id: "f02f067e3a3bd3658a2a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47994&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker54a165655920b07ee931.png",
      price: "Miễn phí",
      name: "Phật Giáo",
      thumbImg: "https://zalo-api.zadn.vn/0/e/b/6/b/11965/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/0/e/b/6/b/11965/icon_pre/phatgiao_thumb.png",
      id: "69ac5868642d8d73d43c",
      source: "0",
      totalImage: "20 sticker",
      value: [
          {
              id: "45e3ebbcd7f93ea767e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46370&size=130"
          },
          {
              id: "3bd79488a8cd419318dc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46371&size=130"
          },
          {
              id: "abbc03e33fa6d6f88fb7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46372&size=130"
          },
          {
              id: "c4726d2d5168b836e179",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46373&size=130"
          },
          {
              id: "45daef85d3c03a9e63d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46374&size=130"
          },
          {
              id: "889923c61f83f6ddaf92",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46375&size=130"
          },
          {
              id: "dab27eed42a8abf6f2b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46376&size=130"
          },
          {
              id: "f021557e693b8065d92a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46377&size=130"
          },
          {
              id: "8f8e29d11594fccaa585",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46378&size=130"
          },
          {
              id: "08bfafe093a57afb23b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46379&size=130"
          },
          {
              id: "aacb0a9436d1df8f86c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46380&size=130"
          },
          {
              id: "461de742db0732596b16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46381&size=130"
          },
          {
              id: "017ba3249f61763f2f70",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46382&size=130"
          },
          {
              id: "126cb1338d7664283d67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46383&size=130"
          },
          {
              id: "65b0d9efe5aa0cf455bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46384&size=130"
          },
          {
              id: "9eb223ed1fa8f6f6afb9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46385&size=130"
          },
          {
              id: "ea7e54216864813ad875",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46386&size=130"
          },
          {
              id: "5e96e1c9dd8c34d26d9d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46387&size=130"
          },
          {
              id: "76b8cee7f2a21bfc42b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46388&size=130"
          },
          {
              id: "8a0a33550f10e64ebf01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46389&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker36f452306e75872bde64.png",
      price: "Miễn phí",
      name: "Sức Trẻ Bình Dương",
      thumbImg: "https://zalo-api.zadn.vn/b/6/c/8/c/12008/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/b/6/c/8/c/12008/icon_pre/suctrebinhduong_thumb.png",
      id: "e03784f3b8b651e808a7",
      source: "0",
      totalImage: "19 sticker",
      value: [
          {
              id: "b739ef64d3213a7f6330",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47060&size=130"
          },
          {
              id: "b2a3ebfed7bb3ee567aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47061&size=130"
          },
          {
              id: "04855ed8629d8bc3d28c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47062&size=130"
          },
          {
              id: "1ef845a579e090bec9f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47063&size=130"
          },
          {
              id: "353e61635d26b478ed37",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47064&size=130"
          },
          {
              id: "236f76324a77a329fa66",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47065&size=130"
          },
          {
              id: "418617db2b9ec2c09b8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47066&size=130"
          },
          {
              id: "fefca9a195e47cba25f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47067&size=130"
          },
          {
              id: "cffe9fa3a3e64ab813f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47068&size=130"
          },
          {
              id: "9870c92df5681c364579",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47069&size=130"
          },
          {
              id: "fad8a88594c07d9e24d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47070&size=130"
          },
          {
              id: "4020137d2f38c6669f29",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47071&size=130"
          },
          {
              id: "06976aca568fbfd1e69e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47072&size=130"
          },
          {
              id: "198f74d24897a1c9f886",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47073&size=130"
          },
          {
              id: "3720597d65388c66d529",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47074&size=130"
          },
          {
              id: "22314d6c71299877c138",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47075&size=130"
          },
          {
              id: "d050b80d84486d163459",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47076&size=130"
          },
          {
              id: "002c69715534bc6ae525",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47077&size=130"
          },
          {
              id: "e76c8d31b174582a0165",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47078&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerce24b3e08fa566fb3fb4.png",
      price: "Miễn phí",
      name: "Quby ",
      thumbImg: "https://zalo-api.zadn.vn/4/a/c/5/a/12017/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/4/a/c/5/a/12017/icon_pre/marshmallowboy_thumb.png",
      id: "60251de121a4c8fa91b5",
      source: "LIVTORRESEC",
      totalImage: "27 sticker",
      value: [
          {
              id: "3e13ca41f6041f5a4615",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47224&size=130"
          },
          {
              id: "4eb8bbea87af6ef137be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47225&size=130"
          },
          {
              id: "cb023d500115e84bb104",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47226&size=130"
          },
          {
              id: "24a0d3f2efb706e95fa6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47227&size=130"
          },
          {
              id: "30e4c0b6fcf315ad4ce2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47228&size=130"
          },
          {
              id: "f4e605b439f1d0af89e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47229&size=130"
          },
          {
              id: "45ecb7be8bfb62a53bea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47230&size=130"
          },
          {
              id: "0ee4fdb6c1f328ad71e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47231&size=130"
          },
          {
              id: "d768db3ae77f0e21576e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47232&size=130"
          },
          {
              id: "323b3f69032cea72b33d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47233&size=130"
          },
          {
              id: "168918db249ecdc0948f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47234&size=130"
          },
          {
              id: "63e46cb650f3b9ade0e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47235&size=130"
          },
          {
              id: "43904bc277879ed9c796",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47236&size=130"
          },
          {
              id: "4b2242707e35976bce24",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47237&size=130"
          },
          {
              id: "16271c752030c96e9021",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47238&size=130"
          },
          {
              id: "8cdb8789bbcc52920bdd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47239&size=130"
          },
          {
              id: "be8ebadc86996fc73688",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47240&size=130"
          },
          {
              id: "575f520d6e488716de59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47241&size=130"
          },
          {
              id: "2a8d2cdf109af9c4a08b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47242&size=130"
          },
          {
              id: "be3db96f852a6c74353b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47243&size=130"
          },
          {
              id: "c9e3c9b1f5f41caa45e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47244&size=130"
          },
          {
              id: "be2bbf79833c6a62332d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47245&size=130"
          },
          {
              id: "63f661a45de1b4bfedf0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47246&size=130"
          },
          {
              id: "ad82aed092957bcb2284",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47247&size=130"
          },
          {
              id: "c7cedb9ce7d90e8757c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47248&size=130"
          },
          {
              id: "d13dcc6ff02a1974403b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47249&size=130"
          },
          {
              id: "e918f74acb0f22517b1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47250&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickera8f030350c70e52ebc61.png",
      price: "Miễn phí",
      name: "Mimi & Neko 5",
      thumbImg: "https://zalo-api.zadn.vn/b/e/9/2/9/12052/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/b/e/9/2/9/12052/icon_pre/mimi&neko5_thumb.png",
      id: "730eebcbd78e3ed0679f",
      source: "Minto Inc.",
      totalImage: "16 sticker",
      value: [
          {
              id: "0d7bfd2bc16e2830717f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47740&size=130"
          },
          {
              id: "4935b86584206d7e3431",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47741&size=130"
          },
          {
              id: "d68b24db189ef1c0a88f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47742&size=130"
          },
          {
              id: "e26011302d75c42b9d64",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47743&size=130"
          },
          {
              id: "455b490b754e9c10c55f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47744&size=130"
          },
          {
              id: "4efe43ae7feb96b5cffa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47745&size=130"
          },
          {
              id: "af7ba12b9d6e74302d7f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47746&size=130"
          },
          {
              id: "278a28da149ffdc1a48e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47747&size=130"
          },
          {
              id: "d56fdd3fe17a0824516b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47748&size=130"
          },
          {
              id: "ed9fe4cfd88a31d4689b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47749&size=130"
          },
          {
              id: "40b04ae076a59ffbc6b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47750&size=130"
          },
          {
              id: "8a9a81cabd8f54d10d9e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47751&size=130"
          },
          {
              id: "836e873ebb7b52250b6a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47752&size=130"
          },
          {
              id: "a112a442980771592816",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47753&size=130"
          },
          {
              id: "524154116854810ad845",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47754&size=130"
          },
          {
              id: "fd7ffa2fc66a2f34767b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47755&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker3f02a3c79f8276dc2f93.png",
      price: "Miễn phí",
      name: "Snubby Story 1",
      thumbImg: "https://zalo-api.zadn.vn/5/e/4/d/f/12048/preview/440 × 440.png",
      iconUrl: "https://zalo-api.zadn.vn/5/e/4/d/f/12048/icon_pre/snubbystory1_thumb.png",
      id: "3866a4a398e671b828f7",
      source: "NEOT",
      totalImage: "30 sticker",
      value: [
          {
              id: "862738770432ed6cb423",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47666&size=130"
          },
          {
              id: "95172a471602ff5ca613",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47667&size=130"
          },
          {
              id: "bc0b045b381ed140880f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47668&size=130"
          },
          {
              id: "8b5032000e45e71bbe54",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47669&size=130"
          },
          {
              id: "a4a81ef822bdcbe392ac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47670&size=130"
          },
          {
              id: "338388d3b4965dc80487",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47671&size=130"
          },
          {
              id: "1d9ba9cb958e7cd0259f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47672&size=130"
          },
          {
              id: "1af7afa793e27abc23f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47673&size=130"
          },
          {
              id: "329584c5b88051de0891",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47674&size=130"
          },
          {
              id: "7a4ccd1cf15918074148",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47675&size=130"
          },
          {
              id: "4ebffeefc2aa2bf472bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47676&size=130"
          },
          {
              id: "7d52cc02f04719194056",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47677&size=130"
          },
          {
              id: "bcc30e9332d6db8882c7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47678&size=130"
          },
          {
              id: "98772b271762fe3ca773",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47679&size=130"
          },
          {
              id: "0b57c707fb42121c4b53",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47680&size=130"
          },
          {
              id: "27f4eaa4d6e13fbf66f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47681&size=130"
          },
          {
              id: "81094f59731c9a42c30d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47682&size=130"
          },
          {
              id: "2bf4e4a4d8e131bf68f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47683&size=130"
          },
          {
              id: "c17f092f356adc34857b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47684&size=130"
          },
          {
              id: "9a1053406f05865bdf14",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47685&size=130"
          },
          {
              id: "59be93eeafab46f51fba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47686&size=130"
          },
          {
              id: "a43a6f6a532fba71e33e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47687&size=130"
          },
          {
              id: "de071a572612cf4c9603",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47688&size=130"
          },
          {
              id: "a69063c05f85b6dbef94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47689&size=130"
          },
          {
              id: "da041c542011c94f9000",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47690&size=130"
          },
          {
              id: "1181d6d1ea9403ca5a85",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47691&size=130"
          },
          {
              id: "c0d700873cc2d59c8cd3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47692&size=130"
          },
          {
              id: "219ee0cedc8b35d56c9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47693&size=130"
          },
          {
              id: "59f29ba2a7e74eb917f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47694&size=130"
          },
          {
              id: "0bf4c8a4f4e11dbf44f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47695&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc1f3b5378972602c3963.png",
      price: "Miễn phí",
      name: "Meme Boss Cat",
      thumbImg: "https://zalo-api.zadn.vn/5/f/d/2/5/12024/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/5/f/d/2/5/12024/icon_pre/memebosscat_thumb.png",
      id: "021e76da4a9fa3c1fa8e",
      source: "Wang Xiao-Mei",
      totalImage: "16 sticker",
      value: [
          {
              id: "1729597b653e8c60d52f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47298&size=130"
          },
          {
              id: "b17cfe2ec26b2b35727a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47299&size=130"
          },
          {
              id: "3de775b549f0a0aef9e1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47300&size=130"
          },
          {
              id: "5aba13e82fadc6f39fbc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47301&size=130"
          },
          {
              id: "b03ffa6dc6282f767639",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47302&size=130"
          },
          {
              id: "4bb600e43ca1d5ff8cb0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47303&size=130"
          },
          {
              id: "2cfa68a854edbdb3e4fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47304&size=130"
          },
          {
              id: "83e7c6b5faf013ae4ae1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47305&size=130"
          },
          {
              id: "705a36080a4de313ba5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47306&size=130"
          },
          {
              id: "efafa8fd94b87de624a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47307&size=130"
          },
          {
              id: "5bd61b8427c1ce9f97d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47308&size=130"
          },
          {
              id: "fa70bb2287676e393776",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47309&size=130"
          },
          {
              id: "8d9ecfccf3891ad74398",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47310&size=130"
          },
          {
              id: "b9c9fa9bc6de2f8076cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47311&size=130"
          },
          {
              id: "c9519503a94640181957",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47312&size=130"
          },
          {
              id: "84fad9a8e5ed0cb355fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47313&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker8a5e179b2bdec2809bcf.png",
      price: "Miễn phí",
      name: "ZOOKiZ Lớp Zu, Kiss",
      thumbImg: "https://zalo-api.zadn.vn/f/d/1/0/9/12049/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/f/d/1/0/9/12049/icon_pre/zookizlopzu,kiss_thumb.png",
      id: "ffb162745e31b76fee20",
      source: "ZOOKiZ",
      totalImage: "12 sticker",
      value: [
          {
              id: "73c8af9893dd7a8323cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47696&size=130"
          },
          {
              id: "e1213c710034e96ab025",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47697&size=130"
          },
          {
              id: "f3482d18115df803a14c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47698&size=130"
          },
          {
              id: "a6b979e945acacf2f5bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47699&size=130"
          },
          {
              id: "4bb493e4afa146ff1fb0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47700&size=130"
          },
          {
              id: "5a9b83cbbf8e56d00f9f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47701&size=130"
          },
          {
              id: "f67c2c2c1069f937a078",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47702&size=130"
          },
          {
              id: "f7412c111054f90aa045",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47703&size=130"
          },
          {
              id: "bde469b455f1bcafe5e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47704&size=130"
          },
          {
              id: "58398d69b12c5872013d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47705&size=130"
          },
          {
              id: "e8563e060243eb1db252",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47706&size=130"
          },
          {
              id: "44f693a6afe346bd1ff2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47707&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickercb1b67de5b9bb2c5eb8a.png",
      price: "Miễn phí",
      name: "Truyện Cổ Remix",
      thumbImg: "https://zalo-api.zadn.vn/a/1/5/9/b/12064/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/a/1/5/9/b/12064/icon_pre/truyencoremix_thumb.png",
      id: "4662eaa7d6e23fbc66f3",
      source: "Media Z",
      totalImage: "15 sticker",
      value: [
          {
              id: "1395a7c49b8172df2b90",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47928&size=130"
          },
          {
              id: "4adbff8ac3cf2a9173de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47929&size=130"
          },
          {
              id: "85ce339f0fdae684bfcb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47930&size=130"
          },
          {
              id: "6377d426e863013d5872",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47931&size=130"
          },
          {
              id: "0de7bdb681f368ad31e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47932&size=130"
          },
          {
              id: "80ea31bb0dfee4a0bdef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47933&size=130"
          },
          {
              id: "3fa98df8b1bd58e301ac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47934&size=130"
          },
          {
              id: "75a7c6f6fab313ed4aa2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47935&size=130"
          },
          {
              id: "143ad86be42e0d70543f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47936&size=130"
          },
          {
              id: "0747ca16f6531f0d4642",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47937&size=130"
          },
          {
              id: "0b51c500f945101b4954",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47938&size=130"
          },
          {
              id: "65eeaabf96fa7fa426eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47939&size=130"
          },
          {
              id: "ee7f262e1a6bf335aa7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47940&size=130"
          },
          {
              id: "2ea3e7f2dbb732e96ba6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47941&size=130"
          },
          {
              id: "bc3076614a24a37afa35",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47942&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickercfa2596765228c7cd533.png",
      price: "Miễn phí",
      name: "Doraemon Sợ Gì Thử Thách",
      thumbImg: "https://zalo-api.zadn.vn/d/e/5/6/5/12058/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/d/e/5/6/5/12058/icon_pre/doraemonsogithuthach_thumb.png",
      id: "102b86eebaab53f50aba",
      source: "0",
      totalImage: "16 sticker",
      value: [
          {
              id: "04f168a154e4bdbae4f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47840&size=130"
          },
          {
              id: "d3bdbeed82a86bf632b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47841&size=130"
          },
          {
              id: "bc16d246ee03075d5e12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47842&size=130"
          },
          {
              id: "4c0723571f12f64caf03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47843&size=130"
          },
          {
              id: "a0e7c8b7f4f21dac44e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47844&size=130"
          },
          {
              id: "4f9426c41a81f3dfaa90",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47845&size=130"
          },
          {
              id: "871ded4dd10838566119",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47846&size=130"
          },
          {
              id: "92eaf9bac5ff2ca175ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47847&size=130"
          },
          {
              id: "41682538197df023a96c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47848&size=130"
          },
          {
              id: "eb968ec6b2835bdd0292",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47849&size=130"
          },
          {
              id: "68450e153250db0e8241",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47850&size=130"
          },
          {
              id: "bb1fdc4fe00a0954501b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47851&size=130"
          },
          {
              id: "1f847fd44391aacff380",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47852&size=130"
          },
          {
              id: "19af78ff44baade4f4ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47853&size=130"
          },
          {
              id: "f0e292b2aef747a91ee6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47854&size=130"
          },
          {
              id: "5eba3dea01afe8f1b1be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47855&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerca2462e15ea4b7faeeb5.png",
      price: "Miễn phí",
      name: "Life At VNG",
      thumbImg: "https://zalo-api.zadn.vn/7/d/5/0/c/12068/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/7/d/5/0/c/12068/icon_pre/lifeatvng_thumb.png",
      id: "a99001553d10d44e8d01",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "56efa1be9dfb74a52dea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47995&size=130"
          },
          {
              id: "8b8a7bdb479eaec0f78f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47996&size=130"
          },
          {
              id: "5bc1aa9096d57f8b26c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47997&size=130"
          },
          {
              id: "19d8eb89d7cc3e9267dd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47998&size=130"
          },
          {
              id: "fc6c0f3d3378da268369",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47999&size=130"
          },
          {
              id: "b1e2bdb381f668a831e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48000&size=130"
          },
          {
              id: "ff47f216ce53270d7e42",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48001&size=130"
          },
          {
              id: "b457ba0686436f1d3652",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48002&size=130"
          },
          {
              id: "73137c424007a959f016",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48003&size=130"
          },
          {
              id: "603a686b542ebd70e43f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48004&size=130"
          },
          {
              id: "3d0c345d0818e146b809",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48005&size=130"
          },
          {
              id: "c215c844f4011d5f4410",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48006&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrstickeredf4483174749d2ac465.png",
      price: "Miễn phí",
      name: "Vani Land",
      thumbImg: "https://zalo-api.zadn.vn/f/4/f/c/e/12073/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/f/4/f/c/e/12073/icon_pre/vaniland_icon.png",
      id: "c24167845bc1b29febd0",
      source: "0",
      totalImage: "16 sticker",
      value: [
          {
              id: "1b24587564308d6ed421",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48079&size=130"
          },
          {
              id: "415a1d0b214ec810915f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48080&size=130"
          },
          {
              id: "d36b8e3ab27f5b21026e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48081&size=130"
          },
          {
              id: "268678d74492adccf483",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48082&size=130"
          },
          {
              id: "65253a740631ef6fb620",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48083&size=130"
          },
          {
              id: "13e94bb877fd9ea3c7ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48084&size=130"
          },
          {
              id: "ea1bb34a8f0f66513f1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48085&size=130"
          },
          {
              id: "3cde668f5acab394eadb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48086&size=130"
          },
          {
              id: "c0ed9bbca7f94ea717e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48087&size=130"
          },
          {
              id: "58ae0cff30bad9e480ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48088&size=130"
          },
          {
              id: "89c3dc92e0d7098950c6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48089&size=130"
          },
          {
              id: "98aacefbf2be1be042af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48090&size=130"
          },
          {
              id: "31ee66bf5afab3a4eaeb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48091&size=130"
          },
          {
              id: "f702a7539b1672482b07",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48092&size=130"
          },
          {
              id: "8ca4ddf5e1b008ee51a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48093&size=130"
          },
          {
              id: "38cb6a9a56dfbf81e6ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48094&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker1385ae4092057b5b2214.png",
      price: "Miễn phí",
      name: "Cá Văn Phòng 2",
      thumbImg: "https://zalo-api.zadn.vn/7/c/e/c/f/12081/preview/240 x 240.png",
      iconUrl: "https://zalo-api.zadn.vn/7/c/e/c/f/12081/icon_pre/cavanphong2_thumb.png",
      id: "7d99c05cfc1915474c08",
      source: "0",
      totalImage: "20 sticker",
      value: [
          {
              id: "a9f811ae2debc4b59dfa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48180&size=130"
          },
          {
              id: "bb1102473e02d75c8e13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48181&size=130"
          },
          {
              id: "b8cc029a3edfd7818ece",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48182&size=130"
          },
          {
              id: "4b1ff049cc0c25527c1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48183&size=130"
          },
          {
              id: "f62042767e33976dce22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48184&size=130"
          },
          {
              id: "819734c10884e1dab895",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48185&size=130"
          },
          {
              id: "88603e360273eb2db262",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48186&size=130"
          },
          {
              id: "0511b2478e02675c3e13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48187&size=130"
          },
          {
              id: "950f2559191cf042a90d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48188&size=130"
          },
          {
              id: "ba7d0b2b376ede30877f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48189&size=130"
          },
          {
              id: "cfd17d8741c2a89cf1d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48190&size=130"
          },
          {
              id: "b15202043e41d71f8e50",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48191&size=130"
          },
          {
              id: "b41d784b440ead50f41f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48192&size=130"
          },
          {
              id: "533d9e6ba22e4b70123f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48193&size=130"
          },
          {
              id: "eb4025161953f00da942",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48194&size=130"
          },
          {
              id: "e7b128e714a2fdfca4b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48195&size=130"
          },
          {
              id: "0be8c3befffb16a54fea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48196&size=130"
          },
          {
              id: "c4980dce318bd8d5819a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48197&size=130"
          },
          {
              id: "d44b1e1d2258cb069249",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48198&size=130"
          },
          {
              id: "fa4231140d51e40fbd40",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48199&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker0c65aaa096e57fbb26f4.png",
      price: "Miễn phí",
      name: "Cột Sống ZOOKiZ",
      thumbImg: "https://zalo-api.zadn.vn/4/4/d/8/8/12074/preview/240 x 240.png",
      iconUrl: "https://zalo-api.zadn.vn/4/4/d/8/8/12074/icon_pre/cotsongzookiz_thumb.png",
      id: "e8a44e6172249b7ac235",
      source: "0",
      totalImage: "16 sticker",
      value: [
          {
              id: "89a9daf8e6bd0fe356ac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48095&size=130"
          },
          {
              id: "d5efb9be85fb6ca535ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48096&size=130"
          },
          {
              id: "53263e770232eb6cb223",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48097&size=130"
          },
          {
              id: "e3d08d81b1c4589a01d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48098&size=130"
          },
          {
              id: "881be74adb0f32516b1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48099&size=130"
          },
          {
              id: "ad6fc53ef97b1025496a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48100&size=130"
          },
          {
              id: "d8a2b1f38db664e83da7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48101&size=130"
          },
          {
              id: "b9cad39befde06805fcf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48102&size=130"
          },
          {
              id: "19d972884ecda793fedc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48103&size=130"
          },
          {
              id: "1f2a7b7b473eae60f72f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48104&size=130"
          },
          {
              id: "d7c9b2988edd67833ecc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48105&size=130"
          },
          {
              id: "b2ded48fe8ca019458db",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48106&size=130"
          },
          {
              id: "40fd27ac1be9f2b7abf8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48107&size=130"
          },
          {
              id: "e8378866b4235d7d0432",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48108&size=130"
          },
          {
              id: "1a487b19475cae02f74d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48109&size=130"
          },
          {
              id: "1fd47d8541c0a89ef1d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48110&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker4485ef40d3053a5b6314.png",
      price: "Miễn phí",
      name: "Cá Văn Phòng 1",
      thumbImg: "https://zalo-api.zadn.vn/c/3/3/3/b/12071/preview/240 x 240.png",
      iconUrl: "https://zalo-api.zadn.vn/c/3/3/3/b/12071/icon_pre/cavanphong1_thumb.png",
      id: "4e33e5f6d9b330ed69a2",
      source: "0",
      totalImage: "20 sticker",
      value: [
          {
              id: "d72cf07dcc3825667c29",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48043&size=130"
          },
          {
              id: "18fd38ac04e9edb7b4f8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48044&size=130"
          },
          {
              id: "d56af43bc87e2120786f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48045&size=130"
          },
          {
              id: "8adca88d94c87d9624d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48046&size=130"
          },
          {
              id: "340917582b1dc2439b0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48047&size=130"
          },
          {
              id: "1f6523341f71f62faf60",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48048&size=130"
          },
          {
              id: "ba7c872dbb6852360b79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48049&size=130"
          },
          {
              id: "086836390a7ce322ba6d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48050&size=130"
          },
          {
              id: "91a8aef992bc7be222ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48051&size=130"
          },
          {
              id: "9f09a7589b1d72432b0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48052&size=130"
          },
          {
              id: "e8d8d189edcc04925ddd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48053&size=130"
          },
          {
              id: "a1cc9b9da7d84e8617c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48054&size=130"
          },
          {
              id: "034738160453ed0db442",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48055&size=130"
          },
          {
              id: "629556c46a8183dfda90",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48056&size=130"
          },
          {
              id: "db9deeccd2893bd76298",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48057&size=130"
          },
          {
              id: "8bfbbdaa81ef68b131fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48058&size=130"
          },
          {
              id: "559a62cb5e8eb7d0ee9f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48059&size=130"
          },
          {
              id: "bc348c65b020597e0031",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48060&size=130"
          },
          {
              id: "e662d733eb7602285b67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48061&size=130"
          },
          {
              id: "2f681d39217cc822916d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48062&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrsticker0e408f85b3c05a9e03d1.png",
      price: "Miễn phí",
      name: "Zameo Rốn Lồi",
      thumbImg: "https://zalo-api.zadn.vn/f/b/5/9/8/12045/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/f/b/5/9/8/12045/icon_pre/zameoronloi_thumb.png",
      id: "11809045ac00455e1c11",
      source: "0",
      totalImage: "19 sticker",
      value: [
          {
              id: "02da70894ccca592fcdd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47614&size=130"
          },
          {
              id: "05b376e04aa5a3fbfab4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47615&size=130"
          },
          {
              id: "a8cb249b18def180a8cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47616&size=130"
          },
          {
              id: "d6765b2667638e3dd772",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47617&size=130"
          },
          {
              id: "3039be69822c6b72323d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47618&size=130"
          },
          {
              id: "90af1fff23bacae493ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47619&size=130"
          },
          {
              id: "d08e58de649b8dc5d48a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47620&size=130"
          },
          {
              id: "5721de71e2340b6a5225",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47621&size=130"
          },
          {
              id: "185a920aae4f47111e5e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47622&size=130"
          },
          {
              id: "71edfabdc6f82fa676e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47623&size=130"
          },
          {
              id: "5778d328ef6d06335f7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47624&size=130"
          },
          {
              id: "7bb0fde0c1a528fb71b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47626&size=130"
          },
          {
              id: "bf5138010444ed1ab455",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47627&size=130"
          },
          {
              id: "ee9d6ecd5288bbd6e299",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47628&size=130"
          },
          {
              id: "6f0aee5ad21f3b41620e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47629&size=130"
          },
          {
              id: "d8095a59661c8f42d60d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47630&size=130"
          },
          {
              id: "b76034300875e12bb864",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47631&size=130"
          },
          {
              id: "f39a6fca538fbad1e39e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47632&size=130"
          },
          {
              id: "1d7c802cbc6955370c78",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47633&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker93a414612824c17a9835.png",
      price: "Miễn phí",
      name: "Tết Miêu 2023",
      thumbImg: "https://zalo-api.zadn.vn/4/2/2/f/0/12043/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/4/2/2/f/0/12043/icon_pre/tetmieu2023_thumb.png",
      id: "ee7f69ba55ffbca1e5ee",
      source: "User",
      totalImage: "16 sticker",
      value: [
          {
              id: "62a434f708b2e1ecb8a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47578&size=130"
          },
          {
              id: "edc4ba9786d26f8c36c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47579&size=130"
          },
          {
              id: "7f592f0a134ffa11a35e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47580&size=130"
          },
          {
              id: "479316c02a85c3db9a94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47581&size=130"
          },
          {
              id: "8678d42be86e0130587f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47582&size=130"
          },
          {
              id: "1e294d7a713f9861c12e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47583&size=130"
          },
          {
              id: "6f5103023f47d6198f56",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47584&size=130"
          },
          {
              id: "7b1b16482a0dc3539a1c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47585&size=130"
          },
          {
              id: "840aea59d61c3f42660d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47586&size=130"
          },
          {
              id: "ef608033bc7655280c67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47587&size=130"
          },
          {
              id: "26a34ef072b59bebc2a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47588&size=130"
          },
          {
              id: "0aaf63fc5fb9b6e7efa8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47589&size=130"
          },
          {
              id: "b20ed85de4180d465409",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47590&size=130"
          },
          {
              id: "8686edd5d19038ce6181",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47591&size=130"
          },
          {
              id: "0f196b4a570fbe51e71e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47592&size=130"
          },
          {
              id: "7fd01a8326c6cf9896d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47593&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker86cd0508394dd013895c.png",
      price: "Miễn phí",
      name: "Bé Tra",
      thumbImg: "https://zalo-api.zadn.vn/e/9/9/2/9/12047/preview/440 X 440.png",
      iconUrl: "https://zalo-api.zadn.vn/e/9/9/2/9/12047/icon_pre/betra_thumb.png",
      id: "ddbb5e7e623b8b65d22a",
      source: "",
      totalImage: "16 sticker",
      value: [
          {
              id: "26688838b47d5d23046c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47650&size=130"
          },
          {
              id: "38a197f1abb442ea1ba5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47651&size=130"
          },
          {
              id: "2db685e6b9a350fd09b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47652&size=130"
          },
          {
              id: "efe946b97afc93a2caed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47653&size=130"
          },
          {
              id: "cc1666465a03b35dea12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47654&size=130"
          },
          {
              id: "a7b40ce430a1d9ff80b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47655&size=130"
          },
          {
              id: "05a5a1f59db074ee2da1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47656&size=130"
          },
          {
              id: "a73d026d3e28d7768e39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47657&size=130"
          },
          {
              id: "456ee33edf7b36256f6a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47658&size=130"
          },
          {
              id: "80ef27bf1bfaf2a4abeb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47659&size=130"
          },
          {
              id: "9b003b500715ee4bb704",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47660&size=130"
          },
          {
              id: "adf30ca330e6d9b880f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47661&size=130"
          },
          {
              id: "25a187f1bbb452ea0ba5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47662&size=130"
          },
          {
              id: "6155c205fe40171e4e51",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47663&size=130"
          },
          {
              id: "15c4a99495d17c8f25c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47664&size=130"
          },
          {
              id: "d1506c005045b91be054",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47665&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerfa8d5448680d8153d81c.png",
      price: "Miễn phí",
      name: "Fluffy Puppy",
      thumbImg: "https://zalo-api.zadn.vn/0/a/9/4/e/12066/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/0/a/9/4/e/12066/icon_pre/fluffypuppy_thumb.png",
      id: "e9b547707b35926bcb24",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "8d2856796a3c8362da2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47959&size=130"
          },
          {
              id: "503c846db82851760839",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47960&size=130"
          },
          {
              id: "e9cb3c9a00dfe981b0ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47961&size=130"
          },
          {
              id: "105fc60efa4b13154a5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47962&size=130"
          },
          {
              id: "3ab8ede9d1ac38f261bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47963&size=130"
          },
          {
              id: "63f5b3a48fe166bf3ff0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47964&size=130"
          },
          {
              id: "7d0aac5b901e7940200f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47965&size=130"
          },
          {
              id: "1019c248fe0d17534e1c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47966&size=130"
          },
          {
              id: "2254f105cd40241e7d51",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47967&size=130"
          },
          {
              id: "3bdad78bebce02905bdf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47968&size=130"
          },
          {
              id: "89da648b58ceb190e8df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47969&size=130"
          },
          {
              id: "bf1851496d0c8452dd1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47970&size=130"
          },
          {
              id: "f91016412a04c35a9a15",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47971&size=130"
          },
          {
              id: "606e883fb47a5d24046b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47972&size=130"
          },
          {
              id: "50d4b98585c06c9e35d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47973&size=130"
          },
          {
              id: "8c2b667a5a3fb361ea2e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47974&size=130"
          },
          {
              id: "dacc319d0dd8e486bdc9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47975&size=130"
          },
          {
              id: "0b4def1cd3593a076348",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47976&size=130"
          },
          {
              id: "909775c64983a0ddf992",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47977&size=130"
          },
          {
              id: "4447a2169e53770d2e42",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47978&size=130"
          },
          {
              id: "b62a517b6d3e8460dd2f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47979&size=130"
          },
          {
              id: "8d5c6d0d5148b816e159",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47980&size=130"
          },
          {
              id: "45d4a48598c0719e28d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47981&size=130"
          },
          {
              id: "17ddf58cc9c9209779d8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47982&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker2872bdb781f268ac31e3.png",
      price: "Miễn phí",
      name: "ZOOKiZ Pang Xù",
      thumbImg: "https://zalo-api.zadn.vn/8/b/9/1/0/12057/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/8/b/9/1/0/12057/icon_pre/zookizpangxu_thumb.png",
      id: "3d11a8d494917dcf2480",
      source: "ZOOKiZ ",
      totalImage: "24 sticker",
      value: [
          {
              id: "c7678337bf72562c0f63",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47816&size=130"
          },
          {
              id: "403c056c3929d0778938",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47817&size=130"
          },
          {
              id: "6efe28ae14ebfdb5a4fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47818&size=130"
          },
          {
              id: "056a423a7e7f9721ce6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47819&size=130"
          },
          {
              id: "284668165453bd0de442",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47820&size=130"
          },
          {
              id: "fc60bd308175682b3164",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47821&size=130"
          },
          {
              id: "6a6e283e147bfd25a46a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47822&size=130"
          },
          {
              id: "a43be76bdb2e32706b3f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47823&size=130"
          },
          {
              id: "ae6af23ace7f27217e6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47824&size=130"
          },
          {
              id: "ab6af63aca7f23217a6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47825&size=130"
          },
          {
              id: "efffb1af8dea64b43dfb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47826&size=130"
          },
          {
              id: "b159ee09d24c3b12625d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47827&size=130"
          },
          {
              id: "059c5dcc618988d7d198",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47828&size=130"
          },
          {
              id: "ca3e936eaf2b46751f3a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47829&size=130"
          },
          {
              id: "c56a9f3aa37f4a21136e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47830&size=130"
          },
          {
              id: "a182fad2c6972fc97686",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47831&size=130"
          },
          {
              id: "cab79ee7a2a24bfc12b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47832&size=130"
          },
          {
              id: "c7dc928caec947971ed8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47833&size=130"
          },
          {
              id: "53790529396cd032897d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47834&size=130"
          },
          {
              id: "04c853986fdd8683dfcc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47835&size=130"
          },
          {
              id: "f31fa34f9f0a76542f1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47836&size=130"
          },
          {
              id: "c5769426a863413d1872",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47837&size=130"
          },
          {
              id: "b449e619da5c33026a4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47838&size=130"
          },
          {
              id: "27ce749e48dba185f8ca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47839&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker64af3b6b072eee70b73f.png",
      price: "Miễn phí",
      name: "Vì Một Việt Nam Xanh",
      thumbImg: "https://zalo-api.zadn.vn/4/6/d/a/9/11987/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/4/6/d/a/9/11987/icon_pre/vimotvietnamxanh_thumb.png",
      id: "3edf611b5d5eb400ed4f",
      source: "0",
      totalImage: "16 sticker",
      value: [
          {
              id: "42484a1476519f0fc640",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46724&size=130"
          },
          {
              id: "45f94ca570e099bec0f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46725&size=130"
          },
          {
              id: "505b5a0766428f1cd653",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46726&size=130"
          },
          {
              id: "222929751530fc6ea521",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46727&size=130"
          },
          {
              id: "ea44ee18d25d3b03624c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46728&size=130"
          },
          {
              id: "d506d05aec1f05415c0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46729&size=130"
          },
          {
              id: "09be0fe233a7daf983b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46730&size=130"
          },
          {
              id: "887f8f23b3665a380377",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46731&size=130"
          },
          {
              id: "4d254d79713c9862c12d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46732&size=130"
          },
          {
              id: "2bf82aa416e1ffbfa6f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46733&size=130"
          },
          {
              id: "5e2c5c706035896bd024",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46734&size=130"
          },
          {
              id: "c2d2c18efdcb14954dda",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46735&size=130"
          },
          {
              id: "a669ba3586706f2e3661",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46736&size=130"
          },
          {
              id: "f410e94cd5093c576518",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46737&size=130"
          },
          {
              id: "bc2ca2709e35776b2e24",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46738&size=130"
          },
          {
              id: "46f959a565e08cbed5f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46739&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerff796ebc52f9bba7e2e8.png",
      price: "Miễn phí",
      name: "I Am Binie",
      thumbImg: "https://zalo-api.zadn.vn/2/5/d/0/a/12061/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/2/5/d/0/a/12061/icon_pre/iambinie_thumb.png",
      id: "b3fe223b1e7ef720ae6f",
      source: "MediaZ",
      totalImage: "16 sticker",
      value: [
          {
              id: "88b308e234a7ddf984b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47884&size=130"
          },
          {
              id: "7dacfcfdc0b829e670a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47885&size=130"
          },
          {
              id: "f7a775f649b3a0edf9a2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47886&size=130"
          },
          {
              id: "b02633770f32e66cbf23",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47887&size=130"
          },
          {
              id: "20c2bc9380d6698830c7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47888&size=130"
          },
          {
              id: "3801a5509915704b2904",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47889&size=130"
          },
          {
              id: "294eb71f8b5a62043b4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47890&size=130"
          },
          {
              id: "7196eec7d2823bdc6293",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47891&size=130"
          },
          {
              id: "5405cc54f011194f4000",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47892&size=130"
          },
          {
              id: "d044491575509c0ec541",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47893&size=130"
          },
          {
              id: "1ec78496b8d3518d08c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47894&size=130"
          },
          {
              id: "972c0c7d3038d9668029",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47895&size=130"
          },
          {
              id: "ba932ec21287fbd9a296",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47896&size=130"
          },
          {
              id: "09779c26a063493d1072",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47897&size=130"
          },
          {
              id: "149682c7be8257dc0e93",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47898&size=130"
          },
          {
              id: "6bf8fca9c0ec29b270fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47899&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7307e4c2d88731d96896.png",
      price: "Miễn phí",
      name: "ZCá Mặp Zagoo",
      thumbImg: "https://zalo-api.zadn.vn/d/9/5/9/3/12059/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/d/9/5/9/3/12059/icon_pre/zcamapzagoo_thumb.png",
      id: "2bb3bc768033696d3022",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "b2adcefdf2b81be642a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47856&size=130"
          },
          {
              id: "cc4bb11b8d5e64003d4f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47857&size=130"
          },
          {
              id: "8f88f1d8cd9d24c37d8c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47858&size=130"
          },
          {
              id: "75c80a9836dddf8386cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47859&size=130"
          },
          {
              id: "d73aaf6a932f7a71233e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47860&size=130"
          },
          {
              id: "ec459515a950400e1941",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47861&size=130"
          },
          {
              id: "35354f6573209a7ec331",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47862&size=130"
          },
          {
              id: "0ed3758349c6a098f9d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47863&size=130"
          },
          {
              id: "93b3e7e3dba632f86bb7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47864&size=130"
          },
          {
              id: "ed1c984ca4094d571418",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47865&size=130"
          },
          {
              id: "687b1e2b226ecb30927f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47866&size=130"
          },
          {
              id: "c7ddb08d8cc865963cd9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47867&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker3efe513a6d7f8421dd6e.png",
      price: "Miễn phí",
      name: "Mèo Méo Meo",
      thumbImg: "https://zalo-api.zadn.vn/d/2/a/9/a/12003/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/d/2/a/9/a/12003/icon_pre/meomeomeo_thumb.png",
      id: "e5c88a0cb6495f170658",
      source: "\bTrixie",
      totalImage: "28 sticker",
      value: [
        {
            id: "b2e34abe76fb9fa5c6ea",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46964&size=130"
        },
        {
            id: "8a1a73474f02a65cff13",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46965&size=130"
        },
        {
            id: "5046aa1b965e7f00264f",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46966&size=130"
        },
        {
            id: "66ca9d97a1d2488c11c3",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46967&size=130"
        },
        {
            id: "2a80dedde2980bc65289",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46968&size=130"
        },
        {
            id: "b37546287a6d9333ca7c",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46969&size=130"
        },
        {
            id: "3e57c80af44f1d11445e",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46970&size=130"
        },
        {
            id: "c3f934a408e1e1bfb8f0",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46971&size=130"
        },
        {
            id: "4d16bd4b810e6850311f",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46972&size=130"
        },
        {
            id: "cfd53e8802cdeb93b2dc",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46973&size=130"
        },
        {
            id: "9f8c6dd15194b8cae185",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46974&size=130"
        },
        {
            id: "0412f74fcb0a22547b1b",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46975&size=130"
        },
        {
            id: "10f31cae20ebc9b590fa",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46976&size=130"
        },
        {
            id: "51045c59601c8942d00d",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46977&size=130"
        },
        {
            id: "3f3a31670d22e47cbd33",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46978&size=130"
        },
        {
            id: "dcf5d3a8efed06b35ffc",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46979&size=130"
        },
        {
            id: "dfd6d78bebce02905bdf",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46980&size=130"
        },
        {
            id: "0ba202ff3ebad7e48eab",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46981&size=130"
        },
        {
            id: "fccff692cad723897ac6",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46982&size=130"
        },
        {
            id: "533c586164248d7ad435",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46983&size=130"
        },
        {
            id: "d977dd2ae16f0831517e",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46984&size=130"
        },
        {
            id: "bce9b9b485f16caf35e0",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46985&size=130"
        },
        {
            id: "02a304fe38bbd1e588aa",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46986&size=130"
        },
        {
            id: "9270952da96840361979",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46987&size=130"
        },
        {
            id: "9703975eab1b42451b0a",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46988&size=130"
        },
        {
            id: "5a3f5b6267278e79d736",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46989&size=130"
        },
        {
            id: "eb49e914d5513c0f6540",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46990&size=130"
        },
        {
            id: "3ae639bb05feeca0b5ef",
            url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46991&size=130"
        }
    ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickera9a8266d1a28f376aa39.png",
      price: "Miễn phí",
      name: "ZOOKiZ Xuân Xập Xình",
      thumbImg: "https://zalo-api.zadn.vn/8/a/9/e/0/12035/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/8/a/9/e/0/12035/icon_pre/zookizxuanxapxinh_thumb.png",
      id: "c1df4e1a725f9b01c24e",
      source: "ZOOKiZ",
      totalImage: "12 sticker",
      value: [
          {
              id: "b2e34abe76fb9fa5c6ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46964&size=130"
          },
          {
              id: "8a1a73474f02a65cff13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46965&size=130"
          },
          {
              id: "5046aa1b965e7f00264f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46966&size=130"
          },
          {
              id: "66ca9d97a1d2488c11c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46967&size=130"
          },
          {
              id: "2a80dedde2980bc65289",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46968&size=130"
          },
          {
              id: "b37546287a6d9333ca7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46969&size=130"
          },
          {
              id: "3e57c80af44f1d11445e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46970&size=130"
          },
          {
              id: "c3f934a408e1e1bfb8f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46971&size=130"
          },
          {
              id: "4d16bd4b810e6850311f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46972&size=130"
          },
          {
              id: "cfd53e8802cdeb93b2dc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46973&size=130"
          },
          {
              id: "9f8c6dd15194b8cae185",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46974&size=130"
          },
          {
              id: "0412f74fcb0a22547b1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46975&size=130"
          },
          {
              id: "10f31cae20ebc9b590fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46976&size=130"
          },
          {
              id: "51045c59601c8942d00d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46977&size=130"
          },
          {
              id: "3f3a31670d22e47cbd33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46978&size=130"
          },
          {
              id: "dcf5d3a8efed06b35ffc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46979&size=130"
          },
          {
              id: "dfd6d78bebce02905bdf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46980&size=130"
          },
          {
              id: "0ba202ff3ebad7e48eab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46981&size=130"
          },
          {
              id: "fccff692cad723897ac6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46982&size=130"
          },
          {
              id: "533c586164248d7ad435",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46983&size=130"
          },
          {
              id: "d977dd2ae16f0831517e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46984&size=130"
          },
          {
              id: "bce9b9b485f16caf35e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46985&size=130"
          },
          {
              id: "02a304fe38bbd1e588aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46986&size=130"
          },
          {
              id: "9270952da96840361979",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46987&size=130"
          },
          {
              id: "9703975eab1b42451b0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46988&size=130"
          },
          {
              id: "5a3f5b6267278e79d736",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46989&size=130"
          },
          {
              id: "eb49e914d5513c0f6540",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46990&size=130"
          },
          {
              id: "3ae639bb05feeca0b5ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46991&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc244ac8090c5799b20d4.png",
      price: "Miễn phí",
      name: "Trọc Trắng",
      thumbImg: "https://zalo-api.zadn.vn/f/6/b/c/0/12002/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/f/6/b/c/0/12002/icon_pre/troctrang_thumb.png",
      id: "617b0fbf33fadaa483eb",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "b572512f6d6a8434dd7b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46952&size=130"
          },
          {
              id: "7b2e9e73a2364b681227",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46953&size=130"
          },
          {
              id: "bb9b5dc6618388ddd192",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46954&size=130"
          },
          {
              id: "7577922aae6f47311e7e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46955&size=130"
          },
          {
              id: "961a76474a02a35cfa13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46956&size=130"
          },
          {
              id: "ea910bcc3789ded78798",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46957&size=130"
          },
          {
              id: "a657440a784f9111c85e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46958&size=130"
          },
          {
              id: "1dbefee3c2a62bf872b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46959&size=130"
          },
          {
              id: "cdbb31e60da3e4fdbdb2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46960&size=130"
          },
          {
              id: "aaf157ac6be982b7dbf8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46961&size=130"
          },
          {
              id: "7f2e8173bd3654680d27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46962&size=130"
          },
          {
              id: "fb8204df389ad1c4888b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46963&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker021f63db5f9eb6c0ef8f.png",
      price: "Miễn phí",
      name: "Usagyuuun Hay Ra Dẻ",
      thumbImg: "https://zalo-api.zadn.vn/e/3/2/9/2/12013/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/e/3/2/9/2/12013/icon_pre/usagyuuunhayrade_thumb.png",
      id: "77f116352a70c32e9a61",
      source: "Minto Inc.",
      totalImage: "23 sticker",
      value: [
          {
              id: "5882fdd0c19528cb7184",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47145&size=130"
          },
          {
              id: "c0d266805ac5b39bead4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47146&size=130"
          },
          {
              id: "a28205d03995d0cb8984",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47147&size=130"
          },
          {
              id: "107cb02e8c6b65353c7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47148&size=130"
          },
          {
              id: "1eb6bfe483a16aff33b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47149&size=130"
          },
          {
              id: "fd775f2563608a3ed371",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47150&size=130"
          },
          {
              id: "2649851bb95e5000094f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47151&size=130"
          },
          {
              id: "c8e474b648f3a1adf8e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47152&size=130"
          },
          {
              id: "d49869ca558fbcd1e59e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47153&size=130"
          },
          {
              id: "6fffd1adede804b65df9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47154&size=130"
          },
          {
              id: "19c3a6919ad4738a2ac5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47155&size=130"
          },
          {
              id: "2be893baafff46a11fee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47156&size=130"
          },
          {
              id: "c19378c14484addaf495",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47157&size=130"
          },
          {
              id: "1677ac259060793e2071",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47158&size=130"
          },
          {
              id: "0876b3248f61663f3f70",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47159&size=130"
          },
          {
              id: "9c2a2878143dfd63a42c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47160&size=130"
          },
          {
              id: "29d39c81a0c4499a10d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47161&size=130"
          },
          {
              id: "1a97acc5908079de2091",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47162&size=130"
          },
          {
              id: "dcfc6bae57ebbeb5e7fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47163&size=130"
          },
          {
              id: "50f0e0a2dce735b96cf6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47164&size=130"
          },
          {
              id: "0132b0608c25657b3c34",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47165&size=130"
          },
          {
              id: "44d6f684cac1239f7ad0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47166&size=130"
          },
          {
              id: "a61015422907c0599916",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47167&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickera275c8b1f4f41daa44e5.png",
      price: "Miễn phí",
      name: "Zookiz Sở Thú Xàm Xí",
      thumbImg: "https://zalo-api.zadn.vn/e/f/1/2/6/12006/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/e/f/1/2/6/12006/icon_pre/zookizsothuxamxi_thumb.png",
      id: "cadca0189c5d75032c4c",
      source: "Zookiz",
      totalImage: "12 sticker",
      value: [
          {
              id: "1fab2bf617b3feeda7a2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47032&size=130"
          },
          {
              id: "33d506883acdd3938adc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47033&size=130"
          },
          {
              id: "a3bd95e0a9a540fb19b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47034&size=130"
          },
          {
              id: "517566285a6db333ea7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47035&size=130"
          },
          {
              id: "00b530e80cade5f3bcbc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47036&size=130"
          },
          {
              id: "e1c1d09cecd905875cc8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47037&size=130"
          },
          {
              id: "e21bd046ec03055d5c12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47038&size=130"
          },
          {
              id: "78e24bbf77fa9ea4c7eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47039&size=130"
          },
          {
              id: "e017ac4a900f7951201e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47040&size=130"
          },
          {
              id: "ae4ee313df5636086f47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47041&size=130"
          },
          {
              id: "30267e7b423eab60f22f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47042&size=130"
          },
          {
              id: "eae9a5b499f170af29e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=47043&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrsticker98c4c600fa45131b4a54.png",
      price: "Miễn phí",
      name: "Thiệp Zookiz",
      thumbImg: "https://zalo-api.zadn.vn/b/7/c/3/8/11986/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/b/7/c/3/8/11986/icon_pre/thiepzookiz_thumb.png",
      id: "8772d9b6e5f30cad55e2",
      source: "\bZookiz",
      totalImage: "20 sticker",
      value: [
          {
              id: "652b9977a5324c6c1523",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46704&size=130"
          },
          {
              id: "e85c15002945c01b9954",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46705&size=130"
          },
          {
              id: "94b76aeb56aebff0e6bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46706&size=130"
          },
          {
              id: "b91546497a0c9352ca1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46707&size=130"
          },
          {
              id: "11b7e9ebd5ae3cf065bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46708&size=130"
          },
          {
              id: "e99010cc2c89c5d79c98",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46709&size=130"
          },
          {
              id: "51e8abb497f17eaf27e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46710&size=130"
          },
          {
              id: "4af9b1a58de064be3df1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46711&size=130"
          },
          {
              id: "e616124a2e0fc7519e1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46712&size=130"
          },
          {
              id: "a61e53426f078659df16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46713&size=130"
          },
          {
              id: "865c70004c45a51bfc54",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46714&size=130"
          },
          {
              id: "e4eb13b72ff2c6ac9fe3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46715&size=130"
          },
          {
              id: "4240b21c8e5967073e48",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46716&size=130"
          },
          {
              id: "8a347b68472dae73f73c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46717&size=130"
          },
          {
              id: "651b9747ab02425c1b13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46718&size=130"
          },
          {
              id: "81db72874ec2a79cfed3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46719&size=130"
          },
          {
              id: "c345cf19f35c1a02434d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46720&size=130"
          },
          {
              id: "fd63f03fcc7a25247c6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46721&size=130"
          },
          {
              id: "8d2a8376bf33566d0f22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46722&size=130"
          },
          {
              id: "6a4865145951b00fe940",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46723&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickera242fe86c2c32b9d72d2.png",
      price: "Miễn phí",
      name: "Kim & Yim Nóng Nảy",
      thumbImg: "https://zalo-api.zadn.vn/9/d/1/8/e/11984/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/9/d/1/8/e/11984/icon_pre/kim&yim_thumb.png",
      id: "4d1311d72d92c4cc9d83",
      source: "Finger Corp.",
      totalImage: "20 sticker",
      value: [
          {
              id: "906450386c7d8523dc6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46668&size=130"
          },
          {
              id: "75f2b4ae88eb61b538fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46669&size=130"
          },
          {
              id: "f6e034bc08f9e1a7b8e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46670&size=130"
          },
          {
              id: "0a2cc970f5351c6b4524",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46671&size=130"
          },
          {
              id: "093cd560e925007b5934",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46672&size=130"
          },
          {
              id: "c2f11fad23e8cab693f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46673&size=130"
          },
          {
              id: "3832e66eda2b33756a3a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46674&size=130"
          },
          {
              id: "9e3841647d21947fcd30",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46675&size=130"
          },
          {
              id: "6965b1398d7c64223d6d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46676&size=130"
          },
          {
              id: "2c94f5c8c98d20d3799c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46677&size=130"
          },
          {
              id: "02e2d8bee4fb0da554ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46678&size=130"
          },
          {
              id: "6a1ab1468d03645d3d12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46679&size=130"
          },
          {
              id: "9c9148cd74889dd6c499",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46680&size=130"
          },
          {
              id: "4f619a3da6784f261669",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46681&size=130"
          },
          {
              id: "935d45017944901ac955",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46682&size=130"
          },
          {
              id: "4542921eae5b47051e4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46683&size=130"
          },
          {
              id: "389ce8c0d4853ddb6494",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46684&size=130"
          },
          {
              id: "c76516392a7cc3229a6d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46685&size=130"
          },
          {
              id: "cd911fcd2388cad69399",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46686&size=130"
          },
          {
              id: "b566663a5a7fb321ea6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46687&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker3ae77f234366aa38f377.png",
      price: "Miễn phí",
      name: "Thỏ Cáu Kỉnh",
      thumbImg: "https://zalo-api.zadn.vn/3/f/6/3/2/11977/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/3/f/6/3/2/11977/icon_pre/thocaukinh_thumb.png",
      id: "140c51c86d8d84d3dd9c",
      source: "Minto Inc.",
      totalImage: "12 sticker",
      value: [
          {
              id: "c70c9753ab1642481b07",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46556&size=130"
          },
          {
              id: "9bd2ca8df6c81f9646d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46557&size=130"
          },
          {
              id: "318063df5f9ab6c4ef8b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46558&size=130"
          },
          {
              id: "cd0e9e51a2144b4a1205",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46559&size=130"
          },
          {
              id: "e0958ccab08f59d1009e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46560&size=130"
          },
          {
              id: "048169de559bbcc5e58a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46561&size=130"
          },
          {
              id: "92effcb0c0f529ab70e4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46562&size=130"
          },
          {
              id: "1c9773c84f8da6d3ff9c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46563&size=130"
          },
          {
              id: "c875a02a9c6f75312c7e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46564&size=130"
          },
          {
              id: "0d266479583cb162e82d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46565&size=130"
          },
          {
              id: "4cd3268c1ac9f397aad8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46566&size=130"
          },
          {
              id: "423929661523fc7da532",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46567&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerb720f1e4cda124ff7db0.png",
      price: "Miễn phí",
      name: "Thỏ Cáu Kỉnh 2",
      thumbImg: "https://zalo-api.zadn.vn/5/3/1/d/2/11978/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/5/3/1/d/2/11978/icon_pre/thocaukinh2_thumb.png",
      id: "7de43b200765ee3bb774",
      source: "Minto Inc.",
      totalImage: "12 sticker",
      value: [
          {
              id: "a43dc062fc2715794c36",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46568&size=130"
          },
          {
              id: "e4468119bd5c54020d4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46569&size=130"
          },
          {
              id: "a663c03cfc7915274c68",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46570&size=130"
          },
          {
              id: "b98bded4e2910bcf5280",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46571&size=130"
          },
          {
              id: "d3e6b3b98ffc66a23fed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46572&size=130"
          },
          {
              id: "7a391b662723ce7d9732",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46573&size=130"
          },
          {
              id: "dc99bec682836bdd3292",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46574&size=130"
          },
          {
              id: "0eee6db151f4b8aae1e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46575&size=130"
          },
          {
              id: "aee7d2b8eefd07a35eec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46576&size=130"
          },
          {
              id: "218e5cd1609489cad085",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46577&size=130"
          },
          {
              id: "af23d17ced3904675d28",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46578&size=130"
          },
          {
              id: "152d6a725637bf69e626",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46579&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker173350f76cb285ecdca3.png",
      price: "Miễn phí",
      name: "Crazy Frog 3D",
      thumbImg: "https://zalo-api.zadn.vn/7/6/8/7/2/11979/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/7/6/8/7/2/11979/icon_pre/crazyfrog3D_thumb.png",
      id: "c9458e81b2c45b9a02d5",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "55942dcb118ef8d0a19f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46580&size=130"
          },
          {
              id: "6bda12852ec0c79e9ed1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46581&size=130"
          },
          {
              id: "95afeff0d3b53aeb63a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46582&size=130"
          },
          {
              id: "9d30e66fda2a33746a3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46583&size=130"
          },
          {
              id: "b112c54df90810564919",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46584&size=130"
          },
          {
              id: "0e777b28476dae33f77c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46585&size=130"
          },
          {
              id: "04c972964ed3a78dfec2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46586&size=130"
          },
          {
              id: "bd9ccac3f6861fd84697",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46587&size=130"
          },
          {
              id: "eeca9e95a2d04b8e12c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46588&size=130"
          },
          {
              id: "a153d00cec4905175c58",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46589&size=130"
          },
          {
              id: "39e34bbc77f99ea7c7e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46590&size=130"
          },
          {
              id: "d80cab5397167e482707",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46591&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc9f38337bf72562c0f63.png",
      price: "Miễn phí",
      name: "Mèo 6 Chân Quằn Quại",
      thumbImg: "https://zalo-api.zadn.vn/6/b/9/9/3/11974/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/6/b/9/9/3/11974/icon_pre/meo6chanquanquai_thumb.png",
      id: "6d3d27f91bbcf2e2abad",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "a31e9f41a3044a5a1315",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46512&size=130"
          },
          {
              id: "a5ed98b2a4f74da914e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46513&size=130"
          },
          {
              id: "90c3ae9c92d97b8722c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46514&size=130"
          },
          {
              id: "7d4f42107e55970bce44",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46515&size=130"
          },
          {
              id: "931bab4497017e5f2710",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46516&size=130"
          },
          {
              id: "446b7d344171a82ff160",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46517&size=130"
          },
          {
              id: "5b43611c5d59b407ed48",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46518&size=130"
          },
          {
              id: "98dfa3809fc5769b2fd4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46519&size=130"
          },
          {
              id: "211c15432906c0589917",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46520&size=130"
          },
          {
              id: "e1d4d48be8ce019058df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46521&size=130"
          },
          {
              id: "cc9dfac2c6872fd97696",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46522&size=130"
          },
          {
              id: "bab28dedb1a858f601b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46523&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker142b5cef60aa89f4d0bb.png",
      price: "Miễn phí",
      name: "Usagyuuun Summertime 2",
      thumbImg: "https://zalo-api.zadn.vn/6/d/7/9/e/11972/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/6/d/7/9/e/11972/icon_pre/usagyuuunsummertime2_thumb.png",
      id: "fa55b2918ed4678a3ec5",
      source: "Minto Inc.",
      totalImage: "12 sticker",
      value: [
          {
              id: "dfcecb91f7d41e8a47c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46488&size=130"
          },
          {
              id: "018814d72892c1cc9883",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46489&size=130"
          },
          {
              id: "7225647a583fb161e82e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46490&size=130"
          },
          {
              id: "71d966865ac3b39dead2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46491&size=130"
          },
          {
              id: "abdfbb8087c56e9b37d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46492&size=130"
          },
          {
              id: "d4cdc592f9d7108949c6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46493&size=130"
          },
          {
              id: "f95aeb05d7403e1e6751",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46494&size=130"
          },
          {
              id: "1ba808f734b2ddec84a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46495&size=130"
          },
          {
              id: "9a3cb6638a2663783a37",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46496&size=130"
          },
          {
              id: "1c31316e0d2be475bd3a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46497&size=130"
          },
          {
              id: "899fa7c09b8572db2b94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46498&size=130"
          },
          {
              id: "b9b596eaaaaf43f11abe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46499&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrsticker8dfbba3f867a6f24366b.png",
      price: "Miễn phí",
      name: "Usagyuuun Summertime 1",
      thumbImg: "https://zalo-api.zadn.vn/f/e/4/1/b/11963/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/f/e/4/1/b/11963/icon_pre/usagyuuunsummertime_thumb.png",
      id: "442d73e94faca6f2ffbd",
      source: "Minto Inc.",
      totalImage: "12 sticker",
      value: [
          {
              id: "2023a87c94397d672428",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46340&size=130"
          },
          {
              id: "c5344c6b702e9970c03f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46341&size=130"
          },
          {
              id: "bc8c36d30a96e3c8ba87",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46342&size=130"
          },
          {
              id: "11de9a81a6c44f9a16d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46343&size=130"
          },
          {
              id: "203fa4609825717b2834",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46344&size=130"
          },
          {
              id: "378ab2d58e9067ce3e81",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46345&size=130"
          },
          {
              id: "bc4c3a130656ef08b647",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46346&size=130"
          },
          {
              id: "5442d31def5806065f49",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46347&size=130"
          },
          {
              id: "630de352df1736496f06",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46348&size=130"
          },
          {
              id: "8c130d4c3109d8578118",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46349&size=130"
          },
          {
              id: "6439e666da23337d6a32",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46350&size=130"
          },
          {
              id: "a103225c1e19f747ae08",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46351&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7ca4db60e7250e7b5734.png",
      price: "Miễn phí",
      name: "Liên Quân Mobile",
      thumbImg: "https://zalo-api.zadn.vn/f/5/7/4/7/11819/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/f/5/7/4/7/11819/icon_pre/lienquan_thumb.png",
      id: "d98f7e4b420eab50f21f",
      source: "Liên Quân Mobile",
      totalImage: "19 sticker",
      value: [
          {
              id: "b0a3c2e2fea717f94eb6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44030&size=130"
          },
          {
              id: "6ae319a225e7ccb995f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44031&size=130"
          },
          {
              id: "1fa193e7afa246fc1fb3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44032&size=130"
          },
          {
              id: "b4c1398705c2ec9cb5d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44033&size=130"
          },
          {
              id: "9a2414622827c1799836",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44034&size=130"
          },
          {
              id: "deca518c6dc98497ddd8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44035&size=130"
          },
          {
              id: "cd2345657920907ec931",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44036&size=130"
          },
          {
              id: "0d4b840db84851160859",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44037&size=130"
          },
          {
              id: "630ce94ad50f3c51651e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44038&size=130"
          },
          {
              id: "ae1e2558191df043a90c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44039&size=130"
          },
          {
              id: "6b59ef1fd35a3a04634b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44040&size=130"
          },
          {
              id: "c52140677c22957ccc33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44041&size=130"
          },
          {
              id: "852203643f21d67f8f30",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44042&size=130"
          },
          {
              id: "e1e566a35ae6b3b8eaf7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44043&size=130"
          },
          {
              id: "2210a2569e13774d2e02",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44044&size=130"
          },
          {
              id: "2445a503994670182957",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44045&size=130"
          },
          {
              id: "632de16bdd2e34706d3f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44046&size=130"
          },
          {
              id: "9a6c192a256fcc31957e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44047&size=130"
          },
          {
              id: "f6536a155650bf0ee641",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44048&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd02f9deba1ae48f011bf.png",
      price: "Miễn phí",
      name: "Doraemon Vũ Trụ Tí Hon",
      thumbImg: "https://zalo-api.zadn.vn/f/7/e/b/7/11969/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/f/7/e/b/7/11969/icon_pre/doraemonvutrutihon_thumb.png",
      id: "b451f995c5d02c8e75c1",
      source: "0",
      totalImage: "20 sticker",
      value: [
          {
              id: "fa0d06523a17d3498a06",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46448&size=130"
          },
          {
              id: "c9df348008c5e19bb8d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46449&size=130"
          },
          {
              id: "ee75102a2c6fc5319c7e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46450&size=130"
          },
          {
              id: "3b16c449f80c1152481d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46451&size=130"
          },
          {
              id: "b6374e68722d9b73c23c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46452&size=130"
          },
          {
              id: "a5b35cec60a989f7d0b8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46453&size=130"
          },
          {
              id: "f6b80ce730a2d9fc80b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46454&size=130"
          },
          {
              id: "7dea86b5baf053ae0ae1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46455&size=130"
          },
          {
              id: "ee8a1ad52690cfce9681",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46456&size=130"
          },
          {
              id: "25c8d097ecd2058c5cc3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46457&size=130"
          },
          {
              id: "26a1d0feecbb05e55caa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46458&size=130"
          },
          {
              id: "dccc2b9317d6fe88a7c7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46459&size=130"
          },
          {
              id: "bb434b1c77599e07c748",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46460&size=130"
          },
          {
              id: "137fe220de65373b6e74",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46461&size=130"
          },
          {
              id: "eac2189d24d8cd8694c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46462&size=130"
          },
          {
              id: "99a66af956bcbfe2e6ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46463&size=130"
          },
          {
              id: "2a0d26521a17f349aa06",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46464&size=130"
          },
          {
              id: "7e3773684f2da673ff3c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46465&size=130"
          },
          {
              id: "8c52820dbe4857160e59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46466&size=130"
          },
          {
              id: "6805675a5b1fb241eb0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46467&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickere7f8d13ced7904275d68.png",
      price: "Miễn phí",
      name: "Mimi & Neko Couple",
      thumbImg: "https://zalo-api.zadn.vn/e/8/c/1/b/11962/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/e/8/c/1/b/11962/icon_pre/mimi&nekocouple_thumb.png",
      id: "5b2b6def51aab8f4e1bb",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "62e01ebe22fbcba592ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46320&size=130"
          },
          {
              id: "2a13574d6b088256db19",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46321&size=130"
          },
          {
              id: "dc52a20c9e4977172e58",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46322&size=130"
          },
          {
              id: "a800d75eeb1b02455b0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46323&size=130"
          },
          {
              id: "1c0f64515814b14ae805",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46324&size=130"
          },
          {
              id: "eb42921cae5947071e48",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46325&size=130"
          },
          {
              id: "71a00bfe37bbdee587aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46326&size=130"
          },
          {
              id: "8a96f1c8cd8d24d37d9c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46327&size=130"
          },
          {
              id: "5fed2bb317f6fea8a7e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46328&size=130"
          },
          {
              id: "6dcf189124d4cd8a94c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46329&size=130"
          },
          {
              id: "39b44fea73af9af1c3be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46330&size=130"
          },
          {
              id: "3a714d2f716a9834c17b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46331&size=130"
          },
          {
              id: "83dff381cfc4269a7fd5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46332&size=130"
          },
          {
              id: "a2eed3b0eff506ab5fe4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46333&size=130"
          },
          {
              id: "22c1509f6cda8584dccb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46334&size=130"
          },
          {
              id: "dcf7afa993ec7ab223fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46335&size=130"
          },
          {
              id: "0fc4839bbfde56800fcf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46336&size=130"
          },
          {
              id: "7887f5d8c99d20c3798c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46337&size=130"
          },
          {
              id: "98fa16a52ae0c3be9af1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46338&size=130"
          },
          {
              id: "e2846ddb519eb8c0e18f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46339&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker8552b1968dd3648d3dc2.png",
      price: "Miễn phí",
      name: "Ppuyo Póng Pẩy",
      thumbImg: "https://zalo-api.zadn.vn/d/e/b/1/8/11960/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/d/e/b/1/8/11960/icon_pre/ppuyopongpay_thumb.png",
      id: "dc8be84fd40a3d54641b",
      source: "Zookiz",
      totalImage: "16 sticker",
      value: [
          {
              id: "0bf44baa77ef9eb1c7fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46284&size=130"
          },
          {
              id: "44ae05f039b5d0eb89a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46285&size=130"
          },
          {
              id: "321e70404c05a55bfc14",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46286&size=130"
          },
          {
              id: "a6bde5e3d9a630f869b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46287&size=130"
          },
          {
              id: "59c4059a39dfd08189ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46288&size=130"
          },
          {
              id: "3f6f62315e74b72aee65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46289&size=130"
          },
          {
              id: "c2a99cf7a0b249ec10a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46290&size=130"
          },
          {
              id: "afc0f09eccdb25857cca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46291&size=130"
          },
          {
              id: "9651ce0ff24a1b14425b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46292&size=130"
          },
          {
              id: "ef34b66a8a2f63713a3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46293&size=130"
          },
          {
              id: "041d5e4362068b58d217",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46294&size=130"
          },
          {
              id: "2bdf70814cc4a59afcd5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46295&size=130"
          },
          {
              id: "30e064be58fbb1a5e8ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46296&size=130"
          },
          {
              id: "336c66325a77b329ea66",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46297&size=130"
          },
          {
              id: "23cb759549d0a08ef9c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46298&size=130"
          },
          {
              id: "1a3f4d617124987ac135",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46299&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerf2edcb29f76c1e32477d.png",
      price: "Miễn phí",
      name: "Piske & Usagi 3",
      thumbImg: "https://zalo-api.zadn.vn/d/a/8/a/2/11957/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/d/a/8/a/2/11957/icon_pre/piske&usagi3_thumb.png",
      id: "8ce6b522896760393976",
      source: "Kanahei",
      totalImage: "16 sticker",
      value: [
          {
              id: "fd5ae804d4413d1f6450",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46233&size=130"
          },
          {
              id: "5f45491b755e9c00c54f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46234&size=130"
          },
          {
              id: "d652c10cfd4914174d58",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46235&size=130"
          },
          {
              id: "15ad05f339b6d0e889a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46236&size=130"
          },
          {
              id: "7323627d5e38b766ee29",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46237&size=130"
          },
          {
              id: "8c179e49a20c4b52121d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46238&size=130"
          },
          {
              id: "4b8b58d564908dced481",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46239&size=130"
          },
          {
              id: "b24e9e10a2554b0b1244",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46240&size=130"
          },
          {
              id: "c895e5cbd98e30d0699f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46241&size=130"
          },
          {
              id: "d134ff6ac32f2a71733e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46242&size=130"
          },
          {
              id: "bb60943ea87b4125186a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46243&size=130"
          },
          {
              id: "e128c976f5331c6d4522",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46244&size=130"
          },
          {
              id: "1f2636780a3de363ba2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46245&size=130"
          },
          {
              id: "9769bd378172682c3163",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46246&size=130"
          },
          {
              id: "b5389e66a2234b7d1232",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46247&size=130"
          },
          {
              id: "580f7c514014a94af005",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46248&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker6a3852fc6eb987e7dea8.png",
      price: "Miễn phí",
      name: "Piske & Usagi 2",
      thumbImg: "https://zalo-api.zadn.vn/6/7/9/1/8/11956/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/6/7/9/1/8/11956/icon_pre/piske&usagi2_thumb.png",
      id: "937fabbb97fe7ea027ef",
      source: "Kanahei",
      totalImage: "16 sticker",
      value: [
          {
              id: "588f5dd1619488cad185",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46217&size=130"
          },
          {
              id: "ca5bcc05f040191e4051",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46218&size=130"
          },
          {
              id: "686b6f355370ba2ee361",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46219&size=130"
          },
          {
              id: "f31af344cf01265f7f10",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46220&size=130"
          },
          {
              id: "5dc85c9660d3898dd0c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46221&size=130"
          },
          {
              id: "ebc5e99bd5de3c8065cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46222&size=130"
          },
          {
              id: "e89eebc0d7853edb6794",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46223&size=130"
          },
          {
              id: "92cd8e93b2d65b8802c7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46224&size=130"
          },
          {
              id: "2460393e057bec25b56a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46225&size=130"
          },
          {
              id: "0e40101e2c5bc5059c4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46226&size=130"
          },
          {
              id: "04871bd9279ccec2978d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46227&size=130"
          },
          {
              id: "a5a4bdfa81bf68e131ae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46228&size=130"
          },
          {
              id: "d93bc065fc20157e4c31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46229&size=130"
          },
          {
              id: "01cc1b9227d7ce8997c6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46230&size=130"
          },
          {
              id: "41f95aa766e28fbcd6f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46231&size=130"
          },
          {
              id: "01f015ae29ebc0b599fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46232&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker18f527311b74f22aab65.png",
      price: "Miễn phí",
      name: "Piske & Usagi",
      thumbImg: "https://zalo-api.zadn.vn/5/6/4/7/b/11955/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/5/6/4/7/b/11955/icon_pre/piske&usagi_thumb.png",
      id: "02bd3d79013ce862b12d",
      source: "Kanahei",
      totalImage: "16 sticker",
      value: [
          {
              id: "5885addb919e78c0218f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46201&size=130"
          },
          {
              id: "350ec350ff15164b4f04",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46202&size=130"
          },
          {
              id: "97c1609f5cdab584eccb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46203&size=130"
          },
          {
              id: "aff45faa63ef8ab1d3fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46204&size=130"
          },
          {
              id: "fe050f5b331eda40830f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46205&size=130"
          },
          {
              id: "bfd54d8b71ce9890c1df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46206&size=130"
          },
          {
              id: "cee73db901fce8a2b1ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46207&size=130"
          },
          {
              id: "ef5ae304df41361f6f50",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46208&size=130"
          },
          {
              id: "ad80a0de9c9b75c52c8a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46209&size=130"
          },
          {
              id: "0dbe03e03fa5d6fb8fb4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46210&size=130"
          },
          {
              id: "212f2e711234fb6aa225",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46211&size=130"
          },
          {
              id: "cb28c376ff33166d4f22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46212&size=130"
          },
          {
              id: "fac3f39dcfd826867fc9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46213&size=130"
          },
          {
              id: "d7a2ddfce1b908e751a8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46214&size=130"
          },
          {
              id: "b0b5bbeb87ae6ef037bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46215&size=130"
          },
          {
              id: "6be96fb753f2baace3e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46216&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickeraf6c91a8aded44b31dfc.png",
      price: "Miễn phí",
      name: "Usagyuuun Tăng Động 2",
      thumbImg: "https://zalo-api.zadn.vn/2/f/2/7/b/11954/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/2/f/2/7/b/11954/icon_pre/usagyuuuntangdong2_thumb.png",
      id: "e489da4de6080f565619",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "0f7fe221de64373a6e75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46177&size=130"
          },
          {
              id: "155efb00c7452e1b7754",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46178&size=130"
          },
          {
              id: "ef53000d3c48d5168c59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46179&size=130"
          },
          {
              id: "a59e4dc0718598dbc194",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46180&size=130"
          },
          {
              id: "2187c8d9f49c1dc2448d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46181&size=130"
          },
          {
              id: "67a78df9b1bc58e201ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46182&size=130"
          },
          {
              id: "3cd7d789ebcc02925bdd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46183&size=130"
          },
          {
              id: "a8154c4b700e9950c01f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46184&size=130"
          },
          {
              id: "764e9310af55460b1f44",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46185&size=130"
          },
          {
              id: "7153970dab4842161b59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46186&size=130"
          },
          {
              id: "1375f42bc86e2130787f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46187&size=130"
          },
          {
              id: "3d65dd3be17e0820516f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46188&size=130"
          },
          {
              id: "d43935670922e07cb933",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46189&size=130"
          },
          {
              id: "d9123b4c0709ee57b718",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46190&size=130"
          },
          {
              id: "800763595f1cb642ef0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46191&size=130"
          },
          {
              id: "3941c51ff95a1004494b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46192&size=130"
          },
          {
              id: "f95a04043841d11f8850",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46193&size=130"
          },
          {
              id: "f80d06533a16d3488a07",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46194&size=130"
          },
          {
              id: "c30e3c500015e94bb004",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46195&size=130"
          },
          {
              id: "dbc5239b1fdef680afcf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46196&size=130"
          },
          {
              id: "3b34c26afe2f17714e3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46197&size=130"
          },
          {
              id: "0c5bf605ca40231e7a51",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46198&size=130"
          },
          {
              id: "cc0937570b12e24cbb03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46199&size=130"
          },
          {
              id: "289cdcc2e08709d95096",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46200&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickercd69eeadd2e83bb662f9.png",
      price: "Miễn phí",
      name: "Betakkuma 2",
      thumbImg: "https://zalo-api.zadn.vn/2/0/8/5/8/11951/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/2/0/8/5/8/11951/icon_pre/betakkuma2_thumb.png",
      id: "2c920f563313da4d8302",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "070fa95195147c4a2505",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46114&size=130"
          },
          {
              id: "7aafd5f1e9b400ea59a5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46115&size=130"
          },
          {
              id: "b3c11b9f27dace8497cb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46116&size=130"
          },
          {
              id: "28f981a7bde254bc0df3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46117&size=130"
          },
          {
              id: "e2e148bf74fa9da4c4eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46118&size=130"
          },
          {
              id: "fdd756896acc8392dadd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46119&size=130"
          },
          {
              id: "cd45691b555ebc00e54f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46120&size=130"
          },
          {
              id: "cbe76eb952fcbba2e2ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46121&size=130"
          },
          {
              id: "2cbd8ae3b6a65ff806b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46122&size=130"
          },
          {
              id: "75d5d28beece07905edf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46123&size=130"
          },
          {
              id: "4e6dee33d2763b286267",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46124&size=130"
          },
          {
              id: "a4c3059d39d8d08689c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46125&size=130"
          },
          {
              id: "c49e66c05a85b3dbea94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46126&size=130"
          },
          {
              id: "b450170e2b4bc2159b5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46127&size=130"
          },
          {
              id: "06d8ba8686c36f9d36d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46128&size=130"
          },
          {
              id: "82b53feb03aeeaf0b3bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46129&size=130"
          },
          {
              id: "b2f80ca630e3d9bd80f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46130&size=130"
          },
          {
              id: "96f229ac15e9fcb7a5f8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46131&size=130"
          },
          {
              id: "c6347e6a422fab71f23e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46132&size=130"
          },
          {
              id: "f7c34e9d72d89b86c2c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46133&size=130"
          },
          {
              id: "e6f35cad60e889b6d0f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46134&size=130"
          },
          {
              id: "647ddf23e3660a385377",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46135&size=130"
          },
          {
              id: "e9075d59611c8842d10d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46136&size=130"
          },
          {
              id: "2a1f9f41a3044a5a1315",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46137&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker765157956bd0828edbc1.png",
      price: "Miễn phí",
      name: "Mimi & Neko 3",
      thumbImg: "https://zalo-api.zadn.vn/3/4/6/3/b/11949/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/3/4/6/3/b/11949/icon_pre/mimi&neko3_thumb.png",
      id: "ff26dee2e2a70bf952b6",
      source: "Minto Inc.",
      totalImage: "18 sticker",
      value: [
          {
              id: "20f9a8a794e27dbc24f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46084&size=130"
          },
          {
              id: "4fc6c698fadd13834acc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46085&size=130"
          },
          {
              id: "979f1dc12184c8da9195",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46086&size=130"
          },
          {
              id: "383ab3648f21667f3f30",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46087&size=130"
          },
          {
              id: "c22a46747a31936fca20",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46088&size=130"
          },
          {
              id: "ed8a68d45491bdcfe480",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46089&size=130"
          },
          {
              id: "3cfebaa086e56fbb36f4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46090&size=130"
          },
          {
              id: "c4ed43b37ff696a8cfe7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46091&size=130"
          },
          {
              id: "181f9841a4044d5a1415",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46092&size=130"
          },
          {
              id: "c13940677c22957ccc33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46093&size=130"
          },
          {
              id: "9a0b18552410cd4e9401",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46094&size=130"
          },
          {
              id: "8b8e08d03495ddcb8484",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46095&size=130"
          },
          {
              id: "2d13b14d8d0864563d19",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46096&size=130"
          },
          {
              id: "0cbb91e5ada044fe1db1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46097&size=130"
          },
          {
              id: "5b3dc563f92610784937",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46098&size=130"
          },
          {
              id: "4b8ad4d4e89101cf5880",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46099&size=130"
          },
          {
              id: "0d9a95c4a98140df1990",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46100&size=130"
          },
          {
              id: "4efad7a4ebe102bf5bf0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46101&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrsticker495b6c9f50dab984e0cb.png",
      price: "Miễn phí",
      name: "Hổ Mặt Mâm",
      thumbImg: "https://zalo-api.zadn.vn/8/2/0/4/8/11945/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/8/2/0/4/8/11945/icon_pre/homatmam_thumb.png",
      id: "8a90af5493117a4f2300",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "27a06ff953bcbae2e3ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46020&size=130"
          },
          {
              id: "b624ff7dc3382a667329",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46021&size=130"
          },
          {
              id: "f9deb3878fc2669c3fd3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46022&size=130"
          },
          {
              id: "4932026b3e2ed7708e3f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46023&size=130"
          },
          {
              id: "e936ad6f912a7874213b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46024&size=130"
          },
          {
              id: "e847ad1e915b7805214a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46025&size=130"
          },
          {
              id: "044c42157e50970ece41",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46026&size=130"
          },
          {
              id: "954ed217ee52070c5e43",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46027&size=130"
          },
          {
              id: "c69a86c3ba8653d80a97",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46028&size=130"
          },
          {
              id: "53ad12f42eb1c7ef9ea0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46029&size=130"
          },
          {
              id: "778b35d20997e0c9b986",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46030&size=130"
          },
          {
              id: "07a744fe78bb91e5c8aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=46031&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickercd76e4b2d8f731a968e6.png",
      price: "Miễn phí",
      name: "Gia Đình Dzăn Hóa",
      thumbImg: "https://zalo-api.zadn.vn/8/7/a/1/c/11941/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/8/7/a/1/c/11941/icon_pre/giadinhdzanhoa_thumb.png",
      id: "455c6c9850ddb983e0cc",
      source: "BT Studio",
      totalImage: "24 sticker",
      value: [
          {
              id: "98a394faa8bf41e118ae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45952&size=130"
          },
          {
              id: "37a23afb06beefe0b6af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45953&size=130"
          },
          {
              id: "1674182d2468cd369479",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45954&size=130"
          },
          {
              id: "3e9931c00d85e4dbbd94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45955&size=130"
          },
          {
              id: "87308f69b32c5a72033d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45956&size=130"
          },
          {
              id: "095d00043c41d51f8c50",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45957&size=130"
          },
          {
              id: "cb0cc155fd10144e4d01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45958&size=130"
          },
          {
              id: "6d04665d5a18b346ea09",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45959&size=130"
          },
          {
              id: "ee93eacad68f3fd1669e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45960&size=130"
          },
          {
              id: "5d005859641c8d42d40d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45961&size=130"
          },
          {
              id: "b89abec382866bd83297",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45962&size=130"
          },
          {
              id: "ef6ee837d4723d2c6463",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45963&size=130"
          },
          {
              id: "ed4ded14d151380f6140",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45964&size=130"
          },
          {
              id: "e364e23dde7837266e69",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45965&size=130"
          },
          {
              id: "2c562e0f124afb14a25b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45966&size=130"
          },
          {
              id: "88368b6fb72a5e74073b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45967&size=130"
          },
          {
              id: "097915202965c03b9974",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45968&size=130"
          },
          {
              id: "febce3e5dfa036fe6fb1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45969&size=130"
          },
          {
              id: "cca1d2f8eebd07e35eac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45970&size=130"
          },
          {
              id: "2c15334c0f09e657bf18",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45971&size=130"
          },
          {
              id: "f234ea6dd6283f766639",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45972&size=130"
          },
          {
              id: "0ad3138a2fcfc6919fde",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45973&size=130"
          },
          {
              id: "fc18e641da04335a6a15",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45974&size=130"
          },
          {
              id: "f780ecd9d09c39c2608d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45975&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker880da6c99a8c73d22a9d.png",
      price: "Miễn phí",
      name: "Moca Chó Điên",
      thumbImg: "https://zalo-api.zadn.vn/6/b/0/6/1/11938/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/6/b/0/6/1/11938/icon_pre/mocachodien_thumb.png",
      id: "6f1c41d87d9d94c3cd8c",
      source: "0",
      totalImage: "20 sticker",
      value: [
          {
              id: "86d143887fcd9693cfdc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45897&size=130"
          },
          {
              id: "7ee4b8bd84f86da634e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45898&size=130"
          },
          {
              id: "a65f61065d43b41ded52",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45899&size=130"
          },
          {
              id: "09c3c99af5df1c8145ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45900&size=130"
          },
          {
              id: "4964883db4785d260469",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45901&size=130"
          },
          {
              id: "7ebebce780a269fc30b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45902&size=130"
          },
          {
              id: "34dcf785cbc0229e7bd1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45903&size=130"
          },
          {
              id: "581e8447b802515c0813",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45904&size=130"
          },
          {
              id: "225cff05c3402a1e7351",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45905&size=130"
          },
          {
              id: "ba8764de589bb1c5e88a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45906&size=130"
          },
          {
              id: "2200fd59c11c2842710d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45907&size=130"
          },
          {
              id: "cf6f17362b73c22d9b62",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45908&size=130"
          },
          {
              id: "d917004e3c0bd5558c1a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45909&size=130"
          },
          {
              id: "42d99880a4c54d9b14d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45910&size=130"
          },
          {
              id: "3f9ee4c7d88231dc6893",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45911&size=130"
          },
          {
              id: "3225e67cda3933676a28",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45912&size=130"
          },
          {
              id: "aaf97fa043e5aabbf3f4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45913&size=130"
          },
          {
              id: "a9f87fa143e4aabaf3f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45914&size=130"
          },
          {
              id: "85b052e96eac87f2debd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45915&size=130"
          },
          {
              id: "b0e260bb5cfeb5a0ecef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45916&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickercda7df63e3260a785337.png",
      price: "Miễn phí",
      name: "Gấu Đụt & Cánh Cụt",
      thumbImg: "https://zalo-api.zadn.vn/a/1/a/a/0/11934/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/a/1/a/a/0/11934/icon_pre/gaudut&canhcut_thumb.png",
      id: "973b85ffb9ba50e409ab",
      source: "0",
      totalImage: "16 sticker",
      value: [
          {
              id: "055c8805b4405d1e0451",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45825&size=130"
          },
          {
              id: "5103df5ae31f0a41530e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45826&size=130"
          },
          {
              id: "57bfd8e6e4a30dfd54b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45827&size=130"
          },
          {
              id: "f042781b445ead00f44f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45828&size=130"
          },
          {
              id: "3394bacd86886fd63699",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45829&size=130"
          },
          {
              id: "88fc02a53ee0d7be8ef1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45830&size=130"
          },
          {
              id: "74ebffb2c3f72aa973e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45831&size=130"
          },
          {
              id: "f6f272ab4eeea7b0feff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45832&size=130"
          },
          {
              id: "60e3e5bad9ff30a169ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45833&size=130"
          },
          {
              id: "b9c53f9c03d9ea87b3c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45834&size=130"
          },
          {
              id: "04d4838dbfc856960fd9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45835&size=130"
          },
          {
              id: "cdf04da971ec98b2c1fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45836&size=130"
          },
          {
              id: "c1f340aa7cef95b1ccfe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45837&size=130"
          },
          {
              id: "307ab2238e6667383e77",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45838&size=130"
          },
          {
              id: "12319168ad2d44731d3c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45839&size=130"
          },
          {
              id: "bfbb23e21fa7f6f9afb6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45840&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker03e718232466cd389477.png",
      price: "Miễn phí",
      name: "Zookiz Cục Súc",
      thumbImg: "https://zalo-api.zadn.vn/8/0/e/4/9/11927/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/8/0/e/4/9/11927/icon_pre/zookizcucsuc_thumb.png",
      id: "98458381bfc4569a0fd5",
      source: "Zookiz",
      totalImage: "12 sticker",
      value: [
          {
              id: "878eaed692937bcd2282",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45733&size=130"
          },
          {
              id: "e940c318ff5d16034f4c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45734&size=130"
          },
          {
              id: "2cab07f33bb6d2e88ba7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45735&size=130"
          },
          {
              id: "6bb04fe873ad9af3c3bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45736&size=130"
          },
          {
              id: "a8158d4db10858560119",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45737&size=130"
          },
          {
              id: "40df66875ac2b39cead3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45738&size=130"
          },
          {
              id: "adc38a9bb6de5f8006cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45739&size=130"
          },
          {
              id: "65dd458579c0909ec9d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45740&size=130"
          },
          {
              id: "d8d7f98fc5ca2c9475db",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45741&size=130"
          },
          {
              id: "d300f158cd1d24437d0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45742&size=130"
          },
          {
              id: "84e5a7bd9bf872a62be9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45743&size=130"
          },
          {
              id: "0a8536dd0a98e3c6ba89",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45744&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker2b712ab516f0ffaea6e1.png",
      price: "Miễn phí",
      name: "Moonie Múp Míp",
      thumbImg: "https://zalo-api.zadn.vn/2/f/d/5/f/11917/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/2/f/d/5/f/11917/icon_pre/mooniemupmip_thumb.png",
      id: "02b403703f35d66b8f24",
      source: "0",
      totalImage: "16 sticker",
      value: [
          {
              id: "f6d96f8153c4ba9ae3d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45589&size=130"
          },
          {
              id: "c1185b4067058e5bd714",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45590&size=130"
          },
          {
              id: "0edb9583a9c6409819d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45591&size=130"
          },
          {
              id: "acc1389904dced82b4cd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45592&size=130"
          },
          {
              id: "06a993f1afb446ea1fa5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45593&size=130"
          },
          {
              id: "bbf92da111e4f8baa1f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45594&size=130"
          },
          {
              id: "3d46aa1e965b7f05264a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45595&size=130"
          },
          {
              id: "90a800f03cb5d5eb8ca4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45596&size=130"
          },
          {
              id: "06d4978cabc942971bd8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45597&size=130"
          },
          {
              id: "37faa5a299e770b929f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45598&size=130"
          },
          {
              id: "2506b65e8a1b63453a0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45599&size=130"
          },
          {
              id: "416ced34d171382f6160",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45600&size=130"
          },
          {
              id: "0224af7c93397a672328",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45601&size=130"
          },
          {
              id: "6f58c100fd45141b4d54",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45602&size=130"
          },
          {
              id: "94f63bae07ebeeb5b7fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45603&size=130"
          },
          {
              id: "648accd2f09719c94086",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45604&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/8/qrsticker1b0415c02985c0db9994.png",
      price: "Miễn phí",
      name: "Ami Bụng Bự 5",
      thumbImg: "https://zalo-api.zadn.vn/d/8/7/1/c/11906/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/d/8/7/1/c/11906/icon_pre/ami5_thumb.png",
      id: "c7a7c963f5261c784537",
      source: "JUJU",
      totalImage: "12 sticker",
      value: [
          {
              id: "f80f1d542111c84f9100",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45417&size=130"
          },
          {
              id: "f9261f7d2338ca669329",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45418&size=130"
          },
          {
              id: "bfe458bf64fa8da4d4eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45419&size=130"
          },
          {
              id: "d3a833f30fb6e6e8bfa7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45420&size=130"
          },
          {
              id: "f20313582f1dc6439f0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45421&size=130"
          },
          {
              id: "a2c5409e7cdb9585ccca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45422&size=130"
          },
          {
              id: "fe341d6f212ac874913b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45423&size=130"
          },
          {
              id: "ccc330980cdde583bccc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45424&size=130"
          },
          {
              id: "574baa1096557f0b2644",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45425&size=130"
          },
          {
              id: "3183cfd8f39d1ac3438c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45426&size=130"
          },
          {
              id: "3a46c51df95810064949",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45427&size=130"
          },
          {
              id: "d56c2d371172f82ca163",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45428&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickercd923e560213eb4db202.png",
      price: "Miễn phí",
      name: "Ami Bụng Bự 4",
      thumbImg: "https://zalo-api.zadn.vn/a/0/3/6/7/11903/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/a/0/3/6/7/11903/icon_pre/amibungbu4_thumb.png",
      id: "988c6b48570dbe53e71c",
      source: "JUJU",
      totalImage: "12 sticker",
      value: [
          {
              id: "6a80a7db9b9e72c02b8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45377&size=130"
          },
          {
              id: "312bff70c3352a6b7324",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45378&size=130"
          },
          {
              id: "b40d7b564713ae4df702",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45379&size=130"
          },
          {
              id: "5e539608aa4d43131a5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45380&size=130"
          },
          {
              id: "a2746b2f576abe34e77b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45381&size=130"
          },
          {
              id: "5e8794dca89941c71888",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45382&size=130"
          },
          {
              id: "126ad931e5740c2a5565",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45383&size=130"
          },
          {
              id: "654fa1149d51740f2d40",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45384&size=130"
          },
          {
              id: "04c0c19bfdde14804dcf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45385&size=130"
          },
          {
              id: "1bd1dd8ae1cf089151de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45386&size=130"
          },
          {
              id: "a65261095d4cb412ed5d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45387&size=130"
          },
          {
              id: "3a1ffa44c6012f5f7610",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45388&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickeree52e196ddd3348d6dc2.png",
      price: "Miễn phí",
      name: "Chim Xanh Giả Trân",
      thumbImg: "https://zalo-api.zadn.vn/a/d/0/8/6/11907/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/a/d/0/8/6/11907/icon_pre/cuxanh_thumb.png",
      id: "511a5ede629b8bc5d28a",
      source: "Vũ Trụ Giả Trân 198X",
      totalImage: "16 sticker",
      value: [
          {
              id: "4f07b65c8a1963473a08",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45429&size=130"
          },
          {
              id: "4524bf7f833a6a64332b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45430&size=130"
          },
          {
              id: "91c46a9f56dabf84e6cb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45431&size=130"
          },
          {
              id: "8154750f494aa014f95b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45432&size=130"
          },
          {
              id: "79bc8ce7b0a259fc00b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45433&size=130"
          },
          {
              id: "b7ed41b67df394adcde2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45434&size=130"
          },
          {
              id: "a8d55f8e63cb8a95d3da",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45435&size=130"
          },
          {
              id: "95ce659559d0b08ee9c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45436&size=130"
          },
          {
              id: "aba55afe66bb8fe5d6aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45437&size=130"
          },
          {
              id: "4134b36f8f2a66743f3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45438&size=130"
          },
          {
              id: "9a8069db559ebcc0e58f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45439&size=130"
          },
          {
              id: "8adc8687bac2539c0ad3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45440&size=130"
          },
          {
              id: "17b31ae826adcff396bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45441&size=130"
          },
          {
              id: "e0a7eefcd2b93be762a8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45442&size=130"
          },
          {
              id: "c71cc847f4021d5c4413",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45443&size=130"
          },
          {
              id: "1b8c13d72f92c6cc9f83",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45444&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerac3eadf991bc78e221ad.png",
      price: "Miễn phí",
      name: "Mimi & Neko 2",
      thumbImg: "https://zalo-api.zadn.vn/6/9/7/4/8/11661/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/6/9/7/4/8/11661/icon_pre/mimineko2_thumb.png",
      id: "3d143cd30096e9c8b087",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "8671373a0b7fe221bb6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41277&size=130"
          },
          {
              id: "18beaaf596b07fee26a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41278&size=130"
          },
          {
              id: "6d2dde66e2230b7d5232",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41279&size=130"
          },
          {
              id: "7b45b70e8b4b62153b5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41280&size=130"
          },
          {
              id: "83ce4e8572c09b9ec2d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41281&size=130"
          },
          {
              id: "81de4f9573d09a8ec3c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41282&size=130"
          },
          {
              id: "a13b6e705235bb6be224",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41283&size=130"
          },
          {
              id: "d034187f243acd64942b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41284&size=130"
          },
          {
              id: "cdd2049938dcd18288cd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41285&size=130"
          },
          {
              id: "66aface490a179ff20b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41286&size=130"
          },
          {
              id: "f9c932820ec7e799bed6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41287&size=130"
          },
          {
              id: "a35e67155b50b20eeb41",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41288&size=130"
          },
          {
              id: "8c1d495675139c4dc502",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41289&size=130"
          },
          {
              id: "6a66ac2d906879362079",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41290&size=130"
          },
          {
              id: "f665312e0d6be435bd7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41291&size=130"
          },
          {
              id: "d12311682d2dc4739d3c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41292&size=130"
          },
          {
              id: "f454351f095ae004b94b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41293&size=130"
          },
          {
              id: "25fce7b7dbf232ac6be3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41294&size=130"
          },
          {
              id: "55ce9685aac0439e1ad1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41295&size=130"
          },
          {
              id: "3710eb5bd71e3e40670f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41296&size=130"
          },
          {
              id: "96984bd377969ec8c787",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41297&size=130"
          },
          {
              id: "1d89c3c2ff8716d94f96",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41298&size=130"
          },
          {
              id: "4f739038ac7d45231c6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41299&size=130"
          },
          {
              id: "59a681edbda854f60db9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41300&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerec0f44cd788891d6c899.png",
      price: "Miễn phí",
      name: "Sugar Cubs",
      thumbImg: "https://zalo-api.zadn.vn/4/5/0/a/d/10276/preview/sugar_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/4/5/0/a/d/10276/icon_pre/sugar_thumb.png",
      id: "087ba0b99cfc75a22ced",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "d94b88f0b4b55deb04a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20957&size=130"
          },
          {
              id: "d65484efb8aa51f408bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20958&size=130"
          },
          {
              id: "d66b85d0b99550cb0984",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20959&size=130"
          },
          {
              id: "31a55d1e615b8805d14a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20960&size=130"
          },
          {
              id: "490824b318f6f1a8a8e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20961&size=130"
          },
          {
              id: "906cfed7c2922bcc7283",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20962&size=130"
          },
          {
              id: "cf37a08c9cc975972cd8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20963&size=130"
          },
          {
              id: "1ea976124a57a309fa46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20964&size=130"
          },
          {
              id: "8c4ee5f5d9b030ee69a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20965&size=130"
          },
          {
              id: "64d40e6f322adb74823b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20966&size=130"
          },
          {
              id: "ed0886b3baf653a80ae7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20967&size=130"
          },
          {
              id: "9d0cf9b7c5f22cac75e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20968&size=130"
          },
          {
              id: "1d7778cc4489add7f498",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20969&size=130"
          },
          {
              id: "f03c9687aac2439c1ad3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20970&size=130"
          },
          {
              id: "655b02e03ea5d7fb8eb4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20971&size=130"
          },
          {
              id: "019b61205d65b43bed74",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20972&size=130"
          },
          {
              id: "c439a58299c7709929d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20973&size=130"
          },
          {
              id: "5c403efb02beebe0b2af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20974&size=130"
          },
          {
              id: "81c2e279de3c37626e2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20975&size=130"
          },
          {
              id: "60ad1c162053c90d9042",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20976&size=130"
          },
          {
              id: "55d5286e142bfd75a43a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20977&size=130"
          },
          {
              id: "49e237590b1ce242bb0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20978&size=130"
          },
          {
              id: "49df36640a21e37fba30",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20979&size=130"
          },
          {
              id: "2985513e6d7b8425dd6a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20980&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickercbb38a70b6355f6b0624.png",
      price: "Miễn phí",
      name: "Bé Dâu và Anh ",
      thumbImg: "https://zalo-api.zadn.vn/3/3/f/2/4/10701/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/3/3/f/2/4/10701/icon_pre/bedauvaanh_thumb.png",
      id: "e11ea0dd9c9875c62c89",
      source: "",
      totalImage: "16 sticker",
      value: [
          {
              id: "281267905bd5b28bebc4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26819&size=130"
          },
          {
              id: "cbd18353bf1656480f07",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26820&size=130"
          },
          {
              id: "a83ae1b8ddfd34a36dec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26821&size=130"
          },
          {
              id: "11125b9067d58e8bd7c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26822&size=130"
          },
          {
              id: "6f8e240c1849f117a858",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26823&size=130"
          },
          {
              id: "f9e3bd618124687a3135",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26824&size=130"
          },
          {
              id: "7a8d3f0f034aea14b35b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26825&size=130"
          },
          {
              id: "16bd503f6c7a8524dc6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26826&size=130"
          },
          {
              id: "9ee5d967e5220c7c5533",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26827&size=130"
          },
          {
              id: "1e525ed062958bcbd284",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26828&size=130"
          },
          {
              id: "efd1ae5392167b482207",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26829&size=130"
          },
          {
              id: "729530170c52e50cbc43",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26830&size=130"
          },
          {
              id: "dabe993ca5794c271568",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26831&size=130"
          },
          {
              id: "21937d114154a80af145",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26832&size=130"
          },
          {
              id: "72e62f641321fa7fa330",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26833&size=130"
          },
          {
              id: "a37ffdfdc1b828e671a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26834&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker34501c9120d4c98a90c5.png",
      price: "Miễn phí",
      name: "Lovely Sugar Cubs",
      thumbImg: "https://zalo-api.zadn.vn/1/3/3/0/f/11172/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/1/3/3/0/f/11172/icon_pre/lovesugarcubs_thumb.png",
      id: "428c6a4d5608bf56e619",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "a3ccfba0c7e52ebb77f4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34516&size=130"
          },
          {
              id: "43131a7f263acf64962b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34517&size=130"
          },
          {
              id: "d1208b4cb7095e570718",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34518&size=130"
          },
          {
              id: "8862d30eef4b06155f5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34519&size=130"
          },
          {
              id: "a0fcf490c8d5218b78c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34520&size=130"
          },
          {
              id: "b93bec57d012394c6003",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34521&size=130"
          },
          {
              id: "d9918ffdb3b85ae603a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34522&size=130"
          },
          {
              id: "6ad13dbd01f8e8a6b1e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34523&size=130"
          },
          {
              id: "e6f5b6998adc63823acd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34524&size=130"
          },
          {
              id: "571406783a3dd3638a2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34525&size=130"
          },
          {
              id: "b4dfe6b3daf633a86ae7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34526&size=130"
          },
          {
              id: "6e0d3d610124e87ab135",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34527&size=130"
          },
          {
              id: "5d2931450d00e45ebd11",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34528&size=130"
          },
          {
              id: "7d4810242c61c53f9c70",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34529&size=130"
          },
          {
              id: "100e7e624227ab79f236",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34530&size=130"
          },
          {
              id: "f9d096bcaaf943a71ae8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34531&size=130"
          },
          {
              id: "d36fbb0387466e183757",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34532&size=130"
          },
          {
              id: "ff4f9623aa6643381a77",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34533&size=130"
          },
          {
              id: "ed9087fcbbb952e70ba8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34534&size=130"
          },
          {
              id: "c067ab0b974e7e10275f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34535&size=130"
          },
          {
              id: "74b510d92c9cc5c29c8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34536&size=130"
          },
          {
              id: "f5e2908eaccb45951cda",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34537&size=130"
          },
          {
              id: "ebee8d82b1c7589901d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34538&size=130"
          },
          {
              id: "26df41b37df694a8cde7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34539&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickerc7e115222967c0399976.png",
      price: "Miễn phí",
      name: "Yêu Quá Đi",
      thumbImg: "https://zalo-api.zadn.vn/2/3/6/5/2/10590/preview/love_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/3/6/5/2/10590/icon_pre/love_thumb.png",
      id: "bf596d9a51dfb881e1ce",
      source: "",
      totalImage: "24 sticker",
      value: [
          {
              id: "21838f0ab34f5a11035e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25378&size=130"
          },
          {
              id: "ee2141a87ded94b3cdfc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25379&size=130"
          },
          {
              id: "933c3bb507f0eeaeb7e1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25380&size=130"
          },
          {
              id: "a4cd0d443101d85f8110",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25381&size=130"
          },
          {
              id: "825928d01495fdcba484",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25382&size=130"
          },
          {
              id: "b95a12d32e96c7c89e87",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25383&size=130"
          },
          {
              id: "4f0beb82d7c73e9967d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25384&size=130"
          },
          {
              id: "9ea53b2c0769ee37b778",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25385&size=130"
          },
          {
              id: "4f20e9a9d5ec3cb265fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25386&size=130"
          },
          {
              id: "e55642df7e9a97c4ce8b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25387&size=130"
          },
          {
              id: "b1fc11752d30c46e9d21",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25388&size=130"
          },
          {
              id: "987d39f405b1ecefb5a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25389&size=130"
          },
          {
              id: "6553c7dafb9f12c14b8e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25390&size=130"
          },
          {
              id: "e0e2436b7f2e9670cf3f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25391&size=130"
          },
          {
              id: "90282ca110e4f9baa0f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25392&size=130"
          },
          {
              id: "4016fd9fc1da288471cb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25393&size=130"
          },
          {
              id: "5631e8b8d4fd3da364ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25394&size=130"
          },
          {
              id: "01f7be7e823b6b65322a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25395&size=130"
          },
          {
              id: "92d62a5f161aff44a60b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25396&size=130"
          },
          {
              id: "4b06f28fceca27947edb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25397&size=130"
          },
          {
              id: "a47c1ef522b0cbee92a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25398&size=130"
          },
          {
              id: "16e6ad6f912a7874213b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25399&size=130"
          },
          {
              id: "80c7344e080be155b81a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25400&size=130"
          },
          {
              id: "0487b10e8d4b64153d5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25401&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerbbc99708ab4d42131b5c.png",
      price: "Miễn phí",
      name: "Zookiz Đang Yêu",
      thumbImg: "https://zalo-api.zadn.vn/0/a/e/7/b/11168/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/0/a/e/7/b/11168/icon_pre/zookizlove_thumb.png",
      id: "28ed042c3869d1378878",
      source: "Zookiz",
      totalImage: "12 sticker",
      value: [
          {
              id: "0fee1e8222c7cb9992d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34461&size=130"
          },
          {
              id: "1c700e1c3259db078248",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34462&size=130"
          },
          {
              id: "398d2ae116a4fffaa6b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34463&size=130"
          },
          {
              id: "bb62970eab4b42151b5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34464&size=130"
          },
          {
              id: "e198ccf4f0b119ef40a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34465&size=130"
          },
          {
              id: "8358ad349171782f2160",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34466&size=130"
          },
          {
              id: "567a79164553ac0df542",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34467&size=130"
          },
          {
              id: "018429e815adfcf3a5bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34468&size=130"
          },
          {
              id: "823fab5397167e482707",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34469&size=130"
          },
          {
              id: "17d23dbe01fbe8a5b1ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34470&size=130"
          },
          {
              id: "58e573894fcca692ffdd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34471&size=130"
          },
          {
              id: "422d66415a04b35aea15",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34472&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickere38761455d00b45eed11.png",
      price: "Miễn phí",
      name: "Quỳnh Aka Và Em",
      thumbImg: "https://zalo-api.zadn.vn/8/0/5/b/7/10254/preview/aka_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/8/0/5/b/7/10254/icon_pre/aka_thumb.png",
      id: "b65e349c08d9e187b8c8",
      source: "QuynhAka",
      totalImage: "20 sticker",
      value: [
          {
              id: "bc86533c6f798627df68",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20579&size=130"
          },
          {
              id: "ce0226b81afdf3a3aaec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20580&size=130"
          },
          {
              id: "5cfbb5418904605a3915",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20581&size=130"
          },
          {
              id: "61958b2fb76a5e34077b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20582&size=130"
          },
          {
              id: "2135ca8ff6ca1f9446db",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20583&size=130"
          },
          {
              id: "77f6934caf0946571f18",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20584&size=130"
          },
          {
              id: "1792f228ce6d27337e7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20585&size=130"
          },
          {
              id: "2ec4c87ef43b1d65442a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20586&size=130"
          },
          {
              id: "f2f1154b290ec050991f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20587&size=130"
          },
          {
              id: "9a597ae346a6aff8f6b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20588&size=130"
          },
          {
              id: "f17410ce2c8bc5d59c9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20589&size=130"
          },
          {
              id: "fa2f189524d0cd8e94c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20590&size=130"
          },
          {
              id: "46eba5519914704a2905",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20591&size=130"
          },
          {
              id: "841f78a544e0adbef4f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20592&size=130"
          },
          {
              id: "fbc4067e3a3bd3658a2a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20593&size=130"
          },
          {
              id: "51e4af5e931b7a45230a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20594&size=130"
          },
          {
              id: "24f9db43e7060e585717",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20595&size=130"
          },
          {
              id: "6f6497deab9b42c51b8a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20596&size=130"
          },
          {
              id: "8aff73454f00a65eff11",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20597&size=130"
          },
          {
              id: "4917b3ad8fe866b63ff9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20598&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickerd5b064725837b169e826.png",
      price: "Miễn phí",
      name: "Sweet Sugar Cubs",
      thumbImg: "https://zalo-api.zadn.vn/b/1/f/1/3/10301/preview/cubs_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/b/1/f/1/3/10301/icon_pre/cubs_thumb.png",
      id: "b20003c23f87d6d98f96",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "53fb68425407bd59e416",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21431&size=130"
          },
          {
              id: "524c66f55ab0b3eeeaa1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21432&size=130"
          },
          {
              id: "b1d2846bb82e5170083f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21433&size=130"
          },
          {
              id: "1add2c641021f97fa030",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21434&size=130"
          },
          {
              id: "886dbfd483916acf3380",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21435&size=130"
          },
          {
              id: "5cc56c7c5039b967e028",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21436&size=130"
          },
          {
              id: "b7ec8655ba10534e0a01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21437&size=130"
          },
          {
              id: "e5fbd742eb0702595b16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21438&size=130"
          },
          {
              id: "3f7a0cc33086d9d88097",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21439&size=130"
          },
          {
              id: "511a1da321e6c8b891f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21440&size=130"
          },
          {
              id: "637f2ec61283fbdda292",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21441&size=130"
          },
          {
              id: "b243fcfac0bf29e170ae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21442&size=130"
          },
          {
              id: "a4d5eb6cd7293e776738",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21443&size=130"
          },
          {
              id: "f5ecbd558110684e3101",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21444&size=130"
          },
          {
              id: "a3b5ea0cd6493f176658",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21445&size=130"
          },
          {
              id: "5fb11508294dc013995c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21446&size=130"
          },
          {
              id: "2c83673a5b7fb221eb6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21447&size=130"
          },
          {
              id: "727436cd0a88e3d6ba99",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21448&size=130"
          },
          {
              id: "099b4c2270679939c076",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21449&size=130"
          },
          {
              id: "165a50e36ca685f8dcb7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21450&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrsticker0922bee082a56bfb32b4.png",
      price: "Miễn phí",
      name: "Sugar Cubs 2",
      thumbImg: "https://zalo-api.zadn.vn/5/f/e/8/f/10299/preview/cubs_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/f/e/8/f/10299/icon_pre/cubs_thumb.png",
      id: "1e1da9df959a7cc4258b",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "09e70a5e361bdf45860a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21391&size=130"
          },
          {
              id: "d1b1cd08f14d1813415c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21392&size=130"
          },
          {
              id: "0cd4116d2d28c4769d39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21393&size=130"
          },
          {
              id: "c552dbebe7ae0ef057bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21394&size=130"
          },
          {
              id: "f4b8eb01d7443e1a6755",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21395&size=130"
          },
          {
              id: "9dd88561b924507a0935",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21396&size=130"
          },
          {
              id: "9be18258be1d57430e0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21397&size=130"
          },
          {
              id: "9b908129bd6c54320d7d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21398&size=130"
          },
          {
              id: "988d8334bf71562f0f60",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21399&size=130"
          },
          {
              id: "b2caa6739a3673682a27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21400&size=130"
          },
          {
              id: "0c6119d8259dccc3958c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21401&size=130"
          },
          {
              id: "e359f5e0c9a520fb79b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21402&size=130"
          },
          {
              id: "f083e73adb7f32216b6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21403&size=130"
          },
          {
              id: "a70bb7b28bf762a93be6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21404&size=130"
          },
          {
              id: "505e41e77da294fccdb3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21405&size=130"
          },
          {
              id: "e485f63cca7923277a68",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21406&size=130"
          },
          {
              id: "f72fe496d8d3318d68c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21407&size=130"
          },
          {
              id: "b1d19d68a12d4873113c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21408&size=130"
          },
          {
              id: "6ee043597f1c9642cf0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21409&size=130"
          },
          {
              id: "784856f16ab483eadaa5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21410&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerb0ef9d22a16748391176.png",
      price: "Miễn phí",
      name: " Bên Nhau",
      thumbImg: "https://zalo-api.zadn.vn/7/5/2/0/f/10145/preview/rabbitcouple_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/5/2/0/f/10145/icon_pre/rabbitcouple_thumb.png",
      id: "f6bbdb76e7330e6d5722",
      source: "Caerux Co.,Ltd.",
      totalImage: "20 sticker",
      value: [
          {
              id: "339835350970e02eb961",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18314&size=130"
          },
          {
              id: "2ff6285b141efd40a40f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18315&size=130"
          },
          {
              id: "f255f2f8cebd27e37eac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18316&size=130"
          },
          {
              id: "f0acf101cd44241a7d55",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18317&size=130"
          },
          {
              id: "504452e96eac87f2debd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18318&size=130"
          },
          {
              id: "e4dbe776db33326d6b22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18319&size=130"
          },
          {
              id: "e239fe94c2d12b8f72c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18320&size=130"
          },
          {
              id: "fd2de080dcc5359b6cd4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18321&size=130"
          },
          {
              id: "e8ebf646ca03235d7a12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18322&size=130"
          },
          {
              id: "096a16c72a82c3dc9a93",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18323&size=130"
          },
          {
              id: "92b38a1eb65b5f05064a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18324&size=130"
          },
          {
              id: "384521e81dadf4f3adbc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18325&size=130"
          },
          {
              id: "88b49219ae5c47021e4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18326&size=130"
          },
          {
              id: "ec17f7bacbff22a17bee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18327&size=130"
          },
          {
              id: "1bac0f013344da1a8355",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18328&size=130"
          },
          {
              id: "6fdd7a704635af6bf624",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18329&size=130"
          },
          {
              id: "18750ed8329ddbc3828c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18330&size=130"
          },
          {
              id: "368c21211d64f43aad75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18331&size=130"
          },
          {
              id: "2cee3c430006e958b017",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18332&size=130"
          },
          {
              id: "620e73a34fe6a6b8fff7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18333&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/4/qrstickerc5305ef162b48bead2a5.png",
      price: "Miễn phí",
      name: "Mimi & Neko",
      thumbImg: "https://zalo-api.zadn.vn/0/9/d/7/d/11031/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/0/9/d/7/d/11031/icon_pre/mimineko_thumb.png",
      id: "cb5a509b6cde8580dccf",
      source: "Minto Inc. ",
      totalImage: "24 sticker",
      value: [
          {
              id: "89c62a511614ff4aa605",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32047&size=130"
          },
          {
              id: "7d63c1f4fdb114ef4da0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32048&size=130"
          },
          {
              id: "cf1072874ec2a79cfed3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32049&size=130"
          },
          {
              id: "92352ca210e7f9b9a0f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32050&size=130"
          },
          {
              id: "6bc6d451e814014a5805",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32051&size=130"
          },
          {
              id: "252c9dbba1fe48a011ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32052&size=130"
          },
          {
              id: "f1fb486c74299d77c438",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32053&size=130"
          },
          {
              id: "91732be417a1feffa7b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32054&size=130"
          },
          {
              id: "31b08a27b6625f3c0673",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32055&size=130"
          },
          {
              id: "5b6eeff9d3bc3ae263ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32056&size=130"
          },
          {
              id: "eede5b49670c8e52d71d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32057&size=130"
          },
          {
              id: "e8955e0262478b19d256",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32058&size=130"
          },
          {
              id: "fce54b7277379e69c726",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32059&size=130"
          },
          {
              id: "5b00eb97d7d23e8c67c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32060&size=130"
          },
          {
              id: "eb515ac666838fddd692",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32061&size=130"
          },
          {
              id: "92b9202e1c6bf535ac7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32062&size=130"
          },
          {
              id: "59b5ea22d6673f396676",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32063&size=130"
          },
          {
              id: "9ff153666f23867ddf32",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32064&size=130"
          },
          {
              id: "ec6f21f81dbdf4e3adac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32065&size=130"
          },
          {
              id: "3c4cf2dbce9e27c07e8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32066&size=130"
          },
          {
              id: "f4a93b3e077bee25b76a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32067&size=130"
          },
          {
              id: "e1c429531516fc48a507",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32068&size=130"
          },
          {
              id: "bea677314b74a22afb65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32069&size=130"
          },
          {
              id: "55339fa4a3e14abf13f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32070&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickera55c8d98b1dd588301cc.png",
      price: "Miễn phí",
      name: "Zookiz Tết Rộn Ràng",
      thumbImg: "https://zalo-api.zadn.vn/7/8/6/6/4/11940/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/7/8/6/6/4/11940/icon_pre/zookiztetronrang_thumb.png",
      id: "a8928056bc13554d0c02",
      source: "0",
      totalImage: "15 sticker",
      value: [
          {
              id: "80de7d8741c2a89cf1d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45937&size=130"
          },
          {
              id: "a1935fca638f8ad1d39e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45938&size=130"
          },
          {
              id: "fc8003d93f9cd6c28f8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45939&size=130"
          },
          {
              id: "f26c0a353670df2e8661",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45940&size=130"
          },
          {
              id: "09d7f08ecccb25957cda",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45941&size=130"
          },
          {
              id: "cf113548090de053b91c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45942&size=130"
          },
          {
              id: "12b8e9e1d5a43cfa65b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45943&size=130"
          },
          {
              id: "84bf70e64ca3a5fdfcb2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45944&size=130"
          },
          {
              id: "3068c531f974102a4965",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45945&size=130"
          },
          {
              id: "f02906703a35d36b8a24",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45946&size=130"
          },
          {
              id: "88cf7f9643d3aa8df3c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45947&size=130"
          },
          {
              id: "3af4caadf6e81fb646f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45948&size=130"
          },
          {
              id: "35b0c4e9f8ac11f248bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45949&size=130"
          },
          {
              id: "0164f33dcf7826267f69",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45950&size=130"
          },
          {
              id: "8d547e0d4248ab16f259",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45951&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker542e65ec59a9b0f7e9b8.png",
      price: "Miễn phí",
      name: "Vui Tết Cùng Lạt",
      thumbImg: "https://zalo-api.zadn.vn/8/2/0/7/7/10429/preview/lat_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/8/2/0/7/7/10429/icon_pre/lat_thumb.png",
      id: "7b264ae476a19fffc6b0",
      source: "Lạt",
      totalImage: "15 sticker",
      value: [
          {
              id: "fc26289714d2fd8ca4c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23384&size=130"
          },
          {
              id: "dca109103555dc0b8544",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23385&size=130"
          },
          {
              id: "069ed02fec6a05345c7b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23386&size=130"
          },
          {
              id: "113dc68cfac913974ad8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23387&size=130"
          },
          {
              id: "819851296d6c8432dd7d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23388&size=130"
          },
          {
              id: "788da93c95797c272568",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23389&size=130"
          },
          {
              id: "04a1d610ea55030b5a44",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23390&size=130"
          },
          {
              id: "52ea815bbd1e54400d0f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23391&size=130"
          },
          {
              id: "5383bf3283776a293366",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23392&size=130"
          },
          {
              id: "15acf81dc4582d067449",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23393&size=130"
          },
          {
              id: "2ca7c216fe53170d4e42",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23394&size=130"
          },
          {
              id: "eac90578393dd063892c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23395&size=130"
          },
          {
              id: "62858a34b6715f2f0660",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23396&size=130"
          },
          {
              id: "a4c24d7371369868c127",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23397&size=130"
          },
          {
              id: "836f69de559bbcc5e58a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23398&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7072b3b68ff366ad3fe2.png",
      price: "Miễn phí",
      name: "Tết Rồi, Suri Ơi",
      thumbImg: "https://zalo-api.zadn.vn/c/5/1/9/d/11855/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/c/5/1/9/d/11855/icon_pre/suritet_thumb.png",
      id: "3299f15dcd1824467d09",
      source: "",
      totalImage: "12 sticker",
      value: [
          {
              id: "c92464605825b17be834",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44577&size=130"
          },
          {
              id: "a66e082a346fdd31847e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44578&size=130"
          },
          {
              id: "59e7f6a3cae623b87af7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44579&size=130"
          },
          {
              id: "8a6222261e63f73dae72",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44580&size=130"
          },
          {
              id: "5d46f402c84721197856",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44581&size=130"
          },
          {
              id: "9abb30ff0cbae5e4bcab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44582&size=130"
          },
          {
              id: "961b3d5f011ae844b10b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44583&size=130"
          },
          {
              id: "5afdfeb9c2fc2ba272ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44584&size=130"
          },
          {
              id: "8a692f2d1368fa36a379",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44585&size=130"
          },
          {
              id: "e5d9439d7fd89686cfc9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44586&size=130"
          },
          {
              id: "ef0f484b740e9d50c41f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44587&size=130"
          },
          {
              id: "5419f45dc81821467809",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44588&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc301b8c284876dd93496.png",
      price: "Miễn phí",
      name: "Noo-Hin Nghỉ Tết",
      thumbImg: "https://zalo-api.zadn.vn/c/9/f/0/6/10743/preview/nohin2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/9/f/0/6/10743/icon_pre/nohin2_thumb.png",
      id: "626919aa25efccb195fe",
      source: "",
      totalImage: "16 sticker",
      value: [
          {
              id: "2fc528441401fd5fa410",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27531&size=130"
          },
          {
              id: "930f938eafcb46951fda",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27532&size=130"
          },
          {
              id: "256324e218a7f1f9a8b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27533&size=130"
          },
          {
              id: "b638b4b988fc61a238ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27534&size=130"
          },
          {
              id: "e6f6e577d932306c6923",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27535&size=130"
          },
          {
              id: "f172edf3d1b638e861a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27536&size=130"
          },
          {
              id: "daa6c727fb62123c4b73",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27537&size=130"
          },
          {
              id: "77be693f557abc24e56b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27538&size=130"
          },
          {
              id: "0ac115402905c05b9914",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27539&size=130"
          },
          {
              id: "2eb736360a73e32dba62",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27540&size=130"
          },
          {
              id: "9c7485f5b9b050ee09a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27541&size=130"
          },
          {
              id: "0ad210532c16c5489c07",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27542&size=130"
          },
          {
              id: "83809801a4444d1a1455",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27543&size=130"
          },
          {
              id: "ba15ae9492d17b8f22c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27544&size=130"
          },
          {
              id: "af52bad386966fc83687",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27545&size=130"
          },
          {
              id: "db93cd12f15718094146",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27546&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker9ce15c256060893ed071.png",
      price: "Miễn phí",
      name: "Zookiz Du Xuân",
      thumbImg: "https://zalo-api.zadn.vn/b/a/6/7/e/11852/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/b/a/6/7/e/11852/icon_pre/zookizvuixuan_thumb.png",
      id: "874047847bc1929fcbd0",
      source: "Zookiz",
      totalImage: "12 sticker",
      value: [
          {
              id: "65251c622027c9799036",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44533&size=130"
          },
          {
              id: "50442a031646ff18a657",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44534&size=130"
          },
          {
              id: "ac1fd758eb1d02435b0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44535&size=130"
          },
          {
              id: "19fa6dbd51f8b8a6e1e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44536&size=130"
          },
          {
              id: "8826fd61c124287a7135",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44537&size=130"
          },
          {
              id: "b236c471f834116a4825",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44538&size=130"
          },
          {
              id: "fa7e8d39b17c5822016d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44539&size=130"
          },
          {
              id: "81acf1ebcdae24f07dbf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44540&size=130"
          },
          {
              id: "d3e0a2a79ee277bc2ef3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44541&size=130"
          },
          {
              id: "0bd7799045d5ac8bf5c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44542&size=130"
          },
          {
              id: "69ed1aaa26efcfb196fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44543&size=130"
          },
          {
              id: "dc7150356c70852edc61",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44544&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker89289deca1a948f711b8.png",
      price: "Miễn phí",
      name: "Mùng Một - Ngày Rằm",
      thumbImg: "https://zalo-api.zadn.vn/9/8/4/3/a/11928/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/9/8/4/3/a/11928/icon_pre/mungmotngayram_thumb.png",
      id: "0a381efc22b9cbe792a8",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "a7fb9aa3a6e64fb816f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45745&size=130"
          },
          {
              id: "7e7d40257c60953ecc71",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45746&size=130"
          },
          {
              id: "01743e2c0269eb37b278",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45747&size=130"
          },
          {
              id: "1c55240d1848f116a859",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45748&size=130"
          },
          {
              id: "72fd4ba577e09ebec7f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45749&size=130"
          },
          {
              id: "c1fefba6c7e32ebd77f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45750&size=130"
          },
          {
              id: "9c3ea7669b23727d2b32",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45751&size=130"
          },
          {
              id: "ee78da20e6650f3b5674",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45752&size=130"
          },
          {
              id: "43b576ed4aa8a3f6fab9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45753&size=130"
          },
          {
              id: "71fa47a27be792b9cbf6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45754&size=130"
          },
          {
              id: "f1c2c69afadf13814ace",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45755&size=130"
          },
          {
              id: "d2e1e2b9defc37a26eed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45756&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc87e0abf36fadfa486eb.png",
      price: "Miễn phí",
      name: "Hamster Đón Tết",
      thumbImg: "https://zalo-api.zadn.vn/d/f/3/2/1/11086/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/d/f/3/2/1/11086/icon_pre/hamster_thumb.png",
      id: "fb0039c10584ecdab595",
      source: "Oanh Vu",
      totalImage: "12 sticker",
      value: [
          {
              id: "0454463e7a7b9325ca6a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32974&size=130"
          },
          {
              id: "d3499023ac6645381c77",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32975&size=130"
          },
          {
              id: "1cfc40967cd3958dccc2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32976&size=130"
          },
          {
              id: "d815857fb93a5064092b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32977&size=130"
          },
          {
              id: "34b46ade569bbfc5e68a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32978&size=130"
          },
          {
              id: "73db2cb110f4f9aaa0e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32979&size=130"
          },
          {
              id: "d7ca8fa0b3e55abb03f4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32980&size=130"
          },
          {
              id: "ba62e308df4d36136f5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32981&size=130"
          },
          {
              id: "727828121457fd09a446",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32982&size=130"
          },
          {
              id: "f3f2a89894dd7d8324cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32983&size=130"
          },
          {
              id: "2a4b7e214264ab3af275",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32984&size=130"
          },
          {
              id: "b059e533d97630286967",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32985&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker4780374d0b08e256bb19.png",
      price: "Miễn phí",
      name: "Táo Hành Quân",
      thumbImg: "https://zalo-api.zadn.vn/0/b/3/2/f/10236/preview/tao_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/0/b/3/2/f/10236/icon_pre/tao_thumb.png",
      id: "67ad17602b25c27b9b34",
      source: "",
      totalImage: "30 sticker",
      value: [
          {
              id: "256d28ca148ffdd1a49e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19841&size=130"
          },
          {
              id: "69a9670e5b4bb215eb5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19842&size=130"
          },
          {
              id: "5b27548068c5819bd8d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19843&size=130"
          },
          {
              id: "75a27d054140a81ef151",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19844&size=130"
          },
          {
              id: "5e4157e66ba382fddbb2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19845&size=130"
          },
          {
              id: "20312a9616d3ff8da6c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19846&size=130"
          },
          {
              id: "d86bd3ccef8906d75f98",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19847&size=130"
          },
          {
              id: "1a521ef522b0cbee92a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19848&size=130"
          },
          {
              id: "d14ad4ede8a801f658b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19849&size=130"
          },
          {
              id: "b575b3d28f9766c93f86",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19850&size=130"
          },
          {
              id: "bd44bae386a66ff836b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19851&size=130"
          },
          {
              id: "204320e41ca1f5ffacb0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19852&size=130"
          },
          {
              id: "d315d2b2eef707a95ee6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19853&size=130"
          },
          {
              id: "c86fcac8f68d1fd3469c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19854&size=130"
          },
          {
              id: "87c8846fb82a5174083b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19855&size=130"
          },
          {
              id: "1aa8060f3a4ad3148a5b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19856&size=130"
          },
          {
              id: "d4d3c974f5311c6f4520",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19857&size=130"
          },
          {
              id: "4efa505d6c188546dc09",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19858&size=130"
          },
          {
              id: "d43acb9df7d81e8647c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19859&size=130"
          },
          {
              id: "50b3481474519d0fc440",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19860&size=130"
          },
          {
              id: "bf19a6be9afb73a52aea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19861&size=130"
          },
          {
              id: "a8fdb25a8e1f67413e0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19862&size=130"
          },
          {
              id: "728e6929556cbc32e57d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19863&size=130"
          },
          {
              id: "eef8fa5fc61a2f44760b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19864&size=130"
          },
          {
              id: "b064a5c3998670d82997",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19865&size=130"
          },
          {
              id: "9d2e8b89b7cc5e9207dd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19866&size=130"
          },
          {
              id: "301a27bd1bf8f2a6abe9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19867&size=130"
          },
          {
              id: "5a4d4aea76af9ff1c6be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19868&size=130"
          },
          {
              id: "53f9425e7e1b9745ce0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19869&size=130"
          },
          {
              id: "00e8124f2e0ac7549e1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19870&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker0b40788d44c8ad96f4d9.png",
      price: "Miễn phí",
      name: "Nuddie Đón Tết",
      thumbImg: "https://zalo-api.zadn.vn/1/e/9/4/9/10239/preview/ga2017_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/1/e/9/4/9/10239/icon_pre/ga2017_thumb.png",
      id: "034d70804cc5a59bfcd4",
      source: "",
      totalImage: "14 sticker",
      value: [
          {
              id: "b7df9378af3d46631f2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19880&size=130"
          },
          {
              id: "ad1d88bab4ff5da104ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19881&size=130"
          },
          {
              id: "2a3b0c9c30d9d98780c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19882&size=130"
          },
          {
              id: "599c7e3b427eab20f26f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19883&size=130"
          },
          {
              id: "b15991feadbb44e51daa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19884&size=130"
          },
          {
              id: "e489c52ef96b1035497a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19885&size=130"
          },
          {
              id: "6c574ef072b59bebc2a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19886&size=130"
          },
          {
              id: "bdc89e6fa22a4b74123b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19887&size=130"
          },
          {
              id: "53a16f065343ba1de352",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19888&size=130"
          },
          {
              id: "52ed6f4a530fba51e31e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19889&size=130"
          },
          {
              id: "65785bdf679a8ec4d78b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19890&size=130"
          },
          {
              id: "7ba644017844911ac855",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19891&size=130"
          },
          {
              id: "3b4103e63fa3d6fd8fb2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19892&size=130"
          },
          {
              id: "5da564025847b119e856",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19893&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickere91cf2dece9b27c57e8a.png",
      price: "Miễn phí",
      name: "Holiday Cùng Lạt",
      thumbImg: "https://zalo-api.zadn.vn/5/d/f/d/3/10391/preview/lat_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/d/f/d/3/10391/icon_pre/lat_thumb.png",
      id: "58be437c7f399667cf28",
      source: "Lạt",
      totalImage: "20 sticker",
      value: [
          {
              id: "336a06d93a9cd3c28a8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22969&size=130"
          },
          {
              id: "db39ed8ad1cf389161de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22970&size=130"
          },
          {
              id: "18792fca138ffad1a39e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22971&size=130"
          },
          {
              id: "b2d58266be23577d0e32",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22972&size=130"
          },
          {
              id: "088f393c0579ec27b568",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22973&size=130"
          },
          {
              id: "d0dce26fde2a37746e3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22974&size=130"
          },
          {
              id: "c98afa39c67c2f22766d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22975&size=130"
          },
          {
              id: "55bf190c2549cc179558",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22976&size=130"
          },
          {
              id: "cc1b81a8bded54b30dfc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22977&size=130"
          },
          {
              id: "6789293a157ffc21a56e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22978&size=130"
          },
          {
              id: "ce5781e4bda154ff0db0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22979&size=130"
          },
          {
              id: "16a85e1b625e8b00d24f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22980&size=130"
          },
          {
              id: "f5f8bc4b800e6950301f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22981&size=130"
          },
          {
              id: "d0c09a73a6364f681627",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22982&size=130"
          },
          {
              id: "405e0bed37a8def687b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22983&size=130"
          },
          {
              id: "cf6b8bd8b79d5ec3078c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22984&size=130"
          },
          {
              id: "5b1f1eac22e9cbb792f8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22985&size=130"
          },
          {
              id: "697e2fcd1388fad6a399",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22986&size=130"
          },
          {
              id: "91bed60dea4803165a59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22987&size=130"
          },
          {
              id: "baaffa1cc6592f077648",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22988&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerce8de249de0c37526e1d.png",
      price: "Miễn phí",
      name: "Usagyuuun Christmas",
      thumbImg: "https://zalo-api.zadn.vn/f/9/9/4/9/11936/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/f/9/9/4/9/11936/icon_pre/usagyuuunchristmas_thumb.png",
      id: "5a7376b74af2a3acfae3",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "03a2aefb92be7be022af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45857&size=130"
          },
          {
              id: "3a63943aa87f4121186e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45858&size=130"
          },
          {
              id: "0868a7319b74722a2b65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45859&size=130"
          },
          {
              id: "bb8c13d52f90c6ce9f81",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45860&size=130"
          },
          {
              id: "c0dd698455c1bc9fe5d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45861&size=130"
          },
          {
              id: "e349491075559c0bc544",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45862&size=130"
          },
          {
              id: "97583c010044e91ab055",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45863&size=130"
          },
          {
              id: "b24b16122a57c3099a46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45864&size=130"
          },
          {
              id: "c85e6d075142b81ce153",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45865&size=130"
          },
          {
              id: "7b5add03e14608185157",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45866&size=130"
          },
          {
              id: "b80a1f532316ca489307",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45867&size=130"
          },
          {
              id: "72eed2b7eef207ac5ee3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45868&size=130"
          },
          {
              id: "4f25ee7cd2393b676228",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45869&size=130"
          },
          {
              id: "9026327f0e3ae764be2b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45870&size=130"
          },
          {
              id: "da7879214564ac3af575",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45871&size=130"
          },
          {
              id: "a52a19732536cc689527",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45872&size=130"
          },
          {
              id: "6d34d06dec2805765c39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45873&size=130"
          },
          {
              id: "0526bb7f873a6e64372b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45874&size=130"
          },
          {
              id: "b29d0dc43181d8df8190",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45875&size=130"
          },
          {
              id: "9dc5259c19d9f087a9c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45876&size=130"
          },
          {
              id: "f33e4a6776229f7cc633",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45877&size=130"
          },
          {
              id: "0179bb2087656e3b3774",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45878&size=130"
          },
          {
              id: "6dd9d680eac5039b5ad4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45879&size=130"
          },
          {
              id: "8a763e2f026aeb34b27b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45880&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker0ca85e6562208b7ed231.png",
      price: "Miễn phí",
      name: "Hiro & Shino - New Year Season",
      thumbImg: "https://zalo-api.zadn.vn/f/3/6/5/c/10206/preview/hishi_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/f/3/6/5/c/10206/icon_pre/hishi_thumb.png",
      id: "2d057fc8438daad3f39c",
      source: "Artbox Friends.",
      totalImage: "20 sticker",
      value: [
          {
              id: "354c29ed15a8fcf6a5b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19344&size=130"
          },
          {
              id: "51144cb570f099aec0e1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19345&size=130"
          },
          {
              id: "8f1991b8adfd44a31dec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19346&size=130"
          },
          {
              id: "ce42d1e3eda604f85db7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19347&size=130"
          },
          {
              id: "9ce98448b80d5153081c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19348&size=130"
          },
          {
              id: "01ba181b245ecd00944f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19349&size=130"
          },
          {
              id: "00391a9826ddcf8396cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19350&size=130"
          },
          {
              id: "b537ae9692d37b8d22c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19351&size=130"
          },
          {
              id: "713d659c59d9b087e9c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19352&size=130"
          },
          {
              id: "f5ece04ddc0835566c19",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19353&size=130"
          },
          {
              id: "036115c02985c0db9994",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19354&size=130"
          },
          {
              id: "506d47cc7b8992d7cb98",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19355&size=130"
          },
          {
              id: "f45ae4fbd8be31e068af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19356&size=130"
          },
          {
              id: "ba3bab9a97df7e8127ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19357&size=130"
          },
          {
              id: "547546d47a9193cfca80",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19358&size=130"
          },
          {
              id: "f258e1f9ddbc34e26dad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19359&size=130"
          },
          {
              id: "29db057a393fd061892e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19360&size=130"
          },
          {
              id: "01fb2c5a101ff941a00e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19361&size=130"
          },
          {
              id: "8682a82394667d382477",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19362&size=130"
          },
          {
              id: "ac4b83eabfaf56f10fbe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19363&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker8fc09e02a2474b191256.png",
      price: "Miễn phí",
      name: "Quỳnh Aka Nghỉ Lễ",
      thumbImg: "https://zalo-api.zadn.vn/7/6/8/8/d/10397/preview/aka_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/6/8/8/d/10397/icon_pre/aka_thumb.png",
      id: "98ec892eb56b5c35057a",
      source: "",
      totalImage: "16 sticker",
      value: [
          {
              id: "439322201e65f73bae74",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23021&size=130"
          },
          {
              id: "ae29cc9af0df198140ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23022&size=130"
          },
          {
              id: "a391c022fc6715394c76",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23023&size=130"
          },
          {
              id: "c610baa386e66fb836f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23024&size=130"
          },
          {
              id: "d878a5cb998e70d0299f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23025&size=130"
          },
          {
              id: "488536360a73e32dba62",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23026&size=130"
          },
          {
              id: "8abbf508c94d2013795c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23027&size=130"
          },
          {
              id: "784500f63cb3d5ed8ca2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23028&size=130"
          },
          {
              id: "9988e03bdc7e35206c6f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23029&size=130"
          },
          {
              id: "ee7d94cea88b41d5189a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23030&size=130"
          },
          {
              id: "8db0f603ca4623187a57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23031&size=130"
          },
          {
              id: "615e15ed29a8c0f699b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23032&size=130"
          },
          {
              id: "29735cc0608589dbd094",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23033&size=130"
          },
          {
              id: "3f0949ba75ff9ca1c5ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23034&size=130"
          },
          {
              id: "d6a9a11a9d5f74012d4e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23035&size=130"
          },
          {
              id: "0fb87f0b434eaa10f35f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23036&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerca8fdc4be00e0950501f.png",
      price: "Miễn phí",
      name: "Chung Cư Cũ Mùa Dịch",
      thumbImg: "https://zalo-api.zadn.vn/b/c/3/f/f/11930/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/b/c/3/f/f/11930/icon_pre/chungcucumuadich_thumb.png",
      id: "3e1328d71492fdcca483",
      source: "0",
      totalImage: "16 sticker",
      value: [
          {
              id: "e6ffa3a79fe276bc2ff3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45769&size=130"
          },
          {
              id: "98a8def0e2b50beb52a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45770&size=130"
          },
          {
              id: "09af4ef772b29becc2a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45771&size=130"
          },
          {
              id: "da519a09a64c4f12165d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45772&size=130"
          },
          {
              id: "38f879a045e5acbbf5f4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45773&size=130"
          },
          {
              id: "6f8d2dd51190f8cea181",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45774&size=130"
          },
          {
              id: "16ba55e269a780f9d9b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45775&size=130"
          },
          {
              id: "d1438d1bb15e5800014f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45776&size=130"
          },
          {
              id: "ff99a2c19e8477da2e95",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45777&size=130"
          },
          {
              id: "30b36eeb52aebbf0e2bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45778&size=130"
          },
          {
              id: "7f4120191c5cf502ac4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45779&size=130"
          },
          {
              id: "b7b2efead3af3af163be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45780&size=130"
          },
          {
              id: "cb5e9206ae43471d1e52",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45781&size=130"
          },
          {
              id: "7b5f21071d42f41cad53",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45782&size=130"
          },
          {
              id: "9a1fc147fd02145c4d13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45783&size=130"
          },
          {
              id: "fe43aa1b965e7f00264f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45784&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker72b7c67afa3f13614a2e.png",
      price: "Miễn phí",
      name: "Đông Yêu Thương",
      thumbImg: "https://zalo-api.zadn.vn/f/2/5/0/d/10040/preview/hjxmas_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/f/2/5/0/d/10040/icon_pre/hjxmas_thumb.png",
      id: "1e8faa4296077f592616",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "8791f93bc57e2c20756f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16626&size=130"
          },
          {
              id: "9340ecead0af39f160be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16627&size=130"
          },
          {
              id: "b862c0c8fc8d15d34c9c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16628&size=130"
          },
          {
              id: "7c7505df399ad0c4898b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16629&size=130"
          },
          {
              id: "74b70e1d3258db068249",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16630&size=130"
          },
          {
              id: "71480ae236a7dff986b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16631&size=130"
          },
          {
              id: "139267385b7db223eb6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16632&size=130"
          },
          {
              id: "2aa25f08634d8a13d35c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16633&size=130"
          },
          {
              id: "dfaea90495417c1f2550",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16634&size=130"
          },
          {
              id: "5c362b9c17d9fe87a7c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16635&size=130"
          },
          {
              id: "e9ae9904a5414c1f1550",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16636&size=130"
          },
          {
              id: "1c0d6da751e2b8bce1f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16637&size=130"
          },
          {
              id: "8f85fd2fc16a2834717b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16638&size=130"
          },
          {
              id: "506923c31f86f6d8af97",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16639&size=130"
          },
          {
              id: "856009cb358edcd0859f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16640&size=130"
          },
          {
              id: "f42d798645c3ac9df5d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16641&size=130"
          },
          {
              id: "974319e825adccf395bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16642&size=130"
          },
          {
              id: "5ccbd360ef25067b5f34",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16643&size=130"
          },
          {
              id: "e95161fa5dbfb4e1edae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16644&size=130"
          },
          {
              id: "f7d17e7a423fab61f22e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16645&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrstickere73716f32ab6c3e89aa7.png",
      price: "Miễn phí",
      name: "Ami Bụng Bự 3",
      thumbImg: "https://zalo-api.zadn.vn/1/0/8/f/6/11901/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/1/0/8/f/6/11901/icon_pre/amibungbu3_thumb.png",
      id: "3abacb7ef73b1e65472a",
      source: "JUJU",
      totalImage: "16 sticker",
      value: [
          {
              id: "4fe4debfe2fa0ba452eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45341&size=130"
          },
          {
              id: "309ea2c59e8077de2e91",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45342&size=130"
          },
          {
              id: "5345c01efc5b15054c4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45343&size=130"
          },
          {
              id: "bf6a13312f74c62a9f65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45344&size=130"
          },
          {
              id: "7237df6ce3290a775338",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45345&size=130"
          },
          {
              id: "32149c4fa00a4954101b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45346&size=130"
          },
          {
              id: "5358fc03c04629187057",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45347&size=130"
          },
          {
              id: "c91961425d07b459ed16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45348&size=130"
          },
          {
              id: "016aa83194747d2a2465",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45349&size=130"
          },
          {
              id: "8b9721cc1d89f4d7ad98",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45350&size=130"
          },
          {
              id: "248d8fd6b3935acd0382",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45351&size=130"
          },
          {
              id: "10e7b4bc88f961a738e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45352&size=130"
          },
          {
              id: "c09965c25987b0d9e996",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45353&size=130"
          },
          {
              id: "b65e10052c40c51e9c51",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45354&size=130"
          },
          {
              id: "8fe228b914fcfda2a4ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45355&size=130"
          },
          {
              id: "f35d53066f43861ddf52",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45356&size=130"
          }
      ]
    },
    {
      textUploader: "Uploader",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker272243e57fa096fecfb1.png",
      price: "Miễn phí",
      name: "Crazy Frog 2",
      thumbImg: "https://zalo-api.zadn.vn/a/e/e/5/6/11752/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/a/e/e/5/6/11752/icon_pre/crazyfrog2_thumb.png",
      id: "cc01a8c694837ddd2492",
      source: "Nam Nguyen",
      totalImage: "12 sticker",
      value: [
          {
              id: "009cc1d1fd9414ca4d85",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42829&size=130"
          },
          {
              id: "fde13fac03e9eab7b3f8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42830&size=130"
          },
          {
              id: "7468b7258b60623e3b71",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42831&size=130"
          },
          {
              id: "0403d84ee40b0d55541a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42832&size=130"
          },
          {
              id: "c47b19362573cc2d9562",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42833&size=130"
          },
          {
              id: "06ded893e4d60d8854c7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42834&size=130"
          },
          {
              id: "aa457508494da013f95c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42835&size=130"
          },
          {
              id: "8d73553e697b8025d96a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42836&size=130"
          },
          {
              id: "aab673fb4fbea6e0ffaf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42837&size=130"
          },
          {
              id: "a34979044541ac1ff550",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42838&size=130"
          },
          {
              id: "a4d57f9843ddaa83f3cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42839&size=130"
          },
          {
              id: "df9f0bd23797dec98786",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42840&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker1ea7a463982671782837.png",
      price: "Miễn phí",
      name: "Bé Đầu Moi",
      thumbImg: "https://zalo-api.zadn.vn/2/6/3/2/5/11830/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/2/6/3/2/5/11830/icon_pre/bedaumoi_thumb.png",
      id: "3af58031bc74552a0c65",
      source: "",
      totalImage: "16 sticker",
      value: [
          {
              id: "a0858dc3b18658d80197",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44193&size=130"
          },
          {
              id: "93b7bdf181b468ea31a5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44194&size=130"
          },
          {
              id: "4f1160575c12b54cec03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44195&size=130"
          },
          {
              id: "c340eb06d7433e1d6752",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44196&size=130"
          },
          {
              id: "a4ab8dedb1a858f601b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44197&size=130"
          },
          {
              id: "90a3bae586a06ffe36b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44198&size=130"
          },
          {
              id: "329019d62593cccd9582",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44199&size=130"
          },
          {
              id: "0378273e1b7bf225ab6a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44200&size=130"
          },
          {
              id: "85f7a0b19cf475aa2ce5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44201&size=130"
          },
          {
              id: "767150376c72852cdc63",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44202&size=130"
          },
          {
              id: "66a441e27da794f9cdb6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44203&size=130"
          },
          {
              id: "710151476d02845cdd13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44204&size=130"
          },
          {
              id: "477b663d5a78b326ea69",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44205&size=130"
          },
          {
              id: "e667c421f864113a4875",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44206&size=130"
          },
          {
              id: "c5b5e6f3dab633e86aa7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44207&size=130"
          },
          {
              id: "47bb7bfd47b8aee6f7a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44208&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerb5f8ea39d67c3f22666d.png",
      price: "Miễn phí",
      name: "Quynh Aka - Stay At Home",
      thumbImg: "https://zalo-api.zadn.vn/d/7/0/1/2/11219/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/d/7/0/1/2/11219/icon_pre/akacovid_thumb.png",
      id: "0db152706e35876bde24",
      source: "Quỳnh Aka",
      totalImage: "12 sticker",
      value: [
          {
              id: "06b09ed0a2954bcb1284",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35348&size=130"
          },
          {
              id: "1cdf85bfb9fa50a409eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35349&size=130"
          },
          {
              id: "dd9947f97bbc92e2cbad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35350&size=130"
          },
          {
              id: "5acdc1adfde814b64df9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35351&size=130"
          },
          {
              id: "cda159c165848cdad595",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35352&size=130"
          },
          {
              id: "3603a3639f2676782f37",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35353&size=130"
          },
          {
              id: "08909ef0a2b54beb12a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35354&size=130"
          },
          {
              id: "80d717b72bf2c2ac9be3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35355&size=130"
          },
          {
              id: "5842c822f4671d394476",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35356&size=130"
          },
          {
              id: "4c2add4ae10f0851511e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35357&size=130"
          },
          {
              id: "85dd17bd2bf8c2a69be9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35358&size=130"
          },
          {
              id: "5117c277fe32176c4e23",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35359&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrsticker1b3319f725b2ccec95a3.png",
      price: "Miễn phí",
      name: "Bida Zagoo",
      thumbImg: "https://zalo-api.zadn.vn/d/e/d/0/8/11918/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/d/e/d/0/8/11918/icon_pre/bidazagoo_thumb.png",
      id: "38ab3a6f062aef74b63b",
      source: "0",
      totalImage: "16 sticker",
      value: [
          {
              id: "536ffa37c6722f2c7663",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45605&size=130"
          },
          {
              id: "b89012c82e8dc7d39e9c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45606&size=130"
          },
          {
              id: "0135aa6d96287f762639",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45607&size=130"
          },
          {
              id: "2b268f7eb33b5a65032a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45608&size=130"
          },
          {
              id: "c0f765af59eab0b4e9fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45609&size=130"
          },
          {
              id: "9e12384a040fed51b41e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45610&size=130"
          },
          {
              id: "3a129d4aa10f4851111e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45611&size=130"
          },
          {
              id: "bc2f1c772032c96c9023",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45612&size=130"
          },
          {
              id: "21fd80a5bce055be0cf1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45613&size=130"
          },
          {
              id: "d36171394d7ca422fd6d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45614&size=130"
          },
          {
              id: "ae0c0d543111d84f8100",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45615&size=130"
          },
          {
              id: "c3f07fa843edaab3f3fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45616&size=130"
          },
          {
              id: "8ff132a90eece7b2befd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45617&size=130"
          },
          {
              id: "7873c62bfa6e13304a7f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45618&size=130"
          },
          {
              id: "af4910112c54c50a9c45",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45619&size=130"
          },
          {
              id: "21bb99e3a5a64cf815b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45620&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickerbe039dc0a18548db1194.png",
      price: "Miễn phí",
      name: "Noo-Hin",
      thumbImg: "https://zalo-api.zadn.vn/e/a/4/c/7/10671/preview/noohin_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/e/a/4/c/7/10671/icon_pre/noohin_thumb.png",
      id: "75c056036a468318da57",
      source: "Vithita Animation Co., Ltd.",
      totalImage: "16 sticker",
      value: [
          {
              id: "b38d1f002345ca1b9354",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26400&size=130"
          },
          {
              id: "7341decce2890bd75298",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26401&size=130"
          },
          {
              id: "ae82000f3c4ad5148c5b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26402&size=130"
          },
          {
              id: "d2877d0a414fa811f15e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26403&size=130"
          },
          {
              id: "76b0de3de2780b265269",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26404&size=130"
          },
          {
              id: "a20a0b8737c2de9c87d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26405&size=130"
          },
          {
              id: "81972b1a175ffe01a74e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26406&size=130"
          },
          {
              id: "b84613cb2f8ec6d09f9f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26407&size=130"
          },
          {
              id: "d3f977744b31a26ffb20",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26408&size=130"
          },
          {
              id: "c47c61f15db4b4eaeda5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26409&size=130"
          },
          {
              id: "d312759f49daa084f9cb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26410&size=130"
          },
          {
              id: "082fafa293e77ab923f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26411&size=130"
          },
          {
              id: "fdde5d5361168848d107",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26412&size=130"
          },
          {
              id: "aaf00b7d3738de668729",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26413&size=130"
          },
          {
              id: "53f6f17bcd3e24607d2f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26414&size=130"
          },
          {
              id: "992e3aa306e6efb8b6f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26415&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker62a23b620727ee79b736.png",
      price: "Miễn phí",
      name: "Nhật Kí Của Mẹ",
      thumbImg: "https://zalo-api.zadn.vn/b/5/d/1/7/10965/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/b/5/d/1/7/10965/icon_pre/mom_thumb.png",
      id: "3bb662765e33b76dee22",
      source: "Jokohama",
      totalImage: "19 sticker",
      value: [
          {
              id: "1b9a31080d4de413bd5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30886&size=130"
          },
          {
              id: "858aae18925d7b03224c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30887&size=130"
          },
          {
              id: "542770b54cf0a5aefce1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30888&size=130"
          },
          {
              id: "88c5ad579112784c2103",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30889&size=130"
          },
          {
              id: "fbcfdd5de11808465109",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30890&size=130"
          },
          {
              id: "4c376ba557e0bebee7f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30891&size=130"
          },
          {
              id: "4f7b6fe953acbaf2e3bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30892&size=130"
          },
          {
              id: "c0dce14edd0b34556d1a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30893&size=130"
          },
          {
              id: "ec40ced2f2971bc94286",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30894&size=130"
          },
          {
              id: "a12c82bebefb57a50eea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30895&size=130"
          },
          {
              id: "8148bdda819f68c1318e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30896&size=130"
          },
          {
              id: "b2158f87b3c25a9c03d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30897&size=130"
          },
          {
              id: "852abbb887fd6ea337ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30898&size=130"
          },
          {
              id: "484377d14b94a2cafb85",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30899&size=130"
          },
          {
              id: "6e2a56b86afd83a3daec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30900&size=130"
          },
          {
              id: "fba1c233fe7617284e67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30901&size=130"
          },
          {
              id: "f53bcfa9f3ec1ab243fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30902&size=130"
          },
          {
              id: "d8d8e34adf0f36516f1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30903&size=130"
          },
          {
              id: "d0ffe46dd82831766839",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30904&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker52d68e14b2515b0f0240.png",
      price: "Miễn phí",
      name: "Kim Chi Vui Vẻ",
      thumbImg: "https://zalo-api.zadn.vn/7/a/6/7/7/10320/preview/ntvv_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/a/6/7/7/10320/icon_pre/ntvv_thumb.png",
      id: "defb02393e7cd7228e6d",
      source: "",
      totalImage: "24 sticker",
      value: [
          {
              id: "ea0760b85cfdb5a3ecec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21766&size=130"
          },
          {
              id: "c9c1427e7e3b9765ce2a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21767&size=130"
          },
          {
              id: "7240f6ffcaba23e47aab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21768&size=130"
          },
          {
              id: "b20137be0bfbe2a5bbea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21769&size=130"
          },
          {
              id: "0f5889e7b5a25cfc05b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21770&size=130"
          },
          {
              id: "e31e64a158e4b1bae8f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21771&size=130"
          },
          {
              id: "0b048bbbb7fe5ea007ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21772&size=130"
          },
          {
              id: "07a88617ba52530c0a43",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21773&size=130"
          },
          {
              id: "7c61fedec29b2bc5728a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21774&size=130"
          },
          {
              id: "d2d951666d23847ddd32",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21775&size=130"
          },
          {
              id: "4ddad165ed20047e5d31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21776&size=130"
          },
          {
              id: "2af9b7468b03625d3b12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21777&size=130"
          },
          {
              id: "3a40a4ff98ba71e428ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21778&size=130"
          },
          {
              id: "5148cef7f2b21bec42a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21779&size=130"
          },
          {
              id: "21e7b958851d6c43350c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21780&size=130"
          },
          {
              id: "5e11c7aefbeb12b54bfa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21781&size=130"
          },
          {
              id: "d7e04d5f711a9844c10b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21782&size=130"
          },
          {
              id: "39b6a2099e4c77122e5d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21783&size=130"
          },
          {
              id: "3461a0de9c9b75c52c8a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21784&size=130"
          },
          {
              id: "1e8f8b30b7755e2b0764",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21785&size=130"
          },
          {
              id: "fc806a3f567abf24e66b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21786&size=130"
          },
          {
              id: "e8bc7f034346aa18f357",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21787&size=130"
          },
          {
              id: "299ab92585606c3e3571",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21788&size=130"
          },
          {
              id: "7128e097dcd2358c6cc3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21789&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/5/qrstickerd0cad00eec4b05155c5a.png",
      price: "Miễn phí",
      name: "Hải Quan TP.HCM",
      thumbImg: "https://zalo-api.zadn.vn/8/0/1/2/c/11916/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/8/0/1/2/c/11916/icon_pre/haiquantphcm_thumb.png",
      id: "340b34cf088ae1d4b89b",
      source: "0",
      totalImage: "20 sticker",
      value: [
          {
              id: "ed1a60425c07b559ec16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45569&size=130"
          },
          {
              id: "0a02845ab81f5141080e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45570&size=130"
          },
          {
              id: "dc2b53736f368668df27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45571&size=130"
          },
          {
              id: "f4727c2a406fa931f07e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45572&size=130"
          },
          {
              id: "06ad8ff5b3b05aee03a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45573&size=130"
          },
          {
              id: "61cceb94d7d13e8f67c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45574&size=130"
          },
          {
              id: "9ac7119f2ddac4849dcb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45575&size=130"
          },
          {
              id: "7b0fff57c3122a4c7303",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45576&size=130"
          },
          {
              id: "db995ec162848bdad295",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45577&size=130"
          },
          {
              id: "a20f24571812f14ca803",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45578&size=130"
          },
          {
              id: "88d60f8e33cbda9583da",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45579&size=130"
          },
          {
              id: "f4b674ee48aba1f5f8ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45580&size=130"
          },
          {
              id: "f0e271ba4dffa4a1fdee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45581&size=130"
          },
          {
              id: "a29020c81c8df5d3ac9c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45582&size=130"
          },
          {
              id: "60e8e3b0dff536ab6fe4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45583&size=130"
          },
          {
              id: "541cc844f4011d5f4410",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45584&size=130"
          },
          {
              id: "da6147397b7c9222cb6d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45585&size=130"
          },
          {
              id: "c4c55a9d66d88f86d6c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45586&size=130"
          },
          {
              id: "06f699aea5eb4cb515fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45587&size=130"
          },
          {
              id: "03259b7da7384e661729",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45588&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickerd7dbe419d85c3102684d.png",
      price: "Miễn phí",
      name: "Mobile Girl, MiM",
      thumbImg: "https://zalo-api.zadn.vn/5/c/c/3/3/10431/preview/mim_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/c/c/3/3/10431/icon_pre/mim_thumb.png",
      id: "b6eb8529b96c5032097d",
      source: "Funnyeve",
      totalImage: "25 sticker",
      value: [
          {
              id: "a6f3594265078c59d516",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23411&size=130"
          },
          {
              id: "6e319680aac5439b1ad4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23412&size=130"
          },
          {
              id: "4761bed082956bcb3284",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23413&size=130"
          },
          {
              id: "56e5ac549011794f2000",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23414&size=130"
          },
          {
              id: "2e3ad58be9ce009059df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23415&size=130"
          },
          {
              id: "1d31e980d5c53c9b65d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23416&size=130"
          },
          {
              id: "baf64f4773029a5cc313",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23417&size=130"
          },
          {
              id: "af20599165d48c8ad5c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23418&size=130"
          },
          {
              id: "bb364c8770c2999cc0d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23419&size=130"
          },
          {
              id: "1ee1ee50d2153b4b6204",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23420&size=130"
          },
          {
              id: "40c8b1798d3c64623d2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23421&size=130"
          },
          {
              id: "28d6da67e6220f7c5633",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23422&size=130"
          },
          {
              id: "28afdb1ee75b0e05574a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23423&size=130"
          },
          {
              id: "1e1712a62ee3c7bd9ef2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23424&size=130"
          },
          {
              id: "4c3341827dc79499cdd6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23425&size=130"
          },
          {
              id: "1ae014512814c14a9805",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23426&size=130"
          },
          {
              id: "276128d01495fdcba484",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23427&size=130"
          },
          {
              id: "885a80ebbcae55f00cbf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23428&size=130"
          },
          {
              id: "dd2dd49ce8d9018758c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23429&size=130"
          },
          {
              id: "935699e7a5a24cfc15b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23430&size=130"
          },
          {
              id: "b430bf8183c46a9a33d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23431&size=130"
          },
          {
              id: "088a0c3b307ed920806f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23432&size=130"
          },
          {
              id: "b8d1bd608125687b3134",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23433&size=130"
          },
          {
              id: "3bc13d700135e86bb124",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23434&size=130"
          },
          {
              id: "b766b0d78c9265cc3c83",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23435&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/4/qrstickerc2f8423c7e799727ce68.png",
      price: "Miễn phí",
      name: "Ami Bụng Bự 2",
      thumbImg: "https://zalo-api.zadn.vn/f/6/d/1/e/11788/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/f/6/d/1/e/11788/icon_pre/amibungbu2_thumb.png",
      id: "00c28006bc43551d0c52",
      source: "JUJU",
      totalImage: "16 sticker",
      value: [
          {
              id: "62aaf2eaceaf27f17ebe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43548&size=130"
          },
          {
              id: "6aaffbefc7aa2ef477bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43549&size=130"
          },
          {
              id: "b2c720871cc2f59cacd3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43550&size=130"
          },
          {
              id: "207cb33c8f7966273f68",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43551&size=130"
          },
          {
              id: "8c3320731c36f568ac27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43552&size=130"
          },
          {
              id: "4a7de73ddb7832266b69",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43553&size=130"
          },
          {
              id: "0966a7269b63723d2b72",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43554&size=130"
          },
          {
              id: "34179b57a7124e4c1703",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43555&size=130"
          },
          {
              id: "b4c21c8220c7c99990d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43556&size=130"
          },
          {
              id: "38439103ad4644181d57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43557&size=130"
          },
          {
              id: "f60a5c4a600f8951d01e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43558&size=130"
          },
          {
              id: "a98002c03e85d7db8e94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43559&size=130"
          },
          {
              id: "2c0f884fb40a5d54041b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43560&size=130"
          },
          {
              id: "227f873fbb7a52240b6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43561&size=130"
          },
          {
              id: "e32545657920907ec931",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43562&size=130"
          },
          {
              id: "e71840587c1d9543cc0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43563&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/4/qrstickerf57bfebfc2fa2ba472eb.png",
      price: "Miễn phí",
      name: "Cô Dứa",
      thumbImg: "https://zalo-api.zadn.vn/e/b/b/1/1/11911/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/e/b/b/1/1/11911/icon_pre/codua_thumb.png",
      id: "d304d8c0e4850ddb5494",
      source: "Vũ Trụ Giả Trân 198X",
      totalImage: "18 sticker",
      value: [
          {
              id: "a1449e1fa25a4b04124b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45491&size=130"
          },
          {
              id: "ff71c72afb6f12314b7e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45492&size=130"
          },
          {
              id: "25a81cf320b6c9e890a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45493&size=130"
          },
          {
              id: "3836026d3e28d7768e39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45494&size=130"
          },
          {
              id: "e9e1d2baeeff07a15eee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45495&size=130"
          },
          {
              id: "a05e9405a840411e1851",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45496&size=130"
          },
          {
              id: "9818ad43910678582117",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45497&size=130"
          },
          {
              id: "e767d13ced7904275d68",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45498&size=130"
          },
          {
              id: "45b372e84eada7f3febc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45499&size=130"
          },
          {
              id: "7ad24a8976cc9f92c6dd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45500&size=130"
          },
          {
              id: "f27cc327ff62163c4f73",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45501&size=130"
          },
          {
              id: "045a36010a44e31aba55",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45502&size=130"
          },
          {
              id: "ee20dd7be13e0860512f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45503&size=130"
          },
          {
              id: "71bf3de401a1e8ffb1b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45504&size=130"
          },
          {
              id: "d1509c0ba04e4910105f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45505&size=130"
          },
          {
              id: "09a547fe7bbb92e5cbaa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45506&size=130"
          },
          {
              id: "bfadf0f6ccb325ed7ca2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45507&size=130"
          },
          {
              id: "df029759ab1c42421b0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45508&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/6/qrsticker13469582a9c7409919d6.png",
      price: "Miễn phí",
      name: "Ami Bụng Bự",
      thumbImg: "https://zalo-api.zadn.vn/6/a/e/d/8/11786/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/6/a/e/d/8/11786/icon_pre/amibungbu_thumb.png",
      id: "f8267ee242a7abf9f2b6",
      source: "JUJU",
      totalImage: "16 sticker",
      value: [
          {
              id: "7a150a563613df4d8602",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43516&size=130"
          },
          {
              id: "744605053940d01e8951",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43517&size=130"
          },
          {
              id: "af3cdd7fe13a0864512b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43518&size=130"
          },
          {
              id: "2d8f5ecc62898bd7d298",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43519&size=130"
          },
          {
              id: "289ea4de989b71c5288a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43520&size=130"
          },
          {
              id: "999114d12894c1ca9885",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43521&size=130"
          },
          {
              id: "bef730b70cf2e5acbce3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43522&size=130"
          },
          {
              id: "5776d836e4730d2d5462",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43523&size=130"
          },
          {
              id: "cf5b471b7b5e9200cb4f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43524&size=130"
          },
          {
              id: "c5b84cf870bd99e3c0ac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43525&size=130"
          },
          {
              id: "7821f261ce24277a7e35",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43526&size=130"
          },
          {
              id: "3e34b5748931606f3920",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43527&size=130"
          },
          {
              id: "3be6bfa683e36abd33f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43528&size=130"
          },
          {
              id: "836d062d3a68d3368a79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43529&size=130"
          },
          {
              id: "eb626d225167b839e176",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43530&size=130"
          },
          {
              id: "ba903dd00195e8cbb184",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43531&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker905360975cd2b58cecc3.png",
      price: "Miễn phí",
      name: "Anh Tỷ Phú Zagoo",
      thumbImg: "https://zalo-api.zadn.vn/8/5/6/b/1/11900/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/8/5/6/b/1/11900/icon_pre/anhtyphuzagoo_thumb.png",
      id: "841674d24897a1c9f886",
      source: "Cờ Tỷ Phú Zagoo",
      totalImage: "16 sticker",
      value: [
          {
              id: "13439218ae5d47031e4c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45325&size=130"
          },
          {
              id: "34a9b6f28ab763e93aa6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45326&size=130"
          },
          {
              id: "c9dd4a8676c39f9dc6d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45327&size=130"
          },
          {
              id: "b2d02e8b12cefb90a2df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45328&size=130"
          },
          {
              id: "33ddae8692c37b9d22d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45329&size=130"
          },
          {
              id: "a4a43aff06baefe4b6ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45330&size=130"
          },
          {
              id: "c874572f6b6a8234db7b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45331&size=130"
          },
          {
              id: "16bf8ee4b2a15bff02b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45332&size=130"
          },
          {
              id: "7e17e74cdb0932576b18",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45333&size=130"
          },
          {
              id: "7e9fe4c4d88131df6890",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45334&size=130"
          },
          {
              id: "1d188643ba0653580a17",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45335&size=130"
          },
          {
              id: "e0c0749b48dea180f8cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45336&size=130"
          },
          {
              id: "57abc2f0feb517eb4ea4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45337&size=130"
          },
          {
              id: "a81f3e440201eb5fb210",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45338&size=130"
          },
          {
              id: "c270552b696e8030d97f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45339&size=130"
          },
          {
              id: "ac833cd8009de9c3b08c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45340&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7e7b85b8b9fd50a309ec.png",
      price: "Miễn phí",
      name: " Quỳnh Aka Bất Bại",
      thumbImg: "https://zalo-api.zadn.vn/2/1/3/2/7/10615/preview/aka2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/1/3/2/7/10615/icon_pre/aka2_thumb.png",
      id: "8b6570a64ce3a5bdfcf2",
      source: "",
      totalImage: "25 sticker",
      value: [
          {
              id: "d7e3f56dc92820767939",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25774&size=130"
          },
          {
              id: "0fbc2c321077f929a066",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25775&size=130"
          },
          {
              id: "f375cffbf3be1ae043af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25776&size=130"
          },
          {
              id: "38b5053b397ed020896f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25777&size=130"
          },
          {
              id: "7d9d43137f569608cf47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25778&size=130"
          },
          {
              id: "776048ee74ab9df5c4ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25779&size=130"
          },
          {
              id: "14ea2c641021f97fa030",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25780&size=130"
          },
          {
              id: "d964e0eadcaf35f16cbe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25781&size=130"
          },
          {
              id: "4bd0715e4d1ba445fd0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25782&size=130"
          },
          {
              id: "ec64d7eaebaf02f15bbe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25783&size=130"
          },
          {
              id: "35e2016c3d29d4778d38",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25784&size=130"
          },
          {
              id: "e792d21cee5907075e48",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25785&size=130"
          },
          {
              id: "c630f0beccfb25a57cea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25786&size=130"
          },
          {
              id: "b5f88276be33576d0e22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25787&size=130"
          },
          {
              id: "1fca2f441301fa5fa310",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25788&size=130"
          },
          {
              id: "f115c09bfcde15804ccf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25789&size=130"
          },
          {
              id: "37fd05733936d0688927",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25790&size=130"
          },
          {
              id: "71cf42417e04975ace15",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25791&size=130"
          },
          {
              id: "bfdaf354cf11264f7f00",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25792&size=130"
          },
          {
              id: "183d55b369f680a8d9e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25793&size=130"
          },
          {
              id: "3f1871964dd3a48dfdc2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25794&size=130"
          },
          {
              id: "febfb1318d74642a3d65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25795&size=130"
          },
          {
              id: "6db2253c1979f027a968",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25796&size=130"
          },
          {
              id: "910bd885e4c00d9e54d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25797&size=130"
          },
          {
              id: "ab4ce1c2dd8734d96d96",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25798&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickere4dc12182e5dc7039e4c.png",
      price: "Miễn phí",
      name: "Cử Tri Trẻ",
      thumbImg: "https://zalo-api.zadn.vn/4/c/f/2/9/11898/preview/440X440.png",
      iconUrl: "https://zalo-api.zadn.vn/4/c/f/2/9/11898/icon_pre/cutritre_thumb.png",
      id: "2f32d9f6e5b30ced55a2",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "a73dc667fa22137c4a33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45293&size=130"
          },
          {
              id: "20a842f27eb797e9cea6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45294&size=130"
          },
          {
              id: "782e1b742731ce6f9720",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45295&size=130"
          },
          {
              id: "2525597f653a8c64d52b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45296&size=130"
          },
          {
              id: "7d4900133c56d5088c47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45297&size=130"
          },
          {
              id: "11e26fb853fdbaa3e3ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45298&size=130"
          },
          {
              id: "c3fcbca680e369bd30f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45299&size=130"
          },
          {
              id: "986ae030dc75352b6c64",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45300&size=130"
          },
          {
              id: "871dfe47c2022b5c7213",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45301&size=130"
          },
          {
              id: "23a359f965bc8ce2d5ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45302&size=130"
          },
          {
              id: "bbe4c0befcfb15a54cea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45303&size=130"
          },
          {
              id: "c4d6b08c8cc965973cd8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45304&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickerf6570d9331d6d88881c7.png",
      price: "Miễn phí",
      name: "Zookiz Quẩy Xuyên Hè",
      thumbImg: "https://zalo-api.zadn.vn/b/1/9/2/4/11895/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/b/1/9/2/4/11895/icon_pre/zookizquayxuyenhe_thumb.png",
      id: "487bb3bf8ffa66a43feb",
      source: "Zookiz",
      totalImage: "12 sticker",
      value: [
          {
              id: "f409b95385166c483507",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45249&size=130"
          },
          {
              id: "cfb781edbda854f60db9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45250&size=130"
          },
          {
              id: "3e37716d4d28a476fd39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45251&size=130"
          },
          {
              id: "628e2ad41691ffcfa680",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45252&size=130"
          },
          {
              id: "ff36b66c8a2963773a38",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45253&size=130"
          },
          {
              id: "45950fcf338adad4839b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45254&size=130"
          },
          {
              id: "977bdc21e064093a5075",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45255&size=130"
          },
          {
              id: "218e65d45991b0cfe980",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45256&size=130"
          },
          {
              id: "e2b3a7e99bac72f22bbd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45257&size=130"
          },
          {
              id: "21b167eb5baeb2f0ebbf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45258&size=130"
          },
          {
              id: "759e32c40e81e7dfbe90",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45259&size=130"
          },
          {
              id: "112c51766d33846ddd22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45260&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerca5f369b0adee380bacf.png",
      price: "Miễn phí",
      name: "Mr. Booo",
      thumbImg: "https://zalo-api.zadn.vn/9/4/c/2/8/11888/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/9/4/c/2/8/11888/icon_pre/mrboo_thumb.png",
      id: "c3e93f2d0368ea36b379",
      source: "Zookiz",
      totalImage: "24 sticker",
      value: [
          {
              id: "138dd2d7ee9207cc5e83",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45133&size=130"
          },
          {
              id: "6a53a809944c7d12245d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45134&size=130"
          },
          {
              id: "d47a17202b65c23b9b74",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45135&size=130"
          },
          {
              id: "ba60663a5a7fb321ea6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45136&size=130"
          },
          {
              id: "57e68abcb6f95fa706e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45137&size=130"
          },
          {
              id: "e17b3f210364ea3ab375",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45138&size=130"
          },
          {
              id: "8b38546268278179d836",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45139&size=130"
          },
          {
              id: "df9907c33b86d2d88b97",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45140&size=130"
          },
          {
              id: "12c7cb9df7d81e8647c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45141&size=130"
          },
          {
              id: "244cfe16c2532b0d7242",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45142&size=130"
          },
          {
              id: "7242a918955d7c03254c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45143&size=130"
          },
          {
              id: "9b5c4f0673439a1dc352",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45144&size=130"
          },
          {
              id: "d776022c3e69d7378e78",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45145&size=130"
          },
          {
              id: "8d715b2b676e8e30d77f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45146&size=130"
          },
          {
              id: "294bfe11c2542b0a7245",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45147&size=130"
          },
          {
              id: "7e2cae7692337b6d2222",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45148&size=130"
          },
          {
              id: "f63327691b2cf272ab3d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45149&size=130"
          },
          {
              id: "86f354a968ec81b2d8fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45150&size=130"
          },
          {
              id: "cc541f0e234bca15935a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45151&size=130"
          },
          {
              id: "8cf860a25ce7b5b9ecf6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45152&size=130"
          },
          {
              id: "a85b45017944901ac955",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45153&size=130"
          },
          {
              id: "0ce5e2bfdefa37a46eeb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45154&size=130"
          },
          {
              id: "af2940737c369568cc27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45155&size=130"
          },
          {
              id: "bd8155db699e80c0d98f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45156&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/6/qrstickercfc728031446fd18a457.png",
      price: "Miễn phí",
      name: "Anh Em Zookiz",
      thumbImg: "https://zalo-api.zadn.vn/c/4/5/2/5/11883/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/c/4/5/2/5/11883/icon_pre/anhemzookiz_thumb.png",
      id: "c2c525011944f01aa955",
      source: "Zookiz",
      totalImage: "12 sticker",
      value: [
          {
              id: "9eabe3eedfab36f56fba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45041&size=130"
          },
          {
              id: "62f71cb220f7c9a990e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45042&size=130"
          },
          {
              id: "9532ea77d6323f6c6623",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45043&size=130"
          },
          {
              id: "8578fd3dc17828267169",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45044&size=130"
          },
          {
              id: "07d67e9342d6ab88f2c7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45045&size=130"
          },
          {
              id: "ce23b4668823617d3832",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45046&size=130"
          },
          {
              id: "1add61985dddb483edcc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45047&size=130"
          },
          {
              id: "c9ecbda981ec68b231fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45048&size=130"
          },
          {
              id: "d64fa30a9f4f76112f5e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45049&size=130"
          },
          {
              id: "13fc65b959fcb0a2e9ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45050&size=130"
          },
          {
              id: "a003d746eb03025d5b12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45051&size=130"
          },
          {
              id: "b2bbc2fefebb17e54eaa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=45052&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd68104453800d15e8811.png",
      price: "Miễn phí",
      name: "Baby Wony 3",
      thumbImg: "https://zalo-api.zadn.vn/3/0/f/0/6/11870/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/3/0/f/0/6/11870/icon_pre/babywony_thumb.png",
      id: "2ab7f873c4362d687427",
      source: "WONYFRAME",
      totalImage: "12 sticker",
      value: [
          {
              id: "5808dd4de10808565119",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44809&size=130"
          },
          {
              id: "83f405b139f4d0aa89e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44810&size=130"
          },
          {
              id: "9e9219d72592cccc9583",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44811&size=130"
          },
          {
              id: "bd083d4d0108e856b119",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44812&size=130"
          },
          {
              id: "3693b7d68b9362cd3b82",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44813&size=130"
          },
          {
              id: "a00322461e03f75dae12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44814&size=130"
          },
          {
              id: "76d0f595c9d0208e79c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44815&size=130"
          },
          {
              id: "fd7f613a5d7fb421ed6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44816&size=130"
          },
          {
              id: "4a14d751eb14024a5b05",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44817&size=130"
          },
          {
              id: "1f38817dbd3854660d29",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44818&size=130"
          },
          {
              id: "acbc33f90fbce6e2bfad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44819&size=130"
          },
          {
              id: "5b80c3c5ff8016de4f91",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44820&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker4f6edcaae0ef09b150fe.png",
      price: "Miễn phí",
      name: "Suri Ngáo Ngơ",
      thumbImg: "https://zalo-api.zadn.vn/1/3/c/8/6/11807/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/1/3/c/8/6/11807/icon_pre/suri_thumb.png",
      id: "ec7c7fb843fdaaa3f3ec",
      source: "RYMEE Studio",
      totalImage: "23 sticker",
      value: [
          {
              id: "030bc24afe0f17514e1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43853&size=130"
          },
          {
              id: "c12d036c3f29d6778f38",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43854&size=130"
          },
          {
              id: "48058b44b7015e5f0710",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43855&size=130"
          },
          {
              id: "f0ee2caf10eaf9b4a0fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43856&size=130"
          },
          {
              id: "1c2ec16ffd2a14744d3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43857&size=130"
          },
          {
              id: "64fcbabd86f86fa636e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43858&size=130"
          },
          {
              id: "42c79d86a1c3489d11d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43859&size=130"
          },
          {
              id: "d02508643421dd7f8430",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43860&size=130"
          },
          {
              id: "f4e82da911ecf8b2a1fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43861&size=130"
          },
          {
              id: "8e3b547a683f8161d82e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43862&size=130"
          },
          {
              id: "696db22c8e6967373e78",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43863&size=130"
          },
          {
              id: "b10165405905b05be914",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43864&size=130"
          },
          {
              id: "871752566e13874dde02",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43865&size=130"
          },
          {
              id: "29deff9fc3da2a8473cb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43866&size=130"
          },
          {
              id: "d5e102a03ee5d7bb8ef4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43867&size=130"
          },
          {
              id: "4a7c9a3da6784f261669",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43868&size=130"
          },
          {
              id: "b74166005a45b31bea54",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43869&size=130"
          },
          {
              id: "1becc9adf5e81cb645f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43870&size=130"
          },
          {
              id: "879c54dd689881c6d889",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43871&size=130"
          },
          {
              id: "644c880db4485d160459",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43872&size=130"
          },
          {
              id: "fd4e100f2c4ac5149c5b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43873&size=130"
          },
          {
              id: "02e9eca8d0ed39b360fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43874&size=130"
          },
          {
              id: "4ecda18c9dc974972dd8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43875&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickere0a8a26f9e2a77742e3b.png",
      price: "Miễn phí",
      name: "Workaholic Usagyuuun",
      thumbImg: "https://zalo-api.zadn.vn/3/a/6/c/2/11726/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/3/a/6/c/2/11726/icon_pre/usagyuun_thumb.png",
      id: "472b05ec39a9d0f789b8",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "a2039d4ca10948571118",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42419&size=130"
          },
          {
              id: "a5c09d8fa1ca489411db",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42420&size=130"
          },
          {
              id: "0e15375a0b1fe241bb0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42421&size=130"
          },
          {
              id: "2ddb17942bd1c28f9bc0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42422&size=130"
          },
          {
              id: "f5bccef3f2b61be842a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42423&size=130"
          },
          {
              id: "e8d7dc98e0dd098350cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42424&size=130"
          },
          {
              id: "38250d6a312fd871813e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42425&size=130"
          },
          {
              id: "0e263869042ced72b43d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42426&size=130"
          },
          {
              id: "74d643997fdc9682cfcd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42427&size=130"
          },
          {
              id: "f69ec6d1fa9413ca4a85",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42428&size=130"
          },
          {
              id: "3ae80ba737e2debc87f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42429&size=130"
          },
          {
              id: "6bbb59f465b18cefd5a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42430&size=130"
          },
          {
              id: "d629e566d923307d6932",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42431&size=130"
          },
          {
              id: "90e8dca7e0e209bc50f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42432&size=130"
          },
          {
              id: "8f20c26ffe2a17744e3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42433&size=130"
          },
          {
              id: "a4e8eaa7d6e23fbc66f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42434&size=130"
          },
          {
              id: "014c4e0372469b18c257",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42435&size=130"
          },
          {
              id: "dca194eea8ab41f518ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42436&size=130"
          },
          {
              id: "75723c3d0078e926b069",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42437&size=130"
          },
          {
              id: "7f40350f094ae014b95b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42438&size=130"
          },
          {
              id: "ae2de562d92730796936",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42439&size=130"
          },
          {
              id: "68602c2f106af934a07b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42440&size=130"
          },
          {
              id: "e0e8a5a799e270bc29f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42441&size=130"
          },
          {
              id: "72c0348f08cae194b8db",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42442&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker0d600ea732e2dbbc82f3.png",
      price: "Miễn phí",
      name: "Angora Rabbit ",
      thumbImg: "https://zalo-api.zadn.vn/8/0/e/4/e/11663/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/8/0/e/4/e/11663/icon_pre/angorarabbit_thumb.png",
      id: "8d548e93b2d65b8802c7",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "31d9e192ddd734896dc6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41308&size=130"
          },
          {
              id: "ed273c6c0029e977b038",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41309&size=130"
          },
          {
              id: "8bf159ba65ff8ca1d5ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41310&size=130"
          },
          {
              id: "1f99ccd2f09719c94086",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41311&size=130"
          },
          {
              id: "d26e3e250260eb3eb271",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41312&size=130"
          },
          {
              id: "7d589013ac5645081c47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41313&size=130"
          },
          {
              id: "9745790e454bac15f55a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41314&size=130"
          },
          {
              id: "0585eaced68b3fd5669a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41315&size=130"
          },
          {
              id: "21fcc9b7f5f21cac45e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41316&size=130"
          },
          {
              id: "534eba0586406f1e3651",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41317&size=130"
          },
          {
              id: "729e98d5a4904dce1481",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41318&size=130"
          },
          {
              id: "5aabb1e08da564fb3db4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41319&size=130"
          },
          {
              id: "b1c2558969cc8092d9dd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41320&size=130"
          },
          {
              id: "6f5d8a16b6535f0d0642",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41321&size=130"
          },
          {
              id: "eeda089134d4dd8a84c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41322&size=130"
          },
          {
              id: "f54e12052e40c71e9e51",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41323&size=130"
          },
          {
              id: "7ee69eada2e84bb612f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41324&size=130"
          },
          {
              id: "34efd5a4e9e100bf59f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41325&size=130"
          },
          {
              id: "dbc5398e05cbec95b5da",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41326&size=130"
          },
          {
              id: "e6f605bd39f8d0a689e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41327&size=130"
          },
          {
              id: "af1c53576f12864cdf03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41328&size=130"
          },
          {
              id: "5295afde939b7ac5238a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41329&size=130"
          },
          {
              id: "c98f37c40b81e2dfbb90",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41330&size=130"
          },
          {
              id: "bae145aa79ef90b1c9fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41331&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7ab6e672da3733696a26.png",
      price: "Miễn phí",
      name: "Thỏ Molang",
      thumbImg: "https://zalo-api.zadn.vn/9/3/e/6/8/11792/preview/preview.png",
      iconUrl: "https://zalo-api.zadn.vn/9/3/e/6/8/11792/icon_pre/icon_pre.png",
      id: "034f9f8ba3ce4a9013df",
      source: "Tenor Inc. ",
      totalImage: "24 sticker",
      value: [
          {
              id: "91557d154150a80ef141",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43616&size=130"
          },
          {
              id: "62018f41b3045a5a0315",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43617&size=130"
          },
          {
              id: "11b7fff7c3b22aec73a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43618&size=130"
          },
          {
              id: "134ffc0fc04a2914705b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43619&size=130"
          },
          {
              id: "71a399e3a5a64cf815b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43620&size=130"
          },
          {
              id: "aac643867fc3969dcfd2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43621&size=130"
          },
          {
              id: "1efef4bec8fb21a578ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43622&size=130"
          },
          {
              id: "07e1eca1d0e439ba60f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43623&size=130"
          },
          {
              id: "271cc35cff1916474f08",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43624&size=130"
          },
          {
              id: "49c6ac8690c3799d20d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43625&size=130"
          },
          {
              id: "7a089c48a00d4953101c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43626&size=130"
          },
          {
              id: "962c716c4d29a477fd38",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43627&size=130"
          },
          {
              id: "ef570f173352da0c8343",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43628&size=130"
          },
          {
              id: "792a986aa42f4d71143e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43629&size=130"
          },
          {
              id: "af354d757130986ec121",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43630&size=130"
          },
          {
              id: "1634f574c931206f7920",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43631&size=130"
          },
          {
              id: "3f88c3c8ff8d16d34f9c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43632&size=130"
          },
          {
              id: "1eb6e3f6dfb336ed6fa2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43633&size=130"
          },
          {
              id: "0cd8f298cedd27837ecc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43634&size=130"
          },
          {
              id: "1162ee22d2673b396276",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43635&size=130"
          },
          {
              id: "aa1052506e15874bde04",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43636&size=130"
          },
          {
              id: "62519b11a7544e0a1745",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43637&size=130"
          },
          {
              id: "3b39c179fd3c14624d2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43638&size=130"
          },
          {
              id: "7d118651ba14534a0a05",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=43639&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7ca668615424bd7ae435.png",
      price: "Miễn phí",
      name: "Peep Peep",
      thumbImg: "https://zalo-api.zadn.vn/2/e/2/3/b/11672/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/2/e/2/3/b/11672/icon_pre/peeppeep_thumb.png",
      id: "632477e34ba6a2f8fbb7",
      source: "WONYFRAME",
      totalImage: "12 sticker",
      value: [
          {
              id: "2f5f4c147051990fc040",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41455&size=130"
          },
          {
              id: "7ac5068e3acbd3958ada",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41456&size=130"
          },
          {
              id: "bd87c0ccfc8915d74c98",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41457&size=130"
          },
          {
              id: "21675f2c63698a37d378",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41458&size=130"
          },
          {
              id: "d6f6a9bd95f87ca625e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41459&size=130"
          },
          {
              id: "54c02c8b10cef990a0df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41460&size=130"
          },
          {
              id: "fdb484ffb8ba51e408ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41461&size=130"
          },
          {
              id: "9a39e072dc3735696c26",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41462&size=130"
          },
          {
              id: "79da02913ed4d78a8ec5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41463&size=130"
          },
          {
              id: "ed0e9945a5004c5e1511",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41464&size=130"
          },
          {
              id: "449b31d00d95e4cbbd84",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41465&size=130"
          },
          {
              id: "e7e191aaadef44b11dfe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41466&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrstickerfe51bf9683d36a8d33c2.png",
      price: "Miễn phí",
      name: "Piyo Chan",
      thumbImg: "https://zalo-api.zadn.vn/d/4/f/3/1/11725/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/d/4/f/3/1/11725/icon_pre/piyochan_thumb.png",
      id: "c3c78200be45571b0e54",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "62a375ec49a9a0f7f9b8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42395&size=130"
          },
          {
              id: "2fb23ffd03b8eae6b3a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42396&size=130"
          },
          {
              id: "81469009ac4c45121c5d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42397&size=130"
          },
          {
              id: "06fe14b128f4c1aa98e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42398&size=130"
          },
          {
              id: "9ac1898eb5cb5c9505da",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42399&size=130"
          },
          {
              id: "096825271962f03ca973",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42400&size=130"
          },
          {
              id: "773d5a7266378f69d626",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42401&size=130"
          },
          {
              id: "da29f466c823217d7832",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42402&size=130"
          },
          {
              id: "9a45b50a894f6011395e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42403&size=130"
          },
          {
              id: "6a8442cb7e8e97d0ce9f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42404&size=130"
          },
          {
              id: "d695ffdac39f2ac1738e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42405&size=130"
          },
          {
              id: "a6d78c98b0dd598300cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42406&size=130"
          },
          {
              id: "b0469b09a74c4e12175d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42407&size=130"
          },
          {
              id: "b14c9503a94640181957",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42408&size=130"
          },
          {
              id: "d610f35fcf1a26447f0b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42409&size=130"
          },
          {
              id: "68264e69722c9b72c23d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42410&size=130"
          },
          {
              id: "d582f2cdce8827d67e99",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42411&size=130"
          },
          {
              id: "db91fbdec79b2ec5778a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42412&size=130"
          },
          {
              id: "476866275a62b33cea73",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42413&size=130"
          },
          {
              id: "426a60255c60b53eec71",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42414&size=130"
          },
          {
              id: "eb71c83ef47b1d25446a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42415&size=130"
          },
          {
              id: "0f70333f0f7ae624bf6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42416&size=130"
          },
          {
              id: "7f60422f7e6a9734ce7b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42417&size=130"
          },
          {
              id: "b77a8935b5705c2e0561",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42418&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerbea308673422dd7c8433.png",
      price: "Miễn phí",
      name: "Peep Peep 2",
      thumbImg: "https://zalo-api.zadn.vn/e/1/3/6/0/11834/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/e/1/3/6/0/11834/icon_pre/peeppeep2_thumb.png",
      id: "9589234d1f08f656af19",
      source: "WONYFRAME",
      totalImage: "12 sticker",
      value: [
          {
              id: "fcc6ad8091c5789b21d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44253&size=130"
          },
          {
              id: "4c451e032246cb189257",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44254&size=130"
          },
          {
              id: "8fa5dce3e0a609f850b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44255&size=130"
          },
          {
              id: "5edd329b0edee780becf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44256&size=130"
          },
          {
              id: "94edf9abc5ee2cb075ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44257&size=130"
          },
          {
              id: "02ed6cab50eeb9b0e0ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44258&size=130"
          },
          {
              id: "20494f0f734a9a14c35b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44259&size=130"
          },
          {
              id: "24ef4ca970ec99b2c0fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44260&size=130"
          },
          {
              id: "4c2325651920f07ea931",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44261&size=130"
          },
          {
              id: "7d6c172a2b6fc2319b7e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44262&size=130"
          },
          {
              id: "65800ec63283dbdd8292",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44263&size=130"
          },
          {
              id: "e1168550b915504b0904",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=44264&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker4e40d481e8c4019a58d5.png",
      price: "Miễn phí",
      name: "Mini Usagyuuun",
      thumbImg: "https://zalo-api.zadn.vn/1/1/a/3/2/11030/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/1/1/a/3/2/11030/icon_pre/miniusagyuuun_thumb.png",
      id: "4e62d4a3e8e601b858f7",
      source: "Minto Inc. ",
      totalImage: "24 sticker",
      value: [
          {
              id: "04439fd4a3914acf1380",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32023&size=130"
          },
          {
              id: "5f8dcb1af75f1e01474e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32024&size=130"
          },
          {
              id: "68fafd6dc12828767139",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32025&size=130"
          },
          {
              id: "ad4c3bdb079eeec0b78f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32026&size=130"
          },
          {
              id: "79ffee68d22d3b73623c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32027&size=130"
          },
          {
              id: "0b079b90a7d54e8b17c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32028&size=130"
          },
          {
              id: "5909c89ef4db1d8544ca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32029&size=130"
          },
          {
              id: "912303b43ff1d6af8fe0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32030&size=130"
          },
          {
              id: "3a65a9f295b77ce925a6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32031&size=130"
          },
          {
              id: "1fd2b3458f00665e3f11",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32032&size=130"
          },
          {
              id: "0b97a6009a45731b2a54",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32033&size=130"
          },
          {
              id: "a50b0b9c37d9de8787c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32034&size=130"
          },
          {
              id: "5360fcf7c0b229ec70a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32035&size=130"
          },
          {
              id: "892521b21df7f4a9ade6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32036&size=130"
          },
          {
              id: "8d0d249a18dff181a8ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32037&size=130"
          },
          {
              id: "04a2ae3592707b2e2261",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32038&size=130"
          },
          {
              id: "6542ced5f2901bce4281",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32039&size=130"
          },
          {
              id: "df537bc44781aedff790",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32040&size=130"
          },
          {
              id: "d23477a34be6a2b8fbf7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32041&size=130"
          },
          {
              id: "3d069b91a7d44e8a17c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32042&size=130"
          },
          {
              id: "667cc1ebfdae14f04dbf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32043&size=130"
          },
          {
              id: "357995eea9ab40f519ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32044&size=130"
          },
          {
              id: "287189e6b5a35cfd05b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32045&size=130"
          },
          {
              id: "855527c21b87f2d9ab96",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32046&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker8c46d181edc4049a5dd5.png",
      price: "Miễn phí",
      name: "Boba Hóng Hớt",
      thumbImg: "https://zalo-api.zadn.vn/b/5/9/9/e/11729/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/b/5/9/9/e/11729/icon_pre/bobagirl_thumb.png",
      id: "4bc316042a41c31f9a50",
      source: "Boba",
      totalImage: "24 sticker",
      value: [
          {
              id: "c1cba98495c17c9f25d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42468&size=130"
          },
          {
              id: "d3acbae386a66ff836b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42469&size=130"
          },
          {
              id: "5fd6359909dce082b9cd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42470&size=130"
          },
          {
              id: "7ded16a22ae7c3b99af6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42471&size=130"
          },
          {
              id: "70f714b828fdc1a398ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42472&size=130"
          },
          {
              id: "133676794a3ca362fa2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42473&size=130"
          },
          {
              id: "977bf134cd71242f7d60",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42474&size=130"
          },
          {
              id: "c69da1d29d9774c92d86",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42475&size=130"
          },
          {
              id: "0c756c3a507fb921e06e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42476&size=130"
          },
          {
              id: "7734167b2a3ec3609a2f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42477&size=130"
          },
          {
              id: "af29cd66f123187d4132",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42478&size=130"
          },
          {
              id: "235940167c53950dcc42",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42479&size=130"
          },
          {
              id: "877ffb30c7752e2b7764",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42480&size=130"
          },
          {
              id: "f1638c2cb06959370078",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42481&size=130"
          },
          {
              id: "94a4eaebd6ae3ff066bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42482&size=130"
          },
          {
              id: "0fee70a14ce4a5bafcf5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42483&size=130"
          },
          {
              id: "5a04224b1e0ef750ae1f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42484&size=130"
          },
          {
              id: "cbf3b2bc8ef967a73ee8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42485&size=130"
          },
          {
              id: "2d25576a6b2f8271db3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42486&size=130"
          },
          {
              id: "8a9ff1d0cd9524cb7d84",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42487&size=130"
          },
          {
              id: "af01db4ee70b0e55571a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42488&size=130"
          },
          {
              id: "aa11df5ee31b0a45530a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42489&size=130"
          },
          {
              id: "0b5b7d144151a80ff140",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42490&size=130"
          },
          {
              id: "bda9cae6f6a31ffd46b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=42491&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerdc3be4fcd8b931e768a8.png",
      price: "Miễn phí",
      name: "Bánh Moon Siêu Quậy",
      thumbImg: "https://zalo-api.zadn.vn/e/9/f/5/4/11700/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/e/9/f/5/4/11700/icon_pre/mooncake_thumb.png",
      id: "31d709103555dc0b8544",
      source: "Nam Nguyen",
      totalImage: "12 sticker",
      value: [
          {
              id: "f7209e69a22c4b72123d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41957&size=130"
          },
          {
              id: "5c15365c0a19e347ba08",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41958&size=130"
          },
          {
              id: "5c4837010b44e21abb55",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41959&size=130"
          },
          {
              id: "dfedbba487e16ebf37f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41960&size=130"
          },
          {
              id: "5fa33aea06afeff1b6be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41961&size=130"
          },
          {
              id: "6b480d013144d81a8155",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41962&size=130"
          },
          {
              id: "ef05884cb4095d570418",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41963&size=130"
          },
          {
              id: "cae1aaa896ed7fb326fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41964&size=130"
          },
          {
              id: "b78fd6c6ea8303dd5a92",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41965&size=130"
          },
          {
              id: "cfa3adea91af78f121be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41966&size=130"
          },
          {
              id: "27cb448278c79199c8d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41967&size=130"
          },
          {
              id: "dc0ba0429c0775592c16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41968&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7d3185f6b9b350ed09a2.png",
      price: "Miễn phí",
      name: "Baby Wony 2",
      thumbImg: "https://zalo-api.zadn.vn/8/c/4/5/8/11636/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/8/c/4/5/8/11636/icon_pre/wony_thumb.png",
      id: "4306bbc187846eda3795",
      source: "WONYFRAME",
      totalImage: "24 sticker",
      value: [
          {
              id: "2bc7d3b2eff706a95fe6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40820&size=130"
          },
          {
              id: "8aea739f4fdaa684ffcb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40821&size=130"
          },
          {
              id: "d47b2e0e124bfb15a25a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40822&size=130"
          },
          {
              id: "27d0dca5e0e009be50f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40823&size=130"
          },
          {
              id: "845470214c64a53afc75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40824&size=130"
          },
          {
              id: "fe4a0b3f377ade24876b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40825&size=130"
          },
          {
              id: "93a965dc5999b0c7e988",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40826&size=130"
          },
          {
              id: "9be86c9d50d8b986e0c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40827&size=130"
          },
          {
              id: "c91139640521ec7fb530",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40828&size=130"
          },
          {
              id: "e04211372d72c42c9d63",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40829&size=130"
          },
          {
              id: "54b6a6c39a8673d82a97",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40830&size=130"
          },
          {
              id: "0969fa1cc6592f077648",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40831&size=130"
          },
          {
              id: "a069ac1c905979072048",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40832&size=130"
          },
          {
              id: "7829755c4919a047f908",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40833&size=130"
          },
          {
              id: "b4cbbabe86fb6fa536ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40834&size=130"
          },
          {
              id: "ac3ba34e9f0b76552f1a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40835&size=130"
          },
          {
              id: "9f409735ab70422e1b61",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40836&size=130"
          },
          {
              id: "32493b3c0779ee27b768",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40837&size=130"
          },
          {
              id: "50c55ab066f58fabd6e4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40838&size=130"
          },
          {
              id: "5d5656236a668338da77",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40839&size=130"
          },
          {
              id: "100314762833c16d9822",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40840&size=130"
          },
          {
              id: "7ec07bb547f0aeaef7e1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40841&size=130"
          },
          {
              id: "a174a7019b44721a2b55",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40842&size=130"
          },
          {
              id: "7a957de041a5a8fbf1b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40843&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker21e02f271362fa3ca373.png",
      price: "Miễn phí",
      name: "Hoa Phượng Đỏ",
      thumbImg: "https://zalo-api.zadn.vn/8/0/0/d/7/11650/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/8/0/0/d/7/11650/icon_pre/hoaphuong_thumb.png",
      id: "9e6490a3ace645b81cf7",
      source: "Thành Đoàn TP. Hồ Chí Minh",
      totalImage: "20 sticker",
      value: [
          {
              id: "5e57b41d885861063849",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41062&size=130"
          },
          {
              id: "bd5356196a5c8302da4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41063&size=130"
          },
          {
              id: "a18245c8798d90d3c99c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41064&size=130"
          },
          {
              id: "73099643aa0643581a17",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41065&size=130"
          },
          {
              id: "527cb4368873612d3862",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41066&size=130"
          },
          {
              id: "2588c2c2fe8717d94e96",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41067&size=130"
          },
          {
              id: "b97b593165748c2ad565",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41068&size=130"
          },
          {
              id: "fef41fbe23fbcaa593ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41069&size=130"
          },
          {
              id: "da9c38d60493edcdb482",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41070&size=130"
          },
          {
              id: "738990c3ac8645d81c97",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41071&size=130"
          },
          {
              id: "3b12c758fb1d12434b0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41072&size=130"
          },
          {
              id: "f9d7049d38d8d18688c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41073&size=130"
          },
          {
              id: "82187c524017a949f006",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41074&size=130"
          },
          {
              id: "b7ed48a774e29dbcc4f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41075&size=130"
          },
          {
              id: "e854101e2c5bc5059c4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41076&size=130"
          },
          {
              id: "fda704ed38a8d1f688b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41077&size=130"
          },
          {
              id: "9e5a64105855b10be844",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41078&size=130"
          },
          {
              id: "53aba8e194a47dfa24b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41079&size=130"
          },
          {
              id: "3e01ca4bf60e1f50461f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41080&size=130"
          },
          {
              id: "ae585b1267578e09d746",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=41081&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker757492b3aef647a81ee7.png",
      price: "Miễn phí",
      name: "Cà Khịa",
      thumbImg: "https://zalo-api.zadn.vn/c/2/8/1/8/11627/preview/440X440.png",
      iconUrl: "https://zalo-api.zadn.vn/c/2/8/1/8/11627/icon_pre/cakhia_thumb.png",
      id: "bcad5b6a672f8e71d73e",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "9715c561f924107a4935",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40670&size=130"
          },
          {
              id: "fd96aee292a77bf922b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40671&size=130"
          },
          {
              id: "1a1d76694a2ca372fa3d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40672&size=130"
          },
          {
              id: "94d9f9adc5e82cb675f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40673&size=130"
          },
          {
              id: "28bd46c97a8c93d2ca9d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40674&size=130"
          },
          {
              id: "1fe570914cd4a58afcc5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40675&size=130"
          },
          {
              id: "00b868cc5489bdd7e498",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40676&size=130"
          },
          {
              id: "d9d2b0a68ce365bd3cf2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40677&size=130"
          },
          {
              id: "ab88c1fcfdb914e74da8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40678&size=130"
          },
          {
              id: "2d9d46e97aac93f2cabd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40679&size=130"
          },
          {
              id: "662d02593e1cd7428e0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40680&size=130"
          },
          {
              id: "1a2e7f5a431faa41f30e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=40681&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickera50f97c9ab8c42d21b9d.png",
      price: "Miễn phí",
      name: "Zookiz - Chuyện Đời Thường",
      thumbImg: "https://zalo-api.zadn.vn/8/b/3/6/c/11454/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/8/b/3/6/c/11454/icon_pre/zookizdoithuong_thumb.png",
      id: "1dca2f0c1349fa17a358",
      source: "Zookiz",
      totalImage: "12 sticker",
      value: [
          {
              id: "9727c858f41d1d43440c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38355&size=130"
          },
          {
              id: "73522b2d1768fe36a779",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38356&size=130"
          },
          {
              id: "084e51316d74842add65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38357&size=130"
          },
          {
              id: "b230e84fd40a3d54641b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38358&size=130"
          },
          {
              id: "51550a2a366fdf31867e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38359&size=130"
          },
          {
              id: "0a195e6662238b7dd232",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38360&size=130"
          },
          {
              id: "ab5efe21c2642b3a7275",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38361&size=130"
          },
          {
              id: "66a930d60c93e5cdbc82",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38362&size=130"
          },
          {
              id: "2c517b2e476bae35f77a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38363&size=130"
          },
          {
              id: "7c3c2c431006f958a017",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38364&size=130"
          },
          {
              id: "19b648c9748c9dd2c49d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38365&size=130"
          },
          {
              id: "e9e9bb9687d36e8d37c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=38366&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker01d0da16e6530f0d5642.png",
      price: "Miễn phí",
      name: "Zookiz Giải Trí",
      thumbImg: "https://zalo-api.zadn.vn/7/f/4/6/c/11351/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/7/f/4/6/c/11351/icon_pre/zookizgiaitri_thumb.png",
      id: "3efbe53dd97830266969",
      source: "Zookiz",
      totalImage: "12 sticker",
      value: [
          {
              id: "5f0a8d72b13758690126",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37470&size=130"
          },
          {
              id: "831d50656c20857edc31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37471&size=130"
          },
          {
              id: "9af976814ac4a39afad5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37472&size=130"
          },
          {
              id: "daed37950bd0e28ebbc1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37473&size=130"
          },
          {
              id: "1d3af342cf0726597f16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37474&size=130"
          },
          {
              id: "5e0fb1778d32646c3d23",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37475&size=130"
          },
          {
              id: "0a23e25bde1e37406e0f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37476&size=130"
          },
          {
              id: "a65f4f2773629a3cc373",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37477&size=130"
          },
          {
              id: "6b65811dbd5854060d49",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37478&size=130"
          },
          {
              id: "97b47ccc4089a9d7f098",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37479&size=130"
          },
          {
              id: "5e51ba29866c6f32367d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37480&size=130"
          },
          {
              id: "7ad09fa8a3ed4ab313fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=37481&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker48f119302575cc2b9564.png",
      price: "Miễn phí",
      name: "Zookiz Đi Làm",
      thumbImg: "https://zalo-api.zadn.vn/4/1/1/c/4/11229/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/4/1/1/c/4/11229/icon_pre/zookizcongso_thumb.png",
      id: "a678f7b9cbfc22a27bed",
      source: "0",
      totalImage: "16 sticker",
      value: [
          {
              id: "4e084c68702d9973c03c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35470&size=130"
          },
          {
              id: "d3c7d0a7ece205bc5cf3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35471&size=130"
          },
          {
              id: "f52be94bd50e3c50651f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35472&size=130"
          },
          {
              id: "8a1d977dab3842661b29",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35473&size=130"
          },
          {
              id: "4dd953b96ffc86a2dfed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35474&size=130"
          },
          {
              id: "d088cfe8f3ad1af343bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35475&size=130"
          },
          {
              id: "292031400d05e45bbd14",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35476&size=130"
          },
          {
              id: "e374fa14c6512f0f7640",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35477&size=130"
          },
          {
              id: "26dc3cbc00f9e9a7b0e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35478&size=130"
          },
          {
              id: "e4b0ffd0c3952acb7384",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35479&size=130"
          },
          {
              id: "78466c265063b93de072",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35480&size=130"
          },
          {
              id: "9ced898db5c85c9605d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35481&size=130"
          },
          {
              id: "518347e37ba692f8cbb7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35482&size=130"
          },
          {
              id: "af22b84284076d593416",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35483&size=130"
          },
          {
              id: "532443447f01965fcf10",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35484&size=130"
          },
          {
              id: "73c462a45ee1b7bfeef0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35485&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker3bad626c5e29b777ee38.png",
      price: "Miễn phí",
      name: "Baby Wony",
      thumbImg: "https://zalo-api.zadn.vn/0/e/1/0/5/11221/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/0/e/1/0/5/11221/icon_pre/wony_thumb.png",
      id: "153c4cfd70b899e6c0a9",
      source: "WONYFRAME",
      totalImage: "24 sticker",
      value: [
          {
              id: "c2e46a8456c1bf9fe6d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35364&size=130"
          },
          {
              id: "8b5722371e72f72cae63",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35365&size=130"
          },
          {
              id: "fd0d576d6b288276db39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35366&size=130"
          },
          {
              id: "dfa374c34886a1d8f897",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35367&size=130"
          },
          {
              id: "fbee5f8e63cb8a95d3da",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35368&size=130"
          },
          {
              id: "0ddca8bc94f97da724e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35369&size=130"
          },
          {
              id: "aa2a0c4a300fd951801e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35370&size=130"
          },
          {
              id: "ab870ce730a2d9fc80b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35371&size=130"
          },
          {
              id: "ce3f6e5f521abb44e20b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35372&size=130"
          },
          {
              id: "a09d01fd3db8d4e68da9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35373&size=130"
          },
          {
              id: "8cfa2e9a12dffb81a2ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35374&size=130"
          },
          {
              id: "a2f101913dd4d48a8dc5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35375&size=130"
          },
          {
              id: "f37f4f1f735a9a04c34b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35376&size=130"
          },
          {
              id: "105fad3f917a7824216b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35377&size=130"
          },
          {
              id: "22429c22a06749391076",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35378&size=130"
          },
          {
              id: "34a68bc6b7835edd0792",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35379&size=130"
          },
          {
              id: "76b2ced2f2971bc94286",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35380&size=130"
          },
          {
              id: "cf6f760f4a4aa314fa5b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35381&size=130"
          },
          {
              id: "c4457e254260ab3ef271",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35382&size=130"
          },
          {
              id: "80413b210764ee3ab775",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35383&size=130"
          },
          {
              id: "7a1bce7bf23e1b60422f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35384&size=130"
          },
          {
              id: "e83c5d5c61198847d108",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35385&size=130"
          },
          {
              id: "5c07ea67d6223f7c6633",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35386&size=130"
          },
          {
              id: "6f15d875e4300d6e5421",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=35387&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerfc4c3c8d00c8e996b0d9.png",
      price: "Miễn phí",
      name: "Piyomaru x Tasty Kid",
      thumbImg: "https://zalo-api.zadn.vn/a/2/3/1/5/11084/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/a/2/3/1/5/11084/icon_pre/acecook_thumb.png",
      id: "4fda8f1bb35e5a00034f",
      source: "",
      totalImage: "16 sticker",
      value: [
          {
              id: "d0ecf886c4c32d9d74d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32932&size=130"
          },
          {
              id: "b0b099daa59f4cc1158e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32933&size=130"
          },
          {
              id: "9c73b6198a5c63023a4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32934&size=130"
          },
          {
              id: "75705e1a625f8b01d24e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32935&size=130"
          },
          {
              id: "214c05263963d03d8972",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32936&size=130"
          },
          {
              id: "a25c8736bb73522d0b62",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32937&size=130"
          },
          {
              id: "341f12752e30c76e9e21",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32938&size=130"
          },
          {
              id: "abd38cb9b0fc59a200ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32939&size=130"
          },
          {
              id: "aed38eb9b2fc5ba202ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32940&size=130"
          },
          {
              id: "3ed81fb223f7caa993e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32941&size=130"
          },
          {
              id: "f4e7d68deac803965ad9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32942&size=130"
          },
          {
              id: "9677b51d895860063949",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32943&size=130"
          },
          {
              id: "66035a69662c8f72d63d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32944&size=130"
          },
          {
              id: "7c5941337d769428cd67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32945&size=130"
          },
          {
              id: "b8e28688bacd53930adc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32946&size=130"
          },
          {
              id: "e7afd8c5e4800dde5491",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=32947&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc2726ab456f1bfafe6e0.png",
      price: "Miễn phí",
      name: "Chuyện Chàng Tom",
      thumbImg: "https://zalo-api.zadn.vn/8/3/6/0/8/11300/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/8/3/6/0/8/11300/icon_pre/chuyenchangtom_thumb.png",
      id: "d8c8700e4c4ba515fc5a",
      source: "Chuyện Chàng Tom",
      totalImage: "20 sticker",
      value: [
          {
              id: "cabdb7d98b9c62c23b8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36593&size=130"
          },
          {
              id: "c91cb7788b3d62633b2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36594&size=130"
          },
          {
              id: "fda582c1be8457da0e95",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36595&size=130"
          },
          {
              id: "f8bf80dbbc9e55c00c8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36596&size=130"
          },
          {
              id: "da97a3f39fb676e82fa7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36597&size=130"
          },
          {
              id: "3cf046947ad1938fcac0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36598&size=130"
          },
          {
              id: "cd0eb66a8a2f63713a3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36599&size=130"
          },
          {
              id: "bcf3c897f4d21d8c44c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36600&size=130"
          },
          {
              id: "8b5dfe39c27c2b22726d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36601&size=130"
          },
          {
              id: "9429e24dde0837566e19",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36602&size=130"
          },
          {
              id: "2e8a59ee65ab8cf5d5ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36603&size=130"
          },
          {
              id: "bca1ccc5f08019de4091",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36604&size=130"
          },
          {
              id: "8df2fc96c0d3298d70c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36605&size=130"
          },
          {
              id: "cf7dbd19815c6802314d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36606&size=130"
          },
          {
              id: "bc51cf35f3701a2e4361",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36607&size=130"
          },
          {
              id: "48f7c492f8d7118948c6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36608&size=130"
          },
          {
              id: "9a4417212b64c23a9b75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36609&size=130"
          },
          {
              id: "da1354766833816dd822",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36610&size=130"
          },
          {
              id: "2827a7429b0772592b16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36611&size=130"
          },
          {
              id: "4bcac3afffea16b44ffb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=36612&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickere278e0b9dcfc35a26ced.png",
      price: "Miễn phí",
      name: "Bé Dúi",
      thumbImg: "https://res-zalo.zadn.vn/upload/media/2020/3/20/240x240_1584691157726_607159.png",
      iconUrl: "https://zalo-api.zadn.vn/a/c/c/f/a/11150/icon_pre/bedui_thumb.png",
      id: "108312422e07c7599e16",
      source: "",
      totalImage: "15 sticker",
      value: [
          {
              id: "bb580c373072d92c8063",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34107&size=130"
          },
          {
              id: "e59a55f569b080eed9a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34108&size=130"
          },
          {
              id: "e1b050df6c9a85c4dc8b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34109&size=130"
          },
          {
              id: "515de332df7736296f66",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34110&size=130"
          },
          {
              id: "e19f66fd5ab8b3e6eaa9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34827&size=130"
          },
          {
              id: "5375d317ef52060c5f43",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34828&size=130"
          },
          {
              id: "e34c622e5e6bb735ee7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34829&size=130"
          },
          {
              id: "6827ea45d6003f5e6611",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34830&size=130"
          },
          {
              id: "ed5f6e3d5278bb26e269",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34831&size=130"
          },
          {
              id: "e20f7e6d4228ab76f239",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34832&size=130"
          },
          {
              id: "e06a7d08414da813f15c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34833&size=130"
          },
          {
              id: "035d9d3fa17a4824116b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34834&size=130"
          },
          {
              id: "a78c38ee04abedf5b4ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34835&size=130"
          },
          {
              id: "5724cf46f3031a5d4312",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34836&size=130"
          },
          {
              id: "5383cae1f6a41ffa46b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34837&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker858e36430a06e358ba17.png",
      price: "Miễn phí",
      name: "Piyomaru",
      thumbImg: "https://zalo-api.zadn.vn/e/c/c/d/9/10047/preview/piyomaru_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/e/c/c/d/9/10047/icon_pre/piyomaru_thumb.png",
      id: "1336a0fb9cbe75e02caf",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "4894863fba7a53240a6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16706&size=130"
          },
          {
              id: "2d6ce2c7de8237dc6e93",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16707&size=130"
          },
          {
              id: "7feab7418b04625a3b15",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16708&size=130"
          },
          {
              id: "9c4855e369a680f8d9b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16709&size=130"
          },
          {
              id: "87204d8b71ce9890c1df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16710&size=130"
          },
          {
              id: "6fffa4549811714f2800",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16711&size=130"
          },
          {
              id: "a62c62875ec2b79ceed3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16712&size=130"
          },
          {
              id: "5f609acba68e4fd0169f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16713&size=130"
          },
          {
              id: "0f26c98df5c81c9645d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16714&size=130"
          },
          {
              id: "4b128cb9b0fc59a200ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16715&size=130"
          },
          {
              id: "d25712fc2eb9c7e79ea8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16716&size=130"
          },
          {
              id: "a0b7611c5d59b407ed48",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16717&size=130"
          },
          {
              id: "2fa6ed0dd14838166159",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16718&size=130"
          },
          {
              id: "c0af03043f41d61f8f50",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16719&size=130"
          },
          {
              id: "cb0217a92becc2b29bfd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16720&size=130"
          },
          {
              id: "e428398305c6ec98b5d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16721&size=130"
          },
          {
              id: "b90667ad5be8b2b6ebf9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16722&size=130"
          },
          {
              id: "b5b66a1d5658bf06e649",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16723&size=130"
          },
          {
              id: "cc3a149128d4c18a98c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16724&size=130"
          },
          {
              id: "2e06f7adcbe822b67bf9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16725&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickered32d7f3ebb602e85ba7.png",
      price: "Miễn phí",
      name: "Emoji Vui Nhộn",
      thumbImg: "https://zalo-api.zadn.vn/e/8/1/f/1/11190/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/e/8/1/f/1/11190/icon_pre/emoji_thumb.png",
      id: "6b9f515e6d1b8445dd0a",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "0ece94aca8e941b718f8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34838&size=130"
          },
          {
              id: "623ef95cc5192c477508",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34839&size=130"
          },
          {
              id: "3eb5aad796927fcc2683",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34840&size=130"
          },
          {
              id: "e7f372914ed4a78afec5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34841&size=130"
          },
          {
              id: "ea647c064043a91df052",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34842&size=130"
          },
          {
              id: "e10176634a26a378fa37",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34843&size=130"
          },
          {
              id: "a72737450b00e25ebb11",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34844&size=130"
          },
          {
              id: "abee3a8c06c9ef97b6d8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34845&size=130"
          },
          {
              id: "96d104b338f6d1a888e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34846&size=130"
          },
          {
              id: "e10772654e20a77efe31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34847&size=130"
          },
          {
              id: "de7272104e55a70bfe44",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34848&size=130"
          },
          {
              id: "3be09682aac743991ad6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34849&size=130"
          },
          {
              id: "cf3161535d16b448ed07",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34850&size=130"
          },
          {
              id: "1ff4b0968cd3658d3cc2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34851&size=130"
          },
          {
              id: "c06d680f544abd14e45b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34852&size=130"
          },
          {
              id: "fa2d534f6f0a8654df1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34853&size=130"
          },
          {
              id: "cecd64af58eab1b4e8fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34854&size=130"
          },
          {
              id: "7dbed6dcea9903c75a88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34855&size=130"
          },
          {
              id: "86c122a31ee6f7b8aef7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34856&size=130"
          },
          {
              id: "34219143ad0644581d17",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=34857&size=130"
          }
      ]
    },
    {
      textUploader: "Uploader",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker41eeb72f8b6a62343b7b.png",
      price: "Miễn phí",
      name: "Crazy Frog",
      thumbImg: "https://zalo-api.zadn.vn/1/4/f/a/f/11130/preview/240 x 240.png",
      iconUrl: "https://zalo-api.zadn.vn/1/4/f/a/f/11130/icon_pre/crazyfog_thumb.png",
      id: "1d89eb48d70d3e53671c",
      source: "Nam Nguyen",
      totalImage: "12 sticker",
      value: [
          {
              id: "80c6f9afc5ea2cb475fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33781&size=130"
          },
          {
              id: "4bd131b80dfde4a3bdec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33782&size=130"
          },
          {
              id: "b669cd00f145181b4154",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33783&size=130"
          },
          {
              id: "4a4b3e220267eb39b276",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33784&size=130"
          },
          {
              id: "da04af6d93287a762339",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33785&size=130"
          },
          {
              id: "54cf22a61ee3f7bdaef2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33786&size=130"
          },
          {
              id: "bc1fcb76f7331e6d4722",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33787&size=130"
          },
          {
              id: "0ca67ccf408aa9d4f09b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33788&size=130"
          },
          {
              id: "b0e5c18cfdc914974dd8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33789&size=130"
          },
          {
              id: "27ea558369c68098d9d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33790&size=130"
          },
          {
              id: "4d1e3e770232eb6cb223",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33791&size=130"
          },
          {
              id: "3736bb58871d6e43370c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33792&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker6e8f9d4ea10b4855111a.png",
      price: "Miễn phí",
      name: "Rich Zookiz ",
      thumbImg: "https://zalo-api.zadn.vn/4/7/5/9/9/11135/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/4/7/5/9/9/11135/icon_pre/richzookiz_thumb.png",
      id: "36d7c516f953100d4942",
      source: "Zookiz",
      totalImage: "12 sticker",
      value: [
          {
              id: "0f5fd331ef74062a5f65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33872&size=130"
          },
          {
              id: "2bfff691cad4238a7ac5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33873&size=130"
          },
          {
              id: "0055de3be27e0b20526f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33874&size=130"
          },
          {
              id: "d932065c3a19d3478a08",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33875&size=130"
          },
          {
              id: "3314eb7ad73f3e61672e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33876&size=130"
          },
          {
              id: "d74d0e233266db388277",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33877&size=130"
          },
          {
              id: "3dcde7a3dbe632b86bf7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33878&size=130"
          },
          {
              id: "95ca4ea472e19bbfc2f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33879&size=130"
          },
          {
              id: "c8b11cdf209ac9c4908b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33880&size=130"
          },
          {
              id: "2fb0fadec69b2fc5768a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33881&size=130"
          },
          {
              id: "6b0cbd62812768793136",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33882&size=130"
          },
          {
              id: "14d0c3befffb16a54fea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=33883&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrstickerfd815e4162048b5ad215.png",
      price: "Miễn phí",
      name: "Kiki 3D! Ta-da!",
      thumbImg: "https://zalo-api.zadn.vn/c/3/6/b/8/10799/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/c/3/6/b/8/10799/icon_pre/kiki3dtada_thumb.png",
      id: "d28171414d04a45afd15",
      source: "Mojitok",
      totalImage: "16 sticker",
      value: [
          {
              id: "4c2214a728e2c1bc98f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28628&size=130"
          },
          {
              id: "bc02e587d9c2309c69d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28629&size=130"
          },
          {
              id: "71ea2b6f172afe74a73b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28630&size=130"
          },
          {
              id: "f232a9b795f27cac25e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28631&size=130"
          },
          {
              id: "1a3e4ebb72fe9ba0c2ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28632&size=130"
          },
          {
              id: "3d4268c75482bddce493",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28633&size=130"
          },
          {
              id: "68403ec50280ebdeb291",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28634&size=130"
          },
          {
              id: "07e050656c20857edc31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28635&size=130"
          },
          {
              id: "0e8c5e09624c8b12d25d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28636&size=130"
          },
          {
              id: "b01be19edddb34856dca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28637&size=130"
          },
          {
              id: "39966b135756be08e747",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28638&size=130"
          },
          {
              id: "a691f514c951200f7940",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28639&size=130"
          },
          {
              id: "1a5d76d84a9da3c3fa8c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28640&size=130"
          },
          {
              id: "64aa092f356adc34857b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28641&size=130"
          },
          {
              id: "924bfccec08b29d5709a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28642&size=130"
          },
          {
              id: "372a58af64ea8db4d4fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28643&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrsticker962e84ecb8a951f708b8.png",
      price: "Miễn phí",
      name: "Tonton Friends",
      thumbImg: "https://zalo-api.zadn.vn/f/6/c/1/5/10398/preview/tonton_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/f/6/c/1/5/10398/icon_pre/tonton_thumb.png",
      id: "a375b1b78df264ac3de3",
      source: "Tonton House Co., Ltd.",
      totalImage: "20 sticker",
      value: [
          {
              id: "e0279194add1448f1dc0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23037&size=130"
          },
          {
              id: "456c37df0b9ae2c4bb8b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23038&size=130"
          },
          {
              id: "39a94a1a765f9f01c64e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23039&size=130"
          },
          {
              id: "2479a8c9948c7dd2249d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23040&size=130"
          },
          {
              id: "5f8bd23bee7e07205e6f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23041&size=130"
          },
          {
              id: "04f78a47b6025f5c0613",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23042&size=130"
          },
          {
              id: "30e1bf5183146a4a3305",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23043&size=130"
          },
          {
              id: "ff8277324b77a229fb66",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23044&size=130"
          },
          {
              id: "cc93452379669038c977",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23045&size=130"
          },
          {
              id: "11c59b75a7304e6e1721",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23046&size=130"
          },
          {
              id: "3707bcb780f269ac30e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23047&size=130"
          },
          {
              id: "a9682dd8119df8c3a18c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23048&size=130"
          },
          {
              id: "cf994a29766c9f32c67d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23049&size=130"
          },
          {
              id: "dcf35a4366068f58d617",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23050&size=130"
          },
          {
              id: "7b0ffcbfc0fa29a470eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23051&size=130"
          },
          {
              id: "8b580be837addef387bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23052&size=130"
          },
          {
              id: "567fd7cfeb8a02d45b9b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23053&size=130"
          },
          {
              id: "85ab071b3b5ed2008b4f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23054&size=130"
          },
          {
              id: "0ad98969b52c5c72053d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23055&size=130"
          },
          {
              id: "b04f2cff10baf9e4a0ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23056&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      price: "Miễn phí",
      name: "Hoàng Thượng Mèo",
      thumbImg: "https://zalo-api.zadn.vn/f/2/e/c/e/11020/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/f/2/e/c/e/11020/icon_pre/hoangthuong_thumb.png",
      id: "8a3e0aff36badfe486ab",
      source: "Bai Cha",
      totalImage: "20 sticker",
      value: [
          {
              id: "3fd18047bc02555c0c13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31795&size=130"
          },
          {
              id: "7fc1c757fb12124c4b03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31796&size=130"
          },
          {
              id: "1cc0a5569913704d2902",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31797&size=130"
          },
          {
              id: "1f13a58599c0709e29d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31798&size=130"
          },
          {
              id: "ca4071d64d93a4cdfd82",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31799&size=130"
          },
          {
              id: "7914cd82f1c7189941d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31800&size=130"
          },
          {
              id: "0e02bb9487d16e8f37c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31801&size=130"
          },
          {
              id: "4f53f9c5c5802cde7591",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31802&size=130"
          },
          {
              id: "18e6af7093357a6b2324",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31803&size=130"
          },
          {
              id: "2c219cb7a0f249ac10e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31804&size=130"
          },
          {
              id: "46e6f770cb35226b7b24",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31805&size=130"
          },
          {
              id: "a52317b52bf0c2ae9be1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31806&size=130"
          },
          {
              id: "f1f642607e25977bce34",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31807&size=130"
          },
          {
              id: "e69b2a0d1648ff16a659",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31808&size=130"
          },
          {
              id: "ab4466d25a97b3c9ea86",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31809&size=130"
          },
          {
              id: "556a9bfca7b94ee717a8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31810&size=130"
          },
          {
              id: "e5522ac41681ffdfa690",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31811&size=130"
          },
          {
              id: "1dd9d54fe90a0054591b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31812&size=130"
          },
          {
              id: "d91510832cc6c5989cd7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31813&size=130"
          },
          {
              id: "6e37a4a198e471ba28f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31814&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/4/qrsticker54a109613524dc7a8535.png",
      price: "Miễn phí",
      name: "Cà & Méo 3",
      thumbImg: "https://zalo-api.zadn.vn/d/9/b/e/c/10961/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/d/9/b/e/c/10961/icon_pre/cameo3_thumb.png",
      id: "c88c954ca90940571918",
      source: "MediaZ",
      totalImage: "20 sticker",
      value: [
          {
              id: "1a17c185fdc0149e4dd1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30807&size=130"
          },
          {
              id: "ce3b1aa926eccfb296fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30808&size=130"
          },
          {
              id: "250ff09dccd825867cc9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30809&size=130"
          },
          {
              id: "1f04c996f5d31c8d45c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30810&size=130"
          },
          {
              id: "31f3e661da24337a6a35",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30811&size=130"
          },
          {
              id: "913741a57de094becdf1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30812&size=130"
          },
          {
              id: "fb0f2a9d16d8ff86a6c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30813&size=130"
          },
          {
              id: "ab09799b45deac80f5cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30814&size=130"
          },
          {
              id: "dc650ff733b2daec83a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30815&size=130"
          },
          {
              id: "923d7eaf42eaabb4f2fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30816&size=130"
          },
          {
              id: "dda330310c74e52abc65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30817&size=130"
          },
          {
              id: "849b6a09564cbf12e65d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30818&size=130"
          },
          {
              id: "cde722751e30f76eae21",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30819&size=130"
          },
          {
              id: "2f00c792fbd712894bc6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30820&size=130"
          },
          {
              id: "d42f3dbd01f8e8a6b1e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30821&size=130"
          },
          {
              id: "f34219d02595cccb9584",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30822&size=130"
          },
          {
              id: "2624cdb6f1f318ad41e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30823&size=130"
          },
          {
              id: "327dd6efeaaa03f45abb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30824&size=130"
          },
          {
              id: "9ac77f554310aa4ef301",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30825&size=130"
          },
          {
              id: "b44b52d96e9c87c2de8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30826&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/5/qrstickerf60280c2bc8755d90c96.png",
      price: "Miễn phí",
      name: "Monta & Montei",
      thumbImg: "https://zalo-api.zadn.vn/3/0/7/7/7/11002/preview/220x220.png",
      iconUrl: "https://zalo-api.zadn.vn/3/0/7/7/7/11002/icon_pre/vintata_thumb.png",
      id: "1f0a69ca558fbcd1e59e",
      source: "VinTaTa",
      totalImage: "20 sticker",
      value: [
          {
              id: "f77980e9bcac55f20cbd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31483&size=130"
          },
          {
              id: "542324b318f6f1a8a8e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31484&size=130"
          },
          {
              id: "6eb11f212364ca3a9375",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31485&size=130"
          },
          {
              id: "2ed65c466003895dd012",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31486&size=130"
          },
          {
              id: "6a3119a125e4ccba95f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31487&size=130"
          },
          {
              id: "b5a039310574ec2ab565",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31488&size=130"
          },
          {
              id: "396cb4fd88b861e638a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31489&size=130"
          },
          {
              id: "63b3ed22d16738396176",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31490&size=130"
          },
          {
              id: "5033dfa2e3e70ab953f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31491&size=130"
          },
          {
              id: "2640aed192947bca2285",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31492&size=130"
          },
          {
              id: "dbdf524e6e0b8755de1a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31493&size=130"
          },
          {
              id: "224ca8dd94987dc62489",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31494&size=130"
          },
          {
              id: "294ca2dd9e9877c62e89",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31495&size=130"
          },
          {
              id: "a5ae213f1d7af424ad6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31496&size=130"
          },
          {
              id: "668ee31fdf5a36046f4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31497&size=130"
          },
          {
              id: "46d9c048fc0d15534c1c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31498&size=130"
          },
          {
              id: "9b731ce220a7c9f990b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31499&size=130"
          },
          {
              id: "370bb79a8bdf62813bce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31500&size=130"
          },
          {
              id: "7cdefd4fc10a2854711b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31501&size=130"
          },
          {
              id: "c711458079c5909bc9d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31502&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker89c00e013244db1a8255.png",
      price: "Miễn phí",
      name: "Quận 10 Yêu Môi Trường",
      thumbImg: "https://zalo-api.zadn.vn/e/7/f/d/e/11019/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/e/7/f/d/e/11019/icon_pre/yeumoitruong_thumb.png",
      id: "91b616772a32c36c9a23",
      source: "",
      totalImage: "12 sticker",
      value: [
          {
              id: "a4c70f513314da4a8305",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31783&size=130"
          },
          {
              id: "df8c7b1a475fae01f74e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31784&size=130"
          },
          {
              id: "5f8bfa1dc6582f067649",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31785&size=130"
          },
          {
              id: "89f82f6e132bfa75a33a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31786&size=130"
          },
          {
              id: "cb476cd15094b9cae085",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31787&size=130"
          },
          {
              id: "7403d495e8d0018e58c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31788&size=130"
          },
          {
              id: "65d2c444f801115f4810",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31789&size=130"
          },
          {
              id: "4cd2ee44d2013b5f6210",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31790&size=130"
          },
          {
              id: "a35000c63c83d5dd8c92",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31791&size=130"
          },
          {
              id: "16ccaa5a961f7f41260e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31792&size=130"
          },
          {
              id: "c6207bb647f3aeadf7e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31793&size=130"
          },
          {
              id: "dce562735e36b768ee27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=31794&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker80bfb77f8b3a62643b2b.png",
      price: "Miễn phí",
      name: "Kiki Cà Tưng",
      thumbImg: "https://zalo-api.zadn.vn/5/a/6/7/8/10939/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/5/a/6/7/8/10939/icon_pre/kikicatung_thumb.png",
      id: "26e511252d60c43e9d71",
      source: "Mojitok",
      totalImage: "16 sticker",
      value: [
          {
              id: "ca2c4cb170f499aac0e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30474&size=130"
          },
          {
              id: "a7c3205e1c1bf545ac0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30475&size=130"
          },
          {
              id: "984218df249acdc4948b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30476&size=130"
          },
          {
              id: "c77546e87aad93f3cabc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30477&size=130"
          },
          {
              id: "b9413bdc0799eec7b788",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30478&size=130"
          },
          {
              id: "7004f399cfdc26827fcd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30479&size=130"
          },
          {
              id: "79dce541d904305a6915",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30480&size=130"
          },
          {
              id: "3b1fa6829ac773992ad6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30481&size=130"
          },
          {
              id: "3c60a2fd9eb877e62ea9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30482&size=130"
          },
          {
              id: "2fe5b0788c3d65633c2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30483&size=130"
          },
          {
              id: "ea3272af4eeaa7b4fefb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30484&size=130"
          },
          {
              id: "a6fe3f630326ea78b337",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30485&size=130"
          },
          {
              id: "b4842e19125cfb02a24d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30486&size=130"
          },
          {
              id: "4f25d4b8e8fd01a358ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30487&size=130"
          },
          {
              id: "6f74fbe9c7ac2ef277bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30488&size=130"
          },
          {
              id: "3877adea91af78f121be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30489&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/7/qrsticker57a7bf6583206a7e3331.png",
      price: "Miễn phí",
      name: "Cún Cưng",
      thumbImg: "https://zalo-api.zadn.vn/3/5/6/f/b/10340/preview/cun_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/3/5/6/f/b/10340/icon_pre/cun_thumb.png",
      id: "6ba8836abf2f56710f3e",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "5634af8893cd7a9323dc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22133&size=130"
          },
          {
              id: "71c08b7cb7395e670728",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22134&size=130"
          },
          {
              id: "3d84c638fa7d13234a6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22135&size=130"
          },
          {
              id: "50f4a448980d7153281c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22136&size=130"
          },
          {
              id: "5f24aa9896dd7f8326cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22137&size=130"
          },
          {
              id: "a56353df6f9a86c4df8b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22138&size=130"
          },
          {
              id: "9ea2691e555bbc05e54a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22139&size=130"
          },
          {
              id: "cbb63b0a074fee11b75e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22140&size=130"
          },
          {
              id: "697498c8a48d4dd3149c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22141&size=130"
          },
          {
              id: "f85c0ae036a5dffb86b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22142&size=130"
          },
          {
              id: "ba20499c75d99c87c5c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22143&size=130"
          },
          {
              id: "7dca71764d33a46dfd22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22144&size=130"
          },
          {
              id: "92369f8aa3cf4a9113de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22145&size=130"
          },
          {
              id: "6a946428586db133e87c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22146&size=130"
          },
          {
              id: "f137fe8bc2ce2b9072df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22147&size=130"
          },
          {
              id: "1bdc13602f25c67b9f34",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22148&size=130"
          },
          {
              id: "aecea7729b3772692b26",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22149&size=130"
          },
          {
              id: "e196eb2ad76f3e31677e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22150&size=130"
          },
          {
              id: "f227f99bc5de2c8075cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22151&size=130"
          },
          {
              id: "8e1a8aa6b6e35fbd06f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22152&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker32e09720ab65423b1b74.png",
      price: "Miễn phí",
      name: "Kiki & Roni",
      thumbImg: "https://zalo-api.zadn.vn/e/8/c/b/a/10793/preview/roni_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/e/8/c/b/a/10793/icon_pre/roni_thumb.png",
      id: "c66763a75fe2b6bceff3",
      source: "Mojitok",
      totalImage: "16 sticker",
      value: [
          {
              id: "47a54d207165983bc174",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28550&size=130"
          },
          {
              id: "42a5492075659c3bc574",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28551&size=130"
          },
          {
              id: "a3bda7389b7d72232b6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28552&size=130"
          },
          {
              id: "6b4d6ec8528dbbd3e29c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28553&size=130"
          },
          {
              id: "25d423511f14f64aaf05",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28554&size=130"
          },
          {
              id: "082f0faa33efdab183fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28555&size=130"
          },
          {
              id: "03aa032f3f6ad6348f7b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28556&size=130"
          },
          {
              id: "c76fc6eafaaf13f14abe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28557&size=130"
          },
          {
              id: "7a03788644c3ad9df4d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28558&size=130"
          },
          {
              id: "846587e0bba552fb0bb4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28559&size=130"
          },
          {
              id: "2fb433310f74e62abf65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28560&size=130"
          },
          {
              id: "02191f9c23d9ca8793c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28561&size=130"
          },
          {
              id: "7d3263b75ff2b6acefe3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28562&size=130"
          },
          {
              id: "50374fb273f79aa9c3e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28563&size=130"
          },
          {
              id: "11e709623527dc798536",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28564&size=130"
          },
          {
              id: "a4eebd6b812e6870313f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28565&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/6/qrstickered8aea4ad60f3f51661e.png",
      price: "Miễn phí",
      name: "Gấu Tiểu Đỉnh 2.0",
      thumbImg: "https://zalo-api.zadn.vn/7/6/c/6/5/10891/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/7/6/c/6/5/10891/icon_pre/gautieudinh_thumb.png",
      id: "372430e40ca1e5ffbcb0",
      source: "",
      totalImage: "30 sticker",
      value: [
          {
              id: "ed7fa9e695a37cfd25b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29640&size=130"
          },
          {
              id: "beebfb72c7372e697726",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29641&size=130"
          },
          {
              id: "bf01f998c5dd2c8375cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29642&size=130"
          },
          {
              id: "0a814d18715d9803c14c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29643&size=130"
          },
          {
              id: "0dfb4d6271279879c136",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29644&size=130"
          },
          {
              id: "2de66c7f503ab964e02b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29645&size=130"
          },
          {
              id: "503712ae2eebc7b59efa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29646&size=130"
          },
          {
              id: "19f65a6f662a8f74d63b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29647&size=130"
          },
          {
              id: "a5f4f96dc5282c767539",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29648&size=130"
          },
          {
              id: "e646bbdf879a6ec4378b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29649&size=130"
          },
          {
              id: "81a1df38e37d0a23536c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29650&size=130"
          },
          {
              id: "1c2443bd7ff896a6cfe9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29651&size=130"
          },
          {
              id: "7f4927d01b95f2cbab84",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29652&size=130"
          },
          {
              id: "0f4e56d76a9283ccda83",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29653&size=130"
          },
          {
              id: "b5a7ef3ed37b3a25636a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29654&size=130"
          },
          {
              id: "75122e8b12cefb90a2df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29655&size=130"
          },
          {
              id: "3294660d5a48b316ea59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29656&size=130"
          },
          {
              id: "93aec637fa72132c4a63",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29657&size=130"
          },
          {
              id: "bb2aedb3d1f638a861e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29658&size=130"
          },
          {
              id: "bef2e96bd52e3c70653f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29659&size=130"
          },
          {
              id: "a43cf4a5c8e021be78f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29660&size=130"
          },
          {
              id: "dd538ccab08f59d1009e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29661&size=130"
          },
          {
              id: "975bc5c2f98710d94996",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29662&size=130"
          },
          {
              id: "45d7164e2a0bc3559a1a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29663&size=130"
          },
          {
              id: "afc6c35fff1a16444f0b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29664&size=130"
          },
          {
              id: "66470bde379bdec5878a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29665&size=130"
          },
          {
              id: "f14a9fd3a3964ac81387",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29666&size=130"
          },
          {
              id: "97a6f83fc47a2d24746b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29667&size=130"
          },
          {
              id: "78c6105f2c1ac5449c0b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29668&size=130"
          },
          {
              id: "82e3eb7ad73f3e61672e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29669&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker8404c7c4fb8112df4b90.png",
      price: "Miễn phí",
      name: "Bunnie Đón Trung Thu",
      thumbImg: "https://zalo-api.zadn.vn/e/e/d/4/4/10959/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/e/e/d/4/4/10959/icon_pre/bunnietrungthu_thumb.png",
      id: "cef58d35b170582e0161",
      source: "",
      totalImage: "8 sticker",
      value: [
          {
              id: "64f5d367ef22067c5f33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30779&size=130"
          },
          {
              id: "a8be182c2469cd379478",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30780&size=130"
          },
          {
              id: "9f772ee512a0fbfea2b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30781&size=130"
          },
          {
              id: "327580e7bca255fc0cb3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30782&size=130"
          },
          {
              id: "85fb36690a2ce372ba3d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30783&size=130"
          },
          {
              id: "6dbca12e9d6b74352d7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30784&size=130"
          },
          {
              id: "94bd592f656a8c34d57b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30785&size=130"
          },
          {
              id: "9e8b50196c5c8502dc4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=30786&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/5/qrstickerb4fb503b6c7e8520dc6f.png",
      price: "Miễn phí",
      name: "Lạc Lạc",
      thumbImg: "https://zalo-api.zadn.vn/e/a/b/1/b/10856/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/e/a/b/1/b/10856/icon_pre/laclac_thumb.png",
      id: "8b2a6fea53afbaf1e3be",
      source: "",
      totalImage: "18 sticker",
      value: [
          {
              id: "f46c46f47ab193efcaa0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29246&size=130"
          },
          {
              id: "f54946d17a9493caca85",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29247&size=130"
          },
          {
              id: "0edcc244fe01175f4e10",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29248&size=130"
          },
          {
              id: "ba6177f94bbca2e2fbad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29249&size=130"
          },
          {
              id: "540e9a96a6d34f8d16c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29250&size=130"
          },
          {
              id: "7adab542890760593916",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29251&size=130"
          },
          {
              id: "2861e0f9dcbc35e26cad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29252&size=130"
          },
          {
              id: "15dcdc44e001095f5010",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29253&size=130"
          },
          {
              id: "eba021381d7df423ad6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29254&size=130"
          },
          {
              id: "e312288a14cffd91a4de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29255&size=130"
          },
          {
              id: "2fb3eb2bd76e3e30677f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29256&size=130"
          },
          {
              id: "9c8a591265578c09d546",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29257&size=130"
          },
          {
              id: "48a68e3eb27b5b25026a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29258&size=130"
          },
          {
              id: "9c285bb067f58eabd7e4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29259&size=130"
          },
          {
              id: "59a3993ba57e4c20156f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29260&size=130"
          },
          {
              id: "dcb31d2b216ec830917f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29261&size=130"
          },
          {
              id: "1fe9dd71e134086a5125",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29262&size=130"
          },
          {
              id: "944157d96b9c82c2db8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29263&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrstickerfa3aeefad2bf3be162ae.png",
      price: "Miễn phí",
      name: "Chung Cư Cũ",
      thumbImg: "https://zalo-api.zadn.vn/b/f/c/5/f/10904/preview/240x240.png",
      iconUrl: "https://zalo-api.zadn.vn/b/f/c/5/f/10904/icon_pre/chungcucu_thumb.png",
      id: "023216f22ab7c3e99aa6",
      source: "",
      totalImage: "21 sticker",
      value: [
          {
              id: "de198487b8c2519c08d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29910&size=130"
          },
          {
              id: "20d77b49470cae52f71d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29911&size=130"
          },
          {
              id: "53d807463b03d25d8b12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29912&size=130"
          },
          {
              id: "acdff941c5042c5a7515",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29913&size=130"
          },
          {
              id: "83ccd552e91700495906",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29914&size=130"
          },
          {
              id: "e1e0b67e8a3b63653a2a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29915&size=130"
          },
          {
              id: "d3f1836fbf2a56740f3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29916&size=130"
          },
          {
              id: "2f857e1b425eab00f24f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29917&size=130"
          },
          {
              id: "576305fd39b8d0e689a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29918&size=130"
          },
          {
              id: "802ed3b0eff506ab5fe4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29919&size=130"
          },
          {
              id: "4aa926371a72f32caa63",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29920&size=130"
          },
          {
              id: "1d4970d74c92a5ccfc83",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29921&size=130"
          },
          {
              id: "f4499ad7a6924fcc1683",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29922&size=130"
          },
          {
              id: "e5f08a6eb62b5f75063a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29923&size=130"
          },
          {
              id: "ecf68468b82d5173083c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29924&size=130"
          },
          {
              id: "c9c7a0599c1c75422c0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29925&size=130"
          },
          {
              id: "f97a93e4afa146ff1fb0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29926&size=130"
          },
          {
              id: "acd9c747fb02125c4b13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29927&size=130"
          },
          {
              id: "f4d4904aac0f45511c1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29928&size=130"
          },
          {
              id: "124277dc4b99a2c7fb88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29929&size=130"
          },
          {
              id: "cdd9ab4797027e5c2713",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=29930&size=130"
          }
      ]
    },
    {
      textUploader: "Uploader",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerf2df581f645a8d04d44b.png",
      price: "Miễn phí",
      name: " Black Popo",
      thumbImg: "https://zalo-api.zadn.vn/4/e/7/7/c/10790/preview/blackpopo_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/4/e/7/7/c/10790/icon_pre/blackpopo_thumb.png",
      id: "b6da1c1a205fc901904e",
      source: "Thao",
      totalImage: "16 sticker",
      value: [
          {
              id: "ac6576e04aa5a3fbfab4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28502&size=130"
          },
          {
              id: "b5866e035246bb18e257",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28503&size=130"
          },
          {
              id: "3a43eec6d2833bdd6292",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28504&size=130"
          },
          {
              id: "868653036f468618df57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28505&size=130"
          },
          {
              id: "115ec7dbfb9e12c04b8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28506&size=130"
          },
          {
              id: "9dc74a4276079f59c616",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28507&size=130"
          },
          {
              id: "018fd10aed4f04115d5e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28508&size=130"
          },
          {
              id: "d75e06db3a9ed3c08a8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28509&size=130"
          },
          {
              id: "8e905c156050890ed041",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28510&size=130"
          },
          {
              id: "7698a51d995870062949",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28511&size=130"
          },
          {
              id: "3b03d786ebc3029d5bd2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28512&size=130"
          },
          {
              id: "3af8d77deb3802665b29",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28513&size=130"
          },
          {
              id: "69aa872fbb6a52340b7b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28514&size=130"
          },
          {
              id: "e9e106643a21d37f8a30",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28515&size=130"
          },
          {
              id: "a9a441217d64943acd75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28516&size=130"
          },
          {
              id: "342eddabe1ee08b051ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28517&size=130"
          }
      ]
    },
    {
      textUploader: "Uploader",
      qrCode: "https://qr-stickers.zaloapp.com/4/qrstickerd6ad7e6d4228ab76f239.png",
      price: "Miễn phí",
      name: "Popo 2",
      thumbImg: "https://zalo-api.zadn.vn/5/f/b/1/1/10788/preview/popo2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/f/b/1/1/10788/icon_pre/popo2_thumb.png",
      id: "a09408543411dd4f8400",
      source: "Thao",
      totalImage: "24 sticker",
      value: [
          {
              id: "7a351ab126f4cfaa96e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28396&size=130"
          },
          {
              id: "82cee34adf0f36516f1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28397&size=130"
          },
          {
              id: "b420d6a4eae103bf5af0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28398&size=130"
          },
          {
              id: "dd25bea182e46bba32f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28399&size=130"
          },
          {
              id: "9033ecb7d0f239ac60e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28400&size=130"
          },
          {
              id: "fa178793bbd652880bc7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28401&size=130"
          },
          {
              id: "379d4919755c9c02c54d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28402&size=130"
          },
          {
              id: "2719589d64d88d86d4c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28403&size=130"
          },
          {
              id: "c529bdad81e868b631f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28404&size=130"
          },
          {
              id: "44763df201b7e8e9b1a6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28405&size=130"
          },
          {
              id: "6deb176f2b2ac2749b3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28406&size=130"
          },
          {
              id: "8693fd17c152280c7143",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28407&size=130"
          },
          {
              id: "e91a9d9ea1db488511ca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28408&size=130"
          },
          {
              id: "f4d38157bd12544c0d03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28409&size=130"
          },
          {
              id: "ba6dcce9f0ac19f240bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28410&size=130"
          },
          {
              id: "8a27fda3c1e628b871f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28411&size=130"
          },
          {
              id: "7cef0c6b302ed970803f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28412&size=130"
          },
          {
              id: "884ff9cbc58e2cd0759f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28413&size=130"
          },
          {
              id: "335341d77d9294cccd83",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28414&size=130"
          },
          {
              id: "c08cb3088f4d66133f5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28415&size=130"
          },
          {
              id: "d1585ddd619888c6d189",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28416&size=130"
          },
          {
              id: "9ad017552b10c24e9b01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28417&size=130"
          },
          {
              id: "d4405ac566808fded691",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28418&size=130"
          },
          {
              id: "a908268d1ac8f396aad9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28419&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrsticker69acc76cfb2912774b38.png",
      price: "Miễn phí",
      name: "Usagyuuun Tăng Động",
      thumbImg: "https://zalo-api.zadn.vn/8/7/2/d/5/10786/preview/usa2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/8/7/2/d/5/10786/icon_pre/usa2_thumb.png",
      id: "647acabaf6ff1fa146ee",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "5c8e180a244fcd11945e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28360&size=130"
          },
          {
              id: "9940dcc4e08109df5090",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28361&size=130"
          },
          {
              id: "b8d2fe56c2132b4d7202",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28362&size=130"
          },
          {
              id: "31b9763d4a78a326fa69",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28363&size=130"
          },
          {
              id: "801fc09bfcde15804ccf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28364&size=130"
          },
          {
              id: "7a503bd40791eecfb780",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28365&size=130"
          },
          {
              id: "115753d36f9686c8df87",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28366&size=130"
          },
          {
              id: "735e30da0c9fe5c1bc8e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28367&size=130"
          },
          {
              id: "40be1c3a207fc921906e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28368&size=130"
          },
          {
              id: "d6718bf5b7b05eee07a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28369&size=130"
          },
          {
              id: "195447d07b9592cbcb84",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28370&size=130"
          },
          {
              id: "70c02f441301fa5fa310",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28371&size=130"
          },
          {
              id: "a196f912c5572c097546",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28372&size=130"
          },
          {
              id: "31b068345471bd2fe460",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28373&size=130"
          },
          {
              id: "50a80a2c3669df378678",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28374&size=130"
          },
          {
              id: "b2a5e921d5643c3a6575",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28375&size=130"
          },
          {
              id: "90d0c454f811114f4800",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28376&size=130"
          },
          {
              id: "131446907ad5938bcac4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28377&size=130"
          },
          {
              id: "b681e005dc40351e6c51",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28378&size=130"
          },
          {
              id: "112946ad7ae893b6caf9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28379&size=130"
          },
          {
              id: "8561d5e5e9a000fe59b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28380&size=130"
          },
          {
              id: "ad6efceac0af29f170be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28381&size=130"
          },
          {
              id: "596e0bea37afdef187be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28382&size=130"
          },
          {
              id: "c13c92b8aefd47a31eec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28383&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker275d549e68db8185d8ca.png",
      price: "Miễn phí",
      name: "PangPond",
      thumbImg: "https://zalo-api.zadn.vn/5/6/9/9/e/10751/preview/pangpond_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/6/9/9/e/10751/icon_pre/pangpond_thumb.png",
      id: "cb49b88a84cf6d9134de",
      source: "Vithita Animation Co., Ltd.",
      totalImage: "16 sticker",
      value: [
          {
              id: "b8831f052340ca1e9351",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27691&size=130"
          },
          {
              id: "07cea7489b0d72532b1c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27692&size=130"
          },
          {
              id: "a59604103855d10b8844",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27693&size=130"
          },
          {
              id: "670cc58af9cf109149de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27694&size=130"
          },
          {
              id: "4deeee68d22d3b73623c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27695&size=130"
          },
          {
              id: "557ce9fad5bf3ce165ae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27696&size=130"
          },
          {
              id: "a96914ef28aac1f498bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27697&size=130"
          },
          {
              id: "6ae9d46fe82a0174583b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27698&size=130"
          },
          {
              id: "d1766ef052b5bbebe2a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27699&size=130"
          },
          {
              id: "46dcfe5ac21f2b41720e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27700&size=130"
          },
          {
              id: "88a431220d67e439bd76",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27701&size=130"
          },
          {
              id: "676fdde9e1ac08f251bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27702&size=130"
          },
          {
              id: "ba1c019a3ddfd4818dce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27703&size=130"
          },
          {
              id: "af1b1b9d27d8ce8697c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27704&size=130"
          },
          {
              id: "7f9bca1df6581f064649",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27705&size=130"
          },
          {
              id: "c15f77d94b9ca2c2fb8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27706&size=130"
          }
      ]
    },
    {
      textUploader: "Uploader",
      qrCode: "https://qr-stickers.zaloapp.com/4/qrstickerc6be4a7e763b9f65c62a.png",
      price: "Miễn phí",
      name: "Popo",
      thumbImg: "https://zalo-api.zadn.vn/b/d/f/0/f/10752/preview/popo_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/b/d/f/0/f/10752/icon_pre/popo_thumb.png",
      id: "34f0b83084756d2b3464",
      source: "Thao",
      totalImage: "24 sticker",
      value: [
          {
              id: "e50652806ec5879bded4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27707&size=130"
          },
          {
              id: "7599c51ff95a1004494b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27708&size=130"
          },
          {
              id: "a43015b629f3c0ad99e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27709&size=130"
          },
          {
              id: "9f502dd61193f8cda182",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27710&size=130"
          },
          {
              id: "613fd2b9eefc07a25eed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27711&size=130"
          },
          {
              id: "d2071e8122c4cb9a92d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27712&size=130"
          },
          {
              id: "347bf9fdc5b82ce675a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27713&size=130"
          },
          {
              id: "1a9ed418e85d0103584c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27714&size=130"
          },
          {
              id: "e85427d21b97f2c9ab86",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27715&size=130"
          },
          {
              id: "f34f3bc9078ceed2b79d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27716&size=130"
          },
          {
              id: "cfb606303a75d32b8a64",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27717&size=130"
          },
          {
              id: "b1497bcf478aaed4f79b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27718&size=130"
          },
          {
              id: "b0407bc64783aeddf792",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27719&size=130"
          },
          {
              id: "75feb1788d3d64633d2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27720&size=130"
          },
          {
              id: "dcd519532516cc489507",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27721&size=130"
          },
          {
              id: "1786d100ed45041b5d54",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27722&size=130"
          },
          {
              id: "573590b3acf645a81ce7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27723&size=130"
          },
          {
              id: "6e23aea592e07bbe22f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27724&size=130"
          },
          {
              id: "823c43ba7fff96a1cfee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27725&size=130"
          },
          {
              id: "cd170f9133d4da8a83c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27726&size=130"
          },
          {
              id: "3edefd58c11d2843710c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27727&size=130"
          },
          {
              id: "e5bf3939057cec22b56d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27728&size=130"
          },
          {
              id: "3f33e2b5def037ae6ee1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27729&size=130"
          },
          {
              id: "7fb3a1359d70742e2d61",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27730&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker261a53d76f9286ccdf83.png",
      price: "Miễn phí",
      name: "Daily Text",
      thumbImg: "https://zalo-api.zadn.vn/e/d/f/9/5/10233/preview/text_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/e/d/f/9/5/10233/icon_pre/text_thumb.png",
      id: "099e7c534016a948f007",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "938d222a1e6ff731ae7e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19773&size=130"
          },
          {
              id: "6492d635ea70032e5a61",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19774&size=130"
          },
          {
              id: "34c08767bb22527c0b33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19775&size=130"
          },
          {
              id: "589e9439a87c4122186d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19776&size=130"
          },
          {
              id: "3ca4f103cd4624187d57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19777&size=130"
          },
          {
              id: "c2be0c19305cd902804d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19778&size=130"
          },
          {
              id: "2fd1e076dc33356d6c22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19779&size=130"
          },
          {
              id: "e8bb201c1c59f507ac48",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19780&size=130"
          },
          {
              id: "2759eefed2bb3be562aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19781&size=130"
          },
          {
              id: "67ffad58911d7843210c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19782&size=130"
          },
          {
              id: "0d86c621fa64133a4a75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19783&size=130"
          },
          {
              id: "ce760ad13694dfca8685",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19784&size=130"
          },
          {
              id: "587b9ddca19948c71188",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19785&size=130"
          },
          {
              id: "29f3ef54d3113a4f6300",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19786&size=130"
          },
          {
              id: "b54e72e94eaca7f2febd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19787&size=130"
          },
          {
              id: "521792b0aef547ab1ee4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19788&size=130"
          },
          {
              id: "6a8cab2b976e7e30277f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19789&size=130"
          },
          {
              id: "eae728401405fd5ba414",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19790&size=130"
          },
          {
              id: "8fba4c1d70589906c049",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19791&size=130"
          },
          {
              id: "b3d06f775332ba6ce323",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19792&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7c7016b32af6c3a89ae7.png",
      price: "Miễn phí",
      name: "Cocomong",
      thumbImg: "https://zalo-api.zadn.vn/9/9/2/f/0/10726/preview/cocomong_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/9/9/2/f/0/10726/icon_pre/cocomong_thumb.png",
      id: "bbf3d130ed75042b5d64",
      source: "Mojitok",
      totalImage: "16 sticker",
      value: [
          {
              id: "0413a3939fd676882fc7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27179&size=130"
          },
          {
              id: "227f82ffbeba57e40eab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27180&size=130"
          },
          {
              id: "e29b431b7f5e9600cf4f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27181&size=130"
          },
          {
              id: "d57f77ff4bbaa2e4fbab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27182&size=130"
          },
          {
              id: "77f1d471e834016a5825",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27183&size=130"
          },
          {
              id: "e0115c9160d4898ad0c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27184&size=130"
          },
          {
              id: "118aac0a904f7911205e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27185&size=130"
          },
          {
              id: "8cce324e0e0be755be1a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27186&size=130"
          },
          {
              id: "039bbc1b805e6900304f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27187&size=130"
          },
          {
              id: "c58a7d0a414fa811f15e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27188&size=130"
          },
          {
              id: "9a4623c61f83f6ddaf92",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27189&size=130"
          },
          {
              id: "6f9bd51be95e0000594f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27190&size=130"
          },
          {
              id: "2b4090c0ac8545db1c94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27191&size=130"
          },
          {
              id: "81ab352b096ee030b97f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27192&size=130"
          },
          {
              id: "4d6cf8ecc4a92df774b8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27193&size=130"
          },
          {
              id: "0f6ab9ea85af6cf135be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27194&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerdf5ba7989bdd72832bcc.png",
      price: "Miễn phí",
      name: "Piyomaru Và Các Bạn",
      thumbImg: "https://zalo-api.zadn.vn/f/7/5/c/e/10740/preview/piyomaru2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/f/7/5/c/e/10740/icon_pre/piyomaru2_thumb.png",
      id: "a2c6da05e6400f1e5651",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "08c6c147fd02145c4d13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27461&size=130"
          },
          {
              id: "75d3bf5283176a493306",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27462&size=130"
          },
          {
              id: "fe5135d00995e0cbb984",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27463&size=130"
          },
          {
              id: "e9442dc51180f8dea191",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27464&size=130"
          },
          {
              id: "6970acf190b479ea20a5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27465&size=130"
          },
          {
              id: "ef8d290c1549fc17a558",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27466&size=130"
          },
          {
              id: "806447e57ba092fecbb1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27467&size=130"
          },
          {
              id: "ac0f6c8e50cbb995e0da",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27468&size=130"
          },
          {
              id: "765bb7da8b9f62c13b8e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27469&size=130"
          },
          {
              id: "b83b7aba46ffafa1f6ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27470&size=130"
          },
          {
              id: "b37270f34cb6a5e8fca7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27471&size=130"
          },
          {
              id: "bac966485a0db353ea1c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27472&size=130"
          },
          {
              id: "318eec0fd04a3914605b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27473&size=130"
          },
          {
              id: "bdf663775f32b66cef23",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27474&size=130"
          },
          {
              id: "f33a2cbb10fef9a0a0ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27475&size=130"
          },
          {
              id: "9ba543247f61963fcf70",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27476&size=130"
          },
          {
              id: "7861a1e09da574fb2db4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27477&size=130"
          },
          {
              id: "6624bca580e069be30f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27478&size=130"
          },
          {
              id: "c68b1d0a214fc811915e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27479&size=130"
          },
          {
              id: "1a73cef2f2b71be942a6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27480&size=130"
          },
          {
              id: "f4bb213a1d7ff421ad6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27481&size=130"
          },
          {
              id: "a0e276634a26a378fa37",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27482&size=130"
          },
          {
              id: "2689f108cd4d24137d5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27483&size=130"
          },
          {
              id: "c1d411552d10c44e9d01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27484&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker039e975eab1b42451b0a.png",
      price: "Miễn phí",
      name: "Nekoemon",
      thumbImg: "https://zalo-api.zadn.vn/c/3/f/7/a/10776/preview/neko_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/3/f/7/a/10776/icon_pre/neko_thumb.png",
      id: "80b414742831c16f9820",
      source: "Nekoemon",
      totalImage: "20 sticker",
      value: [
          {
              id: "89a83d2c0169e837b178",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28216&size=130"
          },
          {
              id: "8bd33e570212eb4cb203",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28217&size=130"
          },
          {
              id: "cc617ae546a0affef6b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28218&size=130"
          },
          {
              id: "98b22f361373fa2da362",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28219&size=130"
          },
          {
              id: "fc694ced70a899f6c0b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28220&size=130"
          },
          {
              id: "025eb3da8f9f66c13f8e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28221&size=130"
          },
          {
              id: "2ce79e63a2264b781237",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28222&size=130"
          },
          {
              id: "24fb977fab3a42641b2b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28223&size=130"
          },
          {
              id: "90c45c406005895bd014",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28224&size=130"
          },
          {
              id: "fa2f37ab0beee2b0bbff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28225&size=130"
          },
          {
              id: "0e02c086fcc3159d4cd2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28226&size=130"
          },
          {
              id: "1004df80e3c50a9b53d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28227&size=130"
          },
          {
              id: "fd7135f509b0e0eeb9a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28228&size=130"
          },
          {
              id: "c3360ab236f7dfa986e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28229&size=130"
          },
          {
              id: "8ecb444f780a9154c81b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28230&size=130"
          },
          {
              id: "c22809ac35e9dcb785f8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28231&size=130"
          },
          {
              id: "37e3f367cf22267c7f33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28232&size=130"
          },
          {
              id: "9f645ae066a58ffbd6b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28233&size=130"
          },
          {
              id: "545892dcae9947c71e88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28234&size=130"
          },
          {
              id: "3efaf97ec53b2c65752a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28235&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker53e91e242261cb3f9270.png",
      price: "Miễn phí",
      name: "Luci",
      thumbImg: "https://zalo-api.zadn.vn/3/7/3/f/3/10177/preview/heoxanh_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/3/7/3/f/3/10177/icon_pre/heoxanh_thumb.png",
      id: "3c8c71414d04a45afd15",
      source: "Zalo Studio",
      totalImage: "25 sticker",
      value: [
          {
              id: "18f30b503715de4b8704",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18847&size=130"
          },
          {
              id: "e5a5c906f5431c1d4552",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18848&size=130"
          },
          {
              id: "e576c8d5f4901dce4481",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18849&size=130"
          },
          {
              id: "14673ac40681efdfb690",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18850&size=130"
          },
          {
              id: "82c7ad649121787f2130",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18851&size=130"
          },
          {
              id: "b9259186adc3449d1dd2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18852&size=130"
          },
          {
              id: "de8ef72dcb6822367b79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18853&size=130"
          },
          {
              id: "de03f4a0c8e521bb78f4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18854&size=130"
          },
          {
              id: "adbd861eba5b53050a4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18855&size=130"
          },
          {
              id: "94f3b0508c15654b3c04",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18856&size=130"
          },
          {
              id: "df11fab2c6f72fa976e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18857&size=130"
          },
          {
              id: "b4e19242ae0747591e16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18858&size=130"
          },
          {
              id: "53e9744a480fa151f81e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18859&size=130"
          },
          {
              id: "ba869a25a6604f3e1671",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18860&size=130"
          },
          {
              id: "79f9585a641f8d41d40e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18861&size=130"
          },
          {
              id: "f8d3da70e6350f6b5624",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18862&size=130"
          },
          {
              id: "e667c5c4f98110df4990",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18863&size=130"
          },
          {
              id: "90deac7d903879662029",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18864&size=130"
          },
          {
              id: "b0f68d55b110584e0101",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18865&size=130"
          },
          {
              id: "ff57c1f4fdb114ef4da0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18866&size=130"
          },
          {
              id: "760b49a875ed9cb3c5fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18867&size=130"
          },
          {
              id: "8959b1fa8dbf64e13dae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18868&size=130"
          },
          {
              id: "c0a8f90bc54e2c10755f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18869&size=130"
          },
          {
              id: "921da8be94fb7da524ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18870&size=130"
          },
          {
              id: "27701cd32096c9c89087",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18871&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker39d246117a54930aca45.png",
      price: "Miễn phí",
      name: "Pikalong",
      thumbImg: "https://zalo-api.zadn.vn/f/f/6/8/5/10739/preview/pikal_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/f/f/6/8/5/10739/icon_pre/pikal_thumb.png",
      id: "03ad7c6e402ba975f03a",
      source: "Thăng Fly Comics",
      totalImage: "15 sticker",
      value: [
          {
              id: "0f93b512895760093946",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27446&size=130"
          },
          {
              id: "27709cf1a0b449ea10a5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27447&size=130"
          },
          {
              id: "0173b5f289b760e939a6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27448&size=130"
          },
          {
              id: "4f99fa18c65d2f03764c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27449&size=130"
          },
          {
              id: "4d3ffbbec7fb2ea577ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27450&size=130"
          },
          {
              id: "827e35ff09bae0e4b9ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27451&size=130"
          },
          {
              id: "ee3c5ebd62f88ba6d2e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27452&size=130"
          },
          {
              id: "3fbf8e3eb27b5b25026a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27453&size=130"
          },
          {
              id: "31e38362bf2756790f36",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27454&size=130"
          },
          {
              id: "81b832390e7ce722be6d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27455&size=130"
          },
          {
              id: "f7b93b38077dee23b76c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27456&size=130"
          },
          {
              id: "d3421ec32286cbd89297",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27457&size=130"
          },
          {
              id: "0faac12bfd6e14304d7f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27458&size=130"
          },
          {
              id: "c4c20b433706de588717",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27459&size=130"
          },
          {
              id: "7a48b2c98e8c67d23e9d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=27460&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker2ea08363bf2656780f37.png",
      price: "Miễn phí",
      name: "Usagyuuun",
      thumbImg: "https://zalo-api.zadn.vn/2/4/a/2/9/10529/preview/Usagyuuun_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/4/a/2/9/10529/icon_pre/Usagyuuun_thumb.png",
      id: "71fadc39e07c0922506d",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "8d9ac22ffe6a17344e7b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24515&size=130"
          },
          {
              id: "9d51d5e4e9a100ff59b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24516&size=130"
          },
          {
              id: "34a27d174152a80cf143",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24517&size=130"
          },
          {
              id: "0f0345b679f390adc9e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24518&size=130"
          },
          {
              id: "3bb170044c41a51ffc50",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24519&size=130"
          },
          {
              id: "e9b6ad03914678182157",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24520&size=130"
          },
          {
              id: "22e167545b11b24feb00",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24521&size=130"
          },
          {
              id: "89f5cf40f3051a5b4314",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24522&size=130"
          },
          {
              id: "675f20ea1caff5f1acbe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24523&size=130"
          },
          {
              id: "5a3f1a8a26cfcf9196de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24524&size=130"
          },
          {
              id: "558114342871c12f9860",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24525&size=130"
          },
          {
              id: "cf5a8defb1aa58f401bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24526&size=130"
          },
          {
              id: "7cff3f4a030fea51b31e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24527&size=130"
          },
          {
              id: "6f7133c40f81e6dfbf90",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24528&size=130"
          },
          {
              id: "faf9a74c9b0972572b18",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24529&size=130"
          },
          {
              id: "b3b9ed0cd14938176158",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24530&size=130"
          },
          {
              id: "a4b4fb01c7442e1a7755",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24531&size=130"
          },
          {
              id: "9848c0fdfcb815e64ca9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24532&size=130"
          },
          {
              id: "8f1ad6afeaea03b45afb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24533&size=130"
          },
          {
              id: "10b94a0c76499f17c658",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24534&size=130"
          },
          {
              id: "fcefa75a9b1f72412b0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24535&size=130"
          },
          {
              id: "a570f1c5cd8024de7d91",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24536&size=130"
          },
          {
              id: "97bcc209fe4c17124e5d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24537&size=130"
          },
          {
              id: "9d8bcb3ef77b1e25476a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24538&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrstickerc0c07e2a426fab31f27e.png",
      price: "Miễn phí",
      name: "Khu Vườn Trên Mây 1",
      thumbImg: "https://zalo-api.zadn.vn/c/0/c/7/c/50/preview/kvtm1_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/0/c/7/c/50/icon_pre/kvtm1_thumb.png",
      id: "c65678bc44f9ada7f4e8",
      source: "",
      totalImage: "34 sticker",
      value: [
          {
              id: "345487babbff52a10bee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1087&size=130"
          },
          {
              id: "dbc0172e2b6bc2359b7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1088&size=130"
          },
          {
              id: "ecc3212d1d68f436ad79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1089&size=130"
          },
          {
              id: "ec2022ce1e8bf7d5ae9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1090&size=130"
          },
          {
              id: "36eaf904c5412c1f7550",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1091&size=130"
          },
          {
              id: "f2003aee06abeff5b6ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1092&size=130"
          },
          {
              id: "e5732c9d10d8f986a0c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1093&size=130"
          },
          {
              id: "ecf1261f1a5af304aa4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1094&size=130"
          },
          {
              id: "b6e67d08414da813f15c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1095&size=130"
          },
          {
              id: "d33017de2b9bc2c59b8a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1096&size=130"
          },
          {
              id: "b22277cc4b89a2d7fb98",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1097&size=130"
          },
          {
              id: "643aa2d49e9177cf2e80",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1098&size=130"
          },
          {
              id: "e4f2231c1f59f607af48",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1099&size=130"
          },
          {
              id: "ad486da651e3b8bde1f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1100&size=130"
          },
          {
              id: "181fd9f1e5b40cea55a5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1101&size=130"
          },
          {
              id: "6f5cadb291f778a921e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1102&size=130"
          },
          {
              id: "ea2829c61583fcdda592",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1103&size=130"
          },
          {
              id: "92994e7772329b6cc223",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1104&size=130"
          },
          {
              id: "975a4ab476f19fafc6e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1105&size=130"
          },
          {
              id: "de2500cb3c8ed5d08c9f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1106&size=130"
          },
          {
              id: "78a2a74c9b0972572b18",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1107&size=130"
          },
          {
              id: "071adff4e3b10aef53a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1108&size=130"
          },
          {
              id: "455b9cb5a0f049ae10e1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1109&size=130"
          },
          {
              id: "f80822e61ea3f7fdaeb2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1110&size=130"
          },
          {
              id: "1c97c779fb3c12624b2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1111&size=130"
          },
          {
              id: "94c3402d7c689536cc79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1112&size=130"
          },
          {
              id: "32ade743db0632586b17",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1113&size=130"
          },
          {
              id: "37cae124dd61343f6d70",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1114&size=130"
          },
          {
              id: "2220f5cec98b20d5799a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1115&size=130"
          },
          {
              id: "e5aa35440901e05fb910",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1116&size=130"
          },
          {
              id: "abc37a2d4668af36f679",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1117&size=130"
          },
          {
              id: "e8e83a060643ef1db652",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1118&size=130"
          },
          {
              id: "ce68d586e9c3009d59d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1175&size=130"
          },
          {
              id: "ec41f8afc4ea2db474fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1176&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker3a081dca218fc8d1919e.png",
      price: "Miễn phí",
      name: "Bu Lầy - Nông Trại",
      thumbImg: "https://zalo-api.zadn.vn/2/d/5/b/5/10411/preview/bu_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/d/5/b/5/10411/icon_pre/bu_thumb.png",
      id: "66c441067d43941dcd52",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "39af0b1f375ade04874b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23230&size=130"
          },
          {
              id: "700f43bf7ffa96a4cfeb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23231&size=130"
          },
          {
              id: "6bda276a1b2ff271ab3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23232&size=130"
          },
          {
              id: "a969e4d9d89c31c2688d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23233&size=130"
          },
          {
              id: "61d02f601325fa7ba334",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23234&size=130"
          },
          {
              id: "665929e915acfcf2a5bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23235&size=130"
          },
          {
              id: "e15fa9ef95aa7cf425bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23236&size=130"
          },
          {
              id: "5ba412142e51c70f9e40",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23237&size=130"
          },
          {
              id: "25346f8453c1ba9fe3d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23238&size=130"
          },
          {
              id: "7f7e34ce088be1d5b89a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23239&size=130"
          },
          {
              id: "367072c04e85a7dbfe94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23240&size=130"
          },
          {
              id: "9152d4e2e8a701f958b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23241&size=130"
          },
          {
              id: "a1f5e745db00325e6b11",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23242&size=130"
          },
          {
              id: "3e1279a245e7acb9f5f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23243&size=130"
          },
          {
              id: "10e950596c1c8542dc0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23244&size=130"
          },
          {
              id: "49d308633426dd788437",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23245&size=130"
          },
          {
              id: "19255b9567d08e8ed7c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23246&size=130"
          },
          {
              id: "ea5da9ed95a87cf625b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23247&size=130"
          },
          {
              id: "7b3227821bc7f299abd6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23248&size=130"
          },
          {
              id: "5a9807283b6dd2338b7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23249&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker02d84c1b705e9900c04f.png",
      price: "Miễn phí",
      name: "Wico Cat",
      thumbImg: "https://zalo-api.zadn.vn/7/9/e/0/e/10690/preview/wico_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/9/e/0/e/10690/icon_pre/wico_thumb.png",
      id: "d57b9bb8a7fd4ea317ec",
      source: "Sundog & Nouticat",
      totalImage: "27 sticker",
      value: [
          {
              id: "34a3d821e4640d3a5475",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26720&size=130"
          },
          {
              id: "2b68c6eafaaf13f14abe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26721&size=130"
          },
          {
              id: "97fe797c4539ac67f528",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26722&size=130"
          },
          {
              id: "7e2c91aeadeb44b51dfa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26723&size=130"
          },
          {
              id: "a7a44f2673639a3dc372",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26724&size=130"
          },
          {
              id: "60df895db5185c460509",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26725&size=130"
          },
          {
              id: "150fff8dc3c82a9673d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26726&size=130"
          },
          {
              id: "e5480eca328fdbd1829e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26727&size=130"
          },
          {
              id: "0b1aef98d3dd3a8363cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26728&size=130"
          },
          {
              id: "97f8727a4e3fa761fe2e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26729&size=130"
          },
          {
              id: "2368c5eaf9af10f149be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26730&size=130"
          },
          {
              id: "872f60ad5ce8b5b6ecf9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26731&size=130"
          },
          {
              id: "c02720a51ce0f5beacf1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26732&size=130"
          },
          {
              id: "fe0b1f8923ccca9293dd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26733&size=130"
          },
          {
              id: "dec73c450000e95eb011",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26734&size=130"
          },
          {
              id: "face194c2509cc579518",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26735&size=130"
          },
          {
              id: "1a9be619da5c33026a4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26736&size=130"
          },
          {
              id: "bdfc407e7c3b9565cc2a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26737&size=130"
          },
          {
              id: "8e8e700c4c49a517fc58",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26738&size=130"
          },
          {
              id: "68ed976fab2a42741b3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26739&size=130"
          },
          {
              id: "78b68034bc71552f0c60",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26740&size=130"
          },
          {
              id: "cc9035120957e009b946",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26741&size=130"
          },
          {
              id: "9daf672d5b68b236eb79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26742&size=130"
          },
          {
              id: "4a59b1db8d9e64c03d8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26743&size=130"
          },
          {
              id: "82dd765f4a1aa344fa0b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26744&size=130"
          },
          {
              id: "bed94b5b771e9e40c70f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26745&size=130"
          },
          {
              id: "b33545b779f290acc9e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26746&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrsticker7d3e83fdbfb856e60fa9.png",
      price: "Miễn phí",
      name: "Ogu Mỏ Vịt",
      thumbImg: "https://zalo-api.zadn.vn/5/b/7/0/8/10610/preview/movit_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/b/7/0/8/10610/icon_pre/movit_thumb.png",
      id: "c3093dca018fe8d1b19e",
      source: "Mojitok",
      totalImage: "20 sticker",
      value: [
          {
              id: "a32843a67fe396bdcff2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25708&size=130"
          },
          {
              id: "448ea5009945701b2954",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25709&size=130"
          },
          {
              id: "a25c40d27c9795c9cc86",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25710&size=130"
          },
          {
              id: "9bfe78704435ad6bf424",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25711&size=130"
          },
          {
              id: "3068cce6f0a319fd40b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25712&size=130"
          },
          {
              id: "9b3466ba5affb3a1eaee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25713&size=130"
          },
          {
              id: "aff4517a6d3f8461dd2e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25714&size=130"
          },
          {
              id: "2c0ed380efc5069b5fd4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25715&size=130"
          },
          {
              id: "1cd5e45bd81e3140680f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25716&size=130"
          },
          {
              id: "9d4d64c35886b1d8e897",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25717&size=130"
          },
          {
              id: "d4242eaa12effbb1a2fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25718&size=130"
          },
          {
              id: "f5dc0e523217db498206",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25719&size=130"
          },
          {
              id: "f0c0044e380bd155881a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25720&size=130"
          },
          {
              id: "b29947177b52920ccb43",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25721&size=130"
          },
          {
              id: "f00d06833ac6d3988ad7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25722&size=130"
          },
          {
              id: "ef09188724c2cd9c94d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25723&size=130"
          },
          {
              id: "0726f7a8cbed22b37bfc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25724&size=130"
          },
          {
              id: "490eb88084c56d9b34d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25725&size=130"
          },
          {
              id: "9c626eec52a9bbf7e2b8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25726&size=130"
          },
          {
              id: "c201318f0dcae494bddb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25727&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7edb77164b53a20dfb42.png",
      price: "Miễn phí",
      name: "Chú Chó Toto",
      thumbImg: "https://zalo-api.zadn.vn/7/d/3/7/9/10117/preview/toto_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/d/3/7/9/10117/icon_pre/toto_thumb.png",
      id: "ea55e398dfdd36836fcc",
      source: "Hanasake",
      totalImage: "20 sticker",
      value: [
          {
              id: "0396de39e27c0b22526d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17745&size=130"
          },
          {
              id: "fc1e22b11ef4f7aaaee5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17746&size=130"
          },
          {
              id: "ffec20431c06f558ac17",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17747&size=130"
          },
          {
              id: "c05518fa24bfcde194ae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17748&size=130"
          },
          {
              id: "6988b0278c62653c3c73",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17749&size=130"
          },
          {
              id: "8d4157ee6bab82f5dbba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17750&size=130"
          },
          {
              id: "efd3347c0839e167b828",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17751&size=130"
          },
          {
              id: "b04d64e258a7b1f9e8b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17752&size=130"
          },
          {
              id: "e3d636790a3ce362ba2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17753&size=130"
          },
          {
              id: "ad477be847adaef3f7bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17754&size=130"
          },
          {
              id: "c96a1ec52280cbde9291",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17755&size=130"
          },
          {
              id: "6324b38b8fce66903fdf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17756&size=130"
          },
          {
              id: "0b7edad1e6940fca5685",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17757&size=130"
          },
          {
              id: "1cc2ce6df2281b764239",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17758&size=130"
          },
          {
              id: "2edefd71c134286a7125",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17759&size=130"
          },
          {
              id: "a47948d674939dcdc482",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17760&size=130"
          },
          {
              id: "5438b99785d26c8c35c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17761&size=130"
          },
          {
              id: "0ef9e056dc13354d6c02",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17762&size=130"
          },
          {
              id: "d0ea3f450300ea5eb311",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17763&size=130"
          },
          {
              id: "1bc5f36acf2f26717f3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17764&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker2b9a62595e1cb742ee0d.png",
      price: "Miễn phí",
      name: "Bà Già Kêu Ca ",
      thumbImg: "https://zalo-api.zadn.vn/a/8/f/b/b/10693/preview/440x440.png",
      iconUrl: "https://zalo-api.zadn.vn/a/8/f/b/b/10693/icon_pre/bagiakeuca_thumb.png",
      id: "ddcc940fa84a4114185b",
      source: "Bà Già Kêu Ca",
      totalImage: "20 sticker",
      value: [
          {
              id: "ed21cea3f2e61bb842f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26799&size=130"
          },
          {
              id: "6a3f56bd6af883a6dae9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26800&size=130"
          },
          {
              id: "7b9d461f7a5a9304ca4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26801&size=130"
          },
          {
              id: "2dce134c2f09c6579f18",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26802&size=130"
          },
          {
              id: "193126b31af6f3a8aae7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26803&size=130"
          },
          {
              id: "a20f9a8da6c84f9616d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26804&size=130"
          },
          {
              id: "689551176d52840cdd43",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26805&size=130"
          },
          {
              id: "180b22891eccf792aedd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26806&size=130"
          },
          {
              id: "bfd18453b81651480807",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26807&size=130"
          },
          {
              id: "ff6bcbe9f7ac1ef247bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26808&size=130"
          },
          {
              id: "6c5559d765928cccd583",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26809&size=130"
          },
          {
              id: "1f2529a715e2fcbca5f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26810&size=130"
          },
          {
              id: "3e6609e435a1dcff85b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26811&size=130"
          },
          {
              id: "fc72ccf0f0b519eb40a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26812&size=130"
          },
          {
              id: "29a418262463cd3d9472",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26813&size=130"
          },
          {
              id: "4e977c154050a90ef041",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26814&size=130"
          },
          {
              id: "92b7a1359d70742e2d61",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26815&size=130"
          },
          {
              id: "92f6de74e2310b6f5220",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26816&size=130"
          },
          {
              id: "51671ce520a0c9fe90b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26817&size=130"
          },
          {
              id: "5daf132d2f68c6369f79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26818&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc80737ca0b8fe2d1bb9e.png",
      price: "Miễn phí",
      name: "Hoa Thiên Cốt",
      thumbImg: "https://zalo-api.zadn.vn/9/9/5/c/a/10099/preview/HTC_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/9/9/5/c/a/10099/icon_pre/HTC_thumb.png",
      id: "7d558298bedd57830ecc",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "007287dcbb9952c70b88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17419&size=130"
          },
          {
              id: "026e82c0be8557db0e94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17420&size=130"
          },
          {
              id: "0bf88a56b6135f4d0602",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17421&size=130"
          },
          {
              id: "5906dba8e7ed0eb357fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17422&size=130"
          },
          {
              id: "4044c3eaffaf16f14fbe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17423&size=130"
          },
          {
              id: "2d23b18d8dc864963dd9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17424&size=130"
          },
          {
              id: "a1003cae00ebe9b5b0fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17425&size=130"
          },
          {
              id: "814c1fe223a7caf993b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17426&size=130"
          },
          {
              id: "8faa10042c41c51f9c50",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17427&size=130"
          },
          {
              id: "26fabe5482116b4f3200",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17428&size=130"
          },
          {
              id: "5577ccd9f09c19c2408d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17429&size=130"
          },
          {
              id: "dd1f47b17bf492aacbe5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17430&size=130"
          },
          {
              id: "10398b97b7d25e8c07c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17431&size=130"
          },
          {
              id: "3664a2ca9e8f77d12e9e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17432&size=130"
          },
          {
              id: "8482112c2d69c4379d78",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17433&size=130"
          },
          {
              id: "6306f5a8c9ed20b379fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17434&size=130"
          },
          {
              id: "346aa3c49f8176df2f90",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17435&size=130"
          },
          {
              id: "7789e727db62323c6b73",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17436&size=130"
          },
          {
              id: "69a0f80ec44b2d15745a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17437&size=130"
          },
          {
              id: "0d6e9fc0a3854adb1394",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17438&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker467c21b11df4f4aaade5.png",
      price: "Miễn phí",
      name: "Nuddie Chicken",
      thumbImg: "https://zalo-api.zadn.vn/1/5/3/1/b/10219/preview/chicken_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/1/5/3/1/b/10219/icon_pre/chicken_thumb.png",
      id: "f95e9e93a2d64b8812c7",
      source: "",
      totalImage: "21 sticker",
      value: [
          {
              id: "7931b0978cd2658c3cc3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19525&size=130"
          },
          {
              id: "c7fe0d58311dd843810c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19526&size=130"
          },
          {
              id: "72c8b96e852b6c75353a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19527&size=130"
          },
          {
              id: "421e86b8bafd53a30aec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19528&size=130"
          },
          {
              id: "d4be11182d5dc4039d4c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19529&size=130"
          },
          {
              id: "6e82a82494617d3f2470",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19530&size=130"
          },
          {
              id: "35b6f210ce55270b7e44",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19531&size=130"
          },
          {
              id: "cb5f0bf937bcdee287ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19532&size=130"
          },
          {
              id: "d58514232866c1389877",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19533&size=130"
          },
          {
              id: "7d1bbfbd83f86aa633e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19534&size=130"
          },
          {
              id: "417082d6be9357cd0e82",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19535&size=130"
          },
          {
              id: "f64c2aea16affff1a6be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19536&size=130"
          },
          {
              id: "2d6ff0c9cc8c25d27c9d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19537&size=130"
          },
          {
              id: "aced724b4e0ea750fe1f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19538&size=130"
          },
          {
              id: "1dbbc21dfe5817064e49",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19539&size=130"
          },
          {
              id: "d18e0928356ddc33857c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19540&size=130"
          },
          {
              id: "1f3ac69cfad913874ac8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19541&size=130"
          },
          {
              id: "f6652cc31086f9d8a097",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19542&size=130"
          },
          {
              id: "04badf1ce3590a075348",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19543&size=130"
          },
          {
              id: "edb539130556ec08b547",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19544&size=130"
          },
          {
              id: "6123b48588c0619e38d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19545&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker3223bce180a469fa30b5.png",
      price: "Miễn phí",
      name: "Quỳnh Aka ",
      thumbImg: "https://zalo-api.zadn.vn/6/1/7/9/7/10242/preview/quynhaka_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/6/1/7/9/7/10242/icon_pre/quynhaka_thumb.png",
      id: "934a1d8821cdc89391dc",
      source: "Quynh Aka",
      totalImage: "28 sticker",
      value: [
          {
              id: "e5a235060943e01db952",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20060&size=130"
          },
          {
              id: "4ee19f45a3004a5e1311",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20061&size=130"
          },
          {
              id: "c0ed12492e0cc7529e1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20062&size=130"
          },
          {
              id: "eb3c389804dded83b4cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20063&size=130"
          },
          {
              id: "e1540df031b5d8eb81a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20064&size=130"
          },
          {
              id: "bf4052e46ea187ffdeb0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20065&size=130"
          },
          {
              id: "037feddbd19e38c0618f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20066&size=130"
          },
          {
              id: "d38e3c2a006fe931b07e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20067&size=130"
          },
          {
              id: "ea1102b53ef0d7ae8ee1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20068&size=130"
          },
          {
              id: "0606efa2d3e73ab963f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20069&size=130"
          },
          {
              id: "caaf200b1c4ef510ac5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20070&size=130"
          },
          {
              id: "64598ffdb3b85ae603a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20071&size=130"
          },
          {
              id: "14a5f001cc44251a7c55",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20072&size=130"
          },
          {
              id: "b5e9504d6c088556dc19",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20073&size=130"
          },
          {
              id: "e4c102653e20d77e8e31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20074&size=130"
          },
          {
              id: "4f63a8c794827ddc2493",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20075&size=130"
          },
          {
              id: "b71f57bb6bfe82a0dbef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20076&size=130"
          },
          {
              id: "868167255b60b23eeb71",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20077&size=130"
          },
          {
              id: "d7d635720937e069b926",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20078&size=130"
          },
          {
              id: "e8140bb037f5deab87e4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20079&size=130"
          },
          {
              id: "9fc363675f22b67cef33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20080&size=130"
          },
          {
              id: "d44529e115a4fcfaa5b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20081&size=130"
          },
          {
              id: "796687c2bb8752d90b96",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20082&size=130"
          },
          {
              id: "eb33149728d2c18c98c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20083&size=130"
          },
          {
              id: "7e3b869fbada53840acb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20084&size=130"
          },
          {
              id: "1a29e38ddfc836966fd9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20085&size=130"
          },
          {
              id: "90db6a7f563abf64e62b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20086&size=130"
          },
          {
              id: "6ec9956da92840761939",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20087&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker918b2849140cfd52a41d.png",
      price: "Miễn phí",
      name: "Quỳnh Aka 2",
      thumbImg: "https://zalo-api.zadn.vn/3/7/f/2/f/10293/preview/aka_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/3/7/f/2/f/10293/icon_pre/aka_thumb.png",
      id: "ca7873ba4fffa6a1ffee",
      source: "Quynh Aka",
      totalImage: "16 sticker",
      value: [
          {
              id: "eda4761d4a58a306fa49",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21271&size=130"
          },
          {
              id: "a8223c9b00dee980b0cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21272&size=130"
          },
          {
              id: "b06325da199ff0c1a98e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21273&size=130"
          },
          {
              id: "8d991b202765ce3b9774",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21274&size=130"
          },
          {
              id: "6c89fb30c7752e2b7764",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21275&size=130"
          },
          {
              id: "8b8e1b372772ce2c9763",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21276&size=130"
          },
          {
              id: "8cb71d0e214bc815915a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21277&size=130"
          },
          {
              id: "4db6df0fe34a0a14535b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21278&size=130"
          },
          {
              id: "7ed3ed6ad12f3871613e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21279&size=130"
          },
          {
              id: "261a8aa3b6e65fb806f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21280&size=130"
          },
          {
              id: "f44059f965bc8ce2d5ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21281&size=130"
          },
          {
              id: "95bf3b060743ee1db752",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21282&size=130"
          },
          {
              id: "1a84b53d897860263969",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21283&size=130"
          },
          {
              id: "25e58d5cb11958470108",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21284&size=130"
          },
          {
              id: "104cb9f585b06cee35a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21285&size=130"
          },
          {
              id: "f2ee585764128d4cd403",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21286&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerdb0418c62483cddd9492.png",
      price: "Miễn phí",
      name: "Ninja Bear",
      thumbImg: "https://zalo-api.zadn.vn/9/a/c/f/1/10319/preview/ninja_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/9/a/c/f/1/10319/icon_pre/ninja_thumb.png",
      id: "3753f491c8d4218a78c5",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "950af7b4cbf122af7be0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21742&size=130"
          },
          {
              id: "792e1a9026d5cf8b96c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21743&size=130"
          },
          {
              id: "fd1481aabdef54b10dfe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21744&size=130"
          },
          {
              id: "5f6f22d11e94f7caae85",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21745&size=130"
          },
          {
              id: "38e1465f7a1a9344ca0b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21746&size=130"
          },
          {
              id: "50342f8a13cffa91a3de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21747&size=130"
          },
          {
              id: "cd7ab5c4898160df3990",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21748&size=130"
          },
          {
              id: "12a06b1e575bbe05e74a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21749&size=130"
          },
          {
              id: "499f33210f64e63abf75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21750&size=130"
          },
          {
              id: "a688dd36e173082d5162",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21751&size=130"
          },
          {
              id: "ef349b8aa7cf4e9117de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21752&size=130"
          },
          {
              id: "9ef3eb4dd7083e566719",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21753&size=130"
          },
          {
              id: "5b8a2d341171f82fa160",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21754&size=130"
          },
          {
              id: "530724b918fcf1a2a8ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21755&size=130"
          },
          {
              id: "0f157fab43eeaab0f3ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21756&size=130"
          },
          {
              id: "98cae974d5313c6f6520",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21757&size=130"
          },
          {
              id: "3231408f7cca9594ccdb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21758&size=130"
          },
          {
              id: "aa4ad9f4e5b10cef55a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21759&size=130"
          },
          {
              id: "0ad1866eba2b53750a3a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21760&size=130"
          },
          {
              id: "ce0a43b57ff096aecfe1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21761&size=130"
          },
          {
              id: "0c0e82b1bef457aa0ee5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21762&size=130"
          },
          {
              id: "d4c25b7d67388e66d729",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21763&size=130"
          },
          {
              id: "ecb0640f584ab114e85b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21764&size=130"
          },
          {
              id: "f05f79e045a5acfbf5b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21765&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker5a6559a765e28cbcd5f3.png",
      price: "Miễn phí",
      name: "Chi Chi ",
      thumbImg: "https://zalo-api.zadn.vn/8/5/8/6/b/10383/preview/chichi_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/8/5/8/6/b/10383/icon_pre/chichi_thumb.png",
      id: "c89bcb59f71c1e42470d",
      source: "Artbox Friends",
      totalImage: "20 sticker",
      value: [
          {
              id: "066fb3dc8f9966c73f88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22841&size=130"
          },
          {
              id: "8398352b096ee030b97f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22842&size=130"
          },
          {
              id: "1289a53a997f7021296e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22843&size=130"
          },
          {
              id: "612fd19cedd904875dc8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22844&size=130"
          },
          {
              id: "f8f1494275079c59c516",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22845&size=130"
          },
          {
              id: "4e3efc8dc0c8299670d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22846&size=130"
          },
          {
              id: "28929b21a7644e3a1775",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22847&size=130"
          },
          {
              id: "41698ddab19f58c1018e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22848&size=130"
          },
          {
              id: "ca8e073d3b78d2268b69",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22849&size=130"
          },
          {
              id: "51929f21a3644a3a1375",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22850&size=130"
          },
          {
              id: "f735388604c3ed9db4d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22851&size=130"
          },
          {
              id: "b9ed715e4d1ba445fd0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22852&size=130"
          },
          {
              id: "93245a9766d28f8cd6c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22853&size=130"
          },
          {
              id: "a2fd684e540bbd55e41a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22854&size=130"
          },
          {
              id: "3dbff60cca4923177a58",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22855&size=130"
          },
          {
              id: "9cdc586f642a8d74d43b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22856&size=130"
          },
          {
              id: "f28737340b71e22fbb60",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22857&size=130"
          },
          {
              id: "2de6eb55d7103e4e6701",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22858&size=130"
          },
          {
              id: "a038678b5bceb290ebdf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22859&size=130"
          },
          {
              id: "b4d374604825a17bf834",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22860&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerec48f58ac9cf209179de.png",
      price: "Miễn phí",
      name: "Hải Cẩu Aza",
      thumbImg: "https://zalo-api.zadn.vn/7/5/a/6/e/10389/preview/aza_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/5/a/6/e/10389/icon_pre/aza_thumb.png",
      id: "bbd6a2149e51770f2e40",
      source: "Caerux Co.,Ltd.",
      totalImage: "20 sticker",
      value: [
          {
              id: "a468badb869e6fc0368f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22930&size=130"
          },
          {
              id: "52504de371a698f8c1b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22931&size=130"
          },
          {
              id: "f92de19edddb34856dca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22932&size=130"
          },
          {
              id: "ea1af3a9cfec26b27ffd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22933&size=130"
          },
          {
              id: "82a79814a4514d0f1440",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22934&size=130"
          },
          {
              id: "f20ae9b9d5fc3ca265ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22935&size=130"
          },
          {
              id: "ec48f8fbc4be2de074af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22936&size=130"
          },
          {
              id: "ede6f855c4102d4e7401",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22937&size=130"
          },
          {
              id: "49265f9563d08a8ed3c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22938&size=130"
          },
          {
              id: "515b46e87aad93f3cabc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22939&size=130"
          },
          {
              id: "cb83db30e7750e2b5764",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22940&size=130"
          },
          {
              id: "c793d620ea65033b5a74",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22941&size=130"
          },
          {
              id: "e7f8f54bc90e2050791f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22942&size=130"
          },
          {
              id: "11d9026a3e2fd7718e3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22943&size=130"
          },
          {
              id: "e15acde9f1ac18f241bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22944&size=130"
          },
          {
              id: "bc909123ad6644381d77",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22945&size=130"
          },
          {
              id: "f567dbd4e7910ecf5780",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22946&size=130"
          },
          {
              id: "7c4753f46fb186efdfa0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22947&size=130"
          },
          {
              id: "898ba1389d7d74232d6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22948&size=130"
          },
          {
              id: "f048d9fbe5be0ce055af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22949&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker826894aaa8ef41b118fe.png",
      price: "Miễn phí",
      name: "Gấu Kumapon",
      thumbImg: "https://zalo-api.zadn.vn/d/c/f/5/3/10394/preview/kuma_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/c/f/5/3/10394/icon_pre/kuma_thumb.png",
      id: "48ae5e6c62298b77d238",
      source: "Caerux Co.,Ltd.",
      totalImage: "20 sticker",
      value: [
          {
              id: "45de066d3a28d3768a39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22991&size=130"
          },
          {
              id: "428c1e3f227acb24926b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22992&size=130"
          },
          {
              id: "403a1d8921ccc89291dd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22993&size=130"
          },
          {
              id: "15b44b0777429e1cc753",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22994&size=130"
          },
          {
              id: "729c2d2f116af834a17b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22995&size=130"
          },
          {
              id: "788620351c70f52eac61",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22996&size=130"
          },
          {
              id: "011858ab64ee8db0d4ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22997&size=130"
          },
          {
              id: "d1528be1b7a45efa07b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22998&size=130"
          },
          {
              id: "2e7a75c9498ca0d2f99d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22999&size=130"
          },
          {
              id: "de648ad7b6925fcc0683",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23000&size=130"
          },
          {
              id: "b9b8ec0bd04e3910605f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23001&size=130"
          },
          {
              id: "618c373f0b7ae224bb6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23002&size=130"
          },
          {
              id: "9180c633fa7613284a67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23003&size=130"
          },
          {
              id: "94c3c470f835116b4824",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23004&size=130"
          },
          {
              id: "5b2e0a9d36d8df8686c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23005&size=130"
          },
          {
              id: "d38a8139bd7c54220d6d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23006&size=130"
          },
          {
              id: "38e96b5a571fbe41e70e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23007&size=130"
          },
          {
              id: "f04e9cfda0b849e610a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23008&size=130"
          },
          {
              id: "28ec455f791a9044c90b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23009&size=130"
          },
          {
              id: "3f0451b76df284acdde3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23010&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker0d85dd46e103085d5112.png",
      price: "Miễn phí",
      name: "Giận Rồi Nha",
      thumbImg: "https://zalo-api.zadn.vn/5/d/9/e/4/10588/preview/gian_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/d/9/e/4/10588/icon_pre/gian_thumb.png",
      id: "c6c516062a43c31d9a52",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "44fe3e760233eb6db222",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25334&size=130"
          },
          {
              id: "5987220f1e4af714ae5b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25335&size=130"
          },
          {
              id: "45a3312b0d6ee430bd7f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25336&size=130"
          },
          {
              id: "cd13b89b84de6d8034cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25337&size=130"
          },
          {
              id: "0ed3785b441ead40f40f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25338&size=130"
          },
          {
              id: "aa7fddf7e1b208ec51a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25339&size=130"
          },
          {
              id: "0f817f09434caa12f35d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25340&size=130"
          },
          {
              id: "b6d8c750fb15124b4b04",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25341&size=130"
          },
          {
              id: "535321db1d9ef4c0ad8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25342&size=130"
          },
          {
              id: "f03483bcbff956a70fe8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25343&size=130"
          },
          {
              id: "fa2976a04ae5a3bbfaf4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25344&size=130"
          },
          {
              id: "d7945a1d66588f06d649",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25345&size=130"
          },
          {
              id: "1a5394daa89f41c1188e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25346&size=130"
          },
          {
              id: "f3537cda409fa9c1f08e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25347&size=130"
          },
          {
              id: "7081f808c44d2d13745c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25348&size=130"
          },
          {
              id: "d6465fcf638a8ad4d39b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25349&size=130"
          },
          {
              id: "8984030d3f48d6168f59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25350&size=130"
          },
          {
              id: "b57c3ef502b0ebeeb2a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25351&size=130"
          },
          {
              id: "09348dbdb1f858a601e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25352&size=130"
          },
          {
              id: "08918d18b15d5803014c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25353&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker1c3023f31fb6f6e8afa7.png",
      price: "Miễn phí",
      name: " Go East Friends",
      thumbImg: "https://zalo-api.zadn.vn/3/f/b/2/3/10675/preview/goeast_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/3/f/b/2/3/10675/icon_pre/goeastf_thumb.png",
      id: "62c65d056140881ed151",
      source: "Coscoi",
      totalImage: "20 sticker",
      value: [
          {
              id: "f396221b1e5ef700ae4f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26461&size=130"
          },
          {
              id: "cd821f0f234aca14935b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26462&size=130"
          },
          {
              id: "534b80c6bc8355dd0c92",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26463&size=130"
          },
          {
              id: "0754ebd9d79c3ec2678d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26464&size=130"
          },
          {
              id: "7a1e9793abd642881bc7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26465&size=130"
          },
          {
              id: "e605088834cddd9384dc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26466&size=130"
          },
          {
              id: "19b4f639ca7c23227a6d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26467&size=130"
          },
          {
              id: "a7a64f2b736e9a30c37f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26468&size=130"
          },
          {
              id: "981471994ddca482fdcd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26469&size=130"
          },
          {
              id: "1697fc1ac05f2901704e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26470&size=130"
          },
          {
              id: "a282490f754a9c14c55b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26471&size=130"
          },
          {
              id: "36c9d244ee01075f5e10",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26472&size=130"
          },
          {
              id: "e30a06873ac2d39c8ad3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26473&size=130"
          },
          {
              id: "3bf3dd7ee13b0865512a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26474&size=130"
          },
          {
              id: "69588ed5b2905bce0281",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26475&size=130"
          },
          {
              id: "d42834a508e0e1beb8f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26476&size=130"
          },
          {
              id: "b8b1593c65798c27d568",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26477&size=130"
          },
          {
              id: "5e55bcd8809d69c3308c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26478&size=130"
          },
          {
              id: "76889505a940401e1951",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26479&size=130"
          },
          {
              id: "0d5cf1d1cd9424ca7d85",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26480&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker088f3c4c0009e957b018.png",
      price: "Miễn phí",
      name: "SeulKi - 360mobi",
      thumbImg: "https://zalo-api.zadn.vn/7/8/b/e/b/10680/preview/sieuki_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/8/b/e/b/10680/icon_pre/sieuki_thumb.png",
      id: "e244d687eac2039c5ad3",
      source: "",
      totalImage: "21 sticker",
      value: [
          {
              id: "58bd7b304775ae2bf764",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26543&size=130"
          },
          {
              id: "f62bcaa6f6e31fbd46f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26544&size=130"
          },
          {
              id: "a94694cba88e41d0189f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26545&size=130"
          },
          {
              id: "64585ad566908fced681",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26546&size=130"
          },
          {
              id: "fce2c36fff2a16744f3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26547&size=130"
          },
          {
              id: "ea5ed2d3ee9607c85e87",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26548&size=130"
          },
          {
              id: "c060f9edc5a82cf675b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26549&size=130"
          },
          {
              id: "5ad6605b5c1eb540ec0f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26550&size=130"
          },
          {
              id: "36230dae31ebd8b581fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26551&size=130"
          },
          {
              id: "29171d9a21dfc88191ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26552&size=130"
          },
          {
              id: "5cbb69365573bc2de562",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26553&size=130"
          },
          {
              id: "02b6343b087ee120b86f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26554&size=130"
          },
          {
              id: "523565b859fdb0a3e9ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26555&size=130"
          },
          {
              id: "ec40dccde08809d65099",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26556&size=130"
          },
          {
              id: "423473b94ffca6a2ffed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26557&size=130"
          },
          {
              id: "9b58a9d595907cce2581",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26558&size=130"
          },
          {
              id: "37eb04663823d17d8832",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26559&size=130"
          },
          {
              id: "38b6743b487ea120f86f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26560&size=130"
          },
          {
              id: "8041cdccf18918d74198",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26561&size=130"
          },
          {
              id: "1fc851456d00845edd11",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26562&size=130"
          },
          {
              id: "96d4d959e51c0c42550d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26563&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker45b3587e643b8d65d42a.png",
      price: "Miễn phí",
      name: "Buppy 2",
      thumbImg: "https://zalo-api.zadn.vn/7/7/1/f/8/10129/preview/bubby2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/7/1/f/8/10129/icon_pre/bubby2_thumb.png",
      id: "1ad0071d3b58d2068b49",
      source: "Zalo",
      totalImage: "20 sticker",
      value: [
          {
              id: "8ba942057e40971ece51",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17989&size=130"
          },
          {
              id: "3a2bf087ccc2259c7cd3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17990&size=130"
          },
          {
              id: "8cc3476f7b2a9274cb3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17991&size=130"
          },
          {
              id: "2b7defd1d3943aca6385",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17992&size=130"
          },
          {
              id: "dde1184d2408cd569419",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17993&size=130"
          },
          {
              id: "c21504b938fcd1a288ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17994&size=130"
          },
          {
              id: "05adc201fe44171a4e55",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17995&size=130"
          },
          {
              id: "09fcc950f5151c4b4504",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17996&size=130"
          },
          {
              id: "08b1c91df5581c064549",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17997&size=130"
          },
          {
              id: "e0f1225d1e18f746ae09",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17998&size=130"
          },
          {
              id: "3e9efd32c17728297166",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17999&size=130"
          },
          {
              id: "b8946438587db123e86c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18000&size=130"
          },
          {
              id: "a7887a244661af3ff670",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18001&size=130"
          },
          {
              id: "549c8a30b6755f2b0664",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18002&size=130"
          },
          {
              id: "aa5975f549b0a0eef9a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18003&size=130"
          },
          {
              id: "fc7e24d21897f1c9a886",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18004&size=130"
          },
          {
              id: "ee22378e0bcbe295bbda",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18005&size=130"
          },
          {
              id: "2eb6f41ac85f2101784e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18006&size=130"
          },
          {
              id: "f9be22121e57f709ae46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18007&size=130"
          },
          {
              id: "1b82cf2ef36b1a35437a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18008&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker414fee8fd2ca3b9462db.png",
      price: "Miễn phí",
      name: "Áo Xanh Thanh Niên 2",
      thumbImg: "https://zalo-api.zadn.vn/9/3/d/2/6/10787/preview/aoxanh2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/9/3/d/2/6/10787/icon_pre/aoxanh2_thumb.png",
      id: "e6e2492275679c39c576",
      source: "",
      totalImage: "12 sticker",
      value: [
          {
              id: "c6faaa7e963b7f65262a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28384&size=130"
          },
          {
              id: "f76b9aefa6aa4ff416bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28385&size=130"
          },
          {
              id: "55db3b5f071aee44b70b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28386&size=130"
          },
          {
              id: "0cd663525f17b649ef06",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28387&size=130"
          },
          {
              id: "b562dde6e1a308fd51b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28388&size=130"
          },
          {
              id: "1be272664e23a77dfe32",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28389&size=130"
          },
          {
              id: "af3fc5bbf9fe10a049ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28390&size=130"
          },
          {
              id: "6310089434d1dd8f84c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28391&size=130"
          },
          {
              id: "b4fad07eec3b05655c2a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28392&size=130"
          },
          {
              id: "103b75bf49faa0a4f9eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28393&size=130"
          },
          {
              id: "afa2c926f5631c3d4572",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28394&size=130"
          },
          {
              id: "429b251f195af004a94b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=28395&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd90c66ce5a8bb3d5ea9a.png",
      price: "Miễn phí",
      name: "Piyomaru 2",
      thumbImg: "https://zalo-api.zadn.vn/e/f/1/8/9/10291/preview/pi_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/e/f/1/8/9/10291/icon_pre/pi_thumb.png",
      id: "41ddfe1fc25a2b04724b",
      source: "Minto Inc.",
      totalImage: "24 sticker",
      value: [
          {
              id: "f8299b91a7d44e8a17c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21231&size=130"
          },
          {
              id: "79ba05023947d0198956",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21232&size=130"
          },
          {
              id: "00fa7d424107a859f116",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21233&size=130"
          },
          {
              id: "db90a528996d7033297c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21234&size=130"
          },
          {
              id: "099a76224a67a339fa76",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21235&size=130"
          },
          {
              id: "5efd26451a00f35eaa11",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21236&size=130"
          },
          {
              id: "96bcef04d3413a1f6350",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21237&size=130"
          },
          {
              id: "10d66a6e562bbf75e63a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21238&size=130"
          },
          {
              id: "d449aff193b47aea23a5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21239&size=130"
          },
          {
              id: "8b94ff2cc3692a377378",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21240&size=130"
          },
          {
              id: "753f00873cc2d59c8cd3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21241&size=130"
          },
          {
              id: "e1ab9713ab5642081b47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21242&size=130"
          },
          {
              id: "175960e15ca4b5faecb5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21243&size=130"
          },
          {
              id: "2e415ef962bc8be2d2ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21244&size=130"
          },
          {
              id: "1bdd6a655620bf7ee631",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21245&size=130"
          },
          {
              id: "229d50256c60853edc71",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21246&size=130"
          },
          {
              id: "d521a6999adc73822acd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21247&size=130"
          },
          {
              id: "76b6fa0fc64a2f14765b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21248&size=130"
          },
          {
              id: "19a89411a854410a1845",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21249&size=130"
          },
          {
              id: "96c918702435cd6b9424",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21250&size=130"
          },
          {
              id: "a693292a156ffc31a57e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21251&size=130"
          },
          {
              id: "aded25541911f04fa900",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21252&size=130"
          },
          {
              id: "06e78f5eb31b5a45030a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21253&size=130"
          },
          {
              id: "4304c9bdf5f81ca645e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21254&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerb96664a458e1b1bfe8f0.png",
      price: "Miễn phí",
      name: "Bà Già Khó Tính",
      thumbImg: "https://zalo-api.zadn.vn/7/6/8/4/e/10321/preview/mechong_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/6/8/4/e/10321/icon_pre/mechong_thumb.png",
      id: "e92a34e808ade1f3b8bc",
      source: "",
      totalImage: "12 sticker",
      value: [
          {
              id: "f43766885acdb393eadc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21790&size=130"
          },
          {
              id: "8b1718a824edcdb394fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21791&size=130"
          },
          {
              id: "f0425cfd60b889e6d0a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21792&size=130"
          },
          {
              id: "9fe632590e1ce742be0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21793&size=130"
          },
          {
              id: "21718fceb38b5ad5039a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21794&size=130"
          },
          {
              id: "f94356fc6ab983e7daa8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21795&size=130"
          },
          {
              id: "4bd1e36edf2b36756f3a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21796&size=130"
          },
          {
              id: "7e4dd7f2ebb702e95ba6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21797&size=130"
          },
          {
              id: "4f19e5a6d9e330bd69f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21798&size=130"
          },
          {
              id: "cd6366dc5a99b3c7ea88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21799&size=130"
          },
          {
              id: "6c32c88df4c81d9644d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21800&size=130"
          },
          {
              id: "29638cdcb09959c70088",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21801&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker59b8567a6a3f8361da2e.png",
      price: "Miễn phí",
      name: "Rồng Medie",
      thumbImg: "https://zalo-api.zadn.vn/0/d/e/c/d/10371/preview/medie_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/0/d/e/c/d/10371/icon_pre/medie_thumb.png",
      id: "960f99cda5884cd61599",
      source: "MediaZ",
      totalImage: "15 sticker",
      value: [
          {
              id: "9e4e73fc4fb9a6e7ffa8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22625&size=130"
          },
          {
              id: "689a8628ba6d53330a7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22626&size=130"
          },
          {
              id: "87fe684c5409bd57e418",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22627&size=130"
          },
          {
              id: "0ae0e252de1737496e06",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22628&size=130"
          },
          {
              id: "9ade736c4f29a677ff38",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22629&size=130"
          },
          {
              id: "49aea31c9f5976072f48",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22630&size=130"
          },
          {
              id: "a6a64d147151980fc140",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22631&size=130"
          },
          {
              id: "1aa0fe12c2572b097246",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22632&size=130"
          },
          {
              id: "475ea2ec9ea977f72eb8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22633&size=130"
          },
          {
              id: "b50953bb6ffe86a0dfef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22634&size=130"
          },
          {
              id: "05dfe26dde2837766e39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22635&size=130"
          },
          {
              id: "1bd1fb63c7262e787737",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22636&size=130"
          },
          {
              id: "4a98ab2a976f7e31277e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22637&size=130"
          },
          {
              id: "6cd48e66b2235b7d0232",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22638&size=130"
          },
          {
              id: "af804c3270779929c066",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22639&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker1d461d8421c1c89f91d0.png",
      price: "Miễn phí",
      name: "Nấm và Sói",
      thumbImg: "https://zalo-api.zadn.vn/a/9/7/9/c/10380/preview/namvasoi_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/a/9/7/9/c/10380/icon_pre/namvasoi_thumb.png",
      id: "32ab32690e2ce772be3d",
      source: "Hà Huy Hoàng",
      totalImage: "16 sticker",
      value: [
          {
              id: "fedb73684f2da673ff3c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22785&size=130"
          },
          {
              id: "f9a9771a4b5fa201fb4e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22786&size=130"
          },
          {
              id: "58e3d750eb15024b5b04",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22787&size=130"
          },
          {
              id: "674eeffdd3b83ae663a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22788&size=130"
          },
          {
              id: "b5823c310074e92ab065",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22789&size=130"
          },
          {
              id: "716afbd9c79c2ec2778d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22790&size=130"
          },
          {
              id: "0298892bb56e5c30057f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22791&size=130"
          },
          {
              id: "b54c31ff0dbae4e4bdab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22792&size=130"
          },
          {
              id: "98141da721e2c8bc91f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22793&size=130"
          },
          {
              id: "c9184fab73ee9ab0c3ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22794&size=130"
          },
          {
              id: "60d1e762db2732796b36",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22795&size=130"
          },
          {
              id: "52ded26dee2807765e39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22796&size=130"
          },
          {
              id: "bc9c3d2f016ae834b17b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22797&size=130"
          },
          {
              id: "1fc69d75a130486e1121",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22798&size=130"
          },
          {
              id: "1b1798a4a4e14dbf14f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22799&size=130"
          },
          {
              id: "396ca5df999a70c4298b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22800&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerb50c31cf0d8ae4d4bd9b.png",
      price: "Miễn phí",
      name: "Bell & Annie",
      thumbImg: "https://zalo-api.zadn.vn/c/9/7/5/0/10504/preview/bellandannie_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/9/7/5/0/10504/icon_pre/bellandannie_thumb.png",
      id: "7e50fa93c6d62f8876c7",
      source: "Ring Ring",
      totalImage: "25 sticker",
      value: [
          {
              id: "87744fc173849adac395",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24388&size=130"
          },
          {
              id: "7d77b4c2888761d93896",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24389&size=130"
          },
          {
              id: "fd1937ac0be9e2b7bbf8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24390&size=130"
          },
          {
              id: "7cabb71e8b5b62053b4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24391&size=130"
          },
          {
              id: "1b74dfc1e3840ada5395",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24392&size=130"
          },
          {
              id: "bfc07a754630af6ef621",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24393&size=130"
          },
          {
              id: "09a0cf15f3501a0e4341",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24394&size=130"
          },
          {
              id: "3459f3eccfa926f77fb8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24395&size=130"
          },
          {
              id: "645fa4ea98af71f128be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24396&size=130"
          },
          {
              id: "0a8bcb3ef77b1e25476a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24397&size=130"
          },
          {
              id: "306df2d8ce9d27c37e8c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24398&size=130"
          },
          {
              id: "4b2c8899b4dc5d8204cd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24399&size=130"
          },
          {
              id: "c7b51b002745ce1b9754",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24400&size=130"
          },
          {
              id: "ed1030a50ce0e5bebcf1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24401&size=130"
          },
          {
              id: "22f5fc40c005295b7014",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24402&size=130"
          },
          {
              id: "e31b3cae00ebe9b5b0fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24403&size=130"
          },
          {
              id: "8c9f542a686f8131d87e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24404&size=130"
          },
          {
              id: "b030698555c0bc9ee5d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24405&size=130"
          },
          {
              id: "ad7877cd4b88a2d6fb99",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24406&size=130"
          },
          {
              id: "7bc1a0749c31756f2c20",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24407&size=130"
          },
          {
              id: "bef36a465603bf5de612",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24408&size=130"
          },
          {
              id: "1433c186fdc3149d4dd2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24409&size=130"
          },
          {
              id: "445d92e8aead47f31ebc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24410&size=130"
          },
          {
              id: "6fc5b87084356d6b3424",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24411&size=130"
          },
          {
              id: "1d3dcd88f1cd189341dc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24412&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker36ab9168ad2d44731d3c.png",
      price: "Miễn phí",
      name: "Mocha & Beige",
      thumbImg: "https://zalo-api.zadn.vn/f/8/5/b/d/10539/preview/mocha_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/f/8/5/b/d/10539/icon_pre/mocha_thumb.png",
      id: "c8cf6f0c5349ba17e358",
      source: "Mojitok",
      totalImage: "20 sticker",
      value: [
          {
              id: "c400108a2ccfc5919cde",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24664&size=130"
          },
          {
              id: "1931ccbbf0fe19a040ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24665&size=130"
          },
          {
              id: "84ed52676e22877cde33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24666&size=130"
          },
          {
              id: "e8dd3f570312ea4cb303",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24667&size=130"
          },
          {
              id: "b38963035f46b618ef57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24668&size=130"
          },
          {
              id: "87c5564f6a0a8354da1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24669&size=130"
          },
          {
              id: "7f29ada391e678b821f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24670&size=130"
          },
          {
              id: "910c42867ec3979dced2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24671&size=130"
          },
          {
              id: "b7eb5b6167248e7ad735",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24672&size=130"
          },
          {
              id: "6c0d8187bdc2549c0dd3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24673&size=130"
          },
          {
              id: "b6bc583664738d2dd462",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24674&size=130"
          },
          {
              id: "84d36b59571cbe42e70d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24675&size=130"
          },
          {
              id: "e84f00c53c80d5de8c91",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24676&size=130"
          },
          {
              id: "a0b4493e757b9c25c56a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24677&size=130"
          },
          {
              id: "e294081e345bdd05844a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24678&size=130"
          },
          {
              id: "624e89c4b5815cdf0590",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24679&size=130"
          },
          {
              id: "3633d2b9eefc07a25eed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24680&size=130"
          },
          {
              id: "629d8717bb52520c0b43",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24681&size=130"
          },
          {
              id: "47c7a14d9d0874562d19",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24682&size=130"
          },
          {
              id: "ba025d8861cd8893d1dc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24683&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker412aa4e998ac71f228bd.png",
      price: "Miễn phí",
      name: "Eagle & Raon",
      thumbImg: "https://zalo-api.zadn.vn/1/a/a/4/d/10601/preview/aegle_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/1/a/a/4/d/10601/icon_pre/aegle_thumb.png",
      id: "711d94dea89b41c5188a",
      source: "Mojitok",
      totalImage: "20 sticker",
      value: [
          {
              id: "2b8f7d064143a81df152",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25562&size=130"
          },
          {
              id: "7ed42f5d1318fa46a309",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25565&size=130"
          },
          {
              id: "082b5ba267e78eb9d7f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25567&size=130"
          },
          {
              id: "733d1eb422f1cbaf92e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25569&size=130"
          },
          {
              id: "cb24a4ad98e871b628f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25571&size=130"
          },
          {
              id: "85edec64d021397f6030",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25573&size=130"
          },
          {
              id: "3ecc54456800815ed811",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25574&size=130"
          },
          {
              id: "6b690fe033a5dafb83b4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25576&size=130"
          },
          {
              id: "733815b129f4c0aa99e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25578&size=130"
          },
          {
              id: "5b233aaa06efefb1b6fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25581&size=130"
          },
          {
              id: "76c6144f280ac154981b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25582&size=130"
          },
          {
              id: "26305ab966fc8fa2d6ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25584&size=130"
          },
          {
              id: "301a4e9372d69b88c2c7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25586&size=130"
          },
          {
              id: "55392cb010f5f9aba0e4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25589&size=130"
          },
          {
              id: "df14a49d98d8718628c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25591&size=130"
          },
          {
              id: "0b577dde419ba8c5f18a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25594&size=130"
          },
          {
              id: "510e21871dc2f49cadd3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25596&size=130"
          },
          {
              id: "a216d39fefda06845fcb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25597&size=130"
          },
          {
              id: "eea0622e5e6bb735ee7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25600&size=130"
          },
          {
              id: "86d20b5c3719de478708",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25601&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerf425e5e6d9a330fd69b2.png",
      price: "Miễn phí",
      name: "Kiki & Ppuyo",
      thumbImg: "https://zalo-api.zadn.vn/4/b/f/9/c/10653/preview/kiki_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/4/b/f/9/c/10653/icon_pre/kiki_thumb.png",
      id: "5a934b5077159e4bc704",
      source: "Mojitok",
      totalImage: "16 sticker",
      value: [
          {
              id: "316824e418a1f1ffa8b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26265&size=130"
          },
          {
              id: "379921151d50f40ead41",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26266&size=130"
          },
          {
              id: "3e3229be15fbfca5a5ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26267&size=130"
          },
          {
              id: "021512992edcc7829ecd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26268&size=130"
          },
          {
              id: "8883990fa54a4c14155b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26269&size=130"
          },
          {
              id: "c4bed632ea7703295a66",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26270&size=130"
          },
          {
              id: "91ad8221be64573a0e75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26271&size=130"
          },
          {
              id: "ef4dc3c1ff8416da4f95",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26272&size=130"
          },
          {
              id: "a84885c4b98150df0990",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26273&size=130"
          },
          {
              id: "fec5d049ec0c05525c1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26274&size=130"
          },
          {
              id: "2fd1005d3c18d5468c09",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26275&size=130"
          },
          {
              id: "e12dc9a1f5e41cba45f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26276&size=130"
          },
          {
              id: "60d44958751d9c43c50c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26277&size=130"
          },
          {
              id: "ee0ec482f8c7119948d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26278&size=130"
          },
          {
              id: "1d0836840ac1e39fbad0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26279&size=130"
          },
          {
              id: "d7a2f32ecf6b26357f7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26280&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker65b25f7f633a8a64d32b.png",
      price: "Miễn phí",
      name: "Heo Hồng",
      thumbImg: "https://zalo-api.zadn.vn/c/b/a/4/f/10166/preview/heohong_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/b/a/4/f/10166/icon_pre/heohong_thumb.png",
      id: "1b3b21f61db3f4edada2",
      source: "Trangbabie",
      totalImage: "16 sticker",
      value: [
          {
              id: "3e7464d65893b1cde882",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18646&size=130"
          },
          {
              id: "e8b9b31b8f5e66003f4f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18647&size=130"
          },
          {
              id: "12e646447a01935fca10",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18648&size=130"
          },
          {
              id: "fb23ae8192c47b9a22d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18649&size=130"
          },
          {
              id: "13b9451b795e9000c94f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18650&size=130"
          },
          {
              id: "df1a88b8b4fd5da304ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18651&size=130"
          },
          {
              id: "4dab1d09214cc812915d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18652&size=130"
          },
          {
              id: "1da94c0b704e9910c05f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18653&size=130"
          },
          {
              id: "9714c5b6f9f310ad49e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18654&size=130"
          },
          {
              id: "e027b3858fc0669e3fd1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18655&size=130"
          },
          {
              id: "ce5ea2fc9eb977e72ea8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18656&size=130"
          },
          {
              id: "2e6e43cc7f8996d7cf98",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18657&size=130"
          },
          {
              id: "d19fbf3d83786a263369",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18658&size=130"
          },
          {
              id: "d5dbba79863c6f62362d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18659&size=130"
          },
          {
              id: "20b8481a745f9d01c44e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18660&size=130"
          },
          {
              id: "70ab1909254ccc12955d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18661&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker6653759049d5a08bf9c4.png",
      price: "Miễn phí",
      name: "Cà & Méo 2",
      thumbImg: "https://zalo-api.zadn.vn/a/7/0/1/0/10655/preview/cameo2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/a/7/0/1/0/10655/icon_pre/cameo2_thumb.png",
      id: "537c40bf7cfa95a4cceb",
      source: "Lạt",
      totalImage: "20 sticker",
      value: [
          {
              id: "350a0b8637c3de9d87d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26290&size=130"
          },
          {
              id: "8bb2b43e887b6125386a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26291&size=130"
          },
          {
              id: "b82280aebceb55b50cfa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26292&size=130"
          },
          {
              id: "c4dbfd57c112284c7103",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26293&size=130"
          },
          {
              id: "7b8441087d4d9413cd5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26294&size=130"
          },
          {
              id: "1b3920b51cf0f5aeace1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26295&size=130"
          },
          {
              id: "5e946a18565dbf03e64c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26296&size=130"
          },
          {
              id: "afa09a2ca6694f371678",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26297&size=130"
          },
          {
              id: "233f15b329f6c0a899e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26298&size=130"
          },
          {
              id: "7b1a4c9670d3998dc0c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26299&size=130"
          },
          {
              id: "28ff18732436cd689427",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26300&size=130"
          },
          {
              id: "3dc20c4e300bd955801a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26301&size=130"
          },
          {
              id: "953fa7b39bf672a82be7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26302&size=130"
          },
          {
              id: "308e03023f47d6198f56",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26303&size=130"
          },
          {
              id: "de92921eae5b47051e4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26304&size=130"
          },
          {
              id: "4a93071f3b5ad2048b4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26305&size=130"
          },
          {
              id: "5cd812542e11c74f9e00",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26306&size=130"
          },
          {
              id: "7f8f30030c46e518bc57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26307&size=130"
          },
          {
              id: "a92be1a7dde234bc6df3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26308&size=130"
          },
          {
              id: "ec3ca5b099f570ab29e4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26309&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker52788abab6ff5fa106ee.png",
      price: "Miễn phí",
      name: "Bé Sen",
      thumbImg: "https://zalo-api.zadn.vn/1/b/d/a/7/10324/preview/sen_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/1/b/d/a/7/10324/icon_pre/sen_thumb.png",
      id: "b8d460165c53b50dec42",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "0cbbe604da41331f6a50",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21862&size=130"
          },
          {
              id: "4f46a4f998bc71e228ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21863&size=130"
          },
          {
              id: "392bdd94e1d1088f51c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21864&size=130"
          },
          {
              id: "ca152faa13effab1a3fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21865&size=130"
          },
          {
              id: "8c2b6a9456d1bf8fe6c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21866&size=130"
          },
          {
              id: "1cebfb54c7112e4f7700",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21867&size=130"
          },
          {
              id: "8c3a6c8550c0b99ee0d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21868&size=130"
          },
          {
              id: "06a5e71adb5f32016b4e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21869&size=130"
          },
          {
              id: "6d0b8fb4b3f15aaf03e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21870&size=130"
          },
          {
              id: "ac804f3f737a9a24c36b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21871&size=130"
          },
          {
              id: "f9da05653920d07e8931",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21872&size=130"
          },
          {
              id: "13beee01d2443b1a6255",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21873&size=130"
          },
          {
              id: "9fab61145d51b40fed40",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21874&size=130"
          },
          {
              id: "5feda0529c1775492c06",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21875&size=130"
          },
          {
              id: "0a2ff290ced5278b7ec4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21876&size=130"
          },
          {
              id: "faad03123f57d6098f46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21877&size=130"
          },
          {
              id: "1c39e686dac3339d6ad2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21878&size=130"
          },
          {
              id: "d9a5221a1e5ff701ae4e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21879&size=130"
          },
          {
              id: "dc9928261463fd3da472",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21880&size=130"
          },
          {
              id: "59e7ac58901d7943200c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21881&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickeraed80e323277db298266.png",
      price: "Miễn phí",
      name: "Ủn Ủn & Meo Meo",
      thumbImg: "https://zalo-api.zadn.vn/f/7/1/7/7/44/preview/meo_un_cover.jpg",
      iconUrl: "https://zalo-api.zadn.vn/f/7/1/7/7/44/icon_pre/heomeo_thumb.png",
      id: "df5e7fb443f1aaaff3e0",
      source: "Zalo",
      totalImage: "20 sticker",
      value: [
          {
              id: "fe26a9cf958a7cd4259b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=987&size=130"
          },
          {
              id: "eb9cbb7587306e6e3721",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=988&size=130"
          },
          {
              id: "b432e5dbd99e30c0698f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=989&size=130"
          },
          {
              id: "892ddbc4e7810edf5790",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=990&size=130"
          },
          {
              id: "df4f8ca6b0e359bd00f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=991&size=130"
          },
          {
              id: "f2849e6da2284b761239",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=992&size=130"
          },
          {
              id: "d9a5b44c880961573818",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=993&size=130"
          },
          {
              id: "3bb3555a691f8041d90e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=994&size=130"
          },
          {
              id: "95f4fa1dc6582f067649",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=995&size=130"
          },
          {
              id: "43cc2b251760fe3ea771",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=996&size=130"
          },
          {
              id: "32375bde679b8ec5d78a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=997&size=130"
          },
          {
              id: "dc42b6ab8aee63b03aff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=998&size=130"
          },
          {
              id: "49eb22021e47f719ae56",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=999&size=130"
          },
          {
              id: "36e7520e6e4b8715de5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1000&size=130"
          },
          {
              id: "9b4afea3c2e62bb872f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1001&size=130"
          },
          {
              id: "406026891accf392aadd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1002&size=130"
          },
          {
              id: "9a8ffd66c123287d7132",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1003&size=130"
          },
          {
              id: "fe939e7aa23f4b61122e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1004&size=130"
          },
          {
              id: "bb7eda97e6d20f8c56c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1005&size=130"
          },
          {
              id: "1e287cc14084a9daf095",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1006&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerb556b89484d16d8f34c0.png",
      price: "Miễn phí",
      name: "Cotton Paka",
      thumbImg: "https://zalo-api.zadn.vn/5/7/9/6/0/10369/preview/paka_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/7/9/6/0/10369/icon_pre/paka_thumb.png",
      id: "cba9c66bfa2e13704a3f",
      source: "Caerux Co.,Ltd.",
      totalImage: "20 sticker",
      value: [
          {
              id: "44e6f154cd11244f7d00",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22585&size=130"
          },
          {
              id: "1a73acc1908479da2095",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22586&size=130"
          },
          {
              id: "0116b6a48ae163bf3af0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22587&size=130"
          },
          {
              id: "384c88feb4bb5de504aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22588&size=130"
          },
          {
              id: "30958127bd62543c0d73",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22589&size=130"
          },
          {
              id: "f43346817ac4939acad5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22590&size=130"
          },
          {
              id: "f2fd414f7d0a9454cd1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22591&size=130"
          },
          {
              id: "35c9f97bc53e2c60752f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22592&size=130"
          },
          {
              id: "b3b17e034246ab18f257",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22593&size=130"
          },
          {
              id: "3e80f032cc7725297c66",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22594&size=130"
          },
          {
              id: "7c93b3218f64663a3f75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22595&size=130"
          },
          {
              id: "ecc524771832f16ca823",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22596&size=130"
          },
          {
              id: "2326ea94d6d13f8f66c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22597&size=130"
          },
          {
              id: "fd0537b70bf2e2acbbe3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22598&size=130"
          },
          {
              id: "2017eba5d7e03ebe67f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22599&size=130"
          },
          {
              id: "4f4a8bf8b7bd5ee307ac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22600&size=130"
          },
          {
              id: "88864d347171982fc160",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22601&size=130"
          },
          {
              id: "f5eb33590f1ce642bf0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22602&size=130"
          },
          {
              id: "2e01e9b3d5f63ca865e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22603&size=130"
          },
          {
              id: "31fcf14ecd0b24557d1a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22604&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerf2ba8d78b13d5863012c.png",
      price: "Miễn phí",
      name: "Zookiz",
      thumbImg: "https://zalo-api.zadn.vn/5/b/7/f/c/10483/preview/zookiz_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/b/7/f/c/10483/icon_pre/zookiz_thumb.png",
      id: "8e60f1a2cde724b97df6",
      source: "Mojitok",
      totalImage: "24 sticker",
      value: [
          {
              id: "1ab2b5068943601d3952",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24099&size=130"
          },
          {
              id: "e4c64c7270379969c026",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24100&size=130"
          },
          {
              id: "b7781ecc2289cbd79298",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24101&size=130"
          },
          {
              id: "80682adc1699ffc7a688",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24102&size=130"
          },
          {
              id: "6edec56af92f1071493e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24103&size=130"
          },
          {
              id: "38999c2da06849361079",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24104&size=130"
          },
          {
              id: "bb191ead22e8cbb692f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24105&size=130"
          },
          {
              id: "07f9a14d9d0874562d19",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24106&size=130"
          },
          {
              id: "936f34db089ee1c0b88f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24107&size=130"
          },
          {
              id: "818021341d71f42fad60",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24108&size=130"
          },
          {
              id: "4bb0ea04d6413f1f6650",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24109&size=130"
          },
          {
              id: "e60644b278f791a9c8e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24110&size=130"
          },
          {
              id: "4de9ee5dd2183b466209",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24111&size=130"
          },
          {
              id: "121aaeae92eb7bb522fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24112&size=130"
          },
          {
              id: "a19a1c2e206bc935907a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24113&size=130"
          },
          {
              id: "cfbc71084d4da413fd5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24114&size=130"
          },
          {
              id: "e5ca5a7e663b8f65d62a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24115&size=130"
          },
          {
              id: "50eae85ed41b3d45640a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24116&size=130"
          },
          {
              id: "30f18945b5005c5e0511",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24117&size=130"
          },
          {
              id: "63e7d953e5160c485507",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24118&size=130"
          },
          {
              id: "aafd11492d0cc4529d1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24119&size=130"
          },
          {
              id: "d78263365f73b62def62",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24120&size=130"
          },
          {
              id: "71b9c40df84811164859",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24121&size=130"
          },
          {
              id: "e386553269778029d966",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24122&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd99115532916c0489907.png",
      price: "Miễn phí",
      name: "Nhây",
      thumbImg: "https://zalo-api.zadn.vn/6/1/a/1/0/10304/preview/nhay_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/6/1/a/1/0/10304/icon_pre/nhay_thumb.png",
      id: "23e9ef2bd36e3a30637f",
      source: "",
      totalImage: "17 sticker",
      value: [
          {
              id: "c61abda381e668b831f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21495&size=130"
          },
          {
              id: "c4a0b0198c5c65023c4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21496&size=130"
          },
          {
              id: "eb7d9ec4a2814bdf1290",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21497&size=130"
          },
          {
              id: "248a52336e768728de67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21498&size=130"
          },
          {
              id: "20ba57036b468218db57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21499&size=130"
          },
          {
              id: "161d66a45ae1b3bfeaf0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21500&size=130"
          },
          {
              id: "093c788544c0ad9ef4d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21501&size=130"
          },
          {
              id: "c203b0ba8cff65a13cee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21502&size=130"
          },
          {
              id: "b67ec5c7f98210dc4993",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21503&size=130"
          },
          {
              id: "449ac824f4611d3f4470",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21504&size=130"
          },
          {
              id: "bbf5364b0a0ee350ba1f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21505&size=130"
          },
          {
              id: "db95552b696e8030d97f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21506&size=130"
          },
          {
              id: "f88677384b7da223fb6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21507&size=130"
          },
          {
              id: "e4e16c5f501ab944e00b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21508&size=130"
          },
          {
              id: "2941a0ff9cba75e42cab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21509&size=130"
          },
          {
              id: "2c04a6ba9aff73a12aee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21510&size=130"
          },
          {
              id: "cad641687d2d9473cd3c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21511&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker96e4da27e6620f3c5673.png",
      price: "Miễn phí",
      name: "Bong Bong - Zookiz",
      thumbImg: "https://zalo-api.zadn.vn/7/3/c/8/3/10688/preview/bongbong_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/3/c/8/3/10688/icon_pre/bongbong_thumb.png",
      id: "f3cabf09834c6a12335d",
      source: "Mojitok",
      totalImage: "16 sticker",
      value: [
          {
              id: "c2a97b2b476eae30f77f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26677&size=130"
          },
          {
              id: "d36569e755a2bcfce5b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26678&size=130"
          },
          {
              id: "c48f7f0d4348aa16f359",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26679&size=130"
          },
          {
              id: "f39347117b54920acb45",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26680&size=130"
          },
          {
              id: "3ae38f61b3245a7a0335",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26681&size=130"
          },
          {
              id: "ff5549d775929cccc583",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26682&size=130"
          },
          {
              id: "dfce684c5409bd57e418",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26683&size=130"
          },
          {
              id: "5da0ed22d16738396176",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26684&size=130"
          },
          {
              id: "4b4dfacfc68a2fd4769b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26685&size=130"
          },
          {
              id: "2dcc9f4ea30b4a55131a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26686&size=130"
          },
          {
              id: "0ab4b93685736c2d3562",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26687&size=130"
          },
          {
              id: "be4772c54e80a7defe91",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26688&size=130"
          },
          {
              id: "9c8f510d6d488416dd59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26689&size=130"
          },
          {
              id: "53ba9d38a17d4823116c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26690&size=130"
          },
          {
              id: "c1d40e563213db4d8202",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26691&size=130"
          },
          {
              id: "4d6e85ecb9a950f709b8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26692&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker22bf2e7d1238fb66a229.png",
      price: "Miễn phí",
      name: "Cà & Méo ",
      thumbImg: "https://zalo-api.zadn.vn/8/c/2/d/7/10368/icon/cameo_ic.png",
      iconUrl: "https://zalo-api.zadn.vn/8/c/2/d/7/10368/icon_pre/cameo_thumb.png",
      id: "4e9142537e169748ce07",
      source: "Lạt",
      totalImage: "23 sticker",
      value: [
          {
              id: "4b64e5d6d99330cd6982",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22562&size=130"
          },
          {
              id: "0adea56c992970772938",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22563&size=130"
          },
          {
              id: "92663ad40691efcfb680",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22564&size=130"
          },
          {
              id: "2cb08502b94750190956",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22565&size=130"
          },
          {
              id: "d4d17e634226ab78f237",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22566&size=130"
          },
          {
              id: "7454dfe6e3a30afd53b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22567&size=130"
          },
          {
              id: "52baf608ca4d23137a5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22568&size=130"
          },
          {
              id: "f76d52df6e9a87c4de8b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22569&size=130"
          },
          {
              id: "0798a12a9d6f74312d7e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22570&size=130"
          },
          {
              id: "6429c39bffde16804fcf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22571&size=130"
          },
          {
              id: "66acc61efa5b13054a4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22572&size=130"
          },
          {
              id: "4e68efdad39f3ac1638e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22573&size=130"
          },
          {
              id: "7715d5a7e9e200bc59f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22574&size=130"
          },
          {
              id: "04eba7599b1c72422b0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22575&size=130"
          },
          {
              id: "049bb829846c6d32347d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22576&size=130"
          },
          {
              id: "d2376f8553c0ba9ee3d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22577&size=130"
          },
          {
              id: "890e37bc0bf9e2a7bbe8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22578&size=130"
          },
          {
              id: "6b95d427e862013c5873",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22579&size=130"
          },
          {
              id: "47feff4cc3092a577318",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22580&size=130"
          },
          {
              id: "2d0a94b8a8fd41a318ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22581&size=130"
          },
          {
              id: "80bf3a0d0648ef16b659",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22582&size=130"
          },
          {
              id: "82ff394d0508ec56b519",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22583&size=130"
          },
          {
              id: "86cd327f0e3ae764be2b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22584&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker5f493a8b06ceef90b6df.png",
      price: "Miễn phí",
      name: "Áo Xanh Thanh Niên",
      thumbImg: "https://zalo-api.zadn.vn/c/c/7/5/c/10473/preview/thanhnien_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/c/7/5/c/10473/icon_pre/thanhnien_thumb.png",
      id: "8962eca0d0e539bb60f4",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "ad28e99fd5da3c8465cb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24008&size=130"
          },
          {
              id: "067043c77f8296dccf93",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24009&size=130"
          },
          {
              id: "5e7e18c9248ccdd2949d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24010&size=130"
          },
          {
              id: "8c55cbe2f7a71ef947b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24011&size=130"
          },
          {
              id: "cb148ba3b7e65eb807f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24012&size=130"
          },
          {
              id: "bbabfa1cc6592f077648",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24013&size=130"
          },
          {
              id: "d8529ae5a6a04ffe16b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24014&size=130"
          },
          {
              id: "0f4b4cfc70b999e7c0a8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24015&size=130"
          },
          {
              id: "3e9362245e61b73fee70",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24016&size=130"
          },
          {
              id: "ed9ab02d8c6865363c79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24017&size=130"
          },
          {
              id: "0c5852ef6eaa87f4debb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24018&size=130"
          },
          {
              id: "10124fa573e09abec3f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24019&size=130"
          },
          {
              id: "169e4e29726c9b32c27d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24020&size=130"
          },
          {
              id: "e740bef782b26bec32a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24021&size=130"
          },
          {
              id: "109e4a29766c9f32c67d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24022&size=130"
          },
          {
              id: "47a31c142051c90f9040",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24023&size=130"
          },
          {
              id: "7d5529e215a7fcf9a5b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24024&size=130"
          },
          {
              id: "4acd1f7a233fca61932e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24025&size=130"
          },
          {
              id: "324b64fc58b9b1e7e8a8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24026&size=130"
          },
          {
              id: "a1f3f644ca01235f7a10",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24027&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker6ee09d2da16848361179.png",
      price: "Miễn phí",
      name: "Ninja Bear 2",
      thumbImg: "https://zalo-api.zadn.vn/7/0/b/b/8/10111/preview/Mamenin_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/0/b/b/8/10111/icon_pre/Mamenin_thumb.png",
      id: "0eedfd20c165283b7174",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "f481bd2f816a6834317b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17605&size=130"
          },
          {
              id: "d1019bafa7ea4eb417fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17606&size=130"
          },
          {
              id: "d8f89356af13464d1f02",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17607&size=130"
          },
          {
              id: "254161ef5daab4f4edbb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17608&size=130"
          },
          {
              id: "98e1dd4fe10a0854511b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17609&size=130"
          },
          {
              id: "8bb8cd16f153180d4142",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17610&size=130"
          },
          {
              id: "2c306b9e57dbbe85e7ca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17611&size=130"
          },
          {
              id: "940cd4a2e8e701b958f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17612&size=130"
          },
          {
              id: "5524148a28cfc19198de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17613&size=130"
          },
          {
              id: "07db45757930906ec921",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17614&size=130"
          },
          {
              id: "af57ecf9d0bc39e260ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17615&size=130"
          },
          {
              id: "19ca45647921907fc930",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17616&size=130"
          },
          {
              id: "3b1e66b05af5b3abeae4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17617&size=130"
          },
          {
              id: "8005deabe2ee0bb052ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17618&size=130"
          },
          {
              id: "71592ef712b2fbeca2a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17619&size=130"
          },
          {
              id: "42a31a0d2648cf169659",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17620&size=130"
          },
          {
              id: "35ac6c025047b919e056",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17621&size=130"
          },
          {
              id: "371d6db351f6b8a8e1e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17622&size=130"
          },
          {
              id: "5a0401aa3defd4b18dfe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17623&size=130"
          },
          {
              id: "b21de6b3daf633a86ae7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17624&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker73c0480374469d18c457.png",
      price: "Miễn phí",
      name: "Dennis - 360mobi",
      thumbImg: "https://zalo-api.zadn.vn/e/f/f/3/0/10679/preview/dennis_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/e/f/f/3/0/10679/icon_pre/dennis_thumb.png",
      id: "1e2925ea19aff0f1a9be",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "338b24061843f11da852",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26523&size=130"
          },
          {
              id: "493859b565f08caed5e1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26524&size=130"
          },
          {
              id: "297d38f004b5edebb4a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26525&size=130"
          },
          {
              id: "00c412492e0cc7529e1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26526&size=130"
          },
          {
              id: "b668a5e599a070fe29b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26527&size=130"
          },
          {
              id: "23b80f353370da2e8361",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26528&size=130"
          },
          {
              id: "20050d8831cdd89381dc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26529&size=130"
          },
          {
              id: "4692681f545abd04e44b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26530&size=130"
          },
          {
              id: "102e3fa303e6eab8b3f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26531&size=130"
          },
          {
              id: "02d02a5d1618ff46a609",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26532&size=130"
          },
          {
              id: "000b298615c3fc9da5d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26533&size=130"
          },
          {
              id: "575d7dd04195a8cbf184",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26534&size=130"
          },
          {
              id: "e2bec933f5761c284567",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26535&size=130"
          },
          {
              id: "a05884d5b89051ce0881",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26536&size=130"
          },
          {
              id: "68e34d6e712b9875c13a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26537&size=130"
          },
          {
              id: "83afa522996770392976",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26538&size=130"
          },
          {
              id: "321a159729d2c08c99c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26539&size=130"
          },
          {
              id: "2982090f354adc14855b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26540&size=130"
          },
          {
              id: "5786760b4a4ea310fa5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26541&size=130"
          },
          {
              id: "9294b0198c5c65023c4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26542&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker96dfec12d05739096046.png",
      price: "Miễn phí",
      name: "Gia Đình Là Vô Giá",
      thumbImg: "https://zalo-api.zadn.vn/a/9/5/2/d/10230/preview/family_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/a/9/5/2/d/10230/icon_pre/family_thumb.png",
      id: "9353e99ed5db3c8565ca",
      source: "",
      totalImage: "12 sticker",
      value: [
          {
              id: "e9fe7c59401ca942f00d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19737&size=130"
          },
          {
              id: "942f02883ecdd7938edc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19738&size=130"
          },
          {
              id: "6e91f936c5732c2d7562",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19739&size=130"
          },
          {
              id: "a0f330540c11e54fbc00",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19740&size=130"
          },
          {
              id: "c97a58dd64988dc6d489",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19741&size=130"
          },
          {
              id: "ecb47e134256ab08f247",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19742&size=130"
          },
          {
              id: "b9372a9016d5ff8ba6c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19743&size=130"
          },
          {
              id: "0e8fa2289e6d77332e7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19744&size=130"
          },
          {
              id: "d55378f444b1adeff4a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19745&size=130"
          },
          {
              id: "2a6e84c9b88c51d2089d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19746&size=130"
          },
          {
              id: "7ebad11ded5804065d49",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19747&size=130"
          },
          {
              id: "be7616d12a94c3ca9a85",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19748&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrstickerb0672a8d16c8ff96a6d9.png",
      price: "Miễn phí",
      name: "Tiểu Yêu Nữ",
      thumbImg: "https://zalo-api.zadn.vn/b/6/d/7/6/22/preview/tieuyeu.jpg",
      iconUrl: "https://zalo-api.zadn.vn/b/6/d/7/6/22/icon_pre/tieuyeunu.png",
      id: "daa6404c7c099557cc18",
      source: "Internet & Zalo",
      totalImage: "15 sticker",
      value: [
          {
              id: "b361c68afacf13914ade",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=505&size=130"
          },
          {
              id: "b3bcc557f912104c4903",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=506&size=130"
          },
          {
              id: "b5cbc220fe65173b4e74",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=507&size=130"
          },
          {
              id: "66ca16212a64c33a9a75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=508&size=130"
          },
          {
              id: "1881696a552fbc71e53e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=509&size=130"
          },
          {
              id: "2dc15e2a626f8b31d27e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=511&size=130"
          },
          {
              id: "5f90d378ef3d06635f2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=512&size=130"
          },
          {
              id: "307abd9281d7688931c6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=513&size=130"
          },
          {
              id: "0d2083c8bf8d56d30f9c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=514&size=130"
          },
          {
              id: "8f5600be3cfbd5a58cea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=515&size=130"
          },
          {
              id: "db3053d86f9d86c3df8c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=516&size=130"
          },
          {
              id: "c7f54e1d72589b06c249",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=517&size=130"
          },
          {
              id: "85b90f513314da4a8305",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=518&size=130"
          },
          {
              id: "3402bfea83af6af133be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=519&size=130"
          },
          {
              id: "a66e22861ec3f79daed2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=520&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerb11a40d87c9d95c3cc8c.png",
      price: "Miễn phí",
      name: "Betakkuma",
      thumbImg: "https://zalo-api.zadn.vn/9/a/5/b/6/10365/preview/beta_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/9/a/5/b/6/10365/icon_pre/beta_thumb.png",
      id: "e7cf160d2a48c3169a59",
      source: "Minto Inc.",
      totalImage: "25 sticker",
      value: [
          {
              id: "44662fdb139efac0a38f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22503&size=130"
          },
          {
              id: "fe209a9da6d84f8616c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22504&size=130"
          },
          {
              id: "d5ffb0428c0765593c16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22505&size=130"
          },
          {
              id: "15e0735d4f18a646ff09",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22506&size=130"
          },
          {
              id: "ae95c928f56d1c33457c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22507&size=130"
          },
          {
              id: "345154ec68a981f7d8b8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22508&size=130"
          },
          {
              id: "7fa71e1a225fcb01924e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22509&size=130"
          },
          {
              id: "ad37cf8af3cf1a9143de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22510&size=130"
          },
          {
              id: "601103ac3fe9d6b78ff8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22511&size=130"
          },
          {
              id: "d858a4e598a071fe28b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22512&size=130"
          },
          {
              id: "198e64335876b128e867",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22513&size=130"
          },
          {
              id: "46f838450400ed5eb411",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22514&size=130"
          },
          {
              id: "acadd310ef55060b5f44",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22515&size=130"
          },
          {
              id: "6fd7176a2b2fc2719b3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22516&size=130"
          },
          {
              id: "d9cba0769c33756d2c22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22517&size=130"
          },
          {
              id: "71ca0b773732de6c8723",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22518&size=130"
          },
          {
              id: "e1a59a18a65d4f03164c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22519&size=130"
          },
          {
              id: "1a6b6ed65293bbcde282",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22520&size=130"
          },
          {
              id: "87b0f20dce4827167e59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22521&size=130"
          },
          {
              id: "23ab55166953800dd942",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22522&size=130"
          },
          {
              id: "158e62335e76b728ee67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22523&size=130"
          },
          {
              id: "0e727ecf428aabd4f29b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22524&size=130"
          },
          {
              id: "e5a0941da85841061849",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22525&size=130"
          },
          {
              id: "c500b7bd8bf862a63be9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22526&size=130"
          },
          {
              id: "18726bcf578abed4e79b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22527&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd95c979eabdb42851bca.png",
      price: "Miễn phí",
      name: "Việt Nam Vô Địch",
      thumbImg: "https://zalo-api.zadn.vn/5/4/d/4/c/10434/preview/aff_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/4/d/4/c/10434/icon_pre/aff_thumb.png",
      id: "8753c991f5d41c8a45c5",
      source: "",
      totalImage: "13 sticker",
      value: [
          {
              id: "60104ca170e499bac0f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23456&size=130"
          },
          {
              id: "8a92a7239b6672382b77",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23457&size=130"
          },
          {
              id: "1c5d32ec0ea9e7f7beb8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23458&size=130"
          },
          {
              id: "54587be947acaef2f7bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23459&size=130"
          },
          {
              id: "417069c15584bcdae595",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23460&size=130"
          },
          {
              id: "a7ac8e1db2585b060249",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23461&size=130"
          },
          {
              id: "cf91e520d965303b6974",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23462&size=130"
          },
          {
              id: "e0afcb1ef75b1e05474a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23463&size=130"
          },
          {
              id: "45ea615b5d1eb440ed0f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23464&size=130"
          },
          {
              id: "517474c54880a1def891",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23465&size=130"
          },
          {
              id: "5f6979d8459dacc3f58c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23466&size=130"
          },
          {
              id: "04ac231d1f58f606af49",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23467&size=130"
          },
          {
              id: "b9e19950a5154c4b1504",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23468&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerac57cc95f0d0198e40c1.png",
      price: "Miễn phí",
      name: "Tiểu Ngư Zalo Shop",
      thumbImg: "https://zalo-api.zadn.vn/1/3/8/4/4/10476/preview/zaloshop_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/1/3/8/4/4/10476/icon_pre/zaloshop_thumb.png",
      id: "49c029021547fc19a556",
      source: "",
      totalImage: "12 sticker",
      value: [
          {
              id: "3a4842ff7eba97e4ceab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24052&size=130"
          },
          {
              id: "8c36f581c9c4209a79d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24053&size=130"
          },
          {
              id: "34154ea272e79bb9c2f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24054&size=130"
          },
          {
              id: "ebf8904fac0a45541c1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24055&size=130"
          },
          {
              id: "48303c8700c2e99cb0d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24056&size=130"
          },
          {
              id: "345441e37da694f8cdb7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24057&size=130"
          },
          {
              id: "a1e5d752eb1702495b06",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24058&size=130"
          },
          {
              id: "151562a25ee7b7b9eef6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24059&size=130"
          },
          {
              id: "bf60cfd7f3921acc4383",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24060&size=130"
          },
          {
              id: "29dd586a642f8d71d43e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24061&size=130"
          },
          {
              id: "69451bf227b7cee997a6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24062&size=130"
          },
          {
              id: "9683e534d971302f6960",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24063&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker0a94ff57c3122a4c7303.png",
      price: "Miễn phí",
      name: "Hi, Bye & Good Night ",
      thumbImg: "https://zalo-api.zadn.vn/2/a/5/a/0/10617/preview/wave_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/a/5/a/0/10617/icon_pre/wave_thumb.png",
      id: "8dc278014444ad1af455",
      source: "",
      totalImage: "24 sticker",
      value: [
          {
              id: "89beda30e6750f2b5664",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25823&size=130"
          },
          {
              id: "c922a5ac99e970b729f8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25824&size=130"
          },
          {
              id: "ce34a3ba9fff76a12fee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25825&size=130"
          },
          {
              id: "f7f69978a53d4c63152c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25826&size=130"
          },
          {
              id: "87c7e849d40c3d52641d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25827&size=130"
          },
          {
              id: "17e57f6b432eaa70f33f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25828&size=130"
          },
          {
              id: "c21eab9097d57e8b27c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25829&size=130"
          },
          {
              id: "a2ebc865f4201d7e4431",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25830&size=130"
          },
          {
              id: "54433fcd0388ead6b399",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25831&size=130"
          },
          {
              id: "fe679ae9a6ac4ff216bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25832&size=130"
          },
          {
              id: "88daed54d111384f6100",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25833&size=130"
          },
          {
              id: "d186b7088b4d62133b5c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25834&size=130"
          },
          {
              id: "ffb2983ca4794d271468",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25835&size=130"
          },
          {
              id: "0f696fe753a2bafce3b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25836&size=130"
          },
          {
              id: "375656d86a9d83c3da8c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25837&size=130"
          },
          {
              id: "640606883acdd3938adc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25838&size=130"
          },
          {
              id: "4b2b28a514e0fdbea4f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25839&size=130"
          },
          {
              id: "fbf5877bbb3e52600b2f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25840&size=130"
          },
          {
              id: "028b7f054340aa1ef351",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25841&size=130"
          },
          {
              id: "44643aea06afeff1b6be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25842&size=130"
          },
          {
              id: "213f5eb162f48baad2e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25843&size=130"
          },
          {
              id: "fd0c8582b9c7509909d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25844&size=130"
          },
          {
              id: "084171cf4d8aa4d4fd9b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25845&size=130"
          },
          {
              id: "b76ccde2f1a718f941b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25846&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker02c59b2fa76a4e34177b.png",
      price: "Miễn phí",
      name: "Tiểu Yêu Nam",
      thumbImg: "https://zalo-api.zadn.vn/3/c/5/9/d/21/preview/tieuyeu.jpg",
      iconUrl: "https://zalo-api.zadn.vn/3/c/5/9/d/21/icon_pre/tieuyeunam.png",
      id: "c3675a8d66c88f96d6d9",
      source: "Internet & Zalo",
      totalImage: "32 sticker",
      value: [
          {
              id: "9921cdcaf18f18d1419e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=472&size=130"
          },
          {
              id: "7fb02a5b161eff40a60f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=473&size=130"
          },
          {
              id: "a622f0c9cc8c25d27c9d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=474&size=130"
          },
          {
              id: "0b755c9e60db8985d0ca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=475&size=130"
          },
          {
              id: "e9ffb91485516c0f3540",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=476&size=130"
          },
          {
              id: "b4d3e638da7d33236a6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=478&size=130"
          },
          {
              id: "dc828f69b32c5a72033d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=479&size=130"
          },
          {
              id: "a9e9c502f94710194956",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=480&size=130"
          },
          {
              id: "d0c3bd28816d6833317c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=481&size=130"
          },
          {
              id: "69f0071b3b5ed2008b4f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=482&size=130"
          },
          {
              id: "37f4581f645a8d04d44b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=483&size=130"
          },
          {
              id: "6f94067f3a3ad3648a2b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=485&size=130"
          },
          {
              id: "8616ecfdd0b839e660a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=486&size=130"
          },
          {
              id: "07216cca508fb9d1e09e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=487&size=130"
          },
          {
              id: "a9bfcd54f111184f4100",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=488&size=130"
          },
          {
              id: "79731c9820ddc98390cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=489&size=130"
          },
          {
              id: "9dd7fb3cc7792e277768",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=490&size=130"
          },
          {
              id: "6b1c0cf730b2d9ec80a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=491&size=130"
          },
          {
              id: "e84a88a1b4e45dba04f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=492&size=130"
          },
          {
              id: "4f3d2ed61293fbcda282",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=493&size=130"
          },
          {
              id: "fe5c9cb7a0f249ac10e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=494&size=130"
          },
          {
              id: "19777a9c46d9af87f6c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=495&size=130"
          },
          {
              id: "67731b9827ddce8397cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=496&size=130"
          },
          {
              id: "21075cec60a989f7d0b8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=497&size=130"
          },
          {
              id: "dc55a2be9efb77a52eea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=498&size=130"
          },
          {
              id: "b60ac9e1f5a41cfa45b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=499&size=130"
          },
          {
              id: "b3f9cb12f7571e094746",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=500&size=130"
          },
          {
              id: "ec0d95e6a9a340fd19b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=501&size=130"
          },
          {
              id: "07287dc34186a8d8f197",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=502&size=130"
          },
          {
              id: "74f80f133356da088347",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=503&size=130"
          },
          {
              id: "89effd04c141281f7150",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=504&size=130"
          },
          {
              id: "d1fa50126c578509dc46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=525&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker677991bbadfe44a01def.png",
      price: "Miễn phí",
      name: "Oko Bear",
      thumbImg: "https://zalo-api.zadn.vn/c/8/b/9/a/10362/preview/oko_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/8/b/9/a/10362/icon_pre/oko_thumb.png",
      id: "f9730fb133f4daaa83e5",
      source: "Caerux Co.,Ltd",
      totalImage: "20 sticker",
      value: [
          {
              id: "9c02afbf93fa7aa423eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22463&size=130"
          },
          {
              id: "ada4e119dd5c34026d4d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22464&size=130"
          },
          {
              id: "ec68a1d59d9074ce2d81",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22465&size=130"
          },
          {
              id: "bc3cf281cec4279a7ed5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22466&size=130"
          },
          {
              id: "8d45c2f8febd17e34eac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22467&size=130"
          },
          {
              id: "ee33a68e9acb73952ada",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22468&size=130"
          },
          {
              id: "47620edf329adbc4828b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22469&size=130"
          },
          {
              id: "b463fedec29b2bc5728a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22470&size=130"
          },
          {
              id: "edd7a66a9a2f73712a3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22471&size=130"
          },
          {
              id: "e73da3809fc5769b2fd4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22472&size=130"
          },
          {
              id: "ff6fbad286976fc93686",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22473&size=130"
          },
          {
              id: "3420729d4ed8a786fec9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22474&size=130"
          },
          {
              id: "2133668e5acbb395eada",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22475&size=130"
          },
          {
              id: "436e03d33f96d6c88f87",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22476&size=130"
          },
          {
              id: "8199c024fc61153f4c70",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22477&size=130"
          },
          {
              id: "8373c1cefd8b14d54d9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22478&size=130"
          },
          {
              id: "6aea29571512fc4ca503",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22479&size=130"
          },
          {
              id: "9f19c3a4ffe116bf4ff0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22480&size=130"
          },
          {
              id: "73b12e0c1249fb17a258",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22481&size=130"
          },
          {
              id: "4ea910142c51c50f9c40",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22482&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker77d11c132056c9089047.png",
      price: "Miễn phí",
      name: "Pikalong và Đồng bọn",
      thumbImg: "https://zalo-api.zadn.vn/e/6/d/7/6/10471/preview/pikagroup_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/e/6/d/7/6/10471/icon_pre/pikagroup_thumb.png",
      id: "bb8fd04dec0805565c19",
      source: "",
      totalImage: "14 sticker",
      value: [
          {
              id: "bb3aab8d97c87e9627d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23964&size=130"
          },
          {
              id: "270736b00af5e3abbae4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23965&size=130"
          },
          {
              id: "8b6799d0a5954ccb1584",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23966&size=130"
          },
          {
              id: "2acd397a053fec61b52e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23967&size=130"
          },
          {
              id: "82e3ae5492117b4f2200",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23968&size=130"
          },
          {
              id: "aba28615ba50530e0a41",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23969&size=130"
          },
          {
              id: "6fc441737d369468cd27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23970&size=130"
          },
          {
              id: "8879a7ce9b8b72d52b9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23971&size=130"
          },
          {
              id: "9217baa086e56fbb36f4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23972&size=130"
          },
          {
              id: "55ae7c19405ca902f04d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23973&size=130"
          },
          {
              id: "2a4f00f83cbdd5e38cac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23974&size=130"
          },
          {
              id: "c9c2e275de30376e6e21",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23975&size=130"
          },
          {
              id: "d43df08acccf25917cde",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23976&size=130"
          },
          {
              id: "4b476ef052b5bbebe2a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23977&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker43757ab646f3afadf6e2.png",
      price: "Miễn phí",
      name: "Đậu Đậu",
      thumbImg: "https://zalo-api.zadn.vn/8/0/5/6/8/10677/preview/daudau_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/8/0/5/6/8/10677/icon_pre/daudau_thumb.png",
      id: "ac2a95e9a9ac40f219bd",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "31fbce76f2331b6d4222",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26483&size=130"
          },
          {
              id: "3f7cc7f1fbb412ea4ba5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26484&size=130"
          },
          {
              id: "e9b7103a2c7fc5219c6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26485&size=130"
          },
          {
              id: "3516cf9bf3de1a8043cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26486&size=130"
          },
          {
              id: "15fbee76d2333b6d6222",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26487&size=130"
          },
          {
              id: "a79c53116f54860adf45",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26488&size=130"
          },
          {
              id: "90dd65505915b04be904",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26489&size=130"
          },
          {
              id: "e9bd1f302375ca2b9364",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26490&size=130"
          },
          {
              id: "f69801153d50d40e8d41",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26491&size=130"
          },
          {
              id: "8cea7c674022a97cf033",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26492&size=130"
          },
          {
              id: "d93628bb14fefda0a4ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26493&size=130"
          },
          {
              id: "776985e4b9a150ff09b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26494&size=130"
          },
          {
              id: "fa1a099735d2dc8c85c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26495&size=130"
          },
          {
              id: "293125bc19f9f0a7a9e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26496&size=130"
          },
          {
              id: "580c558169c4809ad9d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26497&size=130"
          },
          {
              id: "34953a18065def03b64c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26498&size=130"
          },
          {
              id: "b108be8582c06b9e32d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26499&size=130"
          },
          {
              id: "ff40f7cdcb8822d67b99",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26500&size=130"
          },
          {
              id: "614068cd5488bdd6e499",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26501&size=130"
          },
          {
              id: "cf7bc5f6f9b310ed49a2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26502&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker14def61dca5823067a49.png",
      price: "Miễn phí",
      name: "Củ Hành 2",
      thumbImg: "https://zalo-api.zadn.vn/7/e/5/1/d/10606/preview/cuhanh2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/e/5/1/d/10606/icon_pre/cuhanh2_thumb.png",
      id: "3c52de91e2d40b8a52c5",
      source: "",
      totalImage: "12 sticker",
      value: [
          {
              id: "3177f0f9ccbc25e27cad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25677&size=130"
          },
          {
              id: "a9c16b4f570abe54e71b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25678&size=130"
          },
          {
              id: "60aca3229f6776392f76",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25679&size=130"
          },
          {
              id: "eb85370b0b4ee210bb5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25680&size=130"
          },
          {
              id: "6285bf0b834e6a10335f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25681&size=130"
          },
          {
              id: "0703d98de5c80c9655d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25682&size=130"
          },
          {
              id: "e99c36120a57e309ba46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25683&size=130"
          },
          {
              id: "82595ad766928fccd683",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25684&size=130"
          },
          {
              id: "7a85a30b9f4e76102f5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25685&size=130"
          },
          {
              id: "9d4d47c37b8692d8cb97",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25686&size=130"
          },
          {
              id: "f5442eca128ffbd1a29e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25687&size=130"
          },
          {
              id: "0945ddcbe18e08d0519f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25688&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker23eded2fd16a3834617b.png",
      price: "Miễn phí",
      name: "Foxie Cáo Siêu Nhắng",
      thumbImg: "https://zalo-api.zadn.vn/9/0/c/3/4/10306/preview/fox_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/9/0/c/3/4/10306/icon_pre/fox_thumb.png",
      id: "1ea8d06aec2f05715c3e",
      source: "Artbox Friends.",
      totalImage: "19 sticker",
      value: [
          {
              id: "1f038fbdb3f85aa603e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21532&size=130"
          },
          {
              id: "ad743cca008fe9d1b09e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21533&size=130"
          },
          {
              id: "e1e4735a4f1fa641ff0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21534&size=130"
          },
          {
              id: "0d579ee9a2ac4bf212bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21535&size=130"
          },
          {
              id: "f8d75469682c8172d83d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21536&size=130"
          },
          {
              id: "28d4856ab92f5071093e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21537&size=130"
          },
          {
              id: "1773b9cd85886cd63599",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21538&size=130"
          },
          {
              id: "d924769a4adfa381face",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21539&size=130"
          },
          {
              id: "f4505cee60ab89f5d0ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21540&size=130"
          },
          {
              id: "f3c45a7a663f8f61d62e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21541&size=130"
          },
          {
              id: "568ffc31c074292a7065",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21542&size=130"
          },
          {
              id: "b2ef19512514cc4a9505",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21543&size=130"
          },
          {
              id: "d30a77b44bf1a2affbe0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21544&size=130"
          },
          {
              id: "e4ac41127d579409cd46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21545&size=130"
          },
          {
              id: "6c2eca90f6d51f8b46c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21546&size=130"
          },
          {
              id: "c93a6e8452c1bb9fe2d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21547&size=130"
          },
          {
              id: "0473a4cd988871d62899",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21548&size=130"
          },
          {
              id: "e26443da7f9f96c1cf8e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21549&size=130"
          },
          {
              id: "ecb94e0772429b1cc253",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21550&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker3791dc53e01609485007.png",
      price: "Miễn phí",
      name: "Con Mả Con Ma",
      thumbImg: "https://zalo-api.zadn.vn/8/a/e/7/3/10343/preview/ma_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/8/a/e/7/3/10343/icon_pre/ma_thumb.png",
      id: "d3e038220467ed39b476",
      source: "Con Mả Con Ma",
      totalImage: "27 sticker",
      value: [
          {
              id: "26851a39267ccf22966d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22192&size=130"
          },
          {
              id: "ed9ad026ec63053d5c72",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22193&size=130"
          },
          {
              id: "83a7bd1b815e6800314f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22194&size=130"
          },
          {
              id: "dc85e339df7c36226f6d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22195&size=130"
          },
          {
              id: "ca4ff2f3ceb627e87ea7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22196&size=130"
          },
          {
              id: "d3c1ea7dd6383f666629",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22197&size=130"
          },
          {
              id: "a7c49d78a13d4863112c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22198&size=130"
          },
          {
              id: "9562aede929b7bc5228a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22199&size=130"
          },
          {
              id: "7a0a4eb672f39badc2e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22200&size=130"
          },
          {
              id: "8594b0288c6d65333c7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22201&size=130"
          },
          {
              id: "4586733a4f7fa621ff6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22202&size=130"
          },
          {
              id: "2d3e1a8226c7cf9996d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22203&size=130"
          },
          {
              id: "8238b2848ec1679f3ed0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22204&size=130"
          },
          {
              id: "3cd30d6f312ad874813b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22205&size=130"
          },
          {
              id: "e74bd5f7e9b200ec59a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22206&size=130"
          },
          {
              id: "485f7be347a6aef8f7b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22207&size=130"
          },
          {
              id: "5ec0127c2e39c7679e28",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22208&size=130"
          },
          {
              id: "8f4fc2f3feb617e84ea7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22209&size=130"
          },
          {
              id: "21026fbe53fbbaa5e3ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22210&size=130"
          },
          {
              id: "562f199325d6cc8895c7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22211&size=130"
          },
          {
              id: "9a2dd291eed4078a5ec5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22212&size=130"
          },
          {
              id: "dbe0925cae1947471e08",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22213&size=130"
          },
          {
              id: "565e1ce220a7c9f990b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22214&size=130"
          },
          {
              id: "8b4dc0f1fcb415ea4ca5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22215&size=130"
          },
          {
              id: "13b7570b6b4e8210db5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22216&size=130"
          },
          {
              id: "e16ca4d0989571cb2884",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22217&size=130"
          },
          {
              id: "f2c8b4748831616f3820",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22218&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickere42609e535a0dcfe85b1.png",
      price: "Miễn phí",
      name: "Wow! Bất Ngờ Quá",
      thumbImg: "https://zalo-api.zadn.vn/d/5/3/0/d/10593/preview/wow_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/5/3/0/d/10593/icon_pre/wow_thumb.png",
      id: "add740147c51950fcc40",
      source: "",
      totalImage: "17 sticker",
      value: [
          {
              id: "acfa7e734236ab68f227",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25438&size=130"
          },
          {
              id: "66a6b52f896a6034397b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25439&size=130"
          },
          {
              id: "1882f40bc84e2110785f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25440&size=130"
          },
          {
              id: "4693ab1a975f7e01274e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25441&size=130"
          },
          {
              id: "2739c9b0f5f51cab45e4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25442&size=130"
          },
          {
              id: "9d3072b94efca7a2feed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25443&size=130"
          },
          {
              id: "84556cdc5099b9c7e088",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25444&size=130"
          },
          {
              id: "684181c8bd8d54d30d9c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25445&size=130"
          },
          {
              id: "df5135d8099de0c3b98c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25446&size=130"
          },
          {
              id: "b08a5b0367468e18d757",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25447&size=130"
          },
          {
              id: "f4f4107d2c38c5669c29",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25448&size=130"
          },
          {
              id: "3d75d8fce4b90de754a8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25449&size=130"
          },
          {
              id: "1e0df884c4c12d9f74d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25450&size=130"
          },
          {
              id: "3da1da28e66d0f33567c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25451&size=130"
          },
          {
              id: "69918918b55d5c03054c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25452&size=130"
          },
          {
              id: "be9d5f1463518a0fd340",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25453&size=130"
          },
          {
              id: "76b5943ca87941271868",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25454&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker008d77404b05a25bfb14.png",
      price: "Miễn phí",
      name: "Rồng Vàng",
      thumbImg: "https://zalo-api.zadn.vn/d/8/7/d/3/10235/preview/rong_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/8/7/d/3/10235/icon_pre/rong_thumb.png",
      id: "ebd49c19a05c4902104d",
      source: "Thăng Fly Comics",
      totalImage: "27 sticker",
      value: [
          {
              id: "d0bc331b0f5ee600bf4f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19823&size=130"
          },
          {
              id: "dafd265a1a1ff341aa0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19824&size=130"
          },
          {
              id: "a6815b2667638e3dd772",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19825&size=130"
          },
          {
              id: "39b1c716fb53120d4b42",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19826&size=130"
          },
          {
              id: "f7f708503415dd4b8404",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19827&size=130"
          },
          {
              id: "05e5fd42c10728597116",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19828&size=130"
          },
          {
              id: "2f69d6ceea8b03d55a9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19829&size=130"
          },
          {
              id: "5a81a0269c63753d2c72",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19830&size=130"
          },
          {
              id: "69dd927aae3f47611e2e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19831&size=130"
          },
          {
              id: "30cec469f82c1172483d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19832&size=130"
          },
          {
              id: "771882bfbefa57a40eeb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19833&size=130"
          },
          {
              id: "9225648258c7b199e8d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19834&size=130"
          },
          {
              id: "0174f6d3ca9623c87a87",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19835&size=130"
          },
          {
              id: "7b878b20b7655e3b0774",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19836&size=130"
          },
          {
              id: "ffd80e7f323adb64822b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19837&size=130"
          },
          {
              id: "ff720dd53190d8ce8181",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19838&size=130"
          },
          {
              id: "361fc5b8f9fd10a349ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19839&size=130"
          },
          {
              id: "90039ca4a0e149bf10f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19840&size=130"
          },
          {
              id: "326308c43481dddf8490",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19894&size=130"
          },
          {
              id: "6bc650616c24857adc35",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19895&size=130"
          },
          {
              id: "9ea3aa0496417f1f2650",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19896&size=130"
          },
          {
              id: "b95d8cfab0bf59e100ae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19897&size=130"
          },
          {
              id: "d42ce28bdece37906edf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19898&size=130"
          },
          {
              id: "4b8d7c2a406fa931f07e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19899&size=130"
          },
          {
              id: "14f9245e181bf145a80a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19900&size=130"
          },
          {
              id: "3cc00d673122d87c8133",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19901&size=130"
          },
          {
              id: "eb01d9a6e5e30cbd55f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19902&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerf46755a569e080bed9f1.png",
      price: "Miễn phí",
      name: "Coco & Bulla",
      thumbImg: "https://zalo-api.zadn.vn/7/e/8/9/a/10285/preview/coco_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/e/8/9/a/10285/icon_pre/coco_thumb.png",
      id: "f30452c66e8387ddde92",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "a471bbc9878c6ed2379d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21139&size=130"
          },
          {
              id: "fb9be323df6636386f77",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21140&size=130"
          },
          {
              id: "ea2ff397cfd2268c7fc3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21141&size=130"
          },
          {
              id: "30882a301675ff2ba664",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21142&size=130"
          },
          {
              id: "768f6d375172b82ce163",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21143&size=130"
          },
          {
              id: "6d0979b145f4acaaf5e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21144&size=130"
          },
          {
              id: "a2abb7138b5662083b47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21145&size=130"
          },
          {
              id: "d47ec2c6fe8317dd4e92",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21146&size=130"
          },
          {
              id: "dce7cb5ff71a1e44470b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21147&size=130"
          },
          {
              id: "cf57dfefe3aa0af453bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21148&size=130"
          },
          {
              id: "af5fbee782a26bfc32b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21149&size=130"
          },
          {
              id: "719a63225f67b639ef76",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21150&size=130"
          },
          {
              id: "e165f2ddce9827c67e89",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21151&size=130"
          },
          {
              id: "92eabe5282176b493206",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21152&size=130"
          },
          {
              id: "a9fc8444b801515f0810",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21153&size=130"
          },
          {
              id: "6cf142497e0c9752ce1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21154&size=130"
          },
          {
              id: "84bbab0397467e182757",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21155&size=130"
          },
          {
              id: "f047d8ffe4ba0de454ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21156&size=130"
          },
          {
              id: "b74b9ef3a2b64be812a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21157&size=130"
          },
          {
              id: "71a55b1d67588e06d749",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21158&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker36dadc18e05d0903504c.png",
      price: "Miễn phí",
      name: "Fuku Mập Ú",
      thumbImg: "https://zalo-api.zadn.vn/7/b/3/5/6/10342/preview/fuku_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/b/3/5/6/10342/icon_pre/fuku_thumb.png",
      id: "f87a12b82efdc7a39eec",
      source: "Caerux Co.,Ltd.",
      totalImage: "20 sticker",
      value: [
          {
              id: "9c728cceb08b59d5009a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22172&size=130"
          },
          {
              id: "e60bf7b7cbf222ac7be3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22173&size=130"
          },
          {
              id: "d020c29cfed917874ec8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22174&size=130"
          },
          {
              id: "c94adaf6e6b30fed56a2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22175&size=130"
          },
          {
              id: "c642eafed6bb3fe566aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22176&size=130"
          },
          {
              id: "d63dfb81c7c42e9a77d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22177&size=130"
          },
          {
              id: "62c64c7a703f9961c02e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22178&size=130"
          },
          {
              id: "aafa8546b903505d0912",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22179&size=130"
          },
          {
              id: "a8358089bccc55920cdd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22180&size=130"
          },
          {
              id: "ff04d6b8eafd03a35aec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22181&size=130"
          },
          {
              id: "8784ad38917d7823216c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22182&size=130"
          },
          {
              id: "79fa52466e03875dde12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22183&size=130"
          },
          {
              id: "661842a47ee197bfcef0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22184&size=130"
          },
          {
              id: "af1d8aa1b6e45fba06f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22185&size=130"
          },
          {
              id: "1cb53a09064cef12b65d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22186&size=130"
          },
          {
              id: "18de3f620327ea79b336",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22187&size=130"
          },
          {
              id: "cc37ec8bd0ce399060df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22188&size=130"
          },
          {
              id: "3c651dd9219cc8c2918d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22189&size=130"
          },
          {
              id: "2fc60d7a313fd861812e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22190&size=130"
          },
          {
              id: "edd3ce6ff22a1b74423b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22191&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd66197acabe942b71bf8.png",
      price: "Miễn phí",
      name: "Luci & Daisy",
      thumbImg: "https://zalo-api.zadn.vn/6/2/1/7/b/10189/preview/daisyluci_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/6/2/1/7/b/10189/icon_pre/daisyluci_thumb.png",
      id: "75ae34630826e178b837",
      source: "Zalo Studio",
      totalImage: "25 sticker",
      value: [
          {
              id: "a2d672764e33a76dfe22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19036&size=130"
          },
          {
              id: "e54734e708a2e1fcb8b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19037&size=130"
          },
          {
              id: "970845a879ed90b3c9fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19038&size=130"
          },
          {
              id: "bff36c535016b948e007",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19039&size=130"
          },
          {
              id: "5957b5f789b260ec39a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19040&size=130"
          },
          {
              id: "9bb876184a5da303fa4c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19041&size=130"
          },
          {
              id: "ea5c04fc38b9d1e788a8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19042&size=130"
          },
          {
              id: "4045afe593a07afe23b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19043&size=130"
          },
          {
              id: "e3af0b0f374ade14875b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19044&size=130"
          },
          {
              id: "dada337a0f3fe661bf2e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19045&size=130"
          },
          {
              id: "ddae370e0b4be215bb5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19046&size=130"
          },
          {
              id: "b3c7586764228d7cd433",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19047&size=130"
          },
          {
              id: "954971e94daca4f2fdbd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19048&size=130"
          },
          {
              id: "71819421a864413a1875",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19049&size=130"
          },
          {
              id: "d43032900ed5e78bbec4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19050&size=130"
          },
          {
              id: "4b8dac2d906879362079",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19051&size=130"
          },
          {
              id: "dbc53b650720ee7eb731",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19052&size=130"
          },
          {
              id: "d38832280e6de733be7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19053&size=130"
          },
          {
              id: "f9221b8227c7ce9997d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19054&size=130"
          },
          {
              id: "c42327831bc6f298abd7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19055&size=130"
          },
          {
              id: "8c1870b84cfda5a3fcec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19056&size=130"
          },
          {
              id: "e90d14ad28e8c1b698f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19057&size=130"
          },
          {
              id: "5fbfa11f9d5a74042d4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19058&size=130"
          },
          {
              id: "155aeafad6bf3fe166ae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19059&size=130"
          },
          {
              id: "707b88dbb49e5dc0048f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19060&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker66f88a3bb67e5f20066f.png",
      price: "Miễn phí",
      name: "Ngày Không Vui",
      thumbImg: "https://zalo-api.zadn.vn/f/6/c/2/a/10592/preview/buon_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/f/6/c/2/a/10592/icon_pre/buon_thumb.png",
      id: "48cda40e984b7115285a",
      source: "",
      totalImage: "22 sticker",
      value: [
          {
              id: "1777d3feefbb06e55faa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25416&size=130"
          },
          {
              id: "94dc51556d10844edd01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25417&size=130"
          },
          {
              id: "baa77c2e406ba935f07a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25418&size=130"
          },
          {
              id: "2101e688dacd33936adc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25419&size=130"
          },
          {
              id: "3739f7b0cbf522ab7be4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25420&size=130"
          },
          {
              id: "69a7a82e946b7d35247a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25421&size=130"
          },
          {
              id: "88264aaf76ea9fb4c6fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25422&size=130"
          },
          {
              id: "ba9d79144551ac0ff540",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25423&size=130"
          },
          {
              id: "6c4eb0c78c8265dc3c93",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25424&size=130"
          },
          {
              id: "aaf077794b3ca262fb2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25425&size=130"
          },
          {
              id: "2858f6d1ca9423ca7a85",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25426&size=130"
          },
          {
              id: "d1020e8b32cedb9082df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25427&size=130"
          },
          {
              id: "4c109499a8dc418218cd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25428&size=130"
          },
          {
              id: "fab6233f1f7af624af6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25429&size=130"
          },
          {
              id: "2986f30fcf4a26147f5b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25430&size=130"
          },
          {
              id: "ebe4306d0c28e576bc39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25431&size=130"
          },
          {
              id: "a71b73924fd7a689ffc6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25432&size=130"
          },
          {
              id: "2a80ff09c34c2a12735d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25433&size=130"
          },
          {
              id: "c5d3135a2f1fc6419f0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25434&size=130"
          },
          {
              id: "1609c180fdc5149b4dd4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25435&size=130"
          },
          {
              id: "dc190c9030d5d98b80c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25436&size=130"
          },
          {
              id: "79f2a87b943e7d60242f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25437&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickercbc6970bab4e42101b5f.png",
      price: "Miễn phí",
      name: "The White Angels",
      thumbImg: "https://zalo-api.zadn.vn/b/e/6/2/9/10192/preview/aff_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/b/e/6/2/9/10192/icon_pre/aff_thumb.png",
      id: "20a37c6e402ba975f03a",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "9937b2978ed2678c3ec3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19111&size=130"
          },
          {
              id: "23c607663b23d27d8b32",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19112&size=130"
          },
          {
              id: "59157cb540f0a9aef0e1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19113&size=130"
          },
          {
              id: "df30f990c5d52c8b75c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19114&size=130"
          },
          {
              id: "528075204965a03bf974",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19115&size=130"
          },
          {
              id: "ed29cd89f1cc189241dd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19116&size=130"
          },
          {
              id: "c0c5e165dd20347e6d31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19117&size=130"
          },
          {
              id: "82c1a0619c24757a2c35",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19118&size=130"
          },
          {
              id: "534c70ec4ca9a5f7fcb8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19119&size=130"
          },
          {
              id: "8b7db7dd8b9862c63b89",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19120&size=130"
          },
          {
              id: "9ad7a7779b32726c2b23",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19121&size=130"
          },
          {
              id: "568b682b546ebd30e47f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19122&size=130"
          },
          {
              id: "62d65d766133886dd122",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19123&size=130"
          },
          {
              id: "a9d89178ad3d44631d2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19124&size=130"
          },
          {
              id: "405f79ff45baace4f5ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19125&size=130"
          },
          {
              id: "8eefb44f880a6154381b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19126&size=130"
          },
          {
              id: "422e798e45cbac95f5da",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19127&size=130"
          },
          {
              id: "f9d0cd70f135186b4124",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19128&size=130"
          },
          {
              id: "042431840dc1e49fbdd0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19129&size=130"
          },
          {
              id: "116827c81b8df2d3ab9c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19130&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker5026bce480a169ff30b0.png",
      price: "Miễn phí",
      name: "Vàng Bựa",
      thumbImg: "https://zalo-api.zadn.vn/7/6/c/6/c/10336/preview/vangbua_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/6/c/6/c/10336/icon_pre/vangbua_thumb.png",
      id: "8956659459d1b08fe9c0",
      source: "Caerux Co.,Ltd.",
      totalImage: "25 sticker",
      value: [
          {
              id: "ada7021b3e5ed7008e4f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22051&size=130"
          },
          {
              id: "6063c8dff49a1dc4448b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22052&size=130"
          },
          {
              id: "8ca7251b195ef000a94f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22053&size=130"
          },
          {
              id: "23bb8907b5425c1c0553",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22054&size=130"
          },
          {
              id: "26f88d44b101585f0110",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22055&size=130"
          },
          {
              id: "550bf1b7cdf224ac7de3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22056&size=130"
          },
          {
              id: "4127e49bd8de318068cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22057&size=130"
          },
          {
              id: "bc5d1ae126a4cffa96b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22058&size=130"
          },
          {
              id: "28a78f1bb35e5a00034f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22059&size=130"
          },
          {
              id: "9a753ac9068cefd2b69d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22060&size=130"
          },
          {
              id: "0ba2aa1e965b7f05264a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22061&size=130"
          },
          {
              id: "aed70c6b302ed970803f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22062&size=130"
          },
          {
              id: "410de2b1def437aa6ee5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22063&size=130"
          },
          {
              id: "28f59449a80c4152181d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22064&size=130"
          },
          {
              id: "e7505aec66a98ff7d6b8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22065&size=130"
          },
          {
              id: "1c4ba2f79eb277ec2ea3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22066&size=130"
          },
          {
              id: "a2c91d752130c86e9121",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22067&size=130"
          },
          {
              id: "507fe8c3d4863dd86497",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22068&size=130"
          },
          {
              id: "2be59259ae1c47421e0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22069&size=130"
          },
          {
              id: "bf34058839cdd09389dc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22070&size=130"
          },
          {
              id: "999d22211e64f73aae75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22071&size=130"
          },
          {
              id: "8cf7384b040eed50b41f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22072&size=130"
          },
          {
              id: "d3df66635a26b378ea37",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22073&size=130"
          },
          {
              id: "240892b4aef147af1ee0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22074&size=130"
          },
          {
              id: "6568d2d4ee9107cf5e80",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22075&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker41cf920cae4947171e58.png",
      price: "Miễn phí",
      name: "Vui Ghê",
      thumbImg: "https://zalo-api.zadn.vn/d/d/4/7/2/10591/preview/haha_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/d/4/7/2/10591/icon_pre/haha_thumb.png",
      id: "a46477a74be2a2bcfbf3",
      source: "",
      totalImage: "14 sticker",
      value: [
          {
              id: "73b9c530f975102b4964",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25402&size=130"
          },
          {
              id: "4718f091ccd4258a7cc5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25403&size=130"
          },
          {
              id: "b5a4052d3968d0368979",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25404&size=130"
          },
          {
              id: "64d6d55fe91a0044590b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25405&size=130"
          },
          {
              id: "4beef967c5222c7c7533",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25406&size=130"
          },
          {
              id: "eec15d48610d8853d11c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25407&size=130"
          },
          {
              id: "0fb6c33fff7a16244f6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25408&size=130"
          },
          {
              id: "9afd57746b31826fdb20",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25409&size=130"
          },
          {
              id: "07cfc946f5031c5d4512",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25410&size=130"
          },
          {
              id: "9c96531f6f5a8604df4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25411&size=130"
          },
          {
              id: "1b23d3aaefef06b15ffe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25412&size=130"
          },
          {
              id: "e4c62d4f110af854a11b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25413&size=130"
          },
          {
              id: "8cf6467f7a3a9364ca2b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25414&size=130"
          },
          {
              id: "d7c31c4a200fc951901e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25415&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickera38f9d4ca10948571118.png",
      price: "Miễn phí",
      name: "Tây Và Đồng Bọn",
      thumbImg: "https://zalo-api.zadn.vn/8/5/f/f/b/10674/preview/vaccomic2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/8/5/f/f/b/10674/icon_pre/vaccomic2_thumb.png",
      id: "34840a473602df5c8613",
      source: " V.A.C Comic",
      totalImage: "25 sticker",
      value: [
          {
              id: "42518adcb6995fc70688",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26436&size=130"
          },
          {
              id: "e14128cc1489fdd7a498",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26437&size=130"
          },
          {
              id: "f5c73f4a030fea51b31e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26438&size=130"
          },
          {
              id: "a1056a8856cdbf93e6dc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26439&size=130"
          },
          {
              id: "7753b3de8f9b66c53f8a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26440&size=130"
          },
          {
              id: "a77462f95ebcb7e2eead",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26441&size=130"
          },
          {
              id: "75ccb3418f04665a3f15",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26442&size=130"
          },
          {
              id: "00fec773fb3612684b27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26443&size=130"
          },
          {
              id: "843044bd78f891a6c8e9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26444&size=130"
          },
          {
              id: "d0cd11402d05c45b9d14",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26445&size=130"
          },
          {
              id: "df251da821edc8b391fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26446&size=130"
          },
          {
              id: "2666e5ebd9ae30f069bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26447&size=130"
          },
          {
              id: "a56e79e345a6acf8f5b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26448&size=130"
          },
          {
              id: "99ba44377872912cc863",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26449&size=130"
          },
          {
              id: "76cfa84294077d592416",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26450&size=130"
          },
          {
              id: "ebed34600825e17bb834",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26451&size=130"
          },
          {
              id: "1f40c7cdfb8812d64b99",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26452&size=130"
          },
          {
              id: "d9a7002a3c6fd5318c7e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26453&size=130"
          },
          {
              id: "980d42807ec5979bced4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26454&size=130"
          },
          {
              id: "edec36610a24e37aba35",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26455&size=130"
          },
          {
              id: "c98b1d062143c81d9152",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26456&size=130"
          },
          {
              id: "a0ed75604925a07bf934",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26457&size=130"
          },
          {
              id: "907046fd7ab893e6caa9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26458&size=130"
          },
          {
              id: "1a27cdaaf1ef18b141fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26459&size=130"
          },
          {
              id: "14c8c445f800115e4811",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26460&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickerdf2868c25487bdd9e496.png",
      price: "Miễn phí",
      name: "Củ Hành",
      thumbImg: "https://zalo-api.zadn.vn/0/9/3/f/6/59/preview/cuhanh_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/0/9/3/f/6/59/icon_pre/cuhanh_thumb.png",
      id: "cd297ac34686afd8f697",
      source: "",
      totalImage: "34 sticker",
      value: [
          {
              id: "46a5d34aef0f06515f1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1305&size=130"
          },
          {
              id: "3594a37b9f3e76602f2f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1306&size=130"
          },
          {
              id: "650ef2e1cea427fa7eb5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1307&size=130"
          },
          {
              id: "675af7b5cbf022ae7be1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1308&size=130"
          },
          {
              id: "20e4b10b8d4e64103d5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1309&size=130"
          },
          {
              id: "cdf15f1e635b8a05d34a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1310&size=130"
          },
          {
              id: "cad5593a657f8c21d56e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1311&size=130"
          },
          {
              id: "8d4f21a01de5f4bbadf4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1312&size=130"
          },
          {
              id: "0b31a6de9a9b73c52a8a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1313&size=130"
          },
          {
              id: "5a2df4c2c88721d97896",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1314&size=130"
          },
          {
              id: "4349eca6d0e339bd60f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1315&size=130"
          },
          {
              id: "f6e55e0a624f8b11d25e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1316&size=130"
          },
          {
              id: "bffc16132a56c3089a47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1317&size=130"
          },
          {
              id: "6efec411f854110a4845",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1318&size=130"
          },
          {
              id: "eb9e40717c34956acc25",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1319&size=130"
          },
          {
              id: "931437fb0bbee2e0bbaf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1320&size=130"
          },
          {
              id: "b1d71438287dc123986c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1321&size=130"
          },
          {
              id: "a62600c93c8cd5d28c9d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1322&size=130"
          },
          {
              id: "77dfd030ec75052b5c64",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1323&size=130"
          },
          {
              id: "1377b3988fdd66833fcc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1324&size=130"
          },
          {
              id: "f91858f764b28decd4a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1325&size=130"
          },
          {
              id: "f3d2513d6d788426dd69",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1326&size=130"
          },
          {
              id: "dac879274562ac3cf573",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1327&size=130"
          },
          {
              id: "b50009ef35aadcf485bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1328&size=130"
          },
          {
              id: "6c36d1d9ed9c04c25d8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1329&size=130"
          },
          {
              id: "ac3712d82e9dc7c39e8c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1330&size=130"
          },
          {
              id: "fa3a45d5799090cec981",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1331&size=130"
          },
          {
              id: "dcf0641f585ab104e84b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1332&size=130"
          },
          {
              id: "d5046ceb50aeb9f0e0bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1333&size=130"
          },
          {
              id: "a6121cfd20b8c9e690a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1334&size=130"
          },
          {
              id: "7686cd69f12c1872413d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1335&size=130"
          },
          {
              id: "bdc1092e356bdc35857a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1336&size=130"
          },
          {
              id: "56e2e30ddf4836166f59",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1337&size=130"
          },
          {
              id: "8c6c3a8306c6ef98b6d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1338&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd20b26c61a83f3ddaa92.png",
      price: "Miễn phí",
      name: "Mèo Mumu",
      thumbImg: "https://zalo-api.zadn.vn/a/b/f/0/9/10104/preview/meo_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/a/b/f/0/9/10104/icon_pre/meo_thumb.png",
      id: "4415b0d88c9d65c33c8c",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "974730e90cace5f2bcbd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17451&size=130"
          },
          {
              id: "246a84c4b88151df0890",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17452&size=130"
          },
          {
              id: "49d9e877d4323d6c6423",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17453&size=130"
          },
          {
              id: "396b9bc5a7804ede1791",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17454&size=130"
          },
          {
              id: "ef874c29706c9932c07d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17455&size=130"
          },
          {
              id: "2a289686aac3439d1ad2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17456&size=130"
          },
          {
              id: "0290bf3e837b6a25336a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17457&size=130"
          },
          {
              id: "6549dbe7e7a20efc57b3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17458&size=130"
          },
          {
              id: "fddd42737e369768ce27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17459&size=130"
          },
          {
              id: "f46d4cc3708699d8c097",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17460&size=130"
          },
          {
              id: "a16518cb248ecdd0949f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17461&size=130"
          },
          {
              id: "3c4886e6baa353fd0ab2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17462&size=130"
          },
          {
              id: "2fea9444a801415f1810",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17463&size=130"
          },
          {
              id: "e61852b66ef387addee2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17464&size=130"
          },
          {
              id: "0339b6978ad2638c3ac3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17465&size=130"
          },
          {
              id: "0eb6b818845d6d03344c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17466&size=130"
          },
          {
              id: "509fe731db74322a6b65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17467&size=130"
          },
          {
              id: "552ee580d9c5309b69d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17468&size=130"
          },
          {
              id: "052db48388c6619838d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17469&size=130"
          },
          {
              id: "416ff3c1cf8426da7f95",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17470&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker117c24bf18faf1a4a8eb.png",
      price: "Miễn phí",
      name: "Thiều Bảo Trâm - 360mobi",
      thumbImg: "https://zalo-api.zadn.vn/b/7/0/d/1/10681/preview/thieubaotram_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/b/7/0/d/1/10681/icon_pre/thieubaotram_thumb.png",
      id: "6f0e5acd66888fd6d699",
      source: "",
      totalImage: "21 sticker",
      value: [
          {
              id: "31c1794c4509ac57f518",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26564&size=130"
          },
          {
              id: "a144e8c9d48c3dd2649d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26565&size=130"
          },
          {
              id: "5fb915342971c02f9960",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26566&size=130"
          },
          {
              id: "c9d48259be1c57420e0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26567&size=130"
          },
          {
              id: "103a54b768f281acd8e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26568&size=130"
          },
          {
              id: "ee70abfd97b87ee627a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26569&size=130"
          },
          {
              id: "9311d59ce9d9008759c8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26570&size=130"
          },
          {
              id: "fb63bcee80ab69f530ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26571&size=130"
          },
          {
              id: "782e38a304e6edb8b4f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26572&size=130"
          },
          {
              id: "5bf41a79263ccf62962d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26573&size=130"
          },
          {
              id: "602c22a11ee4f7baaef5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26574&size=130"
          },
          {
              id: "57f6147b283ec160982f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26575&size=130"
          },
          {
              id: "99b2c53ff97a1024496b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26576&size=130"
          },
          {
              id: "70262dab11eef8b0a1ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26577&size=130"
          },
          {
              id: "8969d7e4eba102ff5bb0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26578&size=130"
          },
          {
              id: "392666ab5aeeb3b0eaff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26579&size=130"
          },
          {
              id: "94a9cc24f061193f4070",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26580&size=130"
          },
          {
              id: "cbc2924fae0a47541e1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26581&size=130"
          },
          {
              id: "01e55b68672d8e73d73c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26582&size=130"
          },
          {
              id: "e58fbe0282476b193256",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26583&size=130"
          },
          {
              id: "54e3006e3c2bd5758c3a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26584&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker38667eab42eeabb0f2ff.png",
      price: "Miễn phí",
      name: "Đậu Đỏ Tung Tăng 1",
      thumbImg: "https://zalo-api.zadn.vn/1/c/f/a/8/10186/preview/daudo1_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/1/c/f/a/8/10186/icon_pre/daudo1_thumb.png",
      id: "c7fb8136bd73542d0d62",
      source: "",
      totalImage: "22 sticker",
      value: [
          {
              id: "5579c7d9fb9c12c24b8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18974&size=130"
          },
          {
              id: "7a0ee9aed5eb3cb565fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18975&size=130"
          },
          {
              id: "27f78b57b7125e4c0703",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18976&size=130"
          },
          {
              id: "c9e264425807b159e816",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18977&size=130"
          },
          {
              id: "269f883fb47a5d24046b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18978&size=130"
          },
          {
              id: "3df09250ae15474b1e04",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18979&size=130"
          },
          {
              id: "6519cdb9f1fc18a241ed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18980&size=130"
          },
          {
              id: "1093b93385766c283567",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18981&size=130"
          },
          {
              id: "40afea0fd64a3f14665b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18982&size=130"
          },
          {
              id: "00f1ab5197147e4a2705",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18983&size=130"
          },
          {
              id: "4aa4ee04d2413b1f6250",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18984&size=130"
          },
          {
              id: "7131d491e8d4018a58c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18985&size=130"
          },
          {
              id: "20c78667ba22537c0a33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18986&size=130"
          },
          {
              id: "49a5ee05d2403b1e6251",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18987&size=130"
          },
          {
              id: "08efa84f940a7d54241b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18988&size=130"
          },
          {
              id: "f49e553e697b8025d96a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18989&size=130"
          },
          {
              id: "34be961eaa5b43051a4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18990&size=130"
          },
          {
              id: "74f7d757eb12024c5b03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18991&size=130"
          },
          {
              id: "378c8b2cb7695e370778",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18992&size=130"
          },
          {
              id: "00f9bd59811c6842310d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18993&size=130"
          },
          {
              id: "50a6ee06d2433b1d6252",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18994&size=130"
          },
          {
              id: "4661f9c1c5842cda7595",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18995&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd069e7aadbef32b16bfe.png",
      price: "Miễn phí",
      name: " Zing Music Awards 2018",
      thumbImg: "https://zalo-api.zadn.vn/d/5/c/8/2/10683/preview/zma18_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/5/c/8/2/10683/icon_pre/zma18_thumb.png",
      id: "b8908f53b3165a480307",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "5fd0205d1c18f546ac09",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26611&size=130"
          },
          {
              id: "5bbf23321f77f629af66",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26612&size=130"
          },
          {
              id: "9514ec99d0dc398260cd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26613&size=130"
          },
          {
              id: "c0c4ba49860c6f52361d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26614&size=130"
          },
          {
              id: "8591fe1cc2592b077248",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26615&size=130"
          },
          {
              id: "29b35d3e617b8825d16a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26616&size=130"
          },
          {
              id: "170d62805ec5b79beed4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26617&size=130"
          },
          {
              id: "1f2469a955ecbcb2e5fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26618&size=130"
          },
          {
              id: "30d3475e7b1b9245cb0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26619&size=130"
          },
          {
              id: "532223af1feaf6b4affb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26620&size=130"
          },
          {
              id: "6ab71b3a277fce21976e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26621&size=130"
          },
          {
              id: "628a10072c42c51c9c53",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26622&size=130"
          },
          {
              id: "3ef54d78713d9863c12c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26623&size=130"
          },
          {
              id: "07c68b44b7015e5f0710",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26624&size=130"
          },
          {
              id: "16ea9b68a72d4e73173c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26625&size=130"
          },
          {
              id: "0f038181bdc4549a0dd5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26626&size=130"
          },
          {
              id: "b58e3a0c0649ef17b658",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26627&size=130"
          },
          {
              id: "e3f86b7a573fbe61e72e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26628&size=130"
          },
          {
              id: "54b6dd34e171082f5160",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26629&size=130"
          },
          {
              id: "63c6e944d5013c5f6510",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=26630&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerf7ff933daf7846261f69.png",
      price: "Miễn phí",
      name: "Ai Cá Hông",
      thumbImg: "https://zalo-api.zadn.vn/2/d/b/3/5/10472/preview/ica_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/d/b/3/5/10472/icon_pre/ica_thumb.png",
      id: "ec2288e0b4a55dfb04b4",
      source: "",
      totalImage: "30 sticker",
      value: [
          {
              id: "d3d1f566c923207d7932",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23978&size=130"
          },
          {
              id: "a76380d4bc9155cf0c80",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23979&size=130"
          },
          {
              id: "295809ef35aadcf485bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23980&size=130"
          },
          {
              id: "d2e3f354cf11264f7f00",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23981&size=130"
          },
          {
              id: "4b9469235566bc38e577",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23982&size=130"
          },
          {
              id: "1bde3869042ced72b43d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23983&size=130"
          },
          {
              id: "21eb1d5c2119c8479108",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23984&size=130"
          },
          {
              id: "6421599665d38c8dd5c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23985&size=130"
          },
          {
              id: "1e2720901cd5f58bacc4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23986&size=130"
          },
          {
              id: "bd848233be7657280e67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23987&size=130"
          },
          {
              id: "77d14f6673239a7dc332",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23988&size=130"
          },
          {
              id: "9abda30a9f4f76112f5e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23989&size=130"
          },
          {
              id: "c8fef249ce0c27527e1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23990&size=130"
          },
          {
              id: "1c7027c71b82f2dcab93",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23991&size=130"
          },
          {
              id: "2b4a1ffd23b8cae693a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23992&size=130"
          },
          {
              id: "31b8040f384ad114885b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23993&size=130"
          },
          {
              id: "1334258319c6f098a9d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23994&size=130"
          },
          {
              id: "b7838034bc71552f0c60",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23995&size=130"
          },
          {
              id: "6bea5b5d67188e46d709",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23996&size=130"
          },
          {
              id: "1d152ca210e7f9b9a0f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23997&size=130"
          },
          {
              id: "dd82ef35d3703a2e6361",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23998&size=130"
          },
          {
              id: "0ebc3d0b014ee810b15f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23999&size=130"
          },
          {
              id: "08fa444d78089156c819",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24000&size=130"
          },
          {
              id: "45b208053440dd1e8451",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24001&size=130"
          },
          {
              id: "86d2c865f4201d7e4431",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24002&size=130"
          },
          {
              id: "1784583364768d28d467",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24003&size=130"
          },
          {
              id: "687720c01c85f5dbac94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24004&size=130"
          },
          {
              id: "ca5a83edbfa856f60fb9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24005&size=130"
          },
          {
              id: "f676bcc1808469da3095",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24006&size=130"
          },
          {
              id: "0e8e4539797c9022c96d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24007&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker3b8ffe4dc2082b567219.png",
      price: "Miễn phí",
      name: "Nhạt",
      thumbImg: "https://zalo-api.zadn.vn/f/0/e/1/f/10313/preview/nhat_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/f/0/e/1/f/10313/icon_pre/nhat_thumb.png",
      id: "862c43ee7fab96f5cfba",
      source: "",
      totalImage: "15 sticker",
      value: [
          {
              id: "9df79049ac0c45521c1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21633&size=130"
          },
          {
              id: "ba7fb4c1888461da3895",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21634&size=130"
          },
          {
              id: "d641d9ffe5ba0ce455ab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21635&size=130"
          },
          {
              id: "9e5b96e5aaa043fe1ab1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21636&size=130"
          },
          {
              id: "61a968175452bd0ce443",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21637&size=130"
          },
          {
              id: "84668ed8b29d5bc3028c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21638&size=130"
          },
          {
              id: "634b68f554b0bdeee4a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21639&size=130"
          },
          {
              id: "927c96c2aa8743d91a96",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21640&size=130"
          },
          {
              id: "850480babcff55a10cee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21641&size=130"
          },
          {
              id: "23d4256a192ff071a93e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21642&size=130"
          },
          {
              id: "0c4a0bf437b1deef87a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21643&size=130"
          },
          {
              id: "15f0154e290bc055991a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21644&size=130"
          },
          {
              id: "8bd28a6cb6295f770638",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21645&size=130"
          },
          {
              id: "79617bdf479aaec4f78b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21646&size=130"
          },
          {
              id: "5e665dd8619d88c3d18c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21647&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker22469e8ba2ce4b9012df.png",
      price: "Miễn phí",
      name: "Nóc Kute - Game iCá",
      thumbImg: "https://zalo-api.zadn.vn/c/6/3/a/5/10032/preview/canoc_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/6/3/a/5/10032/icon_pre/canoc_thumb.png",
      id: "a49818552410cd4e9401",
      source: "",
      totalImage: "16 sticker",
      value: [
          {
              id: "b79bb5318974602a3965",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16526&size=130"
          },
          {
              id: "f1f7f25dce1827467e09",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16527&size=130"
          },
          {
              id: "8b95973fab7a42241b6b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16528&size=130"
          },
          {
              id: "88a39509a94c4012195d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16529&size=130"
          },
          {
              id: "65a27b08474dae13f75c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16530&size=130"
          },
          {
              id: "c836d79cebd902875bc8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16531&size=130"
          },
          {
              id: "8fde9774ab31426f1b20",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16532&size=130"
          },
          {
              id: "ce54d7feebbb02e55baa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16533&size=130"
          },
          {
              id: "db73c1d9fd9c14c24d8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16534&size=130"
          },
          {
              id: "227239d8059decc3b58c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16535&size=130"
          },
          {
              id: "6c4278e844adadf3f4bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16536&size=130"
          },
          {
              id: "6c6c79c64583acddf592",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16537&size=130"
          },
          {
              id: "77ba61105d55b40bed44",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16538&size=130"
          },
          {
              id: "a664b1ce8d8b64d53d9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16539&size=130"
          },
          {
              id: "3e4f2ee512a0fbfea2b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16540&size=130"
          },
          {
              id: "468357296b6c8232db7d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16541&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker150ec3ccff8916d74f98.png",
      price: "Miễn phí",
      name: "Người Phán Xử",
      thumbImg: "https://zalo-api.zadn.vn/0/1/8/d/b/10330/preview/npx_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/0/1/8/d/b/10330/icon_pre/npx_thumb.png",
      id: "3409e2cbde8e37d06e9f",
      source: "",
      totalImage: "12 sticker",
      value: [
          {
              id: "148f5e3062758b2bd264",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21958&size=130"
          },
          {
              id: "dee0955fa91a4044190b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21959&size=130"
          },
          {
              id: "e63da2829ec777992ed6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21960&size=130"
          },
          {
              id: "8a5acfe5f3a01afe43b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21961&size=130"
          },
          {
              id: "0df74b48770d9e53c71c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21962&size=130"
          },
          {
              id: "f669b1d68d9364cd3d82",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21963&size=130"
          },
          {
              id: "be9cfe23c2662b387277",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21964&size=130"
          },
          {
              id: "ec9ead219164783a2175",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21965&size=130"
          },
          {
              id: "5ec41c7b203ec960902f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21966&size=130"
          },
          {
              id: "ceb48d0bb14e5810015f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21967&size=130"
          },
          {
              id: "6bad37120b57e209bb46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21968&size=130"
          },
          {
              id: "a9faf445c800215e7811",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21969&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerb17a02b83efdd7a38eec.png",
      price: "Miễn phí",
      name: "Trại Thú - Khu Vườn Trên Mây",
      thumbImg: "https://zalo-api.zadn.vn/d/7/8/b/d/10303/preview/cubicfarm_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/7/8/b/d/10303/icon_pre/cubicfarm_thumb.png",
      id: "5105e2c7de8237dc6e93",
      source: "",
      totalImage: "24 sticker",
      value: [
          {
              id: "739020291c6cf532ac7d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21471&size=130"
          },
          {
              id: "7ee6125f2e1ac7449e0b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21472&size=130"
          },
          {
              id: "d8e6b55f891a6044390b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21473&size=130"
          },
          {
              id: "d382bd3b817e6820316f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21474&size=130"
          },
          {
              id: "7e4511fc2db9c4e79da8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21475&size=130"
          },
          {
              id: "99d5f16ccd2924777d38",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21476&size=130"
          },
          {
              id: "28b6410f7d4a9414cd5b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21477&size=130"
          },
          {
              id: "021668af54eabdb4e4fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21478&size=130"
          },
          {
              id: "117e7ac74682afdcf693",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21479&size=130"
          },
          {
              id: "a521c198fddd14834dcc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21480&size=130"
          },
          {
              id: "2fb94a0076459f1bc654",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21481&size=130"
          },
          {
              id: "03fb65425907b059e916",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21482&size=130"
          },
          {
              id: "1d1a7aa346e6afb8f6f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21483&size=130"
          },
          {
              id: "a826c89ff4da1d8444cb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21484&size=130"
          },
          {
              id: "507731ce0d8be4d5bd9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21485&size=130"
          },
          {
              id: "0b1969a055e5bcbbe5f4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21486&size=130"
          },
          {
              id: "6bb5080c3449dd178458",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21487&size=130"
          },
          {
              id: "b967c5def99b10c5498a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21488&size=130"
          },
          {
              id: "11cc6c755030b96ee021",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21489&size=130"
          },
          {
              id: "f80886b1baf453aa0ae5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21490&size=130"
          },
          {
              id: "e90596bcaaf943a71ae8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21491&size=130"
          },
          {
              id: "443e3c8700c2e99cb0d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21492&size=130"
          },
          {
              id: "e88c9135ad70442e1d61",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21493&size=130"
          },
          {
              id: "a0bcda05e6400f1e5651",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21494&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickeraa9cae5f921a7b44220b.png",
      price: "Miễn phí",
      name: "Terrier",
      thumbImg: "https://zalo-api.zadn.vn/2/e/e/b/0/10632/preview/ter_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/e/e/b/0/10632/icon_pre/ter_thumb.png",
      id: "4a984e5b721e9b40c20f",
      source: "Mojitok",
      totalImage: "17 sticker",
      value: [
          {
              id: "9e7d49f275b79ce9c5a6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25947&size=130"
          },
          {
              id: "7f08af8793c27a9c23d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25948&size=130"
          },
          {
              id: "7ff8ae7792327b6c2223",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25949&size=130"
          },
          {
              id: "88955a1a665f8f01d64e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25950&size=130"
          },
          {
              id: "c0cd13422f07c6599f16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25951&size=130"
          },
          {
              id: "a75f4bd077959ecbc784",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25952&size=130"
          },
          {
              id: "27b4ca3bf67e1f20466f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25953&size=130"
          },
          {
              id: "203bceb4f2f11baf42e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25954&size=130"
          },
          {
              id: "21ebce64f2211b7f4230",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25955&size=130"
          },
          {
              id: "bf8c57036b468218db57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25956&size=130"
          },
          {
              id: "796990e6aca345fd1cb2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25957&size=130"
          },
          {
              id: "a34949c675839cddc592",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25958&size=130"
          },
          {
              id: "9e5b75d44991a0cff980",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25959&size=130"
          },
          {
              id: "22fec671fa34136a4a25",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25960&size=130"
          },
          {
              id: "0ee2eb6dd7283e766739",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25961&size=130"
          },
          {
              id: "b3d65559691c8042d90d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25962&size=130"
          },
          {
              id: "742693a9afec46b21ffd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25963&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerafb2b77f8b3a62643b2b.png",
      price: "Miễn phí",
      name: " Li Lém - Stony Mobi",
      thumbImg: "https://zalo-api.zadn.vn/7/a/1/b/b/10132/preview/lilemsieungau_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/a/1/b/b/10132/icon_pre/lilemsieungau_thumb.png",
      id: "27e63f2b036eea30b37f",
      source: "Game",
      totalImage: "16 sticker",
      value: [
          {
              id: "d5fe24521817f149a806",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18045&size=130"
          },
          {
              id: "a8175abb66fe8fa0d6ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18046&size=130"
          },
          {
              id: "b71844b478f191afc8e0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18047&size=130"
          },
          {
              id: "a5fca95095157c4b2504",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18048&size=130"
          },
          {
              id: "adc4a0689c2d75732c3c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18049&size=130"
          },
          {
              id: "289426381a7df323aa6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18050&size=130"
          },
          {
              id: "231b2cb710f2f9aca0e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18051&size=130"
          },
          {
              id: "585850f46cb185efdca0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18052&size=130"
          },
          {
              id: "c283cb2ff76a1e34477b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18053&size=130"
          },
          {
              id: "db9fd133ed7604285d67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18054&size=130"
          },
          {
              id: "05a00e0c3249db178258",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18055&size=130"
          },
          {
              id: "73d977754b30a26efb21",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18056&size=130"
          },
          {
              id: "fca1f90dc5482c167559",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18057&size=130"
          },
          {
              id: "331335bf09fae0a4b9eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18058&size=130"
          },
          {
              id: "93ad9401a844411a1855",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18059&size=130"
          },
          {
              id: "35ca35660923e07db932",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18060&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker61c8ac0a904f7911205e.png",
      price: "Miễn phí",
      name: "Khu Vườn Trên Mây 3",
      thumbImg: "https://zalo-api.zadn.vn/d/a/1/6/2/10305/preview/kvtm_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/a/1/6/2/10305/icon_pre/kvtm_thumb.png",
      id: "3513f8d1c4942dca7485",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "9e8d1a332676cf289667",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21512&size=130"
          },
          {
              id: "4327c699fadc13824acd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21513&size=130"
          },
          {
              id: "a15427ea1baff2f1abbe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21514&size=130"
          },
          {
              id: "3ea7b919855c6c02354d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21515&size=130"
          },
          {
              id: "86c1067f3a3ad3648a2b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21516&size=130"
          },
          {
              id: "36bdb7038b4662183b57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21517&size=130"
          },
          {
              id: "f1eb73554f10a64eff01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21518&size=130"
          },
          {
              id: "d4e5575b6b1e8240db0f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21519&size=130"
          },
          {
              id: "77ebeb55d7103e4e6701",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21520&size=130"
          },
          {
              id: "3d8fa0319c74752a2c65",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21521&size=130"
          },
          {
              id: "4df0d34eef0b06555f1a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21522&size=130"
          },
          {
              id: "7858e7e6dba332fd6bb2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21523&size=130"
          },
          {
              id: "d6574ee972ac9bf2c2bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21524&size=130"
          },
          {
              id: "ce7f57c16b8482dadb95",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21525&size=130"
          },
          {
              id: "c7725dcc618988d7d198",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21526&size=130"
          },
          {
              id: "e5397e8742c2ab9cf2d3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21527&size=130"
          },
          {
              id: "a422309c0cd9e587bcc8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21528&size=130"
          },
          {
              id: "22c6b7788b3d62633b2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21529&size=130"
          },
          {
              id: "3f86a938957d7c23256c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21530&size=130"
          },
          {
              id: "d7b740097c4c9512cc5d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21531&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker14e4b4278862613c3873.png",
      price: "Miễn phí",
      name: "Zookiz Mê Bóng Đá",
      thumbImg: "https://zalo-api.zadn.vn/9/d/d/1/6/10540/preview/zookizwc_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/9/d/d/1/6/10540/icon_pre/zookizwc_thumb.png",
      id: "273887fbbbbe52e00baf",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "d5be35340971e02fb960",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24684&size=130"
          },
          {
              id: "54d9b553891660483907",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24685&size=130"
          },
          {
              id: "02f4e07edc3b35656c2a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24686&size=130"
          },
          {
              id: "b9315abb66fe8fa0d6ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24687&size=130"
          },
          {
              id: "b46148eb74ae9df0c4bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24688&size=130"
          },
          {
              id: "223cdfb6e3f30aad53e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24689&size=130"
          },
          {
              id: "e3c61d4c2109c8579118",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24690&size=130"
          },
          {
              id: "ec06138c2fc9c6979fd8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24691&size=130"
          },
          {
              id: "57e0af6a932f7a71233e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24692&size=130"
          },
          {
              id: "a17e58f464b18defd4a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24693&size=130"
          },
          {
              id: "662c9ca6a0e349bd10f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24694&size=130"
          },
          {
              id: "00a7fb2dc7682e367779",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24695&size=130"
          },
          {
              id: "d5a5212f1d6af434ad7b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24696&size=130"
          },
          {
              id: "e8b51d3f217ac824916b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24697&size=130"
          },
          {
              id: "4789b1038d4664183d57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24698&size=130"
          },
          {
              id: "916466ee5aabb3f5eaba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24699&size=130"
          },
          {
              id: "2a2bdaa1e6e40fba56f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24700&size=130"
          },
          {
              id: "d57724fd18b8f1e6a8a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24701&size=130"
          },
          {
              id: "63fa9170ad35446b1d24",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24702&size=130"
          },
          {
              id: "7e798df3b1b658e801a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=24703&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker4ce0bf2383666a383377.png",
      price: "Miễn phí",
      name: "MU Chewyhams",
      thumbImg: "https://zalo-api.zadn.vn/2/8/5/3/8/10623/preview/mu_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/8/5/3/8/10623/icon_pre/mu_thumb.png",
      id: "c6ba3579093ce062b92d",
      source: "",
      totalImage: "22 sticker",
      value: [
          {
              id: "b098c016fc53150d4c42",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25852&size=130"
          },
          {
              id: "d64ba7c59b8072de2b91",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25853&size=130"
          },
          {
              id: "2d965f18635d8a03d34c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25854&size=130"
          },
          {
              id: "89b7fa39c67c2f22766d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25855&size=130"
          },
          {
              id: "ab7527fa1bbff2e1abae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25856&size=130"
          },
          {
              id: "6ea2e32ddf6836366f79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25857&size=130"
          },
          {
              id: "46d4c85bf41e1d40440f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25858&size=130"
          },
          {
              id: "ac1b23941fd1f68fafc0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25859&size=130"
          },
          {
              id: "679aef15d3503a0e6341",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25860&size=130"
          },
          {
              id: "3d71b4fe88bb61e538aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25861&size=130"
          },
          {
              id: "4966c3e9ffac16f24fbd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25862&size=130"
          },
          {
              id: "f14b7ac44681afdff690",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25863&size=130"
          },
          {
              id: "cf1c4b9377d69e88c7c7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25864&size=130"
          },
          {
              id: "82f707783b3dd2638b2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25865&size=130"
          },
          {
              id: "a45122de1e9bf7c5ae8a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25866&size=130"
          },
          {
              id: "41cec641fa04135a4a15",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25867&size=130"
          },
          {
              id: "922512aa2eefc7b19efe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25868&size=130"
          },
          {
              id: "d2f4537b6f3e8660df2f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25869&size=130"
          },
          {
              id: "09168b99b7dc5e8207cd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25870&size=130"
          },
          {
              id: "fefa62755e30b76eee21",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25872&size=130"
          },
          {
              id: "078e9901a5444c1a1555",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25874&size=130"
          },
          {
              id: "afe3306c0c29e577bc38",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25875&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker389f26521a17f349aa06.png",
      price: "Miễn phí",
      name: "Không Cảm Xúc",
      thumbImg: "https://zalo-api.zadn.vn/d/7/3/3/a/10130/preview/khongcamxuc_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/7/3/3/a/10130/icon_pre/khongcamxuc_thumb.png",
      id: "fe44e089dccc35926cdd",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "9ec74b6b772e9e70c73f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18009&size=130"
          },
          {
              id: "d9d00f7c3339da678328",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18010&size=130"
          },
          {
              id: "74f9a3559f10764e2f01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18011&size=130"
          },
          {
              id: "fb332b9f17dafe84a7cb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18012&size=130"
          },
          {
              id: "87c0566c6a298377da38",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18013&size=130"
          },
          {
              id: "99324b9e77db9e85c7ca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18014&size=130"
          },
          {
              id: "e9c73a6b062eef70b63f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18015&size=130"
          },
          {
              id: "11c0fd6cc12928777138",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18016&size=130"
          },
          {
              id: "bc2b51876dc2849cddd3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18017&size=130"
          },
          {
              id: "a6fd485174149d4ac405",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18018&size=130"
          },
          {
              id: "886267ce5b8bb2d5eb9a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18019&size=130"
          },
          {
              id: "a5024dae71eb98b5c1fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18020&size=130"
          },
          {
              id: "69be8012bc5755090c46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18021&size=130"
          },
          {
              id: "e93403983fddd6838fcc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18022&size=130"
          },
          {
              id: "20b2cb1ef75b1e05474a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18023&size=130"
          },
          {
              id: "619f8533b97650280967",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18024&size=130"
          },
          {
              id: "6a888f24b3615a3f0370",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18025&size=130"
          },
          {
              id: "813967955bd0b28eebc1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18026&size=130"
          },
          {
              id: "e5fd02513e14d74a8e05",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18027&size=130"
          },
          {
              id: "3b83db2fe76a0e34577b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18028&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker03d98c1bb05e5900004f.png",
      price: "Miễn phí",
      name: "Rồng Vàng Voice",
      thumbImg: "https://zalo-api.zadn.vn/a/5/6/e/e/10243/preview/rongvoice_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/a/5/6/e/e/10243/icon_pre/rongvoice_thumb.png",
      id: "3883b7418b04625a3b15",
      source: "Thăng Fly Comics",
      totalImage: "13 sticker",
      value: [
          {
              id: "194fedebd1ae38f061bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20088&size=130"
          },
          {
              id: "059ef03acc7f25217c6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20089&size=130"
          },
          {
              id: "8c657ac14684afdaf695",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20090&size=130"
          },
          {
              id: "4057b7f38bb662e83ba7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20091&size=130"
          },
          {
              id: "3a14cab0f6f51fab46e4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20092&size=130"
          },
          {
              id: "2038d19cedd904875dc8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20093&size=130"
          },
          {
              id: "f85a0afe36bbdfe586aa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20094&size=130"
          },
          {
              id: "3636c592f9d7108949c6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20095&size=130"
          },
          {
              id: "24fc2858141dfd43a40c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20096&size=130"
          },
          {
              id: "388b352f096ae034b97b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20097&size=130"
          },
          {
              id: "bb26b58289c7609939d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20098&size=130"
          },
          {
              id: "504d5fe963ac8af2d3bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20099&size=130"
          },
          {
              id: "fc0bf4afc8ea21b478fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20100&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker865b9396afd3468d1fc2.png",
      price: "Miễn phí",
      name: "Kottu 1",
      thumbImg: "https://zalo-api.zadn.vn/7/1/5/e/8/10137/preview/kottu3_cover.jpg",
      iconUrl: "https://zalo-api.zadn.vn/7/1/5/e/8/10137/icon_pre/kottu3_thumb.png",
      id: "1049058439c1d09f89d0",
      source: "Hanasake",
      totalImage: "20 sticker",
      value: [
          {
              id: "79ae31020d47e419bd56",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18116&size=130"
          },
          {
              id: "087c41d07d9594cbcd84",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18117&size=130"
          },
          {
              id: "1da957056b40821edb51",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18118&size=130"
          },
          {
              id: "967dddd1e19408ca5185",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18119&size=130"
          },
          {
              id: "57f513592f1cc6429f0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18120&size=130"
          },
          {
              id: "7ad33f7f033aea64b32b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18121&size=130"
          },
          {
              id: "506116cd2a88c3d69a99",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18122&size=130"
          },
          {
              id: "c3cc8460b825517b0834",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18123&size=130"
          },
          {
              id: "10ea50466c03855ddc12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18124&size=130"
          },
          {
              id: "1a6e5bc267878ed9d796",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18125&size=130"
          },
          {
              id: "52cd10612c24c57a9c35",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18126&size=130"
          },
          {
              id: "ff07bcab80ee69b030ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18127&size=130"
          },
          {
              id: "ebe6b74a8b0f62513b1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18128&size=130"
          },
          {
              id: "6fd532790e3ce762be2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18129&size=130"
          },
          {
              id: "a9aaf706cb43221d7b52",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18130&size=130"
          },
          {
              id: "57c3086f342add74843b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18131&size=130"
          },
          {
              id: "142d4c8170c4999ac0d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18132&size=130"
          },
          {
              id: "ad16f4bac8ff21a178ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18133&size=130"
          },
          {
              id: "35ef6f435306ba58e317",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18134&size=130"
          },
          {
              id: "c60a9da6a1e348bd11f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18135&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker1902abc097857edb2794.png",
      price: "Miễn phí",
      name: "Chồn Mặt Lầy",
      thumbImg: "https://zalo-api.zadn.vn/b/5/7/8/f/10302/preview/chonml_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/b/5/7/8/f/10302/icon_pre/chonml_thumb.png",
      id: "0750b59289d7608939c6",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "1a645ddd619888c6d189",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21451&size=130"
          },
          {
              id: "6f6b2fd21397fac9a386",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21452&size=130"
          },
          {
              id: "93f3d24aee0f07515e1e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21453&size=130"
          },
          {
              id: "910cd3b5eff006ae5fe1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21454&size=130"
          },
          {
              id: "dcb09f09a34c4a12135d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21455&size=130"
          },
          {
              id: "3ef862415e04b75aee15",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21456&size=130"
          },
          {
              id: "8a58d7e1eba402fa5bb5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21457&size=130"
          },
          {
              id: "d91087a9bbec52b20bfd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21458&size=130"
          },
          {
              id: "343d6b8457c1be9fe7d0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21459&size=130"
          },
          {
              id: "9da1c518f95d1003494c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21460&size=130"
          },
          {
              id: "8ecad773eb3602685b27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21461&size=130"
          },
          {
              id: "d2fa8843b4065d580417",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21462&size=130"
          },
          {
              id: "829cd925e5600c3e5571",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21463&size=130"
          },
          {
              id: "32c3667a5a3fb361ea2e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21464&size=130"
          },
          {
              id: "6cfa39430506ec58b517",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21465&size=130"
          },
          {
              id: "1b684dd1719498cac185",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21466&size=130"
          },
          {
              id: "b85fefe6d3a33afd63b2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21467&size=130"
          },
          {
              id: "d2c88271be34576a0e25",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21468&size=130"
          },
          {
              id: "ef4abef382b66be832a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21469&size=130"
          },
          {
              id: "232971904dd5a48bfdc4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21470&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker0dd8951aa95f4001194e.png",
      price: "Miễn phí",
      name: "Đậu Xanh Đậu Đỏ",
      thumbImg: "https://zalo-api.zadn.vn/d/a/7/9/6/10260/preview/daudo_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/a/7/9/6/10260/icon_pre/daudo_thumb.png",
      id: "7644ee86d2c33b9d62d2",
      source: "",
      totalImage: "21 sticker",
      value: [
          {
              id: "f4a3a919955c7c02254d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20689&size=130"
          },
          {
              id: "2ba1751b495ea000f94f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20690&size=130"
          },
          {
              id: "752f2a9516d0ff8ea6c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20691&size=130"
          },
          {
              id: "ec6bb4d1889461ca3885",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20692&size=130"
          },
          {
              id: "d5498cf3b0b659e800a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20693&size=130"
          },
          {
              id: "a651fcebc0ae29f070bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20694&size=130"
          },
          {
              id: "6bed30570c12e54cbc03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20695&size=130"
          },
          {
              id: "939ac720fb65123b4b74",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20696&size=130"
          },
          {
              id: "e971bccb808e69d0309f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20697&size=130"
          },
          {
              id: "cc6e9ad4a6914fcf1680",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20698&size=130"
          },
          {
              id: "ec09bbb387f66ea837e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20699&size=130"
          },
          {
              id: "c9df9965a5204c7e1531",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20700&size=130"
          },
          {
              id: "5930088a34cfdd9184de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20701&size=130"
          },
          {
              id: "9cc9ce73f2361b684227",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20702&size=130"
          },
          {
              id: "4bf6184c2409cd579418",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20703&size=130"
          },
          {
              id: "8593e929d56c3c32657d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20704&size=130"
          },
          {
              id: "707c1dc62183c8dd9192",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20705&size=130"
          },
          {
              id: "12477cfd40b8a9e6f0a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20706&size=130"
          },
          {
              id: "9c97f32dcf6826367f79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20707&size=130"
          },
          {
              id: "6cd5046f382ad174883b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20708&size=130"
          },
          {
              id: "c8e4a15e9d1b74452d0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=20709&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/3/qrstickerb16d87a0bbe552bb0bf4.png",
      price: "Miễn phí",
      name: "Poka Poka",
      thumbImg: "https://zalo-api.zadn.vn/2/e/8/5/d/10170/preview/poka_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/e/8/5/d/10170/icon_pre/poka_thumb.png",
      id: "422974e448a1a1fff8b0",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "c7485deb61ae88f0d1bf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18710&size=130"
          },
          {
              id: "0c1897bbabfe42a01bef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18711&size=130"
          },
          {
              id: "0fb29b11a7544e0a1745",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18712&size=130"
          },
          {
              id: "a43031930dd6e488bdc7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18713&size=130"
          },
          {
              id: "db024da171e498bac1f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18714&size=130"
          },
          {
              id: "5c95cb36f7731e2d4762",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18715&size=130"
          },
          {
              id: "eec37e604225ab7bf234",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18716&size=130"
          },
          {
              id: "06209783abc642981bd7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18717&size=130"
          },
          {
              id: "bb5829fb15befce0a5af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18718&size=130"
          },
          {
              id: "cad4597765328c6cd523",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18719&size=130"
          },
          {
              id: "ef0b43a87fed96b3cffc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18720&size=130"
          },
          {
              id: "bf1a12b92efcc7a29eed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18721&size=130"
          },
          {
              id: "4ad0e473d83631686827",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18722&size=130"
          },
          {
              id: "61cece6df2281b764239",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18723&size=130"
          },
          {
              id: "d4107cb340f6a9a8f0e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18724&size=130"
          },
          {
              id: "cdda6479583cb162e82d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18725&size=130"
          },
          {
              id: "de39749a48dfa181f8ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18726&size=130"
          },
          {
              id: "2af08153bd1654480d07",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18727&size=130"
          },
          {
              id: "facc5e6f622a8b74d23b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18728&size=130"
          },
          {
              id: "5b01fea2c2e72bb972f6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18729&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc7f39831a4744d2a1465.png",
      price: "Miễn phí",
      name: "Đội Tuyển Bóng Đá Việt Nam",
      thumbImg: "https://zalo-api.zadn.vn/4/b/7/3/0/10451/preview/u23_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/4/b/7/3/0/10451/icon_pre/u23_thumb.png",
      id: "27e178234466ad38f477",
      source: "",
      totalImage: "21 sticker",
      value: [
          {
              id: "750545b379f690a8c9e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23740&size=130"
          },
          {
              id: "aa419bf7a7b24eec17a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23741&size=130"
          },
          {
              id: "e68cd43ae87f0121586e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23742&size=130"
          },
          {
              id: "0daa3e1c0259eb07b248",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23743&size=130"
          },
          {
              id: "9e5ad2eceea907f75eb8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23744&size=130"
          },
          {
              id: "7e5833ee0fabe6f5bfba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23745&size=130"
          },
          {
              id: "594617f02bb5c2eb9ba4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23746&size=130"
          },
          {
              id: "efb9a00f9c4a75142c5b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23747&size=130"
          },
          {
              id: "15db5d6d61288876d139",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23748&size=130"
          },
          {
              id: "bf3df68bcace23907adf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23749&size=130"
          },
          {
              id: "3e0d74bb48fea1a0f8ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23750&size=130"
          },
          {
              id: "f22cb99a85df6c8135ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23751&size=130"
          },
          {
              id: "17a9531f6f5a8604df4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23752&size=130"
          },
          {
              id: "40e8055e391bd045890a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23753&size=130"
          },
          {
              id: "f5d0b3668f23667d3f32",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23754&size=130"
          },
          {
              id: "759b322d0e68e736be79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23755&size=130"
          },
          {
              id: "c10781b1bdf454aa0de5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23756&size=130"
          },
          {
              id: "4a590bef37aadef487bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23757&size=130"
          },
          {
              id: "418a033c3f79d6278f68",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23758&size=130"
          },
          {
              id: "09344a8276c79f99c6d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23759&size=130"
          },
          {
              id: "8bc3d775eb30026e5b21",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23760&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker948274414804a15af815.png",
      price: "Miễn phí",
      name: "Happy Birthday 2 ",
      thumbImg: "https://zalo-api.zadn.vn/7/5/c/6/b/10604/preview/birthday_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/5/c/6/b/10604/icon_pre/birthday_thumb.png",
      id: "905570964cd3a58dfcc2",
      source: "",
      totalImage: "18 sticker",
      value: [
          {
              id: "763ed2b0eef507ab5ee4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25640&size=130"
          },
          {
              id: "d8537ddd4198a8c6f189",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25641&size=130"
          },
          {
              id: "6cedca63f6261f784637",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25642&size=130"
          },
          {
              id: "a9c50e4b320edb50821f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25643&size=130"
          },
          {
              id: "e65246dc7a9993c7ca88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25644&size=130"
          },
          {
              id: "0efbaf7593307a6e2321",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25645&size=130"
          },
          {
              id: "774cd5c2e98700d95996",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25646&size=130"
          },
          {
              id: "824721c91d8cf4d2ad9d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25647&size=130"
          },
          {
              id: "0338bfb683f36aad33e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25648&size=130"
          },
          {
              id: "cb9d76134a56a308fa47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25649&size=130"
          },
          {
              id: "7bc3c54df90810564919",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25650&size=130"
          },
          {
              id: "0524baaa86ef6fb136fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25651&size=130"
          },
          {
              id: "1e94a61a9a5f73012a4e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25652&size=130"
          },
          {
              id: "873d3eb302f6eba8b2e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25653&size=130"
          },
          {
              id: "2a6790e9acac45f21cbd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25654&size=130"
          },
          {
              id: "044dbfc383866ad83397",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25655&size=130"
          },
          {
              id: "fd5a49d475919ccfc580",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25656&size=130"
          },
          {
              id: "479ef210ce55270b7e44",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25657&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker924ad988e5cd0c9355dc.png",
      price: "Miễn phí",
      name: "Tiến Lên Việt Nam",
      thumbImg: "https://zalo-api.zadn.vn/0/4/4/5/8/10439/preview/vntienlen_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/0/4/4/5/8/10439/icon_pre/vntienlen_thumb.png",
      id: "20c26b005745be1be754",
      source: "",
      totalImage: "10 sticker",
      value: [
          {
              id: "bbefd35eef1b06455f0a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23524&size=130"
          },
          {
              id: "ff959624aa61433f1a70",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23525&size=130"
          },
          {
              id: "14967e274262ab3cf273",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23526&size=130"
          },
          {
              id: "f2f49945a5004c5e1511",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23527&size=130"
          },
          {
              id: "4da729161553fc0da542",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23528&size=130"
          },
          {
              id: "3a095fb863fd8aa3d3ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23529&size=130"
          },
          {
              id: "ccd5aa6496217f7f2630",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23530&size=130"
          },
          {
              id: "66d201633d26d4788d37",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23531&size=130"
          },
          {
              id: "71eb115a2d1fc4419d0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23532&size=130"
          },
          {
              id: "207c41cd7d8894d6cd99",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=23533&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickeradc11b2b276ece30977f.png",
      price: "Miễn phí",
      name: "Bunnie",
      thumbImg: "https://zalo-api.zadn.vn/6/6/f/0/4/58/preview/MoonRabbit_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/6/6/f/0/4/58/icon_pre/MoonRabbit_thumb.png",
      id: "b67a00903cd5d58b8cc4",
      source: "",
      totalImage: "30 sticker",
      value: [
          {
              id: "bcc9cb27f7621e3c4773",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1275&size=130"
          },
          {
              id: "c43eb4d0889561cb3884",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1276&size=130"
          },
          {
              id: "72d903373f72d62c8f63",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1277&size=130"
          },
          {
              id: "9c0beee5d2a03bfe62b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1278&size=130"
          },
          {
              id: "c412b7fc8bb962e73ba8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1279&size=130"
          },
          {
              id: "d9b0555f691a8044d90b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1280&size=130"
          },
          {
              id: "777efa91c6d42f8a76c5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1281&size=130"
          },
          {
              id: "11af9f40a3054a5b1314",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1282&size=130"
          },
          {
              id: "e5d46a3b567ebf20e66f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1283&size=130"
          },
          {
              id: "3b4eb3a18fe466ba3ff5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1284&size=130"
          },
          {
              id: "68cee121dd64343a6d75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1285&size=130"
          },
          {
              id: "15e69f09a34c4a12135d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1286&size=130"
          },
          {
              id: "45c8ce27f2621b3c4273",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1287&size=130"
          },
          {
              id: "7c44f8abc4ee2db074ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1288&size=130"
          },
          {
              id: "8c9a09753530dc6e8521",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1289&size=130"
          },
          {
              id: "c482426d7e289776ce39",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1290&size=130"
          },
          {
              id: "75c0f22fce6a27347e7b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1291&size=130"
          },
          {
              id: "cf8f4f6073259a7bc334",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1292&size=130"
          },
          {
              id: "3e0cbfe383a66af833b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1293&size=130"
          },
          {
              id: "fed37c3c4079a927f068",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1294&size=130"
          },
          {
              id: "0dc38e2cb2695b370278",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1295&size=130"
          },
          {
              id: "346aa88594c07d9e24d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1296&size=130"
          },
          {
              id: "5e5dc3b2fff716a94fe6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1297&size=130"
          },
          {
              id: "e3a77d48410da853f11c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1298&size=130"
          },
          {
              id: "45e4da0be64e0f10565f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1299&size=130"
          },
          {
              id: "e70c7fe343a6aaf8f3b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1300&size=130"
          },
          {
              id: "74c1ed2ed16b3835617a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1301&size=130"
          },
          {
              id: "070b9de4a1a148ff11b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1302&size=130"
          },
          {
              id: "b7c72c28106df933a07c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1303&size=130"
          },
          {
              id: "263bb2d48e9167cf3e80",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1304&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker7b784ab576f09faec6e1.png",
      price: "Miễn phí",
      name: "Hiro & Shino",
      thumbImg: "https://zalo-api.zadn.vn/7/8/6/6/7/10173/preview/hiroshino_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/8/6/6/7/10173/icon_pre/hiroshino_thumb.png",
      id: "bb198ad4b6915fcf0680",
      source: "Artbox Friends.",
      totalImage: "20 sticker",
      value: [
          {
              id: "895f4afc76b99fe7c6a8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18767&size=130"
          },
          {
              id: "a14a7de941aca8f2f1bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18768&size=130"
          },
          {
              id: "8e2353806fc5869bdfd4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18769&size=130"
          },
          {
              id: "48ef964caa0943571a18",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18770&size=130"
          },
          {
              id: "a1d87e7b423eab60f22f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18771&size=130"
          },
          {
              id: "bc8264215864b13ae875",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18772&size=130"
          },
          {
              id: "5c4085e3b9a650f809b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18773&size=130"
          },
          {
              id: "60c7ba6486216f7f3630",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18774&size=130"
          },
          {
              id: "07e0dc43e00609585017",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18775&size=130"
          },
          {
              id: "ed7739d40591eccfb580",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18776&size=130"
          },
          {
              id: "40fd955ea91b4045190a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18777&size=130"
          },
          {
              id: "33ace50fd94a3014695b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18778&size=130"
          },
          {
              id: "1a31cd92f1d7188941c6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18779&size=130"
          },
          {
              id: "6815b8b684f36dad34e2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18780&size=130"
          },
          {
              id: "83fc525f6e1a8744de0b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18781&size=130"
          },
          {
              id: "834351e06da584fbddb4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18782&size=130"
          },
          {
              id: "e44e37ed0ba8e2f6bbb9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18783&size=130"
          },
          {
              id: "0e8fe22cde6937376e78",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18784&size=130"
          },
          {
              id: "e8b705143951d00f8940",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18785&size=130"
          },
          {
              id: "62518cf2b0b759e900a6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18786&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker4758c5b2f9f710a949e6.png",
      price: "Miễn phí",
      name: "Nhóc Siêu Nhân",
      thumbImg: "https://zalo-api.zadn.vn/a/a/b/3/2/14/preview/nhocsieunhan.jpg",
      iconUrl: "https://zalo-api.zadn.vn/a/a/b/3/2/14/icon_pre/babykid_thumb.png",
      id: "fb1879f245b7ace9f5a6",
      source: "Internet & Zalo",
      totalImage: "25 sticker",
      value: [
          {
              id: "470349e875ad9cf3c5bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=386&size=130"
          },
          {
              id: "aacfa5249961703f2970",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=387&size=130"
          },
          {
              id: "22a72a4c1609ff57a618",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=388&size=130"
          },
          {
              id: "85458caeb0eb59b500fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=389&size=130"
          },
          {
              id: "8fa38548b90d5053091c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=390&size=130"
          },
          {
              id: "921099fba5be4ce015af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=391&size=130"
          },
          {
              id: "ff57fbbcc7f92ea777e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=392&size=130"
          },
          {
              id: "5fb85a5366168f48d607",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=393&size=130"
          },
          {
              id: "9f4a99a1a5e44cba15f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=394&size=130"
          },
          {
              id: "f716f0fdccb825e67ca9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=395&size=130"
          },
          {
              id: "8a238ac8b68d5fd3069c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=396&size=130"
          },
          {
              id: "586a598165c48c9ad5d5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=397&size=130"
          },
          {
              id: "648b73604f25a67bff34",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=411&size=130"
          },
          {
              id: "dce0cc0bf04e1910405f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=412&size=130"
          },
          {
              id: "d4b6c55df91810464909",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=413&size=130"
          },
          {
              id: "4d675f8c63c98a97d3d8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=414&size=130"
          },
          {
              id: "850d96e6aaa343fd1ab2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=415&size=130"
          },
          {
              id: "272e0bc53780dede8791",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=416&size=130"
          },
          {
              id: "35b918522417cd499406",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=417&size=130"
          },
          {
              id: "9341bdaa81ef68b131fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=418&size=130"
          },
          {
              id: "977fb89484d16d8f34c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=419&size=130"
          },
          {
              id: "b6c99e22a2674b391276",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=420&size=130"
          },
          {
              id: "b5cc9c27a062493c1073",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=421&size=130"
          },
          {
              id: "a4348edfb29a5bc4028b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=422&size=130"
          },
          {
              id: "50a17b4a470fae51f71e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=423&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker5e15ead7d6923fcc6683.png",
      price: "Miễn phí",
      name: "Wolfboy",
      thumbImg: "https://zalo-api.zadn.vn/6/4/3/9/f/10296/preview/wolf_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/6/4/3/9/f/10296/icon_pre/wolf_thumb.png",
      id: "5c34e8f6d4b33ded64a2",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "e77a3fc30386ead8b397",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21332&size=130"
          },
          {
              id: "486491ddad9844c61d89",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21333&size=130"
          },
          {
              id: "0a48d0f1ecb405ea5ca5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21334&size=130"
          },
          {
              id: "bae861515d14b44aed05",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21335&size=130"
          },
          {
              id: "ad96792f456aac34f57b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21336&size=130"
          },
          {
              id: "0c6fd9d6e5930ccd5582",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21337&size=130"
          },
          {
              id: "ec9e3a270662ef3cb673",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21338&size=130"
          },
          {
              id: "b9bf6e065243bb1de252",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21339&size=130"
          },
          {
              id: "c7b2170b2b4ec2109b5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21340&size=130"
          },
          {
              id: "4b569aefa6aa4ff416bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21341&size=130"
          },
          {
              id: "cd171fae23ebcab593fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21342&size=130"
          },
          {
              id: "79a3aa1a965f7f01264e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21343&size=130"
          },
          {
              id: "8b7c67c55b80b2deeb91",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21344&size=130"
          },
          {
              id: "0817e5aed9eb30b569fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21345&size=130"
          },
          {
              id: "d89136280a6de333ba7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21346&size=130"
          },
          {
              id: "5d3ab2838ec667983ed7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21347&size=130"
          },
          {
              id: "dccf34760833e16db822",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21348&size=130"
          },
          {
              id: "345adde3e1a608f851b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21349&size=130"
          },
          {
              id: "f93f13862fc3c69d9fd2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21350&size=130"
          },
          {
              id: "0677edced18b38d5619a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21351&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerde2013ca2f8fc6d19f9e.png",
      price: "Miễn phí",
      name: "Bé Ba",
      thumbImg: "https://zalo-api.zadn.vn/f/c/4/9/0/65/preview/zing_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/f/c/4/9/0/65/icon_pre/zing_thumb.png",
      id: "1a6bd781ebc4029a5bd5",
      source: "",
      totalImage: "30 sticker",
      value: [
          {
              id: "ff82ba6d86286f763639",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1481&size=130"
          },
          {
              id: "717b37940bd1e28fbbc0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1482&size=130"
          },
          {
              id: "c65e81b1bdf454aa0de5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1483&size=130"
          },
          {
              id: "261f66f05ab5b3ebeaa4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1484&size=130"
          },
          {
              id: "630522ea1eaff7f1aebe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1485&size=130"
          },
          {
              id: "450107ee3babd2f58bba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1486&size=130"
          },
          {
              id: "14b3575c6b198247db08",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1487&size=130"
          },
          {
              id: "0db851576d12844cdd03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1488&size=130"
          },
          {
              id: "8e03d3ecefa906f75fb8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1489&size=130"
          },
          {
              id: "07d65939657c8c22d56d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1490&size=130"
          },
          {
              id: "48d4173b2b7ec2209b6f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1491&size=130"
          },
          {
              id: "73d02b3f177afe24a76b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1492&size=130"
          },
          {
              id: "1de844077842911cc853",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1493&size=130"
          },
          {
              id: "dc6e8681bac4539a0ad5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1494&size=130"
          },
          {
              id: "9fb0c45ff81a1144480b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1495&size=130"
          },
          {
              id: "b634e2dbde9e37c06e8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1496&size=130"
          },
          {
              id: "d9eb8c04b041591f0050",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1497&size=130"
          },
          {
              id: "c60d90e2aca745f91cb6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1498&size=130"
          },
          {
              id: "125845b779f290acc9e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1499&size=130"
          },
          {
              id: "e02ab0c58c8065de3c91",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1500&size=130"
          },
          {
              id: "fb0daae296a77ff926b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1501&size=130"
          },
          {
              id: "0f965d79613c8862d12d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1502&size=130"
          },
          {
              id: "226c71834dc6a498fdd7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1503&size=130"
          },
          {
              id: "f2d19e3ea27b4b25126a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1504&size=130"
          },
          {
              id: "e2518fbeb3fb5aa503ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1505&size=130"
          },
          {
              id: "9aa6f449c80c2152781d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1506&size=130"
          },
          {
              id: "1c0773e84fada6f3ffbc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1507&size=130"
          },
          {
              id: "6992017d3d38d4668d29",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1508&size=130"
          },
          {
              id: "7f8616692a2cc3729a3d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1509&size=130"
          },
          {
              id: "49e1230e1f4bf615af5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1510&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker2d8566485a0db353ea1c.png",
      price: "Miễn phí",
      name: "Biệt Đội Tha Thu",
      thumbImg: "https://zalo-api.zadn.vn/8/5/a/4/4/10183/preview/Chibi_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/8/5/a/4/4/10183/icon_pre/Chibi_thumb.png",
      id: "9d46d68beace03905adf",
      source: "User",
      totalImage: "20 sticker",
      value: [
          {
              id: "5c7a0ad9369cdfc2868d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18906&size=130"
          },
          {
              id: "a8d6ff75c3302a6e7321",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18907&size=130"
          },
          {
              id: "eca5bc068043691d3052",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18908&size=130"
          },
          {
              id: "485019f325b6cce895a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18909&size=130"
          },
          {
              id: "1d564ff573b09aeec3a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18910&size=130"
          },
          {
              id: "4723148028c5c19b98d4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18911&size=130"
          },
          {
              id: "85bae919d55c3c02654d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18912&size=130"
          },
          {
              id: "32385f9b63de8a80d3cf",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18913&size=130"
          },
          {
              id: "dfc6b1658d20647e3d31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18914&size=130"
          },
          {
              id: "34375b9467d18e8fd7c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18915&size=130"
          },
          {
              id: "07b96f1a535fba01e34e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18916&size=130"
          },
          {
              id: "ce23a7809bc5729b2bd4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18917&size=130"
          },
          {
              id: "8549efead3af3af163be",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18918&size=130"
          },
          {
              id: "21184abb76fe9fa0c6ef",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18919&size=130"
          },
          {
              id: "0ae06e435206bb58e217",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18920&size=130"
          },
          {
              id: "09376c9450d1b98fe0c0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18921&size=130"
          },
          {
              id: "611f07bc3bf9d2a78be8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18922&size=130"
          },
          {
              id: "8447e3e4dfa136ff6fb0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18923&size=130"
          },
          {
              id: "6c590cfa30bfd9e180ae",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18924&size=130"
          },
          {
              id: "6cbe0d1d3158d8068149",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18925&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd82603cc3f89d6d78f98.png",
      price: "Miễn phí",
      name: "Nặc Tha Siêu Nhân",
      thumbImg: "https://zalo-api.zadn.vn/c/7/e/1/2/87/preview/NacTha_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/7/e/1/2/87/icon_pre/NacTha_thumb.png",
      id: "b9f262185e5db703ee4c",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "98a18a4cb6095f570618",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1950&size=130"
          },
          {
              id: "3c152ff813bdfae3a3ac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1951&size=130"
          },
          {
              id: "4a0466e95aacb3f2eabd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1952&size=130"
          },
          {
              id: "422f6fc25387bad9e396",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1953&size=130"
          },
          {
              id: "42526cbf50fab9a4e0eb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1954&size=130"
          },
          {
              id: "f3a2dc4fe00a0954501b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1955&size=130"
          },
          {
              id: "1981316c0d29e477bd38",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1956&size=130"
          },
          {
              id: "1cd0353d0978e026b969",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1957&size=130"
          },
          {
              id: "681242ff7eba97e4ceab",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1958&size=130"
          },
          {
              id: "ed95c678fa3d13634a2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1959&size=130"
          },
          {
              id: "81d1a53c997970272968",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1960&size=130"
          },
          {
              id: "4bbc6e515214bb4ae205",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1961&size=130"
          },
          {
              id: "a1be8753bb1652480b07",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1962&size=130"
          },
          {
              id: "bc0e9be3a7a64ef817b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1963&size=130"
          },
          {
              id: "308810652c20c57e9c31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1964&size=130"
          },
          {
              id: "2d190cf430b1d9ef80a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1965&size=130"
          },
          {
              id: "6dce4f2373669a38c377",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1966&size=130"
          },
          {
              id: "024c21a11de4f4baadf5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1967&size=130"
          },
          {
              id: "8eb5b2588e1d67433e0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1968&size=130"
          },
          {
              id: "40167dfb41bea8e0f1af",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1969&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd9af3a6c0629ef77b638.png",
      price: "Miễn phí",
      name: "Tom Hai Mái",
      thumbImg: "https://zalo-api.zadn.vn/4/3/7/9/c/10607/preview/tom2mai_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/4/3/7/9/c/10607/icon_pre/tom2mai_thumb.png",
      id: "0c24efe7d3a23afc63b3",
      source: "Chuyện Chàng Tom",
      totalImage: "17 sticker",
      value: [
          {
              id: "dabd0f333376da288367",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25689&size=130"
          },
          {
              id: "ce4218cc2489cdd79498",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25690&size=130"
          },
          {
              id: "759da2139e5677082e47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25691&size=130"
          },
          {
              id: "1b8dcb03f7461e184757",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25692&size=130"
          },
          {
              id: "b913689d54d8bd86e4c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25693&size=130"
          },
          {
              id: "b30e61805dc5b49bedd4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25694&size=130"
          },
          {
              id: "075cd4d2e89701c95886",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25695&size=130"
          },
          {
              id: "0618ea96d6d33f8d66c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25696&size=130"
          },
          {
              id: "de5633d80f9de6c3bf8c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25697&size=130"
          },
          {
              id: "98ad76234a66a338fa77",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25698&size=130"
          },
          {
              id: "a5534add76989fc6c689",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25699&size=130"
          },
          {
              id: "0614ee9ad2df3b8162ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25700&size=130"
          },
          {
              id: "f3441aca268fcfd1969e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25701&size=130"
          },
          {
              id: "413dabb397f67ea827e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25702&size=130"
          },
          {
              id: "fe4415ca298fc0d1999e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25703&size=130"
          },
          {
              id: "4b36afb893fd7aa323ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25704&size=130"
          },
          {
              id: "a102448c78c99197c8d8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=25705&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker4ebfec72d03739696026.png",
      price: "Miễn phí",
      name: "Kottu 2",
      thumbImg: "https://zalo-api.zadn.vn/0/8/d/5/6/10030/preview/kottu2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/0/8/d/5/6/10030/icon_pre/kottu2_thumb.png",
      id: "c9866b4b570ebe50e71f",
      source: "Hanasake",
      totalImage: "20 sticker",
      value: [
          {
              id: "58d6b17c8d3964673d28",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16485&size=130"
          },
          {
              id: "5bfdb1578d12644c3d03",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16486&size=130"
          },
          {
              id: "366fddc5e18008de5191",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16487&size=130"
          },
          {
              id: "7c8c9826a4634d3d1472",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16488&size=130"
          },
          {
              id: "2c7bc9d1f5941cca4585",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16489&size=130"
          },
          {
              id: "d67530df0c9ae5c4bc8b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16490&size=130"
          },
          {
              id: "74e5934faf0a46541f1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16491&size=130"
          },
          {
              id: "ace14c4b700e9950c01f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16492&size=130"
          },
          {
              id: "a62347897bcc9292cbdd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16493&size=130"
          },
          {
              id: "44b8a6129a5773092a46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16494&size=130"
          },
          {
              id: "cf342c9e10dbf985a0ca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16495&size=130"
          },
          {
              id: "82927e38427dab23f26c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16496&size=130"
          },
          {
              id: "2e76d3dcef9906c75f88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16497&size=130"
          },
          {
              id: "2e1ed0b4ecf105af5ce0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16498&size=130"
          },
          {
              id: "e51d1ab726f2cfac96e3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16499&size=130"
          },
          {
              id: "12ceea64d6213f7f6630",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16500&size=130"
          },
          {
              id: "ec6415ce298bc0d5999a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16501&size=130"
          },
          {
              id: "1189eb23d7663e386777",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16502&size=130"
          },
          {
              id: "39d4c27efe3b17654e2a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16503&size=130"
          },
          {
              id: "3d33c999f5dc1c8245cd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16504&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker6ea5d068ec2d05735c3c.png",
      price: "Miễn phí",
      name: "Bà Nội",
      thumbImg: "https://zalo-api.zadn.vn/b/2/f/b/1/10034/preview/ELBNCA_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/b/2/f/b/1/10034/icon_pre/ELBNCA_thumb.png",
      id: "45d0fb1dc7582e067749",
      source: "Em Là Bà Nội Của Anh",
      totalImage: "15 sticker",
      value: [
          {
              id: "65785bd267978ec9d786",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16562&size=130"
          },
          {
              id: "fe68c1c2fd8714d94d96",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16563&size=130"
          },
          {
              id: "54d76c7d5038b966e029",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16564&size=130"
          },
          {
              id: "c382fa28c66d2f33767c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16565&size=130"
          },
          {
              id: "86ddbc778032696c3023",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16566&size=130"
          },
          {
              id: "108f2b251760fe3ea771",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16567&size=130"
          },
          {
              id: "3d820928356ddc33857c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16568&size=130"
          },
          {
              id: "850db0a78ce265bc3cf3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16569&size=130"
          },
          {
              id: "148222281e6df733ae7c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16570&size=130"
          },
          {
              id: "61b956136a568308da47",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16571&size=130"
          },
          {
              id: "26ba16102a55c30b9a44",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16572&size=130"
          },
          {
              id: "60ac51066d43841ddd52",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16573&size=130"
          },
          {
              id: "1c752edf129afbc4a28b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16574&size=130"
          },
          {
              id: "d0fee354df11364f6f00",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16575&size=130"
          },
          {
              id: "77d83b720737ee69b726",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16576&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickera6031ec12284cbda9295.png",
      price: "Miễn phí",
      name: "Cá Tháng Tư",
      thumbImg: "https://zalo-api.zadn.vn/d/b/a/f/b/10292/preview/ca_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/b/a/f/b/10292/icon_pre/ca_thumb.png",
      id: "e21d5adf669a8fc4d68b",
      source: "",
      totalImage: "16 sticker",
      value: [
          {
              id: "a3bd28041441fd1fa450",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21255&size=130"
          },
          {
              id: "f91f7da641e3a8bdf1f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21256&size=130"
          },
          {
              id: "7bccfe75c2302b6e7221",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21257&size=130"
          },
          {
              id: "f4c972704e35a76bfe24",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21258&size=130"
          },
          {
              id: "028f8536b973502d0962",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21259&size=130"
          },
          {
              id: "3b3cbb8587c06e9e37d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21260&size=130"
          },
          {
              id: "311bb0a28ce765b93cf6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21261&size=130"
          },
          {
              id: "2a30a88994cc7d9224dd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21262&size=130"
          },
          {
              id: "eb0b68b254f7bda9e4e6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21263&size=130"
          },
          {
              id: "a585393c0579ec27b568",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21264&size=130"
          },
          {
              id: "9cf001493d0cd4528d1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21265&size=130"
          },
          {
              id: "51fccf45f3001a5e4311",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21266&size=130"
          },
          {
              id: "44bfdb06e7430e1d5752",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21267&size=130"
          },
          {
              id: "814b19f225b7cce995a6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21268&size=130"
          },
          {
              id: "5837c18efdcb14954dda",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21269&size=130"
          },
          {
              id: "8984133d2f78c6269f69",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=21270&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickereca3346e082be175b83a.png",
      price: "Miễn phí",
      name: "Chip Tinh Quái",
      thumbImg: "https://zalo-api.zadn.vn/a/4/9/8/2/10068/preview/chucky_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/a/4/9/8/2/10068/icon_pre/chucky_thumb.png",
      id: "c5061dcb218ec8d0919f",
      source: "",
      totalImage: "10 sticker",
      value: [
          {
              id: "68ed67455b00b25eeb11",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17027&size=130"
          },
          {
              id: "d9e0d148ed0d04535d1c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17028&size=130"
          },
          {
              id: "7bd7727f4e3aa764fe2b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17029&size=130"
          },
          {
              id: "e1e5eb4dd7083e566719",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17030&size=130"
          },
          {
              id: "2b3820901cd5f58bacc4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17031&size=130"
          },
          {
              id: "bea5ba0d86486f163659",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17032&size=130"
          },
          {
              id: "c122c48af8cf119148de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17033&size=130"
          },
          {
              id: "852c8384bfc1569f0fd0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17034&size=130"
          },
          {
              id: "a370a4d8989d71c3288c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17035&size=130"
          },
          {
              id: "0cd20c7a303fd961802e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17036&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker41514d9c71d99887c1c8.png",
      price: "Miễn phí",
      name: "Buppy",
      thumbImg: "https://zalo-api.zadn.vn/7/1/2/a/6/10112/preview/buppy_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/1/2/a/6/10112/icon_pre/buppy_thumb.png",
      id: "257129bc15f9fca7a5e8",
      source: "Zalo",
      totalImage: "16 sticker",
      value: [
          {
              id: "fed6ab78973d7e63272c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17625&size=130"
          },
          {
              id: "daad8c03b04659180057",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17626&size=130"
          },
          {
              id: "f7e8a0469c03755d2c12",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17627&size=130"
          },
          {
              id: "d49f8431b874512a0865",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17628&size=130"
          },
          {
              id: "339862365e73b72dee62",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17629&size=130"
          },
          {
              id: "38986a365673bf2de662",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17630&size=130"
          },
          {
              id: "38a96b075742be1ce753",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17631&size=130"
          },
          {
              id: "56b13a1f065aef04b64b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17632&size=130"
          },
          {
              id: "f2579ff9a3bc4ae213ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17633&size=130"
          },
          {
              id: "d156bff883bd6ae333ac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17634&size=130"
          },
          {
              id: "bf39d097ecd2058c5cc3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17635&size=130"
          },
          {
              id: "34d05c7e603b8965d02a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17636&size=130"
          },
          {
              id: "d701beaf82ea6bb432fb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17637&size=130"
          },
          {
              id: "49d5237b1f3ef660af2f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17638&size=130"
          },
          {
              id: "d958b2f68eb367ed3ea2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17639&size=130"
          },
          {
              id: "ccbfa81194547d0a2445",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17640&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker221385f9b9bc50e209ad.png",
      price: "Miễn phí",
      name: "Meo Meo",
      thumbImg: "https://zalo-api.zadn.vn/1/7/e/6/2/43/preview/meo_cover.jpg",
      iconUrl: "https://zalo-api.zadn.vn/1/7/e/6/2/43/icon_pre/meo_thumb.png",
      id: "8a9e2d741131f86fa120",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "8053cbbaf7ff1ea147ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=967&size=130"
          },
          {
              id: "94ebd002ec4705195c56",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=968&size=130"
          },
          {
              id: "e47fa1969dd3748d2dc2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=969&size=130"
          },
          {
              id: "d7d3913aad7f44211d6e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=970&size=130"
          },
          {
              id: "2d1b6af256b7bfe9e6a6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=971&size=130"
          },
          {
              id: "9359d3b0eff506ab5fe4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=972&size=130"
          },
          {
              id: "44ce05273962d03c8973",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=973&size=130"
          },
          {
              id: "712033c90f8ce6d2bf9d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=974&size=130"
          },
          {
              id: "05d946307a75932bca64",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=975&size=130"
          },
          {
              id: "b373ef9ad3df3a8163ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=976&size=130"
          },
          {
              id: "9db3c05afc1f15414c0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=977&size=130"
          },
          {
              id: "b870e699dadc33826acd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=978&size=130"
          },
          {
              id: "28f3771a4b5fa201fb4e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=979&size=130"
          },
          {
              id: "df1087f9bbbc52e20bad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=980&size=130"
          },
          {
              id: "47411ea822edcbb392fc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=981&size=130"
          },
          {
              id: "cb889161ad24447a1d35",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=982&size=130"
          },
          {
              id: "75d62e3f127afb24a26b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=983&size=130"
          },
          {
              id: "70ee24071842f11ca853",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=984&size=130"
          },
          {
              id: "aeeafb03c7462e187757",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=985&size=130"
          },
          {
              id: "6b043ded01a8e8f6b1b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=986&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker08c1ab2b976e7e30277f.png",
      price: "Miễn phí",
      name: "Chaien",
      thumbImg: "https://zalo-api.zadn.vn/6/7/c/6/a/47/preview/Popo_cover1.jpg",
      iconUrl: "https://zalo-api.zadn.vn/6/7/c/6/a/47/icon_pre/Popo_thumb.png",
      id: "3ff59c1fa05a4904104b",
      source: "",
      totalImage: "30 sticker",
      value: [
          {
              id: "ed689281aec4479a1ed5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1011&size=130"
          },
          {
              id: "3a67428e7ecb9795ceda",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1012&size=130"
          },
          {
              id: "1e2a67c35b86b2d8eb97",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1013&size=130"
          },
          {
              id: "92eae803d4463d186457",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1014&size=130"
          },
          {
              id: "b68ecd67f122187c4133",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1015&size=130"
          },
          {
              id: "553d21d41d91f4cfad80",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1016&size=130"
          },
          {
              id: "d0c8a5219964703a2975",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1017&size=130"
          },
          {
              id: "2d7e5b9767d28e8cd7c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1018&size=130"
          },
          {
              id: "425535bc09f9e0a7b9e8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1019&size=130"
          },
          {
              id: "c141b1a88ded64b33dfc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1020&size=130"
          },
          {
              id: "5f472eae12ebfbb5a2fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1021&size=130"
          },
          {
              id: "3dee4f0773429a1cc353",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1022&size=130"
          },
          {
              id: "211e52f76eb287ecdea3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1023&size=130"
          },
          {
              id: "1ea2924cae0947571e18",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1024&size=130"
          },
          {
              id: "f3257ecb428eabd0f29f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1025&size=130"
          },
          {
              id: "90be1e502215cb4b9204",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1026&size=130"
          },
          {
              id: "1fc2902cac6945371c78",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1027&size=130"
          },
          {
              id: "9d4815a629e3c0bd99f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1028&size=130"
          },
          {
              id: "ddb954576812814cd803",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1029&size=130"
          },
          {
              id: "8e3504db389ed1c0888f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1030&size=130"
          },
          {
              id: "56d9dd37e172082c5163",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1031&size=130"
          },
          {
              id: "789bfc75c030296e7021",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1032&size=130"
          },
          {
              id: "6c8ae964d5213c7f6530",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1033&size=130"
          },
          {
              id: "101a96f4aab143ef1aa0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1034&size=130"
          },
          {
              id: "472dc0c3fc8615d84c97",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1035&size=130"
          },
          {
              id: "17669788abcd42931bdc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1036&size=130"
          },
          {
              id: "06c0872ebb6b52350b7a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1037&size=130"
          },
          {
              id: "dc475ea962ec8bb2d2fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1038&size=130"
          },
          {
              id: "422ec1c0fd8514db4d94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1039&size=130"
          },
          {
              id: "50c1cc2ff06a1934407b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1040&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker9fd99814a4514d0f1440.png",
      price: "Miễn phí",
      name: "Emoticat",
      thumbImg: "https://zalo-api.zadn.vn/c/a/8/1/0/10123/preview/emoticat_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/a/8/1/0/10123/icon_pre/emoticat_thumb.png",
      id: "298b2e461203fb5da212",
      source: "Hansigan",
      totalImage: "20 sticker",
      value: [
          {
              id: "54c613692f2cc6729f3d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17867&size=130"
          },
          {
              id: "02ec42437e069758ce17",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17868&size=130"
          },
          {
              id: "5ca31d0c2149c8179158",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17869&size=130"
          },
          {
              id: "cb6689c9b58c5cd2059d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17870&size=130"
          },
          {
              id: "2bbc68135456bd08e447",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17871&size=130"
          },
          {
              id: "8562d9cde5880cd65599",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17872&size=130"
          },
          {
              id: "d27c8fd3b3965ac80387",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17873&size=130"
          },
          {
              id: "44591af626b3cfed96a2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17874&size=130"
          },
          {
              id: "43ab1c042041c91f9050",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17875&size=130"
          },
          {
              id: "ee9ab6358a70632e3a61",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17876&size=130"
          },
          {
              id: "fb57a2f89ebd77e32eac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17877&size=130"
          },
          {
              id: "85c9df66e3230a7d5332",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17878&size=130"
          },
          {
              id: "64243f8b03ceea90b3df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17879&size=130"
          },
          {
              id: "a068f4c7c88221dc7893",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17880&size=130"
          },
          {
              id: "3fa76a08564dbf13e65c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17881&size=130"
          },
          {
              id: "d18b8724bb61523f0b70",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17882&size=130"
          },
          {
              id: "8276d5d9e99c00c2598d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17883&size=130"
          },
          {
              id: "a0b0f01fcc5a25047c4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17884&size=130"
          },
          {
              id: "89ecd843e4060d585417",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17885&size=130"
          },
          {
              id: "485c1af326b6cfe896a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17886&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc03fc3f2ffb716e94fa6.png",
      price: "Miễn phí",
      name: "Chuppy",
      thumbImg: "https://zalo-api.zadn.vn/1/b/2/b/a/10127/preview/chuppy_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/1/b/2/b/a/10127/icon_pre/chuppy_thumb.png",
      id: "69ce6a035646bf18e657",
      source: "Caerux Co.,Ltd.",
      totalImage: "20 sticker",
      value: [
          {
              id: "1af28b5eb71b5e45070a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17949&size=130"
          },
          {
              id: "8ff61d5a211fc841910e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17950&size=130"
          },
          {
              id: "adac3e000245eb1bb254",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17951&size=130"
          },
          {
              id: "007aacd6909379cd2082",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17952&size=130"
          },
          {
              id: "f23e5f9263d78a89d3c6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17953&size=130"
          },
          {
              id: "6f2ec182fdc714994dd6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17954&size=130"
          },
          {
              id: "78b6d71aeb5f02015b4e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17955&size=130"
          },
          {
              id: "4386eb2ad76f3e31677e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17956&size=130"
          },
          {
              id: "c0f3695f551abc44e50b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17957&size=130"
          },
          {
              id: "3b4c91e0ada544fb1db4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17958&size=130"
          },
          {
              id: "fff954556810814ed801",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17959&size=130"
          },
          {
              id: "e8be4c1270579909c046",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17960&size=130"
          },
          {
              id: "39219c8da0c8499610d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17961&size=130"
          },
          {
              id: "38029eaea2eb4bb512fa",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17962&size=130"
          },
          {
              id: "980a3fa603e3eabdb3f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17963&size=130"
          },
          {
              id: "5f22ff8ec3cb2a9573da",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17964&size=130"
          },
          {
              id: "77f6d65aea1f03415a0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17965&size=130"
          },
          {
              id: "f23c50906cd5858bdcc4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17966&size=130"
          },
          {
              id: "4e71edddd19838c66189",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17967&size=130"
          },
          {
              id: "d1a76d0b514eb810e15f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17968&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker8bf49136ad73442d1d62.png",
      price: "Miễn phí",
      name: "Mr. Lờ Đờ",
      thumbImg: "https://zalo-api.zadn.vn/7/f/9/6/b/10390/preview/lodo_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/f/9/6/b/10390/icon_pre/lodo_thumb.png",
      id: "27d33d110154e80ab145",
      source: "Artbox Friends",
      totalImage: "19 sticker",
      value: [
          {
              id: "a35189e2b5a75cf905b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22950&size=130"
          },
          {
              id: "56737dc04185a8dbf194",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22951&size=130"
          },
          {
              id: "a6d68265be20577e0e31",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22952&size=130"
          },
          {
              id: "825ca7ef9baa72f42bbb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22953&size=130"
          },
          {
              id: "7c1e5aad66e88fb6d6f9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22954&size=130"
          },
          {
              id: "d1b8f60bca4e23107a5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22955&size=130"
          },
          {
              id: "ee3fce8cf2c91b9742d8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22956&size=130"
          },
          {
              id: "530f72bc4ef9a7a7fee8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22957&size=130"
          },
          {
              id: "dbb9f90ac54f2c11755e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22958&size=130"
          },
          {
              id: "096e2add1698ffc6a689",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22959&size=130"
          },
          {
              id: "4b7877cb4b8ea2d0fb9f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22960&size=130"
          },
          {
              id: "126f2fdc1399fac7a388",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22961&size=130"
          },
          {
              id: "a1469ff5a3b04aee13a1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22962&size=130"
          },
          {
              id: "486a77d94b9ca2c2fb8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22963&size=130"
          },
          {
              id: "bfcb8778bb3d52630b2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22964&size=130"
          },
          {
              id: "663e5f8d63c88a96d3d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22965&size=130"
          },
          {
              id: "51bb6b08574dbe13e75c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22966&size=130"
          },
          {
              id: "1cfb27481b0df253ab1c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22967&size=130"
          },
          {
              id: "85d3b1608d25647b3d34",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=22968&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker6a7ecc94f0d1198f40c0.png",
      price: "Miễn phí",
      name: "Ủn Ủn",
      thumbImg: "https://zalo-api.zadn.vn/a/1/d/0/c/42/preview/heo_cover1.jpg",
      iconUrl: "https://zalo-api.zadn.vn/a/1/d/0/c/42/icon_pre/heo_thumb.png",
      id: "f7ef51056d40841edd51",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "bc1d83f4bfb156ef0fa0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=947&size=130"
          },
          {
              id: "13042bed17a8fef6a7b9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=948&size=130"
          },
          {
              id: "be0787eebbab52f50bba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=949&size=130"
          },
          {
              id: "0e1934f008b5e1ebb8a4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=950&size=130"
          },
          {
              id: "1f6a248318c6f198a8d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=951&size=130"
          },
          {
              id: "298e1d672122c87c9133",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=952&size=130"
          },
          {
              id: "6a255fcc63898ad7d398",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=953&size=130"
          },
          {
              id: "ce98f871c4342d6a7425",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=954&size=130"
          },
          {
              id: "93f5a41c985971072848",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=955&size=130"
          },
          {
              id: "da9aea73d6363f686627",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=956&size=130"
          },
          {
              id: "554d64a458e1b1bfe8f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=957&size=130"
          },
          {
              id: "787f4a9676d39f8dc6c2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=958&size=130"
          },
          {
              id: "ed1edef7e2b20bec52a3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=959&size=130"
          },
          {
              id: "81d6cd3ff17a1824416b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=960&size=130"
          },
          {
              id: "1e5753be6ffb86a5dfea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=961&size=130"
          },
          {
              id: "24626a8b56cebf90e6df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=962&size=130"
          },
          {
              id: "8092cf7bf33e1a60432f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=963&size=130"
          },
          {
              id: "0fb3475a7b1f9241cb0e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=964&size=130"
          },
          {
              id: "1a5053b96ffc86a2dfed",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=965&size=130"
          },
          {
              id: "51711b9827ddce8397cc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=966&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker1c3e27f31bb6f2e8aba7.png",
      price: "Miễn phí",
      name: "Rồng Ú",
      thumbImg: "https://zalo-api.zadn.vn/d/f/f/7/0/10167/preview/rongu_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/f/f/7/0/10167/icon_pre/rongu_thumb.png",
      id: "16582d9511d0f88ea1c1",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "57903d320177e829b166",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18662&size=130"
          },
          {
              id: "7fe214402805c15b9814",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18663&size=130"
          },
          {
              id: "e9638dc1b18458da0195",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18664&size=130"
          },
          {
              id: "d9e4bc468003695d3012",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18665&size=130"
          },
          {
              id: "321854ba68ff81a1d8ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18666&size=130"
          },
          {
              id: "c1fba6599a1c73422a0d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18667&size=130"
          },
          {
              id: "3c045ca660e389bdd0f2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18668&size=130"
          },
          {
              id: "ddcdbc6f802a6974303b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18669&size=130"
          },
          {
              id: "488b2a29166cff32a67d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18670&size=130"
          },
          {
              id: "3cb15f1363568a08d347",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18671&size=130"
          },
          {
              id: "e5f59957a5124c4c1503",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18672&size=130"
          },
          {
              id: "8986f424c861213f7870",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18673&size=130"
          },
          {
              id: "8621f883c4c62d9874d7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18674&size=130"
          },
          {
              id: "a842d7e0eba502fb5bb4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18675&size=130"
          },
          {
              id: "4e7636d40a91e3cfba80",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18676&size=130"
          },
          {
              id: "35f24c507015994bc004",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18677&size=130"
          },
          {
              id: "32ca4868742d9d73c43c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18678&size=130"
          },
          {
              id: "8ce2f740cb05225b7b14",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18679&size=130"
          },
          {
              id: "119a6538597db023e96c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18680&size=130"
          },
          {
              id: "9767e2c5de8037de6e91",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18681&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker2ac48b09b74c5e12075d.png",
      price: "Miễn phí",
      name: "Nông Trại Vui Vẻ",
      thumbImg: "https://zalo-api.zadn.vn/6/0/7/2/c/10029/preview/kimchi_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/6/0/7/2/c/10029/icon_pre/kimchi_thumb.png",
      id: "5ac5fb08c74d2e13775c",
      source: "",
      totalImage: "20 sticker",
      value: [
          {
              id: "16e2cb48f70d1e53471c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16465&size=130"
          },
          {
              id: "e96737cd0b88e2d6bb99",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16466&size=130"
          },
          {
              id: "131bccb1f0f419aa40e5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16467&size=130"
          },
          {
              id: "4fa4970eab4b42151b5a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16468&size=130"
          },
          {
              id: "a32f7a8546c0af9ef6d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16469&size=130"
          },
          {
              id: "e221388b04ceed90b4df",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16470&size=130"
          },
          {
              id: "1ce5c74ffb0a12544b1b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16471&size=130"
          },
          {
              id: "69a8bd02814768193156",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16472&size=130"
          },
          {
              id: "3b10eebad2ff3ba162ee",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16473&size=130"
          },
          {
              id: "4b709ddaa19f48c1118e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16474&size=130"
          },
          {
              id: "fd9c2a361673ff2da662",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16475&size=130"
          },
          {
              id: "1dafcd05f140181e4151",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16476&size=130"
          },
          {
              id: "3f44eeeed2ab3bf562ba",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16477&size=130"
          },
          {
              id: "0060d2caee8f07d15e9e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16478&size=130"
          },
          {
              id: "93f940537c169548cc07",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16479&size=130"
          },
          {
              id: "ef94033e3f7bd6258f6a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16480&size=130"
          },
          {
              id: "34e3d949e50c0c52551d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16481&size=130"
          },
          {
              id: "7a339499a8dc418218cd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16482&size=130"
          },
          {
              id: "c2122db811fdf8a3a1ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16483&size=130"
          },
          {
              id: "080be0a1dce435ba6cf5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16484&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker2c649e8ea2cb4b9512da.png",
      price: "Miễn phí",
      name: "Để Mai Tính 2",
      thumbImg: "https://zalo-api.zadn.vn/4/4/f/6/8/62/preview/demaitinh2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/4/4/f/6/8/62/icon_pre/demaitinh2_thumb.png",
      id: "2e499ca3a0e649b810f7",
      source: "",
      totalImage: "22 sticker",
      value: [
          {
              id: "cef5351a095fe001b94e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1399&size=130"
          },
          {
              id: "3fe7cb08f74d1e13475c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1400&size=130"
          },
          {
              id: "b23647d97b9c92c2cb8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1401&size=130"
          },
          {
              id: "7d5f8bb0b7f55eab07e4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1402&size=130"
          },
          {
              id: "4a69bd8681c3689d31d2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1403&size=130"
          },
          {
              id: "e2f3121c2e59c7079e48",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1404&size=130"
          },
          {
              id: "fd570cb830fdd9a380ec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1405&size=130"
          },
          {
              id: "0233f0dccc9925c77c88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1406&size=130"
          },
          {
              id: "b29c41737d369468cd27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1407&size=130"
          },
          {
              id: "3cb730580c1de543bc0c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1408&size=130"
          },
          {
              id: "3fbb32540e11e74fbe00",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1409&size=130"
          },
          {
              id: "566d588264c78d99d4d6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1410&size=130"
          },
          {
              id: "fcbaf355cf10264e7f01",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1411&size=130"
          },
          {
              id: "d9aad145ed00045e5d11",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1412&size=130"
          },
          {
              id: "3fec36030a46e318ba57",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1413&size=130"
          },
          {
              id: "1fac15432906c0589917",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1414&size=130"
          },
          {
              id: "f77afc95c0d0298e70c1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1415&size=130"
          },
          {
              id: "163d12d22e97c7c99e86",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1416&size=130"
          },
          {
              id: "9472919dadd844861dc9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1417&size=130"
          },
          {
              id: "a0a6a6499a0c73522a1d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1418&size=130"
          },
          {
              id: "96c2912dad6844361d79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1419&size=130"
          },
          {
              id: "2cd42c3b107ef920a06f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1420&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd753319e0ddbe485bdca.png",
      price: "Miễn phí",
      name: "Tân Võ Lâm",
      thumbImg: "https://zalo-api.zadn.vn/4/3/9/2/e/10090/preview/tanvolam.cover.png",
      iconUrl: "https://zalo-api.zadn.vn/4/3/9/2/e/10090/icon_pre/tanvolam_thumb.png",
      id: "b3fe553369768028d967",
      source: "",
      totalImage: "15 sticker",
      value: [
          {
              id: "63347e9d42d8ab86f2c9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17297&size=130"
          },
          {
              id: "15e40b4d3708de568719",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17298&size=130"
          },
          {
              id: "5b33449a78df9181c8ce",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17299&size=130"
          },
          {
              id: "3d5125f819bdf0e3a9ac",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17300&size=130"
          },
          {
              id: "44d25d7b613e8860d12f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17301&size=130"
          },
          {
              id: "8fbb9512a95740091946",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17302&size=130"
          },
          {
              id: "96958d3cb17958270168",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17303&size=130"
          },
          {
              id: "9aeb8e42b2075b590216",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17304&size=130"
          },
          {
              id: "5a7c4fd573909acec381",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17305&size=130"
          },
          {
              id: "2a7e3cd70092e9ccb083",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17306&size=130"
          },
          {
              id: "dbe4cc4df00819564019",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17307&size=130"
          },
          {
              id: "417551dc6d9984c7dd88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17308&size=130"
          },
          {
              id: "ba66abcf978a7ed4279b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17309&size=130"
          },
          {
              id: "68877a2e466baf35f67a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17310&size=130"
          },
          {
              id: "3ddf2e761233fb6da222",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17311&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickeraa4a15a029e5c0bb99f4.png",
      price: "Miễn phí",
      name: "Khu Vườn Trên Mây 2",
      thumbImg: "https://zalo-api.zadn.vn/2/8/3/8/0/51/preview/kvtm2_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/2/8/3/8/0/51/icon_pre/kvtm2_thumb.png",
      id: "87a4384e040bed55b41a",
      source: "",
      totalImage: "17 sticker",
      value: [
          {
              id: "545687b8bbfd52a30bec",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1119&size=130"
          },
          {
              id: "bc2850c66c8385dddc92",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1120&size=130"
          },
          {
              id: "1806f5e8c9ad20f379bc",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1121&size=130"
          },
          {
              id: "95c47b2a476fae31f77e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1122&size=130"
          },
          {
              id: "f90b16e52aa0c3fe9ab1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1123&size=130"
          },
          {
              id: "be9d56736a368368da27",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1124&size=130"
          },
          {
              id: "ca2423ca1f8ff6d1af9e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1125&size=130"
          },
          {
              id: "ed0b07e53ba0d2fe8bb1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1126&size=130"
          },
          {
              id: "e8e903073f42d61c8f53",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1127&size=130"
          },
          {
              id: "593dbcd3809669c83087",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1129&size=130"
          },
          {
              id: "d2b63458081de143b80c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1130&size=130"
          },
          {
              id: "1ec3f92dc5682c367579",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1131&size=130"
          },
          {
              id: "d2dc32320e77e729be66",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1132&size=130"
          },
          {
              id: "23acc242fe0717594e16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1133&size=130"
          },
          {
              id: "46c7a429986c7132287d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1134&size=130"
          },
          {
              id: "9284876abb2f52710b3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1177&size=130"
          },
          {
              id: "0d791b9727d2ce8c97c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=1178&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker98e69a2ba66e4f30167f.png",
      price: "Miễn phí",
      name: "Ninja Siêu Ngố",
      thumbImg: "https://zalo-api.zadn.vn/c/c/2/6/f/10126/preview/Ninjango_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/c/2/6/f/10126/icon_pre/NinjaNgo_thumb.png",
      id: "d624d4e9e8ac01f258bd",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "fb8f7e234266ab38f277",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17929&size=130"
          },
          {
              id: "b2b0341c0859e107b848",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17930&size=130"
          },
          {
              id: "250aa2a69ee377bd2ef2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17931&size=130"
          },
          {
              id: "3a4ebae286a76ff936b6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17932&size=130"
          },
          {
              id: "0e008facb3e95ab703f8",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17933&size=130"
          },
          {
              id: "11ba9316af53460d1f42",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17934&size=130"
          },
          {
              id: "7cddff71c3342a6a7325",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17935&size=130"
          },
          {
              id: "a8c53469082ce172b83d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17936&size=130"
          },
          {
              id: "2809b5a589e060be39f1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17937&size=130"
          },
          {
              id: "70ddee71d2343b6a6225",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17938&size=130"
          },
          {
              id: "10058fa9b3ec5ab203fd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17939&size=130"
          },
          {
              id: "e126798a45cfac91f5de",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17940&size=130"
          },
          {
              id: "f0a56909554cbc12e55d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17941&size=130"
          },
          {
              id: "65b5ff19c35c2a02734d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17942&size=130"
          },
          {
              id: "d3da487674339d6dc422",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17943&size=130"
          },
          {
              id: "43d5d779eb3c02625b2d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17944&size=130"
          },
          {
              id: "327ba7d79b9272cc2b83",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17945&size=130"
          },
          {
              id: "c84f5ee362a68bf8d2b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17946&size=130"
          },
          {
              id: "d081472d7b689236cb79",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17947&size=130"
          },
          {
              id: "ae6d3ec10284ebdab295",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17948&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerd670babd86f86fa636e9.png",
      price: "Miễn phí",
      name: "Luci Daisy Xmas",
      thumbImg: "https://zalo-api.zadn.vn/a/c/b/5/5/10208/preview/luda_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/a/c/b/5/5/10208/icon_pre/luda_thumb.png",
      id: "ff56939bafde46801fcf",
      source: "",
      totalImage: "16 sticker",
      value: [
          {
              id: "63665fc763828adcd393",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19376&size=130"
          },
          {
              id: "f49fc93ef57b1c25456a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19377&size=130"
          },
          {
              id: "b9b58714bb51520f0b40",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19378&size=130"
          },
          {
              id: "b5318a90b6d55f8b06c4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19379&size=130"
          },
          {
              id: "8880b0218c64653a3c75",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19380&size=130"
          },
          {
              id: "07583ef902bcebe2b2ad",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19381&size=130"
          },
          {
              id: "d942e3e3dfa636f86fb7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19382&size=130"
          },
          {
              id: "7b9d403c7c799527cc68",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19383&size=130"
          },
          {
              id: "8c8fb82e846b6d35347a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19384&size=130"
          },
          {
              id: "27cb126a2e2fc7719e3e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19385&size=130"
          },
          {
              id: "d0bfe61eda5b33056a4a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19386&size=130"
          },
          {
              id: "39440ee532a0dbfe82b1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19387&size=130"
          },
          {
              id: "2f961f372372ca2c9363",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19388&size=130"
          },
          {
              id: "ce40ffe1c3a42afa73b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19389&size=130"
          },
          {
              id: "66b654176852810cd843",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19390&size=130"
          },
          {
              id: "844eb7ef8baa62f43bbb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19391&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerc85e8893b4d65d8804c7.png",
      price: "Miễn phí",
      name: "Trang Trại Vui Nhộn",
      thumbImg: "https://zalo-api.zadn.vn/7/6/3/0/9/10188/preview/happyfarm_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/6/3/0/9/10188/icon_pre/happyfarm_thumb.png",
      id: "c66a86a7bae253bc0af3",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "c5e201423d07d4598d16",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19016&size=130"
          },
          {
              id: "a8e36d435106b858e117",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19017&size=130"
          },
          {
              id: "f3d135710934e06ab925",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19018&size=130"
          },
          {
              id: "06b4c114fd51140f4d40",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19019&size=130"
          },
          {
              id: "41938133bd7654280d67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19020&size=130"
          },
          {
              id: "1cb7dd17e152080c5143",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19021&size=130"
          },
          {
              id: "278ee52ed96b3035697a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19022&size=130"
          },
          {
              id: "cfeb0c4b300ed950801f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19023&size=130"
          },
          {
              id: "9a5546f57ab093eecaa1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19024&size=130"
          },
          {
              id: "1e01c3a1ffe416ba4ff5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19025&size=130"
          },
          {
              id: "74b4aa1496517f0f2640",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19026&size=130"
          },
          {
              id: "570788a7b4e25dbc04f3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19027&size=130"
          },
          {
              id: "45fc9d5ca11948471108",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19028&size=130"
          },
          {
              id: "6683bf2383666a383377",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19029&size=130"
          },
          {
              id: "187bc2dbfe9e17c04e8f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19030&size=130"
          },
          {
              id: "f6512df111b4f8eaa1a5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19031&size=130"
          },
          {
              id: "eff73b570712ee4cb703",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19032&size=130"
          },
          {
              id: "1f25ca85f6c01f9e46d1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19033&size=130"
          },
          {
              id: "99534ff373b69ae8c3a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19034&size=130"
          },
          {
              id: "6000b7a08be562bb3bf4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=19035&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker5439c6d3fa9613c84a87.png",
      price: "Miễn phí",
      name: "Xeko 1",
      thumbImg: "https://zalo-api.zadn.vn/3/4/1/7/3/30/preview/casau_cover1.png",
      iconUrl: "https://zalo-api.zadn.vn/3/4/1/7/3/30/icon_pre/casau_thumb.png",
      id: "74b3e659da1c33426a0d",
      source: "Zalo",
      totalImage: "20 sticker",
      value: [
          {
              id: "bb4591adade844b61df9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=678&size=130"
          },
          {
              id: "1b5530bd0cf8e5a6bce9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=679&size=130"
          },
          {
              id: "1afb3e130256eb08b247",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=680&size=130"
          },
          {
              id: "51b3745b481ea140f80f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=681&size=130"
          },
          {
              id: "5e3678de449badc5f48a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=682&size=130"
          },
          {
              id: "611d46f57ab093eecaa1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=683&size=130"
          },
          {
              id: "1594357c0939e067b928",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=684&size=130"
          },
          {
              id: "b94b98a3a4e64db814f7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=685&size=130"
          },
          {
              id: "727850906cd5858bdcc4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=686&size=130"
          },
          {
              id: "37801468282dc173983c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=687&size=130"
          },
          {
              id: "08cb34230866e138b877",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=688&size=130"
          },
          {
              id: "c2e2ff0ac34f2a11735e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=689&size=130"
          },
          {
              id: "84f0ba18865d6f03364c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=690&size=130"
          },
          {
              id: "63935c7b603e8960d02f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=691&size=130"
          },
          {
              id: "2da5154d2908c0569919",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=692&size=130"
          },
          {
              id: "9bb8a2509e15774b2e04",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=693&size=130"
          },
          {
              id: "359d0f753330da6e8321",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=694&size=130"
          },
          {
              id: "0de3360b0a4ee310ba5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=695&size=130"
          },
          {
              id: "9228a6c09a8573db2a94",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=696&size=130"
          },
          {
              id: "2a431fab23eecab093ff",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=697&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker327437b90bfce2a2bbed.png",
      price: "Miễn phí",
      name: "Thỏ Có Râu",
      thumbImg: "https://zalo-api.zadn.vn/1/7/d/f/6/10121/preview/Thocorau_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/1/7/d/f/6/10121/icon_pre/Thocorau_thumb.png",
      id: "cefecb33f7761e284767",
      source: "Minto Inc.",
      totalImage: "20 sticker",
      value: [
          {
              id: "839cac33907679282067",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17827&size=130"
          },
          {
              id: "7da75508694d8013d95c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17828&size=130"
          },
          {
              id: "a3b28a1db6585f060649",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17829&size=130"
          },
          {
              id: "6f20458f79ca9094c9db",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17830&size=130"
          },
          {
              id: "064b2de411a1f8ffa1b0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17831&size=130"
          },
          {
              id: "0bca2f651320fa7ea331",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17832&size=130"
          },
          {
              id: "bef39b5ca7194e471708",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17833&size=130"
          },
          {
              id: "795c5ff363b68ae8d3a7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17834&size=130"
          },
          {
              id: "16f2315d0d18e446bd09",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17835&size=130"
          },
          {
              id: "1dbb3d140151e80fb140",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17836&size=130"
          },
          {
              id: "c273e3dcdf9936c76f88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17837&size=130"
          },
          {
              id: "b42c9683aac643981ad7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17838&size=130"
          },
          {
              id: "44cd67625b27b279eb36",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17839&size=130"
          },
          {
              id: "a50599aaa5ef4cb115fe",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17840&size=130"
          },
          {
              id: "36ad0b023747de198756",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17841&size=130"
          },
          {
              id: "9d3aa3959fd0768e2fc1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17842&size=130"
          },
          {
              id: "01f23e5d0218eb46b209",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17843&size=130"
          },
          {
              id: "44ef7c404005a95bf014",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17844&size=130"
          },
          {
              id: "aa1f93b0aff546ab1fe4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17845&size=130"
          },
          {
              id: "17402def11aaf8f4a1bb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17846&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerf51e2cd31096f9c8a087.png",
      price: "Miễn phí",
      name: "Dean Thật Thà",
      thumbImg: "https://zalo-api.zadn.vn/5/3/0/a/d/10069/preview/mice_boy_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/5/3/0/a/d/10069/icon_pre/dean_thumb.png",
      id: "af0876c54a80a3defa91",
      source: "",
      totalImage: "10 sticker",
      value: [
          {
              id: "25ce24661823f17da832",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17037&size=130"
          },
          {
              id: "b116b3be8ffb66a53fea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17038&size=130"
          },
          {
              id: "e8daeb72d7373e696726",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17039&size=130"
          },
          {
              id: "dfa3c30bff4e16104f5f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17040&size=130"
          },
          {
              id: "e692fb3ac77f2e21776e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17041&size=130"
          },
          {
              id: "33602dc8118df8d3a19c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17042&size=130"
          },
          {
              id: "16900938357ddc23856c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17043&size=130"
          },
          {
              id: "894d91e5ada044fe1db1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17044&size=130"
          },
          {
              id: "37722eda129ffbc1a28e",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17045&size=130"
          },
          {
              id: "6ec17469482ca172f83d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17046&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrsticker5d7595b8a9fd40a319ec.png",
      price: "Miễn phí",
      name: "Tiêu Viêm - Huân Nhi",
      thumbImg: "https://zalo-api.zadn.vn/d/6/f/8/d/10052/preview/cover.png",
      iconUrl: "https://zalo-api.zadn.vn/d/6/f/8/d/10052/icon_pre/tieuviem_thumb.png",
      id: "a3496b8457c1be9fe7d0",
      source: "",
      totalImage: "18 sticker",
      value: [
          {
              id: "761874b348f6a1a8f8e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16782&size=130"
          },
          {
              id: "a715a4be98fb71a528ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16783&size=130"
          },
          {
              id: "63c77f6c4329aa77f338",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16784&size=130"
          },
          {
              id: "140a09a135e4dcba85f5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16785&size=130"
          },
          {
              id: "c6d8d873e4360d685427",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16786&size=130"
          },
          {
              id: "61bf7e144251ab0ff240",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16787&size=130"
          },
          {
              id: "03f91b522717ce499706",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16788&size=130"
          },
          {
              id: "05e31c48200dc953901c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16789&size=130"
          },
          {
              id: "46fc5c576012894cd003",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16790&size=130"
          },
          {
              id: "68c4736f4f2aa674ff3b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16791&size=130"
          },
          {
              id: "55cd41667d23947dcd32",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16792&size=130"
          },
          {
              id: "54b941127d579409cd46",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16793&size=130"
          },
          {
              id: "89689fc3a3864ad81397",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16794&size=130"
          },
          {
              id: "d3abc400f845111b4854",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16795&size=130"
          },
          {
              id: "ae15bebe82fb6ba532ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16796&size=130"
          },
          {
              id: "12c903623f27d6798f36",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16797&size=130"
          },
          {
              id: "528840237c669538cc77",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16798&size=130"
          },
          {
              id: "408c53276f62863cdf73",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=16799&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickere2a93d640121e87fb130.png",
      price: "Miễn phí",
      name: "Alice Điệu Đà",
      thumbImg: "https://zalo-api.zadn.vn/7/9/2/d/d/10067/preview/mice_girl_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/7/9/2/d/d/10067/icon_pre/mice_girl_thumb.png",
      id: "b1e06e2d5268bb36e279",
      source: "",
      totalImage: "10 sticker",
      value: [
          {
              id: "423fb7978bd2628c3bc3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17017&size=130"
          },
          {
              id: "191befb3d3f63aa863e7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17018&size=130"
          },
          {
              id: "bd414ae976ac9ff2c6bd",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17019&size=130"
          },
          {
              id: "3c16ccbef0fb19a540ea",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17020&size=130"
          },
          {
              id: "4d55bcfd80b869e630a9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17021&size=130"
          },
          {
              id: "cf8e3d260163e83db172",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17022&size=130"
          },
          {
              id: "3c36cf9ef3db1a8543ca",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17023&size=130"
          },
          {
              id: "41ce4d667123987dc132",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17024&size=130"
          },
          {
              id: "636e6ec65283bbdde292",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17025&size=130"
          },
          {
              id: "0c7102d93e9cd7c28e8d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17026&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickera3ad9b60a7254e7b1734.png",
      price: "Miễn phí",
      name: "Olympic - H.X.Vinh",
      thumbImg: "https://zalo-api.zadn.vn/c/f/0/9/1/10164/preview/hxv_cover.png",
      iconUrl: "https://zalo-api.zadn.vn/c/f/0/9/1/10164/icon_pre/hxv_thumb.png",
      id: "44907c5d4018a946f009",
      source: "Gấu studio",
      totalImage: "20 sticker",
      value: [
          {
              id: "8722a7809bc5729b2bd4",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18604&size=130"
          },
          {
              id: "8ba7aa0596407f1e2651",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18605&size=130"
          },
          {
              id: "0e8a2c28106df933a07c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18606&size=130"
          },
          {
              id: "7fb15c1360568908d047",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18607&size=130"
          },
          {
              id: "5ed462765e33b76dee22",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18608&size=130"
          },
          {
              id: "9a04a7a69be372bd2bf2",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18609&size=130"
          },
          {
              id: "91f9af5b931e7a40230f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18610&size=130"
          },
          {
              id: "64e25b4067058e5bd714",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18611&size=130"
          },
          {
              id: "12bc2a1e165bff05a64a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18612&size=130"
          },
          {
              id: "412f788d44c8ad96f4d9",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18613&size=130"
          },
          {
              id: "9e7da4df989a71c4288b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18614&size=130"
          },
          {
              id: "d506eea4d2e13bbf62f0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18615&size=130"
          },
          {
              id: "a7c59367af22467c1f33",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18616&size=130"
          },
          {
              id: "46bd731f4f5aa604ff4b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18617&size=130"
          },
          {
              id: "a44092e2aea747f91eb6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18618&size=130"
          },
          {
              id: "a6ff915dad1844461d09",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18619&size=130"
          },
          {
              id: "dcd9ec7bd03e3960602f",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18620&size=130"
          },
          {
              id: "6a2c5b8e67cb8e95d7da",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18621&size=130"
          },
          {
              id: "609a52386e7d8723de6c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18622&size=130"
          },
          {
              id: "4c8a7f28436daa33f37c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=18623&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/1/qrstickerabdb47167b53920dcb42.png",
      price: "Miễn phí",
      name: "Tiêu Viêm - Huân Nhi Tết",
      thumbImg: "https://zalo-api.zadn.vn/e/b/d/7/7/10080/preview/tieuviem_tet.png",
      iconUrl: "https://zalo-api.zadn.vn/e/b/d/7/7/10080/icon_pre/tieuviem_thumb.png",
      id: "0450e89dd4d83d8664c9",
      source: "",
      totalImage: "18 sticker",
      value: [
          {
              id: "86ee21471d02f45cad13",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17195&size=130"
          },
          {
              id: "cf996f305375ba2be364",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17196&size=130"
          },
          {
              id: "8ab02b19175cfe02a74d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17197&size=130"
          },
          {
              id: "7cc7de6ee22b0b75523a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17198&size=130"
          },
          {
              id: "28488be1b7a45efa07b5",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17199&size=130"
          },
          {
              id: "3ee4824dbe0857560e19",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17200&size=130"
          },
          {
              id: "cb26768f4acaa394fadb",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17201&size=130"
          },
          {
              id: "bed100783c3dd5638c2c",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17202&size=130"
          },
          {
              id: "cf9a70334c76a528fc67",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17203&size=130"
          },
          {
              id: "897531dc0d99e4c7bd88",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17204&size=130"
          },
          {
              id: "e5685cc1608489dad095",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17205&size=130"
          },
          {
              id: "d1ba6b135756be08e747",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17206&size=130"
          },
          {
              id: "596ae2c3de8637d86e97",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17207&size=130"
          },
          {
              id: "4079f4d0c89521cb7884",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17208&size=130"
          },
          {
              id: "da4a6fe353a6baf8e3b7",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17209&size=130"
          },
          {
              id: "4df9fb50c7152e4b7704",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17210&size=130"
          },
          {
              id: "cdb07a19465caf02f64d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17211&size=130"
          },
          {
              id: "accf1c662023c97d9032",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=17212&size=130"
          }
      ]
    },
    {
      textUploader: "Họa sĩ",
      qrCode: "https://qr-stickers.zaloapp.com/2/qrsticker966628a314e6fdb8a4f7.png",
      price: "Miễn phí",
      name: "Anh Thỏ Ngọc",
      thumbImg: "https://zalo-api.zadn.vn/1/8/3/1/d/12082/preview/440 x 440.png",
      iconUrl: "https://zalo-api.zadn.vn/1/8/3/1/d/12082/icon_pre/anhthongoc_thumb.png",
      id: "5b25e5e0d9a530fb69b4",
      source: "0",
      totalImage: "12 sticker",
      value: [
          {
              id: "59299d7fa13a4864112b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48200&size=130"
          },
          {
              id: "ae5a6b0c5749be17e758",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48201&size=130"
          },
          {
              id: "0359c50ff94a1014495b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48202&size=130"
          },
          {
              id: "7b31bc678022697c3033",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48203&size=130"
          },
          {
              id: "e81f2849140cfd52a41d",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48204&size=130"
          },
          {
              id: "46b387e5bba052fe0bb1",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48205&size=130"
          },
          {
              id: "fd863fd00395eacbb384",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48206&size=130"
          },
          {
              id: "9ac4599265d78c89d5c6",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48207&size=130"
          },
          {
              id: "47c19b97a7d24e8c17c3",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48208&size=130"
          },
          {
              id: "56288b7eb73b5e65072a",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48209&size=130"
          },
          {
              id: "c2a21cf420b1c9ef90a0",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48210&size=130"
          },
          {
              id: "92394d6f712a9874c13b",
              url: "https://zalo-api.zadn.vn/api/emoticon/sticker/webpc?eid=48211&size=130"
          }
      ]
    }
  ]
