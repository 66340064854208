import { Injectable } from '@angular/core';
import { AuthenticateStore } from './authenticate.store.service';
import { checkMatch } from '@etop/core/modules/authenticate/common.internal';

@Injectable({
  providedIn: 'root'
})
export class AuthenticateService {
  constructor(private authStore: AuthenticateStore) {
    AuthenticateService._authStore = authStore;
  }

  public hookDecorator() {
    // NOTE: just for tree-shaking cheating
    return;
  }

  private static _authStore: AuthenticateStore;

  public static staticCheckPermissions(permissions, operator) {
    return checkMatch(permissions, AuthenticateService._authStore.snapshot.permission.permissions, operator);
  }

  public static staticCheckRoles(roles, operator) {
    return checkMatch(roles, AuthenticateService._authStore.snapshot.permission.roles, operator || 'or');
  }

  public checkPermissions(permissions, operator) {
    return checkMatch(permissions, AuthenticateService._authStore.snapshot.permission.permissions, operator);
  }

  public checkRoles(roles, operator) {
    return checkMatch(roles, AuthenticateService._authStore.snapshot.permission.roles, operator || 'or');
  }

  public static getReCaptcha(recaptchaKey: string) {
    return new Promise((resolve, reject) => {
      grecaptcha.ready(async function() {
        try {
          let token = await grecaptcha.execute(recaptchaKey);
          resolve(token);
        } catch (e) {
          if (!e) {
            e = {
              message: "Lỗi captcha"
            };
          }
          grecaptcha.reset();
          reject(e);
        }
      });
    });
  }

}
