import { BaseModel } from '@etop/core';
import { Address } from './Address';
import { ExtendedAccount } from './Account';
import { Order, OrderLine } from './Order';
import { FulfillmentFeeLine } from './Fee';
import {Ticket} from "@etop/models/Ticket";
import {Connection} from "@etop/models/Connection";

export declare type Provider = 'ghn' | 'ghtk' | 'vtpost' | 'ahamove' | 'grab' | 'unknown';
export declare type ShippingState = 'default' | 'created' | 'confirmed'
  | 'processing' | 'picking' | 'holding' | 'returning' | 'returned'
  | 'delivering' | 'delivered' | 'unknown' | 'undeliverable' | 'cancelled' | 'error';

export const TRY_ON_OPTIONS = [
  {
    value: 'try',
    name: 'Cho thử hàng'
  },
  {
    value: 'open',
    name: 'Cho xem hàng không thử'
  },
  {
    value: 'none',
    name: 'Không cho xem hàng'
  }
];


export const TRY_ON_OPTIONS_SHIPPING = [
  {
    value: 'seller',
    name: 'Shop trả phí'
  },
  {
    value: 'buyer',
    name: 'Khách trả phí'
  },
];

export const FULFILLMENT_STATE = {
  default: 'Đã tạo',
  created: 'Đã tạo',
  confirmed: 'Đã xác nhận',
  assigning: 'Đang nhận giao',
  picking: 'Đang lấy hàng',
  holding: 'Chờ giao',
  delivering: 'Đang giao',
  delivered: 'Đã giao',
  returning: 'Đang trả hàng',
  returned: 'Đã trả hàng',
  cancelled: 'Đã hủy',
  error: 'Lỗi vận đơn',
  undeliverable: 'Không giao được',
  unknown: 'Không xác định'
};

export const PAYMENT_STATUS = {
  P: 'Đã chuyển tiền',
  S: 'Chờ chuyển tiền',
  Z: 'Chưa đối soát',
  N: 'Đã chuyển tiền'
};

export const SHIPPING_FEE_SHOP_TYPE = {
  main: 'Phí chính',
  insurance: 'Phí bảo hiểm',
  cods: 'Phí thu hộ (COD)',
  return: 'Phí trả hàng',
  adjustment: 'Phí thay đổi thông tin',
  redelivery: 'Phí kích hoạt giao lại',
  tax: 'Thuế',
  address_change: 'Phí đổi thông tin',
  discount: 'Giảm giá',
  other: 'Phụ phí khác',
  unknown: 'Không xác định'
};

export const TRY_ON = {
  try: 'Cho thử hàng',
  open: 'Cho xem hàng không thử',
  none: 'Không cho xem hàng',
  unknown: 'Không xác định'
};

export const PROVIDER_LOGO = {
  ghn: 'assets/images/provider_logos/ghn-s.png',
  ghtk: 'assets/images/provider_logos/ghtk-s.png',
  vtpost: 'assets/images/provider_logos/vtpost-s.png',
  ahamove: 'assets/images/provider_logos/ahamove-s.png',
  ninjavan: 'assets/images/provider_logos/ninjavan-s.png',
  dhl: 'assets/images/provider_logos/dhl-s.png',
  grab: 'assets/images/provider_logos/grab-s.png',
  default: 'assets/images/placeholder_medium.png'
};

export class FulfillmentLine extends BaseModel {
  order_id: string;
  variant_id: string;
  supplier_id: string;
  x_variant_id: string;
  product_name: string;
  is_outside_etop: boolean;
  updated_at: string;
  closed_at: string;
  confirmed_at: string;
  cancelled_at: string;
  cancel_reason: string;
  s_confirm: string;
  quantity: number;
  list_price: number;
  retail_price: number;
  payment_price: number;
  image_url: string;
  attributes: Array<any>;
  product_id: string;
  total_discount: number;
}

export class ShipnowDeliveryPoint {
  cod_amount: number;
  shipping_address: Address;
  chargeable_weight?: number;
  gross_weight?: number;
  order_id?: string;
  shipping_note?: string;
  id?: string;
  lines?: Array<OrderLine>;
  try_on?: string;
  value_info?: ValueInfo;
  weight_info?: WeightInfo;

  order_total_amount?: number;
  order_code?: string;
  order_status?: string;
  order_status_display?: string;

  invalid?: boolean;
}

export class ValueInfo {
  basket_value: number;
  cod_amount: number;
  include_insurance?: boolean;
}

export class WeightInfo {
  chargeable_weight: number;
  gross_weight?: number;
  height?: number;
  length?: number;
  width?: number;
}

export class Fulfillment extends BaseModel {
  actual_compensation_amount: number;
  admin_note: string;
  basket_value: number;
  barcode: string; // barcode by shipping_code
  cancel_reason: string;
  cancelled_at: string;
  carrier: Provider;
  carrier_display: string;
  chargeable_weight: number;
  length: number;
  width: number;
  height: number;
  connection_id: string;
  cod_amount: number;
  coupon: string;
  insurance_value: number;
  code: string;
  created_at: string;
  delivery_points: Array<ShipnowDeliveryPoint>;
  estimated_delivery_at: string;
  estimated_pickup_at: string;
  etop_payment_status: string;
  etop_payment_status_display: string;
  expected_delivery_at: string;
  expected_pick_at: string;
  ed_code: string;
  id: string;
  include_insurance: boolean;
  money_transaction_shipping_id: string;
  money_transaction_shipping_external_line_id: string;
  order: Order;
  orders: Order[];
  order_id: string;
  order_ids: Array<string>;
  pickup_address: Address;
  provider_shipping_fee_lines: Array<FulfillmentFeeLine>;
  shipment: Fulfillment;
  shipnow: Fulfillment;
  shipping_address: Address;
  shipping_code: string;
  shipping_fee_provider: number;
  shipping_fee_shop: number;
  shipping_fee_shop_lines: Array<FulfillmentFeeLine>;
  shipping_note: string;
  shipping_provider: Provider;
  shipping_type: 'manual' | 'shipment' | 'shipnow';
  shipping_provider_logo: string;
  shipping_provider_logo_big: string; // big logo used in printings
  shipping_service_code: string;
  shipping_service_description: string;
  shipping_service_fee: number;
  shipping_service_name: string;
  shipping_shared_link: string;
  shipping_state: ShippingState;
  shipping_state_display: string;
  shipping_status: string;
  shop: ExtendedAccount;
  shop_id: string;
  status: string;
  status_display: string;
  tickets: Ticket[];
  total_amount: number;
  total_cod_amount: number;
  total_items: number;
  total_weight: number;
  try_on: string;
  try_on_display: string;
  updated_at: string;
  value_info: ValueInfo;
  weight_info: WeightInfo;
  x_shipping_delivered_at: string;
  x_shipping_logs: Array<any>;
  x_shipping_note: string;
  x_shipping_picked_at: string;
  x_shipping_sub_state: string;
  x_sort_code: string;
  x_shipping_code?: string;
  shipping_payment_type : 'seller' | 'buyer';
  from_topship?: boolean;
  not_found_on_etop?: boolean;
  lines?: Array<FulfillmentLine>;
  lines_content?: string;
  external_code?: string;
  external_customer?: string;
  external_address?: string;
  external_total_cod?: number;
  external_total_shipping_fee?: number;
  external_import_error?: any;
  status_mapped: {
    display: string,
    state: string
  };

  connection_name?: string;
  tracking_url?: string;

  number_of_print?: string;

  import_fulfillment_id?: string;

  external_sort_code?: string;

  shipping_fee_shop_lines_mapped: Array<{
    cost: number,
    name: string
  }>;

  p_data: any = {
    detailed: false,
    selected: false
  };

  public static fulfillmentMap(fulfillment: Fulfillment, connections: Connection[]): Fulfillment {
    if (!fulfillment) {
      return null;
    }

    const _connection = connections.find(conn => conn.id == fulfillment.connection_id);
    fulfillment.from_topship = fulfillment.connection_id == '0' || !fulfillment.connection_id || _connection?.connection_method == 'builtin';

    return {
      ...fulfillment,
      shipping_status: fulfillment.shipping_code ? fulfillment.shipping_status : 'N',
      shipping_state: fulfillment.shipping_code ? fulfillment.shipping_state : 'error',
      shipping_state_display: fulfillment.shipping_code ?
        (
          (fulfillment.delivery_points?.length && fulfillment.shipping_state == 'delivered') ?
            'Hoàn thành' :
            FULFILLMENT_STATE[fulfillment.shipping_state]
        ) :
        FULFILLMENT_STATE.error,
      etop_payment_status: Fulfillment.paymentStatusMap(fulfillment.etop_payment_status, fulfillment.money_transaction_shipping_id),
      etop_payment_status_display: PAYMENT_STATUS[Fulfillment.paymentStatusMap(
        fulfillment.etop_payment_status, fulfillment.money_transaction_shipping_id)],
      shipping_fee_shop_lines: fulfillment.shipping_fee_shop_lines?.map(line => ({
        ...line,
        shipping_fee_type_display: SHIPPING_FEE_SHOP_TYPE[line.shipping_fee_type] || SHIPPING_FEE_SHOP_TYPE.unknown
      })),
      try_on_display: TRY_ON[fulfillment.try_on],
      shipping_fee_shop: fulfillment.delivery_points ? fulfillment.shipping_service_fee : fulfillment.shipping_fee_shop,
      total_cod_amount: fulfillment.delivery_points ? fulfillment.value_info.cod_amount : fulfillment.total_cod_amount,
      total_weight: fulfillment.delivery_points ? fulfillment.weight_info.chargeable_weight : fulfillment.chargeable_weight,
      chargeable_weight: fulfillment.delivery_points ? fulfillment.weight_info.chargeable_weight : fulfillment.chargeable_weight,
      carrier_display: _connection ? _connection.name : `${fulfillment.from_topship ? 'TOPSHIP - ' : ''}${fulfillment.carrier}`,
      shipping_provider_logo: _connection ? _connection.provider_logo : PROVIDER_LOGO[fulfillment.carrier],
      connection_name: _connection?.name,
      tracking_url: _connection?.tracking_url
    };
  }

  public static paymentStatusMap(paymentStatus: string, moneyTransactionId: string) {
    if (paymentStatus == 'N') {
      return 'P';
    }
    if (!moneyTransactionId || moneyTransactionId == "0") {
      return 'Z';
    }
    return paymentStatus;
  }

  static trackingLink(fulfillment: Fulfillment) {
    if (!fulfillment) {
      return null;
    }
    switch (fulfillment.carrier) {
      case 'ghn':
        return `https://donhang.ghn.vn/?order_code=${fulfillment.shipping_code}&code=${fulfillment.shipping_code}`;
      case 'vtpost':
        return `https://old.viettelpost.com.vn/Tracking?KEY=${fulfillment.shipping_code}`;
      case 'ahamove':
        return fulfillment.shipping_shared_link;
      default:
        return null;
    }
  }

}

export interface ShippingCarrier {
  id: string;
  name: string;
  logo: string;

  carrier_name?: string;
  from_topship?: boolean;
  loading?: boolean;
}

export interface ShipmentCarrier extends ShippingCarrier {
  services: FulfillmentShipmentService[];
}

export interface ShipnowCarrier extends ShippingCarrier {
  services: FulfillmentShipnowService[];
}

export interface FulfillmentShippingService {
  selected: boolean;
  unique_id: string;
  name: string;
  carrier: string;
  fee: number;
  code: string;

  is_available: boolean;
  error_message: string;
  provider_logo: string;
  connection_info: ConnectionInfo;

  from_topship?: boolean;
}

export interface FulfillmentShipmentService extends FulfillmentShippingService {
  estimated_pickup_at: string;
  estimated_delivery_at: string;
  shipment_service_info: ShipmentServiceInfo;
  shipment_price_info: ShipmentPriceInfo;
}

export interface FulfillmentShipnowService extends FulfillmentShippingService {
  description: string;
}

export interface ConnectionInfo {
  id: string;
  image_url: string;
  name: string;
}

export interface ShipmentServiceInfo {
  id: string;
  code: string;
  name: string;
  is_available: boolean;
  error_message: string;
}

export interface ShipmentPriceInfo {
  id: string;
  origin_fee: number;
  makeup_fee: number;
}
