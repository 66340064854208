import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../../../core/src/services/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'etelecom-cs-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('confirmPasswordInput', {static: false}) confirmPasswordInput: ElementRef;
  resetPasswordData: any = {};
  error = false;
  errorMessage = "";
  token = "";
  loading = false;

  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.token = this.activatedRoute.snapshot.queryParams['t'];
  }

  async doResetPassword() {
    let resetData = this.resetPasswordData;
    this.error = false;

    try {
      if (!resetData.password) {
        throw new Error("Vui lòng nhập mật khẩu mới.");
      }

      if (resetData.password != resetData.confirm) {
        throw new Error("Mật khẩu nhập lại không chính xác.");
      }
    } catch (e) {
      this.error = true;
      this.errorMessage = e.message;
      return;
    }

    this.loading = true;
    try {
      let data = {
        reset_password_token: this.token,
        new_password: resetData.password,
        confirm_password: resetData.confirm
      };
      await this.userService.updatePasswordUsingToken(data)
      toastr.success("Đặt lại mật khẩu thành công!");
      this.router.navigateByUrl("/register");
    } catch (e) {
      toastr.error(e.message, "Đặt lại mật khẩu không thành công!");
    }

    this.loading = false;
  }

  async onEnterLoginInput() {
    if (!this.resetPasswordData.confirm ) {
      if (this.confirmPasswordInput) {
        setTimeout(_ => {
          this.confirmPasswordInput.nativeElement.focus();
        });
      }
    }
  }

}
