import { Component, OnInit } from '@angular/core';
import { ModalAction } from 'apps/core/src/components/modal-controller/modal-action.service';
import { AuthenticateStore } from '@etop/core';
import { UserService } from 'apps/core/src/services/user.service';

@Component({
  selector: 'shared-change-password-modal',
  templateUrl: './change-password-modal.component.html',
  styleUrls: ['./change-password-modal.component.scss']
})
export class ChangePasswordModalComponent implements OnInit {
  changePasswordData: any = {};
  loading = false;
  error = false;
  errorMessage = '';

  constructor(
    private userService: UserService,
    private auth: AuthenticateStore,
    private modalDismiss: ModalAction
  ) {}

  ngOnInit() {}

  close() {
    this.modalDismiss.dismiss(null);
  }

  validate() {
    let changeData = this.changePasswordData;
    if (!changeData.currentPassword) {
      throw new Error('Vui lòng nhập mật khẩu hiện tại.');
    }

    if (!changeData.newPassword) {
      throw new Error('Vui lòng nhập mật khẩu mới.');
    }

    if (changeData.newPassword != changeData.confirmNewPassword) {
      throw new Error('Mật khẩu nhập lại không chính xác.');
    }
  }

  async save() {
    let changeData = this.changePasswordData;
    this.error = false;
    try {
      this.validate();
    } catch (e) {
      this.error = true;
      this.errorMessage = e.message;
      return;
    }

    this.loading = true;
    try {
      let data = {
        login: this.auth.snapshot.user.email || this.auth.snapshot.user.phone,
        current_password: changeData.currentPassword,
        new_password: changeData.newPassword,
        confirm_password: changeData.confirmNewPassword
      };
      await this.userService.updatePassword(data);
      toastr.success('Thay đổi mật khẩu thành công!');
      this.close();
    } catch (e) {
      toastr.error(e.message, 'Thay đổi mật khẩu không thành công!');
    }

    this.loading = false;
  }
}
