import {Injectable} from '@angular/core';
import { TenantApi } from '@etop/api/shop/tenant.api';

@Injectable({
    providedIn: 'root'
})

export class TenantService {
    constructor(
      private tenantApi: TenantApi,
    ) {}

    async getTenant(connection_id: string) {
        try {
            return  await this.tenantApi.getTenant(connection_id);
        } catch (e) {
            return false;
        }
    }

    async createTenant(connection_id: string) {
        return await this.tenantApi.createTenant(connection_id);
    }
}  