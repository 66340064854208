import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsyncScrollDirective } from 'libs/shared/directives/async-scroll.directive';
import { AsyncScrollControllerService } from 'libs/shared/directives/async-scroll-controller.service';

@NgModule({
  declarations: [
    AsyncScrollDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AsyncScrollDirective
  ],
  providers: [
    AsyncScrollControllerService
  ]
})
export class SharedDirectivesModule { }
