import { AuthenticateService } from './authenticate.service';

export class PermissionError extends Error {
  constructor(props) {
    super(props);
    this.name = 'PermissionError';
  }
}

interface CommonConfig {
  skipError?: boolean;
  valueOnError?: any;
  op?: 'or' | 'and';
}

export interface RolesConfig extends CommonConfig {
  roles: string[];
}

export interface PermissionsConfig extends CommonConfig {
  permissions: string[];
}

function checkAllowedMethodBy(byType: 'roles' | 'permissions', config: PermissionsConfig | RolesConfig, origin) {
  return function(...args) {
    let allowed = byType == 'permissions' ?
      AuthenticateService.staticCheckPermissions((config as PermissionsConfig).permissions, config.op || 'or')
      : AuthenticateService.staticCheckRoles((config as RolesConfig).roles, config.op || 'or');
    if (allowed) {
      return origin.apply(this, args);
    }
    if (!config.skipError) {
      throw new PermissionError(`You are not permitted to perform this action.`);
    }
    return config.valueOnError;
  };
}

export function requireMethodPermissions(config: PermissionsConfig) {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    descriptor.value = checkAllowedMethodBy('permissions', config, descriptor.value);
    return descriptor;
  };
}

export function requireMethodRoles(config: RolesConfig) {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    descriptor.value = checkAllowedMethodBy('roles', config, descriptor.value);
    return descriptor;
  };
}

export function requireClassPermission() {
  throw new Error('Not implemented');
}

export function requireClassRoles() {
  throw new Error('Not implemented');
}
